import { AxiosResponse } from "axios";

export class ApiError
{
    public statusCode: number;
    public statusText: string;

    constructor(statusCode: number, statusText: string)
    {
        this.statusCode = statusCode;
        this.statusText = statusText;
    }

    public static fromAxiosResponse(value: AxiosResponse)
    {
        return new ApiError(value.status, value.statusText ?? value.data.Message);
    }
}
