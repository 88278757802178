import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../styles/css/header.scss";
import "../../App.css";
import "../../styles/css/form.scss";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Submenu from "../../Components/SubMenu";
import apis from "../../Providers.Api/apis";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Inputbox from "../../Components/uicomponents/Inputbox";
import SelectBox from "../../Components/uicomponents/SelectBox";
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import { MDBDataTable } from "mdbreact";
import LoadingOverlay from "../../Components/LoadingOverlay"
import { convertApiResponseObjectToLower } from "../../Common/Helper"
import { appContext } from "../../AppContext";
import { IUserRights } from "../../Providers.Api/Models.UserApi";

class SpacesEdit extends Component<IProps, IState>
{
    private hasPartEmptyTable = {

        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private isPartOfEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private devicesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private taskTypesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private taskCategoriesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private taskStatesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private spaceStatesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private agentsEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private KPIsEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private signagesEmptyTable = {
        columns: null,
        thClasses: [],
        heading: [],
        style: [],
        message: this.labels.HubLabelLoadingText,
        dashboard: 0,
    };

    private get labels() { return appContext().labels; }
    private local = appContext().localStorageProvider;

    constructor(props: any)
    {
        super(props);
        this.state = {
            isLoadingToSave: false,
            activePage: 0,
            loading: false,
            tableHeading: [this.labels.HubLabelName, this.labels.HubLabelCode, this.labels.HubLabelType, this.labels.HubLabelCapacity, this.labels.HubLabelSize, this.labels.HubLabelActiveRoles, ""],
            thClasses: ["spaces-col-400 spaces-col-text th", "spaces-col-400 spaces-col-text th", "spaces-col-200 spaces-col-text th", "spaces-col-200 spaces-col-number-th", "spaces-col-200 spaces-col-number-th", "spaces-col-150 th", "col-button th"],
            tdClasses: ["spaces-col-400 spaces-col-text td", "spaces-col-400 spaces-col-text td", "spaces-col-200 spaces-col-text td", "spaces-col-200 spaces-col-number", "spaces-col-200 spaces-col-number", "spaces-col-150 td", "col-button td"],
            tableHeadParameters: [this.labels.HubLabelName, this.labels.HubLabelLabel, this.labels.HubLabelSection, this.labels.HubLabelValue, ""],
            parametersThClasses: ["spaces-col-400 spaces-col-text th", "spaces-col-200 spaces-col-text th", "spaces-col-200 spaces-col-text th", "spaces-col-200 spaces-col-text-th", "col-button th nopadding"],
            parametersTdClasses: ["spaces-col-400 spaces-col-text td", "spaces-col-200 spaces-col-text td", "spaces-col-200 spaces-col-text td", "spaces-col-200 spaces-col-number-td", "col-button td nopadding"],
            tableHeadingDevices: [this.labels.HubLabelSpace, this.labels.HubLabelDeviceType, this.labels.HubLabelDeviceCode, this.labels.HubLabelDeviceMode, ""],
            devicesThClasses: ["spaces-col-400 spaces-col-text th", "spaces-col-400 spaces-col-text th", "spaces-col-400 spaces-col-text th", "spaces-col-400 spaces-col-text th", "col-button th"],
            devicesTdClasses: ["spaces-col-400 spaces-col-text td", "spaces-col-400 spaces-col-text td", "spaces-col-400 spaces-col-text td", "spaces-col-400 spaces-col-text td", "col-button td"],
            tableHeadingTaskCategories: [this.labels.HubLabelName, this.labels.HubLabelEnabled, ""],
            taskCategoriesThClasses: ["col-name th", "col-name th", "col-button th"],
            taskCategoriesTdClasses: ["col-name td", "col-name td", "col-button td"],
            tableHeadingTaskTypes: [this.labels.HubLabelTaskTypes, this.labels.HubLabelTaskCateory, this.labels.HubLabelEnabled, ""],
            taskTypesthClasses: ["col-name th", "col-name th", "col-name th", "col-button th"],
            taskTypestdClasses: ["col-name td", "col-name td", "col-name td", "col-button td"],
            tableHeadingTaskStates: [this.labels.HubLabelstateId, this.labels.HubLabelName, ""],
            taskStatesThClasses: ["col-name th", "col-name th", "col-button th"],
            taskStatesTdClasses: ["col-name td", "col-name td", "col-button td"],
            tableHeadingSpaceStates: [this.labels.HubLabelstateId, this.labels.HubLabelName, ""],
            spaceStatesThClasses: ["col-name th", "col-name th", "col-button th"],
            spaceStatesTdClasses: ["col-name td", "col-name td", "col-button td"],
            tableHeadingAgents: [this.labels.HubLabelName, this.labels.HubLabelRoleLabel, ""],
            agentsThClasses: ["col-name th", "col-name th", "col-button th"],
            agentsTdClasses: ["col-name td", "col-name td", "col-button td"],
            tableHeadingKPIs: [this.labels.HubLabelLabel, this.labels.HubLabelKPIType, this.labels.HubLabelValue, ""],
            KPIsThClasses: ["col-name th", "col-name th", "col-name th", "col-button th"],
            KPIsTdClasses: ["col-name td", "col-name td", "col-name td", "col-button td"],
            tableHeadingSignages: [this.labels.HubLabelName, this.labels.HubLabelMode, this.labels.HubLabelOrientation, ""],
            signagesThClasses: ["col-name th", "col-name th", "col-name th", "col-button th"],
            signagesTdClasses: ["col-name td", "col-name td", "col-name td", "col-button td"],

            spacesIsPartOf: [],
            spacesHasPart: [],
            spacesParameters: [],
            spacesDevices: [],
            spacesTaskCategories: [],
            spacesSpaceStates: [],
            spacesTaskTypes: [],
            spacesTaskStates: [],
            spacesAgents: [],
            spacesKPIs: [],
            spacesSignages: [],
            spaceTypeList: [],
            spaceTypeListAll: [],
            spaceWorkTypeList: [],
            spaceClassTypeList: [],
            filter: "",
            nameHeader: this.labels.HubLabelLoadingText,
            updates: false,
            code: "",
            concurrencyStamp: "",
            id: null,
            isEnabled: null,
            isPartOf: 1,
            isSearchable: 0,
            name: "",
            personCapacity: null,
            type: "",
            space_Type: "",
            area: [],
            showLevelFields: false,
            showBuildingFields: false,
            imageURI: "",
            space_Custom_Info: "",
            country: "",
            city: "",
            address: "",
            longitude: 0,
            latitude: 0,
            meta_Occ_Type: 0,
            space_Arrival_Loc: 0,
            meta_Bookable: 0,
            meta_Bookable_Res_Req: 0,
            meta_Space_DurationMin_min: 0,
            meta_Space_DurationMax_min: 0,
            meta_Sol_Thld_Min: 0,
            meta_Clean_Thld_Min: 0,
            meta_Ext_Occ_System: 0,
            meta_Ext_Occ_Count_Mode: 0,
            meta_Ext_Occ_Area_Id: "",
            meta_Ext_Occ_Sensor_Id: "",
            meta_Ext_Booking_System: 0,
            meta_Ext_Booking_Space_Id: "",
            meta_Ext_Catering_System: 0,
            meta_Ext_Catering_Space_Id: "",
            meta_Gtwy_Type: 0,
            meta_Gtwy: "",
            space_Light_Mode: 0,
            space_Light_Scenes: "",
            space_AV_Source_Scenes: "",
            space_Work_Type: "",
            space_Class: "",
            space_Type_Label: "",
            meta_Loc_Y_m: "",
            meta_Loc_X_m: "",
            mapURI: "",
            levelNumber: null,
            organisationState: "",
            organisationPath: "",
            organisationId: null,
            showOrganisation: false,
            regionState: "",
            regionPath: "",
            regionId: null,
            showRegion: false,
            buildingState: "",
            buildingPath: "",
            buildingId: null,
            showBuilding: false,
            floorState: "",
            floorPath: "",
            floorId: null,
            showFloor: false,
            zoneState: "",
            zonePath: "",
            zoneId: null,
            showZone: false,
            roomState: "",
            roomPath: "",
            roomId: null,
            showRoom: false,
            deskState: "",
            deskPath: "",
            deskId: null,
            showDesk: false,
            reload: true,
            parameterMessage: this.labels.HubLabelLoadingText,
            hasPartMessage: this.labels.HubLabelLoadingText,
            isPartOfMessage: this.labels.HubLabelLoadingText,
            devicesMessage: this.labels.HubLabelLoadingText,
            taskTypesMessage: this.labels.HubLabelLoadingText,
            taskCategoriesMessage: this.labels.HubLabelLoadingText,
            taskStatesMessage: this.labels.HubLabelLoadingText,
            spaceStatesMessage: this.labels.HubLabelLoadingText,
            agentsMessage: this.labels.HubLabelLoadingText,
            KPIsMessage: this.labels.HubLabelLoadingText,
            signagesMessage: this.labels.HubLabelLoadingText,
            allTab: [],
            spaceFilter: "",
            externalFlag: false,
            updateFilter: false,
            show: false,
            isDelete: false,

            isUpdateSpaceRight: false,
            isDeleteSpaceRight: false,

            isCreateParameterRight: false,
            isUpdateParameterRight: false,
            isDeleteParameterRight: false,

            isCreateDeviceRight: false,
            isUpdateDeviceRight: false,
            isDeleteDeviceRight: false,

            isCreateTaskTypeRight: false,
            isUpdateTaskTypeRight: false,
            isDeleteTaskTypeRight: false,

            isCreateTaskCategoriesRight: false,
            isUpdateTaskCategoriesRight: false,
            isDeleteTaskCategoriesRight: false,

            isCreateTaskStateRight: false,
            isUpdateTaskStateRight: false,
            isDeleteTaskStateRight: false,

            isCreateSpaceStateRight: false,
            isUpdateSpaceStateRight: false,
            isDeleteSpaceStateRight: false,

            isCreateBuildingKPIRight: false,
            isUpdateBuildingKPIRight: false,
            isDeleteBuildingKPIRight: false,

            isCreateSignageRight: false,
            isUpdateSignageRight: false,
            isDeleteSignageRight: false,

            isCreatePartRight: false,
            isUpdatePartRight: false,
            isDeletePartRight: false,

            isCreateIsPartRight: false,
            isUpdateIsPartRight: false,
            isDeleteIsPartRight: false,
            rightsData: [],
        }
    }

    componentDidMount()
    {
        const { match }: any = this.props;
        if (match.params.tab)
        {
            this.setState({
                activePage: parseInt(match.params.tab)
            })
        }

        let filter = sessionStorage.getItem('spaceFilter')
        if (filter === null)
        {
            sessionStorage.setItem('spaceFilter', match.params.filter);
            this.setState({
                spaceFilter: match.params.filter
            })
        } else
        {
            this.setState({
                spaceFilter: sessionStorage.getItem('spaceFilter')
            })
        }

        if (match.params.id === "0")
        {
            this.setState({
                filter: match.params.filter,
                id: 0,
                nameHeader: this.labels.HubLabelNewSpaces,
                loading: false,
                isEnabled: 1,
                showLevelFields: false,
                showBuildingFields: false,
                isUpdateSpaceRight: true,
            }, () =>
            {

                if (match.params.filter === "Space")
                {
                    this.setState({
                        type: "Space",
                        externalFlag: true,
                    })
                } else
                {
                    this.setState({
                        type: match.params.filter,
                        externalFlag: false
                    })
                }

                this.loadMenu(match.params.filter);
            })
        } else
        {
            this.setState({
                filter: match.params.filter,
                id: match.params.id
            }, () =>
            {
                this.getDetails(match.params.id, match.params.filter);
            })
        }
        this.getRightList();
        this.getSpacetypeList();
        this.getSpaceWorkType();
        this.getSpaceClassTypeList();
    }

    getRightList = () =>
    {

        const res = this.local.getIbssRightList();
        this.setState({
            rightsData: res
        },
            () =>
            {
                this.getTabsList();
            })

        if (res && res.ADMINPORTAL)
        {
            if (res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Update") > -1 && (this.state.activePage === 0 || this.state.activePage === 1 || this.state.activePage === 13))
            {
                this.setState({
                    isUpdateSpaceRight: true,
                })
            }
            if (res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Delete") > -1 && (this.state.activePage === 0 || this.state.activePage === 1 || this.state.activePage === 13))
            {
                this.setState({
                    isDeleteSpaceRight: true,
                })
            }
            if (res.ADMINPORTAL.Parameters && res.ADMINPORTAL.Parameters.indexOf("Create") > -1 && this.state.activePage === 2)
            {
                this.setState({
                    isCreateParameterRight: true,
                })
            }

            if (res.ADMINPORTAL.Devices && res.ADMINPORTAL.Devices.indexOf("Create") > -1 && this.state.activePage === 6)
            {
                this.setState({
                    isCreateDeviceRight: true,
                })
            }

            if (res.ADMINPORTAL.TaskTypes && res.ADMINPORTAL.TaskTypes.indexOf("Create") > -1 && this.state.activePage === 7)
            {
                this.setState({
                    isCreateTaskTypeRight: true,
                })
            }

            if (res.ADMINPORTAL.TaskCategories && res.ADMINPORTAL.TaskCategories.indexOf("Create") > -1 && this.state.activePage === 8)
            {
                this.setState({
                    isCreateTaskCategoriesRight: true,
                })
            }

            if (res.ADMINPORTAL.TaskStates && res.ADMINPORTAL.TaskStates.indexOf("Create") > -1 && this.state.activePage === 9)
            {
                this.setState({
                    isCreateTaskStateRight: true,
                })
            }

            if (res.ADMINPORTAL.SpaceStates && res.ADMINPORTAL.SpaceStates.indexOf("Create") > -1 && this.state.activePage === 10)
            {
                this.setState({
                    isCreateSpaceStateRight: true,
                })
            }

            if (res.ADMINPORTAL.BuildingKPIs && res.ADMINPORTAL.BuildingKPIs.indexOf("Create") > -1 && this.state.activePage === 11)
            {
                this.setState({
                    isCreateBuildingKPIRight: true,
                })
            }

            if (res.ADMINPORTAL.Signage && res.ADMINPORTAL.Signage.indexOf("Create") > -1 && this.state.activePage === 12)
            {
                this.setState({
                    isCreateSignageRight: true,
                })
            }

            if (res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Create") > -1 && this.state.activePage === 3)
            {
                this.setState({
                    isCreatePartRight: true,
                })
            }
            if (res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Update") > -1 && this.state.activePage === 3)
            {
                this.setState({
                    isUpdatePartRight: true,
                })
            }
            if (res.ADMINPORTAL.Spaces && res.ADMINPORTAL.Spaces.indexOf("Delete") > -1 && this.state.activePage === 3)
            {
                this.setState({
                    isDeletePartRight: true,
                })
            }

            if (res.ADMINPORTAL.IsPart && res.ADMINPORTAL.IsPart.indexOf("Create") > -1 && this.state.activePage === 4)
            {
                this.setState({
                    isCreateIsPartRight: true,
                })
            }
            if (res.ADMINPORTAL.IsPart && res.ADMINPORTAL.IsPart.indexOf("Update") > -1 && this.state.activePage === 4)
            {
                this.setState({
                    isUpdateIsPartRight: true,
                })
            }
            if (res.ADMINPORTAL.IsPart && res.ADMINPORTAL.IsPart.indexOf("Delete") > -1 && this.state.activePage === 4)
            {
                this.setState({
                    isDeleteIsPartRight: true,
                })
            }

        }
    }

    getSpaceClassTypeList = () =>
    {
        apis.getSpaceClassTypeList().then((res) =>
        {
            let tempArray = [];
            for (let i = 0; i < res.data.length; i++)
            {
                tempArray.push({
                    title: res.data[i],
                    value: res.data[i]
                })
            }

            this.setState({
                spaceClassTypeList: tempArray
            })

        }).catch((error: any) => { });
    }

    getSpaceWorkType = () =>
    {
        apis.getSpaceWorkTypeList().then((res) =>
        {
            let tempArray = [];
            for (let i = 0; i < res.data.length; i++)
            {
                tempArray.push({
                    title: res.data[i],
                    value: res.data[i]
                })
            }

            this.setState({
                spaceWorkTypeList: tempArray
            })

        }).catch((error: any) => { });
    }

    getSpacetypeList = () =>
    {
        apis.getSpacetypeList().then((res) =>
        {
            let tempArray = [];
            for (let i = 0; i < res.data.length; i++)
            {
                tempArray.push({
                    title: res.data[i],
                    value: res.data[i]
                })
            }

            this.setState({
                spaceTypeList: tempArray
            }, () =>
            {
                if (this.state.type === "Space")
                {
                    this.setState({
                        spaceTypeListAll: this.state.spaceTypeList
                    })
                } else
                {
                    let tempArray = [
                        { title: this.labels.HubLabelEnvironmental, value: "Environmental" },
                        { title: this.labels.HubLabelSpaces, value: "Space" }
                    ];
                    this.setState({
                        spaceTypeListAll: tempArray
                    })
                }
            })

        }).catch((error: any) => { });
    }

    getTabsList = () =>
    {
        const { match }: any = this.props;
        let rights: any = this.state.rightsData;
        let temp = [];
        if (rights && rights.ADMINPORTAL && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push(
                {
                    title: this.labels.HubLabelSpaceConfiguration,
                    tabNo: 0,
                    active: this.state.activePage === 0 ? true : false,
                    display: 'block'
                },
                {
                    title: this.labels.HubTabSpaceProperties,
                    tabNo: 1,
                    active: this.state.activePage === 1 ? true : false,
                    display: 'block'
                },
                {
                    title: this.labels.HubLabelExternalSystems,
                    tabNo: 13,
                    active: this.state.activePage === 13 ? true : false,
                    display: 'block'
                },
            );
        }

        if ((this.state.id !== 0 && rights && rights.ADMINPORTAL && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Parameters") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1) && match.params.filter !== "Zone"))
        {
            temp.push({
                title: this.labels.HubTabSpaceParameters,
                tabNo: 2,
                active: this.state.activePage === 2 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && (rights.ADMINPORTAL.Spaces.indexOf("Parts") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubTabSpaceParts,
                tabNo: 3,
                active: this.state.activePage === 3 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && (rights.ADMINPORTAL.Spaces.indexOf("IsPartOf") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubTabSpaceIsPartOfTab,
                tabNo: 4,
                active: this.state.activePage === 4 ? true : false,
                display: 'block'
            });
        }
        // if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter !== "Zone" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Agents") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)) {
        //   temp.push({
        //     title: this.labels.spaceAgentsTab,
        //     tabNo: 5,
        //     active: this.state.activePage === 5 ? true : false,
        //     display: 'block'
        //   });
        // }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Devices") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubLabelDevices,
                tabNo: 6,
                active: this.state.activePage === 6 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("TaskTypes") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubTabTaskTypes,
                tabNo: 7,
                active: this.state.activePage === 7 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("TaskCategories") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubLabelTaskCateory,
                tabNo: 8,
                active: this.state.activePage === 8 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("TaskStates") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubTaskStates,
                tabNo: 9,
                active: this.state.activePage === 9 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("SpaceStates") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubLabelSpaceStatesl,
                tabNo: 10,
                active: this.state.activePage === 10 ? true : false,
                display: 'block'
            });
        }
        if (this.state.id !== 0 && rights && rights.ADMINPORTAL && match.params.filter === "Building" && (rights.ADMINPORTAL.PortfolioSetup.indexOf("BuildingKPIs") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1))
        {
            temp.push({
                title: this.labels.HubTabBuildingKPIs,
                tabNo: 11,
                active: this.state.activePage === 11 ? true : false,
                display: 'block'
            });
        }
        // if (this.state.id !== 0 && rights && rights.ADMINPORTAL && (this.state.filter === "Building" || match.params.filter !== "Zone") && (rights.ADMINPORTAL.PortfolioSetup.indexOf("Signage") > -1 || rights.ADMINPORTAL.PortfolioSetup.indexOf("Spaces/*") > -1)) {
        //   temp.push({
        //     title: this.labels.HubLabelSignage,
        //     tabNo: 12,
        //     active: this.state.activePage === 12 ? true : false,
        //     display: 'block'
        //   });
        // }

        this.setState({
            allTab: temp
        })
    }

    loadMenu(filter: any)
    {
        if (filter === 'Building')
        {
            this.setState({
                showLevelFields: false,
                showBuildingFields: true,
                externalFlag: false
            })
        } else
        {
            if (filter === 'Floor' || filter === 'Level')
            {
                this.setState({
                    showLevelFields: true,
                    showBuildingFields: false,
                    externalFlag: false
                })
            } else
            {
                if (filter === 'Space')
                {
                    this.setState({
                        showLevelFields: true,
                        showBuildingFields: false,
                        externalFlag: true
                    })
                } else
                {
                    this.setState({
                        showLevelFields: false,
                        showBuildingFields: false,
                        externalFlag: false
                    })
                }

            }
        }
    }

    getDetails(id: any, filter: any)
    {
        this.emptyLocalstorage(filter);
        if (sessionStorage.getItem('showOrganisation') === "true")
        {
            this.setState({
                showOrganisation: true,
                organisationState: sessionStorage.getItem('organisationState'),
                organisationPath: sessionStorage.getItem('organisationPath'),
                organisationId: sessionStorage.getItem('organisationId')
            })
        }

        if (sessionStorage.getItem('showRegion') === "true")
        {
            this.setState({
                showRegion: true,
                regionState: sessionStorage.getItem('regionState'),
                regionPath: sessionStorage.getItem('regionPath'),
                regionId: sessionStorage.getItem('regionId')
            })
        }

        if (sessionStorage.getItem('showBuilding') === "true")
        {
            this.setState({
                showBuilding: true,
                buildingState: sessionStorage.getItem('buildingState'),
                buildingPath: sessionStorage.getItem('buildingPath'),
                buildingId: sessionStorage.getItem('buildingId')
            })
        }
        if (sessionStorage.getItem('showFloor') === "true")
        {
            this.setState({
                showFloor: true,
                floorState: sessionStorage.getItem('floorState'),
                floorPath: sessionStorage.getItem('floorPath'),
                floorId: sessionStorage.getItem('floorId')
            })
        }
        if (sessionStorage.getItem('showZone') === "true")
        {
            this.setState({
                showZone: true,
                zoneState: sessionStorage.getItem('zoneState'),
                zonePath: sessionStorage.getItem('zonePath'),
                zoneId: sessionStorage.getItem('zoneId')
            })
        }
        if (sessionStorage.getItem('showRoom') === "true")
        {
            this.setState({
                showRoom: true,
                roomState: sessionStorage.getItem('roomState'),
                roomPath: sessionStorage.getItem('roomPath'),
                roomId: sessionStorage.getItem('roomId')
            })
        }
        if (sessionStorage.getItem('showDesk') === "true")
        {
            this.setState({
                showDesk: true,
                deskState: sessionStorage.getItem('deskState'),
                deskPath: sessionStorage.getItem('deskPath'),
                deskId: sessionStorage.getItem('deskId')
            })
        }

        if (filter === "Root")
        {
            this.setState({
                showOrganisation: false,
                organisationState: "",
                organisationPath: "",
                organisationId: null
            })
        }

        if (filter === "Region")
        {
            this.setState({
                showRegion: false,
                regionState: "",
                regionPath: "",
                regionId: null
            })
        }

        if (filter === "Building")
        {
            this.setState({
                showBuilding: false,
                buildingState: "",
                buildingPath: "",
                buildingId: null
            })
        }

        if (filter === "Floor")
        {
            this.setState({
                showFloor: false,
                floorState: "",
                floorPath: "",
                floorId: null
            })
        }

        if (filter === "Zone")
        {
            this.setState({
                showZone: false,
                zoneState: "",
                zonePath: "",
                zoneId: null,
            })
        }

        if (filter === "Space")
        {
            this.setState({
                showRoom: false,
                roomState: "",
                roomPath: "",
                roomId: null,
            })
        }

        if (filter === "Desk")
        {
            this.setState({
                showDesk: false,
                deskState: "",
                deskPath: "",
                deskId: null
            })
        }

        this.loadMenu(filter);
        apis.getSpacesById(id, filter).then((res) =>
        {
            if (res.data.parameters)
            {
                res.data.parameters = convertApiResponseObjectToLower(res.data.parameters)
            }
            this.setState({
                ...res.data.parameters,
                nameHeader: res.data.name,
                personCapacity: res.data.parameters.space_Capacity,
                loading: false
            }, () =>
            {
                if (filter === "Root")
                {
                    sessionStorage.setItem('showOrganisation', "true");
                    sessionStorage.setItem('organisationState', this.state.nameHeader);
                    sessionStorage.setItem('organisationPath', '/portfolio-setup-spaces/edit/' + id + '/Root');
                    sessionStorage.setItem('organisationId', id);
                }
                if (filter === "Region")
                {
                    sessionStorage.setItem('showRegion', "true");
                    sessionStorage.setItem('regionState', this.state.nameHeader);
                    sessionStorage.setItem('regionPath', '/portfolio-setup-spaces/edit/' + id + '/Region');
                    sessionStorage.setItem('regionId', id);
                }
                if (filter === "Building")
                {
                    sessionStorage.setItem('showBuilding', "true");
                    sessionStorage.setItem('buildingState', this.state.nameHeader);
                    sessionStorage.setItem('buildingPath', '/portfolio-setup-spaces/edit/' + id + '/Building');
                    sessionStorage.setItem('buildingId', id);
                }
                if (filter === "Floor")
                {
                    sessionStorage.setItem('showFloor', "true");
                    sessionStorage.setItem('floorState', this.state.nameHeader);
                    sessionStorage.setItem('floorPath', '/portfolio-setup-spaces/edit/' + id + '/Floor');
                    sessionStorage.setItem('floorId', id);
                }
                if (filter === "Zone")
                {
                    sessionStorage.setItem('showZone', "true");
                    sessionStorage.setItem('zoneState', this.state.nameHeader);
                    sessionStorage.setItem('zonePath', '/portfolio-setup-spaces/edit/' + id + '/Zone');
                    sessionStorage.setItem('floorId', id);
                }
                if (filter === "Space")
                {
                    sessionStorage.setItem('showRoom', "true");
                    sessionStorage.setItem('roomState', this.state.nameHeader);
                    sessionStorage.setItem('roomPath', '/portfolio-setup-spaces/edit/' + id + '/Spaces');
                    sessionStorage.setItem('roomId', id);
                }
                if (filter === "Desk")
                {
                    sessionStorage.setItem('showDesk', "true");
                    sessionStorage.setItem('deskState', this.state.nameHeader);
                    sessionStorage.setItem('deskPath', '/portfolio-setup-spaces/edit/' + id + '/Desk');
                    sessionStorage.setItem('deskId', id);
                }

            })
            this.setState({ ...res.data })
            // if (this.state.reload) {
            if (filter === "Root")
            {
                this.setState({
                    organisationState: res.data.name,
                    organisationPath: '/portfolio-setup-spaces/edit/' + id + '/Root',
                    organisationId: id
                })
            }
            if (filter === "Region")
            {
                this.setState({
                    regionState: res.data.name,
                    regionPath: '/portfolio-setup-spaces/edit/' + id + '/Region',
                    regionId: id,
                    showOrganisation: true
                })
            }
            if (filter === "Building")
            {
                this.setState({
                    buildingState: res.data.name,
                    buildingPath: "/portfolio-setup-spaces/edit/" + id + "/Building",
                    buildingId: id,
                    showRegion: true
                })
            }
            if (filter === "Floor")
            {
                this.setState({
                    floorState: res.data.name,
                    floorPath: "/portfolio-setup-spaces/edit/" + id + "/Floor",
                    floorId: id,
                    showBuilding: true
                })
            }
            if (filter === "Zone")
            {
                this.setState({
                    zoneState: res.data.name,
                    zonePath: "/portfolio-setup-spaces/edit/" + id + "/Zone",
                    zoneId: id,
                    showFloor: true
                })
            }
            if (filter === "Space")
            {
                this.setState({
                    roomState: res.data.name,
                    roomPath: "/portfolio-setup-spaces/edit/" + id + "/Spaces",
                    roomId: id,
                    showZone: true
                })
            }
            if (filter === "Desk")
            {
                this.setState({
                    deskState: res.data.name,
                    deskPath: "/portfolio-setup-spaces/edit/" + id + "/Desk",
                    deskId: id,
                    showRoom: true
                })
            }
            // }

        }).catch((error: any) => { });

        apis.getSpacesByIdIsPartOf(id, filter).then((res) =>
        {
            if (res.data.length === 0)
            {
                this.setState({
                    spacesIsPartOf: res.data,
                    isPartOfMessage: this.labels.HublabelErrorEmptyData
                });
            } else
            {
                if (filter === "Zone" || filter === "Space")
                {
                    let tempData = [];
                    tempData.push(res.data);
                    this.setState({
                        ...res.data.parameters, //check
                        levelNumber: res.data.levelNumber,
                        mapURI: res.data.mapURI,
                        // personCapacity:res.data.Space_Capacity,
                        area: res.data.area,
                        spacesIsPartOf: tempData,
                    });
                } else
                {
                    this.setState({
                        ...res.data.parameters, //check
                        levelNumber: res.data.levelNumber,
                        mapURI: res.data.mapURI,
                        // personCapacity:res.data.Space_Capacity,
                        spacesIsPartOf: res.data,
                    });
                }
            }

        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        isPartOfMessage: errorMessage
                    }, () =>
                    {
                        this.isPartOfEmptyTable.message = this.state.isPartOfMessage;
                    })
                }
            } else
            {
                this.setState({
                    isPartOfMessage: errorMessage
                }, () =>
                {
                    this.isPartOfEmptyTable.message = this.state.isPartOfMessage;
                })
            }
        });

        // apis.getSpacesByIdAgents(id).then((res) => {
        //   if (res.data.length === 0) {
        //     this.setState({
        //       spacesAgents: res.data,
        //       agentsMessage: this.labels.HublabelErrorEmptyData
        //     });
        //   } else {
        //     this.setState({ spacesAgents: res.data });
        //   }

        // }).catch((error: any) => {
        //   let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
        //   if (error.response) {
        //     if (error.response.statusText) {
        //       errorMessage = error.response.statusText
        //       this.setState({
        //         agentsMessage: errorMessage
        //       }, () => {
        //         this.agentsEmptyTable.message = this.state.agentsMessage;
        //       })
        //     }
        //   } else {
        //     this.setState({
        //       agentsMessage: errorMessage
        //     }, () => {
        //       this.agentsEmptyTable.message = this.state.agentsMessage;
        //     })
        //   }
        // });

        apis.getSpacesByIdHasPart(id, filter).then((res) =>
        {
            if (res.data.length === 0)
            {
                this.setState({
                    spacesHasPart: res.data,
                    hasPartMessage: this.labels.HublabelErrorEmptyData
                });
            } else
            {
                this.setState({ spacesHasPart: res.data });
            }

        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        hasPartMessage: errorMessage
                    }, () =>
                    {
                        this.hasPartEmptyTable.message = this.state.hasPartMessage;
                    })
                }
            } else
            {
                this.setState({
                    hasPartMessage: errorMessage
                }, () =>
                {
                    this.hasPartEmptyTable.message = this.state.hasPartMessage;
                })
            }
        });

        apis.getSpacesByParameters(id).then((res) =>
        {
            if (res.data.length === 0)
            {
                this.setState({
                    spacesParameters: res.data,
                    parameterMessage: this.labels.HublabelErrorEmptyData
                });
            } else
            {
                this.setState({ spacesParameters: res.data });
            }

        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        parameterMessage: errorMessage
                    })
                }
            } else
            {
                this.setState({
                    parameterMessage: errorMessage
                })
            }
        });

        apis.getDevices(id).then((res) =>
        {
            if (res.data.length === 0)
            {
                this.setState({
                    spacesDevices: res.data,
                    devicesMessage: this.labels.HublabelErrorEmptyData
                });
            } else
            {
                this.setState({ spacesDevices: res.data });
            }

        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        devicesMessage: errorMessage
                    }, () =>
                    {
                        this.devicesEmptyTable.message = this.state.devicesMessage;
                    })
                }
            } else
            {
                this.setState({
                    devicesMessage: errorMessage
                }, () =>
                {
                    this.devicesEmptyTable.message = this.state.devicesMessage;;
                })
            }
        });

        if (filter === 'Building')
        {


            apis.getTaskCategories(id).then((res) =>
            {
                if (res.data.length === 0)
                {
                    this.setState({
                        spacesTaskCategories: res.data,
                        taskCategoriesMessage: this.labels.HublabelErrorEmptyData
                    });
                } else
                {
                    this.setState({ spacesTaskCategories: res.data });
                }

            }).catch((error: any) =>
            {
                let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            taskCategoriesMessage: errorMessage
                        }, () =>
                        {
                            this.taskCategoriesEmptyTable.message = this.state.taskCategoriesMessage;
                        })
                    }
                } else
                {
                    this.setState({
                        taskCategoriesMessage: errorMessage
                    }, () =>
                    {
                        this.taskCategoriesEmptyTable.message = this.state.taskCategoriesMessage;
                    })
                }
            });

            apis.getTaskStates(id).then((res) =>
            {
                if (res.data.length === 0)
                {
                    this.setState({
                        spacesTaskStates: res.data,
                        taskStatesMessage: this.labels.HublabelErrorEmptyData
                    });
                } else
                {
                    this.setState({ spacesTaskStates: res.data });
                }

            }).catch((error: any) =>
            {
                let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            taskStatesMessage: errorMessage
                        }, () =>
                        {
                            this.taskStatesEmptyTable.message = this.state.taskStatesMessage;
                        })
                    }
                } else
                {
                    this.setState({
                        taskStatesMessage: errorMessage
                    }, () =>
                    {
                        this.taskStatesEmptyTable.message = this.state.taskStatesMessage;
                    })
                }
            });

            apis.getSpaceStates(id).then((res) =>
            {
                if (res.data.length === 0)
                {
                    this.setState({
                        spacesSpaceStates: res.data,
                        spaceStatesMessage: this.labels.HublabelErrorEmptyData
                    });
                } else
                {
                    this.setState({ spacesSpaceStates: res.data });
                }

            }).catch((error: any) =>
            {
                let errorMessage = "Something went Space wrong!"
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            spaceStatesMessage: errorMessage
                        }, () =>
                        {
                            this.spaceStatesEmptyTable.message = this.state.spaceStatesMessage;
                        });
                    }
                } else
                {
                    this.setState({
                        spaceStatesMessage: errorMessage
                    }, () =>
                    {
                        this.spaceStatesEmptyTable.message = this.state.spaceStatesMessage;
                    });
                }
            });

            apis.getTaskTypes(id).then((res) =>
            {
                if (res.data.length === 0)
                {
                    this.setState({
                        spacesTaskTypes: res.data,
                        taskTypesMessage: this.labels.HublabelErrorEmptyData
                    });
                } else
                {
                    this.setState({ spacesTaskTypes: res.data });
                }

            }).catch((error: any) =>
            {
                let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            taskTypesMessage: errorMessage
                        }, () =>
                        {
                            this.taskTypesEmptyTable.message = this.state.taskTypesMessage;
                        })
                    }
                } else
                {
                    this.setState({
                        taskTypesMessage: errorMessage
                    }, () =>
                    {
                        this.taskTypesEmptyTable.message = this.state.taskTypesMessage;
                    })
                }
            });

            apis.getKPIs(id).then((res) =>
            {
                if (res.data.length === 0)
                {
                    this.setState({
                        spacesKPIs: res.data,
                        KPIsMessage: this.labels.HublabelErrorEmptyData
                    });
                } else
                {
                    const newData = res.data.map((item: any) => convertApiResponseObjectToLower(item))
                    this.setState({ spacesKPIs: newData });
                }

            }).catch((error: any) =>
            {
                let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            KPIsMessage: errorMessage
                        }, () =>
                        {
                            this.KPIsEmptyTable.message = this.state.KPIsMessage;
                        })
                    }
                } else
                {
                    this.setState({
                        KPIsMessage: errorMessage
                    }, () =>
                    {
                        this.KPIsEmptyTable.message = this.state.KPIsMessage;
                    })
                }
            });

            apis.getSignages(id).then((res) =>
            {
                try
                {
                    if (res.data.length === 0 || JSON.parse(res.data))
                    {
                        this.setState({
                            spacesSignages: [],
                            signagesMessage: this.labels.HublabelErrorEmptyData
                        });
                    } else
                    {
                        this.setState({ spacesSignages: res.data });
                    }
                } catch (e)
                {
                    this.setState({
                        spacesSignages: [],
                        signagesMessage: this.labels.HublabelErrorEmptyData
                    });
                }

            }).catch((error: any) =>
            {
                let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
                if (error.response)
                {
                    if (error.response.statusText)
                    {
                        errorMessage = error.response.statusText
                        this.setState({
                            signagesMessage: errorMessage
                        }, () =>
                        {
                            this.signagesEmptyTable.message = this.state.signagesMessage;
                        })
                    }
                } else
                {
                    this.setState({
                        KPIsMessage: errorMessage
                    }, () =>
                    {
                        this.signagesEmptyTable.message = this.state.signagesMessage;
                    })
                }
            });


        }
    }


    setChecked = (e: any) =>
    {
        this.setState({ updates: true })
        if (e.target.name === 'isEnabled' && this.state.isEnabled === 0)
        {
            this.setState({
                isEnabled: 1
            })
        }
        if (e.target.name === 'isEnabled' && this.state.isEnabled === 1)
        {
            this.setState({
                isEnabled: 0
            })
        }

        if (e.target.name === 'isSearchable' && this.state.isSearchable === 0)
        {
            this.setState({
                isSearchable: 1
            })
        }
        if (e.target.name === 'isSearchable' && this.state.isSearchable === 1)
        {
            this.setState({
                isSearchable: 0
            })
        }

    };

    change = (e: any) =>
    {
        this.setState({ updates: true })
        // @ts-ignore
        this.setState({ [e.target.name]: e.target.value });
    };

    submit = () =>
    {
        this.setState({ isLoadingToSave: true });
        const { match }: any = this.props;
        const id = match.params.id;
        const { updates, isEnabled, isPartOf, isSearchable, name, levelNumber, grossArea,
            personCapacity, type, code, area, city, longitude, latitude, address, imageURI, space_Custom_Info, country, meta_Loc_Y_m, meta_Loc_X_m, mapURI, concurrencyStamp }: any = this.state;

        let payload;

        if (match.params.id === "0")
        {
            payload = {
                "name": name,
                "code": code,
                "type": type,
                "city": city,
                "address": address,
                "longitude": parseFloat(longitude),
                "latitude": parseFloat(latitude),
                "personCapacity": parseInt(personCapacity),
                "grossArea": parseInt(area),
                "isSearchable": isSearchable,
                "levelNumber": levelNumber,
                "isPartOf": parseInt(isPartOf),
                "ownedBy": "",
                "operatedBy": "",
                "mapUri": mapURI,
                "imageURI": imageURI,
                "space_Custom_Info": space_Custom_Info,
                "country": country,
                "meta_Loc_Y_m": meta_Loc_Y_m,
                "meta_Loc_X_m": meta_Loc_X_m
            };
            this.create(payload);
        } else
        {
            payload = {
                "concurrencyStamp": concurrencyStamp,
                "name": name,
                "code": code,
                "type": type,
                "city": city,
                "longitude": parseFloat(longitude),
                "latitude": parseFloat(latitude),
                "address": address,
                "levelNumber": levelNumber,
                "personCapacity": parseInt(personCapacity),
                "grossArea": parseInt(area),
                "isSearchable": isSearchable,
                "isEnabled": isEnabled,
                "isPartOf": parseInt(isPartOf),
                "ownedBy": "",
                "operatedBy": "",
                "mapUri": mapURI,
                "imageURI": imageURI,
                "space_Custom_Info": space_Custom_Info,
                "country": country,
                "meta_Loc_Y_m": meta_Loc_Y_m,
                "meta_Loc_X_m": meta_Loc_X_m
            };
            this.update(payload);
        }
    };

    create = (payload: any) =>
    {
        const { history }: any = this.props;
        const { filter }: any = this.state;
        apis.createSpace(payload).then((res) =>
        {
            this.setState({ isLoadingToSave: false })
            history.push("/portfolio-setup-spaces/filter=" + filter);
        }).catch((error: any) =>
        {
            this.setState({ isLoadingToSave: false })
        });
    }

    update = (payload: any) =>
    {
        const { match, history }: any = this.props;
        const { updates, filter }: any = this.state;
        const id = match.params.id;
        if (updates)
        {
            apis.updateSpacesById(id, payload).then(() =>
            {
                this.setState({ isLoadingToSave: false })
                history.push("/portfolio-setup-spaces/filter=" + filter);
            }).catch((error: any) =>
            {
                this.setState({ isLoadingToSave: false })
            });
        } else
        {
            this.setState({ isLoadingToSave: false })
            history.push("/portfolio-setup-spaces/filter=" + filter);
        }
    };

    isDelete = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
        this.delete();
    }

    cancelDeleteModal = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
    }

    delete = () =>
    {
        const { filter } = this.state;
        const { match }: any = this.props;
        const id = match.params.id;
        if (this.state.isDelete)
        {
            apis.deleteSpacesById(id).then((res) =>
            {
                const { history }: any = this.props;
                history.push("/portfolio-setup-spaces/filter=" + filter);
            }).catch((error: any) => { });
        }
    };
    handleModal = () =>
    {
        this.setState({
            show: !this.state.show,
        })
    }

    cancel = () =>
    {
        const { updates, filter } = this.state
        const { history }: any = this.props;
        if (updates)
        {
            this.handleModal();
        } else
        {
            history.push("/portfolio-setup-spaces/filter=" + filter);
        }
    }

    emptyLocalstorage = (filter: any) =>
    {
        if (filter === "Root")
        {
            sessionStorage.setItem('showOrganisation', "");
            sessionStorage.setItem('organisationState', "");
            sessionStorage.setItem('organisationPath', '');
            sessionStorage.setItem('organisationId', "");
        }
        if (filter === "Root" || filter === "Region")
        {
            sessionStorage.setItem('showRegion', "");
            sessionStorage.setItem('regionState', "");
            sessionStorage.setItem('regionPath', '');
            sessionStorage.setItem('regionId', "");
        }
        if (filter === "Root" || filter === "Region" || filter === "Building")
        {
            sessionStorage.setItem('showBuilding', "");
            sessionStorage.setItem('buildingState', "");
            sessionStorage.setItem('buildingPath', '');
            sessionStorage.setItem('buildingId', "");
        }
        if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor")
        {
            sessionStorage.setItem('showFloor', "");
            sessionStorage.setItem('floorState', "");
            sessionStorage.setItem('floorPath', '');
            sessionStorage.setItem('floorId', "");
        }
        if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone")
        {
            sessionStorage.setItem('showZone', "");
            sessionStorage.setItem('zoneState', "");
            sessionStorage.setItem('zonePath', '');
            sessionStorage.setItem('floorId', "");
        }
        if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Space")
        {
            sessionStorage.setItem('showRoom', "");
            sessionStorage.setItem('roomState', "");
            sessionStorage.setItem('roomPath', '');
            sessionStorage.setItem('roomId', "");
        }
        if (filter === "Root" || filter === "Region" || filter === "Building" || filter === "Floor" || filter === "Zone" || filter === "Space" || filter === "Desk")
        {
            sessionStorage.setItem('showDesk', "");
            sessionStorage.setItem('deskState', "");
            sessionStorage.setItem('deskPath', '');
            sessionStorage.setItem('deskId', "");
        }
    }

    linkPath = (data: any) =>
    {
        const { filter } = this.state
        const { history }: any = this.props;

        if (data === "Root")
        {
            this.setState({
                showOrganisation: false,
                showRegion: false,
                showBuilding: false,
                showFloor: false,
                showZone: false,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Root");
                window.open("/portfolio-setup-spaces/edit/" + this.state.organisationId + "/Root");
                this.getDetails(this.state.organisationId, 'Root');
            })
        }

        if (data === "Region")
        {
            this.setState({
                showOrganisation: true,
                showRegion: false,
                showBuilding: false,
                showFloor: false,
                showZone: false,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Region");
                window.open("/portfolio-setup-spaces/edit/" + this.state.regionId + "/Region");
                this.getDetails(this.state.regionId, 'Region');
            })

        }

        if (data === "Building")
        {
            this.setState({
                showOrganisation: true,
                showRegion: true,
                showBuilding: false,
                showFloor: false,
                showZone: false,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Building");
                window.open("/portfolio-setup-spaces/edit/" + this.state.buildingId + "/Building");
                this.getDetails(this.state.buildingId, 'Building');
            })

        }

        if (data === "Floor")
        {
            this.setState({
                showOrganisation: true,
                showRegion: true,
                showBuilding: true,
                showFloor: false,
                showZone: false,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Floor");
                window.open("/portfolio-setup-spaces/edit/" + this.state.floorId + "/Floor");
                this.getDetails(this.state.floorId, 'Floor');
            })

        }

        if (data === "Zone")
        {
            this.setState({
                showOrganisation: true,
                showRegion: true,
                showBuilding: true,
                showFloor: true,
                showZone: false,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Zone");
                window.open("/portfolio-setup-spaces/edit/" + this.state.zoneId + "/Zone");
                this.getDetails(this.state.zoneId, 'Zone');
            })
        }

        if (data === "Space")
        {
            this.setState({
                showOrganisation: true,
                showRegion: true,
                showBuilding: true,
                showFloor: true,
                showZone: true,
                showRoom: false,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Space");
                this.getDetails(this.state.roomId, 'Space');
            })
        }

        if (data === "Desk")
        {
            this.setState({
                showOrganisation: true,
                showRegion: true,
                showBuilding: true,
                showFloor: true,
                showZone: true,
                showRoom: true,
                showDesk: false,
                reload: false
            }, () =>
            {
                this.emptyLocalstorage("Desk");
                window.open(this.state.deskPath ?? undefined);
                this.getDetails(this.state.deskId, 'Desk');
            })
        }

    }

    redirect = (path: any, id: any, filter: any) =>
    {
        const { history }: any = this.props;
        const urlToGo = path + id + "/" + filter;
        this.setState({ activePage: 0 })
        this.getDetails(id, filter);
        history.push(urlToGo);
        window.location.reload();
    }

    working = () =>
    {
        alert("Work in Progress")
    }

    reloadPage(filter: any)
    {
        const { history }: any = this.props;
        if (filter === "Root")
        {
            history.push("/portfolio-setup-spaces/edit/0/Region/0");
        }
        if (filter === "Region")
        {
            history.push("/portfolio-setup-spaces/edit/0/Building/0");
        }
        if (filter === "Building")
        {
            history.push("/portfolio-setup-spaces/edit/0/Floor/0");
        }
        if (filter === "Floor")
        {
            history.push("/portfolio-setup-spaces/edit/0/Zone/0");
        }
        if (filter === "Zone")
        {
            history.push("/portfolio-setup-spaces/edit/0/Space/0");
        }
        if (filter === "Space")
        {
            history.push("/portfolio-setup-spaces/edit/0/Desk/0");
        }

        window.location.reload();
    }

    handleParametersClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Parameter/nodeid=" + match.params.id + "/id=" + params.parameter_Id)
    }

    handleDevicesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Device/nodeid=" + match.params.id + `/id=` + params.device_Id)
    }

    handleTaskCategoriesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=Taskcategories/nodeid=" + match.params.id + "/id=" + params.task_Category_Id)
    }

    handleTaskTypesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=Building/TaskTypes/nodeid=" + match.params.id + "/id=" + params.task_Type_Id)
    }

    handleSpaceStatesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=SpaceStates/nodeid=" + this.state.id + "/id=" + params.id)
    }

    handleKPIsClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/BuildingKPI/nodeid=" + match.params.id + "/id=" + params.buildingKPIId)
    }

    handleSignagesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=" + this.state.filter + "/Signage/nodeid=" + match.params.id + "/id=" + params.signage_Id)
    }

    handleTaskStatesClick(params: any)
    {
        const { match, history }: any = this.props;
        history.push("/portfolio-setup-spaces/filter=TaskStates/nodeid=" + this.state.id + "/id=" + params.id)
    }

    render()
    {
        const { match, history }: any = this.props;
        const {
            tableHeading, thClasses, tdClasses, tableHeadParameters, parametersThClasses, parametersTdClasses, showBuildingFields, showLevelFields, externalFlag,
            spacesIsPartOf, spacesHasPart, spacesParameters, nameHeader, filter, levelNumber, tableHeadingTaskTypes, taskTypesthClasses, taskTypestdClasses,
            name, code, mapURI, concurrencyStamp, id, isEnabled, isPartOf, isSearchable, personCapacity, type, area, tableHeadingDevices, grossArea,
            devicesThClasses, devicesTdClasses, tableHeadingTaskCategories, taskCategoriesThClasses, taskCategoriesTdClasses,
            loading, imageURI, space_Custom_Info, country, meta_Loc_Y_m, meta_Loc_X_m, spacesDevices, spacesTaskCategories, spacesSpaceStates, spacesTaskTypes, spacesTaskStates,
            tableHeadingTaskStates, taskStatesThClasses, taskStatesTdClasses, tableHeadingSpaceStates, spaceStatesThClasses, spaceStatesTdClasses,
            spaceStatesMessage, parameterMessage, devicesMessage, hasPartMessage, isPartOfMessage, taskTypesMessage,
            taskCategoriesMessage, taskStatesMessage, spacesAgents, tableHeadingAgents, agentsThClasses, agentsTdClasses, agentsMessage,
            tableHeadingKPIs, KPIsThClasses, KPIsMessage, spacesKPIs, KPIsTdClasses, tableHeadingSignages, signagesThClasses,
            signagesMessage, spacesSignages, signagesTdClasses, space_Type, space_Work_Type, space_Type_Label, meta_Occ_Type, space_Arrival_Loc,
            meta_Bookable, meta_Bookable_Res_Req, meta_Space_DurationMin_min, meta_Space_DurationMax_min, meta_Sol_Thld_Min,
            meta_Clean_Thld_Min, meta_Ext_Occ_System, meta_Ext_Occ_Count_Mode, meta_Ext_Occ_Area_Id, meta_Ext_Occ_Sensor_Id,
            meta_Ext_Booking_System, meta_Ext_Booking_Space_Id, meta_Ext_Catering_System, meta_Ext_Catering_Space_Id, meta_Gtwy_Type,
            meta_Gtwy, space_Light_Mode, space_Light_Scenes, space_AV_Source_Scenes, city, address, longitude, latitude, space_Class }: any = this.state;

        this.signagesEmptyTable = {
            columns: tableHeadingSignages.length,
            thClasses: signagesThClasses,
            heading: tableHeadingSignages,
            style: [],
            message: signagesMessage,
            dashboard: 0,
        };

        this.hasPartEmptyTable = {
            columns: tableHeading.length,
            thClasses: thClasses,
            heading: tableHeading,
            style: [],
            message: hasPartMessage,
            dashboard: 0,
        };

        this.isPartOfEmptyTable = {
            columns: tableHeading.length,
            thClasses: thClasses,
            heading: tableHeading,
            style: [],
            message: isPartOfMessage,
            dashboard: 0,
        };

        this.devicesEmptyTable = {
            columns: tableHeadingDevices.length,
            thClasses: devicesThClasses,
            heading: tableHeadingDevices,
            style: [],
            message: devicesMessage,
            dashboard: 0,
        };

        this.taskTypesEmptyTable = {
            columns: tableHeadingTaskTypes.length,
            thClasses: taskTypesthClasses,
            heading: tableHeadingTaskTypes,
            style: [],
            message: taskTypesMessage,
            dashboard: 0,
        };

        this.taskCategoriesEmptyTable = {
            columns: tableHeadingTaskCategories.length,
            thClasses: taskCategoriesThClasses,
            heading: tableHeadingTaskCategories,
            style: [],
            message: taskCategoriesMessage,
            dashboard: 0,
        };

        this.taskStatesEmptyTable = {
            columns: tableHeadingTaskStates.length,
            thClasses: taskStatesThClasses,
            heading: tableHeadingTaskStates,
            style: [],
            message: taskStatesMessage,
            dashboard: 0,
        };

        this.spaceStatesEmptyTable = {
            columns: tableHeadingSpaceStates.length,
            thClasses: spaceStatesThClasses,
            heading: tableHeadingSpaceStates,
            style: [],
            message: spaceStatesMessage,
            dashboard: 0,
        };

        this.agentsEmptyTable = {
            columns: tableHeadingAgents.length,
            thClasses: agentsThClasses,
            heading: tableHeadingAgents,
            style: [],
            message: agentsMessage,
            dashboard: 0,
        };

        this.KPIsEmptyTable = {
            columns: tableHeadingKPIs.length,
            thClasses: KPIsThClasses,
            heading: tableHeadingKPIs,
            style: [],
            message: KPIsMessage,
            dashboard: 0,
        };

        const nameFld = {
            name: "name",
            label: this.labels.HubLabelName,
            value: name,
            mandatory: true,
            placeholder: this.labels.HubLabelNamePlaceholderText,
            onchange: this.change,
        };

        const codeFld = {
            name: "code",
            label: this.labels.HubLabelCode,
            value: code,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideCode,
            onchange: this.change,
        };

        const idFld = {
            name: "id",
            label: this.labels.HubLabelId,
            value: id,
            placeholder: this.labels.HubLabelProvideId,
            onchange: this.change,
        };
        const confModalData = this.state.isDelete ? {
            name: this.state.name,
            show: this.state.show,
            handleModal: this.cancelDeleteModal,
            okButton: this.delete,
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }
            : {
                name: this.state.name,
                show: this.state.show,
                handleModal: this.handleModal,
                okButton: () => { history.push("/portfolio-setup-spaces/filter=" + this.state.filter) },
                modalHeading: this.labels.HubLabelConfirmation,
                modalMessage: this.labels.HubLabelConfirmCancelMessage,
            }

        const spaceTypeFld = {
            name: "type",
            label: this.labels.HubLabelSpaceType,
            value: type,
            mandatory: true,
            onchange: (value: any) =>
            {
                this.setState({
                    type: value,
                    updates: true
                }, () =>
                {
                    if (value === 'Building')
                    {
                        this.setState({
                            showLevelFields: false,
                            showBuildingFields: true,
                            externalFlag: false,
                            updateFilter: true
                        }, () =>
                        {
                            this.getTabsList();
                        })
                    } else
                    {
                        if (value === 'Floor' || value === 'Level')
                        {
                            this.setState({
                                showLevelFields: true,
                                showBuildingFields: false,
                                externalFlag: false,
                                updateFilter: true
                            }, () =>
                            {
                                this.getTabsList();
                            })
                        } else
                        {
                            if (value === "Zone")
                            {
                                let tempArray = [
                                    { title: "Environmental", value: "Environmental" },
                                    { title: "Spaces", value: "Space" }
                                ];
                                this.setState({
                                    showLevelFields: false,
                                    showBuildingFields: false,
                                    spaceTypeListAll: tempArray,
                                    externalFlag: false,
                                    updateFilter: true
                                }, () =>
                                {
                                    this.getTabsList();
                                    this.setState({
                                        space_Type: ""
                                    })
                                })
                            } else
                            {
                                if (value === "Space")
                                {
                                    this.getSpacetypeList()
                                    this.setState({
                                        showLevelFields: false,
                                        showBuildingFields: false,
                                        externalFlag: true,
                                        updateFilter: false
                                    }, () =>
                                    {
                                        this.setState({
                                            space_Type: ""
                                        })
                                        this.getTabsList();
                                    })
                                } else
                                {
                                    this.setState({
                                        showLevelFields: false,
                                        showBuildingFields: false,
                                        externalFlag: false,
                                        updateFilter: true
                                    }, () =>
                                    {
                                        this.getTabsList();
                                    })
                                }
                            }

                        }
                    }
                });
            },
            type: "option",
            disabled: this.state.id !== 0,
            options: [
                { title: this.labels.HubLabelRoot, value: "Root" },
                { title: this.labels.HubLabelRegion, value: "Region" },
                { title: this.labels.HubLabelLand, value: "Land" },
                { title: this.labels.HubLabelBuilding, value: "Building" },
                { title: this.labels.HubLabellevel, value: "Floor" },
                { title: this.labels.HubLabelZone, value: "Zone" },
                { title: this.labels.HubLabelSpace, value: "Space" },
                { title: this.labels.HubLabelRoom, value: "Room" },
                { title: this.labels.HubLabelDesk, value: "Desk" },
            ],
        };

        const typeFld = {
            name: "space_Type",
            label: "",
            value: space_Type,
            mandatory: true,
            onchange: (value: any) =>
            {
                this.setState({
                    space_Type: value,
                    updates: true
                });
            },
            type: "option",
            disabled: this.state.id !== 0,
            options: this.state.spaceTypeListAll,
            placeholder: this.labels.HubLabelProvidevalue,
        };

        const countryFld = {
            name: "country",
            label: this.labels.HubLabelCountry,
            value: country,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideCountryName,
            onchange: this.change,
        };

        const cityFld = {
            name: "city",
            label: this.labels.HubLabelCity,
            value: city,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideCity,
            onchange: this.change,
        };

        const addressFld = {
            name: "address",
            label: this.labels.HubLabelAddress,
            value: address,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideAddress,
            onchange: this.change,
        };

        const longitudeFld = {
            name: "longitude",
            label: this.labels.HubLabelLongitude,
            value: longitude,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideLongitude,
            onchange: this.change,
        };
        const latitudeFld = {
            name: "latitude",
            label: this.labels.HubLabelLatitude,
            value: latitude,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideLatitude,
            onchange: this.change,
        };

        const spaceWorkTypeFld = {
            name: "space_Work_Type",
            label: this.labels.HubLabelworkType,
            value: space_Work_Type,
            onchange: (value: any) =>
            {
                this.setState({
                    space_Work_Type: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.spaceWorkTypeList
        };

        const spaceClassTypeFld = {
            name: "space_Class",
            label: this.labels.HubLabelSpaceClass,
            value: space_Class,
            onchange: (value: any) =>
            {
                this.setState({
                    space_Class: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.spaceClassTypeList
        };

        const spaceCustomInfoFld = {
            name: "space_Custom_Info",
            label: this.labels.HubLabelCustomInformation,
            value: space_Custom_Info,
            placeholder: this.labels.HubLabelProvideCustomInformation,
            onchange: this.change,
        };
        const imageURIFld = {
            name: "imageURI",
            label: this.labels.HubLabelImageURI,
            value: imageURI,
            placeholder: this.labels.HubLabelImageURIPlaceholder,
            onchange: this.change,
        };

        const metaLocXmFld = {
            name: "meta_Loc_X_m",
            label: this.labels.HubLabelxpositionLabel,
            value: meta_Loc_X_m,
            placeholder: this.labels.HubLabelProvideXposition,
            onchange: this.change,
        };

        const metaLocYmFld = {
            name: "meta_Loc_Y_m",
            label: this.labels.HubLabelyposition,
            value: meta_Loc_Y_m,
            placeholder: this.labels.HubLabelprovideYposition,
            onchange: this.change,
        };

        const levelNumberFld = {
            name: "levelNumber",
            label: this.labels.HubLabelLevelNumber,
            value: levelNumber,
            placeholder: this.labels.HubLabelProvideLevelNumber,
            onchange: this.change,
        };

        const mapUriFld = {
            name: "maprui",
            label: this.labels.HubLabelmapURI,
            value: mapURI,
            placeholder: this.labels.HubLabelProvideMapURI,
            onchange: this.change,
        };

        const capacityFld = {
            name: "personCapacity",
            label: this.labels.HubLabelCapacityMaximumOccupancy,
            value: personCapacity,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideCapacity,
            onchange: this.change,
        };

        const areaFld = {
            name: "area",
            label: this.labels.HubLabelSize,
            value: area.grossArea,
            mandatory: true,
            placeholder: this.labels.HubLabelProvideSize,
            onchange: this.change,
        };

        const metaOccTypeFld = {
            name: "meta_Occ_Type",
            label: this.labels.HubLabelOccupancyType,
            value: meta_Occ_Type,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Occ_Type: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelManual, value: 0 },
                { title: this.labels.HubLabelAutomatic, value: 1 }
            ]
        };

        const meta_BookableFld = {
            name: "meta_Bookable",
            label: this.labels.HubLabelBookable,
            value: meta_Bookable,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Bookable: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelNotBookable, value: 0 },
                { title: this.labels.HubLabelBookable, value: 1 },
                { title: this.labels.HubLabelReservable, value: 2 },
                { title: this.labels.HubLabelAlldayBookingsOnly, value: 3 }
            ]
        };

        const meta_Space_DurationMin_MinFld = {
            name: "meta_Space_DurationMin_min",
            label: this.labels.HubLabelMinBookingDurationminutes,
            value: meta_Space_DurationMin_min,
            placeholder: this.labels.HubLabelMinBookingDuration,
            onchange: this.change,
        };

        const meta_Space_DurationMax_MinFld = {
            name: "meta_Space_DurationMax_min",
            label: this.labels.HubLabelMaxBookingDurationminutes,
            value: meta_Space_DurationMax_min,
            placeholder: this.labels.HubLabelMaxBookingDuration,
            onchange: this.change,
        };

        const meta_Sol_Thld_MinFld = {
            name: "meta_Sol_Thld_Min",
            label: this.labels.HubLabelSignsofLifeThresholdminutes,
            value: meta_Sol_Thld_Min,
            placeholder: this.labels.HubLabelSignsofLifeThreshold,
            onchange: this.change,
        };

        const meta_Clean_Thld_MinFld = {
            name: "meta_Clean_Thld_Min",
            label: this.labels.HubLabelCleanThresholdminutes,
            value: meta_Clean_Thld_Min,
            placeholder: this.labels.HubLabelCleanThreshold,
            onchange: this.change,
        };

        const meta_Ext_Occ_SystemFld = {
            name: "meta_Ext_Occ_System",
            label: this.labels.HubLabelOccupancySystem,
            value: meta_Ext_Occ_System,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Ext_Occ_System: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelNone, value: 0 },
                { title: this.labels.HubLabelCogniPointRESTJSON, value: 1 },
                { title: this.labels.HubLabelCogniPointWebhook, value: 2 },
                { title: this.labels.HubLabelVergesenseRESTJSON, value: 3 },
                { title: this.labels.HubLabelVergesenseWebhook, value: 4 },
                { title: this.labels.HubLabelSyneticaMQTT, value: 5 },
                { title: this.labels.HubLabelgENESIS64WebhookLabel, value: 6 }
            ]
        };

        const meta_Ext_Occ_Count_ModeFld = {
            name: "meta_Ext_Occ_Count_Mode",
            label: this.labels.HubLabelOccupancyCountMode,
            value: meta_Ext_Occ_Count_Mode,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Ext_Occ_Count_Mode: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelPresence, value: 0 },
                { title: this.labels.HubLabelTrafficLine, value: 1 },
            ]
        };

        const meta_Ext_Occ_Area_IdFld = {
            name: "meta_Ext_Occ_Area_Id",
            label: this.labels.HubLabelareaId,
            value: meta_Ext_Occ_Area_Id,
            placeholder: this.labels.HubLabelProvideAreaId,
            onchange: this.change,
        };

        const meta_Ext_Occ_Sensor_IdFld = {
            name: "meta_Ext_Occ_Sensor_Id",
            label: this.labels.HubLabelSensorId,
            value: meta_Ext_Occ_Sensor_Id,
            placeholder: this.labels.HubLabelProvideSensorId,
            onchange: this.change,
        };

        const meta_Ext_Booking_SystemFld = {
            name: "meta_Ext_Booking_System",
            label: this.labels.HubLabelextBookingSystemLabel,
            value: meta_Ext_Booking_System,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Ext_Booking_System: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelNone, value: 0 },
                { title: this.labels.HubLabelOffice365, value: 1 },
                { title: this.labels.HubLabelCondeco, value: 5 },
            ]
        };

        const meta_Ext_Booking_Space_IdFld = {
            name: "meta_Ext_Booking_Space_Id",
            label: this.labels.HubLabelBookingSystemSpace,
            value: meta_Ext_Booking_Space_Id,
            placeholder: this.labels.HubLabelProvideBookingSystemSpace,
            onchange: this.change,
        };

        const meta_Ext_Catering_SystemFld = {
            name: "meta_Ext_Catering_System",
            label: this.labels.HubLabelextCateringSystem,
            value: meta_Ext_Catering_System,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Ext_Catering_System: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelNone, value: 0 }
            ]
        };

        const meta_Ext_Catering_Space_IdFld = {
            name: "meta_Ext_Catering_Space_Id",
            label: this.labels.HubLabelCateringSystemSpace,
            value: meta_Ext_Catering_Space_Id,
            placeholder: this.labels.HubLabelProvideCateringSystemSpace,
            onchange: this.change,
        };

        const meta_Gtwy_TypeFld = {
            name: "meta_Gtwy_Type",
            label: this.labels.HubLabelGatewayMode,
            value: meta_Gtwy_Type,
            onchange: (value: any) =>
            {
                this.setState({
                    meta_Gtwy_Type: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelNone, value: 0 },
                { title: this.labels.HubLabelgENESIS64, value: 1 }
            ]
        };

        const meta_GtwyFld = {
            name: "meta_Gtwy",
            label: this.labels.HubLabelGateway,
            value: meta_Gtwy,
            placeholder: this.labels.HubLabelProvideGatewayl,
            onchange: this.change,
        };

        const space_Light_ModeFld = {
            name: "space_Light_Mode",
            label: this.labels.HubLabelLightControlMode,
            value: space_Light_Mode,
            onchange: (value: any) =>
            {
                this.setState({
                    space_Light_Mode: value,
                    updates: true
                });
            },
            type: "option",
            options: [
                { title: this.labels.HubLabelValues, value: 0 },
                { title: this.labels.HubLabelScenes, value: 1 }
            ]
        };

        const space_Light_ScenesFld = {
            name: "space_Light_Scenes",
            label: this.labels.HubLabelLightScenes,
            value: space_Light_Scenes,
            placeholder: this.labels.HubLabelProvideLightScenes,
            onchange: this.change,
        };

        const space_AV_Source_ScenesFld = {
            name: "space_AV_Source_Scenes",
            label: this.labels.HubLabelAVSourceScenes,
            value: space_AV_Source_Scenes,
            placeholder: this.labels.HubLabelProvideAVSourceScenes,
            onchange: this.change,
        };

        const spaceTypeLabelFld = {
            name: "space_Type_Label",
            label: this.labels.HubLabelSpaceTypeLabel,
            value: space_Type_Label,
            placeholder: this.labels.HubLabelProvideSpaceTypeLabel,
            onchange: this.change,
        };

        let rowDataAgents: any = [];
        for (let j = 0; j < spacesAgents.length; j++)
        {
            let params = spacesAgents[j];
            spacesAgents[j] = {
                ...spacesAgents[j],
                clickEvent: () => this.working(),
                'Option': <button className="btn btn-success" onClick={() => this.working()} >Edit </button>
            }
            // rowDataAgents.push([
            //   spacesAgents[j].name,
            //   spacesAgents[j].role,
            //   <div className="button-right">
            //     <button className="button-outline" onClick={() => this.working()}>{this.labels.HubButtonEdit}</button></div>
            // ])
        }

        const dataAgents = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "role",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
            ],
            rows: spacesAgents
        };

        const dataAgentsEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "role",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
            ],
            rows: []
        };

        let agentsTable: any = {};
        if (rowDataAgents.length !== 0)
        {
            agentsTable = {
                columns: tableHeadingAgents.length,
                thClasses: agentsThClasses,
                tdClasses: agentsTdClasses,
                rows: rowDataAgents.length,
                data: rowDataAgents,
                heading: tableHeading,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataIsPartOf: any = [];
        for (let j = 0; j < spacesIsPartOf.length; j++)
        {
            let params = spacesIsPartOf[j];
            spacesIsPartOf[j] = {
                ...spacesIsPartOf[j],
                clickEvent: () => this.redirect('/portfolio-setup-spaces/edit/', spacesIsPartOf[j].id, spacesIsPartOf[j].type),
                'grossArea': spacesIsPartOf[j].area.grossArea,
                'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                    defaultChecked={spacesIsPartOf[j].isEnabled === 1 ? true : false} />,
                'Option': <button className="btn btn-success" onClick={() => this.redirect('/portfolio-setup-spaces/edit/', spacesIsPartOf[j].id, spacesIsPartOf[j].type)} >Edit </button>
            }
            // rowDataIsPartOf.push([
            //   spacesIsPartOf[j].name,
            //   spacesIsPartOf[j].code,
            //   spacesIsPartOf[j].type,
            //   spacesIsPartOf[j].personCapacity,
            //   spacesIsPartOf[j].area.grossArea,
            //   <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
            //     defaultChecked={spacesIsPartOf[j].isEnabled === 1 ? true : false} />,
            //   <div className="button-right">
            //     <button className="button-outline" onClick={() => { this.redirect('/portfolio-setup-spaces/edit/', spacesIsPartOf[j].id, spacesIsPartOf[j].type) }}>{this.labels.HubButtonEdit}</button></div>
            // ])
        }


        const dataIsPartOf = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "code",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCapacity,
                    field: "personCapacity",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelSize,
                    field: "grossArea",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelActive,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesIsPartOf
        };

        const dataIsPartOfEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "code",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCapacity,
                    field: "personCapacity",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelSize,
                    field: "grossArea",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelActive,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                },
            ],
            rows: []
        };

        let isPartOfTable: any = {};
        if (rowDataIsPartOf.length !== 0)
        {
            isPartOfTable = {
                columns: tableHeading.length,
                thClasses: thClasses,
                tdClasses: tdClasses,
                rows: rowDataIsPartOf.length,
                data: rowDataIsPartOf,
                heading: tableHeading,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataHasPart: any = [];
        for (let j = 0; j < spacesHasPart.length; j++)
        {
            let params = spacesHasPart[j];
            if (spacesHasPart[j].type === "Level")
            {
                spacesHasPart[j].type = "Floor"
                spacesHasPart[j] = {
                    ...spacesHasPart[j],
                    clickEvent: () => this.redirect('/portfolio-setup-spaces/edit/', spacesHasPart[j].id, spacesHasPart[j].type),
                    'grossArea': spacesHasPart[j].area.grossArea,
                    'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                        defaultChecked={spacesHasPart[j].isEnabled === 1 ? true : false} />,
                    'Option': <button className="btn btn-success" onClick={() => this.redirect('/portfolio-setup-spaces/edit/', spacesHasPart[j].id, spacesHasPart[j].type)} >Edit </button>
                }
            } else
            {
                spacesHasPart[j] = {
                    ...spacesHasPart[j],
                    clickEvent: () => this.redirect('/portfolio-setup-spaces/edit/', spacesHasPart[j].id, spacesHasPart[j].type),
                    'grossArea': spacesHasPart[j].area.grossArea,
                    'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                        defaultChecked={spacesHasPart[j].isEnabled === 1 ? true : false} />,
                    'Option': <button className="btn btn-success" onClick={() => this.redirect('/portfolio-setup-spaces/edit/', spacesHasPart[j].id, spacesHasPart[j].type)} >Edit </button>
                }
            }

            // rowDataHasPart.push([
            //   spacesHasPart[j].name,
            //   spacesHasPart[j].code,
            //   spacesHasPart[j].type,
            //   spacesHasPart[j].personCapacity,
            //   spacesHasPart[j].area.grossArea,
            //   <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
            //     defaultChecked={spacesHasPart[j].isEnabled === 1 ? true : false} />,
            //   <div className="button-right">
            //     <button className="button-outline" onClick={() => { this.redirect('/portfolio-setup-spaces/edit/', spacesHasPart[j].id, spacesHasPart[j].type) }}>{this.labels.HubButtonEdit}</button></div>
            // ])
        }

        const dataHasPart = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "code",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCapacity,
                    field: "personCapacity",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelSize,
                    field: "grossArea",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelActiveRoles,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesHasPart
        };

        const dataHasPartEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCode,
                    field: "code",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCapacity,
                    field: "personCapacity",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelSize,
                    field: "grossArea",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelActiveRoles,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        // let hasPartTable: any = {};
        // if (rowDataHasPart.length !== 0) {
        //   hasPartTable = {
        //     columns: tableHeading.length,
        //     thClasses: thClasses,
        //     tdClasses: tdClasses,
        //     rows: rowDataHasPart.length,
        //     data: rowDataHasPart,
        //     heading: tableHeading,
        //     style: [],
        //     dashboard: 0,
        //   };
        // }

        for (let j = 0; j < spacesParameters.length; j++)
        {
            let params = spacesParameters[j];
            spacesParameters[j] = {
                ...spacesParameters[j],
                clickEvent: () => this.handleParametersClick(params),
                'val': spacesParameters[j].value + " " + spacesParameters[j].units,
                'Option': <button className="btn btn-success" onClick={() => this.handleParametersClick(params)} >Edit </button>
            }
        }

        const dataParameters = {
            columns: [
                {
                    label: this.labels.HubLabelSection,
                    field: "section",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelLabel,
                    field: "label",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelValue,
                    field: "val",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesParameters
        };

        const dataParametersEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelSection,
                    field: "section",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelLabel,
                    field: "label",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelValue,
                    field: "val",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let rowDataspacesDevices: any = [];
        for (let j = 0; j < spacesDevices.length; j++)
        {
            let params = spacesDevices[j];
            spacesDevices[j] = {
                ...spacesDevices[j],
                clickEvent: () => this.handleDevicesClick(params),
                'Option': <button className="btn btn-success" onClick={() => this.handleDevicesClick(params)} >Edit </button>
            }
            // rowDataspacesDevices.push([
            //   spacesDevices[j].space_Id,
            //   spacesDevices[j].device_Type,
            //   spacesDevices[j].device_Code,
            //   spacesDevices[j].device_Mode,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/Device/nodeid=` + id + `/id=` + spacesDevices[j].device_Id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataDevices = {
            columns: [
                {
                    label: this.labels.HubLabelSpace,
                    field: "space_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceId,
                    field: "device_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceType,
                    field: "device_Type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceMode,
                    field: "device_Mode",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesDevices
        };

        const dataDevicesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelSpace,
                    field: "space_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceId,
                    field: "device_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceType,
                    field: "device_Type",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeviceMode,
                    field: "device_Mode",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let devicesTable: any = {};
        if (rowDataspacesDevices.length !== 0)
        {
            devicesTable = {
                columns: tableHeadingDevices.length,
                thClasses: devicesThClasses,
                tdClasses: devicesTdClasses,
                rows: rowDataspacesDevices.length,
                data: rowDataspacesDevices,
                heading: tableHeadingDevices,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesTaskCategories: any = [];
        for (let j = 0; j < spacesTaskCategories.length; j++)
        {
            let params = spacesTaskCategories[j];
            spacesTaskCategories[j] = {
                ...spacesTaskCategories[j],
                clickEvent: () => this.handleTaskCategoriesClick(params),
                'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                    defaultChecked={spacesTaskCategories[j].task_Category_IsEnabled === 1 ? true : false} />,
                'Option': <button className="btn btn-success" onClick={() => this.handleTaskCategoriesClick(params)} >Edit </button>
            }
            // rowDataSpacesTaskCategories.push([
            //   spacesTaskCategories[j].task_Category_Name,
            //   <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
            //     defaultChecked={spacesTaskCategories[j].task_Category_IsEnabled === 1 ? true : false} />,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=Taskcategories/nodeid=` + match.params.id + `/id=` + spacesTaskCategories[j].task_Category_Id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataTaskCategories = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "task_Category_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEnabled,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesTaskCategories
        };

        const dataTaskCategoriesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "task_Category_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEnabled,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let taskCategoriesTable: any = {};
        if (rowDataSpacesTaskCategories.length !== 0)
        {
            taskCategoriesTable = {
                columns: tableHeadingTaskCategories.length,
                thClasses: taskCategoriesThClasses,
                tdClasses: taskCategoriesTdClasses,
                rows: rowDataSpacesTaskCategories.length,
                data: rowDataSpacesTaskCategories,
                heading: tableHeadingTaskCategories,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesTaskTypes: any = [];
        for (let j = 0; j < spacesTaskTypes.length; j++)
        {
            let params = spacesTaskTypes[j];
            spacesTaskTypes[j] = {
                ...spacesTaskTypes[j],
                clickEvent: () => this.handleTaskTypesClick(params),
                'active': <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
                    defaultChecked={spacesTaskTypes[j].task_Type_IsEnabled === 1 ? true : false} />,
                'Option': <button className="btn btn-success" onClick={() => this.handleTaskTypesClick(params)} >Edit </button>
            }
            // rowDataSpacesTaskTypes.push([
            //   spacesTaskTypes[j].task_Type_Name,
            //   spacesTaskTypes[j].taskCategoryName,
            //   <input name="activeRole" type="checkbox" className="input-check-box border" disabled={true}
            //     defaultChecked={spacesTaskTypes[j].task_Type_IsEnabled === 1 ? true : false} />,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=Tasktypes/` + spacesTaskTypes[j].task_Type_Id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataTaskTypes = {
            columns: [
                {
                    label: this.labels.HubLabelTaskCategory,
                    field: "taskCategoryName",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabeltaskType,
                    field: "task_Type_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEnabled,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesTaskTypes
        };

        const dataTaskTypesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelTaskCategory,
                    field: "taskCategoryName",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabeltaskType,
                    field: "task_Type_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelEnabled,
                    field: "active",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let taskTypesTable: any = {};
        if (rowDataSpacesTaskTypes.length !== 0)
        {
            taskTypesTable = {
                columns: tableHeadingTaskTypes.length,
                thClasses: taskTypesthClasses,
                tdClasses: taskTypestdClasses,
                rows: rowDataSpacesTaskTypes.length,
                data: rowDataSpacesTaskTypes,
                heading: tableHeadingTaskTypes,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesTaskStates: any = [];
        for (let j = 0; j < spacesTaskStates.length; j++)
        {
            let params = spacesTaskStates[j];
            spacesTaskStates[j] = {
                ...spacesTaskStates[j],
                clickEvent: () => this.handleTaskStatesClick(params),
                'Option': <button className="btn btn-success" onClick={() => this.handleTaskStatesClick(params)} >Edit </button>
            }
            // rowDataSpacesTaskStates.push([
            //   spacesTaskStates[j].task_State_Id,
            //   spacesTaskStates[j].task_State_Name,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=TaskStates/nodeid=` + this.state.id + `/id=` + spacesTaskStates[j].id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataTaskStates = {
            columns: [
                {
                    label: this.labels.HubLabelstateId,
                    field: "task_State_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "task_State_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesTaskStates
        };

        const dataTaskStatesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelstateId,
                    field: "task_State_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "task_State_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let taskStatesTable: any = {};
        if (rowDataSpacesTaskStates.length !== 0)
        {
            taskStatesTable = {
                columns: tableHeadingTaskStates.length,
                thClasses: taskStatesThClasses,
                tdClasses: taskStatesTdClasses,
                rows: rowDataSpacesTaskStates.length,
                data: rowDataSpacesTaskStates,
                heading: tableHeadingTaskStates,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesSpaceStates: any = [];
        for (let j = 0; j < spacesSpaceStates.length; j++)
        {
            let params = spacesSpaceStates[j];
            spacesSpaceStates[j] = {
                ...spacesSpaceStates[j],
                clickEvent: () => this.handleSpaceStatesClick(params),
                'Option': <button className="btn btn-success" onClick={() => this.handleSpaceStatesClick(params)} >Edit </button>
            }
            // rowDataSpacesSpaceStates.push([
            //   spacesSpaceStates[j].space_State_Id,
            //   spacesSpaceStates[j].space_State_Name,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=SpaceStates/nodeid=` + this.state.id + `/id=` + spacesSpaceStates[j].id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataSpaceStates = {
            columns: [
                {
                    label: this.labels.HubLabelstateId,
                    field: "space_State_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "space_State_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesSpaceStates
        };

        const dataSpaceStatesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelstateId,
                    field: "space_State_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelName,
                    field: "space_State_Name",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let spaceStatesTable: any = {};
        if (rowDataSpacesSpaceStates.length !== 0)
        {
            spaceStatesTable = {
                columns: tableHeadingSpaceStates.length,
                thClasses: spaceStatesThClasses,
                tdClasses: spaceStatesTdClasses,
                rows: rowDataSpacesSpaceStates.length,
                data: rowDataSpacesSpaceStates,
                heading: tableHeadingSpaceStates,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesKPIs: any = [];
        for (let j = 0; j < spacesKPIs.length; j++)
        {
            let params = spacesKPIs[j];
            spacesKPIs[j] = {
                ...spacesKPIs[j],
                clickEvent: () => this.handleKPIsClick(params),
                'Option': <button className="btn btn-success" onClick={() => this.handleKPIsClick(params)} >Edit </button>
            }
            // rowDataSpacesKPIs.push([
            //   spacesKPIs[j].label,
            //   spacesKPIs[j].kpiType,
            //   spacesKPIs[j].valueURI,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/BuildingKPI/nodeid=` + match.params.id + `/id=` + spacesKPIs[j].buildingKPIId }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataKPIs = {
            columns: [
                {
                    label: this.labels.HubLabelLabel,
                    field: "label",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelKPIType,
                    field: "kPIType",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelValue,
                    field: "valueURI",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: spacesKPIs
        };

        const dataKPIsEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelLabel,
                    field: "label",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelKPIType,
                    field: "kpiType",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelValue,
                    field: "valueURI",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                }
            ],
            rows: []
        };

        let KPIsTable: any = {};
        if (rowDataSpacesKPIs.length !== 0)
        {
            KPIsTable = {
                columns: tableHeadingKPIs.length,
                thClasses: KPIsThClasses,
                tdClasses: KPIsTdClasses,
                rows: rowDataSpacesKPIs.length,
                data: rowDataSpacesKPIs,
                heading: tableHeadingKPIs,
                style: [],
                dashboard: 0,
            };
        }

        let rowDataSpacesSignages: any = [];
        for (let j = 0; j < spacesSignages.length; j++)
        {
            let params = spacesSignages[j];
            spacesSignages[j] = {
                ...spacesSignages[j],
                clickEvent: () => this.handleSignagesClick(params),
                'Option': <button className="btn btn-success" onClick={() => this.handleSignagesClick(params)} >Edit </button>
            }
            // rowDataSpacesSignages.push([
            //   spacesSignages[j].signage_Id,
            //   spacesSignages[j].signage_Mode,
            //   spacesSignages[j].signage_Orientation,
            //   <div className="button-right">
            //     <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/Signage/nodeid=` + match.params.id + `/id=` + spacesSignages[j].signage_Id }}>
            //       <button className="button-outline">{this.labels.HubButtonEdit}</button></Link></div>
            // ])
        }

        const dataSignages = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "signage_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelMode,
                    field: "signage_Mode",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelOrientation,
                    field: "signage_Orientation",
                    sort: "",
                    width: 50
                },
            ],
            rows: spacesSignages
        };

        const dataSignagesEmpty = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "signage_Id",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelMode,
                    field: "signage_Mode",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelOrientation,
                    field: "signage_Orientation",
                    sort: "",
                    width: 50
                },
            ],
            rows: []
        };

        let SignagesTable: any = {};
        if (rowDataSpacesSignages.length !== 0)
        {
            SignagesTable = {
                columns: tableHeadingSignages.length,
                thClasses: signagesThClasses,
                tdClasses: signagesTdClasses,
                rows: rowDataSpacesSignages.length,
                data: rowDataSpacesSignages,
                heading: tableHeadingSignages,
                style: [],
                dashboard: 0,
            };
        }

        const props = {
            ...this.props,
            submenu: this.state.allTab
            // [
            //   {
            //     title: this.labels.spaceConfigTab,
            //     active: this.state.activePage === 0 ? true : false,
            //     display: 'block',
            //   },
            //   {
            //     title: this.labels.HubLabelSpacePropertiesTab,
            //     active: this.state.activePage === 1 ? true : false,
            //     display: 'block',
            //   },
            //   {
            //     title: this.labels.HubTabSpaceParameters,
            //     active: this.state.activePage === 2 ? true : false,
            //     display: match.params.id !== "0" ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubTabSpaceParts,
            //     active: this.state.activePage === 3 ? true : false,
            //     display: match.params.id !== "0" ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubTabSpaceIsPartOfTab,
            //     active: this.state.activePage === 4 ? true : false,
            //     display: match.params.id !== "0" ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.spaceAgentsTab,
            //     active: this.state.activePage === 5 ? true : false,
            //     display: match.params.id !== "0" ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubLabelDevices,
            //     active: this.state.activePage === 6 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubTabTaskTypes,
            //     active: this.state.activePage === 7 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.taskCategories,
            //     active: this.state.activePage === 8 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubTaskStates,
            //     active: this.state.activePage === 9 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubLabelSpaceStatesl,
            //     active: this.state.activePage === 10 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubTabBuildingKPIs,
            //     active: this.state.activePage === 11 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            //   {
            //     title: this.labels.HubLabelSignage,
            //     active: this.state.activePage === 12 ? true : false,
            //     display: match.params.id !== "0" && this.state.showBuildingFields ? 'block' : 'none',
            //   },
            // ]
            ,
            activePageValue: this.state.activePage,
            childValue: (i: any) =>
            {
                this.setState({
                    activePage: i,
                }, () =>
                {
                    this.getRightList();
                });
            },
        };


        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="rightPanel">
                    <Submenu {...props} />
                    <div className="rightPanel-main-content">
                        {this.state.show ? (
                            <ConfirmModal {...confModalData} />
                        ) : ""}
                        <div className="table-panel">
                            <div className="table-panel-info">
                                <div className="table-panel-heading">
                                    <div className="table-panel-breadcrumbs">
                                        <div className="breadcrumbs">
                                            <Link className="breadcrumbs" to={{ pathname: `/portfolio-setup-spaces/filter=` + sessionStorage.getItem('spaceFilter') }}>{this.labels.HubLabelSpaces}</Link>
                                            {this.state.showOrganisation && this.state.organisationState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.organisationPath ?? undefined }} onClick={() => this.linkPath('Root')}> / {this.state.organisationState}</Link>)}
                                            {this.state.showRegion && this.state.regionState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.regionPath ?? undefined }} onClick={() => this.linkPath('Region')}> / {this.state.regionState}</Link>)}
                                            {this.state.showBuilding && this.state.buildingState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.buildingPath ?? undefined }} onClick={() => this.linkPath('Building')}> / {this.state.buildingState}</Link>)}
                                            {this.state.showFloor && this.state.floorState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.floorPath ?? undefined }} onClick={() => this.linkPath('Floor')}> / {this.state.floorState}</Link>)}
                                            {this.state.showZone && this.state.zoneState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.zonePath ?? undefined }} onClick={() => this.linkPath('Zone')}> / {this.state.zoneState}</Link>)}
                                            {this.state.showRoom && this.state.roomState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.roomState ?? undefined }} onClick={() => this.linkPath('Spaces')}> / {this.state.roomState}</Link>)}
                                            {this.state.showDesk && this.state.deskState !== "" && (<Link className="breadcrumbs" target="_blank" to={{ pathname: this.state.deskState ?? undefined }} onClick={() => this.linkPath('Desk')}> / {this.state.deskState}</Link>)}
                                        </div>
                                        <div className="page-title mb-20"><h1>{nameHeader}</h1></div>
                                    </div>
                                </div>

                            </div>
                            <div className="table-panel-content">
                                {this.state.activePage === 2 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/Parameter/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateParameterRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateParameterRight === true &&
                                            <button className="button-primary button-right">{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 3 && (
                                    <Link to={{}}>
                                        {this.state.isCreatePartRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreatePartRight === true &&
                                            <button className="button-primary button-right" onClick={() => this.reloadPage(filter)}>{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 6 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/Device/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateDeviceRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateDeviceRight === true &&
                                            <button className="button-primary button-right" >{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 7 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=Building/TaskTypes/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateTaskTypeRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateTaskTypeRight === true &&
                                            <button className="button-primary button-right">{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 8 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=Taskcategories/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateTaskCategoriesRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateTaskCategoriesRight === true &&
                                            <button className="button-primary button-right" >{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 9 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=TaskStates/nodeid=` + this.state.id + `/id=0` }}>
                                        {this.state.isCreateTaskStateRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateTaskStateRight === true &&
                                            <button className="button-primary button-right">{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 10 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=SpaceStates/nodeid=` + this.state.id + `/id=0`, }}>
                                        {this.state.isCreateSpaceStateRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateSpaceStateRight === true &&
                                            <button className="button-primary button-right" >{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 11 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/BuildingKPI/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateBuildingKPIRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateBuildingKPIRight === true &&
                                            <button className="button-primary button-right">{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                                {this.state.activePage === 12 && (
                                    <Link to={{ pathname: `/portfolio-setup-spaces/filter=` + this.state.filter + `/Signage/nodeid=` + match.params.id + `/id=0` }}>
                                        {this.state.isCreateTaskCategoriesRight === false &&
                                            <button className="button-primary-disable button-right" disabled={true}>{this.labels.HubButtonAdd}</button>
                                        }
                                        {this.state.isCreateTaskCategoriesRight === true &&
                                            <button className="button-primary button-right">{this.labels.HubButtonAdd}</button>
                                        }
                                    </Link>
                                )}
                            </div>



                            {this.state.activePage === 0 && (
                                <div className="main-content">
                                    {(this.state.loading || isEnabled !== null) && (
                                        <div className="row">
                                            <div className="col-12 p-0 w-100">
                                                <div className="card card-scroller scrollbarShow">
                                                    <div className="p-45">
                                                        <form>
                                                            <div className="row" >
                                                                <div className="col-6 pr-2">
                                                                    <Inputbox {...nameFld} />
                                                                </div>
                                                                {this.state.type === "Space" ?
                                                                    <div className="col-6 pr-2">
                                                                        <Inputbox {...idFld} />
                                                                    </div>
                                                                    :
                                                                    <div className="col-6 pr-2">
                                                                        <Inputbox {...codeFld} />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-3 pr-2 input-controls" >
                                                                    <SelectBox {...spaceTypeFld} />
                                                                </div>
                                                                <div className="col-3 input-controls">
                                                                    {(this.state.type === "Zone" || this.state.type === "Space") &&
                                                                        <SelectBox {...typeFld} />
                                                                    }
                                                                </div>
                                                                {this.state.type === "Space" &&
                                                                    <div className="col-3 pr-2 input-controls">
                                                                        <Inputbox {...spaceTypeLabelFld} />
                                                                    </div>
                                                                }
                                                                {(this.state.type === "Root" || this.state.type === "Region" || this.state.type === "Land" || this.state.type === "Building" || this.state.type === "Level" || this.state.type === "Floor") &&
                                                                    <div className="col-3 pr-2 input-controls">
                                                                        <label className="check-label">{this.labels.HubLabelSearchable}</label>
                                                                        <input name="isSearchable" type="checkbox" className="input-check-box-label border"
                                                                            defaultChecked={isSearchable === 1 ? true : false} onChange={this.setChecked} />
                                                                    </div>
                                                                }

                                                                <div className="col-3 pr-2 input-controls">
                                                                    <label className="check-label">{this.labels.HubLabelActive}</label>
                                                                    <input name="isEnabled" type="checkbox" className="input-check-box-label border"
                                                                        defaultChecked={isEnabled === 1 ? true : false} onChange={this.setChecked} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6 pr-2" style={{ display: showBuildingFields ? 'block' : 'none' }}>
                                                                    <Inputbox {...countryFld} />
                                                                </div>
                                                                <div className="col-6  pr-2" style={{ display: showBuildingFields ? 'block' : 'none' }}>
                                                                    <Inputbox {...cityFld} />
                                                                </div>
                                                                <div className="col-3 pr-2 input-controls" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'flex' : 'none', paddingRight: '0px' }}>
                                                                    <SelectBox {...spaceWorkTypeFld} />
                                                                </div>
                                                                <div className="col-3 input-controls" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'flex' : 'none' }}>
                                                                    <SelectBox {...spaceClassTypeFld} />
                                                                </div>
                                                                <div className="col-6 pr-2" style={{ display: showLevelFields ? 'block' : 'none' }}>
                                                                    <Inputbox {...levelNumberFld} />
                                                                </div>
                                                                <div className="col-6 pr-2" style={{ display: showLevelFields ? 'block' : 'none' }}></div>
                                                                <div className="col-6 pr-2" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'block' : 'none' }}>
                                                                    <Inputbox {...spaceCustomInfoFld} />
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-6 pr-2" style={{ display: showBuildingFields ? 'block' : 'none' }}>
                                                                    <Inputbox {...addressFld} />
                                                                </div>
                                                                <div className="col-3 pr-2 input-controls" style={{ display: showBuildingFields ? 'flex' : 'none' }}>
                                                                    <Inputbox {...longitudeFld} />
                                                                </div>
                                                                <div className="col-3 input-controls" style={{ display: showBuildingFields ? 'flex' : 'none' }}>
                                                                    <Inputbox {...latitudeFld} />
                                                                </div>
                                                                <div className="col-6 pr-2" style={{ display: showLevelFields ? 'block' : 'none' }}>
                                                                    <Inputbox {...mapUriFld} />
                                                                </div>
                                                                <div className="col-6 pr-2" style={{ display: showLevelFields ? 'block' : 'none' }}></div>
                                                                <div className="col-6 pr-2" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'block' : 'none' }}>
                                                                    <Inputbox {...imageURIFld} />
                                                                </div>
                                                                {this.state.type === "Space" &&
                                                                    <div className="col-6 pr-2 div-flex" style={{ display: showLevelFields ? 'none' : 'flex' }}>
                                                                        <div className="col-6 pr-2 input-controls" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'block' : 'none' }}>
                                                                            <Inputbox {...metaLocXmFld} />
                                                                        </div>
                                                                        <div className="col-6 input-controls" style={{ display: (externalFlag === true || (match.params.filter === "Space" && this.state.updateFilter === false)) ? 'block' : 'none' }}>
                                                                            <Inputbox {...metaLocYmFld} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.activePage === 1 && (
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 p-0 w-100">
                                            <div className="card card-scroller">
                                                <div className="p-45">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-3 pr-2 input-controls">
                                                                <Inputbox {...capacityFld} />
                                                            </div>
                                                            <div className="col-3 input-controls">
                                                                <Inputbox {...areaFld} />
                                                            </div>

                                                            {this.state.type === "Space" ?
                                                                <div className="col-6 div-flex">
                                                                    <div className="col-6 pr-2 input-controls" >
                                                                        <SelectBox {...metaOccTypeFld} />
                                                                    </div>
                                                                    <div className="col-6 pr-2">
                                                                        <label className="label">{this.labels.HubLabelArrivalLocation}</label>
                                                                        <div className="switch-field ">
                                                                            <input type="radio" id="radio-one" name="switch-one" defaultValue="Disabled" checked={space_Arrival_Loc === 0} onChange={() => { this.setState({ space_Arrival_Loc: 0 }); }} />
                                                                            <label htmlFor="radio-one">
                                                                                {this.labels.HubLabelChecked}
                                                                            </label>
                                                                            <input type="radio" id="radio-two" name="switch-one" defaultValue="Enabled" checked={space_Arrival_Loc === 1} onChange={() => { this.setState({ space_Arrival_Loc: 1 }); }} />
                                                                            <label htmlFor="radio-two">
                                                                                {this.labels.HubLabelUnchecked}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="col-6 pr-2 div-flex"></div>
                                                            }
                                                        </div>

                                                        {this.state.type === "Space" &&
                                                            <div className="row">
                                                                <div className="col-6 pr-2">
                                                                    <SelectBox {...meta_BookableFld} />
                                                                </div>
                                                                <div className="col-6 div-flex">
                                                                    <div className="col-6">
                                                                        <label className="label">{this.labels.HubLabelReservationRequired}</label>
                                                                        <div className="switch-field ">
                                                                            <input type="radio" id="radio-three" name="switch-three" defaultValue="Disabled" checked={meta_Bookable_Res_Req === 0} onChange={() => { this.setState({ meta_Bookable_Res_Req: 0 }); }} />
                                                                            <label htmlFor="radio-three">
                                                                                {this.labels.HubLabelChecked}
                                                                            </label>
                                                                            <input type="radio" id="radio-four" name="switch-four" defaultValue="Enabled" checked={meta_Bookable_Res_Req === 1} onChange={() => { this.setState({ meta_Bookable_Res_Req: 1 }); }} />
                                                                            <label htmlFor="radio-four">
                                                                                {this.labels.HubLabelUnchecked}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.type === "Space" &&
                                                            <div className="row">
                                                                <div className="col-3 pr-2 input-controls">
                                                                    <Inputbox {...meta_Space_DurationMin_MinFld} />
                                                                </div>
                                                                <div className="col-3 input-controls">
                                                                    <Inputbox {...meta_Space_DurationMax_MinFld} />
                                                                </div>
                                                                <div className="col-6 div-flex">
                                                                    <div className="col-6 pr-2 input-controls">
                                                                        <Inputbox {...meta_Sol_Thld_MinFld} />
                                                                    </div>
                                                                    <div className="col-6 input-controls">
                                                                        <Inputbox {...meta_Clean_Thld_MinFld} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.activePage === 13 && (
                                <div className="main-content">
                                    {/* {this.state.loading || ( */}
                                    <div className="row">
                                        <div className="col-12 p-0 w-100">
                                            <div className="card card-scroller">
                                                <div className="p-45">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-6 pr-2 div-flex">
                                                                <div className="col-6 pr-2 input-controls" >
                                                                    <SelectBox {...meta_Ext_Occ_SystemFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <SelectBox {...meta_Ext_Occ_Count_ModeFld} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 div-flex">
                                                                <div className="col-6 pr-2 pr-2 input-controls">
                                                                    <Inputbox {...meta_Ext_Occ_Area_IdFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <Inputbox {...meta_Ext_Occ_Sensor_IdFld} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-6 pr-2 div-flex">
                                                                <div className="col-6 pr-2 input-controls">
                                                                    <SelectBox {...meta_Ext_Booking_SystemFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <Inputbox {...meta_Ext_Booking_Space_IdFld} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 div-flex">
                                                                <div className="col-6 pr-2 input-controls">
                                                                    <SelectBox {...meta_Ext_Catering_SystemFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <Inputbox {...meta_Ext_Catering_Space_IdFld} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-6 pr-2 div-flex">
                                                                <div className="col-6 pr-2 input-controls">
                                                                    <SelectBox {...meta_Gtwy_TypeFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <Inputbox {...meta_GtwyFld} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6 div-flex">
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-6 pr-2 div-flex">
                                                                <div className="col-6 pr-2 input-controls">
                                                                    <SelectBox {...space_Light_ModeFld} />
                                                                </div>
                                                                <div className="col-6 input-controls">
                                                                    <Inputbox {...space_Light_ScenesFld} />
                                                                </div>
                                                            </div>

                                                            <div className="col-6 div-flex">
                                                                <div className="col-6 pr-2 input-controls">
                                                                    <Inputbox {...space_AV_Source_ScenesFld} />
                                                                </div>
                                                                <div className="col-6"></div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.state.activePage === 2 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesParameters.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableParameters" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataParametersEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableParameters" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataParameters} />
                                                }

                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.activePage === 3 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesHasPart.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaces" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataHasPartEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaces" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataHasPart} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 4 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesIsPartOf.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaces" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataIsPartOfEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaces" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataIsPartOf} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 5 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesAgents.length === 0 ?
                                                    <MDBDataTable className="customsizeInnerCol3" entriesOptions={[5, 10, 50, 100]} entries={5} hover data={dataAgentsEmpty} />
                                                    :
                                                    <MDBDataTable className="customsizeInnerCol3" entriesOptions={[5, 10, 50, 100]} entries={5} hover data={dataAgents} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 6 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesDevices.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableDevices" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataDevicesEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableDevices" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataDevices} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 7 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesTaskTypes.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskTypes" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskTypesEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskTypes" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskTypes} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 8 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesTaskCategories.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskCategories" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskCategoriesEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskCategories" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskCategories} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 9 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesTaskStates.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskStates" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskStatesEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableTaskStates" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataTaskStates} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 10 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesSpaceStates.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaceStates" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataSpaceStatesEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableSpaceStates" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataSpaceStates} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 11 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesKPIs.length === 0 ?
                                                    <MDBDataTable className="standardTable tableTabButtons tableKPIs" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataKPIsEmpty} />
                                                    :
                                                    <MDBDataTable className="standardTable tableTabButtons tableKPIs" disableRetreatAfterSorting={true} displayEntries={false} entries={50} hover small data={dataKPIs} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 12 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="row">
                                            <div className="col-12">
                                                {this.state.spacesSignages.length === 0 ?
                                                    <MDBDataTable className="customsizeInnerCol3" entriesOptions={[5, 10, 50, 100]} entries={5} hover data={dataSignagesEmpty} />
                                                    :
                                                    <MDBDataTable className="customsizeInnerCol3" entriesOptions={[5, 10, 50, 100]} entries={5} hover data={dataSignages} />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>
                        <div className="main-button-content">
                            <div className="buttons-group" >
                                <div className="col-6">
                                    <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                                    {(this.state.id !== 0 && this.state.isDeleteSpaceRight === false) &&
                                        <button className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</button>
                                    }
                                    {(this.state.id !== 0 && this.state.isDeleteSpaceRight === true) &&
                                        <button className="button-tertiary mr-2" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                                    }

                                </div>
                                <div className=" space-between">
                                    {this.state.isUpdateSpaceRight === false &&
                                        <button className="button-primary-disable ml-2" disabled={true}>{this.labels.HubButtonSave}</button>
                                    }
                                    {this.state.isUpdateSpaceRight === true &&
                                        <button className="button-primary ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
    };
};
export default connect(mapStateToProps)(SpacesEdit);

interface IProps
{
}

interface IState
{
    isLoadingToSave: boolean;
    activePage: number;
    loading: boolean;
    tableHeading: string[];
    thClasses: string[];
    tdClasses: string[];
    tableHeadParameters: string[];
    parametersThClasses: string[];
    parametersTdClasses: string[];
    tableHeadingDevices: string[];
    devicesThClasses: string[];
    devicesTdClasses: string[];
    tableHeadingTaskCategories: string[];
    taskCategoriesThClasses: string[];
    taskCategoriesTdClasses: string[];
    tableHeadingTaskTypes: string[];
    taskTypesthClasses: string[];
    taskTypestdClasses: string[];
    tableHeadingTaskStates: string[];
    taskStatesThClasses: string[];
    taskStatesTdClasses: string[];
    tableHeadingSpaceStates: string[];
    spaceStatesThClasses: string[];
    spaceStatesTdClasses: string[];
    tableHeadingAgents: string[];
    agentsThClasses: string[];
    agentsTdClasses: string[];
    tableHeadingKPIs: string[];
    KPIsThClasses: string[];
    KPIsTdClasses: string[];
    tableHeadingSignages: string[];
    signagesThClasses: string[];
    signagesTdClasses: string[];

    spacesIsPartOf: [];
    spacesHasPart: [];
    spacesParameters: [];
    spacesDevices: [];
    spacesTaskCategories: [];
    spacesSpaceStates: [];
    spacesTaskTypes: [];
    spacesTaskStates: [];
    spacesAgents: [];
    spacesKPIs: [];
    spacesSignages: [];
    spaceTypeList: { title: any; value: any; }[];
    spaceTypeListAll: { title: any; value: any; }[];
    spaceWorkTypeList: { title: any; value: any; }[];
    spaceClassTypeList: { title: any; value: any; }[];
    filter: string;
    nameHeader: string;
    updates: boolean;
    code: string;
    concurrencyStamp: string;
    id: number | null;
    isEnabled: number | null;
    isPartOf: number;
    isSearchable: number;
    name: string;
    personCapacity: null;
    type: string;
    space_Type: string;
    area: [];
    showLevelFields: boolean;
    showBuildingFields: boolean;
    imageURI: string;
    space_Custom_Info: string;
    country: string;
    city: string;
    address: string;
    longitude: number;
    latitude: number;
    meta_Occ_Type: number;
    space_Arrival_Loc: number;
    meta_Bookable: number;
    meta_Bookable_Res_Req: number;
    meta_Space_DurationMin_min: number;
    meta_Space_DurationMax_min: number;
    meta_Sol_Thld_Min: number;
    meta_Clean_Thld_Min: number;
    meta_Ext_Occ_System: number;
    meta_Ext_Occ_Count_Mode: number;
    meta_Ext_Occ_Area_Id: string;
    meta_Ext_Occ_Sensor_Id: string;
    meta_Ext_Booking_System: number;
    meta_Ext_Booking_Space_Id: string;
    meta_Ext_Catering_System: number;
    meta_Ext_Catering_Space_Id: string;
    meta_Gtwy_Type: number;
    meta_Gtwy: string;
    space_Light_Mode: number;
    space_Light_Scenes: string;
    space_AV_Source_Scenes: string;
    space_Work_Type: string;
    space_Class: string;
    space_Type_Label: string;
    meta_Loc_Y_m: string;
    meta_Loc_X_m: string;
    mapURI: string;
    levelNumber: null;
    organisationState: string | null;
    organisationPath: string | null;
    organisationId: string | null;
    showOrganisation: boolean;
    regionState: string | null;
    regionPath: string | null;
    regionId: string | null;
    showRegion: boolean;
    buildingState: string | null;
    buildingPath: string | null;
    buildingId: string | null;
    showBuilding: boolean;
    floorState: string | null;
    floorPath: string | null;
    floorId: string | null;
    showFloor: boolean;
    zoneState: string | null;
    zonePath: string | null;
    zoneId: string | null;
    showZone: boolean;
    roomState: string | null;
    roomPath: string | null;
    roomId: string | null;
    showRoom: boolean;
    deskState: string | null;
    deskPath: string | null;
    deskId: string | null;
    showDesk: boolean;
    reload: boolean;
    parameterMessage: string;
    hasPartMessage: string;
    isPartOfMessage: string;
    devicesMessage: string;
    taskTypesMessage: string;
    taskCategoriesMessage: string;
    taskStatesMessage: string;
    spaceStatesMessage: string;
    agentsMessage: string;
    KPIsMessage: string;
    signagesMessage: string;
    allTab: { title: string, tabNo: number, active: boolean, display: string }[];
    spaceFilter: string | null;
    externalFlag: boolean;
    updateFilter: boolean;
    show: boolean;
    isDelete: boolean;

    isUpdateSpaceRight: boolean;
    isDeleteSpaceRight: boolean;

    isCreateParameterRight: boolean;
    isUpdateParameterRight: boolean;
    isDeleteParameterRight: boolean;

    isCreateDeviceRight: boolean;
    isUpdateDeviceRight: boolean;
    isDeleteDeviceRight: boolean;

    isCreateTaskTypeRight: boolean;
    isUpdateTaskTypeRight: boolean;
    isDeleteTaskTypeRight: boolean;

    isCreateTaskCategoriesRight: boolean;
    isUpdateTaskCategoriesRight: boolean;
    isDeleteTaskCategoriesRight: boolean;

    isCreateTaskStateRight: boolean;
    isUpdateTaskStateRight: boolean;
    isDeleteTaskStateRight: boolean;

    isCreateSpaceStateRight: boolean;
    isUpdateSpaceStateRight: boolean;
    isDeleteSpaceStateRight: boolean;

    isCreateBuildingKPIRight: boolean;
    isUpdateBuildingKPIRight: boolean;
    isDeleteBuildingKPIRight: boolean;

    isCreateSignageRight: boolean;
    isUpdateSignageRight: boolean;
    isDeleteSignageRight: boolean;

    isCreatePartRight: boolean;
    isUpdatePartRight: boolean;
    isDeletePartRight: boolean;

    isCreateIsPartRight: boolean;
    isUpdateIsPartRight: boolean;
    isDeleteIsPartRight: boolean;
    rightsData: IUserRights | [];
}
