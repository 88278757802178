import { DateTime } from "luxon";
import { TypeHelper } from "../Common/TypeHelper";

export interface IRawPagedResponse<TValue>
{
    "@odata.nextLink": (string | null);
    Value: TValue;
}

export class PagedResponse<TValue>
{
    public nextLink: (string | null);
    public value: TValue;

    constructor(data: { nextLink: (string | null),  value: TValue })
    {
        this.nextLink = data.nextLink;
        this.value = data.value;
    }

    public static fromRawResponse<TValue>(rawResponse: IRawPagedResponse<TValue>)
    {
        let pagedResponse = new PagedResponse<TValue>(
        {
            nextLink: rawResponse["@odata.nextLink"],
            value: rawResponse.Value,
        });
        return pagedResponse;
    }

    public get skipToken(): string
    {
        if (!this.nextLink)
        {
            return '';
        }
       
        const tokenExpression = /\$skiptoken=([a-f0-9-]+)/i;
        const tokenMatch = this.nextLink.match(tokenExpression);
        const skipToken = (tokenMatch && tokenMatch[1] ? tokenMatch[1] : "");
        return skipToken;
    }
}

/** @deprecated **/
// use the IRawPagedResponse and PagedResponse instead
export interface IV2GetResponse<TValue>
{
    SkipToken: (string | null);
    Value: TValue;
}

export interface IRootNode
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsSearchable: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Building_Address: string;
    Building_City: string;
    Building_Country: string;
    Building_Longitude: number;
    Building_Latitude: number;
    Building_Operator: string;
    Building_Owner: string;
    Node_Search_Config: string;
    Regions: IRegion[];
}

export interface IRegion
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsSearchable: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Buildings: INode[];
}

export interface INode
{
    Node_Id: number;
    Name: string;
    Address: string;
    City: string;
    Region: string;
    Node_IsSearchable: number;
    Country: string;
    Longitude: number;
    Latitude: number;
    Capacity: number;
    Zones: string[];
    SearchConfig: ISearchConfig;
    Building_Operator: string;
    Building_Owner: string;
    Node_FloorCount: number;
    Node_SpaceCount: number;
    Building_Occupier: string;
    Floor_Occupier: string;
    Floor_MapURI: string;
    Bkng_Early_Chkin_Thld: number;
    Vis_Arvl_Loc: string;
    Floors: IFloor[];
    Occ_Wkng_Hrs_Stt: string;
    Occ_Wkng_Hrs_Stp: string;
    Occ_Wkng_Days_Stt: string;
    Occ_Wkng_Days_Stp: string;
    Occ_Space_Rsrv_Time: string;
    Task_Due_Amber_Thld: string;
    Bkng_Auto_Cancel_Thld: number;
    Building_ImageURI: string;
    Bkng_Room_Red_Thld: number;
    Bkng_Room_Amber_Thld: number;
    Building_Roamer_Menu: number;
    Vis_Arvl_Wndw_Mins: number;
    Uty_Page_Delay: number;
    Insp_VM_Page_Delay: number;
    Insp_LV_Page_Delay: number;
    Insp_SV_Page_Delay: number;
    Insp_FV_Page_Delay: number;
    Env_Temp_Hi: number;
    Env_Temp_Lo: number;
    Building_TZ: string;
    Building_TZ_DST: number;
    BuildingC19Mode: number;
    Env_Temp_Hi_SP: number;
    Env_Temp_Lo_SP: number;
    Pre_Book_Cat_Mins: number;
    Occ_Working_Hrs_Mode: number;
    Occ_Office_hrs_stt: string;
    Occ_Office_hrs_stp: string;
    Occ_Verify_Loc: number;
    Node_IsDefault: number;
    Cat_Crncy: string;
    Pre_Book_Cat_Time: string;
    Cat_Use_List: number;
    Bkng_Allowed_Starts: string;
}

export interface IFloor
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_FloorCount: number;
    Node_SpaceCount: number;
    Floor_Occupier: string;
    Floor_MapURI: string;
    Zones: (IZone[] | null);
}

export interface IZone
{
    Node_Id: number;
    Node_Name: string;
    Node_Capacity: number;
    Node_Size: number;
    Node_IsEnabled: number;
    Node_SortName: string;
    Node_Level: number;
    Node_Parent_Id: number;
    Node_Level_Name: string;
    Node_Code: string;
    Node_SpaceCount: number;
    Meta_Loc_Zone: string;
}

export interface ISearchConfig
{
    Config: ISearchConfigConfig;
    Map: ISearchConfigMap;
    Workspaces: IWorkspaces;
    Amenities: IAmenities;
    CapitalPlantEquipment: ICapitalPlantEquipment;
    WorkplaceEquipment: IWorkplaceEquipment;
    Colleagues: ISearchConfigItem;
}

export interface ISearchConfigConfig
{
    PageWhenDefaultTime: string;
    PageWhenMinDuration: number;
    PageWhenStartTime: string;
    PageWhenEndTime: string;
    ShowPageExtra: number;
    PageExtraShowTemperature: number;
    PageExtraShowNoise: number;
    PageExtraShowCatering: number;
    PageExtraShowAV: number;
    PageExtraShowHearingAid: number;
    PageExtraShowPresentationAid: number;
}

export interface ISearchConfigMap
{
    MapTimeMode: string;
    MapEndTimeOffset: number;
    MapDefaultType: string;
}

export interface IWorkspaces
{
    WORKTYPES: ISearchConfigItem[];
    WORKSPACES: ISearchConfigItem[];
    "SUPPORT SPACES": ISearchConfigItem[];
}

export interface IAmenities
{
    AMENITIES: ISearchConfigItem[];
}

export interface ICapitalPlantEquipment
{
    CAPITALPLANT: ISearchConfigItem[];
}

export interface IWorkplaceEquipment
{
    WORKPLACE: ISearchConfigItem[];
}

export interface ISearchConfigItem
{
    Name: string;
    Icon: string;
    Label: string;
    Config: ISearchConfigItemConfig;
}

export interface ISearchConfigItemConfig
{
    PageWhenDefaultTime: string;
    PageWhenMinDuration: number;
    PageWhenStartTime: string;
    PageWhenEndTime: string;
    ShowPageExtra: number;
    PageExtraShowTemperature: number;
    PageExtraShowNoise: number;
    PageExtraShowCatering: number;
    PageExtraShowAV: number;
    PageExtraShowHearingAid: number;
    PageExtraShowPresentationAid: number;
    DefaultSpaceId: string;
    DefaultEquipId: string;
    SearchRadius: number;
}

export interface IUserList
{
    displayName: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    pin: string | number | null,
    userName: string,
    oid: string | number | null,
    label?: string,
}

export interface IUpdateEquipmentRequest
{
    EquipmentType: string,
    StartDate: string,
    EndDate: string,
}

export interface IUpdateEquipmentResponse
{
    Node_Id: number;
    Equip_Id: string;
    Equip_Name: string;
    Equip_Type: string;
    Equip_Class: string;
    ImageURI: string;
    Space_Id: string;
    Booking_Resource_Id: string;
    Meta_Loc_Zone: string;
}

export interface IVisit
{
    Node_Id: number;
    Space_Id: string;
    Space_Name: string;
    Visit_End_Date: string;
    Visit_Host_Email: string;
    Visit_Host_Name: string;
    Visit_Id: string;
    Visit_Start_Date: string;
    Visit_Status: "New" | "Amended" | "Late Checkin" | "Awaiting Approval" | "Approved" | "Checked In" | "Cancelled" | "Completed" | "No Show" | "Auto Cancelled";
    Visitor_Company: string;
    Visitor_Email: string;
    Visitor_First_Name: string;
    Visitor_Last_Name: string;
    Visit_Pacs_Id: string;
}

export interface IEquipmentDetailsResponse
{
    Node_Id: number;
    Equip_Id: string;
    Equip_Name: string;
    Equip_Type: string;
    Equipment_Class: string;
    ImageURI: string;
    Space_Id: string;
    Booking_Resource_Id: string;
    Meta_Loc_Zone: string;
}
