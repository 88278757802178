export enum MenuItemKey
{
    NotSet,
    Flex,
    Flex_Home,
    Flex_FindASpace,
    Flex_FindASpace_Single,
    Flex_FindASpace_Recurring,
    Flex_MyNotification,
    Flex_MySchedule,
    Flex_MySchedule_MyBookings,
    Flex_MySchedule_MyVisitors,
    Flex_MySchedule_MyBookingsForOthers,
    Flex_MyTasks,
    Flex_MyTask_MyTasks,
    Flex_MyTasks_MyTasksToResolve,
    Flex_UserPreferences,
    Flex_UserPreferences_Notifications,
    Flex_UserPreferences_Workplace,
    Flex_UserPreferences_Account,
    OneLens,
    OneLens_Select_Building,
    OneLens_OperationalServices,
    OneLens_OperationalServices_Home,
    OneLens_OperationalServices_Bookings,
    OneLens_OperationalServices_Bookings_List,
    OneLens_OperationalServices_Bookings_Trends,
    OneLens_OperationalServices_Catering,
    OneLens_OperationalServices_Catering_CateringOrders,
    Onelens_OperationalServices_Catering_CateringItems,
    OneLens_OperationalServices_DirectedCleaning,
    OneLens_OperationalServices_Notifications,
    OneLens_OperationalServices_Spaces,
    OneLens_OperationalServices_Tasks,
    OneLens_OperationalServices_Visitors,
    OneLens_OperationalServices_Visitors_List,
    OneLens_OperationalServices_Visitors_Trends,
    OneLens_Analytics,
    OneLens_Analytics_Sustainability,
    OneLens_Analytics_Sustainability_Overview,
    OneLens_Analytics_Sustainability_Floors,
    OneLens_Analytics_Sustainability_Zones,
    OneLens_Analytics_Sustainability_Sensors,
    OneLens_Analytics_Space,
    OneLens_Analytics_Space_Overview,
    OneLens_Analytics_Space_Heatmaps,
    OneLens_OperationalServices_CostCodes,
    Admin,
    // new keys
    Admin_Home,
    Admin_Setup,
    Admin_Manage,
    Admin_Setup_Security,
    Admin_Setup_Security_IdentityProviders,
    Admin_Setup_Security_Roles,
    Admin_Manage_Users,
    Admin_Manage_CostCodes,
    Admin_Manage_BookingPolicies,
    Admin_Manage_FileManagement,
    Admin_Manage_FileManagement_Roamer,
    Admin_Manage_FileManagement_Unity,
    Admin_Manage_FileManagement_Inspire,
    Admin_Manage_FileManagement_Common,
    // old keys
    Admin_NotificationsSetup_Notifications,
    Admin_PortfolioSetup_Organisation,
    Admin_PortfolioSetup_Regions,
    Admin_PortfolioSetup_Buildings,
    Admin_NotificationsSetup,
    Admin_PortfolioSetup,
    Admin_PortfolioSetup_Floors,
    Admin_PortfolioSetup_Zones,
    Admin_PortfolioSetup_Rooms,
}