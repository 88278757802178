import { Component } from "react";
import { appContext } from "../../AppContext";
import IbssDialog from "./IbssDialog";
import IbssButton from "./IbssButton";

export default class ConfirmModal extends Component {
    private labels = appContext().labels;
    render() {
        const { name, show, history, handleModal, okButton, modalHeading, modalMessage }: any = this.props;
        return (
            <IbssDialog
            fullWidth
                open={show}
                header={modalHeading}
                content={
                    <>
                        <div className="modal-space-name">{name}</div>
                        <div>{modalMessage}</div>
                    </>
                }
                footer=
                {
                    <div className="d-flex justify-content-center">
                        <IbssButton fullWidth variant="outlined" color="secondary" className="mr-2 modal-button" onClick={() => handleModal()}>{this.labels.HubButtonCancel}</IbssButton>
                        <IbssButton fullWidth variant="contained" onClick={() => okButton()}>{this.labels.HubLabelOk}</IbssButton>
                    </div>
                }
            />
        );
    }
}
