import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { IV2GetResponse } from "../Models";
import { DateHelper } from "../../Common/DateHelper";

export class DeleteV1BookingEndpoint implements IDeleteV1BookingEndpoint
{
    public async execute(buildingId: number, bookingId: string): Promise<IDeleteBookingResponse>
    {
        try
        {
            const response = await axios.delete<IDeleteBookingResponse>(apis.allApi + buildingId + `/Bookings/`+ bookingId );
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteV1BookingEndpoint
{
    execute(buildingId: number, bookingId: string): Promise<IDeleteBookingResponse>;
}

export interface IDeleteBookingResponse
{
    Details: [];
    Message: string;
}
