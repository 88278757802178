import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { IV2GetResponse } from "../Models";
import { DateHelper } from "../../Common/DateHelper";

export class RejectBookingEndpoint implements IRejectBookingEndpoint
{
    public async execute(nodeId: number, bookingId: string): Promise<string>
    {
        try
        {
            const data = { Booking_IsApproved: 1 };
            const response = await axios.patch(apis.allApiv2 + nodeId + '/Bookings/' + bookingId, data);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IRejectBookingEndpoint
{
    execute(nodeId: number, bookingId: string): Promise<string>;
}
