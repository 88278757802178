import { Component, SyntheticEvent } from "react";
import "../../../styles/css/dashboard.scss";
import "../../../styles/css/header.scss";
import "../../../App.css";
import Submenu from "../../../Components/SubMenu";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import Inputbox from "../../../Components/uicomponents/Inputbox";
import { getFloorsFromSelectedBuilding, getAllBuildingsData, getSpaceTypesByNodeId, getFloorUsingFloorId, getBuildingNameUsingFloorNodeId } from "../../../Common/Helper";
import ConfirmModal from "../../../Components/uicomponents/ConfirmModal";
import SelectTextBox from "../../../Components/uicomponents/SelectTextBox";
import SelectBox from "../../../Components/uicomponents/SelectBox";
import LoadingOverlay from "../../../Components/LoadingOverlay"
import Autocomplete from '@mui/material/Autocomplete';
import Paper from "@material-ui/core/Paper";
import InputTextBox from "../../../Components/uicomponents/InputTextBox"
import TextField from '@mui/material/TextField';
import FormPanelInfo from "../../../Components/FormPanelInfo"
import { appContext } from "../../../AppContext";
import Helper from "../../../Common/Helper";
import { DateHelper } from "../../../Common/DateHelper";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Box, Grid, TextareaAutosize } from "@mui/material";
import IbssAutocomplete from "../../../Components/uicomponents/IbssAutocomplete";
import { RouterProps } from "react-router";
import { INode,IFloor } from "../../../Providers.Api/Models";
import { IDispatch, IPropsFromState } from "../../../redux/Interfaces";
import Typography from '@mui/material/Typography';
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import { LineChart } from "recharts";


interface ISpaces{
  Node_Id:number;
  Space_Id:string;
  Space_Type:string;
  title?: string; 
  label?: string;
}

interface ICategories {
  Space_Type: string;
  task_Category_Id: string;
  task_Category_Name: string;
  title: string;
  value: string;
  space_Type?: string;
}

interface SpaceType {
  Task_Type_Name: string;
  Task_Type_Id?: string;
}

interface ISelectedBuilding {
  Node_Id: number;
  Name: string;
}

interface INodeCategory {
  Node_Id: number;
  Space_Id:string;
  Space_Type:string;
}

class TaskDetails extends Component<IProps> {

  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  private local = appContext().localStorageProvider;
  private get spaceService() { return appContext().spaceService; }

  state = {
    isLoadingToSave: false,
    activePage: 0,
    updates: false,
    title: "Tasks",
    taskCategoryLoading: false,
    task_Cost_Code:"",
    loading: false,
    Task_Id: "",
    Task_Priority: 0,
    Task_Owner: "",
    Task_Description: "",
    task_Category_Id: "",
    Task_Category_Name: "",
    Task_Type_Id: 0,
    Task_Type_Name: "",
    space_Id_Value: "",
    Space_Id: "",
    spaceLabel: "",
    Space_Type: "",
    Booking_Id: "",
    Equip_Id: "",
    Task_Type: 0,
    show: false,
    nodeid: 0,
    isDelete: false,
    tab: 1,
    taskCategories: [{ title: "", value: "", Space_Type: "" }],
    taskTypes: [{ title: "", value: "" }],
    isUpdateRight: false,
    isDeleteRight: false,
    spaceList: [],
    copySpaceList: {} as INodeCategory,
    apiVersion: 11,
    buildingId: this.session.getBuildingId(),
    building: [],
    selectedBuilding: "",
    floors: [],
    selectedFloor: "",
    categories: [],
    categoryId: "",
    categoryName: "",
    taskTypeId: "",
    spaceTypes: [],
    serviceType: [],
    description: "",
    spaceId: "",
    isEdit: false,
    floorNameById: "",
    buildingNameById: "",
    editSpaceName: "",
    editCategoryName: "",
    editSpaceTypeName: ""
  };




  componentDidMount() {
    let versions = 11;
    const version = this.local.getVersion();
    if (version !== 0) {
      versions = version;
    }
    this.setState({
      apiVersion: versions
    }, () => {
      this.handleComponentMount()
    })
    const buildings = getAllBuildingsData();
    this.setState({
      building: buildings
    })
  }

  handleComponentMount = async () => {
    const { match }: any = this.props;

    if (match.params.task_Id !== "0") {
      await this.handleGetDetails(match.params.task_Id)
    }


    if (match.params.task_Id !== "0") {
      this.getRightList();
      this.setState({
        Task_Id: match.params.task_Id,
        nodeid: this.state.buildingId,
        tab: match.params.tab
      })
    } else {
      this.setState({
        tab: match.params.tab,
        nodeid: this.state.buildingId,
        isUpdateRight: true
      })
    }
  }


  handleUpdateTaskType = async (value: any) => { // value ==> task_Category_Id
    const { match }: any = this.props;

    if (!value) {
      return;
    }
    try {
      this.setState({ taskCategoryLoading: true })
      const res = await apis.getTaskTypesByCategoryIdfacility(this.state.buildingId, value)
      this.setState({ taskCategoryLoading: false })
      let taskTypeList: any = []

      if (this.state.apiVersion === 10) {
        res.data.forEach((item: any) => {
          const taskTypeObj = {
            title: item.task_Type_Name,
            value: item.task_Type_Id,
          }
          taskTypeList.push(taskTypeObj)
        });
      }
      else if (this.state.apiVersion > 10) {
        res.data.forEach((item: any) => {
          const taskTypeObj = {
            title: item.Task_Type_Name,
            value: item.Task_Type_Id,
          }
          taskTypeList.push(taskTypeObj)
        });
      }

      if (match.params.task_Id !== "0") {
        this.setState({
          taskTypes: taskTypeList,
          // Task_Type: task_Type
        })
      } else {
        this.setState({
          taskTypes: taskTypeList,
          // Task_Type: task_Type
        })
      }

      return taskTypeList;
    }
    catch (error: any) {
      this.setState({ taskCategoryLoading: false })
    }
  }


  getRightList = () => {
    const res = this.local.getIbssRightList();

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Tasks && res.ADMINPORTAL.Tasks.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Tasks && res.ADMINPORTAL.Tasks.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getFilteredCategoeryBySpaceType = () => {
    let newCategoery: any = []
    this.state.taskCategories && this.state.taskCategories[0] && this.state.taskCategories.forEach((item) => {
      if (this.state.Space_Type === item.Space_Type) {
        newCategoery.push(item)
      }
    })
    return newCategoery
  }


  handleGetDetails = async (id: any) => {
    try {
      this.setState({
        isEdit: true
      })
      const res = await apis.getTaskById(this.state.buildingId, id);
      const responseData = res.data;
      const getFloor = getFloorUsingFloorId(responseData.Node_Id);
      const getBuilding = getBuildingNameUsingFloorNodeId(responseData.Node_Id);
      if (this.state.apiVersion === 10) {
        this.setState({
          spaceId: responseData.Space_Id,
          categoryId: responseData.Task_Type_Id,
          floorNameById: getFloor,
          buildingNameById: getBuilding,
          editSpaceName: responseData.Space_Id,
          editCategoryName: responseData.Task_Category_Name,
          editSpaceTypeName: responseData.Task_Type_Name,
          description: responseData.Task_Description
        })
      }
      else if (this.state.apiVersion > 10) {
        this.setState({
          spaceId: responseData.Space_Id,
          categoryId: responseData.Task_Type_Id,
          floorNameById: getFloor,
          buildingNameById: getBuilding,
          editSpaceName: responseData.Space_Id,
          editCategoryName: responseData.Task_Category_Name,
          editSpaceTypeName: responseData.Task_Type_Name,
          description: responseData.Task_Description
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  handleGetSelectList = async () => {
    try {
      const response = await apis.getTaskCategoriesByNodeid(this.state.selectedBuilding)
      const taskCategoryList = response.data && response.data.map((item: ICategories) => {
        const taskCategoryObj = {
          title: item.task_Category_Name,
          task_Category_Name: item.task_Category_Name,
          task_Category_Id: item.task_Category_Id,
          value: item.task_Category_Id,
          Space_Type: item.space_Type,
        }
        return taskCategoryObj
      })

      this.setState({
        taskCategories: taskCategoryList
      });
      return taskCategoryList;
    }
    catch (error) {
      console.log(error);
    }
  }


  submit = () => {
    // this.setState({ isLoadingToSave: true })
    const { match }: any = this.props;


    const nodeid = this.state.copySpaceList.Node_Id;

    let payload = {};
    if (match.params.task_Id === "0") {
      if (this.state.apiVersion === 10) {
        payload = {
          "Space_Id": this.state.copySpaceList.Space_Id,
          "Task_Category_Id": parseInt(this.state.categoryId),
          "Task_Type_Id": parseInt(this.state.taskTypeId),
          "Task_Description": this.state.description,
          "Booking_Start": DateHelper.now().toJSDate(),
          "Catering_Service_Time": DateHelper.now().toJSDate(),
          "Catering_Clearing_Time": DateHelper.now().toJSDate(),
          "Catering_Attendees": 0,
          "Catering_Notes": "",
          "DisableExtUpdate": false
        }
      }
      if (this.state.apiVersion > 10) {
        payload = {
          "Space_Id": this.state.copySpaceList.Space_Id,
          "Task_Category_Id": parseInt(this.state.categoryId),
          "Task_Type_Id": parseInt(this.state.taskTypeId),
          "Task_Description": this.state.description,
          "Booking_Start": DateHelper.now().toJSDate(),
          "Catering_Service_Time": DateHelper.now().toJSDate(),
          "Catering_Clearing_Time": DateHelper.now().toJSDate(),
          "Catering_Attendees": 0,
          "Catering_Notes": "",
          "DisableExtUpdate": false
        }
      }
      this.create(nodeid, payload);
    } else {
      if (this.state.apiVersion === 10) {
        payload = {
          "task_Priority": this.state.Task_Priority,
          "task_Description": this.state.description,
          "task_Resolve_Description": "",
          "task_Cost_Code": this.state.task_Cost_Code,
          "catering_Attendees": 0,
          "catering_Service_Time": DateHelper.now().toJSDate(),
          "catering_Clearing_Time": DateHelper.now().toJSDate(),
        };
      }
      if (this.state.apiVersion > 10) {
        payload = {
          "Task_Priority": this.state.Task_Priority,
          "Task_Description": this.state.description,
          "Task_Resolve_Description": "",
          "Task_Cost_Code": this.state.task_Cost_Code,
          "Catering_Attendees": 0,
          "Catering_Service_Time": DateHelper.now().toJSDate(),
          "Catering_Clearing_Time": DateHelper.now().toJSDate(),
        };
      }

      this.update(nodeid, payload)
    }
  };

  create = (nodeid: any, payload: any) => {
    apis.createTask(nodeid, payload).then((res) => {
      this.setState({ isLoadingToSave: false })
      this.redirectPath();
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false })
    });
  };

  update = (nodeid: any, payload: any) => {
    const { updates, Task_Id }: any = this.state;
    if (updates) {
      apis.updateTaskByTaskId(nodeid, Task_Id, payload).then(() => {
        this.setState({ isLoadingToSave: false })
        this.redirectPath();
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false })
      });
    } else {
      this.setState({ isLoadingToSave: false })
      this.redirectPath();
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value });
  };

  validateAllForm() {
    const { }: any = this.state;
  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }

  delete = () => {

  }

  cancel = () => {
    const { updates } = this.state
    if (updates) {
      this.handleModal();
    } else {
      this.redirectPath();
    }
  }

  redirectPath = () => {
    const { history }: any = this.props;
    let activeurl = window.location.href;
    if (activeurl.includes("flex-my-tasks-to-resolve")) {
      history.push("/flex-my-tasks-to-resolve");
    } else {
      if (activeurl.includes("flex-my-tasks")) {
        history.push("/flex-my-tasks");
      } else {
        history.push("/operational-services-tasks/" + this.state.buildingId);
      }
    }
  }

  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  buildingChange = async (event: SelectChangeEvent) => {
    this.setState({
      selectedBuilding: event.target.value
    })
    const _getFloor = this.state.building.filter((item:INode) => item.Node_Id === Number(event.target.value)).map((item:INode) => item.Floors);
      this.setState({
        floors:_getFloor[0],
      })
  };

  handleSpaceChange =  async (event:React.SyntheticEvent<Element, Event>,value:INodeCategory) => {
    this.setState({
      copySpaceList: {
        Node_Id: value.Node_Id,
        Space_Id: value.Space_Id,
        Space_Type: value.Space_Type
      }
    })
    const _allCategories = await this.handleGetSelectList();
    const _categories = _allCategories.filter((item: ICategories) => item.Space_Type === value.Space_Type)
    .sort((a: ICategories, b: ICategories) => a.task_Category_Name.localeCompare(b.task_Category_Name, 'en', { sensitivity: 'base' }));
    this.setState({
      categories: _categories,
      spaceId: value.Space_Id
    })
  };

  handleFloorChange = async (event:React.SyntheticEvent<Element, Event>, value:IFloor) => {
    const val = await apis.getSpaceByFloorId(this.state.selectedBuilding,value.Node_Id);
    this.setState({
      spaceList: val.data
    })
  };

  categoryChange = async (event: SelectChangeEvent) => {
    this.setState({
      categoryId: event.target.value,
    })
    const res = await apis.getTaskTypesByCategoryIdfacility(this.state.selectedBuilding, event.target.value)
    this.setState({
      spaceTypes: res.data
    })
  }

  spaceTypeChange = (event: React.SyntheticEvent<Element, Event>, value: SpaceType) => {
    this.setState({
      taskTypeId: value.Task_Type_Id
    })
  }


  render() {
    const { history, match }: any = this.props;

    const confModalData = this.state.isDelete ? {
      name: "",
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: "",
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => { history.push("/operational-services-tasks/" + this.state.buildingId) },
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }


    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabProperties,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        }
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };

    return (
      <>
        {(this.state.isLoadingToSave || this.state.taskCategoryLoading) && <LoadingOverlay />}
        <div className="rightPanel-main-content-form">
          {this.state.show ? (
            <ConfirmModal {...confModalData} />
          ) : ""}
          <div className="form-panel ">
            <FormPanelInfo
              formHeading={this.labels.HubLabelTaskDetails}
              iconSrc="/images/Sidebar_Icons/Light_theme/Tasks.svg"
            />
            {this.state.loading || (
              <div className="form-panel-form">
                <form>
                  <Grid container spacing={2}>
                    <Grid item sm={7}>
                      <Grid container spacing={3}>
                      <Grid item sm={12}>
                        <Typography variant="body1" className="my-0">{this.labels.HubLabelTaskDetailsAdd}</Typography>
                        </Grid>
                        <Grid item sm={12}>
                          {this.state.isEdit ?
                            <TextField id="outlined-basic" disabled value={this.state.buildingNameById} size="small" label={this.labels.HubLabelBuilding} variant="outlined" fullWidth /> :
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">{this.labels.HubLabelBuilding}</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedBuilding}
                                label={this.labels.HubLabelBuilding}
                                onChange={this.buildingChange}
                              >
                                {this.state.building.map((item: ISelectedBuilding) =>
                                  <MenuItem value={item.Node_Id}>{item.Name}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          }
                        </Grid>

                        {/* <div className="form-input-box"> */}
                        <Grid item sm={6}>
                          {this.state.isEdit ?
                            <TextField id="outlined-basic" disabled value={this.state.floorNameById} size="small" label={this.labels.HubLabelFloor} variant="outlined" fullWidth />
                            :
                            <IbssAutocomplete
                              disablePortal
                              size="small"
                              id="combo-box-demo"
                              options={this.state.floors as IFloor[]}
                              onChange={this.handleFloorChange}
                              getOptionLabel={(option:IFloor) => option.Node_Name}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.Node_Name}
                                </Box>
                              )}
                              renderInput={(params) => <TextField {...params} label={this.labels.HubLabelFloor} />}
                            />}
                        </Grid>
                        {/* </div> */}
                        <Grid item sm={6}>
                          {this.state.isEdit ?
                            <TextField id="outlined-basic" disabled value={this.state.editSpaceName} size="small" label={this.labels.HubLabelSpaces} variant="outlined" fullWidth />
                            :
                            <IbssAutocomplete
                              disablePortal
                              id="combo-box-demo"
                              size="small"
                              options={this.state.spaceList as ISpaces[]}
                              getOptionLabel={(option: ISpaces) => option.Space_Id}
                              renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.Space_Id}
                                </Box>
                              )}
                              value={this.state.spaceList.find((space:ISpaces) => space.Space_Id === this.state.spaceId)}
                              onChange={this.handleSpaceChange}
                              renderInput={(params) => <TextField{...params} label={this.labels.HubLabelSpaces} />}
                            />}
                        </Grid>

                        <Grid item sm={12}>
                          {this.state.isEdit ?
                            <TextField id="outlined-basic" disabled value={this.state.editCategoryName} size="small" label={this.labels.HubLabelCategories} variant="outlined" fullWidth />
                            :
                            <FormControl fullWidth size="small">
                              <InputLabel id="demo-simple-select-label">{this.labels.HubLabelCategories}</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                displayEmpty
                                value={this.state.categoryId}
                                label={this.labels.HubLabelCategories}
                                onChange={this.categoryChange}
                              >
                                {this.state.categories.map((item: ICategories) =>
                                  <MenuItem value={item.task_Category_Id}>{item.task_Category_Name}</MenuItem>
                                )}
                              </Select>
                            </FormControl>}
                        </Grid>

                        <Grid item sm={12}>
                          {this.state.isEdit ?
                            <TextField id="outlined-basic" disabled value={this.state.editSpaceTypeName} size="small" label={this.labels.HubLabeltaskType} variant="outlined" fullWidth />
                            :
                            <IbssAutocomplete
                              id="combo-box-demo"
                              size="small"
                              options={this.state.spaceTypes as SpaceType[]}
                              getOptionLabel={(option: SpaceType) => option.Task_Type_Name}
                              renderOption={(props, option: SpaceType) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.Task_Type_Name}
                                </Box>
                              )}
                              onChange={this.spaceTypeChange}
                              renderInput={(params) => <TextField {...params} label={this.labels.HubLabeltaskType} />}
                            />
                          }
                        </Grid>
                        <Grid item sm={12}>
                          <TextField
                            onChange={(e) => this.setState({
                              description: e.target.value
                            })}
                            label={this.labels.HubLabelDescription}
                            multiline
                            id="outlined-basic" variant="outlined"
                            size="small"
                            fullWidth
                            value={this.state.description}
                          />
                         </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={5}></Grid>
                </form>
              </div>
            )}
          </div>

        </div>

        <div className="rightPanel-main-button-group">
          <div>
            
            {/* {(this.state.Task_Id !== "0" && this.state.isDeleteRight === false) &&
              <IbssButtonRedo color="primary" variant="contained" className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</IbssButtonRedo>
            } */}
            {/* {(this.state.Task_Id !== "0" && this.state.isDeleteRight === true) &&
              <IbssButtonRedo color="primary" variant="contained" className="mr-2">{this.labels.HubButtonDelete}</IbssButtonRedo>
            } */}
          </div>
          <div>
          <IbssButtonRedo color="secondary" variant="contained" className="mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
            {this.state.isUpdateRight === false &&
              <IbssButtonRedo color="primary" variant="contained" className="ml-2" disabled={true}>{this.labels.HubButtonSave}</IbssButtonRedo>
            }
            {this.state.isUpdateRight === true &&
              <IbssButtonRedo color="primary" variant="contained" className="ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</IbssButtonRedo>
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};
export default connect(mapStateToProps)(TaskDetails);


interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}