import { ChangeEvent, Component } from "react";
import "../../../styles/css/searchspace.scss";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import IbssTimePicker from "../../../Components/uicomponents/IbssTimePicker";
import { Grid, TextField } from "@mui/material";
import { getUserPrefrenceJosn } from "../../../Common/UserPreferenceJSON";
import { getWkngDaysBySelectedBuilding } from "../../../Common/Helper";
import { IUserPreferences } from "../../../Providers.Api/UserPreferenceRepository";

export default class OnBoardingWorkingDay extends Component<IOnBoardingWorkingDay, State>
{
    private labels = appContext().labels;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;
    private incrementActiveStepProp;

    constructor(props: IOnBoardingWorkingDay) {
        super(props);

        this.incrementActiveStepProp = props.incrementActiveStep;
        this.state = new State();
    }
    public componentDidMount() {

        this.setState({ isLoading: true });
        const response = this.local.getUserPreferences();
        this.setState({ isLoading: false, BuildingSpecificNotification: response });
    };

    submitBuildingTime = async () => {
        const startDate = new Date(this.props.startTime as Date);
        const endDate = new Date(this.props.endTime as Date);

        const startTime = `${(startDate.getHours() < 10 ? '0' : '') + startDate.getHours()}:${(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}`;
        const endTime = `${(endDate.getHours() < 10 ? '0' : '') + endDate.getHours()}:${(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}`

        const payload =
        {
            ...this.local.getUserPreferences(),
            WorkingHoursPrefs:
            {
                UserStartTime: startTime,
                UserEndTime: endTime
            },
        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.incrementActiveStepProp();
            }
        }
        catch { }
    }



    public render(): JSX.Element {

        return (<>
            <div className="px-50">
                <h2 className="ibss-modal-title mb-10"> {this.labels.HubLabelYourWorkingDay}</h2>
                <p className="ibss-modal-text-md"> {this.labels.HubLabelWorkingHrs} </p>

                <Grid container spacing={2} className="mb-34">
                    <Grid item xs={6}>
                        <div className="ibss-label-bold-md">{this.labels.HubLabelStarttext}</div>

                        <IbssTimePicker
                            onChange={(e) => this.props.onStartTimeChange(e)}
                            value={this.props.startTime}
                            ampm
                            minutesStep={15}
                            renderInput={(params) => {
                                const { sx, ...paramsMinusSx } = params
                                return <TextField 
                                            {...paramsMinusSx}
                                            inputProps={{...params.inputProps, readOnly: true,}} // stop user from using entering a time via key strokes. 
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <div className="ibss-label-bold-md">{this.labels.HubLabelEndtext}</div>
                        <IbssTimePicker
                            value={this.props.endTime}
                            onChange={(e) => this.props.onEndTimeChange(e)}
                            ampm
                            minutesStep={15}
                            renderInput={(params) => {
                                const { sx, ...paramsMinusSx } = params
                                return <TextField 
                                            {...paramsMinusSx}
                                            inputProps={{...params.inputProps, readOnly: true,}}  // stop user from using entering a time via key strokes.
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                            }}
                        />
                    </Grid>
                </Grid>
                <IbssButton
                    color="primary" variant="contained"
                    onClick={() => this.submitBuildingTime()}
                > {this.labels.HubButtonNext} </IbssButton>
            </div>
        </>);
    }
}

class State {
    public startTime: any = "";
    public endTime: any = "";
    public isLoading = false;
    public BuildingSpecificNotification = getUserPrefrenceJosn();


}

export interface IOnBoardingWorkingDay {
    incrementActiveStep?: any;
    startTime: any,
    endTime: any,
    onStartTimeChange: any,
    onEndTimeChange: any,
    userPrefrences: IUserPreferences;
}

