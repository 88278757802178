import React, { Component } from 'react'
import { appContext } from '../../../AppContext';

export default class NoNotificationPage extends Component {
  private labels = appContext().labels;
  render() {
    return (
      <div className="notification-placeholder">
        <div className="text-center">
        <div className="notification-label-lg">{this.labels.HubLabelNoEquipment}</div>
        <div className="text-light-md">{this.labels.HubLabelNoEquipmentPara}</div>
        </div>
      </div>
    )
  }
}
