import { Component } from 'react';
import './BookingPolicy.scss'
import { Card, Grid, TextField } from '@mui/material';
import { Icons } from '../../../Common/AllsvgIcons';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import { IPartialAppState, appContext } from '../../../AppContext';
import IbssTextField from '../../../Components/uicomponents/IbssTextField';
import IbssHorizontalTabs, { TabsParameters } from '../../../Components/uicomponents/IbssTabs';
import IbssInputDropDown from '../../../Components/uicomponents/IbssInputDropDown';
import IbssFormControl from '../../../Components/uicomponents/IbssFormControl';
import IbssRadioButton from '../../../Components/uicomponents/IbssRadioButton';
import IbssSwitchLabel from '../../../Components/uicomponents/IbssSwitchLabel';
import IbssButton from '../../../Components/uicomponents/IbssButton';
import IbssDataGrid from '../../../Components/uicomponents/IbssDataGrid';
import DateSelectModal from '../../../Components/uicomponents/DateSelectModal';
import Spinner from '../../../Components/Spinner';
import IbssTimePicker from '../../../Components/uicomponents/IbssTimePicker';
import moment from 'moment';
import { ComponentHelper } from '../../../Common/ComponentHelper';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid/models';
import IbssTransferList from '../../../Components/uicomponents/IbssTransferList';
import React from 'react';
import { Modal } from 'react-bootstrap';
import TimeSelectList from '../../../Components/uicomponents/TimeSelectList';
import IbssActionButton from '../../../Components/uicomponents/IbssActionButton';
import { DateTime } from 'luxon';
import { RouteComponentProps } from 'react-router-dom';
import { IBookingPolicy } from '../../../Providers.Api/BookingPolicies/BookingPolicyRepository';

export default class EditBookingPolicy extends Component<RouteComponentProps<IMatchParams>, IState>
{
    private labels = appContext().labels;
    private apiClient = appContext().apiClient;
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);
    private local = appContext().localStorageProvider;
    private appState = appContext().state;
    private fixedTimeLimitsTransferListRef: React.RefObject<IbssTransferList>;
    private approvalGroupsTransferListRef: React.RefObject<IbssTransferList>;
    private userCanUpdate: boolean;
    private columns: IColumn[];

    constructor(props: RouteComponentProps<IMatchParams>)
    {
        super(props);
        this.userCanUpdate = this.local.hasRight('DATAMODEL.BookingPolicies.Update');
        this.state = {
            buildingId: -1,
            bookingPolicyId: '',
            bookingPolicyName: '',
            bookingPolicyDescription: '',
            bookingSlots: {
                BookableTime: [],
                ExcludedDates: [],
                BookingStart: { SpecificMinutes: [], SpecificTimes: [] },
                BookingEnd: { SpecificMinutes: [], SpecificTimes: [] },
                BookingDuration: {
                    Minimum: 0,
                    Maximum: 30,
                    Fixed: []
                },
                BookingHorizon: {
                    Minimum: 0,
                    Maximum: 0
                },
                BookingRecurrence: {
                    Maximum: 0,
                    Allowed: false
                }
            },
            arrivalPolicies: {
                AutoCheckin: false,
                EarlyCheckin: 0,
                AutoCancellation: 0,
            },
            approvalPolicies: {
                ApprovalRequired: false,
                AutoRejectionAfter: 0,
                ApprovalGroups: []
            },
            bookingDurationType: 'configurable',
            bookingStartAndEndType: 'repeating',
            formattedBookableTime: [{ dayId: '', times: [] }],
            autoCancelEnabled: false,
            showDateModal: false,
            loading: false,
            showErrors: false,
            hasValidationErrors: false,
            erroredFields: {
                name: false,
                description: false,
                slotsOverlap: false,
                slotsInvalidTimeRange: false,
                configurableDurationLimits: false,
                earlyCheckin: false,
                autoCancellation: false,
                horizon: false,
                autoRejection: false,
                approvalGroups: false,
                recurrence: false
            },
            selectedExcludedDateIds: [] as GridRowSelectionModel,
            dateEdit: false,
            showFixedTimesModal: false,
            showApprovalGroupsModal: false,
            showSpecificStartModal: false,
            showSpecificEndModal: false,
            approvalGroups: [],
            approvalGroupsSearchFilter: '',
            showSpecificTimesWarningModal: false,
            specificTimesWarningShown: false,
        }
        this.fixedTimeLimitsTransferListRef = React.createRef();
        this.approvalGroupsTransferListRef = React.createRef();

        this.columns = [
            {
                field: 'StartDate',
                headerName: this.labels.HubLabelFromDate,
                width: 150
            },
            {
                field: 'EndDate',
                headerName: this.labels.HubLabelToDate,
                width: 150,
            },
            {
                field: 'StartTime',
                headerName: this.labels.HubLabelFromTime,
                width: 150,
            },
            {
                field: 'EndTime',
                headerName: this.labels.HubLabelToTime,
                width: 120,
            },
        ];

    }

    public async componentDidMount(): Promise<void>
    {
        const { match } = this.props;
        const approvalGroups = await this.apiClient.roles.getRoles();
        this.setState({ approvalGroups: approvalGroups.map(x => {return({name: x.name, id: x.id})})});

        if (match.params.bookingId)
        {
            this.setState({ loading: true });
            const bookingPolicy = await this.apiClient.bookingPolicies.getBookingPolicy(this.appState.buildingId, match.params.bookingId);
            const policyJson = JSON.parse(bookingPolicy.Booking_Policy.toString());

            const selectedApprovalGroupObjects = this.state.approvalGroups.filter(group => policyJson.ApprovalPolicies.ApprovalGroups.includes(group.id));

            this.setState({
                buildingId: bookingPolicy.Node_Id,
                bookingPolicyId: bookingPolicy.Booking_Policy_Id || '',
                bookingPolicyName: bookingPolicy.Booking_Policy_Name,
                bookingPolicyDescription: policyJson.Booking_Policy_Description,
                bookingSlots: {
                    BookableTime: policyJson.BookingSlots.BookableTime,
                    ExcludedDates: policyJson.BookingSlots.ExcludedDates.map((x: IExcludedDate) =>
                    {
                        return ({
                            id: x.StartDate.toString() + Math.random(),
                            StartDate: x.StartDate,
                            StartTime: x.StartTime,
                            EndDate: x.EndDate,
                            EndTime: x.EndTime,
                        })
                    }),
                    BookingStart: policyJson.BookingSlots.BookingStart,
                    BookingEnd: policyJson.BookingSlots.BookingEnd,
                    BookingDuration: policyJson.BookingSlots.BookingDuration,
                    BookingHorizon: policyJson.BookingSlots.BookingHorizon,
                    BookingRecurrence: policyJson.BookingSlots.BookingRecurrence
                },
                arrivalPolicies: {
                    AutoCheckin: policyJson.ArrivalPolicies.AutoCheckin,
                    EarlyCheckin: policyJson.ArrivalPolicies.EarlyCheckin,
                    AutoCancellation: policyJson.ArrivalPolicies.AutoCancellation,
                },
                approvalPolicies: {
                    ApprovalRequired: policyJson.ApprovalPolicies.ApprovalRequired,
                    AutoRejectionAfter: policyJson.ApprovalPolicies.AutoRejectionAfter,
                    ApprovalGroups: selectedApprovalGroupObjects
                },
                autoCancelEnabled: policyJson.ArrivalPolicies.AutoCancellation > 0,
                bookingStartAndEndType: policyJson.BookingSlots.BookingStart.SpecificTimes?.length > 0 ? 'specific' : 'repeating',
                bookingDurationType: policyJson.BookingSlots.BookingDuration.Fixed.length > 0 ? 'fixed' : 'configurable',
            })
            this.setState({ loading: false });
        }
        else
        {
            this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        }

        this.setState({ formattedBookableTime: this.groupSlotsDataByDay() });
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != -1 && prevState.buildingId != this.state.buildingId && this.state.bookingPolicyId == '')
        {
            const { history, match } = this.props;
            history.push(match.path.replace(":buildingId", this.appState.buildingId.toString()));
        }
    }

    private groupSlotsDataByDay(): IFormattedBookableTime[]
    {
        const { bookingSlots, bookingPolicyId } = this.state;
        let reformattedArray = [
            { dayId: '1', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '2', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '3', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '4', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '5', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '6', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] },
            { dayId: '7', times: [{ StartTime: '00:00', EndTime: '23:59', enabled: false }] }];

        if (bookingPolicyId != '')
        {
            for (let index = 0; index < bookingSlots.BookableTime.length; index++)
            {
                const element = bookingSlots.BookableTime[index];
                element.Days.forEach((day: string) =>
                {
                    if (JSON.stringify(reformattedArray[reformattedArray.findIndex(x => x.dayId == day)].times[0]) == JSON.stringify({ StartTime: '00:00', EndTime: '23:59', enabled: false }))
                    {
                        reformattedArray[reformattedArray.findIndex(x => x.dayId == day)].times = [{ StartTime: element.StartTime, EndTime: element.EndTime, enabled: true }];
                    }
                    else
                    {
                        reformattedArray[reformattedArray.findIndex(x => x.dayId == day)].times.push({ StartTime: element.StartTime, EndTime: element.EndTime, enabled: true });
                    }
                }
                );
            };
        }

        return reformattedArray;

    }

    private groupSlotsDataByTime(): IBookableTime[]
    {
        const { formattedBookableTime } = this.state;

        let reformattedArray: IBookableTime[];
        reformattedArray = [];

        for (let index = 0; index < formattedBookableTime.length; index++)
        {
            const element = formattedBookableTime[index];
            element.times.forEach((time: { StartTime: string; EndTime: string; enabled?: boolean }) =>
            {
                const itemIndex = reformattedArray.findIndex(x => x.StartTime == time.StartTime && x.EndTime == time.EndTime);
                //if obj with matching times exists add the day to the days array
                if (itemIndex >= 0 && time.enabled == true)
                {
                    reformattedArray[itemIndex].Days.push(element.dayId);
                    return;
                }
                //if obj with matching times does not exist than create new obj
                if (itemIndex == -1 && time.StartTime != null && time.EndTime != null && time.enabled == true)
                {
                    reformattedArray.push({ Days: [element.dayId], StartTime: time.StartTime, EndTime: time.EndTime });
                    return;
                }
            });

        }

        return reformattedArray;

    }

    private addSlot(dayId: string): void
    {
        const { formattedBookableTime } = this.state;

        const slotDayIndex = formattedBookableTime.findIndex(x => x.dayId == dayId);
        const slotsBookableTime = [...formattedBookableTime];
        const item = [...slotsBookableTime[slotDayIndex].times];

        item.push({ StartTime: '00:00', EndTime: '23:59', enabled: false });
        slotsBookableTime[slotDayIndex].times = item;

        this.setState({ formattedBookableTime: slotsBookableTime });
    }

    private slotInputChanged(dayId: string, startTime: string, endTime: string, inputType: string, index: number, toggle?: boolean): void
    {
        const { formattedBookableTime } = this.state;

        const slotDayIndex = formattedBookableTime.findIndex(x => x.dayId == dayId);
        const slotsBookableTime = [...formattedBookableTime];
        const item = { ...slotsBookableTime[slotDayIndex] };

        if (inputType == 'startTime')
        {
            item.times[index].StartTime = startTime;
        }
        if (inputType == 'endTime')
        {
            item.times[index].EndTime = endTime;
        }
        if (inputType == 'toggle')
        {
            item.times[index].enabled = toggle;
        }

        slotsBookableTime[slotDayIndex] = item;
        this.setState({ formattedBookableTime: slotsBookableTime });
    }

    private durationLimitsTabChange(value: number): void
    {
        if (value == 0)
        {
            this.setState({ bookingDurationType: 'configurable' });
        }
        if (value == 1)
        {
            this.setState({ bookingDurationType: 'fixed' });
        }
    }

    private createRepeatingIntervals(value: number, field: 'start' | 'end'): void
    {
        let total = 0;
        let intervals: string[] = [];
        while (total < 60)
        {
            if (total + value < 60)
            {
                intervals.push((total + value).toString());
            }
            if (total + value == 60)
            {
                intervals = ['0', ...intervals];
            }
            total = total + value;
        }
        if (field == 'start')
        {
            this.setState({ bookingSlots: { ...this.state.bookingSlots, BookingStart: { ...this.state.bookingSlots.BookingStart, SpecificMinutes: intervals } } })
        }
        if (field == 'end')
        {
            this.setState({ bookingSlots: { ...this.state.bookingSlots, BookingEnd: { ...this.state.bookingSlots.BookingEnd, SpecificMinutes: intervals } } })
        }
    }

    private prepareRequestObj(): IBookingPolicy
    {
        const policy: IBookingPolicy =
        {
            Node_Id: this.appState.buildingId,
            Booking_Policy_Name: this.state.bookingPolicyName,
            Booking_Policy_Id: this.state.bookingPolicyId,
            Booking_Policy: '',
            Booking_Policy_Object:
            {
                Booking_Policy_Description: this.state.bookingPolicyDescription,
                BookingSlots:
                {
                    ...this.state.bookingSlots,
                    BookableTime: this.groupSlotsDataByTime(),
                    BookingDuration:
                    {
                        Minimum: this.state.bookingDurationType == 'configurable' ? this.state.bookingSlots.BookingDuration.Minimum : 0,
                        Maximum: this.state.bookingDurationType == 'configurable' ? this.state.bookingSlots.BookingDuration.Maximum : 0,
                        Fixed: this.state.bookingDurationType == 'fixed' ? this.state.bookingSlots.BookingDuration.Fixed : []
                    },
                    BookingStart:
                    {
                        SpecificMinutes: this.state.bookingStartAndEndType == 'repeating' ? this.state.bookingSlots.BookingStart.SpecificMinutes : [],
                        SpecificTimes: this.state.bookingStartAndEndType == 'specific' ? this.state.bookingSlots.BookingStart.SpecificTimes : [],
                    },
                    BookingEnd:
                    {
                        SpecificMinutes: this.state.bookingStartAndEndType == 'repeating' ? this.state.bookingSlots.BookingEnd.SpecificMinutes : [],
                        SpecificTimes: this.state.bookingStartAndEndType == 'specific' ? this.state.bookingSlots.BookingEnd.SpecificTimes : [],
                    },
                    ExcludedDates: this.state.bookingSlots.ExcludedDates,
                },
                ArrivalPolicies: this.state.arrivalPolicies,
                ApprovalPolicies: {...this.state.approvalPolicies, ApprovalGroups: this.state.approvalPolicies.ApprovalGroups.map(group => group.id)}
            }
        };
        return policy;
    }

    private validateFields(): IErroredFields
    {
        const { bookingPolicyName, bookingPolicyDescription, formattedBookableTime, bookingDurationType, bookingSlots, bookingStartAndEndType, arrivalPolicies, approvalPolicies, autoCancelEnabled } = this.state;

        //validating for overlapping dates or from > to in slots
        let overlapExists = false;
        let invalidTimeRangExists = false;
        let index = 0;
        while (overlapExists == false && invalidTimeRangExists == false && index < 7)
        {
            const activeTimeSlots = formattedBookableTime[index]?.times?.filter((x: {
                EndTime: null;
                StartTime: null; enabled: boolean;
            }) => x.enabled == true && x.StartTime != null && x.EndTime != null);
            if (activeTimeSlots?.length > 0)
            {
                overlapExists = this.isOverlapping(activeTimeSlots);
                invalidTimeRangExists = activeTimeSlots?.filter((x: { StartTime: string; EndTime: string }) => x.StartTime.replace(':', '') > x.EndTime.replace(':', '')).length > 0;
            }
            index += 1;
        }

        let fieldValidation = {
            name: !(bookingPolicyName.length > 0),
            description: !(bookingPolicyDescription.length > 0),
            slotsOverlap: overlapExists,
            slotsInvalidTimeRange: invalidTimeRangExists,
            configurableDurationLimits: bookingDurationType == 'configurable' && (bookingSlots.BookingDuration.Minimum > bookingSlots.BookingDuration.Maximum),
            earlyCheckin: arrivalPolicies.EarlyCheckin < 0 || arrivalPolicies.EarlyCheckin > 60,
            autoCancellation: autoCancelEnabled && (arrivalPolicies.AutoCancellation < 10 || arrivalPolicies.AutoCancellation > 60),
            horizon: bookingSlots.BookingHorizon.Minimum < 0 || bookingSlots.BookingHorizon.Maximum < bookingSlots.BookingHorizon.Minimum,
            autoRejection: approvalPolicies.AutoRejectionAfter < 0 || approvalPolicies.AutoRejectionAfter > bookingSlots.BookingHorizon.Minimum,
            approvalGroups: approvalPolicies.ApprovalRequired && approvalPolicies.ApprovalGroups.length == 0,
            recurrence: bookingSlots.BookingRecurrence?.Allowed && (bookingSlots.BookingRecurrence.Maximum < 1 || bookingSlots.BookingRecurrence.Maximum > 52)
        };

        return fieldValidation;
    }

    private async handleOnSave(): Promise<void>
    {
        const { bookingPolicyId, bookingStartAndEndType, specificTimesWarningShown } = this.state;
        await this.setStateAsync({ loading: true, showErrors: true, erroredFields: this.validateFields() });

        if (!(Object.values(this.state.erroredFields).includes(true)))
        {
            if ((bookingStartAndEndType == 'specific') && specificTimesWarningShown == false)
            {
                this.setState({ showSpecificTimesWarningModal: true, specificTimesWarningShown: true, loading: false, showErrors: false })
                return;
            }
            if (bookingPolicyId != '')
            {
                await this.apiClient.bookingPolicies.updateBookingPolicy(this.prepareRequestObj());
                this.setState({ loading: false, showErrors: false });
                this.props.history.push("/booking-policies/" + this.appState.buildingId)
                return;
            }
            await this.apiClient.bookingPolicies.createBookingPolicy(this.prepareRequestObj());
            this.setState({ loading: false, showErrors: false });
            this.props.history.push("/booking-policies/" + this.appState.buildingId)
            return;
        }
        this.setState({ loading: false })

    }

    private createOptionsArray(values: number[])
    {
        return values.map((value: number) =>
        {
            return (
                { label: value.toString(), value: value }
            )
        })
    }

    private getMinutes(s: string): number
    {
        const p = s.split(':').map(Number);
        return p[0] * 60 + p[1];
    };

    private overlapping(a: { EndTime: string; StartTime: string; }, b: { StartTime: string; EndTime: string; }): boolean
    {
        if (a.StartTime && a.EndTime && b.StartTime && b.EndTime)
        {
            return this.getMinutes(a.EndTime) > this.getMinutes(b.StartTime) && this.getMinutes(b.EndTime) > this.getMinutes(a.StartTime);
        }
        return false;
    };

    private isOverlapping(arr: string | any[]): boolean
    {
        let i, j;
        for (i = 0; i < arr.length - 1; i++)
        {
            for (j = i + 1; j < arr.length; j++)
            {
                if (this.overlapping(arr[i], arr[j]))
                {
                    return true;
                }
            };
        };
        return false;
    };

    private removeExcludedDates(): void
    {
        let valuesArr = this.state.bookingSlots.ExcludedDates;
        let removeValFromIndex = this.state.selectedExcludedDateIds;

        for (let index = 0; index < removeValFromIndex.length; index++)
        {
            valuesArr = valuesArr.filter((x: IExcludedDate) => x.id != removeValFromIndex[index])

        }

        const updateExcludedDates = valuesArr;

        this.setState({
            bookingSlots: {
                ...this.state.bookingSlots, ExcludedDates: updateExcludedDates
            }
        })
    }

    private editExcludedDate(newDateObj: { id: string; StartDate: string; StartTime: string; EndDate: string; EndTime: string; }): void
    {
        const excludedDates = [...this.state.bookingSlots.ExcludedDates];
        const editIndex = excludedDates.findIndex((x: { id: GridRowId; }) => x.id == this.state.selectedExcludedDateIds[0]);
        excludedDates[editIndex] = newDateObj;
        this.setState({ bookingSlots: { ...this.state.bookingSlots, ExcludedDates: excludedDates }, showDateModal: false, dateEdit: false });
    }

    private selectionModelChange(rowPolicyIds: GridRowSelectionModel): void
    {
        this.setState({ selectedExcludedDateIds: rowPolicyIds });
    }

    private updateFixedDurations(): void
    {
        const transferListState = this.fixedTimeLimitsTransferListRef.current?.state
        if (transferListState === undefined)
        {
            return;
        }

        this.setState({ bookingSlots: { ...this.state.bookingSlots, BookingDuration: { ...this.state.bookingSlots.BookingDuration, Fixed: transferListState.right.map(x => x.primaryText) } }, showFixedTimesModal: false })
    }

    private updateSelectedApprovalGroups(): void
    {
        const transferListState = this.approvalGroupsTransferListRef.current?.state
        if (transferListState === undefined)
        {
            return;
        }
        const selectedApprovalGroupObjects = this.state.approvalGroups.filter(group => transferListState.right.map(x => x.primaryText).includes(group.name));
        this.setState({ approvalPolicies: { ...this.state.approvalPolicies, ApprovalGroups: selectedApprovalGroupObjects }, showApprovalGroupsModal: false })
    }

    private filterApprovalGroups(): void
    {
        const transferListState = this.approvalGroupsTransferListRef.current
        if (transferListState === undefined)
        {
            return;
        }
        let groups = this.state.approvalGroups.map(group => group.name);
        groups = groups.filter(x => x.includes(this.state.approvalGroupsSearchFilter) && !transferListState?.state.right.map(x => x.primaryText).includes(x));
        transferListState?.setState({ left: groups.map(x => { return ({ primaryText: x, secondaryText: '' }) }) })
    }

    private datesChanged(start: DateTime, end: DateTime): void
    {
        const id = start.toISO() + Math.random();
        const startDate = start.toFormat("yyyy-MM-dd").toLocaleString();
        const startTime = start.toLocaleString(DateTime.TIME_24_SIMPLE);
        const endDate = end.toFormat("yyyy-MM-dd").toLocaleString();
        const endTime = end.toLocaleString(DateTime.TIME_24_SIMPLE);

        if (this.state.dateEdit)
        {
            this.editExcludedDate(
                {
                    id: id,
                    StartDate: startDate,
                    StartTime: startTime,
                    EndDate: endDate,
                    EndTime: endTime,
                });
        }
        else
        {
            this.setState(
                {
                    bookingSlots:
                    {
                        ...this.state.bookingSlots,
                        ExcludedDates:
                            [
                                ...this.state.bookingSlots.ExcludedDates,
                                {
                                    id: id,
                                    StartDate: startDate,
                                    StartTime: startTime,
                                    EndDate: endDate,
                                    EndTime: endTime,
                                }
                            ]
                    }
                },
                () => this.setState({ showDateModal: false, dateEdit: false }));
        }
    }

    private startIntervalsChanged(times: DateTime[]): void
    {
        const timesAsStrings = times.map(i => i.toFormat("HH:mm"));
        this.setState(
            {
                showSpecificStartModal: false,
                bookingSlots:
                {
                    ...this.state.bookingSlots,
                    BookingStart:
                    {
                        ...this.state.bookingSlots.BookingStart,
                        SpecificTimes: timesAsStrings,
                    }
                },
            });
    }

    private endIntervalsChanged(times: DateTime[]): void
    {
        const timesAsStrings = times.map(i => i.toFormat("HH:mm"));
        this.setState(
            {
                showSpecificEndModal: false,
                bookingSlots:
                {
                    ...this.state.bookingSlots,
                    BookingEnd:
                    {
                        ...this.state.bookingSlots.BookingEnd,
                        SpecificTimes: timesAsStrings
                    }
                },
            });
    }

    private getSpecificMinutesDefaultValue(values: string[]): string
    {
        if(values.length == 0)
        {
            return '61'; //Any
        }
        if(values[0] == '0' && values.length == 1)
        {
            return '60';
        }
        if (values[0] != '0' && values.length > 0)
        {
            return values[0];
        }
        else{
            return values[1];
        }
    }

    public render(): JSX.Element
    {
        const { bookingPolicyId, bookingPolicyName, bookingPolicyDescription, bookingSlots, formattedBookableTime, arrivalPolicies, autoCancelEnabled, approvalPolicies, showDateModal, bookingStartAndEndType, showErrors, erroredFields } = this.state;

        const nameFieldError = showErrors && erroredFields.name;
        const descriptionFieldError = showErrors && erroredFields.description;
        const slotsOverlapError = showErrors && erroredFields.slotsOverlap;
        const slotsInvalidTimeRangeError = showErrors && erroredFields.slotsInvalidTimeRange;
        const configurableDurationLimitsError = showErrors && erroredFields.configurableDurationLimits;
        const earlyCheckinError = showErrors && erroredFields.earlyCheckin;
        const autoCancellationError = showErrors && erroredFields.autoCancellation;
        const horizonError = showErrors && erroredFields.horizon;
        const autoRejectionError = showErrors && erroredFields.autoRejection;
        const approvalGroupsError = showErrors && erroredFields.approvalGroups;
        const recurrenceError = showErrors && erroredFields.recurrence;

        const weekdaysObject = {
            1: this.labels.HubLabelMonday,
            2: this.labels.HubLabelTuesday,
            3: this.labels.HubLabelWednesday,
            4: this.labels.HubLabelThursday,
            5: this.labels.HubLabelFriday,
            6: this.labels.HubLabelSaturday,
            7: this.labels.HubLabelSunday
        };

        const tabs: TabsParameters[] = [
            {
                label: (
                    <div>
                        {this.labels.HubLabelConfigurableLimits}
                    </div>
                ),
                components: (
                    <div className='d-flex mt-1' style={{ gap: '5px' }}>
                        <IbssFormControl style={{ flex: 'auto' }} >
                            <IbssInputDropDown
                                error={configurableDurationLimitsError}
                                onChange={(e: { target: { value: number; }; }) => this.setState({ bookingSlots: { ...bookingSlots, BookingDuration: { ...this.state.bookingSlots.BookingDuration, Minimum: e.target.value } } })}
                                helperText={configurableDurationLimitsError ? this.labels.HubLabelBookingPolicyMinDurationError : this.labels.HubLabelMinutes}
                                inputLabel={this.labels.HubLabelMinimumDuration}
                                id='minimumDuration'
                                options={this.createOptionsArray([0, 10, 15, 30, 45, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600, 630, 660, 690, 720])}
                                defaultValue={bookingSlots.BookingDuration.Minimum}
                            />
                        </IbssFormControl>
                        <IbssFormControl style={{ flex: 'auto' }}>
                            <IbssInputDropDown
                                error={configurableDurationLimitsError}
                                onChange={(e: { target: { value: number; }; }) => this.setState({ bookingSlots: { ...bookingSlots, BookingDuration: { ...this.state.bookingSlots.BookingDuration, Maximum: e.target.value } } })}
                                helperText={configurableDurationLimitsError ? this.labels.HubLabelBookingPolicyMaxDurationError : this.labels.HubLabelMinutes}
                                inputLabel={this.labels.HubLabelMaximumDuration}
                                id='maximumDuration'
                                options={this.createOptionsArray([30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900, 930, 960, 990, 1020, 1050, 1080, 1110, 1140, 1170, 1200, 1230, 1260, 1290, 1320, 1350, 1380, 1410, 1440])}
                                defaultValue={bookingSlots.BookingDuration.Maximum}
                            />
                        </IbssFormControl>
                    </div>
                ),
            },
            {
                label: (
                    <div>
                        {this.labels.HubLabelFixedTimeLimits}
                    </div>
                ),
                components: (
                    <>
                        <div>
                            <IbssButton className='mr-2' style={{ minWidth: '100px' }} variant="contained" onClick={() => this.setState({ showFixedTimesModal: true })}>{this.labels.HubLabelSet}...</IbssButton>
                            {this.state.bookingSlots.BookingDuration.Fixed.toString()}
                        </div>
                        <Modal show={this.state.showFixedTimesModal} onHide={() => this.setState({ showFixedTimesModal: false })}>
                            <Modal.Header>
                                <Modal.Title>{this.labels.HubLabelFixedDurationLimits}</Modal.Title>
                                <button type="button" className="close" onClick={() => this.setState({ showFixedTimesModal: false })} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </Modal.Header>
                            <div className='mt-2 mb-3'>
                                <IbssTransferList
                                    ref={this.fixedTimeLimitsTransferListRef}
                                    left={['15', '25', '30', '45', '55', '60', '120', '150', '180', '210', '240'].filter(x => !this.state.bookingSlots.BookingDuration.Fixed.includes(x)).map(x => { return ({ primaryText: x, secondaryText: '' }) })}
                                    right={this.state.bookingSlots.BookingDuration.Fixed.map(x => { return ({ primaryText: x, secondaryText: '' }) })} />
                            </div>
                            <Modal.Footer>
                                <div style={{ textAlign: 'center' }}>
                                    <IbssButton
                                        style={{ height: '45px', minWidth: '100px' }}
                                        variant="contained"
                                        onClick={() =>
                                            this.updateFixedDurations()}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButton>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </>
                ),
            },
        ];

        const defaultValues = this.state.dateEdit ?
            this.state.bookingSlots.ExcludedDates[bookingSlots.ExcludedDates.findIndex((x: { id: string; }) => x.id == this.state.selectedExcludedDateIds[0])] :
            undefined;

        let defaultStart = DateTime.fromISO(defaultValues?.StartDate + 'T' + defaultValues?.StartTime);
        let defaultEnd = DateTime.fromISO(defaultValues?.EndDate + 'T' + defaultValues?.EndTime);

        return (
            <div className='m-4'>
                {
                    this.state.loading ?
                        <Spinner />
                        :
                        <div>
                            <Grid container spacing={{ xl: 2, lg: 0 }}>
                                <Grid item xl={6} lg={12}>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelPolicyInformation}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelPolicyInformationText}</p>
                                            <IbssTextField className='mb-1' error={nameFieldError} helperText={nameFieldError ? this.labels.HubLabelBookingPolicyNameError : ' '} value={bookingPolicyName} fullWidth variant='standard' label={this.labels.HubLabelPolicyName} onChange={e => this.setState({ bookingPolicyName: e.target.value })} />
                                            <IbssTextField className='mb-1' error={descriptionFieldError} helperText={descriptionFieldError ? this.labels.HubLabelBookingPolicyDescription : ' '} value={bookingPolicyDescription} fullWidth variant='standard' label={this.labels.HubLabelPolicyDescription} onChange={e => this.setState({ bookingPolicyDescription: e.target.value })} />
                                            <IbssTextField fullWidth variant='standard' label={this.labels.HubLabelPolicyId} disabled value={bookingPolicyId} />
                                        </div>
                                    </Card>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelSlots}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelSlotsInformationText}</p>
                                            {slotsOverlapError && <p className='subText text-danger'>{this.labels.HubLabelBookingPolicyOverlappingSlots}</p>}
                                            {slotsInvalidTimeRangeError && <p className='subText text-danger'>{this.labels.HubLabelBookingPolicyInvalidTimeSlotsError}</p>}
                                            {formattedBookableTime.map(slot => slot.times.map((time: { StartTime: string; EndTime: string; enabled: boolean }, index: number) =>
                                            {
                                                return (
                                                    <div key={`${slot.dayId}${index}`} className='d-flex' style={{ backgroundColor: `${index !== 0 ? '#F2F3F4' : ''}`, paddingTop: '10px', paddingLeft: '5px' }}>
                                                        <div style={{ width: '22%', display: 'flex' }} >
                                                            <IbssSwitchLabel defaultChecked={time.enabled} onChange={e => this.slotInputChanged(slot.dayId, time.StartTime, time.EndTime, 'toggle', index, e.target.checked)} />
                                                            <p style={{ alignSelf: 'center' }}>{Object.values(weekdaysObject)[parseInt(slot.dayId) - 1]}</p>
                                                        </div>
                                                        <IbssFormControl style={{ width: '35%', paddingRight: '2%', paddingLeft: `${index !== 0 ? '40px' : ''}` }}>
                                                            <div>
                                                                <IbssTimePicker
                                                                    className='ibss-timepicker'
                                                                    value={new Date(0, 0, 0, parseInt(time.StartTime?.slice(0, 2)) || 0, parseInt(time.StartTime?.slice(3, 5)) || 0)}
                                                                    onChange={e => this.slotInputChanged(slot.dayId, moment(e?.toISOString()).format('HH:mm'), time.EndTime, 'startTime', index)}
                                                                    ampm
                                                                    minutesStep={1}
                                                                    renderInput={(params) =>
                                                                    {
                                                                        const { sx, ...paramsMinusSx } = params
                                                                        let paramsMinusSxUpdated = {...paramsMinusSx, inputProps: {...paramsMinusSx.inputProps, onChange: () => {}}}
                                                                        return <TextField fullWidth {...paramsMinusSxUpdated} error={false} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                        />
                                                                    }}
                                                                />
                                                            </div>
                                                        </IbssFormControl>
                                                        <IbssFormControl style={{ width: '35%', paddingRight: '2%' }}>
                                                            <IbssTimePicker
                                                                className='ibss-timepicker'
                                                                value={new Date(0, 0, 0, parseInt(time.EndTime?.slice(0, 2)) || 23, parseInt(time.EndTime?.slice(3, 5)) || 0)}
                                                                onChange={e => this.slotInputChanged(slot.dayId, time.StartTime, moment(e?.toISOString()).format('HH:mm'), 'endTime', index)}
                                                                ampm
                                                                minutesStep={1}
                                                                renderInput={(params) =>
                                                                {
                                                                    const { sx, ...paramsMinusSx } = params
                                                                    let paramsMinusSxUpdated = {...paramsMinusSx, inputProps: {...paramsMinusSx.inputProps, onChange: () => {}}}
                                                                    return <TextField fullWidth {...paramsMinusSxUpdated} error={false} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                    />
                                                                }}
                                                            />
                                                        </IbssFormControl>
                                                        {
                                                            index == 0 &&
                                                            <IbssButton variant="contained" style={{ height: '38px', padding: '0', minWidth: '40px', marginTop: '23px' }} onClick={() => this.addSlot(slot.dayId)}>+</IbssButton>
                                                        }
                                                    </div>
                                                )
                                            }))}
                                        </div>
                                    </Card>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <DateSelectModal
                                                show={showDateModal}
                                                closeClicked={() => this.setState({ showDateModal: false, dateEdit: false })}
                                                okButtonClicked={(start, end) => this.datesChanged(start, end)}
                                                title={this.labels.HubLabelExcludedDates}
                                                message={this.labels.HubLabelExcludedDatesInformationText}
                                                start={defaultStart.isValid ? defaultStart : undefined}
                                                end={defaultEnd.isValid ? defaultEnd : undefined}
                                            />
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelExcludedDates}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelExcludedDatesInformationText}</p>
                                            <div style={{ display: 'grid' }}>
                                                <div>
                                                    <div style={{ float: 'right', paddingBottom: '4px' }}>
                                                        <IbssActionButton buttons={[
                                                            {
                                                                label: this.labels.HubButtonDelete,
                                                                icon: <IbssSvgIcon>{Icons.Bin}</IbssSvgIcon>,
                                                                color: 'error',
                                                                onClick: () => this.removeExcludedDates(),
                                                                disabled: this.state.selectedExcludedDateIds.length == 0
                                                            },
                                                            {
                                                                label: this.labels.HubButtonEdit,
                                                                icon: <IbssSvgIcon>{Icons.EditIcon}</IbssSvgIcon>,
                                                                color: 'primary',
                                                                onClick: () => this.setState({ showDateModal: true, dateEdit: true }),
                                                                disabled: this.state.selectedExcludedDateIds.length != 1
                                                            },
                                                            {
                                                                label: this.labels.HubLabelAddButton,
                                                                icon: <IbssSvgIcon>{Icons.AddIcon}</IbssSvgIcon>,
                                                                color: 'primary',
                                                                onClick: () => this.setState({ showDateModal: true })
                                                            }
                                                        ]} />
                                                    </div>
                                                </div>
                                                <IbssDataGrid onRowSelectionModelChange={e => this.selectionModelChange(e)} checkboxSelection columns={this.columns} rows={this.state.bookingSlots.ExcludedDates} />
                                            </div>
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xl={6} lg={12}>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelDurationLimits}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelDurationLimitsInformationText}</p>
                                            <IbssHorizontalTabs
                                                tabs={tabs}
                                                orientation="horizontal"
                                                boxwidth='100%'
                                                tabChanged={(value: number) => this.durationLimitsTabChange(value)}
                                                defaultValue={bookingSlots.BookingDuration.Fixed.length > 0 ? 1 : 0}
                                            />
                                        </div>
                                    </Card>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelBehaviours}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelBehavioursInformationText}</p>
                                            <div className='ml-4 behaviours-radioBtns'>
                                                <IbssRadioButton
                                                    value={bookingStartAndEndType || ''}
                                                    horizontal
                                                    valueChanged={(value: string) => this.setState({ bookingStartAndEndType: value })}
                                                    option={[{ label: this.labels.HubLabelRepeatingStartAndEndInterval, value: 'repeating', labelplacement: 'end' }, { label: this.labels.HubLabelSpecificStartandEndInterval, value: 'specific', labelplacement: 'end' }]}
                                                />
                                            </div>
                                            <div className='d-flex ml-2 mt-1 behaviours-time-input'>
                                                <div className='d-flex'>
                                                    <p className='mr-2 mt-0'>{this.labels.HubLabelEvery}:</p>
                                                    <IbssFormControl>
                                                        <IbssInputDropDown
                                                            disabled={bookingStartAndEndType === 'specific'}
                                                            defaultValue={this.getSpecificMinutesDefaultValue(bookingSlots.BookingStart.SpecificMinutes)}
                                                            minWidth={'220px'}
                                                            helperText={this.labels.HubLabelMinutes}
                                                            inputLabel={this.labels.HubLabelRepeatingStartInterval}
                                                            id='repeatingInterval'
                                                            options={[{ label: 'Any', value: 61 }, ...this.createOptionsArray([1, 5, 10, 15, 30, 45, 55, 60])]}
                                                            onChange={(e: { target: { value: number; }; }) => this.createRepeatingIntervals(e.target.value, 'start')}
                                                        />
                                                    </IbssFormControl>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    {this.labels.HubLabelEvery}:
                                                    <div style={{ overflowWrap: 'anywhere', paddingLeft: '5px' }}>
                                                        <IbssButton className='mr-2' style={{ minWidth: '100px' }} variant="contained" onClick={() => this.setState({ showSpecificStartModal: true })} disabled={bookingStartAndEndType != 'specific'}>{this.labels.HubLabelSet}...</IbssButton>
                                                        {this.state.bookingSlots.BookingStart.SpecificTimes.toString()}
                                                    </div>
                                                </div>
                                                <TimeSelectList
                                                    show={this.state.showSpecificStartModal}
                                                    closeButtonClicked={() => this.setState({ showSpecificStartModal: false })}
                                                    okButtonClicked={times => this.startIntervalsChanged(times)}
                                                    title={this.labels.HubLabelSpecificStartandEndInterval}
                                                    selectedTimes={bookingSlots.BookingStart.SpecificTimes.map(i => DateTime.fromISO(i))}
                                                />
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelEndBehavioursInformationText}</p>
                                            <div className='d-flex ml-2 mt-1 behaviours-time-input'>
                                                <div className='d-flex'>
                                                    <p className='mr-2 mt-0'>{this.labels.HubLabelEvery}:</p>
                                                    <IbssFormControl>
                                                        <IbssInputDropDown
                                                            disabled={bookingStartAndEndType === 'specific'}
                                                            defaultValue={this.getSpecificMinutesDefaultValue(bookingSlots.BookingEnd.SpecificMinutes)}
                                                            minWidth={'220px'}
                                                            helperText={this.labels.HubLabelMinutes}
                                                            inputLabel={this.labels.HubLabelRepeatingEndInterval}
                                                            id='repeatingInterval'
                                                            options={[{ label: 'Any', value: 61 }, ...this.createOptionsArray([1, 5, 10, 15, 30, 45, 55, 60])]}
                                                            onChange={(e: { target: { value: number; }; }) => this.createRepeatingIntervals(e.target.value, 'end')}
                                                        />
                                                    </IbssFormControl>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    {this.labels.HubLabelEvery}:
                                                    <div style={{ overflowWrap: 'anywhere', paddingLeft: '5px' }}>
                                                        <IbssButton className='mr-2' style={{ minWidth: '100px' }} variant="contained" onClick={() => this.setState({ showSpecificEndModal: true })} disabled={bookingStartAndEndType != 'specific'}>{this.labels.HubLabelSet}...</IbssButton>
                                                        {this.state.bookingSlots.BookingEnd.SpecificTimes.toString()}
                                                    </div>
                                                </div>
                                                <TimeSelectList
                                                    show={this.state.showSpecificEndModal}
                                                    closeButtonClicked={() => this.setState({ showSpecificEndModal: false })}
                                                    okButtonClicked={times => this.endIntervalsChanged(times)}
                                                    title={this.labels.HubLabelSpecificEndIntervals}
                                                    selectedTimes={bookingSlots.BookingEnd.SpecificTimes.map(i => DateTime.fromISO(i))}
                                                />
                                            </div>
                                            <Modal show={this.state.showSpecificTimesWarningModal} onHide={() => this.setState({ showSpecificTimesWarningModal: false })}>
                                                <Modal.Header>
                                                    <Modal.Title>{this.labels.HubLabelWarning}</Modal.Title>
                                                    <button type="button" className="close" onClick={() => this.setState({ showSpecificTimesWarningModal: false })} aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </Modal.Header>
                                                <div className='m-5'>
                                                    {this.labels.SpecificTimesWarningMessage}
                                                    <br />
                                                    <br />
                                                    {this.labels.SpecificTimesWarningMessageFurtherInfo}
                                                </div>
                                                <Modal.Footer>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <IbssButton
                                                            style={{ height: '45px', minWidth: '100px' }}
                                                            variant="contained"
                                                            onClick={() =>
                                                                this.handleOnSave()}
                                                        >
                                                            {this.labels.HubLabelOk}
                                                        </IbssButton>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                            <div>
                                                <p className='subText'>
                                                    {this.labels.HubLabelAutoCheckInSubText}
                                                </p>
                                                    <div className='ml-4' style={{ width: '30%', alignSelf: 'center' }}>
                                                        <IbssSwitchLabel onChange={e => this.setState({ arrivalPolicies: { ...arrivalPolicies, AutoCheckin: e.target.checked, AutoCancellation: 0 }, autoCancelEnabled: e.target.checked ? false : this.state.autoCancelEnabled })} label={this.labels.HubLabelAutoCheckIn} checked={arrivalPolicies.AutoCheckin} />
                                                    </div>
                                                <p className='subText'>
                                                    {this.labels.HubLabelEarlyCheckinThresholdText}
                                                </p>
                                                    <div className='ml-4' style={{ width: '70%' }}>
                                                        <IbssTextField error={earlyCheckinError} helperText={earlyCheckinError ? this.labels.HubLabelBookingPolicyEarlyCheckinError : this.labels.HubLabelMinutes} value={arrivalPolicies.EarlyCheckin} fullWidth variant='standard' type='number' onChange={e => this.setState({ arrivalPolicies: { ...arrivalPolicies, EarlyCheckin: parseInt(e.target.value) } })} onBlur={(e) => e.target.value == '' && this.setState({ arrivalPolicies: { ...arrivalPolicies, EarlyCheckin: 0 }})} label={this.labels.HubLabelEarlyCheckInThreshold} />
                                                    </div>
                                                <p className='subText'>
                                                    {this.labels.HubLabelAutoCancelSubText}
                                                </p>
                                                <div className='ml-4 d-flex'>
                                                    <div style={{ width: '30%', alignSelf: 'center' }}>
                                                        <IbssSwitchLabel disabled={this.state.arrivalPolicies.AutoCheckin} onChange={e => this.setState({ autoCancelEnabled: e.target.checked })} label={this.labels.HubLabelAutoCancelBookings} checked={autoCancelEnabled} />
                                                    </div>
                                                    <div style={{ width: '70%' }}>
                                                        <IbssTextField error={autoCancellationError} helperText={autoCancellationError ? this.labels.HubLabelBookingPolicyAutoCancelError : this.labels.HubLabelMinutes} value={arrivalPolicies.AutoCancellation} fullWidth variant='standard' type='number' disabled={!autoCancelEnabled} onChange={e => this.setState({ arrivalPolicies: { ...arrivalPolicies, AutoCancellation: parseInt(e.target.value) } })} onBlur={(e) => e.target.value == '' && this.setState({ arrivalPolicies: { ...arrivalPolicies, AutoCancellation: 0 }})} label={this.labels.HubLabelCancellationThreshold} />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='subText'>
                                                {this.labels.HubLabelBookingHorizonSubText}
                                            </p>
                                            <IbssTextField sx={{ width: '49%', paddingRight: '10px' }} error={horizonError} helperText={horizonError ? this.labels.HubLabelBookingPolicyMinDurationError : this.labels.HubLabelDays} value={bookingSlots.BookingHorizon.Minimum} variant='standard' type='number' onChange={e => this.setState({ bookingSlots: { ...bookingSlots, BookingHorizon: { ...bookingSlots.BookingHorizon, Minimum: parseInt(e.target.value) } } })} onBlur={e => e.target.value == '' && this.setState({ bookingSlots: { ...bookingSlots, BookingHorizon: { ...bookingSlots.BookingHorizon, Minimum: 0 } } })} label={this.labels.HubLabelBookingHorizonMinimum} />
                                            <IbssTextField sx={{ width: '49%' }} error={horizonError} helperText={horizonError ? this.labels.HubLabelBookingPolicyMaxDurationError : this.labels.HubLabelDays} value={bookingSlots.BookingHorizon.Maximum} variant='standard' type='number' onChange={e => this.setState({ bookingSlots: { ...bookingSlots, BookingHorizon: { ...bookingSlots.BookingHorizon, Maximum: parseInt(e.target.value) } } })} onBlur={e => e.target.value == '' && this.setState({ bookingSlots: { ...bookingSlots, BookingHorizon: { ...bookingSlots.BookingHorizon, Maximum: 0 } } })} label={this.labels.HubLabelBookingHorizonMaximum} />
                                            <p className='subText mt-2'>
                                                {this.labels.HubLabelRecurringBookingPolicySubText}
                                            </p>
                                            <div className='d-flex'>
                                                    <div style={{ width: '30%', alignSelf: 'center' }}>
                                                        <IbssSwitchLabel onChange={e => this.setState({ bookingSlots: { ...bookingSlots, BookingRecurrence: { ...bookingSlots.BookingRecurrence, Allowed: e.target.checked } } })} label={this.labels.HubLabelAllowRecurringBookings} checked={bookingSlots.BookingRecurrence?.Allowed ?? false} />
                                                    </div>
                                                    <div style={{ width: '70%' }}>
                                                        <IbssTextField error={recurrenceError} helperText={recurrenceError ? this.labels.HubLabelRecurringBookingErrorText : this.labels.HubLabelRecurringBookingHelperText} value={bookingSlots.BookingRecurrence?.Maximum ?? 0} fullWidth variant='standard' type='number' disabled={!(bookingSlots.BookingRecurrence?.Allowed ?? false)} onChange={e => this.setState({ bookingSlots: { ...bookingSlots, BookingRecurrence: { ...bookingSlots.BookingRecurrence, Maximum: e.target.value == '' ? 0 : parseInt(e.target.value) } } })} label={this.labels.HubLabelRecurringBookingThreshold} />
                                                    </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card className='mb-3'>
                                        <div className='m-3'>
                                            <div className='d-flex'>
                                                <div className="mr-2">
                                                    <IbssSvgIcon>
                                                        {Icons.StandardDesk}
                                                    </IbssSvgIcon>
                                                </div>
                                                <div className='heading'>
                                                    {this.labels.HubLabelApprovals}
                                                </div>
                                            </div>
                                            <p className='subText'>{this.labels.HubLabelApprovalsInformationText}</p>
                                            <div className='ml-4 d-flex'>
                                                <div style={{ width: '30%', alignSelf: 'center', paddingTop: '14px' }}>
                                                    <IbssSwitchLabel onChange={e => this.setState({ approvalPolicies: { ...approvalPolicies, ApprovalRequired: e.target.checked } })} label={this.labels.HubLabelApprovalRequired} checked={approvalPolicies.ApprovalRequired} />
                                                </div>
                                                <div style={{ width: '70%' }}>
                                                    <IbssTextField disabled={!this.state.approvalPolicies.ApprovalRequired} error={autoRejectionError} helperText={autoRejectionError ? this.labels.HubLabelBookingPolicyAutoRejectionError : this.labels.HubLabelDays} value={approvalPolicies.AutoRejectionAfter} fullWidth variant='standard' label={this.labels.HubLabelAutoRejectionAfter} type='number' onChange={e => this.setState({ approvalPolicies: { ...approvalPolicies, AutoRejectionAfter: parseInt(e.target.value) } })} onBlur={e => e.target.value == '' && this.setState({ approvalPolicies: { ...approvalPolicies, AutoRejectionAfter: 0 } })} />
                                                </div>
                                            </div>
                                            <>
                                                <div>
                                                    <IbssButton disabled={!this.state.approvalPolicies.ApprovalRequired} className='mr-2' style={{ minWidth: '100px' }} variant="contained" onClick={() => this.setState({ showApprovalGroupsModal: true })}>{this.labels.HubLabelSet}...</IbssButton>
                                                    {this.state.approvalPolicies.ApprovalGroups.map(group => group.name).toString()}
                                                </div>
                                                {approvalGroupsError && <p className='subText text-danger'>{this.labels.HubLabelApprovalGroupError}</p>}
                                                <Modal show={this.state.showApprovalGroupsModal} onHide={() => this.setState({ showApprovalGroupsModal: false })}>
                                                    <Modal.Header>
                                                        <Modal.Title>{this.labels.HubLabelApprovalRoles}</Modal.Title>
                                                        <button type="button" className="close" onClick={() => this.setState({ showApprovalGroupsModal: false })} aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </Modal.Header>
                                                    <div className='mt-2 mb-3'>
                                                        <div className='d-flex'>
                                                            <IbssFormControl sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                                                                <IbssTextField onChange={e => this.setState({ approvalGroupsSearchFilter: e.target.value })} placeholder={this.labels.HubLabelSearch} variant='outlined' sx={{ width: '300px', marginLeft: '53px', marginBottom: '10px' }} />
                                                            </IbssFormControl>
                                                            <div style={{ paddingTop: '7px', paddingLeft: '8px' }}>
                                                                <IbssButton fullWidth={false} style={{ minWidth: '100px', height: '40px' }} variant="contained" onClick={() => this.filterApprovalGroups()}>{this.labels.HubLabelFilter}</IbssButton>
                                                            </div>
                                                        </div>
                                                        <IbssTransferList
                                                            ref={this.approvalGroupsTransferListRef}
                                                            left={this.state.approvalGroups.map(group => group.name).filter(x => !this.state.approvalPolicies.ApprovalGroups.map(group => group.name).includes(x)).map(x => { return ({ primaryText: x, secondaryText: '' }) })}
                                                            right={this.state.approvalPolicies.ApprovalGroups.map(x => { return ({ primaryText: x.name, secondaryText: '' }) })}
                                                        />
                                                    </div>
                                                    <Modal.Footer>
                                                        <div style={{ textAlign: 'center' }}>
                                                            <IbssButton
                                                                style={{ height: '45px', minWidth: '100px' }}
                                                                variant="contained"
                                                                onClick={() =>
                                                                    this.updateSelectedApprovalGroups()}
                                                            >
                                                                {this.labels.HubLabelOk}
                                                            </IbssButton>
                                                        </div>
                                                    </Modal.Footer>
                                                </Modal>
                                            </>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                            <div style={{ float: 'right', paddingBottom: '10px' }}>
                                <div>
                                    <IbssButton style={{ minWidth: '100px' }} disabled={this.state.loading} className='mr-2' variant="contained" color='secondary' onClick={() => this.props.history.push("/booking-policies/" + this.appState.buildingId)}>{this.labels.HubButtonCancel}</IbssButton>
                                    {this.userCanUpdate &&
                                        <IbssButton style={{ minWidth: '100px' }} disabled={this.state.loading} variant="contained" onClick={() => this.handleOnSave()}>{this.labels.HubButtonSave}</IbssButton>
                                    }
                                </div>
                                <div className='mt-1 mb-1'>
                                    <p className='subText text-danger mr-2' style={{ fontSize: '18px', margin: '0px', minHeight: '23px' }}>{Object.values(this.state.erroredFields).includes(true) ? this.labels.HubLabelBookingPolicyResolveErrorsMessage : ' '}</p>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

interface IMatchParams
{
    bookingId: string;
}

export interface IState
{
    buildingId: number;
    bookingPolicyId: string;
    bookingPolicyName: string;
    bookingPolicyDescription: string;
    bookingSlots: IBookingSlots;
    arrivalPolicies: IArrivalPolicies;
    approvalPolicies: IApprovalPolicies;
    bookingDurationType: string;
    bookingStartAndEndType: string;
    formattedBookableTime: Array<IFormattedBookableTime>;
    autoCancelEnabled: boolean;
    showDateModal: boolean;
    loading: boolean;
    showErrors: boolean;
    hasValidationErrors: boolean;
    erroredFields: IErroredFields;
    selectedExcludedDateIds: GridRowSelectionModel;
    dateEdit: boolean;
    showFixedTimesModal: boolean;
    showApprovalGroupsModal: boolean;
    showSpecificStartModal: boolean;
    showSpecificEndModal: boolean;
    approvalGroups: IApprovalGroups[];
    approvalGroupsSearchFilter: string;
    showSpecificTimesWarningModal: boolean;
    specificTimesWarningShown: boolean;
}

export interface IApprovalGroups
{
    name: string;
    id: string;
}

export interface IBookingPolicyData
{
    BookingDescription: string;
    BookingSlots: IBookingSlots;
    ArrivalPolicies: IArrivalPolicies;
    ApprovalPolicies: IApprovalPolicies;
}

export interface IApprovalPolicies
{
    ApprovalRequired: boolean;
    AutoRejectionAfter: number;
    ApprovalGroups: IApprovalGroups[];
}

export interface IArrivalPolicies
{
    AutoCheckin: boolean;
    EarlyCheckin: number;
    AutoCancellation: number;
}

export interface IBookingSlots
{
    BookableTime: IBookableTime[];
    ExcludedDates: IExcludedDate[];
    BookingStart: IBookingStart;
    BookingEnd: IBookingEnd;
    BookingDuration: IBookingDuration;
    BookingHorizon: IBookingHorizon;
    BookingRecurrence: IBookingRecurrence
}

export interface IBookingRecurrence
{
    Maximum: number,
    Allowed: boolean
}

export interface IBookableTime
{
    Days: string[];
    StartTime: string;
    EndTime: string;
}

export interface IBookingDuration
{
    Minimum: number;
    Maximum: number;
    Fixed: string[];
}

export interface IBookingHorizon
{
    Minimum: number;
    Maximum: number;
}

export interface IExcludedDate
{
    id: string;
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}

export interface IBookingStart
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IBookingEnd
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IFormattedBookableTime
{
    dayId: string;
    times: Array<any>;
}

export interface IErroredFields
{
    name: boolean,
    description: boolean,
    slotsOverlap: boolean,
    slotsInvalidTimeRange: boolean,
    configurableDurationLimits: boolean,
    earlyCheckin: boolean,
    autoCancellation: boolean,
    horizon: boolean,
    autoRejection: boolean,
    approvalGroups: boolean,
    recurrence: boolean
}

interface IColumn
{
    field: string;
    headerName: string;
    width: number;
}
