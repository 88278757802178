import { Component } from 'react'
import { connect } from 'react-redux';
import { appContext } from '../../../AppContext';
import Header, { IProps as IHeaderProps } from '../../../Components/Header'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import { Box, styled, BoxProps, Grid } from '@mui/material';
import { RootState } from '../../../app/store';
import apis from '../../../Providers.Api/apis';
import { UPDATE_MAIN_PAGE_TITLE } from '../../../app/constants';
import SearchAvailableEquipment, { IQueryParams } from './SearchAvailableEquipment';
import SearchEquipmentAddToBookings from './SearchEquipmentAddToBooking';
import { getBuildingIdUsingFloorNodeId, getFloorNameUsingFloorAndBuildingId } from '../../../Common/Helper';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import IbssButton from '../../../Components/uicomponents/IbssButton';
import { RouteComponentProps } from 'react-router-dom';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    background: `var(--ui-background-alternate)`,
    height: "-webkit-fill-available"
}));

class SearchEquipment extends Component<any, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: any)
    {
        super(props);
        this.state = {
            selectedEquipmentIds: [],
            bookedEquipment: [],
            bookedData: "",
            isLoadingToSave: false
        }
    }

    public async componentDidMount(): Promise<void>
    {
        const { dispatch, location, match }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HublabelAddResources });

        this.getBookedParties(match.params.buildingid, location.state.bookingResponse.Booking_Id)
    }

    private selectEquipment(equipmentId: string): void
    {
        if (this.state.selectedEquipmentIds.includes(equipmentId))
        {
            const newList = this.state.selectedEquipmentIds.filter(x => x != equipmentId);
            this.setState({ selectedEquipmentIds: newList });
        }
        if (!this.state.selectedEquipmentIds.includes(equipmentId))
        {
            const newList = [...this.state.selectedEquipmentIds, equipmentId];
            this.setState({ selectedEquipmentIds: newList });
        }
    }

    private getBookedParties(nodeId: string, Booking_Id: string): void
    {
        this.setState({ isLoadingToSave: true })
        apis.getBookingParties(nodeId, Booking_Id).then((res) =>
        {
            for (let i = 0; i < res.data.length; i++)
            {
                let FloorName = getFloorNameUsingFloorAndBuildingId(this.props.match.params.buildingid, res.data[i].Node_Id)
                res.data[i].Floor_Name = FloorName;
            }
            this.setState({ isLoadingToSave: false, bookedEquipment: res.data, selectedEquipmentIds: res.data.map((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id) })
        })
            .catch((error: any) => { this.setState({ isLoadingToSave: false }) });
    }

    private addEquipmentClicked(): void
    {
        const { location } = this.props;
        this.props.history.push('/flex-my-bookings/' + getBuildingIdUsingFloorNodeId(location.state.bookingResponse.Node_Id) + '/mybooking/' + location.state.bookingResponse.Booking_Id + '/' + location.state.bookingResponse.Space_Id);
    }

    public render(): JSX.Element
    {
        const booking = this.props.location.state.bookingResponse;
        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
                    <Sidebar {...this.props} />
                    <div className="rightPanel">
                        <Header {...this.props as unknown as IHeaderProps} />
                        <div className="rightPanel-main-content">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='mb-4' style={{ fontSize: '23px' }}>{this.labels.HubLabelAddYourEquipment}</div>
                                <IbssButton variant='outlined' color='secondary' style={{ width: '120px', height: '40px' }} onClick={() => this.addEquipmentClicked()}>{`< ${this.labels.HubButtonBack}`}</IbssButton>
                            </div>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <StyledBox className="notification-section">
                                        <SearchAvailableEquipment {...this.props as RouteComponentProps<IQueryParams>} bookingId={booking.Booking_Id} bookingStart={booking.Booking_Start} bookingEnd={booking.Booking_End} equipmentType={this.props.location.state.equipment_Type} selectEquipment={id => this.selectEquipment(id)} selectedEquipmentIds={this.state.selectedEquipmentIds} bookedEquipment={this.state.bookedEquipment} />
                                    </StyledBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <SearchEquipmentAddToBookings {...this.props}
                                        selectedEquipmentIds={this.state.selectedEquipmentIds}
                                        bookedEquipment={this.state.bookedEquipment} />

                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

const mapStateToProps = (state: RootState) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,

    };
}

export default connect(mapStateToProps)(SearchEquipment);

export interface IState
{
    selectedEquipmentIds: string[];
    bookedEquipment: IBookedEquipment[],
    bookedData: string,
    isLoadingToSave: boolean,
}

export interface IBookedEquipment
{
    Booking_Resource_Id: string;
}