import { IGetUsersEndpoint } from "./GetUsersEndpoint";

export class UsersRepository implements IUsersRepository
{
    private getUsersEndpoint: IGetUsersEndpoint;

    constructor(
        getUsersEndpoint: IGetUsersEndpoint,
    )
    {
        this.getUsersEndpoint = getUsersEndpoint;
    }

    public getUsers(name: string): Promise<IUser[]>
    {
        return this.getUsersEndpoint.execute(name);
    }

}
export interface IUsersRepository 
{
    getUsers(name: string): Promise<IUser[]>;
}

export interface IUser
{
    displayName: string,
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    pin: string,
    UserName: string,
    OID: string,
}
