import { Component } from "react";
import { appContext } from "../../../../AppContext";
import { SvgIcon } from "@mui/material";
import CostCodeIcon from "../../../../Components/uicomponents/customMaterialIcons/CostCodeIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/uicomponents/IbssSvgIcon";
import CostCodeModal, { CostCodeWithAllocation } from "../../../../Components/CostCodeModal";

export default class AddCostCodes extends Component<IProps, IState>
{
    private labels = appContext().labels;
    private get local() { return appContext().localStorageProvider; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            showCostCodeModal: false,
            costCodes: []
        }

        this.handleCostCodeModal = this.handleCostCodeModal.bind(this);
        this.updateBookingCostCodes = this.updateBookingCostCodes.bind(this);
    }

    private handleCostCodeModal(): void
    {
        this.setState((prevState) => ({
            showCostCodeModal: !prevState.showCostCodeModal
        }));
    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState({
            costCodes: updatedCostCodes
        })

        this.props.onSubmit(updatedCostCodes)
    }

    private getCostCodeLabel(): string
    {
        /* return the string that is returned on the cost code button*/
        const numberOfCostCodes = this.state.costCodes.length;
        if (numberOfCostCodes === 1)
        {
            return this.state.costCodes[0].costCode; //if just one cost code, show the cost code
        }
        else if (numberOfCostCodes > 1)
        {
            return `${this.state.costCodes.length} ${this.labels.HubLabelCostCodes}` // Cost Codes
        }
        else
        {
            return '';
        }
    }

    public render(): JSX.Element
    {

        return (
            <>
                {
                    (this.local.hasRight("API.Bookings.AssignCostCode") && this.local.hasRight("API.Bookings.V2")) && (
                        <>
                            <div className="row attendes cp" onClick={() => this.handleCostCodeModal()}>
                                <div className="icon-text-inline pl-0">
                                    <span className="space-icon-item">
                                        <SvgIcon component={CostCodeIcon} inheritViewBox sx={{ height: '18px', width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }} />
                                    </span>
                                    <span style={{ marginLeft: '9px' }} className="space-text-item col-text">{this.labels.HubLabelCostCode}</span>
                                </div>
                                <div className="d-flex">
                                    <span className="space-text-item mr-3">{this.getCostCodeLabel()}</span>
                                    <IbssSvgIcon fontSize='medium' className="mr-2">
                                        {Icons.RightArrowIcon}
                                    </IbssSvgIcon>
                                </div>
                            </div>
                            <hr />
                        </>
                    )
                }

                <CostCodeModal
                    bookingId={''}
                    bookingCostCodes={this.state.costCodes}
                    show={this.state.showCostCodeModal}
                    onClose={this.handleCostCodeModal}
                    updateBookingCostCodes={this.updateBookingCostCodes}
                />
            </>
        );
    }
}

export interface IState
{
    showCostCodeModal: boolean;
    costCodes: CostCodeWithAllocation[]
}

export interface IProps
{
    onSubmit: (updatedCostCodes: CostCodeWithAllocation[]) => void;
}
