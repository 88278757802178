import axios, { AxiosResponse } from "axios";
import { StringHelper } from "../../Common/StringHelper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CostCodeSource, CostCodeType, IV2CostCode } from "./CostCodeRepository";

export class GetV2CostCodesEndpoint implements IGetV2CostCodesEndpoint
{
    public async execute(take: number, skipToken?: string, startOfCodeCode?: string, costCodeSource?: CostCodeSource, costCodeType?: CostCodeType): Promise<IV2CostCode>
    {
        try
        {

            let query =
                `$select=Cost_Code,Cost_Code_Description,Cost_Code_Id,Cost_Code_Type,EventTime,Cost_Code_Source&` +
                `$top=${take}&` +
                `$filter=Cost_Code_Id ne null and Cost_Code_IsEnabled eq 1`

            if (startOfCodeCode)
            {
                query = query + ` and Cost_Code ge '${startOfCodeCode}' and Cost_Code lt '${StringHelper.incrementLastCharacter(startOfCodeCode)}'`
            }

            if(costCodeSource != null)
            {
                switch (costCodeSource)
                {
                    case CostCodeSource.API:
                        query = query + ` and Cost_Code_Source eq '${CostCodeSource.API}'`
                        break;
                    case CostCodeSource.Manual:
                        query = query + ` and Cost_Code_Source eq '${CostCodeSource.Manual}'`
                        break;
                    case CostCodeSource.SFTP:
                        query = query + ` and Cost_Code_Source eq '${CostCodeSource.SFTP}'`
                        break;
                    // nothing to add to query if costCodeSource is 'Any'
                }
            }

            if (costCodeType != null)
            {
                switch (costCodeType)
                {
                    case CostCodeType.ClientCode:
                        query = query + ` and Cost_Code_Type eq 100`;
                        break;

                    case CostCodeType.DepartmentCode:
                        query = query + ` and Cost_Code_Type eq 101`;
                        break;

                    case CostCodeType.StandardCostCode:
                        query = query + ` and (Cost_Code_Type ge 0 and Cost_Code_Type le 99)`;
                        break;
                    // nothing to add to query if CostCodeType is 'Any'
                }
            }

            if(skipToken)
            {
                query = query + `&$skipToken=${skipToken}`
            }

            const response: AxiosResponse<IV2CostCode> = await axios.get(`${apis.allApiv2}${1}/costCodes?${query}`)
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2CostCodesEndpoint
{
    execute(take: number, skipToken?: string, startOfCodeCode?: string, costCodeSource?: CostCodeSource, costCodeType?: CostCodeType): Promise<IV2CostCode>;
}