export default class ApiMessages
{
    public ﻿Not_Bookable_Title = "";
    public Already_Booked_Title = "";
    public Invalid_Node_ID_Title = "";
    public CheckedIn_Booking_Start_Amend_Title = "";
    public Booking_Outside_Hours_Title = "";
    public Booking_Invalid_Times_Title = "";
    public Booking_Invalid_Time_Intervals_Title = "";
    public Booking_Invalid_Dates_Title = "";
    public Booking_Invalid_Dates_Closed_Title = "";
    public Booking_Invalid_Day_Title = "";
    public Booking_Invalid_Dates_Below_Min_Horizon_Title = "";
    public Booking_Invalid_Dates_Horizon_Max_Exceeded_Title = "";
    public Booking_Invalid_Duration_Too_Long_Title = "";
    public Booking_Invalid_Duration_Too_Short_Title = "";
    public Booking_Invalid_Title_Length_Title = "";
    public Import_Max_Error_Title = "";
    public Booking_Invalid_OnBehalfOfEmail_Title = "";
    public Booking_Invalid_Party_Visitor_Title = "";
    public Import_Error_Title = "";
    public Booking_Invalid_Party_Internal_Title = "";
    public Booking_Parties_Warning_Title = "";
    public Booking_Admin_Bookable_Only_Title = "";
    public Booking_Space_Capacity_Exceeded_Title = "";
    public Booking_Linked_Space_Not_Allowed_Title = "";
    public Booking_Invalid_Linked_Space_Title = "";
    public Space_Not_Found_Title = "";
    public Space_Out_Of_Service_Title = "";
    public Booking_Invalid_Horizon_Title = "";
    public Booking_Missing_Values_Title = "";
    public Booking_Allowed_Exceeded_Title = "";
    public Booking_Same_Class_Space_Overlap_Title = "";
    public Booking_V2_Access_Denied_Title = "";
    public Booking_No_Policy_Title = "";
    public Cost_Code_Invalid_Title = "";
    public Cost_Code_Allocation_Invalid_Title = "";
    public Lockers_No_Auth_Service_Title = "";
    public Lockers_Invalid_Auth_Token_Title = "";
    public Lockers_System_Unauthorised_Title = "";
    public Lockers_User_Unauthorised_Title = "";
    public Lockers_LockerBanks_Failure_Title = "";
    public Lockers_LockerBanks_Incompatible_Title = "";
    public Lockers_LockerBanks_Error_Title = "";
    public Lockers_LockerBank_Lockers_Failure_Title = "";
    public Lockers_LockerBanks_None_Title = "";
    public Lockers_Available_Lockers_Failure_Title = "";
    public Lockers_Available_Lockers_None_Title = "";
    public Lockers_Available_Lockers_Incompatible_Title = "";
    public Lockers_Available_Lockers_Error_Title = "";
    public Lockers_No_User_Title = "";
    public Lockers_Allocated_Lockers_Failure_Title = "";
    public Lockers_Allocated_Lockers_Incompatible_Title = "";
    public Lockers_Allocated_Lockers_None_Title = "";
    public Lockers_Allocated_Lockers_Error_Title = "";
    public Lockers_Allocate_Locker_Failure_Title = "";
    public Lockers_Allocate_Locker_Forbidden_Title = "";
    public Lockers_Allocate_Locker_Incompatible_Title = "";
    public Lockers_Release_Locker_Failure_Title = "";
    public Lockers_Release_Locker_Forbidden_Title = "";
    public Lockers_Release_Locker_Incompatible_Title = "";
    public IBSS_Request_Failed_Title = "";
    public IBSS_Request_Unauthorised_Title = "";
    public IBSS_Request_Not_Found_Title = "";
    public IBSS_Request_No_Content_Title = "";
    public IBSS_Request_Spaces_Incompatible_Title = "";
    public IBSS_Request_Succeeded_Title = "";
    public IBSS_Request_Exception_Title = "";
    public IBSS_Request_Location_Incompatible_Title = "";
    public IBSS_Internal_Request_Unauthorised_Title = "";
    public System_Request_Unauthorised_Title = "";
    public Irisys_Payload_Deserialise_Failed_Title = "";
    public Irisys_Payload_NoCountLog_Title = "";
    public Irisys_Payload_NoCounters_Title = "";
    public Irisys_Counter_NoTag_Title = "";
    public Irisys_Counter_NotNewer_Title = "";
    public Irisys_Counter_UnmatchedSensor_Title = "";
    public Irisys_Error_Title = "";
    public Invalid_Booking_Owner_Email_Title = "";
    public Import_Visit_InvalidDates_Title = "";
    public Import_Visit_FirstName_LastName_Missing_Title = "";
    public Import_Visit_Email_Missing_Title = "";
    public Import_Visit_Host_Email_Missing_Title = "";
    public Import_Visit_Host_Name_Missing_Title = "";
    public Import_Visit_Node_Id_Missing_Title = "";
    public Not_Bookable_Message = "";
    public Already_Booked_Message = "";
    public Invalid_Node_ID_Message = "";
    public CheckedIn_Booking_Start_Amend_Message = "";
    public Booking_Outside_Hours_Message = "";
    public Booking_Invalid_Times_Message = "";
    public Booking_Invalid_Time_Intervals_Message = "";
    public Booking_Invalid_Dates_Message = "";
    public Booking_Invalid_Dates_Closed_Message = "";
    public Booking_Invalid_Day_Message = "";
    public Booking_Invalid_Dates_Below_Min_Horizon_Message = "";
    public Booking_Invalid_Dates_Horizon_Max_Exceeded_Message = "";
    public Booking_Invalid_Duration_Too_Long_Message = "";
    public Booking_Invalid_Duration_Too_Short_Message = "";
    public Booking_Invalid_Title_Length_Message = "";
    public Import_Max_Error_Message = "";
    public Booking_Invalid_OnBehalfOfEmail_Message = "";
    public Booking_Invalid_Party_Visitor_Message = "";
    public Import_Error_Message = "";
    public Booking_Invalid_Party_Internal_Message = "";
    public Booking_Parties_Warning_Message = "";
    public Booking_Admin_Bookable_Only_Message = "";
    public Booking_Space_Capacity_Exceeded_Message = "";
    public Booking_Linked_Space_Not_Allowed_Message = "";
    public Booking_Invalid_Linked_Space_Message = "";
    public Space_Not_Found_Message = "";
    public Space_Out_Of_Service_Message = "";
    public Booking_Invalid_Horizon_Message = "";
    public Booking_Missing_Values_Message = "";
    public Booking_Allowed_Exceeded_Message = "";
    public Booking_Same_Class_Space_Overlap_Message = "";
    public Booking_V2_Access_Denied_Message = "";
    public Booking_No_Policy_Message = "";
    public Cost_Code_Invalid_Message = "";
    public Cost_Code_Allocation_Invalid_Message = "";
    public Lockers_No_Auth_Service_Message = "";
    public Lockers_Invalid_Auth_Token_Message = "";
    public Lockers_System_Unauthorised_Message = "";
    public Lockers_User_Unauthorised_Message = "";
    public Lockers_LockerBanks_Failure_Message = "";
    public Lockers_LockerBanks_Incompatible_Message = "";
    public Lockers_LockerBanks_Error_Message = "";
    public Lockers_LockerBank_Lockers_Failure_Message = "";
    public Lockers_LockerBanks_None_Message = "";
    public Lockers_Available_Lockers_Failure_Message = "";
    public Lockers_Available_Lockers_None_Message = "";
    public Lockers_Available_Lockers_Incompatible_Message = "";
    public Lockers_Available_Lockers_Error_Message = "";
    public Lockers_No_User_Message = "";
    public Lockers_Allocated_Lockers_Failure_Message = "";
    public Lockers_Allocated_Lockers_None_Message = "";
    public Lockers_Allocated_Lockers_Incompatible_Message = "";
    public Lockers_Allocated_Lockers_Error_Message = "";
    public Lockers_Allocate_Locker_Failure_Message = "";
    public Lockers_Allocate_Locker_Forbidden_Message = "";
    public Lockers_Allocate_Locker_Incompatible_Message = "";
    public Lockers_Release_Locker_Failure_Message = "";
    public Lockers_Release_Locker_Forbidden_Message = "";
    public Lockers_Release_Locker_Incompatible_Message = "";
    public IBSS_Request_Failed_Message = "";
    public IBSS_Request_Unauthorised_Message = "";
    public IBSS_Request_Not_Found_Message = "";
    public IBSS_Request_No_Content_Message = "";
    public IBSS_Request_Spaces_Incompatible_Message = "";
    public IBSS_Request_Succeeded_Message = "";
    public IBSS_Request_Exception_Message = "";
    public IBSS_Request_Location_Incompatible_Message = "";
    public IBSS_Internal_Request_Unauthorised_Message = "";
    public System_Request_Unauthorised_Message = "";
    public Irisys_Payload_Deserialise_Failed_Message = "";
    public Irisys_Payload_NoCountLog_Message = "";
    public Irisys_Payload_NoCounters_Message = "";
    public Irisys_Counter_NoTag_Message = "";
    public Irisys_Counter_NotNewer_Message = "";
    public Irisys_Counter_UnmatchedSensor_Message = "";
    public Irisys_Error_Message = "";
    public Invalid_Booking_Owner_Email_Message = "";
    public Import_Visit_InvalidDates_Message = "";
    public Import_Visit_FirstName_LastName_Missing_Message = "";
    public Import_Visit_Email_Missing_Message = "";
    public Import_Visit_Host_Email_Missing_Message = "";
    public Import_Visit_Host_Name_Missing_Message = "";
    public Import_Visit_Node_Id_Missing_Message = "";
    public Booking_Not_Found_Title = "";
    public Booking_Not_Found_Message = "";
    public Visit_Not_Found_Title = "";
    public Task_Not_Found_Title = "";
    public Catering_Order_Not_Found_Title = "";
    public Catering_Menu_Not_Found_Title = "";
    public Catering_Menu_Item_Not_Found_Title = "";
    public Building_Not_Found_Title = "";
    public Floor_Not_Found_Title = "";
    public Visit_Not_Found_Message = "";
    public Task_Not_Found_Message = "";
    public Catering_Order_Not_Found_Message = "";
    public Catering_Menu_Not_Found_Message = "";
    public Catering_Menu_Item_Not_Found_Message = "";
    public Building_Not_Found_Message = "";
    public Floor_Not_Found_Message = "";
    public Booking_Party_Not_Found_Title = "";
    public Building_Kpi_Not_Found_Title = "";
    public Connector_Not_Found_Title = "";
    public Device_Not_Found_Title = "";
    public Equipment_Not_Found_Title = "";
    public Parameter_Not_Found_Title = "";
    public Cost_Code_Not_Found_Title = "";
    public Booking_Party_Not_Found_Message = "";
    public Building_Kpi_Not_Found_Message = "";
    public Connector_Not_Found_Message = "";
    public Device_Not_Found_Message = "";
    public Equipment_Not_Found_Message = "";
    public Parameter_Not_Found_Message = "";
    public Cost_Code_Not_Found_Message = "";
}