import React from 'react';
import { Component } from "react";
import { appContext } from "../AppContext";
import IbssDialog from "./uicomponents/IbssDialog"
import IbssButton from "./uicomponents/IbssButton";
import { Box } from '@mui/material';
import IbssTextField from "./uicomponents/IbssTextField";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import IbssTransferList from "./uicomponents/IbssTransferList";
import Typography from '@mui/material/Typography';
import { CostCodeType, ICostCode } from "../Providers.Api/CostCodes/CostCodeRepository";
import { IListItem } from './uicomponents/IbssTransferList';

export default class CostCodeModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private modalRef: React.RefObject<IbssTransferList>

    constructor(props: IProps)
    {
        super(props);
        this.modalRef = React.createRef();
        this.state =
        {
            costCodes: [],
            costCodeTypeFilter: CostCodeType.Any,
            isLoading: false,
            search: '',
            selectedCostCodes: [], // state used to infer, in real time, whether the percentages of cost codes on the right of IbssTransferList add up to 100. 
            selectedCostCodesChanged: false,
            validCostCodeAllocation: null,
        }
    }

    private costCodeTypes =
        [
            {
                value: CostCodeType.Any,
                label: this.labels.HubLabelAny,
            },
            {
                value: CostCodeType.StandardCostCode,
                label: this.labels.HubLabelIBSSStandardCostCode,
            },
            {
                value: CostCodeType.ClientCode,
                label: this.labels.HubLabelClientCode,
            },
            {
                value: CostCodeType.DepartmentCode,
                label: this.labels.HubLabelDepartmentCode,
            },

        ]

    public async componentDidUpdate(prevProps: IProps, prevState: IState): Promise<void>
    {
        if (prevProps.show !== this.props.show && this.props.show === true)
        {
            // on showing modal to user, reset search term, costCodeType and costCodes states.
            this.setState({search: ''});
            this.setState({costCodes: []});
            this.setState({costCodeTypeFilter: CostCodeType.Any});
            // load costCodes.
            await this.loadCostCodes(50);
        }
        
        if (prevProps.bookingCostCodes !== this.props.bookingCostCodes)
        {
            this.setState({selectedCostCodes: this.props.bookingCostCodes.map(i => new CostCodePresentation(i))});
        }
        if (prevProps.show !== this.props.show)
        {
            // user closes the modal and program needs to reset state.selecteCostCodes to prevent bug.
            this.updateSelectedCostCodes(this.props.bookingCostCodes.map(i => new CostCodePresentation(i)));
        }
    }

    private async loadCostCodes(take: number, startOfCodeCode?: string, costCodeType?: CostCodeType): Promise<void>
    {
        this.setState({ isLoading: true });
        const costCodes = await this.apiClient.costCodes.getCostCodes(take, startOfCodeCode, costCodeType);
        const costCodesWithAllocation: CostCodePresentation[] = costCodes.map(i => ({
            id: i.Cost_Code_Id.toString(),
            primaryText: i.Cost_Code,
            secondaryText: i.Cost_Code_Description,
            percent: '', // need to set this to empty string rather than number 0, so the user gets an error to remind them to set a percent, both before and after Search and filter are applied. 
        }));

        this.setState({
            costCodes: costCodesWithAllocation,
            isLoading: false,
        });

        // imperatively set left list in transfer list. to deal with edge case where IbssTransferList does not refresh because props have not changed.
        const selectedCostCodes = this.modalRef.current?.state.right;
        
        const left = costCodesWithAllocation.filter(i => !selectedCostCodes?.some(j => j.id === i.id));
        
        this.modalRef.current?.setState({ left: left});
    }

    private filterByCostCodeType(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const costCodeType = e.target.value as CostCodeType;
        this.setState({ costCodeTypeFilter: costCodeType });
    }

    private handleSearchInput(e: React.ChangeEvent<HTMLInputElement>): void
    {
        this.setState({ search: e.target.value });
    }

    private handleSearch(): void
    {
        this.loadCostCodes(50, this.state.search, this.state.costCodeTypeFilter);
    }

    private async updateBookingCostCodes(): Promise<void> 
    {
        // update costCodes state in CostCodeModal's parent component.
        const costCodesToAdd: CostCodeWithAllocation[] = this.state.selectedCostCodes.map(costCode => ({
            costCode: costCode.primaryText,
            costCodeId: costCode.id!,
            costCodeDescription: costCode.secondaryText,
            allocation: parseInt(costCode?.percent ?? '0') ,
        }));

        this.props.updateBookingCostCodes(costCodesToAdd);
        this.props.onClose();
    }

    private validPercentSum(list: IListItem[]): boolean
    {
        if(list.length === 0 )
        {
            return true;
        }

        // cost code allocation percentages have to add up to 100.
        if (list.some(i => i?.percent === "")) return false;

        const percentList = list.map(i => parseInt(i?.percent ?? '0'));
        return percentList.reduce((accumulator, currentValue) => accumulator + currentValue, 0) === 100;
    }

    private updateSelectedCostCodes(list: IListItem[]): void
    {
        this.setState({
            selectedCostCodes: list,
            selectedCostCodesChanged: this.bookingCostCodesChanged(list),
            validCostCodeAllocation: this.validPercentSum(list),
        });
    }

    private bookingCostCodesChanged(list: IListItem[]): boolean
    {
        // cost codes associated with the booking has been updated.
        return JSON.stringify(this.props.bookingCostCodes.map(i=> new CostCodePresentation(i)))!== JSON.stringify(list);
    }

    public render(): JSX.Element
    {
        const buttonLabel = this.props.bookingCostCodes.length > 0 ? this.labels.HubLabelUpdate : this.labels.HubLabelAddButton;

        return (
            <IbssDialog
                fullWidth
                maxWidth={'md'}
                open={this.props.show}
                onClose={this.props.onClose}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelCostCodes}</label>
                    </>
                }
                content=
                {
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '0px 0px 10px 0px'
                        }}>
                            <IbssTextField
                                id="cost-code-type-filter"
                                label={this.labels.HubLabelCodeType}
                                select
                                sx={{ width: '280px' }}
                                value={this.state.costCodeTypeFilter}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => { this.filterByCostCodeType(e) }}
                            >
                                {
                                    this.costCodeTypes.map(option =>
                                        <MenuItem key={option.label} value={option.value}>
                                            {option.label}
                                        </MenuItem>)
                                }
                            </IbssTextField>
                            <IbssTextField
                                id="cost-code-search"
                                label={this.labels.HubLabelSearch}
                                variant="outlined"
                                placeholder={`${this.labels.HubLabelCostCode} ...`}
                                sx={{ width: '350px' }}
                                value={this.state.search}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSearchInput(event)}
                            />
                            <IbssButton
                                variant='contained'
                                disabled={this.state.search.length < 7}
                                onClick={() => this.handleSearch()}
                            >
                                {this.labels.HubLabelSearch}{/*'Search'*/}
                            </IbssButton>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0px' }}>
                            <Typography sx={{
                                fontFamily: 'Source Sans Pro',
                                fontSize: '14px',
                                fontWeidght: '400',
                                color: (theme) => theme.palette.error.main,
                                visibility: this.state.costCodes.length >= 50 ? 'visible' : 'hidden',
                            }}>
                                {/*'Results: 50+ Add additional search criteria to narrow down results further'*/}
                                {this.labels.HubMessageNarrowResults}
                            </Typography>
                            <Typography sx={{
                                fontFamily: 'Source Sans Pro',
                                fontSize: '14px',
                                fontWeidght: '400'
                            }}>
                                {/*'Cost Code Apportioning %:'*/}
                                {this.labels.HubLabelCostCodeApportioning}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '20px', margin: '0' }} >
                            <IbssTransferList
                                ref={this.modalRef}
                                flexHorizontalPlacement={'flex-start'}
                                left={this.state.costCodes.filter(i => !this.props.bookingCostCodes.some(j => j.costCodeId === i.id)).map(costCode => ({
                                    id: (costCode.id),
                                    primaryText: costCode.primaryText,
                                    secondaryText: costCode?.secondaryText ?? '',
                                    percent: '',
                                }))}
                                right={this.props.bookingCostCodes.map(costCode => ({
                                    id: (costCode.costCodeId),
                                    primaryText: costCode.costCode,
                                    secondaryText: costCode?.costCodeDescription ?? '',
                                    percent: (costCode.allocation).toString(),
                                }))}
                                listWidth={'315px'}
                                onRightListChanged={e => this.updateSelectedCostCodes(e)}
                                showInputs={true}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: '10px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Source Sans Pro',
                                    fontSize: '14px',
                                    fontWeidght: '400',
                                    color: (theme) => theme.palette.error.main,
                                    visibility: this.state.selectedCostCodes.length === 0 || this.state.validCostCodeAllocation ? 'hidden' : 'visible'
                                }}>
                                {/*'The breakdown of costs need to equal 100%'*/}
                                {this.labels.HubMessageCostsAddUpTo100}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <IbssButton
                                variant='contained'
                                disabled={!this.state.validCostCodeAllocation || !this.state.selectedCostCodesChanged}
                                onClick={() => this.updateBookingCostCodes()}>{buttonLabel}{/*Add or Update*/}</IbssButton>
                        </Box>
                    </Box>
                }

            />
        )
    }
}

export interface IProps
{
    bookingId: string,
    bookingCostCodes: CostCodeWithAllocation[];
    show: boolean,
    onClose: () => void,
    updateBookingCostCodes: (updatedCostCodes: CostCodeWithAllocation[]) => void,
}

export interface IState
{
    costCodes: CostCodePresentation[];
    costCodeTypeFilter: CostCodeType;
    isLoading: boolean;
    search: string;
    selectedCostCodes: IListItem[];
    selectedCostCodesChanged: boolean;
    validCostCodeAllocation: boolean | null;
}

export class CostCodeWithAllocation
{
    public costCode: string;
    public costCodeDescription: string;
    public costCodeId: string;
    public allocation: number;

    constructor(codeCode: ICostCode, allocation: number)
    {
        this.costCode = codeCode.Cost_Code;
        this.costCodeDescription = codeCode.Cost_Code_Description;
        this.costCodeId = codeCode.Cost_Code_Id;
        this.allocation = allocation;
    }
}

export class CostCodePresentation
{
    public id: string;
    public primaryText: string;
    public secondaryText: string;
    public percent: string;

    constructor(costCode: CostCodeWithAllocation)
    {
        this.id = costCode.costCodeId;
        this.primaryText = costCode.costCode;
        this.secondaryText = costCode?.costCodeDescription ?? '';
        this.percent = (costCode.allocation).toString();
    }

}