import Labels from "./Labels";
import ApiMessages from "./ApiMessages";

export class TextProvider implements ITextProvider
{
    public async getLocalLabels(): Promise<Labels>
    {
        // get default labels
        let defaultLabels: any = null;
        const defaultLabelsPath = `./en/Labels.json`;

        try
        {
            defaultLabels = await import(`${defaultLabelsPath}`);
            console.log(`Loaded the default labels from '${defaultLabelsPath}'.`);
        }
        catch 
        {
            console.error(`Failed to load the default labels from '${defaultLabelsPath}'.`);
            defaultLabels = {};
        }

        // get labels for locale
        let preferredLabels: any = null;
        const languages = navigator.languages;

        for (var i = 0; i < languages.length; i++)
        {
            try
            {
                const preferredLabelsPath = `./${languages[i]}/Labels.json`;
                preferredLabels = await import(`${preferredLabelsPath}`);
                console.log(`Loaded the labels for the current locale from '${preferredLabelsPath}'.`);
                break;
            }
            catch
            {
            }
        }

        if (preferredLabels == null)
        {
            console.error("Failed to load the labels for the current locale.");
            preferredLabels = {};
        }

        // map labels
        let labelsInterface = new Labels();
        let key: keyof Labels;
        const defaultValuesByKey = new Map(Object.keys(defaultLabels).map(i => [ i.toLowerCase(), defaultLabels[i] as string ]));
        const preferredValuesByKey = new Map(Object.keys(preferredLabels).map(i => [ i.toLowerCase(), preferredLabels[i] as string ]));

        for (key in labelsInterface)
        {
            const keyInLowercase = key.toLowerCase();
            const preferredValue = (preferredValuesByKey.get(keyInLowercase) ?? "");
            const defaultValue = (defaultValuesByKey.get(keyInLowercase) ?? "");
            labelsInterface[key] = (preferredValue.trim() != "" ? preferredValue : (defaultValue.trim() != "" ? defaultValue : `[${key}]`));
        }

        // done
        return labelsInterface;
    }

    public async getLocalApiMessages(): Promise<ApiMessages>
    {
        // get default labels
        let defaultLabels: any = null;
        const defaultLabelsPath = `./en/ApiMessages.json`;

        try
        {
            defaultLabels = await import(`${defaultLabelsPath}`);
            console.log(`Loaded the default labels from '${defaultLabelsPath}'.`);
        }
        catch 
        {
            console.error(`Failed to load the default labels from '${defaultLabelsPath}'.`);
            defaultLabels = {};
        }

        // get labels for locale
        let preferredLabels: any = null;
        const languages = navigator.languages;

        for (var i = 0; i < languages.length; i++)
        {
            try
            {
                const preferredLabelsPath = `./${languages[i]}/ApiMessages.json`;
                preferredLabels = await import(`${preferredLabelsPath}`);
                console.log(`Loaded the labels for the current locale from '${preferredLabelsPath}'.`);
                break;
            }
            catch
            {
            }
        }

        if (preferredLabels == null)
        {
            console.error("Failed to load the labels for the current locale.");
            preferredLabels = {};
        }

        // map labels
        let labelsInterface = new ApiMessages();
        let key: keyof ApiMessages;
        const defaultValuesByKey = new Map(Object.keys(defaultLabels).map(i => [ i.toLowerCase(), defaultLabels[i] as string ]));
        const preferredValuesByKey = new Map(Object.keys(preferredLabels).map(i => [ i.toLowerCase(), preferredLabels[i] as string ]));

        for (key in labelsInterface)
        {
            const keyInLowercase = key.toLowerCase();
            const preferredValue = (preferredValuesByKey.get(keyInLowercase) ?? "");
            const defaultValue = (defaultValuesByKey.get(keyInLowercase) ?? "");
            labelsInterface[key] = (preferredValue.trim() != "" ? preferredValue : (defaultValue.trim() != "" ? defaultValue : `[${key}]`));
        }

        // done
        return labelsInterface;
    }
}

export interface ITextProvider
{
    getLocalLabels(): Promise<Labels>;
    getLocalApiMessages(): Promise<ApiMessages>;
}
