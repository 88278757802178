import { Component } from 'react'
import { connect } from 'react-redux';
import { appContext } from '../../../AppContext';
import Header, { IProps as IHeaderProps } from '../../../Components/Header'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import IbssHorizontalTabs, { TabsParameters } from '../../../Components/uicomponents/IbssTabs';
import YourNotifications from './YourNotifications';
import { Box, styled, BoxProps } from '@mui/material';
import { RootState } from '../../../app/store';
import apis from '../../../Providers.Api/apis';
import { NotificationListProps } from '../../../Components/uicomponents/models/FlexNotificationProps';
import BuildingNotifications from './BuildingNotifications';
import IbssBadge from '../../../Components/uicomponents/IbssBadge';
import NotificationDetails from './NotificationDetails';
import { MY_NEW_NOTIFICATION, MY_NOTIFICATION_LIST, MY_NOTIFICATION_READED_LIST, UPDATE_MAIN_PAGE_TITLE } from '../../../app/constants';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
    background: `var(--ui-background-alternate)`,
    height: "-webkit-fill-available"
}));

class MyNotificationState {
    public myNotificationList: NotificationListProps[] = [];
    public myNotificationListCopy: NotificationListProps[] = [];
    public buildingNotificationList: NotificationListProps[] = [];
    public buildingNotificationListCopy: NotificationListProps[] = [];
    public notificationDetail: NotificationListProps = {
        Notification_Id: 0,
        ImageURI: "",
        ActionURI: "",
        Notification_Title: "",
        Notification_Priority: 1,
        Classification: "",
        Subclassification: "",
        Notification_Message: "",
        Available_From: "",
        Expiry_Date: "",
        Node_Id: 0,
        Notification_Record_Id: "",
        Notification_Short_Message: "",
        read: false,
    };
    public allNotification = true;
}

class FlexMyNotications extends Component<any, MyNotificationState> {
    private labels = appContext().labels;
    private apiClient = appContext().apiClient;

    constructor(props: any) {
        super(props);
        this.state = new MyNotificationState();
    }

    public async componentDidMount() {
        // setting the Page title
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubMenuMyNotifications });

        // getting notificationLists from redux store
        const { notificationList, notificationReadedList } = this.props;
        let response: NotificationListProps[] = notificationList.map((value: NotificationListProps) => ({ ...value, read: false }));
        if (notificationList?.length === 0) {
            const notifyResponse = await this.apiClient.notifications.getActiveNotification(1);
            response = notifyResponse.map(value => ({ ...value, read: false }));
            if (notificationList.length < response.length) {
                dispatch({ type: MY_NEW_NOTIFICATION, payload: true });
            }
            dispatch({ type: MY_NOTIFICATION_LIST, payload: response });
        };

        // compare read notification list and all notification list to remove duplicate items
        const readIds = notificationReadedList.map((obj: NotificationListProps) => obj.Notification_Id);
        const notificationUnReadList = response.filter(({ Notification_Id }) => !readIds.includes(Notification_Id));

        // if there is any data in read list then concat All the read and unread notification list
        if (notificationReadedList.length === 0) {
            response = response;
        } else {
            response = [...notificationUnReadList, ...notificationReadedList];
        }

        // filter out building notification list
        const buildingNotifyList: NotificationListProps[] = response.filter((item: NotificationListProps) => item.Classification === "Building" && item.Subclassification !== "INSPIRE");
        this.setState({ buildingNotificationList: buildingNotifyList, buildingNotificationListCopy: buildingNotifyList });

        // filter out my notification list
        const myNotifyList: NotificationListProps[] = response.filter((item: NotificationListProps) => item.Classification === "YourAlerts");
        this.setState({ myNotificationList: myNotifyList, myNotificationListCopy: myNotifyList })

    }

    private setNotificationDetails = async (notifyDetails: NotificationListProps) => {
        const { dispatch, notificationReadedList, notificationList } = this.props;
        this.setState({
            notificationDetail: notifyDetails
        });

        if (!notificationReadedList.some((val: NotificationListProps) => val.Notification_Id === notifyDetails.Notification_Id)) {
            await dispatch({ type: MY_NOTIFICATION_READED_LIST, payload: [...notificationReadedList, notifyDetails] });
            if (notificationList.length === (notificationReadedList.length + 1)) {
                dispatch({ type: MY_NEW_NOTIFICATION, payload: false });
            }
        }

        if (notifyDetails.Classification === "Building") {
            const newBuildingNotificationlist = this.state.buildingNotificationList.map((obj: NotificationListProps) => {
                // 👇️ if Notification_Id equals 2, update country property
                if (obj.Notification_Id === notifyDetails.Notification_Id) {
                    return { ...obj, read: true };
                }

                // 👇️ otherwise return object as is
                return obj;
            });

            this.setState({
                buildingNotificationList: newBuildingNotificationlist, buildingNotificationListCopy: newBuildingNotificationlist
            })
        } else {
            const newMyNotificationlist = this.state.myNotificationList.map((obj: NotificationListProps) => {
                // 👇️ if Notification_Id equals 2, update country property
                if (obj.Notification_Id === notifyDetails.Notification_Id) {
                    return { ...obj, read: true };
                }

                // 👇️ otherwise return object as is
                return obj;
            });

            this.setState({
                myNotificationList: newMyNotificationlist, myNotificationListCopy: newMyNotificationlist
            })
        }
    }

    private removeNotificationDetails = () => {
        this.setState({
            notificationDetail: {
                Notification_Id: 0,
                ImageURI: "",
                ActionURI: "",
                Notification_Title: "",
                Notification_Priority: 1,
                Classification: "",
                Subclassification: "",
                Notification_Message: "",
                Available_From: "",
                Expiry_Date: "",
                Node_Id: 0,
                Notification_Record_Id: "",
                Notification_Short_Message: "",
                read: false,
            }
        });
    };

    private handleChipFilter = (type: string, from: string) => {
        if (type === "All") {
            this.setState({ allNotification: true });
            if (from === "Building") {
                this.setState({
                    buildingNotificationList: this.state.buildingNotificationListCopy
                });
            } else {
                this.setState({
                    myNotificationList: this.state.myNotificationListCopy
                });
            }
        } else {
            this.setState({ allNotification: false });
            if (from === "Building") {
                const unReadOnly = this.state.buildingNotificationList.filter((item: NotificationListProps) => item.read === false);
                this.setState({
                    buildingNotificationList: unReadOnly
                });
            } else {
                const unReadOnly = this.state.myNotificationList.filter((item: NotificationListProps) => item.read === false);
                this.setState({
                    myNotificationList: unReadOnly
                });
            }
        }
    }

    render() {

        const ChipList = [
            {
                label: this.labels.funcNotificationsListAll_S,
                onClick: (value: string) => this.handleChipFilter("All", value),
                className: `${!this.state.allNotification ? "btn-secondary-outline" : null} ml-24 mb-16 mt-24`,
            },
            {
                label: this.labels.funcNotificationsListUnread_S,
                onClick: (value: string) => this.handleChipFilter("Unread", value),
                className: `${this.state.allNotification ? "btn-secondary-outline" : null} mx-2 mb-16 mt-24`,
            }
        ];

        const tabs: TabsParameters[] = [
            {
                label: (
                    <>
                        <div>
                            <IbssBadge
                                badgeContent={this.state?.myNotificationList?.length > 0 ? this.state.myNotificationList.filter((item) => item.read == false).length : 0}
                                color="error"
                            />
                            <span className='notification-label'>{this.labels.HubLabelYourNotifications}</span>
                        </div>
                    </>
                ),
                components: (
                    <>
                        <YourNotifications
                            ChipList={ChipList}
                            myNotificationList={this.state?.myNotificationList}
                            setNotificationDetails={this.setNotificationDetails}
                        />
                    </>
                ),
                tabButtonHeight: "75px",
            },
            {
                label: (
                    <>
                        <div>
                            <IbssBadge
                                badgeContent={this.state?.buildingNotificationList?.length > 0 ? this.state.buildingNotificationList.filter((item) => item.read === false).length : 0}
                                color="error"
                            />
                            <span className='notification-label'>{this.labels.HubLabelAnnouncements}</span>
                        </div>
                    </>
                ),
                components: (
                    <>
                        <BuildingNotifications
                            ChipList={ChipList}
                            buildingNotificationList={this.state?.buildingNotificationList}
                            setNotificationDetails={this.setNotificationDetails}
                        />
                    </>
                ),
                tabButtonHeight: "75px",
            },
        ];
        return (
            <>
                <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
                    <Sidebar {...this.props} />
                    <div className="rightPanel">
                        <Header {...this.props as unknown as IHeaderProps} />
                        <div className="rightPanel-main-content notification-box">
                            <div className={`notification-card ${this.state.notificationDetail.Node_Id !== 0 ? 'd-flex' : null}`} style={{ height: "100%", width: "auto" }}>
                                <StyledBox className="notification-section">
                                    <IbssHorizontalTabs
                                        tabs={tabs}
                                        orientation="horizontal"
                                        boxwidth='100%'
                                        className=""
                                    />
                                </StyledBox>
                                {this.state?.notificationDetail?.Node_Id !== 0 && (
                                    <NotificationDetails
                                        details={this.state?.notificationDetail}
                                        removeNotificationDetails={this.removeNotificationDetails}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,
        notificationList: state.notificationList,
        notificationReadedList: state.notificationReadedList,
        newNotification: state.newNotification,
    };
};

export default connect(mapStateToProps)(FlexMyNotications);