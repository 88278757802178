import React, { Component } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { appContext } from '../../../../AppContext';
import IbssInputDropDown from '../../../../Components/uicomponents/IbssInputDropDown';
import { InputOption } from '../../../../Components/uicomponents/InputSelectBox';
import Helper from '../../../../Common/Helper';
import IbssButton from '../../../../Components/uicomponents/IbssButton';
import { getUserPrefrenceJosn } from '../../../../Common/UserPreferenceJSON';
import { IUserPreferences, IUserPreferencesNode } from '../../../../Providers.Api/UserPreferenceRepository';

class BuildingState {
    public buildings =
        {
            name: "DefaultBuilding",
            id: "DefaultBuilding",
            inputLabel: "",
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };

    public floors =
        {
            name: "DefaultFloor",
            id: "DefaultFloor",
            inputLabel: "",
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };
    public buildingUserPrefState: IUserPreferences = getUserPrefrenceJosn();
};

class Building extends Component<any, BuildingState> {
    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider

    constructor(props: any) {
        super(props)
        this.state = new BuildingState();
    };

    componentDidMount(): void {

        // get user preferences
        const userPreferences = this.local.getUserPreferences();
        const buildingId = userPreferences?.SearchPrefs?.DefaultBuilding;
        const floorId = userPreferences?.Nodes?.find((i: IUserPreferencesNode) => i.NodeId === buildingId)?.DefaultFloor as number | null;

        // configure buildings input
        this.setState({
            buildings: {
                value: buildingId,
                inputLabel: this.labels.dataUserPrefWorkplaceDefBuilding_L,
                options: this.makeOptions(Helper.getAllBuildingsData(), "Name", "Node_Id"),
                onChange: (value => this.buildingChanged(value)),
                name: "DefaultBuilding",
                id: "DefaultBuilding",
            },
            buildingUserPrefState: userPreferences,
        });

        // configure floors input
        this.state.floors.inputLabel = this.labels.dataUserPrefWorkplaceDefFloor_L
        this.state.floors.options = this.makeOptions(Helper.getFloorsFromSelectedBuilding(buildingId), "Node_Name", "Node_Id");
        this.state.floors.value = floorId;
        this.state.floors.onChange = (value => this.floorChanged(value));
    };

    private makeOptions(data: Array<any>, labelField: string, valueField: string): Array<InputOption> {
        let options = new Array<InputOption>();

        if (data) {
            data.map(i => {
                options.push(new InputOption(i[labelField], i[valueField]));
            });
        }

        return options;
    };

    private buildingChanged(option: any): void {
        this.state.buildings.value = option.target.value;
        this.state.floors.options = this.makeOptions(Helper.getFloorsFromSelectedBuilding(option.target.value), "Node_Name", "Node_Id");
        this.state.floors.value = 0;
        this.setState({ buildings: this.state.buildings, floors: this.state.floors });
    };

    private floorChanged(option: any): void {
        this.state.floors.value = option.target.value;
        this.setState({ floors: this.state.floors });
    };

    private submitBuildingAndFloor = async () => {
        const payload:IUserPreferences = {
            ...this.state.buildingUserPrefState,
            SearchPrefs:
            {
                DefaultBuilding: this.state.buildings.value,
                DefaultSearchResults: this.state.buildingUserPrefState.SearchPrefs.DefaultSearchResults,
            },
            Nodes:
                [
                    {
                        NodeId: this.state.buildings.value,
                        DefaultFloor: this.state.floors.value,
                    }
                ]
        };

        try
        {
            await this.userPreferences.update(payload);
            this.alert.show(this.labels.HubLabelSuccess, this.labels.HubLabelDataHasBeenSavedSuccessfully);
        }
        catch { }
        
        // await this.userPrefApi.updateUserPreferences(payload);
    };

    render() {

        return (
            <>
                <Grid container>
                    <Card className='card-userPref'>
                        <Grid
                            container
                            gap={1}
                        >
                            <Typography className='card-userPref-title' variant="h6" gutterBottom>{this.labels.HubTitleTailorNotification}</Typography>

                            <Typography className='card-userPref-text mb-32' variant="body2" gutterBottom>{this.labels.HubSubtitleworkplace}</Typography>

                        </Grid>

                        <Grid className='mb-16'>
                            <IbssInputDropDown
                                {...this.state.buildings}
                            />
                        </Grid>

                        <Grid>
                            <IbssInputDropDown
                                {...this.state.floors}
                            />
                        </Grid>

                    </Card>
                </Grid>
                <div className='btn-right'>
                    <IbssButton variant='contained' onClick={() => this.submitBuildingAndFloor()}>
                        {this.labels.HubButtonSave}
                    </IbssButton>
                </div>
            </>
        )
    }
};

export default Building;
