import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { IV2GetResponse } from "../Models";
import { DateHelper } from "../../Common/DateHelper";
import { IDeleteBookingResponse } from "./DeleteV1BookingEndpoint";

export class DeleteV2BookingEndpoint implements IDeleteV2BookingEndpoint
{
    public async execute(buildingId: number, bookingId: string): Promise<IDeleteBookingResponse>
    {
        try
        {
            const response = await axios.patch<IDeleteBookingResponse>(apis.allApiv2 + buildingId + `/bookings/` + bookingId + `/cancel` );
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IDeleteV2BookingEndpoint
{
    execute(buildingId: number, bookingId: string): Promise<IDeleteBookingResponse>;
}
