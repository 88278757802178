import { Component } from "react";
import { appContext } from "../../../AppContext";
import { Card, Grid, SvgIcon, TextField, TextareaAutosize, Divider, Typography, Box } from "@mui/material";
import "../../../styles/css/dashboard.scss";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import { CateringTask } from "../../../Providers.Api/Tasks/TaskRepository";
import IbssSvgIcon from "../../../Components/uicomponents/IbssSvgIcon";
import { Icons } from "../../../Common/AllsvgIcons";
import RightArrowIcon from "../../../Components/uicomponents/customMaterialIcons/RightArrowIcon";
import customTheme from "../../../customTheme";
import IbssIconButton from "../../../Components/uicomponents/IbssIconButton";
import { RouteComponentProps } from "react-router-dom";
import IbssTimePicker from "../../../Components/uicomponents/IbssTimePicker";
import { DateTime } from "luxon";
import { ReactComponent as CostCodeIcon } from '../../../Components/Sidebar/icons/CostCode.svg';
import CostCodeModal, { CostCodeWithAllocation } from "../../../Components/CostCodeModal";
import { ICostCodeAllocation, ICreateTask } from "../../../Providers.Api/Tasks/CreateTaskEndpoint";
import Spinner from "../../../Components/Spinner";
import moment from "moment";
import { IPropsFromState } from "../../../redux/Interfaces";
import { ICateringOrder } from "../../../Providers.Api/CateringOrders/CateringOrderRepository";
import { INode } from "../../../Providers.Api/Models";
import { Modal } from "react-bootstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Space } from "../../../Providers.Api/Spaces/SpaceRepository";

export default class EditCateringOrder extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get appState() { return appContext().state; }
    private get bookingService() { return appContext().bookingService; }
    private get apiCache() {return appContext().apiCache; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            originalTask: new TaskView(),
            task: new TaskView(),
            orderItems: [],
            loading: false,
            currency: '',
            preBookCateringTime: '',
            bookingStart: '',
            bookingEnd: '',
            showConfirmationModal: false,
            floorId: 0,
            numOfAttendees: 0,
            bookingName: '',
            spaces: [],
            showCostCodeModal: false,
            lightModeTheme: this.appState.lightModeTheme,
            costCodes: [],
        };
    }

    private _buildingId: number | null = null;
    private get buildingId(): number
    {
        if (this._buildingId == null)
        {
            this._buildingId = Number(this.props.match.params.buildingid);
        }
        return this._buildingId;
    }

    private _primarySpaceId: string | null = null;
    private get primarySpaceId(): string
    {
        if (this._primarySpaceId == null)
        {
            const delimitedSpaceIds = this.props.match.params.spaceId;
            if (!delimitedSpaceIds)
            {
                return "";
            }
            const spaceIds = delimitedSpaceIds.split(";");
            this._primarySpaceId = spaceIds[0];
        }
        return this._primarySpaceId;
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap<IState>(this, i => ({ lightModeTheme: i.lightModeTheme }));
        const { match } = this.props;
        const availableSpaces = this.localStorage.getNodeData();
        let selectedBuilding: INode | null = null;
        const buildings = availableSpaces.Regions.map(x => x.Buildings);
        const spaces = await this.apiCache.getSpacesByBuilding(this.buildingId);
        this.setState({ spaces: spaces })

        for (let i = 0; i < buildings.length; i++)
        {
            const building = buildings[i].filter(building => building.Node_Id === this.buildingId);
            if (building.length > 0)
            {
                selectedBuilding = building[0];
                break;
            }
        }
        if (selectedBuilding == null)
        {
            return;
        }
        this.setState({ loading: true, currency: selectedBuilding.Cat_Crncy, preBookCateringTime: selectedBuilding.Pre_Book_Cat_Time });

        if(match.params.spacecateringId)
        {
            let taskData = await this.apiClient.tasks.getTask(CateringTask, 1, match.params.spacecateringId);
            const task = TaskView.fromApiTask(taskData);
            this.setState({ task: task, originalTask: task });
            
            const taskOrderItemsData = await this.apiClient.cateringOrders.getCateringOrdersEdit(1, match.params.spacecateringId);
            const orderItems = taskOrderItemsData.map(x => CateringOrderView.fromApiCateringOrder(x));
            this.setState({ orderItems: orderItems });
            const bookingData = await this.bookingService.get(taskData.Node_Id, this.state.task.bookingId);
            this.setState({ bookingStart: bookingData.Booking_Start?.toString(), bookingEnd: bookingData.Booking_End?.toString(), bookingName: bookingData.Booking_Name});
        }

        if (match.params.spaceId)
        {
            const floorId = spaces.filter(space => space.Space_Id == this.primarySpaceId)[0].Node_Id
            const taskOrderItemsData = await this.apiClient.cateringOrders.getNewCateringOrders(1, this.primarySpaceId);
            const orderItems = taskOrderItemsData.map(x => CateringOrderView.fromApiCateringOrder(x));
            this.setState({ orderItems: orderItems });
            const bookingData = await this.bookingService.get(floorId, match.params.bookingid);
            
            this.setState(
            {
                floorId: floorId,
                bookingStart: bookingData.Booking_Start?.toString(),
                bookingEnd: bookingData.Booking_End?.toString(),
                bookingName: bookingData.Booking_Name,
                task: new TaskView(
                {
                    spaceId: match.params.spaceId,
                    cateringServiceTime: bookingData.Booking_Start?.toString(),
                    cateringClearingTime: bookingData.Booking_End?.toString(),
                    cateringAttendees: bookingData.Booking_Parties.filter(x => x.Booking_Participant_Type == 1 || x.Booking_Participant_Type == 2).length + 1 //1 = Guest, 2 = Visitor, + 1 for the host
                }),
            });
        }

        await this.loadTaskCostCodes();
        this.setState({ loading: false });
    };

    private itemQuantityChanged(itemId: number, type: 'remove' | 'add'): void
    {
        const objIndex = this.state.orderItems.findIndex(x => x.menuItemId == itemId);
        let orderItems = this.state.orderItems;

        if (type == 'remove' && orderItems[objIndex].cateringOrderItemQuantity != '0')
        {
            const updatedItem = new CateringOrderView({ ...orderItems[objIndex], cateringOrderItemQuantity: (parseInt(orderItems[objIndex].cateringOrderItemQuantity) - 1).toString() });
            orderItems[objIndex] = updatedItem;
        }
        if (type == 'add')
        {
            const updatedItem = new CateringOrderView({ ...orderItems[objIndex], cateringOrderItemQuantity: (parseInt(orderItems[objIndex].cateringOrderItemQuantity) + 1).toString() });
            orderItems[objIndex] = updatedItem;
        }
        this.setState({ orderItems: orderItems });

    }

    private async updateTask(): Promise<void>
    {
        const { match } = this.props
        this.setState({ loading: true })
        const task = this.state.task.toApiTask();
        const orderItems = this.state.orderItems.map(x => x.toApiModel());
        const activeurl = window.location.href;
        
        await this.apiClient.tasks.updateTask(1, this.state.task.taskId, task)
        await this.apiClient.cateringOrders.updateCateringOrders(this.state.task.nodeId, this.state.task.taskId, orderItems)

        if(match.params.bookingid !== undefined || activeurl.includes("flex-my-bookings"))
        {
            this.props.history.goBack();
        }
        else
        {
            this.props.history.push("/operational-services-catering/" + this.buildingId + "/catering/" + match.params.spacecateringId + "/view")
        }
    }

    private cancelChanges(): void
    {
        const { match } = this.props
        const activeurl = window.location.href;
        
        if(match.params.bookingid !== undefined || activeurl.includes("flex-my-bookings"))
        {
            this.props.history.goBack();
        }
        else
        {
            this.props.history.push("/operational-services-catering/" + this.buildingId + "/catering/" + match.params.spacecateringId + "/view");
        }
    }

    private async createTask(): Promise<void>
    {
        const { match } = this.props
        this.setState({ loading: true });

        const orderItems =  this.state.orderItems
            .filter(item => parseInt(item.cateringOrderItemQuantity) > 0).map(item => {return(`Item: ${item.menuItemTitle}, Qty: ${item.cateringOrderItemQuantity},\\n`)});

        const costCodeAllocations = this.state.costCodes
            .map(i => ({ Cost_Code: i.costCode, Cost_Code_Id: i.costCodeId, Allocation: i.allocation }));

        const payload = this.state.task.toApiCreateTask(match.params.spaceId, match.params.bookingid, this.state.bookingStart, this.state.numOfAttendees, orderItems, costCodeAllocations);
        await this.apiClient.tasks.createTask(this.state.floorId, payload)
        this.props.history.push('/flex-my-bookings/' + this.buildingId + '/mybooking/' + match.params.bookingid + '/' + match.params.spaceId);
    }

    private spaceNamesById: (Map<string, string> |  null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        this.spaceNamesById = new Map(this.state.spaces.map(i => [ i.Space_Id, i.Space_Name ]));
        return this.spaceNamesById.get(spaceId) ?? "";
    }

    private handleCostCodeModal(): void
    {
        this.setState((prevState) => ({
            showCostCodeModal: !prevState.showCostCodeModal
        }));
    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState((prevState) => {
            return {
                ...prevState, 
                costCodes: updatedCostCodes,
                task: new TaskView({
                    ...prevState.task, 
                    costCodeAllocation: updatedCostCodes.map(i => ({costCode: i.costCode, costCodeId: i.costCodeId, allocation: i.allocation})), 
                }),
            }
        });
    }

    private async loadTaskCostCodes(): Promise<void>
    {
        this.setState({ loading: true });

        const taskCostCodes = this.state.task.costCodeAllocation ?? [];

        // make a call to the cost codes api to get more info about cost codes than is available from task's Cost_Code_Allocation field.
        const costCodeIds = taskCostCodes.map(i => i.costCodeId);
        const costCodePromises = costCodeIds.map(id => this.apiClient.costCodes.getCostCodeById(id));
        const costCodes = await Promise.all(costCodePromises);

        const costCodesWithAllocation = costCodes.map(i => 
        {
            return new CostCodeWithAllocation(i, taskCostCodes.filter(j => j.costCodeId === i.Cost_Code_Id)[0].allocation);
        });

        this.setState({
            costCodes: costCodesWithAllocation, //costs codes associated with task, which has to be displayed on this component.
        });


        this.setState({
            loading: false,
        });
    }

    private getCostCodeLabel(): string
    {
        /* return the string that is returned on the cost code button*/
        const numberOfCostCodes = this.state.costCodes.length;
        if (numberOfCostCodes === 1)
        {
            return this.state.costCodes[0].costCode; //if just one cost code, show the cost code
        }
        else if (numberOfCostCodes > 1)
        {
            return `${this.state.costCodes.length} ${this.labels.HubLabelCostCodes}` // Cost Codes
        }
        else
        {
            return '';
        }
    }

    private handleChangeCateringServiceTime(e: Date | null): void
    {
        if(e !== null)
        {
            const time = new Date(e);
            if(!isNaN(time.getTime())) // check the input date is a valid date, so changing the time by typing doesn't crash program.
            {
                this.setState({ task: new TaskView({ ...this.state.task, cateringServiceTime: time?.toISOString() ?? '' }) });
            }
        }
    }

    private handleChangeCateringClearingTime(e: Date | null): void
    {   
        if(e !== null)
        {
            const time = new Date(e);
            if(!isNaN(time.getTime())) // check the input date is a valid date, so changing the time by typing doesn't crash program.
            {
                this.setState({ task: new TaskView ({ ...this.state.task, cateringClearingTime: time?.toISOString() ?? '' }) });
            }
        }
    }

    public render(): JSX.Element
    {
        const { match, history } = this.props;
        const cutOffTimeDays = this.state.preBookCateringTime.split('.')[0];
        const preBookCateringHours = parseInt(this.state.preBookCateringTime?.split('.')[1]?.split(':')[0]);
        const bookingStartHours = DateTime.fromISO(this.state.bookingStart).hour;
        const preBookCateringMinutes = parseInt(this.state.preBookCateringTime?.split('.')[1]?.split(':')[1]);
        const bookingStartMinutes = DateTime.fromISO(this.state.bookingStart).minute;
        const cutOffTimeHours = bookingStartHours - preBookCateringHours < 0 ? (bookingStartHours - preBookCateringHours) + 24 : bookingStartHours - preBookCateringHours;
        const cutOffTimeMinutes = bookingStartMinutes - preBookCateringMinutes < 0 ? (bookingStartMinutes - preBookCateringMinutes) + 60 : bookingStartMinutes - preBookCateringMinutes;

        const gridTaskDetailValues: IGridTaskDetailValues[] =
        [
            {
                details: this.labels.HubLabelLocation,
                original: this.state.originalTask ? this.state.originalTask.spaceId : '',
                change: this.state.task ? this.state.task.spaceId : ''
            },
            {
                details: this.labels.HubLabelServiceTime,
                original: this.state.originalTask ? `${DateTime.fromISO(this.state.originalTask.cateringServiceTime).toFormat('HH:mm')}` : '',
                change: this.state.task ? `${DateTime.fromISO(this.state.task.cateringServiceTime).toFormat('HH:mm')}` : '',
            },
            {
                details: this.labels.HubLabelCleaningTime,
                original: this.state.originalTask ? `${DateTime.fromISO(this.state.originalTask.cateringClearingTime).toFormat('HH:mm')}` : '',
                change: this.state.task ? `${DateTime.fromISO(this.state.task.cateringClearingTime).toFormat('HH:mm')}` : '',
            },
            {
                details: this.labels.HubLabelAttendees,
                original: this.state.originalTask ? this.state.originalTask.cateringAttendees?.toString() : '',
                change: this.state.task ? this.state.task.cateringAttendees?.toString() : ''
            },
            {
                details: this.labels.HubLabelCodes,
                original: this.state.originalTask ? this.state.originalTask.costCodeAllocation?.map(x => x.costCode).toString().replaceAll(',', ', ') : '',
                change: this.state.task ? this.state.task.costCodeAllocation?.map(x => x.costCode).toString().replaceAll(',', ', ') : '',
            },
        ];

        const selectedServiceTimeError = this.state.task ? (DateTime.fromISO(this.state.task.cateringServiceTime).toJSDate() <  DateTime.fromISO(this.state.bookingStart).toJSDate()) || (DateTime.fromISO(this.state.task.cateringServiceTime).toJSDate() >  DateTime.fromISO(this.state.bookingEnd).toJSDate()) : true;
        const selectedCleanUpTimeError = this.state.task ? (DateTime.fromISO(this.state.task.cateringClearingTime).toJSDate() <  DateTime.fromISO(this.state.bookingStart).toJSDate()) || (DateTime.fromISO(this.state.task.cateringClearingTime).toJSDate() >  DateTime.fromISO(this.state.bookingEnd).toJSDate()) : true;
        const serviceTimeGreaterThanClearUpTime = this.state.task ? DateTime.fromISO(this.state.task.cateringServiceTime).toJSDate() > DateTime.fromISO(this.state.task.cateringClearingTime).toJSDate() : true;
        const newTask = !match.params.spacecateringId;

        return (
            <>
                {
                    this.state.loading ?
                        <Spinner />
                        :
                        <div className="rightPanel-main-content-form">
                                <div className="m-3">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h5" gutterBottom>{`${this.labels.HubLabelCateringTask}${newTask ? '' : `- ${this.state.task?.taskId}`}`}</Typography>
                                        <Box component="div">
                                            {
                                                newTask ?
                                                <IbssButton color="secondary" variant="contained" onClick={() => history.push('/flex-my-bookings/' + this.buildingId + '/mybooking/' + match.params.bookingid + '/' + match.params.spaceId)}>{this.labels.HubLabelBackToBooking}</IbssButton>
                                                :
                                                <>
                                                    <IbssButton color="secondary" variant="contained" className="m-2" onClick={() => this.cancelChanges()}>{this.labels.HubLabelCancelChanges}</IbssButton>
                                                    <IbssButton disabled={selectedServiceTimeError || selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime} variant="contained" className="m-2" onClick={() => this.setState({ showConfirmationModal: true })}>{this.labels.HubLabelSaveChanges}</IbssButton>
                                                </>
                                            }
                                        </Box>
                                    </div>
                                    <div style={{ float: 'right', marginTop: '-45px' }}>
                                        <Typography variant="h5" gutterBottom style={{ fontSize: '14px' }} className='text-danger'>{selectedServiceTimeError || selectedCleanUpTimeError ? this.labels.HubLabelCateringDetailsErrorMsg : serviceTimeGreaterThanClearUpTime ? this.labels.HubLabelServiceTimeGreaterThanCleanUpTimeMsg : ''}</Typography>
                                    </div>
                                    <Box sx={{my:2}}><Typography variant="body2" gutterBottom>{this.labels.HubLabelCateringDetailsDescription}</Typography></Box>
                                    <Grid container spacing={2}>
                                        <Grid item md={7} xl={8}>
                                            <Card>
                                                {this.state.orderItems?.map(item =>
                                                {
                                                    return (
                                                        <Box component="div" key={item.menuItemId} sx={{mb:1,p:3}}>
                                                            <Grid container>
                                                                <Grid item xs={2} md={3} lg={2} sx={{ display:'flex',justifyContent:'center', padding: item.imageUri.length > 0 ? '' : '40px 0px' }}>
                                                                    {item.imageUri.length > 0 &&
                                                                        <img
                                                                            width={100}
                                                                            height={100}
                                                                            src={item.imageUri}
                                                                            alt={item.menuItemTitle}
                                                                        />
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={6} md={5} lg={6} sx={{ alignSelf: 'center' }}>
                                                                    <Box sx={{ml:2}}>
                                                                        <Typography variant="h6" gutterBottom>{item.menuItemTitle}</Typography>
                                                                        <Typography variant="body2" gutterBottom>{item.menuItemDescription}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={1} sx={{ alignSelf: 'center' }}><Typography sx={{mb:0}} variant="body2" gutterBottom>{this.state.currency}{item.menuItemPrice.toFixed(2)}</Typography></Grid>
                                                                <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center', fontSize: '23px' }}>
                                                                    <IbssIconButton
                                                                        className="mr-1"
                                                                        aria-label="close"
                                                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'remove')}
                                                                    >
                                                                        <RemoveCircleOutlineIcon />
                                                                    </IbssIconButton>
                                                                    {item.cateringOrderItemQuantity}
                                                                    <IbssIconButton
                                                                        className="ml-1"
                                                                        aria-label="close"
                                                                        onClick={() => this.itemQuantityChanged(item.menuItemId, 'add')}
                                                                    >
                                                                        <AddCircleOutlineIcon />
                                                                    </IbssIconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    )
                                                })}
                                            </Card>
                                        </Grid>
                                        <Grid item md={5} xl={4}>
                                            <Card className="ml-2">
                                                <Box sx={{p:4}}>
                                                    <Typography variant="h5" gutterBottom>{this.labels.HublabelSummary}</Typography>
                                                    <Card style={{ backgroundColor: this.state.lightModeTheme ? customTheme.lightTheme.uiBackGround : customTheme.darkTheme.uiBackGround }}>
                                                        <Box component="div" sx={{ m:2 }}>
                                                        <Typography variant="overline" fontSize={14} display="block" gutterBottom style={{ color: this.state.lightModeTheme ? customTheme.lightTheme.uiTextAlternate : customTheme.darkTheme.uiTextAlternate }}>{this.state.bookingName}</Typography>
                                                            <Typography sx={{fontSize:24,fontWeight:500}} gutterBottom>{this.getSpaceNameById(this.state.task?.spaceId)}</Typography>
                                                            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
                                                                <Box display="flex" gridColumn="span 1" gap={1}>
                                                                        <IbssSvgIcon fontSize="small">{Icons.calenderIcon}</IbssSvgIcon>
                                                                    {moment(this.state.task?.cateringServiceTime).format('DD/MM/yyyy')}
                                                                </Box>
                                                                <Box display="flex" gridColumn="span 1" gap={1}>
                                                                        <IbssSvgIcon fontSize="medium">{Icons.TimeIcon}</IbssSvgIcon>
                                                                    {`${DateTime.fromISO(this.state.bookingStart).toFormat('HH:mm')} - ${DateTime.fromISO(this.state.bookingEnd).toFormat('HH:mm')}`}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                    <Box component="div" sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-around'}}>
                                                        <Box style={{ alignSelf: 'center' }}>
                                                            {this.labels.HubLabelService}
                                                            <div style={{ fontSize: '20px' }}>
                                                                <IbssTimePicker
                                                                    className='ibss-timepicker'
                                                                    value={DateTime.fromISO(this.state.task.cateringServiceTime).toJSDate()}
                                                                    onChange={e => this.handleChangeCateringServiceTime(e)}
                                                                    ampm={false}
                                                                    minutesStep={1}
                                                                    renderInput={(params) =>
                                                                    {
                                                                        const { sx, ...paramsMinusSx } = params
                                                                        return <TextField fullWidth {...paramsMinusSx} error={selectedServiceTimeError || serviceTimeGreaterThanClearUpTime} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                        />
                                                                    }}
                                                                />
                                                            </div>
                                                        </Box>
                                                        <div
                                                            className="icon-text-inline mt-3"
                                                            style={{
                                                                padding: "10px 22px",
                                                            }}>
                                                            <img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                                        </div>
                                                        <div style={{ alignSelf: 'center' }}>
                                                            <div>
                                                                {this.labels.HubLabelCleanUp}
                                                            </div>
                                                            <div style={{ fontSize: '23px' }}>
                                                                <IbssTimePicker
                                                                    className='ibss-timepicker'
                                                                    value={DateTime.fromISO(this.state.task.cateringClearingTime).toJSDate()}
                                                                    onChange={e => this.handleChangeCateringClearingTime(e)}
                                                                    ampm={false}
                                                                    minutesStep={1}
                                                                    renderInput={(params) =>
                                                                    {
                                                                        const { sx, ...paramsMinusSx } = params
                                                                        return <TextField fullWidth {...paramsMinusSx} error={selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                                        />
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Box>
                                                    <div className="d-flex">
                                                        <IbssSvgIcon fontSize="small" className="mr-1">{Icons.TimerIcon}</IbssSvgIcon>
                                                        <Typography gutterBottom>
                                                            {`${this.labels.HubLabelCutOffTime}: ${cutOffTimeDays} ${this.labels.HubLabelDays}, ${cutOffTimeHours} ${this.labels.HubLabelHours}, ${cutOffTimeMinutes} ${this.labels.HubLabelMinutes}`}
                                                        </Typography>
                                                    </div>
                                                    <div>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        multiline
                                                        color="secondary"
                                                        placeholder={this.labels.HubLabelAddNotes}
                                                        value={this.state.task?.cateringNotes}
                                                        rows={4}
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                        fullWidth
                                                        onChange={e => this.state.task ? this.setState({ task: new TaskView({ ...this.state.task, cateringNotes: e.target.value }) }) : {}}
                                                        />
                                                    </div>
                                                    <Box className="mt-1" sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                                        <Typography variant="overline" fontSize={14} display="block" gutterBottom>{`${this.state.orderItems?.map(x => parseInt(x.cateringOrderItemQuantity)).reduce((x, y) => { return x + y }, 0)} ${this.labels.HubLabelItems}`}</Typography>
                                                        <Typography variant="h3" display="block" gutterBottom>{`${this.state.currency}${this.state.orderItems?.map(x => x.menuItemPrice * parseInt(x.cateringOrderItemQuantity)).reduce((x, y) => { return x + y }, 0).toFixed(2)}`}</Typography>
                                                    </Box>
                                                    {/* -------------------------Start of Cost codes Button---------------------------*/}
                                                    {(this.localStorage.hasRight("API.Catering.AssignCostCode")) && ( 
                                                        <>
                                                            <div className={`row attendes cp`} style={{height: '60px'}} onClick={() => this.handleCostCodeModal()}>
                                                                <div className="icon-text-inline pl-0">
                                                                    <span className="space-icon-item">
                                                                        <SvgIcon component={CostCodeIcon} inheritViewBox sx={{ height: '18px', width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }} />
                                                                    </span>
                                                                    <span className="space-text-item col-text">{this.labels.HubLabelCostCode}</span> {/* 'Cost Code'*/}

                                                                </div>
                                                                <div className="d-flex">
                                                                    <span className="space-text-item mr-3">{this.getCostCodeLabel()}</span>
                                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.state.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                                </div>
                                                            </div>
                                                            <Divider />
                                                        </>
                                                    )}
                                                    {/* -------------------------End of Cost codes Button---------------------------*/}
                                                    {
                                                        newTask &&
                                                            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
                                                                <IbssButton disabled={selectedServiceTimeError || selectedCleanUpTimeError || serviceTimeGreaterThanClearUpTime} variant="contained" className="m-2" onClick={() => this.createTask()}>{this.labels.HubLabelConfirmOrder}</IbssButton>
                                                            </Box>
                                                    }
                                                </Box>
                                            </Card>
                                        </Grid>
                                        <Modal show={this.state.showConfirmationModal} onHide={() => this.setState({ showConfirmationModal: false })}>
                                            <Modal.Header>
                                                <Modal.Title>{this.labels.HubLabelTaskId}: {this.state.task?.taskId}</Modal.Title>
                                                <button type="button" className="close" onClick={() => this.setState({ showConfirmationModal: false })} aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </Modal.Header>
                                            <div className='m-4'>
                                                {this.labels.HubLabelEditCateringTaskModalSubtext}
                                            </div>
                                            {/* Task details */}
                                            <div className="container">
                                                <div className="row" style={{ paddingBottom: '10px', fontSize: '18px' }}>
                                                    <div className="col-4">
                                                        {this.labels.HubLabelDetailslabel}
                                                    </div>
                                                    <div className="col-4">
                                                        {this.labels.HubLabelOriginal}
                                                    </div>
                                                    <div className="col-4">
                                                        {this.labels.HublabelChange}
                                                    </div>
                                                </div>
                                                {gridTaskDetailValues.map(x =>
                                                {
                                                    return (
                                                        <div key={x.details} className="row" style={{ paddingBottom: '10px' }}>
                                                            <div className="col-4">
                                                                {x.details}
                                                            </div>
                                                            <div className="col-4">
                                                                {x.original}
                                                            </div>
                                                            <div className="col-4">
                                                                {x.change}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {/* Order details */}
                                            <div className="container">
                                                <div className="row" style={{ paddingBottom: '10px' }}>
                                                    <div className="col-3">
                                                        {this.labels.HubLabelSection}
                                                    </div>
                                                    <div className="col-3">
                                                        {this.labels.HubLabelItem}
                                                    </div>
                                                    <div className="col-3">
                                                        {this.labels.HubLabelDescription}
                                                    </div>
                                                    <div className="col-2">
                                                        {this.labels.HubLabelQty}
                                                    </div>
                                                    <div className="col-1">
                                                        {this.labels.HubLabelCost}
                                                    </div>
                                                </div>
                                                {this.state.orderItems.map(x =>
                                                {
                                                    return (
                                                        <div key={x.menuItemId} className="row" style={{ paddingBottom: '10px' }}>
                                                            <div className="col-3">
                                                                {x.menuItemSection}
                                                            </div>
                                                            <div className="col-3">
                                                                {x.menuItemTitle}
                                                            </div>
                                                            <div className="col-3">
                                                                {x.menuItemDescription}
                                                            </div>
                                                            <div className="col-2">
                                                                {x.cateringOrderItemQuantity}
                                                            </div>
                                                            <div className="col-1">
                                                                {this.state.currency}{(x.menuItemPrice * parseInt(x.cateringOrderItemQuantity)).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <Modal.Footer>
                                                <div style={{ textAlign: 'center' }}>
                                                    <IbssButton
                                                        style={{ height: '45px', minWidth: '100px' }}
                                                        color='error'
                                                        variant="contained"
                                                        onClick={() => this.updateTask()}
                                                    >
                                                        {this.labels.HubButtonSave}
                                                    </IbssButton>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>
                                        
                                        {/* -----------------------------Start Cost codes Popup---------------------------*/}
                                        <CostCodeModal
                                            bookingId={this.state.originalTask.bookingId}
                                            bookingCostCodes={this.state.costCodes}
                                            show={this.state.showCostCodeModal}
                                            onClose={()=> this.handleCostCodeModal()}
                                            updateBookingCostCodes={(updatedCostCodes)=> this.updateBookingCostCodes(updatedCostCodes)}
                                        />

                                        {/* -----------------------------End Cost codes Popup---------------------------*/}
                                    </Grid>
                                </div>
                        </div>
                }
            </>
        );
    }
}

export interface IProps extends RouteComponentProps<IQueryParams>, IPropsFromState
{
}

export interface IQueryParams
{
    buildingid: string;
    spacecateringId: string;
    spaceId: string;
    bookingid: string;
}

export interface IState
{
    task: TaskView;
    originalTask: TaskView;
    orderItems: CateringOrderView[];
    loading: boolean;
    currency: string;
    preBookCateringTime: string;
    bookingStart: string;
    bookingEnd: string;
    showConfirmationModal: boolean;
    floorId: number;
    numOfAttendees: number;
    bookingName: string;
    spaces: Space[];
    showCostCodeModal: boolean;
    lightModeTheme: boolean;
    costCodes: CostCodeWithAllocation[];
}

export class TaskView
{
    public nodeId = 0;
    public bookingId = "";
    public taskId = 0;
    public taskTypeName = "";
    public createdAt = "";
    public taskCategoryName = "";
    public spaceId = "";
    public taskPriority = 0;
    public taskDueDate = "";
    public taskOwner = "";
    public taskEventTime = "";
    public taskCreatedBy = "";
    public taskStatus = "";
    public taskIsActive = 0;
    public taskIsOwned = 0;
    public cateringServiceTime = "";
    public cateringClearingTime = "";
    public cateringAttendees = 0;
    public cateringNotes = "";
    public costCodeAllocation = new Array<ICostAllocation>();
    public taskTTC = 0;

    constructor(value?: Partial<TaskView>)
    {
        if (value == null)
        {
            return;
        }
        Object.assign(this, value);
    }
    
    public static fromApiTask(data: CateringTask): TaskView
    {
        return new TaskView(
        {
            nodeId: data.Node_Id,
            bookingId: data.Booking_Id,
            taskId: data.Task_Id,
            taskTypeName: data.Task_Type_Name,
            createdAt: data.CreatedAt,
            taskCategoryName: data.Task_Category_Name,
            spaceId: data.Space_Id,
            taskPriority: data.Task_Priority,
            taskDueDate: data.Task_Due_Date,
            taskOwner: data.Task_Owner,
            taskEventTime: data.Task_Event_Time,
            taskCreatedBy: data.Task_CreatedBy,
            taskStatus: data.Task_Status,
            taskIsActive: data.Task_IsActive,
            taskIsOwned: data.Task_IsOwned,
            cateringServiceTime: data.Catering_Service_Time,
            cateringClearingTime: data.Catering_Clearing_Time,
            cateringAttendees: data.Catering_Attendees,
            cateringNotes: data.Catering_Notes,
            costCodeAllocation: data.Cost_Code_Allocation.map(x => {return{costCode: x.Cost_Code, costCodeId: x.Cost_Code_Id, allocation: x.Allocation}}),
            taskTTC: data.Task_TTC,
        });
    }

    public toApiTask(): CateringTask
    {
        return {
            Node_Id: this.nodeId,
            Booking_Id: this.bookingId,
            Task_Id: this.taskId,
            Task_Type_Name: this.taskTypeName,
            CreatedAt: this.createdAt,
            Task_Category_Name: this.taskCategoryName,
            Space_Id: this.spaceId,
            Task_Priority: this.taskPriority,
            Task_Due_Date: this.taskDueDate,
            Task_Owner: this.taskOwner,
            Task_Event_Time: this.taskEventTime,
            Task_CreatedBy: this.taskCreatedBy,
            Task_Status: this.taskStatus,
            Task_IsActive: this.taskIsActive,
            Task_IsOwned: this.taskIsOwned,
            Catering_Service_Time: this.cateringServiceTime,
            Catering_Clearing_Time: this.cateringClearingTime,
            Catering_Attendees: this.cateringAttendees,
            Catering_Notes: this.cateringNotes,
            Cost_Code_Allocation: this.costCodeAllocation.map(x => {return{Cost_Code: x.costCode, Cost_Code_Id: x.costCodeId, Allocation: x.allocation}}),
            Task_TTC: this.taskTTC
        };
    }

    public toApiCreateTask(spaceId: string, bookingId: string, bookingStart: string, numOfAttendees: number, orderItems: string[], costCodeAllocations: ICostCodeAllocation[]): ICreateTask
    {
        let taskDescription = '';
        for (let x = 0; x < orderItems.length; x++)
        {
            taskDescription = taskDescription + orderItems[x];
        }

        const payload: ICreateTask =
        {
            Space_Id: spaceId,
            Task_Category_Id: 0,
            Task_Type_Id: 0,
            Task_Description: taskDescription,
            Task_Priority: 0,
            Equipment_Id: "",
            Booking_Id: bookingId,
            Task_Cost_Code: "",
            Booking_Start: bookingStart,
            Is_Catering_Request: true,
            Catering_Service_Time: this.cateringServiceTime,
            Catering_Clearing_Time: this.cateringClearingTime,
            Catering_Attendees: numOfAttendees,
            Is_Catering_Clear: true,
            Catering_Notes: this.cateringNotes,
            DisableExtUpdate: false,
            Cost_Code_Allocation: costCodeAllocations,
          };

        return payload;
    }
}

export class CateringOrderView
{
    public imageUri = "";
    public menuItemId = 0;
    public menuItemTitle = "";
    public menuItemDescription = "";
    public menuItemSection = "";
    public menuItemPrice = 0;
    public nodeId = 0;
    public cateringOrderId = 0;
    public spaceId = "";
    public cateringOrderItemId = "";
    public cateringOrderItemQuantity = "";

    constructor(value?: Partial<CateringOrderView>)
    {
        if (value == null)
        {
            return;
        }
        Object.assign(this, value);
    }
    
    public static fromApiCateringOrder(data: ICateringOrder) : CateringOrderView
    {
        return new CateringOrderView(
        {
            imageUri: data.ImageURI,
            menuItemId: data.Menu_Item_Id,
            menuItemTitle: data.Menu_Item_Title,
            menuItemDescription: data.Menu_Item_Description,
            menuItemSection: data.Menu_Item_Section,
            menuItemPrice: data.Menu_Item_Price,
            nodeId: data.Node_Id,
            cateringOrderId: data.Catering_Order_Id,
            spaceId: data.Space_Id,
            cateringOrderItemId: data.Catering_Order_Item_Id,
            cateringOrderItemQuantity: data.Catering_Order_Item_Quantity,
        });
    }

    public toApiModel(): ICateringOrder
    {
        return {
            ImageURI: this.imageUri,
            Menu_Item_Id: this.menuItemId,
            Menu_Item_Title: this.menuItemTitle,
            Menu_Item_Description: this.menuItemDescription,
            Menu_Item_Section: this.menuItemSection,
            Menu_Item_Price: this.menuItemPrice,
            Node_Id: this.nodeId,
            Catering_Order_Id: this.cateringOrderId,
            Space_Id: this.spaceId,
            Catering_Order_Item_Id: this.cateringOrderItemId,
            Catering_Order_Item_Quantity: this.cateringOrderItemQuantity,
        };
    }
}

export interface ICostAllocation
{
    costCode: string;
    costCodeId: string;
    allocation: number;
}

export interface IGridTaskDetailValues
{
    details: string;
    original: string;
    change: string;
}
