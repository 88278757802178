import IResponse from "../IResponse";
import { IDeleteEndpoint } from "./DeleteEndpoint";
import { ISpaceCateringMenuItem, IGetManyEndpoint } from "./GetManyEndpoint";
import { IUpdateSpaceCateringMenuItemPayload, IUpdateEndpoint } from "./UpdateEndpoint";
import { ICreateSpaceCateringMenuItemPayload, ICreateEndpoint } from "./CreateEndpoint";

export class CateringItemRepository implements ICateringItemRepository
{
    private getManyEndpointEndpoint: IGetManyEndpoint;
    private createEndpoint: ICreateEndpoint;
    private updateEndpoint: IUpdateEndpoint;
    private deleteEndpoint: IDeleteEndpoint;

    constructor(
        getManyEndpointEndpoint: IGetManyEndpoint,
        createEndpoint: ICreateEndpoint,
        updateEndpoint: IUpdateEndpoint,
        deleteEndpoint: IDeleteEndpoint,
    )
    {
        this.getManyEndpointEndpoint = getManyEndpointEndpoint;
        this.createEndpoint =createEndpoint;
        this.updateEndpoint = updateEndpoint;
        this.deleteEndpoint = deleteEndpoint;
    }

    public getMany(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>
    {
        return this.getManyEndpointEndpoint.execute(buildingId, filter);
    }

    public create(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<void>
    {
        return this.createEndpoint.execute(buildingId, payload);
    }

    public update(buildingId: number, spaceCateringId: number, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>
    {
        return this.updateEndpoint.execute(buildingId, spaceCateringId, payload);
    }
    
    public delete(buildingId: number, spaceCateringId: number): Promise<void>
    {
        return this.deleteEndpoint.execute(buildingId, spaceCateringId);
    }
}

export interface ICateringItemRepository
{
    getMany(buildingId: number, cateringFilter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>;
    create(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<void>;
    update(buildingId: number, spaceCateringId :number, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>;
    delete(buildingId: number, spaceCateringId :number): Promise<void>;
}

export class CateringItemsFilter
{
    public section: (string | null) = null;
    public isEnabled: (boolean | null) = null;
    public isPreBookable: (boolean | null) = null;
    public isRoomService: (boolean | null) = null;

    constructor(value: Partial<CateringItemsFilter>)
    {
        Object.assign(this, value);
    }

    public toODataString(): string
    {
        let filterParts = new Array<string | null>();
        filterParts.push(this.section == null ? null : `Space_Catering_Section eq '${this.section }'`);
        filterParts.push(this.isEnabled == null ? null : `Space_Catering_IsEnabled eq ${this.isEnabled ? 1 : 0}`);
        filterParts.push(this.isPreBookable == null ? null : `Space_Catering_IsPreBookable eq ${this.isPreBookable ? 1 : 0}`);
        filterParts.push(this.isRoomService == null ? null : `Space_Catering_IsRoomService eq ${this.isRoomService ? 1 : 0}`);
        return filterParts.filter(i => i != null).join(" and ");
    }
}
