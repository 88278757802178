import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { appContext } from "../AppContext";
import AlertModal from "./AlertModal";
import Header, { IProps as IHeaderProps } from "./Header";
import Sidebar from "./Sidebar/Sidebar";

class Layout extends Component<IProps, IState>
{
    private appContext = appContext();
    private alert = appContext().alert;

    constructor(props: IProps)
    {
        super(props);

        this.appContext.router =
        {
            history: this.props.history,
            location: this.props.location,
            match: this.props.match,
            staticContext: this.props.staticContext,
        };

        this.state =
        {
            isPrivate: props.isPrivate,
            showAlert: false,
            alertTitle: "",
            alertMessage: "",
            alertMessageIsHtml: false,
        };

    }

    public componentDidMount(): void
    {
        this.alert.changed = (show, title, message, messageIsHtml) => this.alertChanged(show, title, message, messageIsHtml);
    }

    public componentWillUnmount(): void
    {
        this.alert.changed = undefined;
    }

    private alertChanged(show: boolean, title: string, message: string, messageIsHtml: boolean): Promise<void>
    {
        return new Promise((resolve, reject) =>
        {
            this.setState(
                {
                    showAlert: show,
                    alertTitle: title,
                    alertMessage: message,
                    alertMessageIsHtml: messageIsHtml,
                },
                () => resolve());
        });
    }

    private hideAlert(): void
    {
        this.alert.hide();
    }

    public render(): JSX.Element
    {
        return (
            <>
                <AlertModal show={this.state.showAlert} modalTitle={this.state.alertTitle} modalMessage={this.state.alertMessage} messageIsHtml={this.state.alertMessageIsHtml} handleModal={() => this.hideAlert()} />
                { this.state.isPrivate &&
                    <div className="page-container" style={{overflow:"hidden",display:"flex",backgroundColor:"var(--ui-background)"}}>
                        <Sidebar {...this.props} />
                        <div className="rightPanel" style={{ position: "relative", display: "flex", flexFlow: "column" }}>
                            <Header {...this.props as unknown as IHeaderProps} />
                            {this.props.children}
                        </div>
                    </div>
                }
                { !this.state.isPrivate && this.props.children }
            </>);
    }
}

export default withRouter(Layout);

interface IProps extends RouteComponentProps
{   
    isPrivate: boolean;
}

interface IState
{
    isPrivate: boolean;
    showAlert: boolean;
    alertTitle: string;
    alertMessage: string;
    alertMessageIsHtml: boolean;
}
