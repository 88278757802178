import { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NotificationListProps } from '../../../Components/uicomponents/models/FlexNotificationProps';
import { appContext } from '../../../AppContext';
import React from 'react';
import IbssButtonRedo from '../../../Components/uicomponents/IbssButton';
import apis from '../../../Providers.Api/apis';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import { Icons } from '../../../Common/AllsvgIcons';
import { Grid } from '@mui/material';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import moment from 'moment';
import { getBuildingIdUsingFloorNodeId } from '../../../Common/Helper';
import { DateTime } from 'luxon';
import { Space } from '../../../Providers.Api/Spaces/SpaceRepository';
import { Equipment } from './SearchAvailableEquipment';
import { AxiosResponse } from 'axios';
import { IEquipmentDetailsResponse, IUpdateEquipmentRequest, IUpdateEquipmentResponse } from '../../../Providers.Api/Models';

class EquipmentDetails extends React.Component<any, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get apiCache() {return appContext().apiCache; }

    constructor(props: any)
    {
        super(props);

        this.state = {
            Space_Id: "",
            Booking_Start: "",
            Booking_End: "",
            Booking_Date: "",
            isLoadingToSave: false,
            isLoading: false,
            bookingParties: "",
            bookingName: '',
            spaces:[],
            equipments: []
        }

    }
    public async componentDidMount(): Promise<void>
    {
        const { location } = this.props;
        let BookingDate = location.state?.bookingResponse?.Booking_Start?.split("T");
        let Bkd = BookingDate[1].split(":");
        let BookingEnd = location.state?.bookingResponse?.Booking_End?.split("T")
        let Bke = BookingEnd[1].split(":")
        const buildingId = this.props.match.params.buildingid;
        const space = await this.apiCache.getSpacesByBuilding(buildingId);

        const updatedEquipment: IUpdateEquipmentRequest =
        {
            EquipmentType: this.props.location.state.equipment_Type,
            StartDate: this.props.location.state.bookingResponse.Booking_Start,
            EndDate: this.props.location.state.bookingResponse.Booking_End,
        };
        
        // get available equipment
        const updateResponse: AxiosResponse<IUpdateEquipmentResponse[]> = await apis.updateEquipment(buildingId, updatedEquipment);
        let equipments = updateResponse.data.map(i => Equipment.fromUpdateEquipmentResponse(i, buildingId));

        // get booked equipment
        if (this.props.bookedEquipment != null)
        {
            for (let i = 0; i < this.props.bookedEquipment.length; i++)
            {
                const bookedEquipment: AxiosResponse<IEquipmentDetailsResponse> = await apis.getEquipmentDetails(1, this.props.bookedEquipment[i].Booking_Resource_Id);
                const bookedEquipmentView = Equipment.fromEquipmentDetailsResponse(bookedEquipment.data, buildingId);
                equipments.push(bookedEquipmentView);
            }
        }
        this.setState({
            Space_Id: location.state.bookingResponse.Space_Id,
            Booking_Date: BookingDate[0],
            Booking_Start: Bkd[0] + ":" + Bkd[1],
            Booking_End: Bke[0] + ":" + Bke[1],
            bookingName: location.state?.bookingResponse?.Booking_Name,
            spaces:space,
            equipments: equipments.filter(x => x.equipmentType == this.props.location.state.equipment_Type)
        });
    }

    public async handleSubmitAddToBooking(): Promise<void>
    {
        const { location } = this.props;

        this.setState({ isLoadingToSave: true, isLoading: true })
        const bookedEquipmentResourceIds = this.props.bookedEquipment.map((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id)

        for (const id of this.props.selectedEquipmentIds)
        {
            if (!bookedEquipmentResourceIds.includes(id))
            {
                await apis.updateBookingParties(
                    location.state.bookingResponse.Node_Id,
                    {
                        "Booking_Id": location.state.bookingResponse.Booking_Id,
                        "BookingStart": location.state.bookingResponse.Booking_Start,
                        "BookingEnd": location.state.bookingResponse.Booking_End,
                        "Booking_Resource_Id": id,
                        "Booking_Participant_Type": 3
                    }
                )
            }
        }

        for (const equipment of this.state.equipments)
        {
            const bookedItemIndex = this.props.bookedEquipment.findIndex((x: { Booking_Resource_Id: string; }) => x.Booking_Resource_Id == equipment.equipmentId)
            if (bookedItemIndex >= 0 && !this.props.selectedEquipmentIds.includes(this.props.bookedEquipment[bookedItemIndex]?.Booking_Resource_Id))
            {
                await this.apiClient.bookingParties.delete(location.state.bookingResponse.Node_Id, this.props.bookedEquipment[bookedItemIndex].Record_Id)
            }
        }

        this.props.history.push('/flex-my-bookings/' + getBuildingIdUsingFloorNodeId(location.state.bookingResponse.Node_Id) + '/mybooking/' + location.state.bookingResponse.Booking_Id + '/' + location.state.bookingResponse.Space_Id);
    }

    private spaceNamesById: (Map<string, string> |  null) = null;
    private getSpaceNameById(spaceId: string): string
    {
        if (this.spaceNamesById == null && spaceId != "" )
        {
            this.spaceNamesById = new Map(this.state.spaces.map(i => [ i.Space_Id, i.Space_Name ]));
        }
        return this.spaceNamesById?.get(spaceId) ?? "";
    }

    public render(): JSX.Element
    {
        const { location } = this.props;
        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <Box className="right-pannel-sm">
                    <Typography className="summary-card-heading"> {this.labels.HublabelSummary} </Typography>
                    <div>
                        <div className='summery-card mb-10'>
                            <Typography className="font-14 card-userPref-text">
                                {this.state.bookingName}
                            </Typography>
                            <div className="summary-card-heading-lg my-6">
                                {this.getSpaceNameById(this.state.Space_Id)}
                            </div>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <div className='d-flex'>
                                        <IbssSvgIcon className='mr-10'>
                                            {Icons.calenderIcon}
                                        </IbssSvgIcon>
                                        {DateTime.fromISO(this.state?.Booking_Date).toFormat("dd/MM/yyyy") == 'Invalid DateTime' ? '' : DateTime.fromISO(this.state?.Booking_Date).toFormat("dd/MM/yyyy")}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <div className='d-flex ml-10'>
                                            <IbssSvgIcon className='mr-10'>{Icons.TimeIcon}</IbssSvgIcon>
                                            <span className='mr-10'>
                                                {`${("0" + moment(this.props.location.state?.bookingResponse?.Booking_Start).hours()).slice(-2)}:${("0" + moment(this.props.location.state?.bookingResponse?.Booking_Start).minutes()).slice(-2)} - 
                        ${("0" + moment(this.props.location.state?.bookingResponse?.Booking_End).hours()).slice(-2)}:${("0" + moment(this.props.location.state?.bookingResponse?.Booking_End).minutes()).slice(-2)}`}
                                            </span>
                                        </div>
                                        <Typography className="card-userPref-text" variant='h6'>
                                            <div>
                                            </div>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ textAlign: "center" }}>
                            {this.props.selectedEquipmentIds.filter((x: string) => this.state.equipments.map(x => x.equipmentId).includes(x)).length} {this.labels.HubLabelItemsSelected}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-20">
                        <IbssButtonRedo
                            variant='contained' onClick={() => this.handleSubmitAddToBooking()}
                        >
                            {this.labels.HubButtonUpdateBooking}
                        </IbssButtonRedo>
                    </div>
                </Box>
            </>
        )
    }
}

export default EquipmentDetails;

export interface IState
{
    isLoadingToSave: boolean;
    isLoading: boolean;
    Space_Id: string;
    Booking_End: string;
    Booking_Start: string;
    Booking_Date: string;
    bookingParties: string;
    bookingName: string;
    spaces: Space[];
    equipments: Equipment[];
}