import { IApiCache, ApiCache } from "./Providers.Api/ApiCache";
import { ApiClient, IApiClient } from "./Providers.Api/ApiClient";
import Authentication from "./Providers.Api/Authentication";
import ApiMessages from "./Providers.Text/ApiMessages";
import Labels from "./Providers.Text/Labels";
import { TextProvider, ITextProvider } from "./Providers.Text/TextProvider";
import { ICache, Cache } from "./Common/Cache";
import { AlertModalController } from "./Components/AlertModal";
import { IMsalProvider } from "./Providers.Msal/IMsalProvider";
import { MsalProvider } from "./Providers.Msal/MsalProvider";
import { RouteComponentProps } from "react-router-dom";
import { ConfigHelper } from "./Common/ConfigHelper";
import { ISessionStorageProvider, SessionStorageProvider } from "./Providers.SessionStorage/SessionStorageProvider";
import { ILocalStorageProvider, LocalStorageProvider } from "./Providers.LocalStorage/LocalStorageProvider";
import { IUserPreferencesService, UserPreferencesService } from "./Services/UserPreferencesService";
import { Event } from "./Common/Event";
import { Component } from "react";
import { BookingService, IBookingService } from "./Services/BookingService";
import { ISpaceService, SpaceService } from "./Services/SpaceService";
import { GetV2BookingEndpoint } from "./Providers.Api/Bookings/GetV2BookingEndpoint";
import { BookingPolicyRepository } from "./Providers.Api/BookingPolicies/BookingPolicyRepository";
import { SpaceRepository } from "./Providers.Api/Spaces/SpaceRepository";
import { BookingRepository } from "./Providers.Api/Bookings/BookingRepository";
import { UserPreferenceRepository } from "./Providers.Api/UserPreferenceRepository";
import { SpacesDailySummaryRepository } from "./Providers.Api/SpacesDailySummaryRepository";
import { RoleRepository } from "./Providers.Api/RoleRepository";
import { TaskRepository } from "./Providers.Api/Tasks/TaskRepository";
import { CostCodeRepository } from "./Providers.Api/CostCodes/CostCodeRepository";
import { ParameterRepository } from "./Providers.Api/Parameters/ParameterRepository";
import { BookingPartyRepository } from "./Providers.Api/BookingPartyRepository";
import { GetV1BookingEndpoint } from "./Providers.Api/Bookings/GetV1BookingEndpoint";
import { GetBookingsEndpoint } from "./Providers.Api/Bookings/GetBookingsEndpoint";
import { GetV2BookingsEndpoint } from "./Providers.Api/Bookings/GetV2BookingsEndpoint";
import { GetV1BookingsByEmailEndpoint } from "./Providers.Api/Bookings/GetV1BookingsByEmailEndpoint";
import { GetMyV2BookingsEndpoint } from "./Providers.Api/Bookings/GetMyV2BookingsEndpoint";
import { GetMyBookingsForOthersEndpoint } from "./Providers.Api/Bookings/GetMyBookingsForOthersEndpoint";
import { GetMyV2BookingsForOthersEndpoint  } from "./Providers.Api/Bookings/GetMyV2BookingsForOthersEndpoint";
import { ApproveBookingEndpoint } from "./Providers.Api/Bookings/ApproveBookingEndpoint";
import { RejectBookingEndpoint } from "./Providers.Api/Bookings/RejectBookingEndpoint";
import { CreateV1BookingEndpoint } from "./Providers.Api/Bookings/CreateV1BookingEndpoint";
import { CreateV2BookingEndpoint } from "./Providers.Api/Bookings/CreateV2BookingEndpoint";
import { DeleteV1BookingEndpoint } from "./Providers.Api/Bookings/DeleteV1BookingEndpoint";
import { DeleteV2BookingEndpoint } from "./Providers.Api/Bookings/DeleteV2BookingEndpoint";
import { UpdateBookingCostCodesEndpoint } from "./Providers.Api/Bookings/UpdateBookingCostCodesEndpoint";
import { UpdateV1BookingEndpoint } from "./Providers.Api/Bookings/UpdateV1BookingEndpoint";
import { UpdateV2BookingEndpoint } from "./Providers.Api/Bookings/UpdateV2BookingEndpoint";
import { DownloadV1BookingEndpoint } from "./Providers.Api/Bookings/DownloadV1BookingEndpoint";
import { DownloadV2BookingEndpoint } from "./Providers.Api/Bookings/DownloadV2BookingEndpoint";
import { CateringItemRepository } from "./Providers.Api/CateringItems/CateringItemRepository";
import { GetManyEndpoint as GetManySpaceCateringMenuItemsEndpoint } from "./Providers.Api/CateringItems/GetManyEndpoint";
import { UpdateEndpoint as UpdateSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/UpdateEndpoint";
import { DeleteEndpoint as DeleteSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/DeleteEndpoint";
import { CreateEndpoint as CreateSpaceCateringMenuItemEndpoint } from "./Providers.Api/CateringItems/CreateEndpoint";
import { GetNewCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/GetNewCateringOrdersEndpoint";
import { GetCateringOrdersEditEndpoint } from "./Providers.Api/CateringOrders/GetCateringOrdersEditEndpoint";
import { GetCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/GetCateringOrdersEndpoint";
import { UpdateCateringOrdersEndpoint } from "./Providers.Api/CateringOrders/UpdateCateringOrdersEndpoint";
import { CreateTaskEndpoint } from "./Providers.Api/Tasks/CreateTaskEndpoint";
import { ExportTasksEndpoint } from "./Providers.Api/Tasks/ExportTasksEndpoint";
import { GetTaskEndpoint } from "./Providers.Api/Tasks/GetTaskEndpoint";
import { GetTasksByBookingIdEndpoint } from "./Providers.Api/Tasks/GetTasksByBookingIdEndpoint";
import { GetTasksEndpoint } from "./Providers.Api/Tasks/GetTasksEndpoint";
import { SetStatusToInProgressEndpoint } from "./Providers.Api/Tasks/SetStatusToInProgressEndpoint";
import { UpdateTaskEndpoint } from "./Providers.Api/Tasks/UpdateTaskEndpoint";
import { CateringOrderRepository } from "./Providers.Api/CateringOrders/CateringOrderRepository";
import { NotificationRepository } from "./Providers.Api/Notifications/NotificationRepository";
import { GetActiveNotificationEndpoint } from "./Providers.Api/Notifications/GetActiveNotificationEndpoint";
import { ExternalIdentityProviderRepository } from "./Providers.Api/ExternalIdentityProviders/ExternalIdentityProviderRepository";
import { GetExternalIdentityProvidersEndpoint } from "./Providers.Api/ExternalIdentityProviders/GetExternalIdentityProvidersEndpoint";
import { GetIdentityProvidersEndpoint } from "./Providers.Api/ExternalIdentityProviders/GetIdentityProvidersEndpoint";
import { GetCostCodesEndpoint } from "./Providers.Api/CostCodes/GetCostCodesEndpoint";
import { GetV2CostCodesEndpoint } from "./Providers.Api/CostCodes/GetV2CostCodesEndpoint";
import { GetCostCodeByIdEndpoint } from "./Providers.Api/CostCodes/GetCostCodeByIdEndpoint";
import { GetBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/GetBookingPolicyEndpoint";
import { GetBookingPoliciesEndpoint } from "./Providers.Api/BookingPolicies/GetBookingPoliciesEndpoint";
import { CreateBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/CreateBookingPolicyEndpoint";
import { UpdateBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/UpdateBookingPolicyEndpoint";
import { DeleteBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/DeleteBookingPolicyEndpoint";
import { AssignBookingPolicyToSpacesEndpoint } from "./Providers.Api/BookingPolicies/AssignBookingPolicyToSpacesEndpoint";
import { GetParameterByNameEndpoint } from "./Providers.Api/Parameters/GetParameterByNameEndpoint";
import { GetUsersEndpoint } from "./Providers.Api/Users/GetUsersEndpoint";
import { UsersRepository } from "./Providers.Api/Users/UsersRepository";
import { SpaceUtilisationSummaryRepository } from "./Providers.Api/SpaceUtilisationSummaries/SpaceUtilisationSummaryRepository";
import { GetManyEndpoint as GetManySpaceUtilisationSummariesEndpoint } from "./Providers.Api/SpaceUtilisationSummaries/GetManyEndpoint";
import { ISpaceUtilisationSummaryService, SpaceUtilisationSummaryService } from "./Services/SpaceUtilisationSummaryService";
import { GetV1SpacesEndpoint } from "./Providers.Api/Spaces/GetV1SpacesEndpoint";
import { GetV2SpacesEndpoint } from "./Providers.Api/Spaces/GetV2SpacesEndpoint";
import { SearchV1SpaceByIdEndpoint } from "./Providers.Api/Spaces/SearchV1SpaceByIdEndpoint";
import { EnvironmentalZoneDataRepository } from "./Providers.Api/EnvironmentalZoneData/EnvironmentalZoneDataRepository";
import { GetByZoneIdsEndpoint } from "./Providers.Api/EnvironmentalZoneData/GetByZoneIdsEndpoint";
import { GetManyByPeriodEndpoint as GetManySpaceUtilisationSummariesByPeriodEndpoint } from "./Providers.Api/SpaceUtilisationSummaries/GetManyByPeriodEndpoint";
import { CheckInEndpoint as CheckIntoSpaceEndpoint } from "./Providers.Api/Spaces/CheckInEndpoint";
import { CheckOutEndpoint as CheckOutOfSpaceEndpoint } from "./Providers.Api/Spaces/CheckOutEndpoint";
import { GetRecurringEndpoint as GetRecurringSpacesEndpoint } from "./Providers.Api/Spaces/GetRecurringEndpoint";
import { GetV1SpaceByIdEndpoint } from "./Providers.Api/Spaces/GetV1SpaceByIdEndpoint";
import { IServices, Services } from "./Services/Services";
import { BookingDailySummaryRepository } from "./Providers.Api/BookingDailySummaries/BookingDailySummaryRepository"; 
import { GetManyEndpoint as GetManyBookingSummaryEndpoint } from "./Providers.Api/BookingDailySummaries/GetManyEndpoint";
import { SpaceZonesRepository } from "./Providers.Api/SpaceZones/SpaceZonesRepository";
import { GetSpaceZonesEndpoint } from "./Providers.Api/SpaceZones/GetSpaceZones";
import { GetSpaceBookingPolicyEndpoint } from "./Providers.Api/BookingPolicies/GetSpaceBookingPolicy";

let instance: (ApplicationContext | null) = null;
export function appContext()
{
    if (!instance)
    {
        instance = new ApplicationContext();
        instance.initialise();
    }
    return instance;
}

export class ApplicationContext
{
    public cache: ICache = {} as ICache;

    // providers
    public apiCache = {} as IApiCache;
    public apiClient = {} as IApiClient;
    public msalProvider = {} as IMsalProvider;
    public textProvider = {} as ITextProvider;
    public labels = {} as Labels;
    public apiMessages = {} as ApiMessages;
    public sessionStorageProvider = {} as ISessionStorageProvider;
    public localStorageProvider = {} as ILocalStorageProvider;

    // services
    public services: IServices = {} as IServices;
    public userPreferencesService: IUserPreferencesService = {} as IUserPreferencesService;
    public bookingService: IBookingService = {} as IBookingService;
    public spaceService: ISpaceService = {} as ISpaceService;
    public spaceUtilisationSummaryService: ISpaceUtilisationSummaryService = {} as ISpaceUtilisationSummaryService;

    // helpers
    public alert = {} as AlertModalController;
    public router = {} as RouteComponentProps;
    public state = {} as Readonly<AppState>;
    public config = {} as ConfigHelper;
    public authentication = {} as Authentication;

    public initialise(): void
    {
        this.alert = new AlertModalController();
        this.state = new AppState();
        this.config = new ConfigHelper();
        this.authentication = new Authentication();
    }

    public useDefaultCache(): void
    {
        this.cache = new Cache();
    }

    public useDefaultApiCache(): void
    {
        if (!this.cache)
        {
            throw "You must set the cache before using the API cache.";
        }
        this.apiCache = new ApiCache(this.cache);
    }

    public useDefaultApiClient(): void
    {
        this.apiClient = new ApiClient(
            this.authentication,
            new BookingPolicyRepository(
                new GetBookingPolicyEndpoint(),
                new GetBookingPoliciesEndpoint(),
                new CreateBookingPolicyEndpoint(),
                new UpdateBookingPolicyEndpoint(),
                new DeleteBookingPolicyEndpoint(),
                new AssignBookingPolicyToSpacesEndpoint(),
                new GetSpaceBookingPolicyEndpoint(),
            ),
            new BookingPartyRepository(),
            new SpaceRepository(
                new GetV1SpacesEndpoint(),
                new GetV2SpacesEndpoint(),
                new GetV1SpaceByIdEndpoint(),
                new SearchV1SpaceByIdEndpoint(),
                new CheckIntoSpaceEndpoint(),
                new CheckOutOfSpaceEndpoint(),
                new GetRecurringSpacesEndpoint(),
            ),
            new BookingRepository(
                new GetV2BookingEndpoint(),
                new GetV1BookingEndpoint(),
                new GetBookingsEndpoint(),
                new GetV2BookingsEndpoint(),
                new GetV1BookingsByEmailEndpoint(),
                new GetMyV2BookingsEndpoint(),
                new GetMyBookingsForOthersEndpoint(),
                new GetMyV2BookingsForOthersEndpoint(),
                new ApproveBookingEndpoint(),
                new RejectBookingEndpoint(),
                new CreateV1BookingEndpoint(),
                new CreateV2BookingEndpoint(),
                new DeleteV1BookingEndpoint(),
                new DeleteV2BookingEndpoint(),
                new UpdateBookingCostCodesEndpoint(),
                new UpdateV1BookingEndpoint(),
                new UpdateV2BookingEndpoint(),
                new DownloadV1BookingEndpoint(),
                new DownloadV2BookingEndpoint()
            ),
            new UserPreferenceRepository(),
            new SpacesDailySummaryRepository(),
            new RoleRepository(),
            new TaskRepository(
                new CreateTaskEndpoint(),
                new ExportTasksEndpoint(),
                new GetTaskEndpoint(),
                new GetTasksByBookingIdEndpoint(),
                new GetTasksEndpoint(),
                new SetStatusToInProgressEndpoint(),
                new UpdateTaskEndpoint()
            ),
            new CostCodeRepository(
                new GetCostCodesEndpoint(),
                new GetV2CostCodesEndpoint(),
                new GetCostCodeByIdEndpoint(),
            ),
            new ParameterRepository(
                new GetParameterByNameEndpoint(),
            ),
            new CateringItemRepository(
                new GetManySpaceCateringMenuItemsEndpoint(),
                new CreateSpaceCateringMenuItemEndpoint(),
                new UpdateSpaceCateringMenuItemEndpoint(),
                new DeleteSpaceCateringMenuItemEndpoint()
            ),
            new CateringOrderRepository(
                new GetNewCateringOrdersEndpoint(),
                new GetCateringOrdersEditEndpoint(),
                new GetCateringOrdersEndpoint(),
                new UpdateCateringOrdersEndpoint()
            ),
            new NotificationRepository(
                new GetActiveNotificationEndpoint()
            ),
            new ExternalIdentityProviderRepository(
                new GetExternalIdentityProvidersEndpoint(),
                new GetIdentityProvidersEndpoint(),
            ),
            new UsersRepository(
                new GetUsersEndpoint(),
            ),
            new SpaceUtilisationSummaryRepository(
                new GetManySpaceUtilisationSummariesEndpoint(),
                new GetManySpaceUtilisationSummariesByPeriodEndpoint(),
            ),
            new EnvironmentalZoneDataRepository(
                new GetByZoneIdsEndpoint(),
            ),
            new SpaceZonesRepository(
                new GetSpaceZonesEndpoint(),
            ),
            new BookingDailySummaryRepository(
                new GetManyBookingSummaryEndpoint(),
            )
        );

        this.apiClient.initialise();
    }

    public useDefaultMsalProvider(): void
    {
        this.msalProvider = new MsalProvider();
    }

    public useDefaultTextProvider(): void
    {
        this.textProvider = new TextProvider();
    }

    public async useLocalLabels(): Promise<void>
    {
        if (!this.textProvider)
        {
            throw "You must set a text provider before using the local labels.";
        }
        this.labels = await this.textProvider.getLocalLabels();
    }

    public async useLocalApiMessages(): Promise<void>
    {
        if (!this.textProvider)
        {
            throw "You must set a text provider before using the local API messages.";
        }
        this.apiMessages = await this.textProvider.getLocalApiMessages();
    }

    public useDefaultLocalStorageProvider(): void
    {
        this.localStorageProvider = new LocalStorageProvider();
    }

    public useDefaultSessionStorageProvider(): void
    {
        this.sessionStorageProvider = new SessionStorageProvider();
    }

    public useDefaultServices(): void
    {
        this.services = new Services(
            new BookingService(),
            new SpaceService(),
            new SpaceUtilisationSummaryService(),
            new UserPreferencesService(),
        );

        // todo: for backwards compatibility
        this.bookingService = this.services.bookings;
        this.spaceService = this.services.spaces;
        this.spaceUtilisationSummaryService = this.services.spaceUtilisationSummaries;
        this.userPreferencesService = this.services.userPreferences;
    }
}

export interface IAppState
{
    pageTitle: string;
    lightModeTheme: boolean;
    buildingId: number;
    buildingName: string;
}

export class AppState implements IAppState
{
    public pageTitle = "";
    public lightModeTheme = true;

    private get session()
    {
        return appContext().sessionStorageProvider;
    }

    public get buildingId(): number
    {
        return this.session.getBuildingId();
    }

    public set buildingId(value: number)
    {
        this.session.setBuildingId(value);
    }

    public get buildingName(): string
    {
        return this.session.getBuildingName();
    }

    public set buildingName(value: string)
    {
        this.session.setBuildingName(value);
    }
    
    public changed = new Event<IPartialAppState>();

    public async set(state: IPartialAppState, sender?: Component): Promise<void>
    {
        for (const property in state)
        {
            (<any>this)[property] = (<any>state)[property];
        }
        await this.changed.notify(state, sender);
    }

    public subscribe(component: Component, handler: (state: IPartialAppState, sender?: Component) => Promise<void>, callHandlerImmediately: boolean = true): string
    {
        let ref = "";
        try
        {
            ref = this.changed.add((state, sender) => handler(state, sender as Component));
            if (callHandlerImmediately)
            {
                handler(this, component);
            }

            let originalComponentWillUnmount = component.componentWillUnmount;
            component.componentWillUnmount = () =>
            {
                this.unsubscribe(ref);
                if (originalComponentWillUnmount != null)
                {
                    originalComponentWillUnmount.bind(component)();
                }
            };
        }
        catch
        {
            this.unsubscribe(ref);
        }
        return ref;
    }

    public unsubscribe(ref: string): void
    {
        this.changed.remove(ref);
    }

    public autoMap<TComponentState>(component: Component<unknown, TComponentState>, map: (appState: IPartialAppState) => Partial<TComponentState>): string
    {
        // todo: would be better to insert this handler first so that the component's state is updated before other handlers are invoked
        return this.subscribe(component, async (appState, sender) =>
        {
            const componentState = map(appState);
            const componentStateWithoutUndefined: Partial<TComponentState> = {};

            for (const componentProperty in componentState)
            {
                if (componentState[componentProperty] != undefined)
                {
                    componentStateWithoutUndefined[componentProperty] = componentState[componentProperty];
                }
            }
            if (Object.keys(componentStateWithoutUndefined).length > 0)
            {
                component.setState(componentStateWithoutUndefined as Pick<TComponentState, keyof TComponentState>);
            }
        });
    }
}

export type IPartialAppState = Partial<IAppState>;
