import { Component } from "react";
import "../../../styles/css/dashboard.scss";
import "../../../styles/css/header.scss";
import "../../../App.css";
import { RouteComponentProps, RouterProps } from 'react-router';
import apis from "../../../Providers.Api/apis";
import ConfirmModal from "../../../Components/uicomponents/ConfirmModal";
import LoadingOverlay from "../../../Components/LoadingOverlay"
import FormPanelInfo from "../../../Components/FormPanelInfo"
import { appContext } from "../../../AppContext";
import { Box, FormControlLabel, Grid, InputLabel } from "@mui/material";
import IbssTextField from "../../../Components/uicomponents/IbssTextField";
import IbssSwitchLabel from "../../../Components/uicomponents/IbssSwitchLabel";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import { IDispatch, IPropsFromState } from "../../../redux/Interfaces";
import { ICreateSpaceCateringMenuItemPayload } from "../../../Providers.Api/CateringItems/CreateEndpoint";
import { IUpdateSpaceCateringMenuItemPayload } from "../../../Providers.Api/CateringItems/UpdateEndpoint";

export default class EditCateringItem extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get appState() { return appContext().state; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isLoadingToSave: false,
            buildingid: this.appState.buildingId,
            updates: false,
            loading: false,
            imageUrl: "",
            spaceCateringCost: 0.0,
            spaceCateringId: 0,
            spaceCateringIsEnabled: false,
            spaceCateringIsPreBookable: false,
            spaceCateringIsRoomService: false,
            spaceCateringMessage: "",
            spaceCateringSection: "",
            spaceCateringShortMessage: "",
            spaceCateringTitle: "",
            show: false,
            isDelete: false,
        };
    }

    public componentDidMount(): void
    {
        const { match } = this.props;
        if (match.params.buildingid !== "0" && match.params.spacecateringId !== "0")
        {
            this.retrieveSpecificCateringData();
        }
    }

    private async retrieveSpecificCateringData(): Promise<void>
    {
        try
        {
            const { match }: IProps = this.props;
            const response = await apis.getSpecificSpaceCateringMenu(this.session.getBuildingId(), match.params.spacecateringId);
            let responseData = response.data;

            responseData.Space_Catering_IsPreBookable = ((responseData.Space_Catering_IsPreBookable === 1) || responseData.Space_Catering_IsPreBookable === true) ? true : false;
            responseData.Space_Catering_IsRoomService = ((responseData.Space_Catering_IsRoomService === 1) || responseData.Space_Catering_IsRoomService === true) ? true : false;
            responseData.Space_Catering_IsEnabled = ((responseData.Space_Catering_IsEnabled === 1) || responseData.Space_Catering_IsEnabled === true) ? true : false;
            this.setState({ 
                imageUrl: responseData.ImageURI,
                spaceCateringCost: responseData.Space_Catering_Cost,
                spaceCateringId: responseData.Space_Catering_Id,
                spaceCateringIsEnabled: responseData.Space_Catering_IsEnabled,
                spaceCateringIsPreBookable: responseData.Space_Catering_IsPreBookable,
                spaceCateringIsRoomService: responseData.Space_Catering_IsRoomService,
                spaceCateringMessage: responseData.Space_Catering_Message,
                spaceCateringSection: responseData.Space_Catering_Section,
                spaceCateringShortMessage: responseData.Space_Catering_Short_Message,
                spaceCateringTitle: responseData.Space_Catering_Title,
             });
        }
        catch
        {
            this.setState({ isLoadingToSave: false });
        }
    }

    private cancel(): void
    {
        const { history }: IProps = this.props;
        history.push(`/catering-items/` + this.session.getBuildingId());
    }

    private delete(): void
    {
        const { history, match }: IProps = this.props;
        if (this.state.isDelete)
        {
            this.apiClient.cateringItems.delete(parseInt(match.params.buildingid), parseInt(match.params.spacecateringId)).then((res) =>
            {
                history.push(`/catering-items/` + this.session.getBuildingId());
            })
            .catch(() =>
            {
                this.setState({ isLoadingToSave: false, });
            });
        }
    }

    private cancelDeleteModal(): void
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        });
    }

    public isDeleteModal(): void
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        });
        this.delete();
    }

    private async submit(): Promise<void>
    {
        this.setState({ isLoadingToSave: true });
        const { match }: IProps = this.props;
        
        if (match.params.spacecateringId === "0")
        {
            await this.create();
        }
        else
        {
            await this.update();
        }
    }

    private async create(): Promise<void>
    {
        let payload: ICreateSpaceCateringMenuItemPayload =
        {
            ImageURI: this.state.imageUrl,
            Node_Id: this.state.buildingid,
            Space_Catering_Title: this.state.spaceCateringTitle,
            Space_Catering_Message: this.state.spaceCateringMessage,
            Space_Catering_Section: this.state.spaceCateringSection,
            Space_Catering_Cost: this.state.spaceCateringCost,
            Space_Catering_Short_Message: this.state.spaceCateringShortMessage,
            Space_Catering_IsEnabled: (this.state.spaceCateringIsEnabled == true ? 1 : 0),
            Space_Catering_IsPreBookable: (this.state.spaceCateringIsPreBookable == true ? 1 : 0),
            Space_Catering_IsRoomService: (this.state.spaceCateringIsRoomService == true ? 1 : 0),
            Space_Catering_Menu: this.state.buildingid.toString()
        };

        const { history, match } = this.props;

        try
        {
            await this.apiClient.cateringItems.create(parseInt(match.params.buildingid), payload);
            history.push(`/catering-items/` + this.session.getBuildingId());
        }
        finally
        {
            this.setState({ isLoadingToSave: false });
        }
    }

    private async update(): Promise<void>
    {
        let payload: IUpdateSpaceCateringMenuItemPayload =
        {
            ImageURI: this.state.imageUrl,
            Node_Id: this.state.buildingid,
            Space_Catering_Id: this.state.spaceCateringId,
            Space_Catering_Title: this.state.spaceCateringTitle,
            Space_Catering_Message: this.state.spaceCateringMessage,
            Space_Catering_Section: this.state.spaceCateringSection,
            Space_Catering_Cost: this.state.spaceCateringCost,
            Space_Catering_Short_Message: this.state.spaceCateringShortMessage,
            Space_Catering_IsEnabled: (this.state.spaceCateringIsEnabled == true ? 1 : 0),
            Space_Catering_IsPreBookable: (this.state.spaceCateringIsPreBookable == true ? 1 : 0),
            Space_Catering_IsRoomService: (this.state.spaceCateringIsRoomService == true ? 1 : 0),
        };

        const { history, match } = this.props;
        try
        {
            await this.apiClient.cateringItems.update(this.session.getBuildingId(), this.state.spaceCateringId, payload);
            history.push(`/catering-items/` + this.session.getBuildingId());
        }
        finally
        {
            this.setState({ isLoadingToSave: false });
        }
    }

    public render(): JSX.Element
    {
        const { match }: IProps = this.props;

        const confModalData = this.state.isDelete &&
        {
            show: this.state.show,
            handleModal: () => this.cancelDeleteModal(),
            okButton: () => this.delete(),
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        };

        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="rightPanel-main-content-form">
                    {this.state.show ? (
                        <ConfirmModal {...confModalData} />
                    ) : ""}
                    <div className="form-panel">
                        <FormPanelInfo
                            formHeading={this.labels.HubLabelCateringMenu}
                            iconSrc="/images/Sidebar_Icons/Light_theme/Drink.svg"
                        />
                        {this.state.loading || (
                            <div className="form-panel-form" >
                                <form>
                                    <Grid container spacing={3} rowSpacing={4}>
                                        <Grid item md={12}>
                                            <IbssTextField id="standard-basic" color="secondary" label={this.labels.HubLabelMenu} variant="standard" fullWidth value={this.state.spaceCateringSection}
                                                onChange={(event) =>
                                                    this.setState({ spaceCateringSection: event.target.value })
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={4}>
                                            <IbssTextField id="standard-basic" color="secondary" label={this.labels.HubLabelTitle} variant="standard" fullWidth value={this.state.spaceCateringTitle}
                                                onChange={(event) =>
                                                    this.setState({ spaceCateringTitle: event.target.value })
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={8}>
                                            <IbssTextField id="standard-basic" color="secondary" label={this.labels.HubLabelShortMessage} variant="standard" fullWidth value={this.state.spaceCateringShortMessage}
                                                onChange={(event) =>
                                                    this.setState({ spaceCateringShortMessage: event.target.value })
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <InputLabel htmlFor="my-input" className="mb-2">{this.labels.HubLabelMessage}</InputLabel>
                                            <IbssTextField
                                                id="outlined-multiline-static"
                                                multiline
                                                color="secondary"
                                                placeholder="Placeholder"
                                                fullWidth
                                                rows={4}
                                                value={this.state.spaceCateringMessage}
                                                onChange={(event) =>
                                                    this.setState({ spaceCateringMessage: event.target.value })
                                                }
                                                sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            />
                                        </Grid>
                                        <Grid item md={5} className="ml-3">
                                            <Box className="mb-2">
                                                <FormControlLabel control={
                                                    <IbssSwitchLabel checked={this.state.spaceCateringIsPreBookable}
                                                        onChange={(event) => this.setState({ spaceCateringIsPreBookable: event.target.checked })}
                                                    />
                                                } label={this.labels.HubLabelPreBookable} />
                                            </Box>
                                            <Box className="mb-2">
                                                <FormControlLabel control={<IbssSwitchLabel checked={this.state.spaceCateringIsRoomService}
                                                    onChange={(event) => this.setState({ spaceCateringIsRoomService: event.target.checked })}
                                                />} label={this.labels.HubLabelRoomService} />
                                            </Box>
                                            <Box>
                                                <FormControlLabel control={<IbssSwitchLabel checked={this.state.spaceCateringIsEnabled}
                                                    onChange={(event) => this.setState({ spaceCateringIsEnabled: event.target.checked })}
                                                />} label={this.labels.HubLabelEnabled} />
                                            </Box>
                                        </Grid>
                                        <Grid item md={4} className="ml-3">
                                            <Box className="mb-3">
                                                <IbssTextField id="standard-basic" color="secondary" type="number" label={this.labels.HubLabelCost} variant="standard" fullWidth value={this.state.spaceCateringCost}
                                                    onChange={(event) =>
                                                        this.setState({ spaceCateringCost: parseFloat(event.target.value) })
                                                    }
                                                />
                                            </Box>
                                            <Box>
                                                <IbssTextField id="standard-basic" color="secondary" label={this.labels.dataCateringMenuItemImage_S} variant="standard" fullWidth value={this.state.imageUrl}
                                                    onChange={(event) =>
                                                        this.setState({ imageUrl: event.target.value })
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        )}
                    </div>
                </div >

                <div className="rightPanel-main-button-group">
                    <Box>
                        <IbssButtonRedo color="error" variant="contained" className="mr-2" disabled={match.params.spacecateringId == "0" ? true : false} onClick={() => this.isDeleteModal()}>{this.labels.HubButtonDelete}</IbssButtonRedo>
                    </Box>
                    <Box>
                        <IbssButtonRedo color="secondary" variant="contained" className="mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</IbssButtonRedo>
                        <IbssButtonRedo color="primary" variant="contained" className="ml-2" onClick={() => this.submit()}>{this.labels.HubButtonSave}</IbssButtonRedo>
                    </Box>
                </div>

            </>
        );
    }
}

interface IProps extends RouterProps, RouteComponentProps<IQueryParams>, IPropsFromState, IDispatch
{
}

interface IQueryParams 
{
    buildingid: string;
    spacecateringId: string;
}

interface IState
{
    isLoadingToSave: boolean;
    buildingid: number;
    updates: boolean;
    loading: boolean;
    imageUrl: string;
    spaceCateringId: number;
    spaceCateringSection: string;
    spaceCateringTitle: string;
    spaceCateringShortMessage: string;
    spaceCateringMessage: string;
    spaceCateringCost: number;
    spaceCateringIsEnabled: boolean;
    spaceCateringIsPreBookable: boolean;
    spaceCateringIsRoomService: boolean;
    show: boolean;
    isDelete: boolean;
}
