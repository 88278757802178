import { IGetNewCateringOrdersEndpoint } from "./GetNewCateringOrdersEndpoint";
import { IGetCateringOrdersEditEndpoint } from "./GetCateringOrdersEditEndpoint";
import { IGetCateringOrdersEndpoint } from "./GetCateringOrdersEndpoint";
import { IUpdateCateringOrdersEndpoint } from "./UpdateCateringOrdersEndpoint";
import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CateringOrderRepository implements ICateringOrderRepository
{
    private getNewCateringOrdersEndpoint: IGetNewCateringOrdersEndpoint;
    private getCateringOrdersEditEndpoint: IGetCateringOrdersEditEndpoint;
    private getCateringOrdersEndpoint: IGetCateringOrdersEndpoint;
    private updateCateringOrdersEndpoint: IUpdateCateringOrdersEndpoint;

    constructor(
        getNewCateringOrdersEndpoint: IGetNewCateringOrdersEndpoint,
        getCateringOrdersEditEndpoint: IGetCateringOrdersEditEndpoint,
        getCateringOrdersEndpoint: IGetCateringOrdersEndpoint,
        updateCateringOrdersEndpoint: IUpdateCateringOrdersEndpoint
    )
    {
        this.getNewCateringOrdersEndpoint = getNewCateringOrdersEndpoint;
        this.getCateringOrdersEditEndpoint = getCateringOrdersEditEndpoint;
        this.getCateringOrdersEndpoint = getCateringOrdersEndpoint;
        this.updateCateringOrdersEndpoint = updateCateringOrdersEndpoint;
    }

    public getNewCateringOrders(nodeId: number, spaceId: string): Promise<ICateringOrder[]>
    {
        return this.getNewCateringOrdersEndpoint.execute(nodeId, spaceId);
    }

    public getCateringOrdersEdit(nodeId: number, taskId: string): Promise<ICateringOrder[]>
    {
        return this.getCateringOrdersEditEndpoint.execute(nodeId, taskId);
    }

    public getTaskOrderItems(nodeId: number, taskId: string): Promise<ICateringOrder[]>
    {
        return this.getCateringOrdersEndpoint.execute(nodeId, taskId);
    }

    public updateCateringOrders(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>
    {
        return this.updateCateringOrdersEndpoint.execute(nodeId, taskId, payload);
    }
}

export interface ICateringOrderRepository
{
    getNewCateringOrders(nodeId: number, spaceId: string): Promise<ICateringOrder[]>;
    getCateringOrdersEdit(nodeId: number, taskId: string): Promise<ICateringOrder[]>;
    getTaskOrderItems(nodeId: number, taskId: string): Promise<ICateringOrder[]>;
    updateCateringOrders(nodeId: number, taskId: number, payload: ICateringOrder[]): Promise<string>;
}

export interface ICateringOrder 
{
    ImageURI: string;
    Menu_Item_Id: number;
    Menu_Item_Title: string;
    Menu_Item_Description: string;
    Menu_Item_Section: string;
    Menu_Item_Price: number;
    Node_Id: number;
    Catering_Order_Id: number;
    Space_Id: string;
    Catering_Order_Item_Id: string;
    Catering_Order_Item_Quantity: string;
}
