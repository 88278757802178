import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateEndpoint implements ICreateEndpoint
{
    public async execute(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<void>
    {
        try
        {
            await axios.post(`${apis.allApi}${buildingId}/SpaceCateringMenu`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateEndpoint
{
    execute(buildingId: number, payload: ICreateSpaceCateringMenuItemPayload): Promise<void>;
}

export interface ICreateSpaceCateringMenuItemPayload
{
    Node_Id: number;
    ImageURI: string;
    Space_Catering_Title: string;
    Space_Catering_Message: string;
    Space_Catering_IsEnabled: number;
    Space_Catering_IsPreBookable: number;
    Space_Catering_IsRoomService: number;
    Space_Catering_Section: string;
    Space_Catering_Cost: number;
    Space_Catering_Short_Message: string;
    Space_Catering_Menu: string;
}
