import { DateTime } from "luxon";
import { ICreateTask, ICreateTaskEndpoint } from './CreateTaskEndpoint';
import { IExportTasksEndpoint } from './ExportTasksEndpoint';
import { IGetTaskEndpoint } from './GetTaskEndpoint';
import { IGetTasksByBookingIdEndpoint } from './GetTasksByBookingIdEndpoint';
import { IGetTasksEndpoint } from './GetTasksEndpoint';
import { ISetStatusToInProgressEndpoint } from './SetStatusToInProgressEndpoint';
import { IUpdateTaskEndpoint } from './UpdateTaskEndpoint';

export class TaskRepository implements ITaskRepository
{
    private createTaskEndpoint: ICreateTaskEndpoint;
    private exportTasksEndpoint: IExportTasksEndpoint;
    private getTaskEndpoint: IGetTaskEndpoint;
    private getTasksByBookingIdEndpoint: IGetTasksByBookingIdEndpoint;
    private getTasksEndpoint: IGetTasksEndpoint;
    private setStatusToInProgressEndpoint: ISetStatusToInProgressEndpoint;
    private updateTaskEndpoint: IUpdateTaskEndpoint;

    constructor(
        createTaskEndpoint: ICreateTaskEndpoint,
        exportTasksEndpoint: IExportTasksEndpoint,
        getTaskEndpoint: IGetTaskEndpoint,
        getTasksByBookingIdEndpoint: IGetTasksByBookingIdEndpoint,
        getTasksEndpoint: IGetTasksEndpoint,
        setStatusToInProgressEndpoint: ISetStatusToInProgressEndpoint,
        updateTaskEndpoint: IUpdateTaskEndpoint
    )
    {
        this.createTaskEndpoint = createTaskEndpoint;
        this.exportTasksEndpoint = exportTasksEndpoint;
        this.getTaskEndpoint = getTaskEndpoint;
        this.getTasksByBookingIdEndpoint = getTasksByBookingIdEndpoint;
        this.getTasksEndpoint = getTasksEndpoint;
        this.setStatusToInProgressEndpoint = setStatusToInProgressEndpoint;
        this.updateTaskEndpoint = updateTaskEndpoint;
    }

    public getTasks<TTask>(type: new() => TTask, buildingId: number, filter: ITasksFilter): Promise<TTask[]>
    {
        return this.getTasksEndpoint.execute(type,buildingId, filter);
    }

    public getTasksByBookingId<TTask>(type: new() => TTask, buildingId: number, bookingId: string): Promise<TTask[]>
    {
        return this.getTasksByBookingIdEndpoint.execute(type, buildingId, bookingId);
    }

    public getTask<TTask>(type: new() => TTask, nodeId: number, taskId: string): Promise<TTask>
    {
        return this.getTaskEndpoint.execute(type, nodeId, taskId);
    }

    public updateTask(nodeId: number, taskId: number, payload: Task): Promise<string>
    {
        return this.updateTaskEndpoint.execute(nodeId, taskId, payload);
    }

    public setStatusToInProgress(nodeId: number, taskId: string): Promise<string>
    {
        return this.setStatusToInProgressEndpoint.execute(nodeId, taskId);
    }

    public exportTasks(buildingId: number, filter: ITasksFilter): Promise<void>
    {
        return this.exportTasksEndpoint.execute(buildingId, filter);
    }

    public createTask(nodeId: number, payload: ICreateTask): Promise<string>
    {
        return this.createTaskEndpoint.execute(nodeId, payload);
    }

}

export interface ITaskRepository
{
    getTasks<TTask>(type: new() => TTask, buildingId: number, tasksFilter: ITasksFilter): Promise<TTask[]>;
    exportTasks(buildingId: number, filter: ITasksFilter): Promise<void>;
    setStatusToInProgress(nodeId: number, taskId: string): Promise<string>;
    getTask<TTask>(type: new() => TTask, nodeId: number, taskId: string): Promise<TTask>;
    updateTask(nodeId: number, taskId: number, payload: Task): Promise<string>;
    createTask(nodeId: number, payload: ICreateTask): Promise<string>;
    getTasksByBookingId<TTask>(type: new() => TTask, buildingId: number, bookingId: string): Promise<TTask[]>;
}

export interface ITasksFilter
{
    status?: string;
    owner?: string;
    taskCategory?: "Catering";
    taskStatusNot?:string[];
    createdBy?:string;
    createdFrom?: DateTime;
    createdTo?: DateTime;
    taskEventStart?: DateTime;
    taskEventEnd?:DateTime;
    taskDueFrom?: DateTime;
    taskDueTo?: DateTime;
}

export class Task
{
    Node_Id = 0;
    Booking_Id = "";
    Task_Id = 0;
    Task_Type_Name = "";
    CreatedAt = "";
    Task_Category_Name = "";
    Space_Id = "";
    Task_Priority = 0;
    Task_Due_Date = "";
    Task_Owner = "";
    Task_Event_Time = "";
    Task_CreatedBy = "";
    Task_Status = "";
    Task_IsActive = 0;
    Task_IsOwned = 0;
    Task_TTC = 0;
}

export class CateringTask
{
    Node_Id = 0;
    Booking_Id = "";
    Task_Id = 0;
    Task_Type_Name = "";
    CreatedAt = "";
    Task_Category_Name = "";
    Space_Id = "";
    Task_Priority = 0;
    Task_Due_Date = "";
    Task_Owner = "";
    Task_Event_Time = "";
    Task_CreatedBy = "";
    Task_Status = "";
    Task_IsActive = 0;
    Task_IsOwned = 0;
    Catering_Service_Time = "";
    Catering_Clearing_Time = "";
    Catering_Attendees = 0;
    Catering_Notes = "";
    Cost_Code_Allocation: ICostAllocation[] = [];
    Task_TTC = 0;
}

export interface ICostAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}
