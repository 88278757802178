// import react from react; 
import '../styles/css/spinner.scss'
import { appContext } from "../AppContext";




function Spinner() {
  // private labels = appContext().labels;
  
  return (
    <div style={{ zIndex: 99999999 }} className="loadingIndicator">
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">In Progress ...</span>
        </div>
      </div>
    </div>
  )
}


export default Spinner;