import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { IV2GetResponse } from "../Models";
import { DateHelper } from "../../Common/DateHelper";
import { ICreateV2BookingRequest } from "./CreateV2BookingEndpoint";
import { IbssRequestConfig } from "../ApiClient";

export class CreateV1BookingEndpoint implements ICreateV1BookingEndpoint
{
    public async execute(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>
    {
        try
        {
            const axiosConfig: IbssRequestConfig = { suppressErrorPopup: suppressErrorPopup };
            const response = await axios.post<ICreateBookingResponse>(apis.allApi + nodeId + `/Bookings`, booking, axiosConfig);
            return response.data;
        }
        catch (error: any)
        {
            throw ApiError.fromAxiosResponse(error.response as AxiosResponse);
        }
    }
}

export interface ICreateV1BookingEndpoint
{
    execute(nodeId: number, booking: ICreateV1BookingRequest, suppressErrorPopup: boolean): Promise<ICreateBookingResponse>;
}

export interface ICreateV1BookingRequest extends Omit<ICreateV2BookingRequest, "Booking_Parties" | "Booking_Parties_Visitors" | "Cost_Code_Allocation">
{
    Booking_Parties?: string;
    Booking_Parties_Visitors?: string;
    Cost_Code_Allocation?: string;
}

export interface ICreateBookingResponse
{
    Node_Id: number;
    Booking_Id: string;
    Space_Id: string;
    Booking_Start: string;
    Booking_End: string;
}
