import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { RouteComponentProps, RouterProps, generatePath } from 'react-router';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { appContext } from '../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../Components/uicomponents/IbssActionButton';
import IbssDataGrid from '../../../Components/uicomponents/IbssDataGrid';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import { IDispatch, IPropsFromState } from '../../../redux/Interfaces';
import IbssDialog from '../../../Components/uicomponents/IbssDialog';
import IbssInputDropDown from '../../../Components/uicomponents/IbssInputDropDown';
import IbssButtonRedo from '../../../Components/uicomponents/IbssButton';
import IbssFilter from '../../../Components/uicomponents/IbssFilter';
import Spinner from '../../../Components/Spinner';
import { ISpaceCateringMenuItem } from '../../../Providers.Api/CateringItems/GetManyEndpoint';
import { CateringItemsFilter } from '../../../Providers.Api/CateringItems/CateringItemRepository';
import { TypeHelper } from '../../../Common/TypeHelper';
import Helper from '../../../Common/Helper';
import { IUpdateSpaceCateringMenuItemPayload } from '../../../Providers.Api/CateringItems/UpdateEndpoint';

export default class CateringItems extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            loading: false,
            taskList: [],
            isCreateRight: false,
            isCancelRight: false,
            buildingId: this.appState.buildingId,
            message: "",
            selectedTaskIds: [],
            actionType: "",
            searchTerm: "",
            openFilterModal: false,
            menuValue: "Any",
            enabledValue: "Any",
            preBookableValue: "Any",
            roomServiceValue: "Any",
            disableBtn: true,
            enableBtn: true,
            filterStatusOptions: []
        }
    }

    public async componentDidMount(): Promise<void> 
    {
        const { history } = this.props;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
        const buildingId = parseInt(this.props.match.params["buildingid"]);

        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: buildingId });
            if (buildingId !== 0)
            {
                history.push("/catering-items/" + this.state.buildingId);
            }
            else 
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }
        }

        this.setRights();
        this.loadTasks();
    }

    public async componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): Promise<void> 
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            const { history } = this.props;
            const buildingID = this.state.buildingId;
            const path = generatePath(this.props.match.path, { buildingid: buildingID });
            history.replace(path);
            await this.loadTasks();
        }
    }

    private setRights(): void 
    {
        const rights = this.local.getIbssRightList();

        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Create") > -1)
        {
            this.setState({ isCreateRight: true });
        }

        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Cancel") > -1)
        {
            this.setState({ isCancelRight: true });
        }
    }

    private async loadTasks(): Promise<void> 
    {
        try
        {
            this.setState({ loading: true });
            const tasksFilter = new CateringItemsFilter(
            {
                section: (this.state.menuValue === "Any" ? null : this.state.menuValue),
                isEnabled: TypeHelper.ToBoolean( this.state.enabledValue === "Any" ? null : this.state.enabledValue),
                isPreBookable: TypeHelper.ToBoolean(this.state.preBookableValue === "Any" ? null : this.state.preBookableValue),
                isRoomService: TypeHelper.ToBoolean(this.state.roomServiceValue === "Any" ? null : this.state.roomServiceValue),
            });
            const response = await this.apiClient.cateringItems.getMany(this.state.buildingId, tasksFilter);

            if (!response) 
            {
                return;
            }
            let tasks = response.map(item => new MenuItemView(item));
            this.setState({ taskList: tasks, loading: false });
            this.tasksSelected(this.state.selectedTaskIds)
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    private filterTasks(searchTerm: string): MenuItemView[] 
    {
        const filteredTasks = this.state.taskList.filter(task =>
        {
            let key: keyof MenuItemView;
            for (key in task)
            {
                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async taskStatusUpdate(status: number): Promise<void> 
    {
        this.setState({ loading: true });
        const payload: IUpdateSpaceCateringMenuItemPayload = { Space_Catering_IsEnabled: status };
        const promises = this.state.selectedTaskIds.map(id => this.apiClient.cateringItems.update(this.state.buildingId, parseInt(id.toString()), payload));
        Promise.all(promises)
        .then(() =>
        {
            this.loadTasks();
        })
        .catch(error => {
            console.log(error)
        }
    );
    }

    private enabledClicked(): void 
    {
        this.taskStatusUpdate(1);
    }

    private disabledClicked(): void 
    {
        this.taskStatusUpdate(0);
    }

    private deleteClicked(): void 
    {
        const promises = this.state.selectedTaskIds.map(id => this.apiClient.cateringItems.delete(this.state.buildingId, parseInt(id.toString())));
        Promise.all(promises)
            .then(() =>
            {
                this.loadTasks();
            })
            .catch(error => {
                console.log(error)
            }
        );

       
    }

    private filterModalCancelled(): void
    {
        this.setState({ openFilterModal: !this.state.openFilterModal });
        this.setState({ loading: false });
    }

    private async filterModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        this.setState({ openFilterModal: false });
        await this.loadTasks();
    }

    private async filterButtonClicked(): Promise<void>
    {
        const tasksFilter = new CateringItemsFilter(
        {
            isEnabled: TypeHelper.ToBoolean(this.state.enabledValue === "Any" ? null : this.state.enabledValue),
            isPreBookable: TypeHelper.ToBoolean(this.state.preBookableValue === "Any" ? null : this.state.preBookableValue),
            isRoomService: TypeHelper.ToBoolean(this.state.roomServiceValue === "Any" ? null : this.state.roomServiceValue),
        });

        const response = await this.apiClient.cateringItems.getMany(this.state.buildingId, tasksFilter);
        const tasks = response.map(item => new MenuItemView(item));

        const filterStatusOptions = tasks
            .map(i => ({ label: i.menu, value: i.menu }))
            .distinct(i => i.value)
            .sort((a, b) =>
            {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return ((textA < textB) ? -1 : ((textA > textB) ? 1 : 0));
            })
            .insert(0, [{ label: this.labels.HubLabelAny, value: "Any" }]);

        this.setState({ taskList: tasks, loading: false, openFilterModal: true, filterStatusOptions: filterStatusOptions });
    }

    private tasksSelected(selection: GridRowSelectionModel): void
    {
        this.setState({ selectedTaskIds: selection });
        const selectedMenuItems = this.state.taskList.filter(menuItem => selection.contains(menuItem.id));
        const enableBtnState = (selectedMenuItems.length ? selectedMenuItems.some(e => e.isEnabled === 1) : true);
        const disableBtnState = (selectedMenuItems.length ? selectedMenuItems.some(e => e.isEnabled === 0) : true);

        if (!enableBtnState)
        {
            this.setState({ enableBtn: false });
        }
        else
        {
            this.setState({ enableBtn: true });
        }

        if (!disableBtnState)
        {
            this.setState({ disableBtn: false });
        }
        else
        {
            this.setState({ disableBtn: true });
        }
    }

    public render(): JSX.Element
    {
        const { history } = this.props;

        const dataGridHeader: GridColDef<MenuItemView>[] =
            [
                {
                    headerName: this.labels.HubLabelItemCode,
                    field: Helper.nameOf<MenuItemView>("title"),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelMenu,
                    field: Helper.nameOf<MenuItemView>("menu"),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelDescription,
                    field: Helper.nameOf<MenuItemView>("shortMessage"),
                    minWidth: 150,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelCost,
                    field: Helper.nameOf<MenuItemView>("cost"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => `\u00A3${params.row.cost}`
                },
                {
                    headerName: this.labels.HubLabelEnabled,
                    field: Helper.nameOf<MenuItemView>("isEnabled"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.isEnabled ? "True" : "False"
                },
                {
                    headerName: this.labels.HubLabelPreBookable,
                    field: Helper.nameOf<MenuItemView>("isPreBookable"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.isPreBookable ? "True" : "False"
                },
                {
                    headerName: this.labels.HubLabelRoomService,
                    field: Helper.nameOf<MenuItemView>("isRoomService"),
                    minWidth: 150,
                    flex: 1,
                    renderCell: (params) => params.row.isRoomService ? "True" : "False"
                },
            ];
            
        let numFilterStatusOption = [
            { label: this.labels.HubLabelAny, value: "Any" },
            { label: this.labels.HubLabelTrue, value: "True" },
            { label: this.labels.HubLabelFalse, value: "False" }
        ]
        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonDelete,
                    icon: (
                        <IbssSvgIcon>
                            <DeleteOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.deleteClicked(),
                    disabled: this.state.selectedTaskIds.length < 1
                },
                {
                    label: this.labels.HubLabelDisable,
                    icon: (
                        <IbssSvgIcon>
                            <CancelOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.disabledClicked(),
                    disabled: this.state.disableBtn
                },
                {
                    label: this.labels.HubLabelEnable,
                    icon: (
                        <IbssSvgIcon>
                            <CheckCircleOutlineTwoToneIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.enabledClicked(),
                    disabled: this.state.enableBtn
                },
                {
                    label: this.labels.HubButtonAdd,
                    icon: (
                        <IbssSvgIcon>
                            <AddIcon />
                        </IbssSvgIcon>
                    ),
                    color: "primary",
                    onClick: () => { history.push(`/operational-services-catering/${this.state.buildingId}/catering/0`) },
                    disabled: !this.state.isCreateRight
                }
            ];

        const filteredTasks = this.filterTasks(this.state.searchTerm);

        return (
            <>
                <div>
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.openFilterModal}
                        onClose={() => this.filterModalCancelled()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        content=
                        {
                            <>
                                <Typography className="mb-3">{this.labels.HubCateringFilterSubTitle}</Typography>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={this.state.filterStatusOptions}
                                        id="statusSelection"
                                        value={this.state.menuValue}
                                        inputLabel={this.labels.HubLabelMenu}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ menuValue: event.target.value }) }}
                                    />
                                </div>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={numFilterStatusOption}
                                        id="enableSelection"
                                        value={this.state.enabledValue}
                                        inputLabel={this.labels.HubLabelEnabled}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ enabledValue: event.target.value }) }}
                                    />
                                </div>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={numFilterStatusOption}
                                        id="preBookSelection"
                                        value={this.state.preBookableValue}
                                        inputLabel={this.labels.HubLabelPreBookable}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ preBookableValue: event.target.value }) }}
                                    />
                                </div>
                                <div className="row my-3">
                                    <IbssInputDropDown
                                        options={numFilterStatusOption}
                                        id="roomServiceSelection"
                                        value={this.state.roomServiceValue}
                                        inputLabel={this.labels.HubLabelRoomService}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ roomServiceValue: event.target.value }) }}
                                    />
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalCancelled()}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    <div>
                        {this.state.loading && <Spinner />}
                        <div className="rightPanel-main-content">
                            <div className="table-panel">
                            <Grid container rowSpacing={1} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                            <Grid item md={12} >
                                <Box className="table-panel-header" sx={{ml:0}}>{this.labels.HubMenuCateringItems}</Box>
                            </Grid>
                            </Grid>
                                    <IbssFilter
                                        showExport={true}
                                        searchTerm={this.state.searchTerm}
                                        searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                        filterButtonClicked={() => this.filterButtonClicked()}
                                    />
                                     <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                        <IbssActionButton
                                            buttons={actionButtons}
                                        />
                                    </Box>
                                    <Box sx={{mt:1}}>
                                <IbssDataGrid
                                    checkboxSelection
                                    columns={dataGridHeader}
                                    disableRowSelectionOnClick
                                    rows={filteredTasks}
                                    onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                    onRowClick={(params) => history.push(`/operational-services-catering/${this.state.buildingId}/catering/${params.id}`)}
                                    className='view-booking-policies-data-grid'
                                />
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;
}

interface IState
{
    loading: boolean;
    taskList: MenuItemView[];
    isCreateRight: boolean;
    isCancelRight: boolean;
    buildingId: number;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    actionType: string;
    searchTerm: string;
    openFilterModal: boolean;
    menuValue: string;
    enabledValue: string;
    preBookableValue: string;
    roomServiceValue: string;
    disableBtn: boolean;
    enableBtn: boolean;
    filterStatusOptions: IFilterOption[]
}

interface IFilterOption
{
    label: string;
    value: string;
}

class MenuItemView
{
    public id: number;
    public cost: number;
    public isEnabled: number;
    public isPreBookable: number;
    public isRoomService: number;
    public menu: string;
    public shortMessage: string;
    public title: string;

    constructor(value: ISpaceCateringMenuItem)
    {
        this.id = value.Space_Catering_Id;
        this.cost = value.Space_Catering_Cost;
        this.isEnabled = value.Space_Catering_IsEnabled;
        this.isPreBookable = value.Space_Catering_IsPreBookable;
        this.isRoomService = value.Space_Catering_IsRoomService;
        this.menu = value.Space_Catering_Section;
        this.shortMessage = value.Space_Catering_Short_Message;
        this.title = value.Space_Catering_Title;
    }
}
