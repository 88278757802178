import React, { Component } from 'react';
import { Grid, styled, Tab, Tabs, TabsProps } from '@mui/material';
import IbssTabPanel from "./IbssTabPanel";

export type TabsParameters = {
    label: React.ReactNode,
    components: React.ReactNode,
    icon?: JSX.Element,
    iconPosition?: "bottom" | "top" | "end" | "start",
    tabButtonHeight?: number | string
}

type TabProps = TabsProps & {
    orientation: "horizontal" | "vertical",
    tabs: Array<TabsParameters>,
    boxwidth?: string,
    gridGap?: string | number,
    tabChanged?: (tabIndex: number) => void,
    defaultValue?: number,
}

const StyledTabs = styled(Tabs)<TabProps>(({}) => ({
    '&.Mui-selected': {
        borderBottom: '2px solid black'
    }
}))

interface ITabState {
    tabValue: number;
}

class IbssHorizontalTabs extends Component<TabProps, ITabState> {

    constructor(props: TabProps) {
        super(props);

        this.state = {
            tabValue: this.props.defaultValue ?? 0,
        }
    }

    public handleTabChange = (event: React.SyntheticEvent, newValue: number) =>
    {
        this.setState({
            tabValue: newValue
        });
        if(this.props.tabChanged)
        {
            this.props.tabChanged(newValue);
        }
    };

    render() {
        return (
            <>
                <Grid sx={{display: this.props.orientation === "horizontal" ? 'block' : 'flex', gap: this.props.gridGap}}>
                    <Grid sx={{ width: this.props.boxwidth }} >
                        <StyledTabs
                            variant="fullWidth"
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                            {...this.props}
                        >
                            {this.props.tabs.map((item, i) => {
                                return <Tab
                                    label={item?.label}
                                    key={i}
                                    icon={item?.icon}
                                    iconPosition={item?.iconPosition}
                                    sx={{width: "100%", color: `var(--ui-text)`, fontFamily: "Source Sans Pro", height: item.tabButtonHeight }}
                                />
                            })}
                        </StyledTabs>
                    </Grid>
                    <Grid>
                        {this.props.tabs.map(({ components }, i) => (
                            <IbssTabPanel value={this.state.tabValue} index={i} key={i} orientation={this.props.orientation}>
                                {components}
                            </IbssTabPanel>
                        ))}
                    </Grid>
                </Grid>
            </>
        )
    }
};

export default IbssHorizontalTabs;
