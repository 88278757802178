import { Component } from "react";
import apis from "../../../Providers.Api/apis";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell, Label } from 'recharts';
import LoadingOverlay from "../../../Components/LoadingOverlay";
import ChartPanelInfo from "../../../Components/ChartPanelInfo";
import { appContext } from "../../../AppContext";
import { Bool } from "reselect/es/types";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { RouteComponentProps } from "react-router";
import { BuildingChangeReason, IbssComponent } from "../../../Components/IbssComponent";
import Helper from "../../../Common/Helper";
import { DateTime } from "luxon";
import { IBookingSummaryData } from "../../../Providers.Api/BookingDailySummaries/GetManyEndpoint";

export default class Trends extends IbssComponent<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }
    private get services() { return appContext().services; }
    private browserWidthChanged = this.setChartDimensions.bind(this);
    private get buildingId() { return this.state.buildingId; }

    constructor(props: IProps)
    {
        super(props);
        this.state = {
            isLoading: false,
            getDetailsApiResponseErrorLoading: false,
            buildingId: this.appState.buildingId,
            selectedDate: "",
            dataGraph: [],
            donutChartData: [],

            dataPieEmpty: [{ name: "No Data", value: 100, count: 0 }],
            COLORSEMPTY: ['#393D44'],

            dataPie1: [],
            dataPie1Value: 0,
            COLORS1: ['#F44336', '#FF7913', '#FFC400', '#00C853', '#2962FF'],

            dataPie2: [],
            dataPie2Value: 0,
            COLORS2: ['#2962FF', '#FFC400', '#00C853', '#FF7913'],

            dataPie3: [],
            dataPie3Value: 0,
            COLORS3: ['#2962FF', '#00C853'],

            widthchart: 0,
            widthdonut: 0,
            cxdonut: 0,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.onBuildingIdChanged<IMatchParams>(params => params.buildingId, (buildingId, reason) => this.buildingIdChanged(buildingId, reason));
        await this.loadCharts();
        this.setChartDimensions();
        window.addEventListener('resize', this.browserWidthChanged);
        window.addEventListener('load', this.browserWidthChanged);
    }

    public componentWillUnmount(): void
    {
        window.removeEventListener('resize', this.browserWidthChanged);
        window.removeEventListener('load', this.browserWidthChanged);
        super.componentWillUnmount();
    }

    public async buildingIdChanged(buildingId: number, reason: BuildingChangeReason): Promise<void>
    {
        if (reason == "BuildingSelectorChanged")
        {
            this.props.history.push(`/buildings/${buildingId}/bookings/trends`);
        }
        await this.setStateAsync({ buildingId: buildingId });
        await this.loadCharts();
    }

    private setChartDimensions(): void
    {
        let chartwidth = window.innerWidth - 390;
        let donutwidth = (window.innerWidth - 370);
        if (chartwidth > 2200)
        {
            this.setState({
                widthchart: 2200,
                widthdonut: donutwidth,
                cxdonut: donutwidth / 17
            })
        } else
        {
            if (chartwidth < 960)
            {
                this.setState({
                    widthchart: 960,
                    widthdonut: donutwidth,
                    cxdonut: donutwidth / 17
                })
            } else
            {
                this.setState({
                    widthchart: chartwidth,
                    widthdonut: donutwidth,
                    cxdonut: donutwidth / 17
                })
            }
        }
    }

    private async loadCharts(): Promise<void>
    {

        const totalApiResponseErrorNumber = 6
        let apiResponseErrorNumber = 0
        this.setState({ isLoading: true, getDetailsApiResponseErrorLoading: true })

        let tempGraph: IBar[] = [];
        let tempPie1: IPieChart[] = [];
        let tempPie2: IPieChart[] = [];
        let tempPie3: IPieChart[] = [];

        let today = DateTime.now().date();
        let dateFormatting = today.plus({ days: -7 }).toLocaleDateString();
        const startOfToday = DateTime.now().startOf('day');
        const yesterday = startOfToday.minus({ days: 1 });

        for (let i = 0; i < 14; i++) 
        {
            const currentDate = yesterday.minus({ days: 13 - i });
            const day = currentDate.day < 10 ? '0' + currentDate.day : currentDate.day;
            const month = currentDate.month < 10 ? '0' + currentDate.month : currentDate.month;
            const year = currentDate.year;

            const formatedDateStartsWithMonth = `${month}/${day}/${year}`;
            const formatedDateStartsWithDay = `${day}/${month}/${year}`;

            tempGraph.push({
                name: navigator.language === "en-US" ? formatedDateStartsWithMonth : formatedDateStartsWithDay,
                NoShow: 0,
                Cancelled: 0,
                AutoCancelled: 0,
                Completed: 0,
                Active: 0,
            });
        }

        tempPie1.push({ name: "No Show", value: 0, count: 0 })
        tempPie1.push({ name: "Auto Cancelled", value: 0, count: 0 })
        tempPie1.push({ name: "Cancelled", value: 0, count: 0 })
        tempPie1.push({ name: "Completed", value: 0, count: 0 })
        tempPie1.push({ name: "Active", value: 0, count: 0 })

        tempPie2.push({ name: "Not Checked In", value: 0, count: 0 })
        tempPie2.push({ name: "Auto Checkin", value: 0, count: 0 })
        tempPie2.push({ name: "Early Checkin", value: 0, count: 0 })
        tempPie2.push({ name: "Late Checkin", value: 0, count: 0 })

        tempPie3.push({ name: "Early Finish", value: 0, count: 0 })
        tempPie3.push({ name: "Completed", value: 0, count: 0 })

        this.setState({
            dataGraph: tempGraph,
            dataPie1: tempPie1,
            dataPie2: tempPie2,
            dataPie3: tempPie3
        })

        const todays = DateTime.now().startOf('day');
        const fourteenDaysAgo = todays.minus({ days: 14 });

        const bookings = await this.apiClient.bookingDailySummary.getMany(
          this.buildingId,
          fourteenDaysAgo.setZoneByNode(this.buildingId),
          todays.setZoneByNode(this.buildingId)
        );

        // getBefore10toAfter3OverviewBookings
        apiResponseErrorNumber++
        this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, });
        this.setState({ donutChartData: bookings });

        tempPie1 = [];
        tempPie2 = [];
        tempPie3 = [];

        const counts = {
            NoShow: 0,
            AutoCancelled: 0,
            Cancelled: 0,
            Completed: 0,
            EarlyCheckin: 0,
            LateCheckin: 0,
            EarlyFinish: 0,
            AutoComplete: 0,
            NotCheckedIn: 0,
            AutoCheckin: 0,
        };
        
        for (let i = 0; i < bookings.length; i++) 
        {
            const booking = bookings[i];
            counts.NoShow += booking.Bookings_No_Show_Count ? 1 : 0;
            counts.AutoCancelled += booking.Bookings_Auto_Cancelled_Count ? 1 : 0;
            counts.Cancelled += booking.Bookings_Cancelled_Count ? 1 : 0;
            counts.Completed += booking.Bookings_Completed_Count ? 1 : 0;
            counts.EarlyCheckin += booking.Bookings_Early_Checkin_Count ? 1 : 0;
            counts.LateCheckin += booking.Bookings_Late_Checkin_Count ? 1 : 0;
        }

        const totalNoShowCount = bookings.reduce((total, booking) => total + (booking.Bookings_No_Show_Count || 0), 0);
        const totalAutoCancelled = bookings.reduce((total, booking) => total + (booking.Bookings_Auto_Cancelled_Count || 0), 0);
        const totalCancelled = bookings.reduce((total, booking) => total + (booking.Bookings_Cancelled_Count || 0), 0);
        const totalCompletedCount = bookings.reduce((total, booking) => total + (booking.Bookings_Completed_Count || 0), 0);
        const totalEarlyCheckinCount = bookings.reduce((total, booking) => total + (booking.Bookings_Early_Checkin_Count || 0), 0);
        const totalLateCheckinCount = bookings.reduce((total, booking) => total + (booking.Bookings_Late_Checkin_Count || 0), 0);

    

        let totalDonut1 = totalNoShowCount + totalAutoCancelled + totalCancelled + totalCompletedCount;
        let totalDonut2 = totalEarlyCheckinCount + totalLateCheckinCount;
        let totalDonut3 = totalCompletedCount;

        // Calculate percentages based on counts
        const percentageNoShow = (totalNoShowCount / totalDonut1) * 100;
        const percentageAutoCancelled = (totalAutoCancelled / totalDonut1) * 100;
        const percentageCancelled = (totalCancelled / totalDonut1) * 100;
        const percentageCompleted = (totalCompletedCount / totalDonut1) * 100;
        const earlyCheckinCompleted = (totalEarlyCheckinCount / totalDonut2) * 100;
        const earlyLateCheckinCount = (totalLateCheckinCount / totalDonut2) * 100;

        // Calculate the sum of percentages
        const sumPercentages = (Math.round((percentageNoShow + percentageAutoCancelled + percentageCancelled + percentageCompleted) * 100) / 100);
        const checkInPercentage = (Math.round((earlyCheckinCompleted + earlyLateCheckinCount) * 100) / 100);

        // Adjust percentages only if the sum exceeds 100
        const adjustmentFactor = sumPercentages > 100 ? 100 / sumPercentages : 1;
        const checkInadjustmentFactor = checkInPercentage > 100 ? 100 / checkInPercentage : 1;

        tempPie1.push({name: "No Show", value: Number((percentageNoShow * adjustmentFactor).toFixed(0)),count: totalNoShowCount });
        tempPie1.push({name: "Auto Cancelled",value: Number((percentageAutoCancelled * adjustmentFactor).toFixed(0)),count: totalAutoCancelled });
        tempPie1.push({name: "Cancelled",value: Number((percentageCancelled * adjustmentFactor).toFixed(0)),count: totalCancelled });
        tempPie1.push({name: "Completed",value: Number((percentageCompleted * adjustmentFactor).toFixed(0)),count: totalCompletedCount });

        tempPie2.push({ name: "Early Checkin", value: Number((earlyCheckinCompleted * checkInadjustmentFactor).toFixed(0)), count: totalEarlyCheckinCount })
        tempPie2.push({ name: "Late Checkin", value: Number((earlyLateCheckinCount * checkInadjustmentFactor).toFixed(0)), count: totalLateCheckinCount })

        tempPie3.push({ name: "Completed", value: Number((percentageCompleted * adjustmentFactor).toFixed(0)), count: totalCompletedCount })

        this.setState({
            dataPie1: tempPie1,
            dataPie1Value: totalDonut1,
            dataPie2: tempPie2,
            dataPie2Value: totalDonut2,
            dataPie3: tempPie3,
            dataPie3Value: totalDonut3,
        });

        // getTodayOverviewBookings
        apiResponseErrorNumber++
        this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true })
        dateFormatting = today.toLocaleDateString();
        
        bookings.forEach(item => 
        {
            const responseDate = DateTime.fromISO(item.Date);
            const formattedResponseDateStartsWithMonth = `${responseDate.month < 10 ? '0' + responseDate.month : responseDate.month}/${responseDate.day < 10 ? '0' + responseDate.day : responseDate.day}/${responseDate.year}`;
            const formattedResponseDateStartsWithDay = `${responseDate.day < 10 ? '0' + responseDate.day : responseDate.day}/${responseDate.month < 10 ? '0' + responseDate.month : responseDate.month}/${responseDate.year}`;
        
            let matchingDateData;
            if(navigator.language === "en-US")
            {
                matchingDateData = tempGraph.find(dateItem => dateItem.name === formattedResponseDateStartsWithMonth);
            } else {
                matchingDateData = tempGraph.find(dateItem => dateItem.name === formattedResponseDateStartsWithDay);
            }
        
            if (matchingDateData) 
            {
                matchingDateData.NoShow += item.Bookings_No_Show_Count;
                matchingDateData.Cancelled += item.Bookings_Cancelled_Count;
                matchingDateData.AutoCancelled += item.Bookings_Auto_Cancelled_Count;
                matchingDateData.Completed += item.Bookings_Completed_Count;
            }
        });
        
        this.setState({ dataGraph: tempGraph });
        this.setState({selectedDate: ""});
        this.setState({ isLoading: false });
    }

    private async barChartClicked(e: CategoricalChartState): Promise<void>
    {
        if (e !== null)
        {
            let bardate = " - " + e.activeLabel;
            if (this.state.selectedDate === bardate)
            {
                this.setState({
                    selectedDate: ""
                })
                await this.loadCharts();
            } else
            {
                let piedata = this.state.donutChartData;
                let tempPie1: IPieChart[] = [];
                let tempPie2: IPieChart[] = [];
                let tempPie3: IPieChart[] = [];

                if(e.activeLabel)
                {
                this.setState({
                    selectedDate: bardate
                })
                const result = piedata.find(item =>
                {
                    const itemDate = DateTime.fromISO(item.Date);
                    const formattedResponseDateStartsWithMonth = `${itemDate.month < 10 ? '0' + itemDate.month : itemDate.month}/${itemDate.day < 10 ? '0' + itemDate.day : itemDate.day}/${itemDate.year}`;
                    const formattedResponseDateStartsWithDay = `${itemDate.day < 10 ? '0' + itemDate.day : itemDate.day}/${itemDate.month < 10 ? '0' + itemDate.month : itemDate.month}/${itemDate.year}`;
                    let formattedItemDate;
                    if(navigator.language === "en-US")
                    {
                        formattedItemDate = formattedResponseDateStartsWithMonth;
                    } 
                    else 
                    {
                        formattedItemDate = formattedResponseDateStartsWithDay;
                    }
                    return e.activeLabel === formattedItemDate;
                });

                if(result)
                {
                    const totalDonut1 = result.Bookings_No_Show_Count + result.Bookings_Auto_Cancelled_Count + result.Bookings_Cancelled_Count + result.Bookings_Completed_Count;
                    const totalDonut2 = result.Bookings_Early_Checkin_Count + result.Bookings_Late_Checkin_Count;
                    const totalDonut3 = result.Bookings_Completed_Count;

                    tempPie1.push({ name: "No Show", value: Math.round((result.Bookings_No_Show_Count / totalDonut1) * 100), count: result.Bookings_No_Show_Count })
                    tempPie1.push({ name: "Auto Cancelled", value: Math.round((result.Bookings_Auto_Cancelled_Count / totalDonut1) * 100), count: result.Bookings_Auto_Cancelled_Count })
                    tempPie1.push({ name: "Cancelled", value: Math.round((result.Bookings_Cancelled_Count / totalDonut1) * 100), count: result.Bookings_Cancelled_Count })
                    tempPie1.push({ name: "Completed", value: Math.round((result.Bookings_Completed_Count / totalDonut1) * 100), count: result.Bookings_Completed_Count })
                    
                    tempPie2.push({ name: "Early Checkin", value: Math.round((result.Bookings_Early_Checkin_Count / totalDonut2) * 100), count: result.Bookings_Early_Checkin_Count })
                    tempPie2.push({ name: "Late Checkin", value: Math.round((result.Bookings_Late_Checkin_Count / totalDonut2) * 100), count: result.Bookings_Late_Checkin_Count })
            
            
                    tempPie3.push({ name: "Completed", value: Math.round((result.Bookings_Completed_Count / totalDonut3) * 100), count: result.Bookings_Completed_Count })

                    this.setState({
                        selectedDate: bardate,
                        dataPie1: tempPie1,
                        dataPie1Value: totalDonut1,
                        dataPie2: tempPie2,
                        dataPie2Value: totalDonut2,
                        dataPie3: tempPie3,
                        dataPie3Value: totalDonut3,
                    })
                } 
                else 
                {
                    tempPie1.push({ name: "No Show", value: 0, count: 0 })
                    tempPie1.push({ name: "Auto Cancelled", value: 0, count: 0 })
                    tempPie1.push({ name: "Cancelled", value: 0, count: 0 })
                    tempPie1.push({ name: "Completed", value: 0, count: 0 })
                    tempPie2.push({ name: "Early Checkin", value: 0, count: 0 })
                    tempPie2.push({ name: "Late Checkin", value: 0, count: 0 })
                    tempPie3.push({ name: "Completed", value: 0, count: 0 })
            
                    this.setState({
                        dataPie1: tempPie1,
                        dataPie1Value:0,
                        dataPie2: tempPie2,
                        dataPie2Value:0,
                        dataPie3: tempPie3,
                        dataPie3Value:0
                    })
                }
            }
        }
        }
    }

    public render(): JSX.Element
    {
        return (
            <>
                {(this.state.isLoading) && <LoadingOverlay />}
                <div className="page-height-exct-header">
                    <div className="rightPanel-main-content">
                        <div className="chart-panel">
                            <div className="chart-panel-section1">
                                <ChartPanelInfo
                                    chartHeading={this.labels.HubLabelBookingsOverview}
                                    iconSrc="/images/Sidebar_Icons/Light_theme/Calendar.svg"
                                />
                                <div className="topchart">
                                    {this.state.dataGraph.length !== 0 &&
                                        <BarChart data={this.state.dataGraph} onClick={e => this.barChartClicked(e)} height={400} width={this.state.widthchart} >
                                            <CartesianGrid vertical={false} />
                                            <XAxis stroke="var(--ui-text)" dataKey="name" />
                                            <YAxis stroke="var(--ui-text)" label={{ value: this.labels.HubLabelAllBookings, angle: -90, position: 'insideLeft', fill: 'var(--ui-text)' }} />
                                            <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                                            <Legend />
                                            <Bar dataKey="NoShow" name="No Show" stackId="a" fill="#F44336" />
                                            <Bar dataKey="Cancelled" name="Cancelled" stackId="a" fill="#FFC400" />
                                            <Bar dataKey="AutoCancelled" name="Auto Cancelled" stackId="a" fill="#FF7913" />
                                            <Bar dataKey="Completed" name="Completed" stackId="a" fill="#00C853" />
                                            <Bar dataKey="Active" name="Active" stackId="a" fill="#2962FF" />
                                        </BarChart>
                                    }
                                </div>
                            </div>
                            <div className="chart-panel-section2">
                                <div className="donutchart">
                                    <h3 className="donutcharttitle">{this.labels.HubLabelBookingStatus}{this.state.selectedDate}</h3>
                                    {(this.state.dataPie1.length !== 0 && this.state.dataPie1Value !== 0) ?
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPie1} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPie1.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORS1[index % this.state.COLORS1.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value={this.state.dataPie1Value} position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie1.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie1.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS1[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.count}</td>
                                                                    <td>{item.value}%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPieEmpty.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie1.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie1.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS1[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>0</td>
                                                                    <td>0%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="donutchart donutchartmargin">
                                    <h3 className="donutcharttitle">{this.labels.HubLabelCheckinStatus}{this.state.selectedDate}</h3>
                                    {(this.state.dataPie2.length !== 0 && this.state.dataPie2Value !== 0) ?
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPie2} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPie2.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORS2[index % this.state.COLORS2.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value={this.state.dataPie2Value} position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie2.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie2.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS2[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.count}</td>
                                                                    <td>{item.value}%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPieEmpty.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie2.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie2.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS2[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>0</td>
                                                                    <td>0%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="donutchart donutchartmargin">
                                    <h3 className="donutcharttitle">{this.labels.HubLabelCheckOutStatus}{this.state.selectedDate}</h3>
                                    {(this.state.dataPie3.length !== 0 && this.state.dataPie3Value !== 0) ?
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPie3} cx={80} cy={70} innerRadius="40" outerRadius="65" dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPie3.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORS3[index % this.state.COLORS3.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value={this.state.dataPie3Value} position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie3.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie3.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS3[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.count}</td>
                                                                    <td>{item.value}%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="div-flex">
                                            <div className="col-6">
                                                <PieChart width={250} height={200}>
                                                    <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                                                        {this.state.dataPieEmpty.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                                                        ))}
                                                        <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                                                    </Pie>
                                                </PieChart>
                                            </div>
                                            <div className="donutchartlegend">
                                                {this.state.dataPie3.length !== 0 &&
                                                    <table className="col-12 charttable">
                                                        <tbody>
                                                            {this.state.dataPie3.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS3[i] }}></div></td>
                                                                    <td>{item.name}</td>
                                                                    <td>0</td>
                                                                    <td>0%</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export interface IProps extends RouteComponentProps<IMatchParams>
{
}

export interface IMatchParams
{
    buildingId: string;
}

export interface IState
{
    isLoading: boolean;
    getDetailsApiResponseErrorLoading: boolean;
    buildingId: number;
    selectedDate: string;
    dataGraph: IBar[];
    donutChartData: IBookingSummaryData[];

    dataPieEmpty: IPieChart[];
    COLORSEMPTY: string[];

    dataPie1: IPieChart[];
    dataPie1Value: number;
    COLORS1: string[];

    dataPie2: IPieChart[];
    dataPie2Value: number;
    COLORS2: string[];

    dataPie3: IPieChart[];
    dataPie3Value: number;
    COLORS3: string[];

    widthchart: number;
    widthdonut: number;
    cxdonut: number;
}

export interface IBar
{
    name: string;
    NoShow: number;
    Cancelled: number;
    AutoCancelled: number;
    Completed: number;
    Active: number;
}

export interface IPieChart
{
    name: string;
    value: number;
    count: number;
}