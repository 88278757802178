import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../Common/TypeHelper";
import { ApiError } from "./ApiError";
import apis from "./apis";

export class BookingPartyRepository implements IBookingPartyRepository
{
    public async delete(nodeId: number, recordId: string): Promise<void>
    {
        try
        {
            const response = await axios.delete(apis.allApi + nodeId + "/BookingParties/" + recordId);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }

    }
}

export interface IBookingPartyRepository
{
    delete(nodeId: number, recordId: string): Promise<void>;
}

export interface IBookingParty
{
    Booking_End: string;
    Booking_Id: string;
    Booking_Participant_CheckedIn: number;
    Booking_Participant_Email: string;
    Booking_Participant_Name: string;
    Booking_Participant_Organisation: string;
    Booking_Participant_Type: number;
    Booking_Resource_Id: string;
    Booking_Start: string;
    Booking_Visitor: number;
    Node_Id: number;
    Record_Id: string;
}
