import TextField from "@mui/material/TextField";
import { appContext } from "../../../../AppContext";
import { IbssComponent } from "../../../../Components/IbssComponent";
import IbssAutocomplete from "../../../../Components/uicomponents/IbssAutocomplete";
import IbssDialog from "../../../../Components/uicomponents/IbssDialog";
import IbssTextField from "../../../../Components/uicomponents/IbssTextField";
import IbssButtonRedo from "../../../../Components/uicomponents/IbssButton";
import { ICreateV2Booking_BookingParty } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { IUser } from "../../../../Providers.Api/Users/UsersRepository";
import { ISpaceView } from "./EditBooking";

export default class AttendeesDialog extends IbssComponent<IProps, IState>
{
    private get labels() { return appContext().labels; }
    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            isShowModalAttendees: false,
            isVisitor: this.props.isVisitor,
            booking_Parties_Visitors_array: this.props.booking_Parties_Visitors_array,
            booking_Parties_array: [],
            allAttendees: [],
            clearTriggered: false,
            nonVisitorAttendeesData: {} as Attendee,
            visitorAttendeesData: {} as Attendee,
            isDisabled: true,
            selectedAttendee: "",
            userListAttendees: [],
            errors: '',
            alreadyPresentError: '',
            spacesData: this.props.spacesData,
        };
    }

    public componentDidUpdate(prevProps: IProps): void
    {
        if (this.props.isVisitor != prevProps.isVisitor)
        {
            this.setState({ isVisitor: this.props.isVisitor });
        }
        if (this.props.booking_Parties_array != prevProps.booking_Parties_array)
        {
            this.setState({ booking_Parties_array: this.props.booking_Parties_array });
        }
        if (this.props.booking_Parties_Visitors_array != prevProps.booking_Parties_Visitors_array)
        {
            this.setState({ booking_Parties_Visitors_array: this.props.booking_Parties_Visitors_array });
        }
        if (this.props.allAttendees != prevProps.allAttendees)
        {
            this.setState({ allAttendees: this.props.allAttendees });
        }
        if (this.props.isDisabled != prevProps.isDisabled)
        {
            this.setState({ isDisabled: this.props.isDisabled });
        }
        if (this.props.spacesData != prevProps.spacesData)
        {
            this.setState({ spacesData: this.props.spacesData });
        }
        if (this.props.show != prevProps.show)
        {
            this.handleShowHideAttendeeModal(this.props.show);
        }
    }

    private handleShowHideAttendeeModal(show: boolean): void
    {
        this.setState({
            isShowModalAttendees: show,
            isVisitor: false,
            nonVisitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1,
                backgroundColor: "",
            },
            visitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2,
                backgroundColor: "",
            },
        });
        this.props.onShowChange(show);
        this.props.onIsVisitorChange(false);
    }

    private handleTotalAddAttendee(): void
    {
        let attendees: Attendee[] = []
        if (!this.state.isVisitor && this.state.booking_Parties_Visitors_array.length === 0)
        {
            attendees = [...this.state.booking_Parties_array];
        }
        else
        {
            if (this.state.booking_Parties_array.length !== 0)
            {
                attendees = [...this.state.booking_Parties_array, ...this.state.booking_Parties_Visitors_array];
                this.setState({
                    allAttendees: attendees
                });
                this.props.onAllAttendeesChange(attendees);
            }
            else
            {
                attendees = [...this.state.booking_Parties_Visitors_array];
            }

        }
        let firstElement = attendees[0];
        firstElement = { ...firstElement, backgroundColor: '#4893CF' };

        attendees.shift();
        attendees.unshift(firstElement);

        this.setState({
            allAttendees: attendees
        });
        this.props.onAllAttendeesChange(attendees);

        if (this.state.clearTriggered === true && this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0)
        {
            this.setState({
                allAttendees: [],
                clearTriggered: false,
            });
            this.props.onAllAttendeesChange([]);
        }
        this.handleShowHideAttendeeModal(false);
    }

    private handleChangeVisitor(): void
    {
        const isVisitor = !this.state.isVisitor;
        this.setState({
            isVisitor: isVisitor,
            nonVisitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1,
                backgroundColor: "",
            },
            visitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2,
                backgroundColor: "",
            },
            isDisabled: true,
            errors: '',
            alreadyPresentError: '',
        });
        this.props.onIsVisitorChange(isVisitor);
    };

    private handleAddAttendee(): void
    {
        if (!this.state.isVisitor)
        {
            const newBooking_Parties_array: Attendee[] = [...this.state.booking_Parties_array]
            newBooking_Parties_array.push(this.state.nonVisitorAttendeesData)
            this.setState({
                booking_Parties_array: newBooking_Parties_array,
                selectedAttendee: '',
                nonVisitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: false,
                    type: 1,
                    backgroundColor: "",
                },
                isDisabled: true,
            });
            this.props.onBookingPartiesChange(newBooking_Parties_array);
        }
        else
        {
            const newBooking_Parties_Visitors_array: Attendee[] = [...this.state.booking_Parties_Visitors_array]
            newBooking_Parties_Visitors_array.push(this.state.visitorAttendeesData)
            this.setState({
                booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
                visitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: true,
                    type: 2,
                    backgroundColor: "",
                },
                isDisabled: true,
            });
            this.props.onBookingPartyVisitorsChange(newBooking_Parties_Visitors_array);
        }
    }

    private handleSelectNonVisitorAttendees(e: any, obj: any): void
    {
        if (obj)
        {
            const HubLabelalreadyPresent = this.state.booking_Parties_array.find((item: any) => item.Email === obj.email);
            if (HubLabelalreadyPresent !== undefined)
            {
                this.setState({
                    alreadyPresentError: this.labels.HubLabelalreadyPresent,
                    isDisabled: true,
                    selectedAttendee: '',
                });
            } else
            {
                this.setState({
                    nonVisitorAttendeesData: {
                        email: obj.email,
                        name: obj.firstName + ' ' + obj.lastName,
                        organisation: obj.companyName,
                        resourceId: null,
                        visitor: false,
                        type: 1,
                        backgroundColor: "",
                    },
                    isDisabled: false,
                })
            }
        }
    }

    private handleInputChangeVisitorAttendeesData(e: any): void
    {
        const { type, value } = e.target;
        let errors: string = '';
        if (type === 'email')
        {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );

            if (!pattern.test(value))
            {
                // update error massage in errors.email  object
                errors = this.labels.HubLabelInvalidEmailAddress;;
            }
            // updated state for email errors
            if (errors === "")
            {
                this.setState({
                    isDisabled: false
                })
            } else
            {
                this.setState({
                    isDisabled: true
                })
            }
            this.setState({
                errors: errors,
            });
        }

        if (e.target.name == 'firstname')
        {
            this.setState(
                {
                    visitorAttendeesData:
                    {
                        ...this.state.visitorAttendeesData,
                        name: e.target.value + ' ' + (this.state.visitorAttendeesData.name?.split(' ').length > 1 ? this.state.visitorAttendeesData.name?.split(' ')[1] : '')
                    },
                })
        }
        else if (e.target.name == 'lastname')
        {
            this.setState(
                {
                    visitorAttendeesData:
                    {
                        ...this.state.visitorAttendeesData,
                        name: this.state.visitorAttendeesData.name?.split(' ')[0] + ' ' + e.target.value
                    },
                })
        }
        else
        {
            this.setState({
                visitorAttendeesData: {
                    ...this.state.visitorAttendeesData,
                    [e.target.name]: e.target.value
                },
            })
        }
    }

    private handleRemoveAttendees(item: Attendee): void
    {
        const newBooking_Parties_array: Attendee[] = this.state.booking_Parties_array.filter((element: Attendee) => element.email !== item.email)
        const newBooking_Parties_Visitors_array: Attendee[] = this.state.booking_Parties_Visitors_array.filter((element: Attendee) => element.email !== item.email)

        this.setState({
            booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
            booking_Parties_array: newBooking_Parties_array,
            clearTriggered: true,
        });
        this.props.onBookingPartyVisitorsChange(newBooking_Parties_Visitors_array);
        this.props.onBookingPartiesChange(newBooking_Parties_array);
    }

    private handleClearAllAttendees(): void
    {
        if (this.state.booking_Parties_array.length == 0 && this.state.booking_Parties_Visitors_array.length == 0)
        {
            return;
        }
        this.setState({
            booking_Parties_Visitors_array: [],
            booking_Parties_array: [],
            clearTriggered: true,
            isDisabled: true,
        });
        this.props.onBookingPartyVisitorsChange([]);
        this.props.onBookingPartiesChange([]);
    }

    private inputChangeTitle(e: any): void
    {
        const changeEvent = { [e.target.name]: e.target.value } as Pick<IState, keyof IState>
        this.setState(changeEvent);

        if (e.target.value.length <= 3)
        {
            this.setState({
                alreadyPresentError: '',
            })
        }

        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value).then((res) =>
            {
                let newArray = res && res[0] && res.map(item =>
                {
                    let newItem = { ...item, label: '' }
                    newItem.label = item.displayName ? item.displayName : item.email;
                    newItem.displayName = item.displayName ? item.displayName : item.email;
                    newItem.lastName = newItem.lastName ? newItem.lastName : ""
                    newItem.firstName = newItem.firstName ? newItem.firstName : ""

                    return newItem
                })

                this.setState({
                    userListAttendees: (newArray && newArray[0]) ? newArray : []
                })
            }).catch((error: any) => { });
        } else
        {
            this.setState({
                userListAttendees: []
            })
        }
    }

    public render(): JSX.Element
    {
        const isButtonDisabled = (this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && this.state.clearTriggered === false;
        return (
            <IbssDialog
                open={this.state.isShowModalAttendees}
                fullWidth
                onClose={() => this.handleShowHideAttendeeModal(false)}
                header=
                {
                    <>
                        <label className="modal-heading">{this.labels.HubLabelAttendeeSelection}</label>
                    </>
                }
                content=
                {
                    <div>

                        <div className="d-flex justify-content-between">
                            <label className="lable-modal">{this.labels.HubButtonVisitorAccess}</label>
                            <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.handleChangeVisitor()} />
                        </div>

                        <hr className="m-0 mx-2" />

                        {!this.state.isVisitor &&
                            <div className="mt-2">
                                <div className="form-group mt-3">
                                    <label className="py-1">{this.labels.HubLabelnameEmailAddress}</label>
                                    <IbssAutocomplete
                                        id="free-solo-demo"
                                        className="mt-8"
                                        freeSolo
                                        options={this.state.userListAttendees}
                                        value={this.state.selectedAttendee}
                                        onChange={(e, obj) => this.handleSelectNonVisitorAttendees(e, obj)} //setFieldValue('search', obj)
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                className="form-control modal-attendees-form w-100 border-1"
                                                name="selectedAttendee"
                                                value={this.state.selectedAttendee}
                                                id="selectedAttendee"
                                                placeholder={this.labels.HubLabelNameOrEmailLabel}
                                                onBlur={(e) => { this.inputChangeTitle(e); }}
                                                onChange={(e) => { this.inputChangeTitle(e); }}
                                                size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            />}
                                    />
                                    <div className="text-danger">{this.state.alreadyPresentError}</div>
                                </div>
                            </div>
                        }
                        {
                            this.state.isVisitor &&
                            <>
                                <div className="form-group mt-3">
                                    <label className="py-1">{this.labels.HubLabelnameEmailAddress + ' *'}</label>
                                    <div>
                                        <IbssTextField
                                            className="form-control modal-attendees-form w-100 mt-8"
                                            variant="outlined"
                                            type="email"
                                            name="email"
                                            value={this.state.visitorAttendeesData.email}
                                            onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                            placeholder={this.labels.HubLabelNameOrEmailLabel}
                                            size="small"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </div>
                                    <div className="text-danger">
                                        {this.state.errors}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="py-1">{this.labels.HubLabelFirstName + ' *'}</label>
                                    <div>
                                        <IbssTextField
                                            className="form-control modal-attendees-form w-100 mt-8"
                                            variant="outlined"
                                            type="text"
                                            name="firstname"
                                            value={this.state.visitorAttendeesData.name?.split(' ')[0]}
                                            onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                            placeholder={this.labels.HubLabelFirstNamePlaceholderLabel}
                                            size="small"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="py-1">{this.labels.HubLabelLastName + ' *'}</label>
                                    <div>
                                        <IbssTextField
                                            className="form-control modal-attendees-form w-100 mt-8"
                                            variant="outlined"
                                            type="text"
                                            name="lastname"
                                            value={this.state.visitorAttendeesData.name?.split(' ')[1]}
                                            onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                            placeholder={this.labels.HubLabelLastNamePlaceholder}
                                            size="small"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="py-1">{this.labels.HubLabelCompany}</label>
                                    <div>
                                        <IbssTextField
                                            className="form-control modal-attendees-form w-100 mt-8"
                                            variant="outlined"
                                            type="text"
                                            name="organisation"
                                            value={this.state.visitorAttendeesData.organisation}
                                            onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                            placeholder={this.labels.HubLabelCompanyNamePlaceholder}
                                            size="small"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="form-group">
                            <IbssButtonRedo
                                variant='contained'
                                onClick={() => this.handleAddAttendee()}
                                disabled={
                                    this.state.isDisabled ||
                                    (this.state.isVisitor &&
                                        (
                                            this.state.visitorAttendeesData.name?.split(' ')[0] == undefined ||
                                            this.state.visitorAttendeesData.name?.split(' ')[1] == undefined ||
                                            this.state.visitorAttendeesData.name?.split(' ')[0]?.length < 1 ||
                                            this.state.visitorAttendeesData.name?.split(' ')[1]?.length < 1
                                        )
                                    )
                                }
                            >
                                {this.labels.HubButtonAddattendeebutton}
                            </IbssButtonRedo>
                        </div>
                    </div>
                }
                footer=
                {
                    <div className="w-100">
                        {this.state.booking_Parties_array.length > 0 && (
                            <>
                                <div className="px-58 form-group">
                                    <label className="py-1 lable-modal-bold">{this.labels.HubLabelAttendees}</label>
                                    <div className="d-flex flex-wrap">
                                        {this.state.booking_Parties_array.map((item: Attendee) => (
                                            <>
                                                <p className="attendee-name mr-3 my-6px">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.handleRemoveAttendees(item)}></i></p>
                                            </>
                                        ))}
                                    </div>
                                </div>
                                <hr className="attendee-hr" />
                            </>
                        )}
                        {this.state.booking_Parties_Visitors_array.length > 0 && (
                            <>
                                <div className="px-58 form-group mt-1">
                                    <label className="py-1 lable-modal-bold">{this.labels.HubLabelVisitorAttendee}</label>
                                    <div className="d-flex flex-wrap">
                                        {this.state.booking_Parties_Visitors_array.map((item: Attendee) => (
                                            <p className="attendee-name mr-3 my-6px">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.handleRemoveAttendees(item)}></i></p>
                                        ))}
                                    </div>
                                </div>
                                <hr className="attendee-hr" />
                            </>
                        )}
                        {
                            this.state.booking_Parties_array.length > this.state.spacesData[0]?.Space_Capacity &&
                            <div className="text-danger px-58 form-group mt-1">
                                {this.labels.HubLabelCapacityReachedPt1}
                                <br />
                                {this.labels.HubLabelCapacityReachedPt2}
                            </div>
                        }
                        <div className="px-58 mt-1 pw-100 d-flex justify-content-end">
                            <a href="#" className="clear-attendees" onClick={() => this.handleClearAllAttendees()} >{this.labels.HubButtonClearAttendees}</a>
                            <IbssButtonRedo
                                className='ml-2'
                                variant='contained'
                                disabled={isButtonDisabled}
                                onClick={() => this.handleTotalAddAttendee()}
                            >
                                {this.labels.HubButtonSave}
                            </IbssButtonRedo>
                        </div>
                    </div>
                }
            />
        );
    }
}

export interface IProps
{
    show: boolean;
    onShowChange: (show: boolean) => void;
    isVisitor: boolean;
    onIsVisitorChange: (isVisitor: boolean) => void;
    booking_Parties_array: Attendee[];
    onBookingPartiesChange: (bookingParties: Attendee[]) => void;
    booking_Parties_Visitors_array: Attendee[];
    onBookingPartyVisitorsChange: (bookingPartyVisitors: Attendee[]) => void;
    allAttendees: Attendee[];
    onAllAttendeesChange: (allAttendees: Attendee[]) => void;
    isDisabled: boolean;
    spacesData: ISpaceView[];
}

export interface IState
{
    isShowModalAttendees: boolean;
    isVisitor: boolean;
    booking_Parties_array: Attendee[];
    booking_Parties_Visitors_array: Attendee[];
    allAttendees: Attendee[];
    clearTriggered: boolean;
    nonVisitorAttendeesData: Attendee;
    visitorAttendeesData: Attendee;
    isDisabled: boolean;
    selectedAttendee: string;
    userListAttendees: IUser[];
    errors: string;
    alreadyPresentError: string;
    spacesData: ISpaceView[];
}

export class Attendee
{
    email: string;
    name: string;
    organisation: string;
    resourceId: string | null;
    visitor: boolean;
    type: number;
    backgroundColor = "";

    constructor(data: ICreateV2Booking_BookingParty)
    {
        this.email = data.Booking_Participant_Email;
        this.name = data.Booking_Participant_Name;
        this.organisation = data.Booking_Participant_Organisation;
        this.resourceId = data.Booking_Resource_Id;
        this.visitor = data.Booking_Visitor;
        this.type = data.Booking_Participant_Type;
    }
}

