import { Component } from "react";
import { appContext, IPartialAppState } from "../AppContext";
import { MenuItem } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/material';
import { Paper} from '@mui/material';

export default class BuldingSelector extends Component<any, IState>
{
    private labels = appContext().labels;
    private apiCache = appContext().apiCache;
    private appState = appContext().state;

    constructor(props: any)
    {
        super(props);
        this.state =
        {
            options: new Array<IOption>(),
            selectedBuildingId: this.appState.buildingId,
            lightModeTheme: this.appState.lightModeTheme,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ selectedBuildingId: i.buildingId }));
        const buildings = await this.apiCache.getBuildings();
        const options = buildings.map(i => ({ value: parseInt(i.id), label: i.name }) as IOption);
        this.setState({ options: options });
        this.appState.subscribe(this, i => this.appStateChanged(i));
    }

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
        if (state.buildingId !== undefined)
        {
            this.setState({ selectedBuildingId: state.buildingId });
        }
        if (state.lightModeTheme !== undefined)
        {
            this.setState({ lightModeTheme: state.lightModeTheme });
        }
    }

    private handleClick(selectedBuildingId: number): void
    {
        const building = this.state.options.find(i => i.value === selectedBuildingId);
        const buildingId = building?.value ?? 0;
        const buildingName = building?.label ?? "";
        this.appState.set({ buildingId: buildingId, buildingName: buildingName });
    }

    public render(): JSX.Element
    {
        return (
            <Box>
                {/* if there is one or fewer building options, do not show buildingSelector component */}
                {this.state.options.length > 1 && <Box 
                    sx={{
                    padding: '16px 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    backgroundColor: (theme) => theme.palette.grey[400]
                }}>
                    <b>{this.labels.HubLabelSelectBuilding}:</b>
                    <Paper elevation={0}>
                        <TextField
                            select 
                            id="buildingDropdown"
                            value={this.state.selectedBuildingId}
                            sx={{
                                width: 210, 
                                '& legend': { display: 'none' }, 
                                '& fieldset': { top: 0 },
                            }}>
                            {this.state.options.sort((a,b) => a.label > b.label ? 1 : -1).map(option => 
                                <MenuItem 
                                    key={option.value} 
                                    value={option.value} 
                                    onClick={()=> this.handleClick(option.value)}>
                                    {option.label}
                                </MenuItem>
                            )}
                        </TextField>
                    </Paper>
                </Box>}
            </Box>
        );
    }
}

interface IOption 
{
    label: string,
    value: number,
}

interface IState
{
    options: IOption[];
    selectedBuildingId: number;
    lightModeTheme: boolean;
}
