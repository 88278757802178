import React from "react";
import { Component } from "react";
import "../../../App.css";
import { appContext } from "../../../AppContext";
import IbssOnBoardingPopup from "../../../Components/uicomponents/IbssOnboardingPopup";
import OnBoardingSetUp from "./OnBoardingSetUp";
import OnBoardingBuildingPreferences from "./OnBoardingBuidingPreferences";
import OnBoardingFloorPreferences from "./OnBoardingFloorPreferences";
import OnBoardingWorkingDay from "./OnBoardingWorkingDay";
import OnBoardingSetUpaPIN from "./OnBoardingSetUpaPIN";
import OnBoardingColleagueFinding from "./OnBoardingColleagueFinding";
import OnBoardingNotified from "./OnBoardingNotified";
import { InputOption } from "../../../Components/uicomponents/InputSelectBox";
import Helper, { getWkngDaysBySelectedBuilding } from "../../../Common/Helper";
import LoadingOverlay from "../../../Components/LoadingOverlay";
import { getUserPrefrenceJosn } from "../../../Common/UserPreferenceJSON";
import { IUserPreferences } from "../../../Providers.Api/UserPreferenceRepository";

export default class OnBoarding extends Component<IOnBoarding, State>
{

    private labels = appContext().labels;
    private alert = appContext().alert;
    private userPreferences = appContext().userPreferencesService;
    private local = appContext().localStorageProvider;
    private maxSteps = 7;

    constructor(props: IOnBoarding) {
        super(props);

        this.state = new State();
    }



    public componentDidMount() {
        this.setState({ isOpen: this.props.open });

        // building and floor option
        const buildingOption = Helper.makeDropdownOptions(Helper.getAllBuildingsData(), "Name", "Node_Id");

        // get user preferences
        const userPreferences = this.local.getUserPreferences();
        const buildingFirstOption = parseInt(buildingOption[0].value) as number | null
        const buildingId = userPreferences?.SearchPrefs?.DefaultBuilding == null || userPreferences?.SearchPrefs?.DefaultBuilding == 1 ? buildingFirstOption : userPreferences?.SearchPrefs?.DefaultBuilding;
        const floorId = userPreferences?.Nodes?.find((i: any) => i.NodeId == buildingId)?.DefaultFloor?.toString() ?? "";
        // configure buildings input
        this.state.buildings.options = buildingOption;
        this.state.buildings.value = buildingId;
        this.state.buildings.onChange = (value => this.buildingChanged(value));

        // configure floors input
        this.state.floors.options = Helper.makeDropdownOptions(Helper.getFloorsFromSelectedBuilding(buildingId), "Node_Name", "Node_Id");
        this.state.floors.value = parseInt(this.state.floors.options[0].value);
        this.state.floors.onChange = (value => this.floorChanged(value));

        // office start time and end time
        const getStrtDate: any = getWkngDaysBySelectedBuilding(buildingId);
        let Occ_Wkng_Hrs_Stt = getStrtDate?.Occ_Wkng_Hrs_Stt.split(":");
        let Hrs_Stt = Occ_Wkng_Hrs_Stt[0] + ":" + Occ_Wkng_Hrs_Stt[1];
        let Occ_Wkng_Hrs_Stp = getStrtDate?.Occ_Wkng_Hrs_Stp.split(":");
        let Hrs_Stp = Occ_Wkng_Hrs_Stp[0] + ":" + Occ_Wkng_Hrs_Stp[1];

        // set start time
        const myStart = new Date();
        const myStartDateTime = myStart.toISOString();
        const myStartDate = myStartDateTime.split('T')[0];

        let startTime = "09:30"
        if (userPreferences?.WorkingHoursPrefs?.UserStartTime != "date" && userPreferences?.WorkingHoursPrefs?.UserStartTime != "" && userPreferences?.WorkingHoursPrefs?.UserStartTime != null && userPreferences?.WorkingHoursPrefs?.UserStartTime != undefined) {
            startTime = userPreferences.WorkingHoursPrefs.UserStartTime;
        }
        else if (userPreferences?.WorkingHoursPrefs?.UserStartTime == "" || userPreferences?.WorkingHoursPrefs?.UserStartTime == null || userPreferences?.WorkingHoursPrefs?.UserStartTime == undefined) {
            startTime = Hrs_Stt;
        }
        const myStartTime = new Date(myStartDate + 'T' + startTime);

        // set end time
        const myEnd = new Date();
        const myEndDateTime = myEnd.toISOString();
        const myEndDate = myEndDateTime.split('T')[0];

        let endTime = "18:30";
        if (userPreferences?.WorkingHoursPrefs?.UserEndTime != "date" && userPreferences?.WorkingHoursPrefs?.UserEndTime != "" && userPreferences?.WorkingHoursPrefs?.UserStartTime != null && userPreferences?.WorkingHoursPrefs?.UserStartTime != undefined) {
            endTime = userPreferences.WorkingHoursPrefs.UserEndTime;
        }
        else if (userPreferences?.WorkingHoursPrefs?.UserEndTime == "" || userPreferences?.WorkingHoursPrefs?.UserEndTime == null || userPreferences?.WorkingHoursPrefs?.UserEndTime == undefined) {
            endTime = Hrs_Stp;
        }
        const myEndTime = new Date(myEndDate + 'T' + endTime);

        this.setState({
            startTime: myStartTime,
            endTime: myEndTime,
            PIN: userPreferences?.SecurityPrefs?.Pin,
            dataPrivacy: userPreferences?.PrivacyPrefs?.ShareLocation === 0 ? false : true,
            userPrefrences: userPreferences,
        });

    }



    private buildingChanged(option: any): void {
        this.state.buildings.value = option.target.value;
        this.state.floors.options = Helper.makeDropdownOptions(Helper.getFloorsFromSelectedBuilding(option.target.value), "Node_Name", "Node_Id");
        this.state.floors.value = parseInt(this.state.floors.options[0].value);
        this.setState({ buildings: this.state.buildings, floors: this.state.floors });
    }

    private floorChanged(option: any): void {
        this.state.floors.value = option.target.value;
        this.setState({ floors: this.state.floors });
    }

    public async incrementActiveStep() {
        await this.setState(prevState => {
            return { activeStep: prevState.activeStep + 1 }
        });
    }

    public decrementActiveStep() {
        this.setState(prevState => {
            return { activeStep: prevState.activeStep - 1 }
        });
    }

    handleSubmitBuilding = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            SearchPrefs:
            {
                DefaultBuilding: this.state.buildings.value,
                DefaultSearchResults: this.state.userPrefrences.SearchPrefs.DefaultSearchResults,
            },
        };

        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }


    handlesubmitFloor = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            Nodes:
                [
                    {
                        NodeId: this.state.buildings.value,
                        DefaultFloor: this.state.floors.value
                    }
                ]
        };

        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    handleSubmitWorkingDay = async () => {
        const startDate = new Date(this.state.startTime as Date);
        const endDate = new Date(this.state.endTime as Date);

        const startTime = `${(startDate.getHours() < 10 ? '0' : '') + startDate.getHours()}:${(startDate.getMinutes() < 10 ? '0' : '') + startDate.getMinutes()}`;
        const endTime = `${(endDate.getHours() < 10 ? '0' : '') + endDate.getHours()}:${(endDate.getMinutes() < 10 ? '0' : '') + endDate.getMinutes()}`

        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            WorkingHoursPrefs:
            {
                UserStartTime: startTime,
                UserEndTime: endTime
            },
        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public async onStartTimeChange(e: any) {
        await this.setState({
            startTime: e.$d,
        });
    }

    public onEndTimeChange(e: any) {
        this.setState({
            endTime: e.$d
        });
    }

    public PinChange = (value: string) => {
        this.setState({ PIN: value })
    }

    handleSubmitSetPIN = async () => {
        const payload: IUserPreferences =
        {
            ...this.state.userPrefrences,
            SecurityPrefs: {
                Pin: this.state.PIN,
            }
        }
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public closingPopup(): void {
        this.props.closePopup();
    }

    public change = (e: any) => {
        this.setState({
            dataPrivacy: e.target.checked
        });
    };

    public submitDataPrivacy = async () => {
        const payload: IUserPreferences = {
            ...this.state.userPrefrences,
            PrivacyPrefs:
            {
                ShareLocation: this.state.dataPrivacy === false ? 0 : 1,
            },

        };
        try {
            const res = await this.userPreferences.update(payload);
            if (res) {
                this.props.closePopup();
            }
        }
        catch { }
    }

    public render(): JSX.Element {
        const popups = [
            {
                image: "onb-1 1",
                content: <OnBoardingSetUp incrementActiveStep={this.incrementActiveStep.bind(this)} />,
                header: <></>

            },
            {
                image: "building selector",
                content: (
                    <OnBoardingBuildingPreferences
                        incrementActiveStep={this.incrementActiveStep.bind(this)}
                        buildings={this.state?.buildings}
                        userPrefrences={this.state.userPrefrences}
                    />
                ),
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div>
                    {/* <div className="modal-headerIco-lg" onClick={() => this.handleSubmitBuilding()} >&times;</div> */}
                </div>
                </>
            },
            {
                image: "floor preference",
                content: (
                    <OnBoardingFloorPreferences
                        incrementActiveStep={this.incrementActiveStep.bind(this)}
                        floors={this.state.floors}
                        selectedBuilding={this.state.buildings.value}
                        userPrefrences={this.state.userPrefrences}
                    />
                ),
                header: (
                    <>
                        <div className="d-flex justify-content-between">
                            <div
                                onClick={() => this.decrementActiveStep()}
                                className="fa fa-angle-left modal-headerIco-lg"
                            ></div>
                            {/* <div
                                className="modal-headerIco-lg"
                                onClick={() => this.handlesubmitFloor()}
                            >
                                &times;
                            </div> */}
                        </div>
                    </>
                )

            },
            {
                image: "your working day",
                content: (<OnBoardingWorkingDay
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                    startTime={this.state.startTime}
                    endTime={this.state.endTime}
                    onStartTimeChange={this.onStartTimeChange.bind(this)}
                    onEndTimeChange={this.onEndTimeChange.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />),
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={(e) => this.handleSubmitWorkingDay()} >&times;</div> */}
                </div>
                </>
            },
            {
                image: "pin setup",
                content: <OnBoardingSetUpaPIN
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                    PIN={this.state.PIN}
                    PinChange={this.PinChange.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={() => this.handleSubmitSetPIN()}>&times;</div> */}
                </div></>
            },
            {
                image: "location services",
                content: <OnBoardingColleagueFinding
                    incrementActiveStep={this.incrementActiveStep.bind(this)}
                    dataPrivacy={this.state.dataPrivacy}
                    change={this.change.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between"> <div onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></div> 
                    {/* <div className="modal-headerIco-lg" onClick={() => this.submitDataPrivacy()} >&times;</div> */}
                </div></>
            },
            {
                image: "notification",
                content: <OnBoardingNotified
                    closePopup={this.closingPopup.bind(this)}
                    userPrefrences={this.state.userPrefrences}
                />,
                header: <><div className="d-flex justify-content-between">  <span onClick={() => this.decrementActiveStep()} className="fa fa-angle-left modal-headerIco-lg"></span>
                </div></>
            }
        ]

        return (<>

            {(this.state.isLoading || this.state.isLoadingToSave) && <LoadingOverlay />}
            <IbssOnBoardingPopup
                isOpen={this.props.open}
                header={popups[this.state.activeStep].header}
                maxSteps={this.maxSteps}
                activeStep={this.state.activeStep}
                image={popups[this.state.activeStep].image}
                content={popups[this.state.activeStep].content}
            />
        </>);

    }
}

const mapStateToProps = (state: any) => {
    return {
        lightModeTheme: state.lightModeTheme,
        mainPageTitle: state.mainPageTitle,

    };
};


class State {
    private get labels() { return appContext().labels; }
    public isLoadingToSave = false;
    public isLoading = false;
    public isOpen = false;
    public activeStep = 0;
    public startTime: any = "";
    public endTime: any = "";
    public PIN: string = "";
    public dataPrivacy = false;
    public buildings =
        {
            name: "DefaultBuilding",
            id: "DefaultBuilding",
            inputLabel: this.labels.dataUserPrefWorkplaceDefBuilding_L,
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };
    public floors =
        {
            name: "DefaultFloor",
            id: "DefaultFloor",
            inputLabel: this.labels.dataUserPrefWorkplaceDefFloor_L,
            options: new Array<InputOption>(),
            value: 0 as number | null,
            onChange: (value: string) => { }
        };

    public userPrefrences = getUserPrefrenceJosn();
}

export interface IOnBoarding {
    open: boolean;
    closePopup: () => void;
}

