import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { DateHelper } from "../../Common/DateHelper";
import { BookingFilter2 } from "./BookingRepository";
import { IRawPagedResponse, PagedResponse } from "../Models";
import { BookingStatus } from "./BookingRepository";
import { ApiHelper } from "../ApiHelper";
import { ODataQuery } from "../ODataQuery";

export class GetV2BookingsEndpoint implements IGetV2BookingsEndpoint
{
    public async execute(query: ODataQuery, startTime?: DateTime, endTime?: DateTime): Promise<PagedResponse<Booking[]>>
    {
        try
        {
            const startTimeAsString = startTime?.toUTC()?.toString() ?? "";
            const endTimeAsString = endTime?.toUTC()?.toString() ?? "";
            const bookingEnd = endTimeAsString && endTimeAsString.length > 0 ? `and Booking_End lt datetime'${endTimeAsString}'` : '';

            query.select = Booking;
            const queryString = query.toODataString(' and ', `Booking_Start ge datetime'${startTimeAsString}' ${bookingEnd}`);

            const response = await axios.get<IRawPagedResponse<Booking[]>>(`${apis.allApiv2}${query.nodeId}/Bookings?${queryString}`);
            response.data.Value.forEach(i =>
            {
                i.Booking_Start = DateTime.fromISO(i.Booking_Start as unknown as string);
                i.Booking_End = DateTime.fromISO(i.Booking_End as unknown as string);
                i.Booking_Completed_Time = (i.Booking_Completed_Time == null ? DateHelper.null() : DateTime.fromISO(i.Booking_Completed_Time as unknown as string));
                i.Booking_Early_Checkin = (i.Booking_Early_Checkin == null ? DateHelper.null() : DateTime.fromISO(i.Booking_Early_Checkin as unknown as string));
                i._CreatedAt = DateTime.fromISO(i._CreatedAt as unknown as string);
            });

            const pagedResponse = PagedResponse.fromRawResponse(response.data);
            return pagedResponse;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetV2BookingsEndpoint
{
    execute(query: ODataQuery, startTime?: DateTime, endTime?: DateTime): Promise<PagedResponse<Booking[]>>;
}

export class Booking
{
    public Node_Id = 0;
    public Booking_Id = "";
    public Booking_Name = "";
    public Booking_Start = DateHelper.null();
    public Booking_End = DateHelper.null();
    public Booking_Completed_Time = DateHelper.null();
    public Booking_Early_Checkin = DateHelper.null();
    public Booking_Status: BookingStatus = "";
    public Booking_Owner_Name = "";
    public Booking_Owner_Email = "";
    public Booking_IsActive = 0;
    public Booking_IsCancelled = 0;
    public Booking_IsCheckedIn = 0;
    public Booking_AutoCheckIn = 0;
    public Booking_IsEarly_Checkin = 0;
    public Booking_IsLate_Checkin = 0;
    public Booking_Share_Loc = 0;
    public Booking_IsApproved = 0;
    public Space_Id = "";
    public Space_Name = "";
    public _CreatedAt = DateHelper.null();
    public _CreatedBy = "";
}
