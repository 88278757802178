import axios, { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { TypeHelper } from "../../Common/TypeHelper";
import { ApiError } from "../ApiError";
import { appContext } from "../../AppContext";
import apis from "../apis";
import { IV2GetResponse } from "../Models";
import { DateHelper } from "../../Common/DateHelper";
import { ICreateV2Booking_CostCodeAllocation } from "./CreateV2BookingEndpoint";

export class UpdateBookingCostCodesEndpoint implements IUpdateBookingCostCodesEndpoint
{
    public async execute(nodeId: number, bookingId: string, data: IUpdateCostCodeAllocation): Promise<string>
    {
        try
        {
            const response = await axios.put(apis.allApiv2 + nodeId + '/bookings/' + bookingId, data);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateBookingCostCodesEndpoint
{
    execute(nodeId: number, bookingId: string, data: IUpdateCostCodeAllocation): Promise<string>;
}

export interface IUpdateCostCodeAllocation
{
    Cost_Code_Allocation: ICreateV2Booking_CostCodeAllocation[]
}
