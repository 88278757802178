import axios, { AxiosResponse } from "axios";
import Helper from "../../Common/Helper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IBookingPolicy } from "./BookingPolicyRepository";

export class UpdateBookingPolicyEndpoint implements IUpdateBookingPolicyEndpoint
{
    public async execute(policy: IBookingPolicy): Promise<void>
    {
        const updatedPolicy = Helper.convertPropertyNamesToCamelCase({
            ...policy,
            booking_Policy:
            {
                booking_Policy_Description: policy.Booking_Policy_Object.Booking_Policy_Description,
                approvalPolicies: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.ApprovalPolicies),
                arrivalPolicies: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.ArrivalPolicies),
                bookingSlots:
                {
                    ...Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots),
                    bookingDuration: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots.BookingDuration),
                    bookableTime: policy.Booking_Policy_Object.BookingSlots.BookableTime.map(x => Helper.convertPropertyNamesToCamelCase(x)),
                    excludedDates: policy.Booking_Policy_Object.BookingSlots.ExcludedDates.map(x => Helper.convertPropertyNamesToCamelCase(x)),
                    bookingStart: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots.BookingStart),
                    bookingEnd: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots.BookingEnd),
                    bookingHorizon: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots.BookingHorizon),
                    bookingRecurrence: Helper.convertPropertyNamesToCamelCase(policy.Booking_Policy_Object.BookingSlots.BookingRecurrence),
            }
            }
        });

        try
        {
            await axios.put(`${apis.dataEntryApiV2}${policy.Node_Id}/BookingPolicies/${policy.Booking_Policy_Id}`, updatedPolicy);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateBookingPolicyEndpoint
{
    execute(policy: IBookingPolicy): Promise<void>;
}
