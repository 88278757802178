import React, { Component } from 'react'
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { Autocomplete, Box, Grid, Paper, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { RouterProps } from 'react-router';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AddIcon from '@mui/icons-material/Add';
import FlatwareIcon from '@mui/icons-material/Flatware';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DateTime, Duration } from 'luxon';
import { IPartialAppState, appContext } from '../../../AppContext';
import IbssActionButton, { IActionButton } from '../../../Components/uicomponents/IbssActionButton';
import IbssDataGrid from '../../../Components/uicomponents/IbssDataGrid';
import IbssSvgIcon from '../../../Components/uicomponents/IbssSvgIcon';
import apis from '../../../Providers.Api/apis';
import { IUserList } from '../../../Providers.Api/Models';
import { IDispatch, IPropsFromState } from '../../../redux/Interfaces';
import { dateConverter, getBuildingTimeZoneByNodeId } from '../../../Common/Helper';
import IbssDialog from '../../../Components/uicomponents/IbssDialog';
import IbssInputDropDown from '../../../Components/uicomponents/IbssInputDropDown';
import IbssButtonRedo from '../../../Components/uicomponents/IbssButton';
import IbssPageHeader from '../../../Components/uicomponents/IbssPageHeader';
import IbssFilter from '../../../Components/uicomponents/IbssFilter';
import IbssAutocomplete from '../../../Components/uicomponents/IbssAutocomplete';
import Spinner from '../../../Components/Spinner';
import IbssTextField from '../../../Components/uicomponents/IbssTextField';
import { DateHelper } from '../../../Common/DateHelper';
import { ComponentHelper } from '../../../Common/ComponentHelper';
import IbssChip from '../../../Components/uicomponents/IbssChip';
import { ITasksFilter, CateringTask } from '../../../Providers.Api/Tasks/TaskRepository';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export default class CateringOrders extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private get apiCache() {return appContext().apiCache; }

    
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);
    private buildingTimeZone: string;

    private readonly actionType =
    {
        resolve: "resolve",
        setInProgress: "set-in-progress",
        assign: "assign",
        cancel: "cancel",
    };

    constructor(props: IProps)
    {
        super(props);
        this.buildingTimeZone = getBuildingTimeZoneByNodeId(this.session.getBuildingId())

        this.state =
        {
            loading: false,
            taskList: [],
            isCreateRight: false,
            isAssignRight: false,
            isCancelRight: false,
            isResolveRight: false,
            isSetInProgressRight: false,
            buildingId: this.appState.buildingId,
            startDate: DateHelper.now().toJSDate(),
            endDate: DateHelper.now().toJSDate(),
            openUserModal: false,
            openMessageModal: false,
            message: "",
            selectedTaskIds: [],
            actionType: "",
            daysFilter: this.labels.HubLabelToday,
            dateDisabled: true,
            searchTerm: "",
            openFilterModal: false,
            statusValue: this.labels.HubLabelAny,
            disableCancel: true,
            disableAssign: true,
            disableResolve: true,
            disableSetInProgress: true,
            userList: [],
            selectedUser: { label: "", userName: "" },
            selectedTaskOwnerObject: { label: "", email: "" },
        }
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.subscribe(this, i => this.appStateChanged(i));
        const today = this.state.startDate;
        const payload: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(today, this.labels.HubLabelStart),
            endDate: this.fromDateToString(today, this.labels.HubLabelEnd),
        };
        this.setRights();
        this.loadTasks(payload);
    }

    public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
        if(prevState.buildingId != this.state.buildingId)
        {
            const today = this.state.startDate;
            const payload: IFilter =
            {
                buildingId: this.state.buildingId,
                startDate: this.state.startDate.toISOString(),
                endDate: this.state.endDate.toISOString(),
            };
            this.loadTasks(payload);
        }
      }

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
      const { history, match }: any = this.props;
        if (state.buildingId != undefined)
        {
            this.setState({ buildingId: state.buildingId });
            history.push(match.path.replace(":buildingid", state.buildingId))
        }
    }

    private fromDateToString(date: Date, type: string): string
    {
        if (type === this.labels.HubLabelStart)
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(this.buildingTimeZone).endOf('day').toUTC().toISO();
        }
    }

    private setRights(): void
    {
        const rights = this.local.getIbssRightList();

        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Create") > -1)
        {
            this.setState({ isCreateRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Assign") > -1)
        {
            this.setState({ isAssignRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Cancel") > -1)
        {
            this.setState({ isCancelRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("SetInProgress") > -1)
        {
            this.setState({ isSetInProgressRight: true });
        }
        if (rights && rights.ADMINPORTAL && rights.ADMINPORTAL.Tasks && rights.ADMINPORTAL.Tasks.indexOf("Resolve") > -1)
        {
            this.setState({ isResolveRight: true });
        }
    }

    private async loadTasks(payload: IFilter): Promise<void>
    {
        try
        {
            this.setState({ loading: true });

            const tasksFilter: ITasksFilter =
            {
                status: payload.statusValue ?? undefined,
                owner: payload.owner ?? undefined,
                taskCategory: 'Catering',
                taskDueFrom: DateTime.fromISO(payload.startDate),
                taskDueTo: DateTime.fromISO(payload.endDate),
            };
            const response = await this.apiClient.tasks.getTasks(CateringTask, payload.buildingId, tasksFilter);

            if (!response)
            {
                return;
            }

            const spaces = await this.apiCache.getSpacesByBuilding(this.state.buildingId);
            let tasks = response.map(item =>
            {
                const diff = DateTime.fromISO(`${(item.Task_Event_Time).slice(0, 19)}`).diff(DateTime.fromISO(`${(item.CreatedAt).slice(0, 19)}`)).toFormat("mm");
                const hours = Duration.fromMillis(+diff).as('hours');
                const duration = Duration.fromObject({ hours: hours });
                const minuteDiff = duration.minutes % 60;
                const timeDiff = duration.hours;
                const resolutionTime = `${timeDiff} hrs ${minuteDiff === 0 ? "" : minuteDiff} ${minuteDiff === 0 ? "" : "min"}`;
                const space = spaces.find(space => space.Space_Id === item.Space_Id);

                return ({
                    id: item.Task_Id,
                    Task_MinutesRemaining: resolutionTime,
                    Task_Space_Name: space?.Space_Name ?? "",
                    ...item
                }) as CateringTask;
            });

            this.setState({ taskList: tasks, loading: false });
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    private filterTasks(searchTerm: string): CateringTask[]
    {
        const filteredTasks = this.state.taskList.filter(task =>
        {
            let key: keyof CateringTask;
            for (key in task)
            {
                if (task[key]?.toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return filteredTasks;
    }

    private async dateRangeDropdownChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().toJSDate(),
                    endDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('week').toJSDate(),
                    endDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    endDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('month').toJSDate(),
                    endDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setStateAsync(
                {
                    startDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    endDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setStateAsync({ dateDisabled: false });
        }

        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        this.loadTasks(filterObject);
    }

    private dateChanged(e: Date, type: string): void
    {
        if (type === this.labels.HubLabelStart)
        {
            this.setState({ startDate: e });
        }
        else
        {
            this.setState({ endDate: e });
        }

        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: type === this.labels.HubLabelStart ? this.fromDateToString(e, this.labels.HubLabelStart) : this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: type === this.labels.HubLabelEnd ? this.fromDateToString(e, this.labels.HubLabelEnd) : this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        this.loadTasks(filterObject);
    }

    private async resolveClicked(): Promise<void>
    {
        this.setState({
            openMessageModal: true,
            actionType: this.actionType.resolve,
        });
    }

    private async setInProgressClicked(): Promise<void>
    {
        this.setState({ loading: true });
        const filterObject: IFilter =
        {
            buildingId: this.state.buildingId,
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)
        };

        await this.state.selectedTaskIds.forEach((id, index) =>
            this.apiClient.tasks.setStatusToInProgress(this.appState.buildingId, id.toString()).then(res =>
            {
                if (index + 1 == this.state.selectedTaskIds.length)
                    this.alert.show("", res);
                this.loadTasks(filterObject)
            })
        );
    }

    private async assignClicked(): Promise<void>
    {
        this.setState({
            openUserModal: true,
            actionType: this.actionType.assign,
        });
    }

    private async cancelClicked(): Promise<void>
    {
        this.setState({
            openMessageModal: true,
            actionType: this.actionType.cancel,
        });
    }

    private messagePopupChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({ message: e.target.value });
    }

    private userPopupChanged(e: React.SyntheticEvent<Element, Event>, newValue: IUserList): void
    {
        if (newValue)
        {
            this.setState({ selectedUser: { label: newValue.label, userName: newValue.userName } })
        } else
        {
            this.setState({ selectedUser: { label: "", userName: "" } })
        }
    }

    private messageModalCancelled(): void
    {
        this.setState({
            openMessageModal: !this.state.openMessageModal,
            message: ""
        });
        this.setState({ loading: false });
    }

    private filterModalCancelled(): void
    {
        this.setState({
            openFilterModal: !this.state.openFilterModal,
            statusValue: this.labels.HubLabelAssigned,
        });
        this.setState({ loading: false });
    }

    private userSelectionModalCancelled(): void
    {
        this.setState({
            openUserModal: !this.state.openUserModal,
            selectedUser: { label: "", userName: "" }
        });
        this.setState({ loading: false });
    }

    private async messageModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        let message = "";

        let payload: IFilter =
        {
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            buildingId: this.state.buildingId,
        };

        let apiPayload = { "TaskResolveDescription": this.state.message };
        const promises = this.state.selectedTaskIds.map(async id =>
        {
            let response;
            if (this.state.actionType == this.actionType.resolve)
            {
                response = await apis.updateTaskForMyTaskToResolveButton(this.state.buildingId, id, apiPayload);
            }
            if (this.state.actionType == this.actionType.cancel)
            {
                response = await apis.updateTasksCancelByTaskId(this.state.buildingId, id, apiPayload);
            }

            if (response)
            {
                message = response.data.Message;
                return Promise.resolve(response);
            }
            else
            {
                return Promise.reject(new Error(`Failed to delete resource with ID ${id}`));
            }
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show("", message);
                this.loadTasks(payload);
                this.messageModalCancelled();
            })
            .catch(() =>
            {
                this.setState({ loading: false });
                this.messageModalCancelled();
            });
    }

    private async filterModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        
        let payload: IFilter =
        {
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            buildingId: this.state.buildingId,
            statusValue: this.state.statusValue == 'Any' ? '' : this.state.statusValue,
            owner: this.state.selectedTaskOwnerObject.email,
        };

        this.setState({ openFilterModal: false });
        await this.loadTasks(payload);
    }

    private async userSelectionModalSubmitted(): Promise<void>
    {
        this.setState({ loading: true });
        let message = "";
        let apiPayload = {};
        let payload: IFilter =
        {
            startDate: this.fromDateToString(this.state.startDate, this.labels.HubLabelStart),
            endDate: this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd),
            buildingId: this.state.buildingId,
        };

        if (this.state.actionType === this.actionType.assign)
        {
            apiPayload = { "resolver_id": this.state.selectedUser.userName };
        }

        const promises = this.state.selectedTaskIds.map(async (id: number | string) =>
        {
            const response = await apis.updateTasksAssignByTaskId(this.state.buildingId, id, this.state.actionType, apiPayload);
            if (response)
            {
                message = response.data.Message;
                return Promise.resolve(response);
            }
            else
            {
                return Promise.reject(new Error(`Failed to delete resource with ID ${id}`));
            }
        });

        Promise.all(promises)
            .then(() =>
            {
                this.alert.show("", message);
                this.loadTasks(payload);
                this.userSelectionModalCancelled();
            })
            .catch(() =>
            {
                this.setState({ loading: false });
                this.userSelectionModalCancelled();
            });
    }

    private tasksSelected(selection: GridRowSelectionModel): void
    {
        this.setState({ selectedTaskIds: selection, disableSetInProgress: true, disableResolve: true, disableAssign: true, disableCancel: true });

        let selectedTasks: (CateringTask | undefined)[] = [];
        selection.forEach((id: number | string) =>
        {
            const taskData = this.filterTasks(this.state.searchTerm).find((obj: CateringTask) => { return obj.Task_Id === id });
            selectedTasks.push(taskData)
        });

        const selectedTaskStatuses = selectedTasks.map(x => x?.Task_Status)

        // enable the Resolve button
        if (selectedTaskStatuses.length > 0 && selectedTaskStatuses.every(status => status == "In Progress") && this.state.isResolveRight)
        {
            this.setState({ disableResolve: false });
        }
        // enable the In Progress button
        if (selectedTaskStatuses.length > 0 && selectedTaskStatuses.every(status => status == "Assigned") && this.state.isSetInProgressRight)
        {
            this.setState({ disableSetInProgress: false });
        }
        // enable the Assign button
        if (selectedTaskStatuses.length > 0 && selectedTaskStatuses.every(status => status == "Active") && this.state.isAssignRight)
        {
            this.setState({ disableAssign: false });
        }
        // enable the Cancel button
        if (selectedTaskStatuses.length > 0 && selectedTaskStatuses.every(status => status != "Resolved" && status != "Cancelled") && this.state.isCancelRight)
        {
            this.setState({ disableCancel: false });
        }
    }

    private emailNameChanged(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element> | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value).then((res) =>
            {
                const newArray: IUserDetails[] = res && res[0] && res.map(item =>
                {
                    let newItem = { ...item, label: '', oid: '', userName: '' }
                    newItem.label = item.displayName ? item.displayName : item.UserName;
                    newItem.oid = item.OID;
                    newItem.userName = item.UserName;
                    return newItem
                })

                this.setState({
                    userList: (newArray && newArray[0]) ? newArray : []
                })
            }).catch((error) => { });
        }
        else
        {
            this.setState({
                userList: []
            })
        }
    }

    private taskOwnerChanged(event: React.SyntheticEvent<Element, Event>, newValue: IUserDetails): void
    {
        if (newValue)
        {
            this.setState({
                selectedTaskOwnerObject: {
                    label: newValue.label,
                    email: newValue.email.toLowerCase(),
                },
            });
        } else
        {
            this.setState({ selectedTaskOwnerObject: { label: "", email: "" } });
        }
    };

    private taskOwnerTextChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    {
        this.setState({ [e.target.name]: e.target.value, updates: true } as any);
        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value)
                .then((res) =>
                {

                    const newArray =
                        res &&
                        res[0] &&
                        res.map(item =>
                        {
                            const newItem = { ...item, label: '', oid: '', userName: '' };
                            newItem.label = item.displayName ? item.displayName : item.email;
                            newItem.displayName = item.displayName
                                ? item.displayName
                                : item.email;
                            newItem.lastName = newItem.lastName ? newItem.lastName : "";
                            newItem.firstName = newItem.firstName ? newItem.firstName : "";
                            newItem.oid = item.OID;
                            newItem.userName = item.UserName;

                            return newItem;
                        });
                    this.setState({
                        userList: newArray && newArray[0] ? newArray : [],
                    });
                })
                .catch((error) =>
                {
                    console.log(error)
                });
        } else
        {
            this.setState({
                userList: [],
            });
        }
    };

    private viewOrderPath(e: any): void
    {
        this.props.history.push(`/operational-services-catering/${this.state.buildingId}/catering/${e.id}/view`);
    }
    
    private async exportTaskList(): Promise<void>
    {
        const filter: ITasksFilter =
        {
            taskCategory: 'Catering',
            status: this.state.statusValue === "Any" ? undefined : this.state.statusValue,
            taskDueFrom: DateTime.fromISO(this.fromDateToString(this.state.startDate, this.labels.HubLabelStart)).toUTC(),
            taskDueTo: DateTime.fromISO(this.fromDateToString(this.state.endDate, this.labels.HubLabelEnd)).toUTC(),
        };
        await this.apiClient.tasks.exportTasks(this.state.buildingId, filter);
    }

    private editPath(param: CateringTask): void
    {
        const { history } = this.props;
        history.push(`/operational-services-catering/${this.state.buildingId}/catering/${param?.Task_Id}/edit`);
    }

    private renderDetailIcon(row: CateringTask): React.ReactNode
    {
            return (
                <>
                    <IbssSvgIcon className="pointer" onClick={() => this.viewOrderPath(row)}>
                        <RemoveRedEyeIcon />
                    </IbssSvgIcon>
                </>
            )
    }

    public render(): JSX.Element
    {
        const { history } = this.props

        const dataGridHeader: GridColDef[] =
            [
                {
                    headerName: this.labels.HubLabelDate,
                    field: "Task_Due_Date",
                    width: 150,
                    valueGetter: (params) => DateTime.fromISO(params.row.Task_Due_Date).toLocaleDateTimeString(),
                },
                {
                    headerName: this.labels.HubLabelServiceTime,
                    field: "Catering_Service_Time",
                    width: 150,
                    valueGetter: (params) => DateTime.fromISO(params.row.Catering_Service_Time).toLocaleDateTimeString(),
                },
                {
                    headerName: this.labels.HubLabelCleaningTime,
                    field: "Catering_Clearing_Time",
                    width: 150,
                    valueGetter: (params) => DateTime.fromISO(params.row.Task_Due_Date).toLocaleDateTimeString(),
                },
                {
                    headerName: this.labels.HubLabelTaskOwner,
                    field: "Task_Owner",
                    width: 150,
                },
                {
                    headerName: this.labels.HubLabelAtt,
                    field: "Catering_Attendees",
                    width: 150,
                },
                {
                    headerName: this.labels.HubLabelStatus,
                    field: "Task_Status",
                    width: 150,
                    renderCell: (params) => this.renderTaskOverAllStatus(params.row)
                },
                {
                    headerName: this.labels.HubLabelNotes,
                    field: "Catering_Notes",
                    minWidth: 250,
                    flex: 1,
                },
                {
                    headerName: this.labels.HubLabelAction,
                    field: "",
                    width: 150,
                    renderCell: (params) => this.renderDetailIcon(params.row)
                },
                {
                    headerName: this.labels.HubLabelSpace,
                    field: "Task_Space_Name",
                    width: 150,
                },
                {
                    headerName: this.labels.HubLabelTaskId,
                    field: "Task_Id",
                    width: 150,
                },
            ];

        // filter status options
        let filterStatusOption =
            [
                { label: this.labels.HubLabelAssigned, value: "Assigned" },
                { label: this.labels.HubLabelActive, value: "Active" },
                { label: this.labels.HubLabelUnassigned, value: "Unassigned" },
                { label: this.labels.HubLabelInProgress, value: "In Progress" },
                { label: this.labels.HubLabelCancelled, value: "Cancelled" },
                { label: this.labels.HubLabelResolved, value: "Resolved" },
                { label: this.labels.HubLabelReAllocation, value: "Awaiting re-allocation" },
            ].sort((a, b) => {
                var textA = a.label.toUpperCase();
                var textB = b.label.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            filterStatusOption = [{ label: this.labels.HubLabelAny, value: "Any" }, ...filterStatusOption]

        // Action buttons
        const actionButtons: IActionButton[] =
            [
                {
                    label: this.labels.HubButtonResolve,
                    icon: (
                        <IbssSvgIcon>
                            <CheckCircleOutlineTwoToneIcon />
                        </IbssSvgIcon>
                    ),
                    color: "info",
                    onClick: () => this.resolveClicked(),
                    disabled: this.state.disableResolve
                },
                {
                    label: this.labels.HubButtonInProgress,
                    icon: (
                        <IbssSvgIcon>
                            <FlatwareIcon />
                        </IbssSvgIcon>
                    ),
                    color: "inherit",
                    onClick: () => this.setInProgressClicked(),
                    disabled: this.state.disableSetInProgress,
                },
                {
                    label: this.labels.HubButtonAssign,
                    icon: (
                        <IbssSvgIcon>
                            <PersonAddAlt1OutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "warning",
                    onClick: () => this.assignClicked(),
                    disabled: this.state.disableAssign,
                },
                {
                    label: this.labels.HubButtonCancel,
                    icon: (
                        <IbssSvgIcon>
                            <CancelOutlinedIcon />
                        </IbssSvgIcon>
                    ),
                    color: "error",
                    onClick: () => this.cancelClicked(),
                    disabled: this.state.disableCancel
                },
            ];

        const filteredTasks = this.filterTasks(this.state.searchTerm);

        return (
            <>
                <div>
                    {/* Modal for Resolve */}
                    <IbssDialog
                        open={this.state.openMessageModal}
                        onClose={() => this.messageModalCancelled()}
                        fullWidth
                        header={this.labels.HubLabelMessageBox}
                        content=
                        {
                            <>
                                <label>{this.labels.HubLabelMessage}</label>
                                <IbssTextField
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    type="text"
                                    name="message"
                                    className="mt-1"
                                    fullWidth
                                    value={this.state.message}
                                    onChange={(e) => { this.messagePopupChanged(e) }}
                                />
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => this.messageModalCancelled()}
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color='primary'
                                    variant='contained'
                                    onClick={() => this.messageModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {/* User Selection Modal */}
                    <IbssDialog
                        open={this.state.openUserModal}
                        onClose={() => this.userSelectionModalCancelled()}
                        header={this.labels.HubLabelUserSelection}
                        fullWidth
                        content=
                        {
                            <>
                                <label>{this.labels.HubLabelnameEmailAddress}</label>
                                <IbssAutocomplete
                                    freeSolo
                                    id="free-solo-demo"
                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    className="mt-1"
                                    options={this.state.userList}
                                    value={this.state.selectedUser.label}
                                    onChange={(e, newValue) => this.userPopupChanged(e, newValue as IUserList)}
                                    PaperComponent={({ children }) => (
                                        <Paper style={{ fontSize: "14px", fontFamily: "Source Sans Pro", fontWeight: "bold" }}>{children}</Paper>
                                    )}
                                    renderInput={(params) =>
                                        <IbssTextField
                                            {...params}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            name="selectedUser"
                                            value={this.state.selectedUser.label}
                                            id="selectedUser"
                                            onBlur={(e) => { this.emailNameChanged(e) }}
                                            onChange={(e) => { this.emailNameChanged(e) }}
                                        />}
                                />
                            </>
                        }
                        footer=
                        {
                            <>
                                <div className="d-flex justify-content-center w-100">
                                    <IbssButtonRedo
                                        className="mr-2"
                                        color='secondary'
                                        variant='outlined'
                                        onClick={() => this.userSelectionModalCancelled()}
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant='contained'
                                        onClick={() => this.userSelectionModalSubmitted()}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </div>
                            </>
                        }
                    />
                    {/* Filter Modal */}
                    <IbssDialog
                        open={this.state.openFilterModal}
                        onClose={() => this.filterModalCancelled()}
                        fullWidth
                        header={this.labels.HubLabelFilter}
                        content=
                        {
                            <>
                                <div className="row">
                                    <IbssInputDropDown
                                        options={filterStatusOption}
                                        id="statusSelection"
                                        value={this.state.statusValue}
                                        inputLabel={this.labels.HubLabelStatus}
                                        fullWidth
                                        onChange={(event: SelectChangeEvent<string>) => { this.setState({ statusValue: event.target.value }) }}
                                    />
                                </div>
                                <div className="row">
                                    <div className="form-input-box">
                                        <label className="form-input-box-label visit-filter-label">
                                            {this.labels.HubLabelTaskOwner}
                                        </label>
                                        <Autocomplete
                                            freeSolo
                                            className="form-input-box-autocomplete auto-text"
                                            options={this.state.userList}
                                            PaperComponent={({ children }) => (
                                                <Paper
                                                    style={{
                                                        fontSize: "14px",
                                                        fontFamily: "Source Sans Pro",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {children}
                                                </Paper>
                                            )}
                                            value={this.state.selectedTaskOwnerObject.label}
                                            onChange={(e, newValue) => this.taskOwnerChanged(e, newValue as IUserDetails)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="input-box model-textbox"
                                                    name="selectedUser"
                                                    id="selectedUser"
                                                    placeholder="&#8230;"
                                                    value={this.state.selectedTaskOwnerObject.label}
                                                    onBlur={e => this.taskOwnerTextChanged(e)}
                                                    onChange={e => this.taskOwnerTextChanged(e)}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                        footer=
                        {
                            <>
                                <IbssButtonRedo
                                    onClick={() => this.filterModalCancelled()}
                                    color="secondary"
                                    variant="outlined"
                                >
                                    {this.labels.HubButtonCancel}
                                </IbssButtonRedo>
                                <IbssButtonRedo
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => this.filterModalSubmitted()}
                                >
                                    {this.labels.HubLabelOk}
                                </IbssButtonRedo>
                            </>
                        }
                    />
                    {this.state.loading && <Spinner />}
                    <div className="rightPanel-main-content">
                        <div className="table-panel">
                                <IbssPageHeader
                                    pageTitle={this.labels.HubMenuCateringOrders}
                                    daysFilter={this.state.daysFilter}
                                    todayChanged={(e) => this.dateRangeDropdownChanged(e)}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    dateDisabled={this.state.dateDisabled}
                                    dateChanged={(event, value) => this.dateChanged(event, value)}
                                    additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                                />                                        
                                <IbssFilter
                                    showExport={true}
                                    searchTerm={this.state.searchTerm}
                                    searchTermChanged={(event) => this.setState({ searchTerm: event.target.value })}
                                    filterButtonClicked={() => this.setState({ openFilterModal: true })}
                                    exportButtonClicked={() => { this.exportTaskList() }}
                                />
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                <IbssActionButton
                                    buttons={actionButtons}
                                />
                                </Box>
                                <Box sx={{mt:1}}>
                            <IbssDataGrid
                                checkboxSelection
                                columns={dataGridHeader}
                                disableRowSelectionOnClick
                                rows={filteredTasks}
                                onRowSelectionModelChange={(rowSelectionModel: GridRowSelectionModel) => this.tasksSelected(rowSelectionModel)}
                                onRowClick={(e) => this.viewOrderPath(e)}
                                className='view-booking-policies-data-grid'
                            />
                            </Box>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    public renderTaskOverAllStatus(row: CateringTask): React.ReactNode
    {
        const taskStatus = row.Task_Status;
        if (taskStatus === "Cancelled")
        {
            return <IbssChip label={this.labels.HubLabelCancelled} sx={{
                backgroundColor: 'var(--ui-error)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (taskStatus === "Active")
        {
            return <IbssChip label={this.labels.HubLabelActive} sx={{
                backgroundColor: 'var(--ui-warn-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (taskStatus === "In Progress")
        {
            return <IbssChip label={this.labels.HubLabelInProgress} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (taskStatus === "Assigned")
        {
            return <IbssChip label={this.labels.HubTabAssigned} sx={{
                backgroundColor: 'var(--ui-success-pastel)',
                color: "var(--ui-text )",
            }} />;
        }
        else if (taskStatus === "Resolved")
        {
            return <IbssChip label={this.labels.HubLabelResolved} sx={{
                backgroundColor: 'var(--ui-success)',
                color: "var(--ui-light-text)",
            }} />;
        }
        else if (taskStatus === "Unassigned")
        {
            return <IbssChip label={this.labels.HubLabelUnassigned} sx={{
                backgroundColor: 'var(--ui-primary-pastel )',
                color: "var(ui-text)",
            }} />;
        }
        else if (taskStatus === "Awaiting re-allocation")
        {
            return <IbssChip label={this.labels.HubLabelReAllocation} sx={{
                backgroundColor: 'var(--ui-primary-pastel )',
                color: "var(ui-text)",
            }} />;
        }
        else
        {
            return <p>-</p>;
        }
    }
}

interface IProps extends RouterProps, IPropsFromState, IDispatch
{
}

interface IState
{
    loading: boolean;
    taskList: CateringTask[];
    isCreateRight: boolean;
    isAssignRight: boolean;
    isResolveRight: boolean;
    isCancelRight: boolean;
    isSetInProgressRight: boolean;
    buildingId: number;
    startDate: Date;
    endDate: Date;
    openUserModal: boolean;
    openMessageModal: boolean;
    message: string;
    selectedTaskIds: GridRowSelectionModel;
    actionType: string;
    daysFilter: string;
    dateDisabled: boolean;
    searchTerm: string;
    openFilterModal: boolean;
    statusValue: string;
    disableCancel: boolean;
    disableAssign: boolean;
    disableResolve: boolean;
    disableSetInProgress: boolean;
    userList: IUserDetails[];
    selectedUser: { label?: string, userName: string },
    selectedTaskOwnerObject: ILabelEmail,
}

export interface IFilter
{
    buildingId: number;
    startDate: string;
    endDate: string;
    statusValue?: string;
    host?: string;
    owner?: string;
}

interface ILabelEmail
{
    label?: string;
    email: string;
}

interface IUserDetails
{
    label?: string;
    companyName: string;
    displayName: string;
    email: string;
    firstName: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}

