import { Component } from "react";
import { Card } from "react-bootstrap";
import { appContext } from "../../AppContext";
import { Constants } from "../../Common/Constants";
import "./RecurringSpaceCard.scss";
import { Icons } from '../../Common/AllsvgIcons';
import IbssSvgIcon from "../uicomponents/IbssSvgIcon";

export default class SpaceCard extends Component<IProps, any> 
{
    private labels = appContext().labels;

    constructor(props: IProps) {
        super(props);
    }

    public render(): JSX.Element {
        let props = this.props;
        return (
            <div className={`mb-1 recurring-space-card ${props.selected ? 'card-selected' : ''}`} onClick={() => { }}>
                <Card className="content-card">
                    <div style={{ position: 'relative' }}>
                        {
                            props.bestFit &&
                            <div className="best-fit-label">{this.labels.HubLabelBestFit}</div>
                        }
                        <Card.Img className="card-image" style={{ borderRadius: props.selected ? '0px' : '5px 5px 0px 0px' }} src={props.imageUrl ? props.imageUrl : Constants.imagePaths.get(props.spaceType)} />
                        <div className="mr-2 ml-2">
                            <div className="d-flex">
                                <h3 className="space-label" style={{ width: '80%', margin: '10px 8px' }}>{props.spaceName ? props.spaceName : props.spaceType}</h3>
                                <div style={{ width: '50%' }} className="align-icons-text">
                                    <span className="recurring-icon"><img src="/images/Sidebar_Icons/Active_icons/Attendee Icon.svg" /></span>
                                    <div style={{ width: '20%', alignSelf: 'center' }}>{props.capacity}</div>
                                </div>
                            </div>
                            <div className="space-info">
                                <div className="d-flex m-2 mb-3">
                                    <div style={{ width: '50%', fontSize: '11px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.FloorIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginTop: '3px' }}>{props.floor}</div>
                                    </div>
                                    <div style={{ width: '50%', fontSize: '11px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.ZoneIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginTop: '3px' }}>{props.zone}</div>
                                    </div>
                                </div>
                                <div className="d-flex m-2">
                                    <div style={{ width: '50%', fontSize: '15px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.calenderIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginLeft: '-5px' }}>{props.dateRange}</div>
                                    </div>
                                    <div style={{ width: '50%', fontSize: '17px' }} className="align-icons-text">
                                        <IbssSvgIcon fontSize='inherit' className="mr-2">
                                            {Icons.TimeIcon}
                                        </IbssSvgIcon>
                                        <div className="headings" style={{ marginLeft: '-5px' }}>{props.timeRange}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <div className="m-2 d-flex" style={{ justifyContent: 'center' }}>
                    <div className="mr-3 availability-text">{this.labels.HubLabelAvailability}</div>
                    <div className="ml-3 occurences">{props.availableOccurrences}/{props.requestedOccurrence} {this.labels.HubLabelOccurrences}</div>
                </div>
            </div>
        );
    }
}

export interface IProps {
    imageUrl: string;
    spaceName: string;
    spaceType: string;
    capacity: string;
    floor: string;
    zone: string;
    dateRange: string;
    timeRange: string;
    requestedOccurrence: string;
    availableOccurrences: string;
    selected: boolean;
    bestFit: boolean;
}


