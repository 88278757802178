import React, { Component } from 'react';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { appContext } from '../../../AppContext';
import Helper, { getAllBuildingsData, getBuildingNameUsingBuildingId, getFloorNameUsingFloorAndBuildingId, getMondayOfCurrentWeek, periodType } from '../../../Common/Helper';
import IbssDatePicker from '../../../Components/uicomponents/Datepicker/IbssDatePicker';
import IbssFormControl from '../../../Components/uicomponents/IbssFormControl';
import IbssInputDropDown, { optionObject } from '../../../Components/uicomponents/IbssInputDropDown';
import { getCalendarView } from '../../../Common/Helper';
import { queryObject } from './SpaceAnalyticsHeatMaps';
import { HEAT_MAP_FILTER_CRITERIA } from '../../../app/constants';
import { DateTime } from "luxon";
import { DateHelper } from '../../../Common/DateHelper';


type HeatmapsSearchState = {
  isOpen: boolean,
  buidlingOptions: optionObject[],
  selectedBuildingOption: string | number,
  floorOptions: optionObject[],
  selectedFloor: string,
  startDay: Date | null | string,
  periodStartDate: Date | null,
  periodEndDate: Date | string,
  periodType: number,
};

const currentDate = new Date()

class SearchCriteriaHeatmaps extends Component<any, HeatmapsSearchState> {

  private labels = appContext().labels;
  private startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  private defaultMonthStartDate = DateTime.fromJSDate(this.startDate).toJSDate();

  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
      buidlingOptions: [],
      floorOptions: [],
      selectedBuildingOption: '',
      selectedFloor: '',
      startDay: '',
      periodStartDate: new Date(),
      periodEndDate: new Date(),
      periodType: 1,
    };
  };

  public componentDidMount(): void {

    const { heatMapFilterCriteria } = this.props
    const bldgOpts = this.makeBuildingOptions(getAllBuildingsData());
    const floorOpts = this.makeFloorOptions(Helper.getFloorsByBuildingId(heatMapFilterCriteria.buildingId));

    this.setState({
      buidlingOptions: bldgOpts,
      selectedBuildingOption: heatMapFilterCriteria.buildingId,
      floorOptions: floorOpts,
      selectedFloor: heatMapFilterCriteria.floorId,
      periodType: heatMapFilterCriteria.periodType,
      periodStartDate: heatMapFilterCriteria.periodStartDate,
      periodEndDate: heatMapFilterCriteria.periodEndDate,
    });
  };

  public makeBuildingOptions = (data: any) => {
    let options: any = [];
    data.map((obj: any) => {
      options.push({ label: obj["Name"], value: obj["Node_Id"] });
    });
    return options;
  };

  public makeFloorOptions = (data: any) => {
    let options: any = [];
    data.map((obj: any) => {
      options.push({ label: obj["Node_Name"], value: obj["Node_Id"] });
    });
    return options;
  };

  //onClick function for ClearSelection button
  public clearForm = () => {
    const bldgOpts = this.makeBuildingOptions(getAllBuildingsData());
    const floorOpts = this.makeFloorOptions(Helper.getFloorsByBuildingId(bldgOpts[0].value));
    this.setState({
      selectedBuildingOption: bldgOpts[0].value,
      selectedFloor: floorOpts[0].value,
      periodType: 1,
      periodStartDate: this.defaultMonthStartDate,
    });
  };

  //onClick function for update button 
  public async upDateSearchResults() {
    let filterData: any = {};
    let query: queryObject = {
      buildingId: '',
      floorNodeId: '',
      periodStartDate: '',
      periodEndDate: '',
      periodType: 1
    };

    if (this.state.selectedBuildingOption) {
      query = { ...query, buildingId: this.state.selectedBuildingOption };
      filterData = { ...filterData, buildingId: this.state.selectedBuildingOption, buildingName: getBuildingNameUsingBuildingId(this.state.selectedBuildingOption) };
    }
    if (this.state.selectedFloor) {
      query = { ...query, floorNodeId: this.state.selectedFloor };
      filterData = { ...filterData, floorId: this.state.selectedFloor, floorName: getFloorNameUsingFloorAndBuildingId(this.state.selectedBuildingOption, this.state.selectedFloor) };
    }
    if (this.state.periodType || this.state.periodType === 0) {
      query = { ...query, periodType: this.state.periodType };
      filterData = { ...filterData, periodType: this.state.periodType }
    }
    if (this.state.periodStartDate) {
      query = { ...query, periodStartDate: this.state.periodStartDate };
      filterData = { ...filterData, periodStartDate: this.state.periodStartDate };
    }
    if (this.state.periodEndDate) {
      query = { ...query, periodEndDate: this.state.periodEndDate };
      filterData = { ...filterData, periodEndDate: this.state.periodEndDate };
    }

    query = { ...query }
    await this.props.dispatch({ type: HEAT_MAP_FILTER_CRITERIA, payload: filterData });
    await this.props.updateSearchResults(query);
  };

  /*Disabled all dates except Monday dates*/
  public selectedMondays = (day: Date | DateTime) => {
    if (this.state.periodType === 0 && (day instanceof DateTime)) 
    {
      return day.weekday !== 1;
    }
    return false;
  };

  /* Start_Date & End_Date change as per selection */
  public handleStartDate = (e: Date) => {
    const startDay = e;
    if (this.state.periodType === 2) {
      this.setState({
        periodStartDate: startDay,
        periodEndDate: (new Date(new Date(e).getFullYear(), 11, 31))
      });
    } else if (this.state.periodType === 1) {
      this.setState({
        periodStartDate: startDay,
        periodEndDate: (new Date(new Date(e).getFullYear(), new Date(e).getMonth() + 1, 0)),
      });
    } else if (this.state.periodType === 0) {
      this.setState({
        periodStartDate: startDay,
        periodEndDate: (new Date(new Date(e).setDate(new Date(e).getDate() - new Date(e).getDay() + 1 + 6))),
      });
    } else if (this.state.periodType === 3) {
      this.setState({
        periodStartDate: startDay,
        periodEndDate: (new Date(new Date(e).setDate(new Date(e).getDate() + 1))),
      });
      this.setState({ periodStartDate: startDay });
    }
  };

  /* Period type selection changes Period Start/End Date*/
  public handlePeriodTypeChange = (e: SelectChangeEvent) => {
    this.setState({
      periodType: (parseInt(e.target.value)),
    });
    if (parseInt(e.target.value) === 1) {
      this.setState({
        periodEndDate: (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
        periodStartDate: (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
      })
    } else if (parseInt(e.target.value) === 3) {
      this.setState({
        periodEndDate: (currentDate),
        periodStartDate: DateTime.local().minus({ days: 1 }).toJSDate(),
      });
    } else if (parseInt(e.target.value) === 0) {
      const { monday, lastday } = getMondayOfCurrentWeek();
      this.setState({
        periodStartDate: monday.toJSDate(),
        periodEndDate: lastday.toJSDate(),
      });
    } else {
      this.setState({
        periodStartDate: (new Date(new Date().getFullYear(), 0, 1)),
        periodEndDate: (new Date(new Date().getFullYear(), 11, 31)),
      });
    }
  };

  render() {
    return (
      <div className="flexMySearch-filter-criteria">
        <div className="flexMySearch-filter-criteria-header">
          <span className="flexMySearch-filter-criteria-icon">
            <img
              className="flexMySearch-filter-criteria-img "
              src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"
                }/Filter-2.svg`}
              alt="icon"
            />
          </span>

          {/* Filter your search criteria */}
          <span className="flexMySearch-filter-criteria-title">
            {this.labels.HubLabelflexFilterSearchCritera}{" "}
          </span>
          <span
            className="flexMySearch-filter-criteria-close"
            onClick={() => this.props.toggleDrawer(false)}
          >
            &times;
          </span>
        </div>

        <div className="flexMySearch-filter-criteria-content">
          {/* Select a Building */}
          <div className="flexMySearch-filter-criteria-firstLabel pt-0">
            {this.labels.HubLabelBuildings}
          </div>
          <div className="row flexMySearch-filter-criteria-selectBldng-filter">
            <div className="col-5 ml-0 flexMySearch-filter-criteria-select-label">
              {this.labels.HubLabelSelectBuilding}
            </div>
            <div className="col-7 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
              <IbssFormControl fullWidth size="small" className="m-0 w-300" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                <IbssInputDropDown
                className=""
                  id='buildings'
                  options={this.state.buidlingOptions}
                  onChange={(e: any) => {
                    const floorOpts = this.makeFloorOptions(Helper.getFloorsByBuildingId(e.target.value));
                    this.setState({
                      selectedBuildingOption: e.target.value,
                      floorOptions: floorOpts,
                      selectedFloor: floorOpts[0].value
                    });
                  }}
                  value={this.state.selectedBuildingOption}
                />
              </IbssFormControl>
            </div>
          </div>
          <div className="flexMySearch-filter-criteria-border" />


          <div className="flexMySearch-filter-criteria-firstLabel pt-0">
            {this.labels.HubLabelLocation}
          </div>
          <div className="row flexMySearch-filter-criteria-selectBldng-filter">
            {/* floors */}
            <div className="col-5 ml-0 flexMySearch-filter-criteria-select-label">
              {this.labels.HubLabelFloors}
            </div>
            <div className="col-7 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
              <IbssFormControl fullWidth size="small" className="w-300" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                <IbssInputDropDown
                  className="m-0"
                  id='floors'
                  options={this.state.floorOptions}
                  onChange={(e: any) => this.setState({
                    selectedFloor: e.target.value
                  })}
                  value={this.state.selectedFloor}
                />
              </IbssFormControl>
            </div>
          </div>
          <div className="flexMySearch-filter-criteria-border" />

          {/* date and time */}
          <div className="flexMySearch-filter-criteria-firstLabel">
            {this.labels.HubLabelDateandTime}
          </div>
          <div className="row flexMySearch-filter-criteria-selectBldng-filter">
            <div className="col-5 ml-0 flexMySearch-filter-criteria-select-label">
              {this.labels.HublabelPeriodType}
            </div>
            <div className="col-7 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
              <IbssFormControl fullWidth className="w-300" size="small" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}>
                <Select
                  className=""
                  displayEmpty
                  value={this.state.periodType}
                  onChange={(e: any) => this.handlePeriodTypeChange(e)}
                >
                  {periodType.map((eachVal: any) => {
                    return (
                      <MenuItem value={eachVal.Value}>
                        {eachVal.Name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </IbssFormControl>
            </div>
          </div>
          <div className="flexMySearch-filter-criteria-border" />

          <div className="row flexMySearch-filter-criteria-selectBldng-filter">
            <div className="col-5 ml-0 pt-0 flexMySearch-filter-criteria-select-label">
              {this.labels.HublabelPeriodStartDate}
            </div>
            <div className="col-7 d-flex justify-content-end mr-0 flexMySearch-filter-criteria-select-selectBox">
              <IbssFormControl fullWidth size="small" className="w-300">
                <IbssDatePicker
                  className=""
                  value={this.state.periodStartDate}
                  shouldDisableDate={this.selectedMondays}
                  onChange={(e) => this.handleStartDate(e as Date)}
                  renderInput={(props) => <TextField {...props} size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />}
                  views={[getCalendarView(this.state.periodType)]}
                />
              </IbssFormControl>
            </div>
          </div>
          <div className="flexMySearch-filter-criteria-border" />

          {/* buttons */}
          <div className="right-space-box-cont">
            <div className="d-flex justify-content-center">
              <a
                type="button"
                className="clear-attendees my-2"
                onClick={() => this.clearForm()}
              >
                {this.labels.HubLabelClearSelections}
              </a>
              <button
                type="button"
                className=" btn btn-primary btn-md"
                onClick={() => this.upDateSearchResults()}
              >
                {this.labels.HubButtonUpdate}
              </button>
            </div>
          </div>

        </div>
      </div>
    )
  };
};

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
    heatMapFilterCriteria: state.heatMapFilterCriteria,
  };
};

export default connect(mapStateToProps)(SearchCriteriaHeatmaps);

