import axios, { AxiosResponse } from "axios";
import Helper from "../../Common/Helper";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { IUserPreferences } from "../UserPreferenceRepository";
import { IAssignBookingPolicyToSpacesEndpoint } from "./AssignBookingPolicyToSpacesEndpoint";
import { ICreateBookingPolicyEndpoint } from "./CreateBookingPolicyEndpoint";
import { IDeleteBookingPolicyEndpoint } from "./DeleteBookingPolicyEndpoint";
import { IGetBookingPoliciesEndpoint } from "./GetBookingPoliciesEndpoint";
import { IGetBookingPolicyEndpoint } from "./GetBookingPolicyEndpoint";
import { IUpdateBookingPolicyEndpoint } from "./UpdateBookingPolicyEndpoint";
import { IGetSpaceBookingPolicyEndpoint } from "./GetSpaceBookingPolicy";

export class BookingPolicyRepository implements IBookingPolicyRepository
{
    private getBookingPolicyEndpoint: IGetBookingPolicyEndpoint;
    private getBookingPoliciesEndpoint: IGetBookingPoliciesEndpoint;
    private createBookingPolicyEndpoint: ICreateBookingPolicyEndpoint;
    private updateBookingPolicyEndpoint: IUpdateBookingPolicyEndpoint;
    private deleteBookingPolicyEndpoint: IDeleteBookingPolicyEndpoint;
    private assignBookingPolicyToSpacesEndpoint: IAssignBookingPolicyToSpacesEndpoint;
    private getSpaceBookingPolicyEndpoint: IGetSpaceBookingPolicyEndpoint;

    constructor(
        getBookingPolicyEndpoint: IGetBookingPolicyEndpoint,
        getBookingPoliciesEndpoint: IGetBookingPoliciesEndpoint,
        createBookingPolicyEndpoint: ICreateBookingPolicyEndpoint,
        updateBookingPolicyEndpoint: IUpdateBookingPolicyEndpoint,
        deleteBookingPolicyEndpoint: IDeleteBookingPolicyEndpoint,
        assignBookingPolicyToSpacesEndpoint: IAssignBookingPolicyToSpacesEndpoint,
        getSpaceBookingPolicyEndpoint: IGetSpaceBookingPolicyEndpoint,
    )
    {
        this.getBookingPolicyEndpoint = getBookingPolicyEndpoint;
        this.getBookingPoliciesEndpoint = getBookingPoliciesEndpoint;
        this.createBookingPolicyEndpoint = createBookingPolicyEndpoint;
        this.updateBookingPolicyEndpoint = updateBookingPolicyEndpoint;
        this.deleteBookingPolicyEndpoint = deleteBookingPolicyEndpoint;
        this.assignBookingPolicyToSpacesEndpoint = assignBookingPolicyToSpacesEndpoint;
        this.getSpaceBookingPolicyEndpoint = getSpaceBookingPolicyEndpoint;
    }

    public getBookingPolicy(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy>
    {
        return this.getBookingPolicyEndpoint.execute(nodeId, bookingPolicyId);
    }

    public getBookingPolicies(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>
    {
        return this.getBookingPoliciesEndpoint.execute(nodeId, top, skipToken);
    }

    public createBookingPolicy(policy: IBookingPolicy): Promise<void>
    {
        return this.createBookingPolicyEndpoint.execute(policy);
    }

    public updateBookingPolicy(policy: IBookingPolicy): Promise<void>
    {
        return this.updateBookingPolicyEndpoint.execute(policy);
    }

    public deleteBookingPolicy(nodeId: number, bookingPolicyId: string): Promise<void>
    {
        return this.deleteBookingPolicyEndpoint.execute(nodeId, bookingPolicyId);
    }

    public assignBookingPolicyToSpaces(nodeId: number, bookingPolicyId: string, spaceIds: string[]): Promise<void>
    {
        return this.assignBookingPolicyToSpacesEndpoint.execute(nodeId, bookingPolicyId, spaceIds);
    }

    public async getSpaceBookingPolicy(nodeId: number, spaceId: string): Promise<IBookingPolicyData>
    {
        return this.getSpaceBookingPolicyEndpoint.execute(nodeId, spaceId);
    }
}

export interface IBookingPolicyRepository
{
    getBookingPolicy(nodeId: number, bookingPolicyId: string): Promise<IBookingPolicy>;
    getBookingPolicies(nodeId: number, top: number, skipToken: number): Promise<IBookingPolicies>;
    createBookingPolicy(policy: IBookingPolicy): Promise<void>;
    updateBookingPolicy(policy: IBookingPolicy): Promise<void>;
    deleteBookingPolicy(nodeId: number, bookingPolicyId: string): Promise<void>;
    assignBookingPolicyToSpaces(nodeId: number, bookingPolicyId: string, spaceIds: string[]): Promise<void>;
    getSpaceBookingPolicy(nodeId: number, spaceId: string): Promise<IBookingPolicyData>;
}

export interface IBookingPolicies
{
    value: IBookingPolicy[];
}

export interface IBookingPolicy
{
    Node_Id: number;
    Booking_Policy_Id?: string;
    Booking_Policy_Name: string;
    Booking_Policy: string;
    Booking_Policy_Object: IBookingPolicyData;
}

export interface IBookingPolicyData
{
    Booking_Policy_Description: string;
    BookingSlots: IBookingSlots;
    ArrivalPolicies: IArrivalPolicies;
    ApprovalPolicies: IApprovalPolicies;
}

export interface IBookingSlots
{
    BookableTime: IBookableTime[];
    ExcludedDates: IExcludedDate[];
    BookingStart: IBookingStart;
    BookingEnd: IBookingEnd;
    BookingDuration: IBookingDuration;
    BookingHorizon: IBookingHorizon;
    BookingRecurrence: IBookingRecurrence;
}

export interface IBookableTime
{
    Days: string[];
    StartTime: string;
    EndTime: string;
}

export interface IExcludedDate
{
    StartDate: string;
    EndDate: string;
    StartTime: string;
    EndTime: string;
}

export interface IBookingStart
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IBookingEnd
{
    SpecificMinutes: string[];
    SpecificTimes: string[];
}

export interface IBookingDuration
{
    Minimum: number;
    Maximum: number;
    Fixed: string[];
}

export interface IBookingHorizon
{
    Minimum: number;
    Maximum: number;
}

export interface IArrivalPolicies
{
    AutoCheckin: boolean;
    EarlyCheckin: number;
    AutoCancellation: number;
}

export interface IApprovalPolicies
{
    ApprovalRequired: boolean;
    AutoRejectionAfter: number;
    ApprovalGroups: string[];
}

export interface IBookingRecurrence
{
    Allowed: boolean;
    Maximum: number;
}
