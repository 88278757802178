import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../styles/css/header.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import InputTextBox from "../../Components/uicomponents/InputTextBox"
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import { getBuildingNameUsingBuildingId, getBuildingOptionsForDropdown } from "../../Common/Helper";
import DatePicker from "react-datepicker";
import "../../styles/css/datepicker/react-datepicker.css";
import Autocomplete from '@mui/material/Autocomplete';
import Paper from "@material-ui/core/Paper";
import TextField from '@mui/material/TextField';
import LoadingOverlay from "../../Components/LoadingOverlay"
import FormPanelInfo from "../../Components/FormPanelInfo"
import InputSelectBox from "../../Components/uicomponents/InputSelectBox";
import { appContext } from "../../AppContext";
import { RouteComponentProps } from "react-router";
import { IPropsFromState } from "../../redux/Interfaces";
import { IRootNode } from "../../Providers.Api/Models";
import { IUserPreferences } from "../../Providers.Api/UserPreferenceRepository";

const moment = require('moment');
class FacilityVisitDetails extends Component<IProps> {
  private labels = appContext().labels;
  private session = appContext().sessionStorageProvider;
  private local = appContext().localStorageProvider;
  private get appState() { return appContext().state; };
  private nodeData: IRootNode | null = null;
  private userPreferences: IUserPreferences | null = null;

  state = {
    isLoading: false,
    isLoadingToSave: false,
    activePage: 0,
    updates: false,
    title: "Visits",
    loading: false,
    visitor_First_Name: "",
    visitor_Last_Name: "",
    visitor_Company: "",
    visitor_Email: "",
    visit_Id: "",
    visit_Start_Date: new Date(),
    visit_End_Date: new Date(),
    visit_Status: "",
    visit_Host_Name: "",
    visit_Host_Email: "",
    Visit_Pacs_Id: "",
    booking_Name: "",
    booking_Id: "",
    booking_Start: "",
    booking_End: "",
    space_Name: "",
    space_Id: "",
    visit_IsApproved: 0,
    visit_IsCancelled: 0,
    visit_IsDenied: 0,
    visit_IsCheckedIn: 0,
    visit_IsCheckedOut: 0,
    visit_IsNoShow: 0,
    show: false,
    isDelete: false,
    tab: 1,
    validvisit_Host_Name: true,
    validvisitor_Company: true,
    validvisitor_Last_Name: true,
    validvisitor_First_Name: true,
    validvisitor_Email: true,
    validvisit_Host_Email: true,
    validvisit_Start_Date: true,
    validvisit_End_Date: true,
    validateForm: false,
    isUpdateRight: false,
    isDeleteRight: false,
    copyHostList: [{ displayName: "", UserName: "" }],
    hostList: [{ displayName: "", UserName: "" }],
    apiVersion: 11,
    buildingId: this.appState.buildingId,
    derivedFrom: 'onelens',
    editMode: false,
  };

  componentDidMount() 
  {
    this.nodeData = this.local.getNodeData();
    this.userPreferences = this.local.getUserPreferences();
    this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));

    const { match }: any = this.props;
    this.setState({ derivedFrom: match.path.includes('flex') ? 'flex' : 'onelens' })
    let versions = 11;
    const version = this.local.getVersion();
    if (version !== 0) {
      versions = version;
    }
    this.setState({
      isLoadingToSave: false,
      apiVersion: versions
    })
    if (match.params.visit_Id !== "0") {
      this.getRightList();
      this.setState({
        visit_Id: match.params.visit_Id,
        tab: match.params.tab,
        editMode: true
      }, () => {
        this.getDetails(match.params.visit_Id)
      })
    } else {
      this.setState({
        tab: match.params.tab,
        isUpdateRight: true,
        visit_Start_Date: new Date(),
        visit_End_Date: new Date()
      }, () => {
        this.getSpaceDetails();
      })
    }
  }

  getSpaceDetails = () => {
    
    // getting and setting spaceId and spaceName
    
    apis.getParametersByName(this.state.buildingId).then((res) => {
      this.setState({
        space_Id: res.data.Parameter_Value
      })
      apis.getSpaceName(this.state.buildingId, res.data.Parameter_Value).then((res) => {
        this.setState({
          space_Name: res.data.Space_Name
        })
      }).catch((error: any) => { });
    }).catch((error: any) => { });
  }

  getRightList = () => {
    const res = this.local.getIbssRightList();

    if (res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Update") > -1) {
      this.setState({
        isUpdateRight: true
      })
    }
    if (res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Delete") > -1) {
      this.setState({
        isDeleteRight: true
      })
    }
  }

  getDetails = (id: any) => {
    apis.getDetailsByVisitId(this.state.buildingId, id).then((res) => {
      if (this.state.apiVersion === 10) {
        let startDate = moment(res.data.visit_Start_Date)
        let endDate = moment(res.data.visit_End_Date)
        res.data.visit_Start_Date = new Date(startDate);
        res.data.visit_End_Date = new Date(endDate);

        if (res.data.Booking_Start !== "") {
          startDate = new Date(res.data.booking_Start);
          endDate = new Date(res.data.booking_End);
          res.data.booking_Start = moment(startDate).format("DD/MM/yyyy HH:mm")
          res.data.booking_End = moment(endDate).format("DD/MM/yyyy HH:mm")
        }

        this.setState({
          visitor_First_Name: res.data.visitor_First_Name,
          visitor_Last_Name: res.data.visitor_Last_Name,
          visitor_Company: res.data.visitor_Company,
          visitor_Email: res.data.visitor_Email,
          visit_Id: res.data.visit_Id,
          visit_Start_Date: res.data.visit_Start_Date,
          visit_End_Date: res.data.visit_End_Date,
          visit_Status: res.data.visit_Status,
          visit_Host_Name: res.data.visit_Host_Name,
          visit_Host_Email: res.data.visit_Host_Email,
          booking_Name: res.data.booking_Name,
          booking_Id: res.data.booking_Id,
          booking_Start: res.data.booking_Start,
          booking_End: res.data.booking_End,
          space_Name: res.data.space_Name,
          space_Id: res.data.space_Id,
          visit_IsApproved: res.data.visit_IsApproved,
          visit_IsCancelled: res.data.visit_IsCancelled,
          visit_IsDenied: res.data.visit_IsDenied,
          visit_IsCheckedIn: res.data.visit_IsCheckedIn,
          visit_IsCheckedOut: res.data.visit_IsCheckedOut,
          visit_IsNoShow: res.data.visit_IsNoShow,
          Visit_Pacs_Id: res.data.visit_Pacs_Id,
          Node_Id: res.data.Node_Id
        })
      }
      if (this.state.apiVersion > 10) {
        let startDate = moment(res.data.Visit_Start_Date)
        let endDate = moment(res.data.Visit_End_Date)
        res.data.Visit_Start_Date = new Date(startDate);
        res.data.Visit_End_Date = new Date(endDate);

        if (res.data.Booking_Start !== "") {
          startDate = new Date(res.data.Booking_Start);
          endDate = new Date(res.data.Booking_End);
          res.data.Booking_Start = moment(startDate).format("DD/MM/yyyy HH:mm")
          res.data.Booking_End = moment(endDate).format("DD/MM/yyyy HH:mm")
        }

        this.setState({
          visitor_First_Name: res.data.Visitor_First_Name,
          visitor_Last_Name: res.data.Visitor_Last_Name,
          visitor_Company: res.data.Visitor_Company,
          visitor_Email: res.data.Visitor_Email,
          visit_Id: res.data.Visit_Id,
          visit_Start_Date: res.data.Visit_Start_Date,
          visit_End_Date: res.data.Visit_End_Date,
          visit_Status: res.data.Visit_Status,
          visit_Host_Name: res.data.Visit_Host_Name,
          visit_Host_Email: res.data.Visit_Host_Email,
          booking_Name: res.data.Booking_Name,
          booking_Id: res.data.Booking_Id,
          booking_Start: res.data.Booking_Start,
          booking_End: res.data.Booking_End,
          space_Name: res.data.Space_Name,
          space_Id: res.data.Space_Id,
          visit_IsApproved: res.data.Visit_IsApproved,
          visit_IsCancelled: res.data.Visit_IsCancelled,
          visit_IsDenied: res.data.Visit_IsDenied,
          visit_IsCheckedIn: res.data.Visit_IsCheckedIn,
          visit_IsCheckedOut: res.data.Visit_IsCheckedOut,
          visit_IsNoShow: res.data.Visit_IsNoShow,
          Node_Id: res.data.Node_Id,
          Visit_Pacs_Id: res.data.Visit_Pacs_Id
        })
      }

    }).catch((error: any) => { });
  };

  submit = () => {
    const { match }: any = this.props;
    const { visitor_First_Name, visitor_Last_Name, visitor_Company, visitor_Email, visit_Id,
      visit_Start_Date, visit_End_Date, visit_Status, visit_Host_Name, visit_Host_Email,
      booking_Name, booking_Id, booking_Start, booking_End, space_Name, space_Id,
      visit_IsApproved, visit_IsCancelled, visit_IsDenied, visit_IsCheckedIn, visit_IsCheckedOut,
      visit_IsNoShow, validateForm, Visit_Pacs_Id }: any = this.state;

    let bookingStart = "";
    let bookingEnd = "";

    let visitStartDate = moment(visit_Start_Date).toISOString();
    let visitEndDate = moment(visit_End_Date).toISOString();
    // bookingStart = visitStartDate
    // bookingEnd = visitEndDate
    let payload = {};
    if (match.params.visit_Id === "0") {
      if (this.state.apiVersion === 10) {
        payload = {
          "space_Id": space_Id,
          "booking_Id": booking_Id,
          "booking_Start": bookingStart,
          "booking_End": bookingEnd,
          "space_Name": space_Name,
          "visitor_First_Name": visitor_First_Name,
          "visitor_Last_Name": visitor_Last_Name,
          "visitor_Email": visitor_Email,
          "visitor_Company": visitor_Company,
          "visit_Host_Name": visit_Host_Name,
          "visit_Host_Email": visit_Host_Email,
          "visit_Start_Date": visitStartDate,
          "visit_End_Date": visitEndDate,
          "visit_Approval_Comments": "",
          "visit_Pacs_Id": Visit_Pacs_Id
        }
      } else {
        payload = {
          "Space_Id": space_Id,
          "Booking_Id": booking_Id,
          "Booking_Start": bookingStart,
          "Booking_End": bookingEnd,
          "Space_Name": space_Name,
          "Visitor_First_Name": visitor_First_Name,
          "Visitor_Last_Name": visitor_Last_Name,
          "Visitor_Email": visitor_Email,
          "Visitor_Company": visitor_Company,
          "Visit_Host_Name": visit_Host_Name,
          "Visit_Host_Email": visit_Host_Email,
          "Visit_Start_Date": visitStartDate,
          "Visit_End_Date": visitEndDate,
          "Visit_Approval_Comments": "",
          "Visit_Pacs_Id": Visit_Pacs_Id
        }
      }
      this.create(payload);
    } else {
      if (this.state.apiVersion === 10) {
        payload = {
          "space_Id": space_Id,
          "booking_Id": booking_Id,
          "booking_Start": bookingStart,
          "booking_End": bookingEnd,
          "space_Name": space_Name,
          "visitor_First_Name": visitor_First_Name,
          "visitor_Last_Name": visitor_Last_Name,
          "visitor_Email": visitor_Email,
          "visitor_Company": visitor_Company,
          "visit_Host_Name": visit_Host_Name,
          "visit_Host_Email": visit_Host_Email,
          "visit_Start_Date": visitStartDate,
          "visit_End_Date": visitEndDate,
          "visit_IsCheckedIn": 0,
          "visit_IsNoShow": 0,
          "visit_IsCheckedOut": 0,
          "visitor_Dietary_Pref": 0,
          "visit_IsDenied": 0,
          "visit_Pacs_Id": Visit_Pacs_Id
        }
      } 
      else 
      {
        payload = {
          "Space_Id": space_Id,
          "Booking_Id": booking_Id,
          "Booking_Start": bookingStart,
          "Booking_End": bookingEnd,
          "Space_Name": space_Name,
          "Visitor_First_Name": visitor_First_Name,
          "Visitor_Last_Name": visitor_Last_Name,
          "Visitor_Email": visitor_Email,
          "Visitor_Company": visitor_Company,
          "Visit_Host_Name": visit_Host_Name,
          "Visit_Host_Email": visit_Host_Email,
          "Visit_Start_Date": visitStartDate,
          "Visit_End_Date": visitEndDate,
          "Visit_IsCheckedIn": 0,
          "Visit_IsNoShow": 0,
          "Visit_IsCheckedOut": 0,
          "Visitor_Dietary_Pref": 0,
          "Visit_IsDenied": 0,
          "Visit_Pacs_Id": Visit_Pacs_Id
        }
      }
      this.update(payload)
    }
  };

  create = (payload: any) => {
    apis.createVisit(this.session.getBuildingId(), payload).then((res) => {
      this.setState({ isLoadingToSave: false })
      this.props.history.push('/flex-my-visitor');
    }).catch((error: any) => {
      this.setState({ isLoadingToSave: false })
    });
  };

  update = (payload: any) => {
    const { updates, visit_Id }: any = this.state;
    if (updates) {
      apis.updateVisitorByVisitId(this.session.getBuildingId(), visit_Id, payload).then(() => {
        this.setState({ isLoadingToSave: false })
        this.props.history.push('/flex-my-visitor');
      }).catch((error: any) => {
        this.setState({ isLoadingToSave: false })
      });
    } else {
      this.setState({ isLoadingToSave: false })
      this.props.history.push('/flex-my-visitor');
    }
  };

  change = (e: any) => {
    this.setState({ updates: true })
    this.setState({ [e.target.name]: e.target.value }, () => {
      let regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value);
      if (e.target.name === "visit_Start_Date") {
        let result = moment(this.state.visit_Start_Date, 'yyy-MM-DDTHH:mm:ssZ', true).isValid();
        if (result) {
          this.setState({
            validvisit_Start_Date: true
          })
        } else {
          this.setState({
            validvisit_Start_Date: false
          })
        }
      }

      if (e.target.name === "visit_End_Date") {
        let result = moment(this.state.visit_End_Date, 'yyy-MM-DDTHH:mm:ssZ', true).isValid();
        if (result) {
          this.setState({
            validvisit_End_Date: true
          })
        } else {
          this.setState({
            validvisit_End_Date: false
          })
        }
      }

      if (e.target.name === 'visitor_Email' && regEmail) {
        this.setState({
          validvisitor_Email: false
        })
      } else if (e.target.name === 'visitor_Email' && e.target.value.length === 0) {
        this.setState({
          validvisitor_Email: false
        })
      }

      if (e.target.name === 'visit_Host_Email' && e.target.value.length > 0) {
        this.setState({
          validvisit_Host_Email: true
        })
      } else if (e.target.name === 'visit_Host_Email' && e.target.value.length === 0) {
        this.setState({
          validvisit_Host_Email: false
        })
      }

    });
  };

  validateAllForm() {
    this.setState({ isLoadingToSave: true })

    const { visit_Host_Name, visitor_Company, visitor_Email, visitor_Last_Name, visitor_First_Name,
      validvisit_Host_Name, visit_Host_Email, validvisitor_Company, validvisitor_Last_Name, validvisitor_First_Name,
      validvisitor_Email, validvisit_Host_Email }: any = this.state;

    let startdate = moment(this.state.visit_Start_Date, 'yyy-MM-DDTHH:mm:ssZ', true).isValid();
    let enddate = moment(this.state.visit_End_Date, 'yyy-MM-DDTHH:mm:ssZ', true).isValid();
    let regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i.test(visitor_Email);
    if (startdate === true && enddate === true && visit_Host_Email.length > 0 && regEmail && visit_Host_Name.length > 0 && visitor_Company.length > 0 && visitor_Last_Name.length > 0 && visitor_First_Name.length > 0) {
      this.setState({
        validateForm: true,
        validvisit_Host_Name: true,
        validvisitor_Company: true,
        validvisitor_Last_Name: true,
        validvisitor_First_Name: true,
        validvisitor_Email: true,
        validvisit_Host_Email: true,
        validvisit_Start_Date: true,
        validvisit_End_Date: true,
      }, () => {
        this.submit();
      })
    } else {
      if (startdate === true) {
        this.setState({
          validvisit_Start_Date: false
        })
      }

      if (startdate === true) {
        this.setState({
          validvisit_End_Date: false
        })
      }

      if (visit_Host_Email.length === 0) {
        this.setState({
          validvisit_Host_Email: false,
          validateForm: false
        })
      }
      if (visitor_Email.length === 0) {
        this.setState({
          validvisitor_Email: false,
          validateForm: false
        })
      }
      if (visit_Host_Name.length === 0) {
        this.setState({
          validvisit_Host_Name: false,
          validateForm: false
        })
      }
      if (visitor_Company.length === 0) {
        this.setState({
          validvisitor_Company: false,
          validateForm: false
        })
      }
      if (visitor_Last_Name.length === 0) {
        this.setState({
          validvisitor_Last_Name: false,
          validateForm: false
        })
      }
      if (visitor_First_Name.length === 0) {
        this.setState({
          validvisitor_First_Name: false,
          validateForm: false
        })
      }
      this.setState({ isLoadingToSave: false })
    }
  }

  changeCheckbox = (e: any) => {
    const { visit_IsCancelled, visit_IsApproved, visit_IsNoShow, visit_IsCheckedIn, visit_IsCheckedOut, visit_IsDenied } = this.state
    this.setState({ updates: true })

    if (e.target.name === "visit_IsCancelled") {
      if (visit_IsCancelled === 0) {
        this.setState({
          visit_IsCancelled: 1
        })
      } else {
        this.setState({
          visit_IsCancelled: 0
        })
      }
    }

    if (e.target.name === "visit_IsApproved") {
      if (visit_IsApproved === 0) {
        this.setState({
          visit_IsApproved: 1
        })
      } else {
        this.setState({
          visit_IsApproved: 0
        })
      }
    }

    if (e.target.name === "visit_IsNoShow") {
      if (visit_IsNoShow === 0) {
        this.setState({
          visit_IsNoShow: 1
        })
      } else {
        this.setState({
          visit_IsNoShow: 0
        })
      }
    }

    if (e.target.name === "visit_IsCheckedIn") {
      if (visit_IsCheckedIn === 0) {
        this.setState({
          visit_IsCheckedIn: 1
        })
      } else {
        this.setState({
          visit_IsCheckedIn: 0
        })
      }
    }

    if (e.target.name === "visit_IsCheckedOut") {
      if (visit_IsCheckedOut === 0) {
        this.setState({
          visit_IsCheckedOut: 1
        })
      } else {
        this.setState({
          visit_IsCheckedOut: 0
        })
      }
    }

    if (e.target.name === "visit_IsDenied") {
      if (visit_IsDenied === 0) {
        this.setState({
          visit_IsDenied: 1
        })
      } else {
        this.setState({
          visit_IsDenied: 0
        })
      }
    }

  }
  isDelete = () => {

  }

  delete = () => {

  }

  cancelDeleteModal = () => {
    this.setState({
      isDelete: !this.state.isDelete,
      show: !this.state.show,
    })
  }
  cancel = () => {
    const { updates } = this.state
    const { history }: any = this.props;
    if (updates) {
      this.handleModal();
    } else {
      this.redirectPath();
    }
  }

  redirectPath = () => {
    const { history }: any = this.props;
    let activeurl = window.location.href;
    if (activeurl.includes("flex-my-visitor")) {
      history.push("/flex-my-visitor");
    } else {
      history.push("/operational-services-visitors/" + this.session.getBuildingId() + "/" + this.state.tab);
    }
  }

  handleModal = () => {
    this.setState({
      show: !this.state.show,
    })
  }

  handleVisitStartDate = (e: any) => {
    const isoStartTime = (new Date(e)).toISOString()
    const isoEndTime = (new Date(this.state.visit_End_Date)).toISOString()

    if (isoStartTime > isoEndTime) {
      this.setState({
        visit_Start_Date: e,
        visit_End_Date: e,
        updates: true
      })
    } else {
      this.setState({
        visit_Start_Date: e,
        updates: true
      })
    }
  }

  handleVisitEndDate = (e: any) => {
    const isoStartTime = (new Date(this.state.visit_Start_Date)).toISOString()
    const isoEndTime = (new Date(e)).toISOString()

    if (isoStartTime > isoEndTime) {
      this.setState({
        visit_Start_Date: e,
        visit_End_Date: e,
        updates: true
      })
    } else {
      this.setState({
        visit_End_Date: e,
        updates: true
      })
    }
  }

  selectHostName = (e: any) => {
    for (let i = 0; i < this.state.copyHostList.length; i++) {
      let dataList: any = this.state.copyHostList[i].displayName.toLowerCase();
      let searchValue = (e.target.value).toLowerCase();
      if (dataList !== "" && dataList === searchValue) {
        this.setState({
          visit_Host_Name: this.state.copyHostList[i].displayName,
          visit_Host_Email: this.state.copyHostList[i].UserName
        })
      }
    }
  }

  changeHostName = (e: any) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length >= 3) {
      // this.setState({ isLoading: true })
      apis.getHostName(e.target.value).then((res) => {
        // this.setState({ isLoading: false })
        let tempHostList = res.data;
        let tempData: any = [{ displayName: "", UserName: "" }];
        for (let i = 0; i < tempHostList.length; i++) {
          if (tempHostList[i].displayName !== null) {
            let dataList: any = tempHostList[i].displayName.toLowerCase();
            let searchValue = (e.target.value).toLowerCase();
            if (dataList.includes(searchValue)) {
              let temp = { displayName: "", UserName: "" }
              temp.displayName = tempHostList[i].displayName;
              temp.UserName = tempHostList[i].UserName;
              tempData.push(temp);
            }
          }
        }
        this.setState({
          hostList: tempData,
          copyHostList: tempData,
          updates:true
        })
      }).catch((error: any) => { });

    } else {
      this.setState({
        hostList: [{ displayName: "", UserName: "" }]
      })
    }
  }

  render() {
    const { history, match }: any = this.props;
    const { visitor_First_Name, visitor_Last_Name, visitor_Company, visitor_Email, visit_Id,
      visit_Start_Date, visit_End_Date, visit_Status, visit_Host_Name, visit_Host_Email,
      Visit_Pacs_Id,
      booking_Name, booking_Id, booking_Start, booking_End, space_Name, space_Id,
      visit_IsApproved, visit_IsCancelled, visit_IsDenied, visit_IsCheckedIn, visit_IsCheckedOut,
      visit_IsNoShow, validvisitor_Email, validvisit_Host_Email, validvisit_Start_Date, validvisit_End_Date }: any = this.state;

    const confModalData = this.state.isDelete ? {
      name: this.state.visitor_First_Name,
      show: this.state.show,
      handleModal: this.cancelDeleteModal,
      okButton: this.delete,
      modalHeading: this.labels.HubLabelConfirmation,
      modalMessage: this.labels.HubLabelDeleteMessage,
    }
      : {
        name: this.state.visitor_First_Name,
        show: this.state.show,
        handleModal: this.handleModal,
        okButton: () => this.props.history.goBack(),
        modalHeading: this.labels.HubLabelConfirmation,
        modalMessage: this.labels.HubLabelConfirmCancelMessage,
      }

    const visitor_First_NameFld = {
      name: "visitor_First_Name",
      label: this.labels.HubLabelFirstName,
      value: visitor_First_Name,
      mandatory: true,
      onchange: this.change,
    };

    const visitor_Last_NameFld = {
      name: "visitor_Last_Name",
      label: this.labels.HubLabelLastName,
      value: visitor_Last_Name,
      mandatory: true,
      onchange: this.change,
    };

    const visitor_CompanyFld = {
      name: "visitor_Company",
      label: this.labels.HubLabelCompany,
      value: visitor_Company,
      mandatory: true,
      onchange: this.change,
    };

    const visitor_EmailFld = {
      name: "visitor_Email",
      label: this.labels.HubLabelEmailAddress,
      value: visitor_Email,
      mandatory: true,
      onchange: this.change,
    };

    const visit_IdFld = {
      name: "visit_Id",
      label: this.labels.HubLabelVisitID,
      value: visit_Id,
      readonly: true,
      onchange: this.change,
    };


    const visit_Start_DateFld = {
      name: "visit_Start_Date",
      label: this.labels.HubLabelVisitStart,
      value: visit_Start_Date,
      placeholder: this.labels.HubLabelVisitStartLabelPlaceholder,
      onchange: this.change,
    };

    const visit_End_DateFld = {
      name: "visit_End_Date",
      label: this.labels.HubLabelVisitEnd,
      value: visit_End_Date,
      placeholder: this.labels.HubLabelVisitEndLabelPlaceholder,
      onchange: this.change,
    };

    const visit_StatusFld = {
      name: "visit_Status",
      label: this.labels.HubLabelStatus,
      value: visit_Status,
      readonly: true,
      onchange: this.change,
    };

    const visit_Host_NameFld = {
      name: "visit_Host_Name",
      label: this.labels.HubLabelHostName,
      value: visit_Host_Name,
      placeholder: this.labels.HubLabelHostNamePlaceholderText,
      onchange: this.change,
    };

    const visit_Host_EmailFld = {
      name: "visit_Host_Email",
      label: this.labels.HubLabelHostNamePlaceholder,
      value: visit_Host_Email,
      readonly: true,
      onchange: this.change,
    };
    const pacs_field = {
      name: "Visit_Pacs_Id",
      label: this.labels.HubLabelPacs,
      value: Visit_Pacs_Id,
      readonly: false,
      onchange: this.change,
    };

    const booking_NameFld = {
      name: "booking_Name",
      label: this.labels.HubLabelBookingName,
      value: booking_Name,
      readonly: true,
      onchange: this.change,
    };

    const booking_IdFld = {
      name: "booking_Id",
      label: this.labels.HublabelBookingId,
      value: booking_Id,
      readonly: true,
      onchange: this.change,
    };

    const booking_StartFld = {
      name: "booking_Start",
      label: this.labels.HubLabelBookingStart,
      value: booking_Start,
      readonly: true,
      onchange: this.change,
    };

    const booking_EndFld = {
      name: "booking_End",
      label: this.labels.HubLabelBookingEnd,
      value: booking_End,
      readonly: true,
      onchange: this.change,
    };

    const space_NameFld = {
      name: "space_Name",
      label: this.labels.HubLabelArrivalLocation,
      value: space_Name,
      readonly: true,
      onchange: this.change,
    };

    const space_IdFld = {
      name: "space_Id",
      label: this.labels.HubLabelArrivalLocationId,
      value: space_Id,
      readonly: true,
      onchange: this.change,
    };
    const buildingField = {
      name: "building",
      label: this.labels.HubLabelBuilding,
      value: (this.state.buildingId).toString(), // the input used here always operates on strings. it turns everything into strings.
      mandatory: true,
      onchange: (value: string) => {
        const buildingName = this.nodeData?.Regions?.flatMap(region => region.Buildings).find(building => building.Node_Id === parseInt(value))?.Name ?? '';
        this.appState.set({ buildingId: parseInt(value), buildingName: buildingName });
        this.setState({
          buildingId: parseInt(value),
          buildingName: buildingName,
        });
      },
      disabled: this.state.editMode,
      type: "option",
      options: getBuildingOptionsForDropdown()
    }


    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubLabelDetailslabel,
          tabNo: 0,
          active: this.state.activePage === 0 ? true : false,
        },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) => {
        this.setState({ activePage: i });
      },
    };
    return (
      <>
        {(this.state.isLoading || this.state.isLoadingToSave) && <LoadingOverlay />}
          <div className="rightPanel">
            {/* <Submenu {...props} /> */}
            <div className="rightPanel-main-content-form">
              {this.state.show ? (
                <ConfirmModal {...confModalData} />
              ) : ""}

              {
                this.state.loading || (
                  <div className="form-panel">
                    <FormPanelInfo
                      formHeading={this.labels.HubLabelVisitorDetails}
                      iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                    />

                    <form className="form-panel-form">
                      {this.state.derivedFrom === 'flex' && <div className="row">
                        <div className="form-input-box">
                          <InputSelectBox {...buildingField} />
                        </div>
                      </div>}
                      <div className="row">
                        <InputTextBox {...visitor_First_NameFld} />
                        {(this.state.validvisitor_First_Name === false && this.state.visitor_First_Name.length === 0) &&
                          <div className="text-danger">
                            {this.labels.HubLabelOneCharacterError}
                          </div>
                        }
                      </div>

                      <div className="row">
                        <InputTextBox {...visitor_Last_NameFld} />
                        {(this.state.validvisitor_Last_Name === false && this.state.visitor_Last_Name.length === 0) &&
                          <div className="text-danger">
                            {this.labels.HubLabelOneCharacterError}
                          </div>
                        }
                      </div>

                      <div className="row">
                        <InputTextBox {...visitor_CompanyFld} />
                        {(this.state.validvisitor_Company === false && this.state.visitor_Company.length === 0) &&
                          <div className="text-danger">
                            {this.labels.HubLabelOneCharacterError}
                          </div>
                        }
                      </div>

                      <div className="row">
                        <InputTextBox {...visitor_EmailFld} />
                      </div>

                      <div className="row">
                        <div className="form-input-box">
                          <label className="form-input-box-label">{this.labels.HubLabelVisitStart}</label>
                          <span className="form-input-box-required-asterisk">*</span>
                          <DatePicker className="form-input-box-datetime-picker form-input-text" selected={this.state.visit_Start_Date} onChange={this.handleVisitStartDate} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" locale="pt-BR" timeFormat="HH:mm" timeIntervals={15} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-input-box">
                          <label className="form-input-box-label">{this.labels.HubLabelVisitEnd}</label>
                          <span className="form-input-box-required-asterisk">*</span>
                          <DatePicker className="form-input-box-datetime-picker" selected={this.state.visit_End_Date} onChange={this.handleVisitEndDate} showTimeSelect dateFormat="dd/MM/yyyy HH:mm" locale="pt-BR" timeFormat="HH:mm" timeIntervals={15} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-input-box">
                          <label className="form-input-box-label">{this.labels.HubLabelHostName}</label>
                          <span className="form-input-box-required-asterisk">*</span>
                          <Autocomplete
                            freeSolo
                            className="form-input-box-autocomplete auto-text form-input-text"
                            options={this.state.hostList.map((option) => option.displayName)}
                            PaperComponent={({ children }) => (
                              <Paper style={{ fontSize: "14px", fontFamily: "Source Sans Pro", fontWeight: "bold" }}>{children}</Paper>
                            )}
                            value={this.state.visit_Host_Name}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                className="input-box model-textbox form-input-text"
                                name="visit_Host_Name"
                                value={this.state.visit_Host_Name}
                                id="spaceId"
                                placeholder="&#8230;"
                                onSelect={(e) => { this.selectHostName(e); }}
                                onChange={(e) => { this.changeHostName(e); }} />} />
                        </div>
                      </div>
                      <div className="row">
                        <InputTextBox {...visit_Host_EmailFld} />
                        {(validvisit_Host_Email === false || (this.state.visit_Host_Email.length !== 0 && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.visit_Host_Email)) === false)) &&
                          <div className="text-danger">
                            {this.labels.HubLabelInvalidEmailError}
                          </div>
                        }
                      </div>
                      <div className="row">
                        <InputTextBox {...pacs_field} />
                      </div>
                    </form>
                  </div>
                )}

              {visit_Id && (
                this.state.loading || (
                  <>
                    <div className="form-panel">
                      <FormPanelInfo
                        formHeading={this.labels.HubLabelStatus}
                        iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                      />
                      <form className="form-panel-form">
                        <div className="row">
                          <InputTextBox {...visit_IdFld} />
                        </div>

                        <div className="row">
                          <InputTextBox {...visit_StatusFld} />
                        </div>

                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelApproved}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsApproved" checked={visit_IsApproved == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelCancelled}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsCancelled" checked={visit_IsCancelled == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelDeniedLabel}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsDenied" checked={visit_IsDenied == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelCheckedIn}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsCheckedIn" checked={visit_IsCheckedIn == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelCheckedOut}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsCheckedOut" checked={visit_IsCheckedOut == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>


                        <div className="row">
                          <div className="form-input-box">
                            <label className="form-input-box-label">{this.labels.HubLabelNoShow}</label>
                            <span className="form-input-box-required-asterisk"><img src="/images/readOnlyField.svg" alt="" width="16px" height="16px" /></span>
                            <input type="checkbox" className="form-input-checkbox" name="visit_IsNoShow" checked={visit_IsNoShow == 1} onClick={this.changeCheckbox} disabled={true} />
                          </div>
                        </div>
                      </form>
                    </div>


                    <div className="form-panel">
                      <FormPanelInfo
                        formHeading={this.labels.HubLabelAssociatedDetails}
                        iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                      />
                      <form className="form-panel-form">

                        <div className="row">
                          <InputTextBox {...space_NameFld} />
                        </div>

                        <div className="row">
                          <InputTextBox {...space_IdFld} />
                        </div>

                        <div className="row">
                          <InputTextBox {...booking_NameFld} />
                        </div>

                        <div className="row">
                          <InputTextBox {...booking_IdFld} />
                        </div>

                        {match.params.visit_Id !== "0" &&
                          <>
                            <div className="row">
                              <InputTextBox {...booking_StartFld} />
                            </div>
                            <div>
                              <div className="row">
                                <InputTextBox {...booking_EndFld} />
                              </div>
                            </div>
                          </>
                        }
                      </form>
                    </div>
                  </>
                )
              )}
            </div>

            <div className="rightPanel-main-button-group">
              <div>
                <button className="button-tertiary mr-2" onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</button>
                {(this.state.visit_Id !== "0" && this.state.isDeleteRight === true) &&
                  <button className="button-tertiary mr-2" onClick={() => this.isDelete()}>{this.labels.HubButtonDelete}</button>
                }
                {(this.state.visit_Id !== "0" && this.state.isDeleteRight === false) &&
                  <button className="button-tertiary-disable mr-2" disabled={true}>{this.labels.HubButtonDelete}</button>
                }
              </div>
              <div>
                  <button className="button-primary ml-2" disabled={!this.state.isUpdateRight} onClick={() => this.validateAllForm()}>{this.labels.HubButtonSave}</button>
              </div>
            </div>
          </div>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProvidersDetails,
    mainPageTitle: state.mainPageTitle,
  };
};

export interface IProps extends RouteComponentProps, IPropsFromState
{
}

export default connect(mapStateToProps)(FacilityVisitDetails);
