import { Component } from "react";
import { Modal } from 'react-bootstrap';
import { appContext } from "../../AppContext";
import IbssButton from "./IbssButton";
import IbssDateTimePicker from "./Datepicker/IbssDateTimePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { DateTime } from "luxon";
import { DateHelper } from "../../Common/DateHelper";

export default class DateSelectModal extends Component<IProps, IState>
{
    private get labels() { return appContext().labels; }

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            start: props.start ?? DateTime.now(),
            end: props.end ?? DateTime.now(),
        };
    }

    public componentDidUpdate(prevProps: IProps, prevState: IState, snapshot?: IState): void
    {
        if (this.props.start != prevProps.start)
        {
            this.setState({ start: this.props.start ?? DateTime.now() });
        }
        if (this.props.end != prevProps.end)
        {
            this.setState({ end: this.props.end ?? DateTime.now() });
        }
    }

    private startChanged(value: Date | null): void
    {
        if(value) {
            const startDate =  value && DateTime.fromISO(value.toString()).toJSDate();
            this.setState({ start: DateTime.fromJSDate(startDate)});
        }
    }

    private endChanged(value: Date | null): void
    {
        if(value) {
            const endDate = value &&  DateTime.fromISO(value.toString()).toJSDate();
            this.setState({ end: DateTime.fromJSDate(endDate)});
        }
    }

    private okButtonClicked(): void
    {
        this.props.okButtonClicked(this.state.start, this.state.end);
    }

    public render(): JSX.Element
    {
        const { closeClicked, title, message } = this.props;
        const okButtonDisabled = (!this.state.start.isValid || !this.state.end.isValid || this.state.start > this.state.end);

        return (
            <Modal show={this.props.show} onHide={() => closeClicked()}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeClicked()} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <div style={{ paddingTop: '5px', paddingLeft: '58px' }} className="mb-4">{message}
                    <IbssDateTimePicker
                        className="d-block mb-4 mt-4"
                        label={this.labels.HubLabelFrom}
                        renderInput={(props) => <TextField {...props} error={false} />}
                        value={this.state.start.toJSDate()}
                        onChange={newValue => this.startChanged(newValue)}
                        inputFormat={"yyyy-MM-dd HH:mm"}
                        minDateTime={new Date()}
                        minutesStep={1}
                        ampm={false}
                    />
                    <IbssDateTimePicker
                        label={this.labels.HubLabelTo}
                        renderInput={(props) => <TextField {...props} error={false} />}
                        value={this.state.end.toJSDate()}
                        onChange={newValue => this.endChanged(newValue)}
                        inputFormat={"yyyy-MM-dd HH:mm"}
                        minDateTime={new Date()}
                        minutesStep={1}
                        ampm={false}
                    />
                </div>
                <Modal.Footer>
                    <div style={{ textAlign: 'center' }}>
                        <IbssButton
                            style={{ height: '45px', minWidth: '100px' }}
                            variant="contained"
                            disabled={okButtonDisabled}
                            onClick={() => this.okButtonClicked()}
                        >
                        {this.labels.HubLabelOk}
                        </IbssButton>
                        {okButtonDisabled && <p>{this.labels.HubLabelStartDateMustBeBeforeTheEndDate}</p>}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export interface IProps
{
    show: boolean;
    title: string;
    message: string;
    start?: DateTime;
    end?: DateTime;
    closeClicked: () => void;
    okButtonClicked: (start: DateTime, end: DateTime) => void;
};

export interface IState
{
    start: DateTime;
    end: DateTime;
}
