import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { CateringItemsFilter } from "./CateringItemRepository";

export class GetManyEndpoint implements IGetManyEndpoint
{
    public async execute(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>
    {
        try
        {
            
            const query =
                `schema=false&` +
                `showDebugStats=false&` +
                `$top=2000&` +
                `$select=Space_Catering_Id%2CSpace_Catering_Title%2CSpace_Catering_Menu%2CSpace_Catering_Short_Message%2CSpace_Catering_Cost%2CSpace_Catering_IsEnabled%2CSpace_Catering_IsPreBookable%2CSpace_Catering_section%2CSpace_Catering_IsRoomService&` +
                `$filter=${filter.toODataString()}`;

            const response = await axios.get<ISpaceCateringMenuItem[]>(`${apis.allApi}${buildingId}/SpaceCateringMenu?${query}`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IGetManyEndpoint
{
    execute(buildingId: number, filter: CateringItemsFilter): Promise<ISpaceCateringMenuItem[]>;
}


export interface ISpaceCateringMenuItem
{
    Space_Catering_Cost: number;
    Space_Catering_IsEnabled: number;
    Space_Catering_IsPreBookable: number;
    Space_Catering_IsRoomService: number;
    Space_Catering_Menu: string;
    Space_Catering_Short_Message: string;
    Space_Catering_Title: string;
    Space_Catering_Section:string;
    Space_Catering_Id: number;
}
