import React, { ChangeEvent, Component } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import { connect } from "react-redux";
import apis from "../../../../Providers.Api/apis";
import momentBusiness from 'moment-business-days';
import { getFloorUsingFloorId, getWkngDaysBySelectedBuilding, getWorkingDayArray, getBuildingTimeZoneByNodeId, getFloorNameUsingFloorAndBuildingId, getBuildingNodeIdUsingFloorNodeId } from "../../../../Common/Helper";
import ConfirmModal from "../../../../Components/uicomponents/ConfirmModal";
import Card from 'react-bootstrap/Card';
import LoadingOverlay from "../../../../Components/LoadingOverlay"
import { AxiosResponse } from "axios";
import { Modal, ModalFooter } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { Link, RouteComponentProps } from "react-router-dom";
import ViewStatus from "./ViewStatus";
import { appContext } from "../../../../AppContext";
import IbssSvgIcon from "../../../../Components/uicomponents/IbssSvgIcon";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssButtonRedo from "../../../../Components/uicomponents/IbssButton";
import IbssTimePicker from "../../../../Components/uicomponents/IbssTimePicker";
import IbssDatePicker from "../../../../Components/uicomponents/Datepicker/IbssDatePicker";
import IbssTextField from "../../../../Components/uicomponents/IbssTextField";
import IbssAutocomplete from "../../../../Components/uicomponents/IbssAutocomplete";
import { DateHelper } from "../../../../Common/DateHelper";
import IbssDialog from "../../../../Components/uicomponents/IbssDialog";
import CostCodeModal, { CostCodeWithAllocation } from "../../../../Components/CostCodeModal";
import { IUserPreferences } from "../../../../Providers.Api/UserPreferenceRepository";
import { IUserRights } from "../../../../Providers.Api/Models.UserApi";
import { DateTime, DurationObjectUnits } from "luxon";
import { ReactComponent as CostCodeIcon } from '../../../../Components/Sidebar/icons/CostCode.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { IPropsFromState } from "../../../../redux/Interfaces";
import { IBookingParty as IBookingPartyResponse } from "../../../../Providers.Api/BookingPartyRepository";
import { ClockPickerView } from "@mui/x-date-pickers/internals/models/views";
import { IBookingSlots } from "../../../../Providers.Api/BookingPolicies/BookingPolicyRepository";
import { UPDATE_MAIN_PAGE_TITLE } from "../../../../app/constants";
import { CateringTask } from "../../../../Providers.Api/Tasks/TaskRepository";
import { IGetV2BookingResponse, IGetV2BookingResponse_BookingParty } from "../../../../Providers.Api/Bookings/GetV2BookingEndpoint";
import { ICreateV2Booking_BookingParty, ICreateV2Booking_CostCodeAllocation, ICreateV2BookingRequest } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import { IUpdateBookingResponse } from "../../../../Providers.Api/Bookings/UpdateV1BookingEndpoint";
import { IUpdateV2BookingRequest } from "../../../../Providers.Api/Bookings/UpdateV2BookingEndpoint";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { IUser } from "../../../../Providers.Api/Users/UsersRepository";
import { Space } from "../../../../Providers.Api/Spaces/SpaceRepository";
import { wrap } from "yargs";
import customTheme from "../../../../customTheme";
import AttendeesDialog, { Attendee } from "./AttendeesDialog";

class EditBooking extends Component<IProps, State>
{
    private currentDate = DateHelper.fromIsoToJsDate(DateHelper.now().toString());
    private get alert() { return appContext().alert; }
    private get labels() { return appContext().labels; }
    private get session() { return appContext().sessionStorageProvider; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private get bookingService() { return appContext().bookingService; }
    private get appState() { return appContext().state; }
    private get apiCache() { return appContext().apiCache; }
    private bookingParties: IBookingPartyResponse[] = [];

    constructor(props: IProps)
    {
        super(props);
        this.state = new State();
        this.handleCostCodeModal = this.handleCostCodeModal.bind(this);
        this.updateBookingCostCodes = this.updateBookingCostCodes.bind(this);
    }

    public componentDidMount(): void
    {
        const { dispatch }: any = this.props;
        dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubMenuSearchaSpace });

        const searchCriteria = this.session.getFlexSpaceSearchCriteria();
        const startTime = searchCriteria.startTime.toJSDate();
        const endTime = searchCriteria.endTime.toJSDate();
        this.setState({ start: startTime, end: endTime, bookingDate: startTime });
        this.getDetails();
    }

    public componentDidUpdate(prevProps: IProps, prevState: State): void
    {
        // load cost codes associated with booking if nodeId !==0, bookingid and buildingid exists.
        if ((prevState.nodeId !== this.state.nodeId) && this.state.bookingid && this.state.buildingid)
        {
            this.loadBookingCostCodes();
        }
    }

    private async loadBookingCostCodes(): Promise<void>
    {
        this.setState({ isLoading: true });
        // check user has v2 rights and the booking Cost_Code_Allocation response is not string, which is returned in v1 calls.
        if (this.local.hasRight('API.Bookings.V2') && typeof this.state.costCodeAllocation !== 'string')
        {
            const bookingCostCodes = this.state.costCodeAllocation;

            // make a call to the cost codes api to get more info about cost codes than is available from booking's Cost_Code_Allocation field.
            const costCodeIds = bookingCostCodes.map(i => i.Cost_Code_Id);
            const costCodePromises = costCodeIds.map(id => this.apiClient.costCodes.getCostCodeById(id));
            const costCodes = await Promise.all(costCodePromises);

            const costCodesWithAllocation = costCodes.map(i =>
            {
                return new CostCodeWithAllocation(i, bookingCostCodes.filter(j => j.Cost_Code_Id === i.Cost_Code_Id)[0].Allocation);
            });

            this.setState({
                costCodes: costCodesWithAllocation, //costs codes associated with booking, which has to be displayed on this component.
            });
        }

        this.setState({
            isLoading: false,
        });

    }

    private updateBookingCostCodes(updatedCostCodes: CostCodeWithAllocation[]): void
    {
        this.setState({
            costCodes: updatedCostCodes
        })
    }

    private getMinutesStepFromPolicy(values: string[]): number
    {
        if (values.length == 0)
        {
            return 1; //Any
        }
        if (values[0] == '0' && values.length == 1)
        {
            return 60;
        }
        if (values[0] != '0' && values.length > 0)
        {
            return parseInt(values[0]);
        }
        else
        {
            return parseInt(values[1]);
        }
    }

    private async getDetails(): Promise<void>
    {
        const { match } = this.props;

        this.setState({ isLoading: true })
        let buildingIdMatchParam = match.params.buildingid;
        let buildingId = parseInt(buildingIdMatchParam);

        const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let buildingTimeZone = getBuildingTimeZoneByNodeId(buildingId);

        const getTzDiff = (timeZone = 'UTC', date = this.currentDate) =>
        {

            const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
            const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
            let offset = (tzDate.getTime() - utcDate.getTime()) / 6e4;
            offset = offset / 60;
            let sign = "UTC+";
            if (offset < 0)
            {
                sign = "-";
                offset *= -1;
            }
            let hours = "0" + Math.floor(offset).toString();
            let minutes = "0" + (Math.round(offset % 1 * 60)).toString();
            return sign + hours.substr(hours.length - 2) + ":" + minutes.substr(minutes.length - 2);
        }
        let localTime = ""

        if (buildingTimeZone == browserTz)
        {
            localTime = this.labels.HubLabelLocalTime;
        }
        else
        {
            localTime = getTzDiff(buildingTimeZone);
        }
        this.setState({
            timelabel: localTime
        })

        let bookingData: (IGetV2BookingResponse | null);
        let spaceIds: string[];

        const spaceData =  await this.apiCache.getSpace(buildingId, match.params.spaceId.split(";")[0]);

        if (match.params.bookingid == null) // new booking
        {
            bookingData = null;
            spaceIds = [match.params.spaceId]; // we expect exactly 1 space ID
        }
        else
        {
            bookingData = await this.bookingService.get(spaceData ? spaceData.Node_Id : 0, match.params.bookingid);
            spaceIds = bookingData.Space_Id.split(";"); // we expect at least one space
        }

        const spacesOrNulls = await Promise.all(spaceIds.map(i => this.apiClient.spaces.getV1SpaceById(spaceData? spaceData.Node_Id : buildingId, i)));
        const spaces = spacesOrNulls.filter(i => i != null) as Space[];
        const primarySpaceId = spaceIds[0];
        const primarySpace = spaces.find(i => i.Space_Id == primarySpaceId);

        if (primarySpace == null)
        {
            throw new Error("Primary space not found.");
        }

        const floorName = getFloorUsingFloorId(primarySpace.Node_Id)
        const spacesWithFloorName = spaces.map(i => ({ ...i, floorName } as ISpaceView));
        const primarySpaceWithFloorName = { ...primarySpace, floorName };
        const policyId = primarySpaceWithFloorName.Booking_Policy_Id;

        this.setState({
            primarySpace: primarySpaceWithFloorName,
            spaceStatus: primarySpace.Space_Status,
        });

        if (policyId && policyId != '0')
        {
            const bookingPolicy = await this.apiClient.bookingPolicies.getSpaceBookingPolicy(spaceData? spaceData.Node_Id : buildingId, primarySpaceId);
            this.setState({
                startIntervalMinutes: bookingPolicy.BookingSlots.BookingStart.SpecificMinutes,
                endIntervalMinutes: bookingPolicy.BookingSlots.BookingEnd.SpecificMinutes,
                bookingSlotsPolicy: bookingPolicy.BookingSlots ?? {}
            });
        }


        let rightList = this.local.getUserDetails().userRights as IUserRights;
        let rightsApiBookingArr = rightList?.API?.Bookings;
        let spaceFlag = false;

        if (primarySpaceWithFloorName.Space_Class === "Work" && rightsApiBookingArr.includes("BookLinkedSpaceWork"))
        {
            spaceFlag = true;
        }
        else if (primarySpaceWithFloorName.Space_Class === "Support" && rightsApiBookingArr.includes("BookLinkedSpaceSupport"))
        {
            spaceFlag = true;
        }
        else if (primarySpaceWithFloorName.Space_Class === "Amenity" && rightsApiBookingArr.includes("BookLinkedSpaceAmenity"))
        {
            spaceFlag = true;
        }

        if (spaceFlag)
        {
            if (primarySpaceWithFloorName.Space_Setup == 1)
            {
                this.setState({
                    showSpaceArrangement: true
                });
            }
            else if (primarySpaceWithFloorName.Space_Setup == 5)
            {
                this.setState({
                    showSpaceArrangement: true,
                    showSpaceLayout: true
                });
            }
        }

        if (match.params.bookingid != null && bookingData != null)
        {
            this.setState({ bookingid: match.params.bookingid });

            const duration = this.differenceInHoursAndMinutes(DateTime.fromISO(bookingData.Booking_Start).toJSDate(), DateTime.fromISO(bookingData.Booking_End).toJSDate());
            const timeDiff = this.durationToString(duration);

            const filteredBookingPartiesIsVisitors = bookingData.Booking_Parties.filter(i => i.Booking_Visitor === true);
            const filteredBookingPartiesIsNotVisitors = bookingData.Booking_Parties.filter(i => i.Booking_Visitor === false);

            const jsonSpaceLayout = primarySpaceWithFloorName.Space_Layout ? JSON.parse(primarySpaceWithFloorName.Space_Layout) : ""
            const bookingSpaceLayoutName = jsonSpaceLayout && jsonSpaceLayout.Layouts.filter((item: any) => item.Space_Id === bookingData?.Space_Id);
            const spaceArrangementOption = jsonSpaceLayout && jsonSpaceLayout?.Layouts.filter((x: { Name: string; }) => x.Name == bookingSpaceLayoutName[0].Name)[0].SeatingArrangements;
            this.getBookedParties(match.params.buildingid, match.params.bookingid)

            this.setState({
                spacesData: spacesWithFloorName,
                isLoading: false,
                name: bookingData.Booking_Name,
                description: bookingData.Booking_Description,
                start: DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                end: DateTime.fromISO(bookingData.Booking_End).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                bookingDate: DateTime.fromISO(bookingData.Booking_Start).offsetTimeByNode(bookingData.Node_Id, true).toJSDate(),
                timeDifference: timeDiff,
                onBehalfOfLabel: bookingData.Booking_Owner_Email ,
                onBehalfOf: bookingData.Booking_Owner_Email,
                bookingOwnerEmail: bookingData.Booking_Owner_Email,
                booking_Parties_array: filteredBookingPartiesIsNotVisitors.map(x => { return ({ email: x.Booking_Participant_Email, name: x.Booking_Participant_Name, organisation: x.Booking_Participant_Organisation, resourceId: x.Booking_Resource_Id, visitor: x.Booking_Visitor, type: x.Booking_Participant_Type, backgroundColor: "" }) }),
                booking_Parties_Visitors_array: filteredBookingPartiesIsVisitors.map(x => { return ({ email: x.Booking_Participant_Email, name: x.Booking_Participant_Name, organisation: x.Booking_Participant_Organisation, resourceId: x.Booking_Resource_Id, visitor: x.Booking_Visitor, type: x.Booking_Participant_Type, backgroundColor: "" }) }),
                spaceId: bookingData.Space_Id,
                selectedSpaceLayout: bookingData.Space_Id,
                selectedSpaceLayoutName: jsonSpaceLayout ? bookingSpaceLayoutName[0].Name : "",
                selectedSeatingArrangement: jsonSpaceLayout ? bookingData.Space_Layout : "",
                Space_Layout: jsonSpaceLayout ? jsonSpaceLayout.Layouts : [],
                SpaceArrangementList: jsonSpaceLayout ? spaceArrangementOption : [],
                Space_Setup: primarySpaceWithFloorName.Space_Setup,
                nodeId: primarySpaceWithFloorName.Node_Id ? primarySpaceWithFloorName.Node_Id : buildingId,
                buildingid: buildingId,
                bookingEarlyCheckIn: bookingData.Booking_Early_Checkin,
                costCodeAllocation: bookingData.Cost_Code_Allocation ? bookingData.Cost_Code_Allocation : '',
                viewStatusProps:
                {
                    _CreatedAt: bookingData._CreatedAt,
                    _CreatedBy: bookingData._CreatedBy,
                    bookingid: bookingData.Booking_Id,
                    metaExtBookingId: bookingData.Meta_Ext_Booking_Id,
                    Space_Layout: bookingData.Space_Layout,
                    bookingStatus: bookingData.Booking_Status,
                    isactive: bookingData.Booking_IsActive,
                    checkedin: bookingData.Booking_IsCheckedIn,
                    completed: bookingData.Booking_IsCompleted,
                    cancelled: bookingData.Booking_IsCancelled,
                    autocheckin: bookingData.Booking_AutoCheckIn,
                    earlycheckin: bookingData.Booking_IsEarly_Checkin,
                    lastcheckin: bookingData.Booking_IsLate_Checkin,
                    bookingCheckInTime: bookingData.Booking_Checkin_Time,
                    bookingCompletionTime: bookingData.Booking_Completed_Time,
                    bookingCancelletionTime: bookingData.Booking_Cancelled_Time,
                },
                bookingStatus: bookingData.Booking_Status,
                tab: parseInt(match.params.tab),
                isOnBehalfOfRight: rightsApiBookingArr.includes("BookOnBehalfOf") ? true : false,
                bookingResponse: bookingData
            })

            this.setCateringTotal();
            const editStatus = bookingData.Booking_Status === "Cancelled" || bookingData.Booking_Status === "Completed" || bookingData.Booking_Status === "No Show" || bookingData.Booking_Status === "Auto Cancelled";
            this.setState({
                isEnable: editStatus
            })
        }
        else
        {
            let myStart = this.currentDate;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                myStart = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal
            }

            const myStartDateTime = myStart.toISOString()
            const myStartDate = myStartDateTime.split('T')[0]

            const userPref = this.local.getUserPreferences();

            let startTime = `${this.zeroPad((this.currentDate.getHours()).toString())}:${this.zeroPad((this.currentDate.getMinutes()).toString())}`;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                var date = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal;
                startTime = DateTime.fromJSDate(date).toFormat("HH:mm")
            }

            let myEnd = this.currentDate
            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal != undefined)
            {
                myEnd = this.props.flexMySearchFilterCriteria.fmsfc_start_date_for_filter_modal
            }

            const myEndDateTime = myEnd.toISOString()
            const myEndDate = myEndDateTime.split('T')[0]

            let endTime = userPref?.WorkingHoursPrefs?.UserEndTime;

            if (Object.keys(this.props.flexMySearchFilterCriteria).length > 0 && this.props.flexMySearchFilterCriteria.End_Date_For_filter_modal != undefined)
            {
                var date = this.props.flexMySearchFilterCriteria.End_Date_For_filter_modal
                endTime = DateTime.fromJSDate(date).toFormat("HH:mm")
            }

            const myEndTime = new Date(myEndDate + 'T' + endTime);
            let bookingDate: Date | number = myEndTime;

            const getStrtDate: any = getWkngDaysBySelectedBuilding(userPref?.SearchPrefs?.DefaultBuilding);
            const Occ_Wkng_Days_Stt = getStrtDate?.Occ_Wkng_Days_Stt;
            const Occ_Wkng_Days_Stp = getStrtDate?.Occ_Wkng_Days_Stp;

            const workingDayArray = getWorkingDayArray(parseInt(Occ_Wkng_Days_Stt), parseInt(Occ_Wkng_Days_Stp))

            momentBusiness.updateLocale('us', {
                workingWeekdays: workingDayArray
            });

            // if no booking id, startTime used to calculate booking duration is set to user's preferred startTime. 
            startTime = userPref.WorkingHoursPrefs.UserStartTime;

            if (this.currentDate > myEndTime)
            {
                const todayTime: Date | number = this.currentDate;
                bookingDate = momentBusiness(todayTime).nextBusinessDay().toDate();

            }
            const myStartTime = DateHelper.fromIsoToJsDate(myStartDate + 'T' + startTime);

            const duration = this.differenceInHoursAndMinutes(this.state.start, this.state.end);
            const timeDiff = this.durationToString(duration);
            const jsonSpaceLayout = primarySpaceWithFloorName.Space_Layout ? JSON.parse(primarySpaceWithFloorName.Space_Layout) : ""
            const spaceArrangementOption = jsonSpaceLayout && jsonSpaceLayout?.Layouts[0].SeatingArrangements;

            let rightList = this.local.getUserDetails().userRights as IUserRights;
            let rightsApiBookingArr = rightList?.API?.Bookings;
            let flag = false;
            if (rightsApiBookingArr.includes("BookOnBehalfOf"))
            {
                flag = true;
            }

            this.setState({
                spacesData: spacesWithFloorName,
                isLoading: false,
                isCateringEnable: false,
                timeDifference: timeDiff,
                Space_Layout: jsonSpaceLayout ? jsonSpaceLayout.Layouts : [],
                SpaceArrangementList: jsonSpaceLayout ? spaceArrangementOption : [],
                selectedSpaceLayout: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Space_Id : "",
                selectedSpaceLayoutName: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Name : "",
                selectedSeatingArrangement: spaceArrangementOption ? spaceArrangementOption[0].Style : "",
                nodeId: primarySpaceWithFloorName.Node_Id ? primarySpaceWithFloorName.Node_Id : buildingId,
                buildingid: buildingId,
                Space_Setup: primarySpaceWithFloorName.Space_Setup,
                spaceId: jsonSpaceLayout ? jsonSpaceLayout.Layouts[0].Space_Id : match.params.spaceId,
                isOnBehalfOfRight: flag,
            });
        }
    }

    private zeroPad(numberStr: string): string
    {
        return numberStr.padStart(2, "0");
    }

    private async submit(): Promise<void>
    {
        // validate form
        let errors = new Array<string>();
        if (!this.state.isStartValid)
        {
            errors.push(this.labels.HubMessageFromIsNotValid);
        }
        if (!this.state.isEndValid)
        {
            errors.push(this.labels.HubMessageToIsNotValid);
        }
        if (errors.length > 0)
        {
            this.alert.show("Error", `<ul>${errors.map(i => `<li>${i}</li>`).join("")}</ul>`, undefined, { messageIsHtml: true });
            return;
        }

        // create / update booking
        this.setState({ isLoadingToSave: true });
        const { match } = this.props;
        const buildingId = parseInt(match.params.buildingid);
        const date = this.state.bookingDate;
        const startDateTime = DateTime.fromJSDate(date).set({ hour: this.state.start.getHours(), minute: this.state.start.getMinutes() }).setZoneByNode(buildingId, true).toUTC().toISO();
        const endDateTime = DateTime.fromJSDate(date).set({ hour: this.state.end.getHours(), minute: this.state.end.getMinutes() }).setZoneByNode(buildingId, true).toUTC().toISO();
        const isNew = (match.params.bookingid == null);

        if (isNew)
        {
            await this.create(this.state.nodeId, startDateTime, endDateTime);
        }
        else
        {
            await this.update(this.state.nodeId, this.state.bookingid, startDateTime, endDateTime);
        }
    }

    public async create(nodeid: number, startDate: string, endDate: string): Promise<void>
    {
        const payload: ICreateV2BookingRequest =
        {
            _CreatedAt: this.state._CreatedAt,
            _CreatedBy: this.state._CreatedBy,
            SpaceId: this.state.spaceId,
            Start: startDate,
            End: endDate,
            Name: this.state.name === '' ? 'Booking' : this.state.name,
            Description: this.state.description,
            BookingAutoCheckin: this.state.bookingAutoCheckin,
            DisableExtUpdate: false,
            Booking_Parties: this.state.booking_Parties_array.concat(this.state.booking_Parties_Visitors_array).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: '',
            })),
            Cost_Code_Allocation: this.state.costCodes.map(costCode =>
            ({
                Cost_Code: costCode.costCode,
                Cost_Code_Id: costCode.costCodeId,
                Allocation: costCode.allocation,
            })),
            AddOnlineMeetingLink: 0
        };

        if (this.state.selectedSeatingArrangement)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement;
        }
        if (this.state.onBehalfOf)
        {
            payload.OnBehalfOf = this.state.onBehalfOf;
            if (this.state.onBehalfOfData.isVisitor)
            {
                payload.Booking_Parties.push(
                    {
                        Booking_Participant_Email: this.state.onBehalfOfData.email,
                        Booking_Participant_Name: [this.state.onBehalfOfData.firstName, this.state.onBehalfOfData.lastName].join(" "),
                        Booking_Participant_Organisation: this.state.onBehalfOfData.company,
                        Booking_Participant_Type: 2,
                        Booking_Visitor: this.state.onBehalfOfData.isVisitor,
                        Booking_Resource_Id: '',
                    });
            }
        }

        try
        {
            this.setState({ isLoadingToSave: true, isLoading: true });
            const response = await this.bookingService.create(nodeid, payload);

            this.setState({
                bookingConfirmedModal: true,
                bookingid: response.Booking_Id,

            });

            this.setState({ bookingResponse: response });
        }
        finally
        {
            this.setState({ isLoadingToSave: false, isLoading: false });
        }
    }

    private async update(nodeId: number, bookingId: string, startDate: string, endDate: string): Promise<void>
    {
        const payload: IUpdateV2BookingRequest =
        {
            SpaceId: this.state.spaceId,
            StartTime: startDate,
            EndTime: endDate,
            PartyCount: 0,
            BookingName: this.state.name === '' ? 'Booking' : this.state.name,
            BookingDescription: this.state.description,
            Private: this.state.isPrivate,
            Organisation: "",
            Booking_Parties: this.state.booking_Parties_array.concat(this.state.booking_Parties_Visitors_array).map(x =>
            ({
                Booking_Participant_Email: x.email,
                Booking_Participant_Name: x.name,
                Booking_Participant_Organisation: x.organisation,
                Booking_Participant_Type: x.type,
                Booking_Visitor: x.visitor,
                Booking_Resource_Id: ''
            })),
            Cost_Code_Allocation: this.state.costCodes.map(costCode =>
            ({
                Cost_Code: costCode.costCode,
                Cost_Code_Id: costCode.costCodeId,
                Allocation: costCode.allocation,
            })),
            DisableExtUpdate: false,
        };

        if (this.state.selectedSeatingArrangement)
        {
            payload.Space_Layout = this.state.selectedSeatingArrangement
        }
        
        // payload.onBehalfOf has to be explicitly set, so it cannot be empty or null. otherwise the last person who edits a booking ends up becoming the booking owner.
        payload.OnBehalfOf = (this.state.onBehalfOf && this.local.hasRight("API.Bookings.BookOnBehalfOf")? this.state.onBehalfOf : this.state.bookingOwnerEmail);

        try
        {
            this.setState({ isLoadingToSave: true, isLoading: true });
            const response = await this.bookingService.update(nodeId, bookingId, payload);

            this.props.history.goBack();

            const bookingResponse = { ...response, Booking_Id: this.state.bookingid };
            this.setState({ bookingResponse: bookingResponse });
        }
        finally
        {
            this.setState({ isLoadingToSave: false, isLoading: false });
        }
    }

    public getBookedParties = (nodeId: any, Booking_Id: any) =>
    {
        apis.getBookingParties(nodeId, Booking_Id).then((res: AxiosResponse<IBookingPartyResponse[]>) =>
        {
            this.bookingParties = res.data;
            this.setEquipmentTotal();
        })
            .catch((error: any) =>
            {
            });
    }

    private handleHearingAndPresentationalAid(type: string): void
    {
        const { history, match } = this.props;

        if (this.state.spacesData[0].Meta_Serv_Reqs_Hearing === 1 && type === "HearingAid")
        {
            history.push({
                pathname: "/flex-Equipment/" + match.params.buildingid,
                state: {
                    bookingResponse: this.state.bookingResponse,
                    equipment_Type: type
                }
            })
        }

        if (this.state.spacesData[0].Meta_Serv_Reqs_Hearing === 1 && type === "PresentationAid")
        {
            history.push({
                pathname: "/flex-Equipment/" + match.params.buildingid,
                state: {
                    bookingResponse: this.state.bookingResponse,
                    equipment_Type: type,
                }
            })
        }
    }

    private cancelBooking(): void
    {
        const { match } = this.props;
        if (match.params.bookingid == null)
        {
            return;
        }

        this.setState({
            isShowModalCancel: !this.state.isShowModalCancel,
            isLoadingToSave: true,
        });

        this.bookingService.delete(parseInt(match.params.buildingid), match.params.bookingid).then((res) =>
        {
            const { history } = this.props;
            this.setState({ isLoadingToSave: false });
            this.alert.show(this.labels.HubLabelSuccess, res.Message, () => { history.push("/operational-services-bookings/") });

        }).catch((error: any) =>
        {
            this.setState({ isLoadingToSave: false });
        });
    };

    private change = (e: any) =>
    {
        const changeEvent = { [e.target.name]: e.target.value } as Pick<State, keyof State>
        this.setState(changeEvent);
    };

    private cancelDeleteModal = () =>
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
    }

    private delete = () =>
    {

    }

    private cancel(): void
    {
        this.props.history.goBack()
    }

    private redirectPath(): void
    {
        const { history } = this.props;
        let activeUrl = window.location.href;

        if (this.state.onBehalfOf !== "" || activeUrl.includes("flex-my-bookings") || activeUrl.includes("flex-find-a-space"))
        {
            history.push("/flex-home");
        }
        else
        {
            history.push("/operational-services-home");
        }
    };

    private handleModal = () =>
    {
        this.setState({
            show: !this.state.show,
        })
    };

    private setNoonTime(): Date
    {
        return DateTime.local().startOf('day').toJSDate();
    }

    private setChecked = (e: any) =>
    {
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 1)
        {
            this.setState({ bookingAutoCheckin: 0 });
        }
        if (e.target.name === 'bookingAutoCheckin' && this.state.bookingAutoCheckin === 0)
        {
            this.setState({ bookingAutoCheckin: 1 });
        }
    };

    private startTimeChanged(time: (Dayjs | null)): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        const startTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));
        let endTime = (!this.state.isEndValid ? DateHelper.null() : DateTime.fromJSDate(this.state.end).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            endTime = startTime.clone();
        }

        if (startTime.isValid && endTime.isValid && endTime.diff(startTime, ["minutes"]).minutes < 5)
        {
            const diff = endTime.diff(startTime, ["minutes"]).minutes;
            endTime = endTime.plus({ minutes: 5 - diff });
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : this.durationToString(duration));

        this.setState(
            {
                start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isStartValid: startTime.isValid,
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
            });
    }

    private endTimeChanged(time: (Dayjs | null)): void
    {
        const date = DateTime.fromJSDate(this.state.bookingDate).date();
        let startTime = (!this.state.isStartValid ? DateHelper.null() : DateTime.fromJSDate(this.state.start).set({ year: date.year, month: date.month, day: date.day }));
        const endTime = ((time == null || !time.isValid()) ? DateHelper.null() : DateTime.fromISO(time.toISOString()).set({ year: date.year, month: date.month, day: date.day }));

        if (startTime.isValid && endTime.isValid && startTime > endTime)
        {
            startTime = endTime.clone();
        }

        if (startTime.isValid && endTime.isValid && endTime.diff(startTime, ["minutes"]).minutes < 5)
        {
            const diff = endTime.diff(startTime, ["minutes"]).minutes;
            startTime = startTime.minus({ minutes: 5 - diff });
        }

        const duration = (startTime.isValid && endTime.isValid ? endTime.diff(startTime, ["hours", "minutes"]) : null);
        const durationAsString = (duration == null ? "-" : this.durationToString(duration));

        this.setState(
            {
                start: (startTime.isValid ? startTime.toJSDate() : this.state.start),
                end: (endTime.isValid ? endTime.toJSDate() : this.state.end),
                isStartValid: startTime.isValid,
                isEndValid: endTime.isValid,
                timeDifference: durationAsString,
            });
    }

    private handleDateChange(event: Date): void
    {

        const selectedDate = new Date(event);

        const userPref = this.local.getUserPreferences();
        const datetime = DateTime.fromJSDate(selectedDate);
        const isToday = datetime.hasSame(DateHelper.now(), 'day');

        let startTime = this.currentDate;

        const myStartDateTime = startTime.toISOString();
        const myStartDate = myStartDateTime.split('T')[0];

        const userStartTime = userPref?.WorkingHoursPrefs?.UserStartTime;

        if (!isToday)
        {
            startTime = DateHelper.fromIsoToJsDate(myStartDate + 'T' + userStartTime);
            ;
        }

        this.setState({ bookingDate: selectedDate });
    }

    private changeTitle = (e: any) =>
    {
        const changeEvent = { [e.target.name]: e.target.value } as Pick<State, keyof State>
        this.setState(changeEvent);

        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value).then(res =>
            {
                const newArray = res && res[0] && res.map(item =>
                {
                    let newItem = { ...item, label: '' }
                    newItem.label = item.displayName ? `${item.displayName} (${item.email})` : item.email;
                    newItem.displayName = item.displayName ? item.displayName : item.email;
                    newItem.lastName = newItem.lastName ? newItem.lastName : ""
                    newItem.firstName = newItem.firstName ? newItem.firstName : ""

                    return newItem
                })

                this.setState({
                    userList: (newArray && newArray[0]) ? newArray : [] // useing only label & email
                })
            }).catch((error: any) => { });
        } else
        {
            this.setState({
                userList: []
            })
        }
    }

    private handleSelectOnBehalfOf = (e: any, newValue: any) =>
    {
        if (newValue)
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    Email: newValue.email,
                    First_Name: newValue.firstName,
                    Last_Name: newValue.lastName,
                    Company: newValue.company,
                    displayName: newValue.displayName
                },
                noOnBehalfOfSelectedError: ""
            })
        } else
        {
            this.setState({
                nonVisitorOnBehalfOfData: {
                    Email: "",
                    First_Name: "",
                    Last_Name: "",
                    Company: "",
                    displayName: ""
                }
            })
        }
    }

    private handleBookingForEmployee = () =>
    {
        this.setState({
            isVisitor: false,
            active: true,
        })
    }

    private handleBookingForVisitor = () =>
    {
        this.setState({
            isVisitor: true,
        })
    }

    private handleInputChangeBookingOnBehalfOfData = (e: any) =>
    {
        const { type, value } = e.target;
        let errors: string = '';
        if (type === 'email')
        {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );

            if (!pattern.test(value))
            {
                // update error massage in errors.email  object
                errors = this.labels.HubLabelInvalidEmailAddress;;
            }
            // updated state for email errors
            if (errors === "")
            {
                this.setState({
                    isDisabled: false
                })
            } else
            {
                this.setState({
                    isDisabled: true
                })
            }
            this.setState({
                errors: errors,
            });
        }

        this.setState({
            visitorOnBehalfOfData: {
                ...this.state.visitorOnBehalfOfData,
                [e.target.name]: e.target.value,
            },
            noOnBehalfOfSelectedError: ""
        })
    };

    private async handleAddBookingFor(): Promise<void>
    {
        if ((this.state.nonVisitorOnBehalfOfData.Email !== "" || this.state.visitorOnBehalfOfData.Email !== "") && this.state.errors === "")
        {
            if (this.state.nonVisitorOnBehalfOfData.Email !== "")
            {
                await this.setState({
                    onBehalfOf: this.state.nonVisitorOnBehalfOfData.Email,
                    onBehalfOfLabel: this.state.nonVisitorOnBehalfOfData.First_Name,
                    onBehalfOfData:
                    {
                        email: this.state.nonVisitorOnBehalfOfData.Email,
                        firstName: this.state.nonVisitorOnBehalfOfData.First_Name,
                        lastName: this.state.nonVisitorOnBehalfOfData.Last_Name,
                        company: this.state.nonVisitorOnBehalfOfData.Company,
                        isVisitor: false,
                    }
                })
            }
            else
            {
                await this.setState({
                    onBehalfOf: this.state.visitorOnBehalfOfData.Email,
                    onBehalfOfLabel: this.state.visitorOnBehalfOfData.First_Name,
                    onBehalfOfData:
                    {
                        email: this.state.visitorOnBehalfOfData.Email,
                        firstName: this.state.visitorOnBehalfOfData.First_Name,
                        lastName: this.state.visitorOnBehalfOfData.Last_Name,
                        company: this.state.visitorOnBehalfOfData.Company,
                        isVisitor: true,
                    }
                })
            }
            this.handleShowHideBookingForModal();
        }
        else
        {
            this.setState({ noOnBehalfOfSelectedError: this.labels.HubLabelNoOnBehalfSelcted });
        }
    }

    private handleShowHideViewStatusModal = () =>
    {
        this.setState({
            isViewStatusShowModal: !this.state.isViewStatusShowModal,
        })
    };

    private handleShowHearingAidsStatusModal(): void
    {
        let { history, match } = this.props;
        this.setState({ bookingConfirmedModal: !this.state.bookingConfirmedModal });
        this.redirectPath();
    }

    private handleShowHideBookingForModal = async () =>
    {
        await this.setState({
            isShowModalBookingsFor: !this.state.isShowModalBookingsFor,
        })
        this.setState(
        {
            isVisitor: false,
            nonVisitorOnBehalfOfData: { Email: "", First_Name: "", Last_Name: "", Company: "", displayName: "" },
            visitorOnBehalfOfData: { Email: "", First_Name: "", Last_Name: "", Company: "", },
            noOnBehalfOfSelectedError: "",
            onBehalfOfLabel: this.state.isShowModalBookingsFor === false ? this.state.onBehalfOfLabel : "",
            onBehalfOf: this.state.isShowModalBookingsFor === false ? this.state.onBehalfOf : "",
            })
    };

    private handleShowHideLayoutModal = () =>
    {
        this.setState({ isShowModalLayout: !this.state.isShowModalLayout });
    };

    private handleShowCancelMOdal = () =>
    {
        this.setState({
            isShowModalCancel: !this.state.isShowModalCancel,
        })
    };

    private handleCostCodeModal(): void
    {
        if (!this.state.isEnable)
        {
            this.setState((prevState) => ({
                showCostCodeModal: !prevState.showCostCodeModal
            }))
        }
    }

    private extractFirstLetters = (First_Name: string, Last_Name: string) =>
    {
        let firstName = First_Name?.charAt(0).toUpperCase();
        let lastName = Last_Name?.charAt(0).toUpperCase();

        let firstLetters = firstName + lastName;

        return firstLetters;
    };

    private handleSpaceLayotChange = (selectedLayout: any) =>
    {
        if (this.state.selectedSpaceLayout === selectedLayout.Space_Id)
        {
            this.setState({
                selectedSpaceLayout: "",
                selectedSpaceLayoutName: ""
            })
        } else
        {
            this.setState({
                selectedSpaceLayout: selectedLayout.Space_Id,
                selectedSpaceLayoutName: selectedLayout.Name,
                SpaceArrangementList: selectedLayout.SeatingArrangements,
                selectedSeatingArrangement: selectedLayout.SeatingArrangements[0].Style,
                spaceId: selectedLayout.Space_Id,
            })
        }
    }

    private handleSeatingArrangementChange = (selectedSeating: any) =>
    {
        if (this.state.selectedSeatingArrangement === selectedSeating.style)
        {
            this.setState({
                selectedSeatingArrangement: ""
            })
        } else
        {
            this.setState({
                selectedSeatingArrangement: selectedSeating.Style
            })
        }
    }

    private isDateUnavailable(date: Date): boolean
    {
        // Slots check
        let activeSlotDays = this.state.bookingSlotsPolicy.BookableTime?.map(x => x.Days).flat(1);
        if (activeSlotDays?.includes('7'))
        {
            activeSlotDays = [...activeSlotDays, '0']; // sunday in the policy is 7 but in datpicker is 0
        }
        const daySlotAvailable = activeSlotDays?.includes((new Date(date).getDay()).toString());

        // check excluded dates
        let dateExcluded = false;
        const excludedDates = this.state.bookingSlotsPolicy?.ExcludedDates;
        let excludedDateIndex = 0;

        while (excludedDateIndex < excludedDates?.length)
        {
            const endOfDay = new Date(new Date(new Date(date).setHours(23)).setMinutes(59));
            if (new Date(date) > new Date(`${excludedDates[excludedDateIndex].StartDate}T${excludedDates[excludedDateIndex].StartTime}`) && endOfDay <= new Date(`${excludedDates[excludedDateIndex].EndDate}T${excludedDates[excludedDateIndex].EndTime}`))
            {
                dateExcluded = true;
            }
            excludedDateIndex += 1;
        }

        return !daySlotAvailable || dateExcluded;
    }

    private isTimeUnavailable(time: number, type: ClockPickerView, pickerType: ('start' | 'end')): boolean
    {
        // check the available slot time
        const selectedDayNumber = new Date(this.state.bookingDate).getDay() == 0 ? '7' : new Date(this.state.bookingDate).getDay().toString();
        const pickerStartDateTime = new Date(this.state.bookingDate.getFullYear(), this.state.bookingDate.getMonth(), this.state.bookingDate.getDate(), this.state.start.getHours(), time, 0, 0);
        const pickerEndDateTime = new Date(this.state.bookingDate.getFullYear(), this.state.bookingDate.getMonth(), this.state.bookingDate.getDate(), this.state.end.getHours(), time, 0, 0);

        let selectedDaySlotTimes = this.state.bookingSlotsPolicy.BookableTime?.filter(x => pickerType == 'start' ?
            x.Days.includes(selectedDayNumber) :
            x.Days.includes(selectedDayNumber) && pickerStartDateTime.getHours() >= parseInt(x.StartTime.substring(0, 2)));

        let validSlotTime = false;
        if (selectedDaySlotTimes?.length == 0)
        {
            validSlotTime = false;
        }
        else
        {
            let selectedDaySlotIndex = 0;
            while (selectedDaySlotIndex < selectedDaySlotTimes?.length)
            {
                const startTimeHours = selectedDaySlotTimes[selectedDaySlotIndex].StartTime.substring(0, 2);
                const endTimeHours = selectedDaySlotTimes[selectedDaySlotIndex].EndTime.substring(0, 2);
                const startTimeMinutes = selectedDaySlotTimes[selectedDaySlotIndex].StartTime.substring(3, 5);
                const endTimeMinutes = selectedDaySlotTimes[selectedDaySlotIndex].EndTime.substring(3, 5);

                const startTime = new Date(this.state.bookingDate.getFullYear(), this.state.bookingDate.getMonth(), this.state.bookingDate.getDate(), parseInt(startTimeHours), parseInt(startTimeMinutes), 0, 0);
                const endTime = new Date(this.state.bookingDate.getFullYear(), this.state.bookingDate.getMonth(), this.state.bookingDate.getDate(), parseInt(endTimeHours), parseInt(endTimeMinutes), 0, 0);

                if (pickerType == 'start')
                {
                    if ((type == 'hours' && (time >= parseInt(startTimeHours)) && (time <= parseInt(endTimeHours))))
                    {
                        validSlotTime = true;
                    }
                    if (type == 'minutes' && pickerStartDateTime >= startTime && pickerStartDateTime <= endTime)
                    {
                        validSlotTime = true;
                    }
                }

                if (pickerType == 'end')
                {
                    if ((type == 'hours' && (time >= parseInt(startTimeHours)) && (time <= parseInt(endTimeHours))))
                    {
                        validSlotTime = true;
                    }
                    if (type == 'minutes' && pickerType == 'end' && pickerEndDateTime <= endTime)
                    {
                        validSlotTime = true;
                    }
                }

                selectedDaySlotIndex += 1;
            }
        }

        // check the excluded date start/end boundary time
        let timeExcluded = false
        if (this.state.bookingSlotsPolicy?.ExcludedDates?.length > 0)
        {
            let excludedDateIndex = 0;
            while (excludedDateIndex < this.state.bookingSlotsPolicy?.ExcludedDates.length)
            {
                const excludedDateStart = new Date(`${this.state.bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.StartDate}T${this.state.bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.StartTime}`);
                const excludedDateEnd = new Date(`${this.state.bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.EndDate}T${this.state.bookingSlotsPolicy?.ExcludedDates[excludedDateIndex]?.EndTime}`);
                const pickerDay = new Date(pickerStartDateTime.getFullYear(), pickerStartDateTime.getMonth(), pickerStartDateTime.getDate(), 0, 0, 0, 0);
                const pickerEndDay = new Date(pickerEndDateTime.getFullYear(), pickerEndDateTime.getMonth(), pickerEndDateTime.getDate(), 0, 0, 0, 0);
                const startDay = new Date(excludedDateStart.getFullYear(), excludedDateStart.getMonth(), excludedDateStart.getDate(), 0, 0, 0, 0);
                const endDay = new Date(excludedDateEnd.getFullYear(), excludedDateEnd.getMonth(), excludedDateEnd.getDate(), 0, 0, 0, 0);

                if (type == 'hours')
                {
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && (time > excludedDateStart.getHours() && time < excludedDateEnd.getHours())))
                    {
                        timeExcluded = true;
                    }
                    if (startDay.toString() != endDay.toString() && (pickerDay.toString() == startDay.toString() && (time > excludedDateStart.getHours())))
                    {
                        timeExcluded = true;
                    }
                    if (startDay.toString() != endDay.toString() && (pickerDay.toString() == endDay.toString() && (time < excludedDateEnd.getHours())))
                    {
                        timeExcluded = true;
                    }
                }
                if (type == 'minutes')
                {
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && excludedDateStart.getHours() == this.state.start.getHours() && time > excludedDateStart.getMinutes()))
                    {
                        timeExcluded = true;
                    }
                    if ((pickerDay.toString() == startDay.toString() && startDay.toString() == endDay.toString() && excludedDateEnd.getHours() == this.state.start.getHours() && time < excludedDateEnd.getMinutes()))
                    {
                        timeExcluded = true;
                    }
                    if (pickerType == 'start')
                    {
                        if (startDay.toString() != endDay.toString() && pickerDay.toString() == startDay.toString() && (time > excludedDateStart.getMinutes()) && excludedDateStart.getHours() == this.state.start.getHours())
                        {
                            timeExcluded = true;
                        }
                        if (startDay.toString() != endDay.toString() && (pickerDay.toString() == endDay.toString() && (time < excludedDateEnd.getMinutes()) && excludedDateEnd.getHours() == this.state.start.getHours()))
                        {
                            timeExcluded = true;
                        }
                    }
                    if (pickerType == 'end')
                    {
                        if (startDay.toString() != endDay.toString() && pickerEndDay.toString() == endDay.toString() && (time > excludedDateStart.getMinutes()) && excludedDateStart.getHours() == this.state.end.getHours())
                        {
                            timeExcluded = true;
                        }
                        if (startDay.toString() != endDay.toString() && (pickerEndDay.toString() == endDay.toString() && (time < excludedDateEnd.getMinutes()) && excludedDateEnd.getHours() == this.state.end.getHours()))
                        {
                            timeExcluded = true;
                        }
                    }
                }
                excludedDateIndex += 1;
            }
        }

        let exceedingMaxDuration = false;
        let belowMinDuration = false;
        let fixLimitInvalid = false
        if (pickerType == 'end')
        {
            //check fixed duration
            if (this.state.bookingSlotsPolicy?.BookingDuration?.Fixed.length > 0)
            {
                const fixedLimitList = this.state.bookingSlotsPolicy?.BookingDuration?.Fixed;
                const validTimes = fixedLimitList.map((limit) => new Date(new Date(this.state.start).setMinutes(this.state.start.getMinutes() + parseInt(limit))))
                if (type == 'hours')
                {
                    const validHours = fixedLimitList.map((limit) => new Date(new Date(this.state.start).setMinutes(this.state.start.getMinutes() + parseInt(limit))).getHours())
                    fixLimitInvalid = !validHours?.includes(time);
                }
                if (type == 'minutes')
                {
                    const selectedHour = validTimes.filter((value) =>
                    {
                        if (value.getHours() == this.state.end.getHours())
                        {
                            return value
                        }
                    })
                    const validMins = selectedHour.map((value) => value.getMinutes())
                    fixLimitInvalid = !(validMins?.includes(time));
                }
            }
            else
            {
                //check max/min duration
                let maxTime = new Date(new Date(this.state.start).setMinutes(this.state.start.getMinutes() + this.state.bookingSlotsPolicy?.BookingDuration?.Maximum));
                if (maxTime.toString() != 'Invalid Date' && maxTime?.getDate() != this.state.start.getDate())
                {
                    maxTime = new Date(new Date(new Date(this.state.start).setMinutes(59)).setHours(23));
                }
                if (type == 'hours' && time > maxTime.getHours())
                {
                    exceedingMaxDuration = true;
                }
                if (type == 'minutes' && this.state.end.getHours() == maxTime.getHours() && time > maxTime.getMinutes())
                {
                    exceedingMaxDuration = true;
                }

                const minTime = new Date(new Date(this.state.start).setMinutes(this.state.start.getMinutes() + this.state.bookingSlotsPolicy?.BookingDuration?.Minimum));
                if (type == 'hours' && time < minTime.getHours())
                {
                    belowMinDuration = true;
                }
                if (type == 'minutes' && this.state.end.getHours() == minTime.getHours() && time < minTime.getMinutes())
                {
                    belowMinDuration = true;
                }
            }
        }



        //specific start intervals check
        let specificStartTimeInvalid = false;
        if (pickerType == 'start' && this.state.bookingSlotsPolicy?.BookingStart?.SpecificTimes.length > 0)
        {
            if (type == 'hours')
            {
                const validHours = this.state.bookingSlotsPolicy?.BookingStart?.SpecificTimes.map(x => parseInt(x.substring(0, 2)));
                specificStartTimeInvalid = !validHours?.includes(time);
            }
            if (type == 'minutes')
            {
                const validMinutes = this.state.bookingSlotsPolicy?.BookingStart?.SpecificTimes.filter(x => parseInt(x.substring(0, 2)) == this.state.start.getHours()).map(x => parseInt(x.substring(3, 5)));
                specificStartTimeInvalid = !validMinutes?.includes(time);
            }
        }

        //specific end intervals check
        let specificEndTimeInvalid = false;
        if (pickerType == 'end' && this.state.bookingSlotsPolicy?.BookingEnd?.SpecificTimes.length > 0)
        {
            if (type == 'hours')
            {
                const validHours = this.state.bookingSlotsPolicy?.BookingEnd?.SpecificTimes.map(x => parseInt(x.substring(0, 2)));
                specificEndTimeInvalid = !validHours?.includes(time);
            }
            if (type == 'minutes')
            {
                const validMinutes = this.state.bookingSlotsPolicy?.BookingEnd?.SpecificTimes.filter(x => parseInt(x.substring(0, 2)) == this.state.end.getHours()).map(x => parseInt(x.substring(3, 5)));
                specificEndTimeInvalid = !validMinutes?.includes(time);
            }
        }
        return timeExcluded || !validSlotTime || exceedingMaxDuration || belowMinDuration || specificStartTimeInvalid || specificEndTimeInvalid || fixLimitInvalid;
    }

    private handleAddLayoutAndSeating = () =>
    {
        this.handleShowHideLayoutModal()
    }

    private differenceInHoursAndMinutes(start: Date, end: Date): DurationObjectUnits
    {
        return DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start), ["hours", "minutes"]).toObject();
    }

    private durationToString(duration: DurationObjectUnits)
    {
        const hourDiff = duration?.hours;
        const minuteDiff = duration?.minutes !== undefined && duration?.minutes % 60
        return `${hourDiff} hr ${minuteDiff === 0 ? "" : minuteDiff} ${minuteDiff === 0 ? "" : "min"}`
    }

    private async setEquipmentTotal(): Promise<void>
    {
        let i = 0;
        let hearingAidTotal = 0;
        let presetationAidTotal = 0;
        while (i < this.bookingParties.length)
        {
            const details = await apis.getEquipmentDetails(1, this.bookingParties[i].Booking_Resource_Id);
            if (details.data.Equip_Type == 'HearingAid')
            {
                hearingAidTotal = hearingAidTotal + 1;
            }
            if (details.data.Equip_Type == 'PresentationAid')
            {
                presetationAidTotal = presetationAidTotal + 1;
            }
            i += 1;
        }

        this.setState({ hearingAidTotal: hearingAidTotal, presentationAidTotal: presetationAidTotal })
    }

    private async setCateringTotal(): Promise<void>
    {
        const { match } = this.props;
        if (match.params.bookingid == null)
        {
            return;
        }

        if (this.state.spacesData[0].Meta_Serv_Reqs_Catering === 1)
        {
            this.setState({ isCateringEnable: true });
            try 
            {
                const details = await this.apiClient.tasks.getTasksByBookingId(CateringTask, this.state.spacesData[0].Node_Id, match.params.bookingid);
                const cateringTask = details.filter(x => x.Task_Category_Name == 'Catering')[0];
                if (cateringTask)
                {
                    const taskOrderItemsData = await this.apiClient.cateringOrders.getTaskOrderItems(this.state.spacesData[0].Node_Id, cateringTask.Task_Id.toString());
                    const cateringTotal = taskOrderItemsData.map(x => parseInt(x.Catering_Order_Item_Quantity)).reduce((a, b) => a + b, 0);
                    this.setState({ isCateringEnable: false, cateringTotal: cateringTotal, cateringTask: cateringTask })
                }
                else 
                {
                    this.setState({
                        isCateringEnable: false
                    })
                }
            }
            catch (error) 
            {
                this.setState({
                    isCateringEnable: false
                })
                console.log(error);
            }
        }
    }

    public handleCateringRoute(): void
    {
        const { history } = this.props;
        const activeurl = window.location.href;
        if (this.state.cateringTask)
        {
            if (activeurl.includes("flex-my-bookings"))
            {
                this.props.history.push("/flex-my-bookings/" + this.state.buildingid + "/catering/" + this.state.cateringTask.Task_Id + "/edit")
            }
            else
            {
                this.props.history.push("/operational-services-catering/" + this.state.buildingid + "/catering/" + this.state.cateringTask.Task_Id + "/edit")
            }
        }
        else
        {
            history.push('/create-catering-order/' + this.state.buildingid + '/' + this.state.bookingid + '/' + this.state.spaceId)
        }
    }

    private getCostCodeLabel(): string
    {
        /* return the string that is returned on the cost code button*/
        const numberOfCostCodes = this.state.costCodes.length;
        if (numberOfCostCodes === 1)
        {
            return this.state.costCodes[0].costCode; //if just one cost code, show the cost code
        }
        else if (numberOfCostCodes > 1)
        {
            return `${this.state.costCodes.length} ${this.labels.HubLabelCostCodes}` // Cost Codes
        }
        else
        {
            return '';
        }
    }

    private async checkIn(): Promise<void> 
    {
        // checkin with either the state.onBehalfOf email address or, if that is an empty string, use the user's email address.
        const checkInEmail = this.state.onBehalfOf || this.state.bookingOwnerEmail;

        try
        {
            this.setState({ isLoading: true });
            const spaceId = this.state.spaceId.split(';')[0];
            await this.apiClient.spaces.checkIntoSpace(this.state.buildingid, spaceId, checkInEmail);
            this.setState({ isLoading: false });
            this.alert.show("", this.labels.HubLabelBookingCheckInSuccessfully);
            this.props.history.goBack();
        }
        catch
        {
            this.setState({ isLoading: false });
        }
    }

    public render(): JSX.Element
    {
        const { history }: any = this.props;
        const { Space_Arrangement, Space_Layout, name, _CreatedBy, description, bookingid, isPrivate, allDay, isactive, checkedin, completed, cancelled, autocheckin, earlycheckin, lastcheckin, bookingStatus, bookingCheckInTime, bookingCompletionTime, bookingCancelletionTime, bookingAutoCheckin }: any = this.state;
        const propsToSend = { ...this.props, spaceTitle: this.state.bookingid ? this.labels.HubLabelEditYourBooking : this.labels.HubLabelConfirmYourBooking }
        const confModalData = this.state.isDelete ? {
            name: "",
            show: this.state.show,
            handleModal: this.cancelDeleteModal,
            okButton: this.delete,
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }
            : {
                name: "",
                show: this.state.show,
                handleModal: this.handleModal,
                okButton: () => { this.redirectPath() },
                modalHeading: this.labels.HubLabelConfirmation,
                modalMessage: this.labels.HubLabelConfirmCancelMessage,
            }

        const updateBookingBtnDisable = (this.state.bookingStatus === "Cancelled" || this.state.bookingStatus === "Completed" || this.state.bookingStatus === "No Show" || this.state.bookingStatus === "Auto Cancelled");
        const cancelBookingBtnDisable = (this.state.bookingStatus === "Early Check In" || this.state.bookingStatus === "In Progress" || this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" || this.state.bookingStatus === "No Show" || this.state.bookingStatus === "Auto Cancelled" || this.state.bookingStatus === "Checked In");
        const checkInEnabled = (
            (this.state.spaceStatus === "S28" || this.state.spaceStatus === "S38" || this.state.spaceStatus === "S39") &&
                DateTime.fromISO(this.state.bookingEarlyCheckIn) < DateTime.utc() &&
                !cancelBookingBtnDisable  // Disable if the booking is in progress or completed
        );
        const bookingConfirmationHasNoFurtherAction = this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 0 && this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 0 && this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 0;
        const primarySpace = this.state.primarySpace;

        return (
            <>

                {(this.state.isLoading || this.state.isLoadingToSave) && <LoadingOverlay />}

                <div className="page-container co-md-4 ">
                    <AttendeesDialog
                        show={this.state.isShowModalAttendees}
                        onShowChange={i => this.setState({ isShowModalAttendees: i })}
                        isVisitor={this.state.isVisitor}
                        onIsVisitorChange={i => this.setState({ isVisitor: i })}
                        booking_Parties_array={this.state.booking_Parties_array}
                        onBookingPartiesChange={i => this.setState({ booking_Parties_array: i })}
                        booking_Parties_Visitors_array={this.state.booking_Parties_Visitors_array}
                        onBookingPartyVisitorsChange={i => this.setState({ booking_Parties_Visitors_array: i })}
                        allAttendees={this.state.allAttendees}
                        onAllAttendeesChange={i => this.setState({ allAttendees: i })}
                        isDisabled={this.state.isDisabled}
                        spacesData={this.state.spacesData}
                    />

                    {/* -------------------start booking made for popup------------------- */}
                    <IbssDialog
                        open={this.state.isShowModalBookingsFor}
                        fullWidth
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelBookOnBehalfHeading}</label>
                            </>
                        }
                        content=
                        {
                            <div>

                                <div className="d-flex justify-content-between">
                                    <label className="lable-modal">{this.labels.HubLabelChooseBookingSpaceFor}</label>
                                    {/* <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.handleChangeVisitor()} /> */}
                                </div>

                                <div className="mb-3">
                                    <button type="button" className={`${this.state.isVisitor ? 'btn-popup-tab' : 'btn-popup-tab-active'} mr-2`} onClick={() => this.handleBookingForEmployee()}>{this.labels.HubLabelEmployee}</button>
                                    <button type="button" className={`${!this.state.isVisitor ? 'btn-popup-tab' : 'btn-popup-tab-active'}`} onClick={() => this.handleBookingForVisitor()}>{this.labels.HubLabelvisitor}</button>
                                </div>
                                <div className="text-danger">{this.state.noOnBehalfOfSelectedError && this.state.noOnBehalfOfSelectedError}</div>

                                <hr className="m-0 mx-2" />

                                {!this.state.isVisitor &&
                                    <div className="mt-2">
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelEnterNameOrEmailOfEmployee}</label>
                                            <IbssAutocomplete
                                                id="free-solo-demo"
                                                className="mt-8"
                                                freeSolo
                                                options={this.state.userList}
                                                value={this.state.onBehalfOfLabel}
                                                onChange={(e, newValue) => this.handleSelectOnBehalfOf(e, newValue)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        className="form-control modal-attendees-form w-100 border-1"
                                                        name="onBehalfOfLabel"
                                                        id="onBehalfOfLabel"
                                                        placeholder={this.labels.HubLabelNameOrEmailLabel}
                                                        value={this.state.onBehalfOfLabel}
                                                        onBlur={(e) => { this.changeTitle(e); }}
                                                        onChange={(e) => { this.changeTitle(e); }}
                                                        size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    />}
                                            />
                                            <div className="text-danger">{this.state.alreadyPresentError}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.isVisitor &&
                                    <>
                                        <div className="mt-1">
                                            <label className="lable-modal">{this.labels.HubLabelNotMemberOfStaff}</label>
                                        </div>
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelEmailAddress}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="email"
                                                    name="Email"
                                                    value={this.state.visitorOnBehalfOfData.Email}
                                                    onChange={(e) => { this.handleInputChangeBookingOnBehalfOfData(e) }}
                                                    placeholder={this.labels.HubLabelemailPlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                            <div className="text-danger">
                                                {this.state.errors}
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelFirstName}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="First_Name"
                                                    value={this.state.visitorOnBehalfOfData.First_Name}
                                                    onChange={(e) => { this.handleInputChangeBookingOnBehalfOfData(e) }}
                                                    placeholder={this.labels.HubLabelFirstNamePlaceholderLabel}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelLastName}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="Last_Name"
                                                    value={this.state.visitorOnBehalfOfData.Last_Name}
                                                    onChange={(e) => { this.handleInputChangeBookingOnBehalfOfData(e) }}
                                                    placeholder={this.labels.HubLabelLastNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelCompany}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="Company"
                                                    value={this.state.visitorOnBehalfOfData.Company}
                                                    onChange={(e) => { this.handleInputChangeBookingOnBehalfOfData(e) }}
                                                    placeholder={this.labels.HubLabelCompanyNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
                                    <label className="lable-modal w-90 text-wrap">{this.state.isVisitor ? this.labels.HubLabelVisitorAutoCheckInRoamerAccess : this.labels.HubLabelEmployeeAutoCheckInRoamerAccess}</label>
                                    <input name="bookingAutoCheckin" type="checkbox" className="input-check-box-label border checkbox-sm" checked={bookingAutoCheckin === 1} onChange={this.setChecked} />
                                </Box>
                            </div>
                        }
                        footer=
                        {
                            <div className="px-58 w-100 d-flex justify-content-end">
                                <IbssButtonRedo
                                    variant='contained'
                                    onClick={() => this.handleAddBookingFor()}
                                >
                                    {this.labels.HubButtonSave}
                                </IbssButtonRedo>
                            </div>
                        }
                        onClose={() => this.handleShowHideBookingForModal()}
                    />
                    {/* -------------------End booking made for popup------------------- */}

                    {/* -------------------Start booking Space_Layout & Seating Arrangement Modal------------------- */}
                    <IbssDialog
                        open={this.state.isShowModalLayout}
                        fullWidth
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelLayoutAndArrangement}</label>
                            </>
                        }
                        content=
                        {
                            <div>
                                {/* -------------------select a linked spaces option--------------------*/}
                                {this.state.Space_Setup === 5 && (
                                    <>

                                        <div className="p-0 my-24">
                                            <label className="lable-modal text-bold" style={{ color: this.state.bookingid.length > 0 ? 'var(--ui-text-alternate )' : '' }}>{this.labels.HubLabelSelectLinkedSpace}</label>
                                        </div>

                                        {this.state?.Space_Layout?.map(item =>
                                        {
                                            return (
                                                <div key={item.Space_Id} className="p-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="lable-modal btn-align w-50 justify-content-start">
                                                            <img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} width="20" height="20" />
                                                            <label className="lable-modal" style={{ color: this.state.bookingid.length > 0 ? 'var(--ui-text-alternate )' : '' }}>{item.Name}</label>
                                                        </div>
                                                        <div className="btn-align">
                                                            <div className="round">
                                                                <input
                                                                    // className="label"
                                                                    disabled={this.state.bookingid.length > 0}
                                                                    type="checkbox"
                                                                    checked={this.state.selectedSpaceLayout == item.Space_Id}
                                                                    id={item.Space_Id}
                                                                    onChange={() => this.handleSpaceLayotChange(item)}
                                                                    value={item.Space_Id}
                                                                />
                                                                <label htmlFor={item.Space_Id}></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                        })}
                                    </>
                                )}

                                {/* -------------------select a layout--------------------*/}
                                <div className="p-0 my-24">
                                    <label className="lable-modal text-bold">{this.labels.HubLabelSelectALayout}</label>
                                </div>

                                {this.state?.SpaceArrangementList?.map(item =>
                                {
                                    return (
                                        <div className="p-0">
                                            <div className="d-flex justify-content-between">
                                                <div className="lable-modal btn-align w-50 justify-content-start">
                                                    <img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} width="20" height="20" />
                                                    <label className="ml-3">{item.Style}</label>
                                                </div>
                                                <div className="btn-align">
                                                    <div className="round">
                                                        <input
                                                            type="checkbox"
                                                            id={item.Style}
                                                            value={item.Style}
                                                            checked={this.state.selectedSeatingArrangement == item.Style}
                                                            onChange={() => { this.handleSeatingArrangementChange(item) }}
                                                        />
                                                        <label htmlFor={item.Style}></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        footer=
                        {
                            <div className="px-58 w-100 d-flex justify-content-end">
                                <IbssButtonRedo
                                    className='ml-2'
                                    variant='contained'
                                    onClick={() => this.handleAddLayoutAndSeating()}
                                >
                                    {this.labels.HubButtonSave}
                                </IbssButtonRedo>
                            </div>
                        }
                        onClose={() => this.handleShowHideLayoutModal()}
                    />
                    {/* -------------------End booking Space_Layout & Seating Arrangement Modal------------------- */}

                    {/* -----------------------------Start View Status Popup--------------------------- */}
                    <IbssDialog
                        open={this.state.isViewStatusShowModal}
                        fullWidth
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelStatus}</label>
                            </>
                        }
                        content=
                        {
                            <div className="" style={{ height: "545px", overflow: "auto" }} >
                                <ViewStatus {...this.state.viewStatusProps} />
                            </div>
                        }
                        onClose={() => this.handleShowHideViewStatusModal()}
                    />
                    {/* -----------------------------End View Status Popup--------------------------- */}


                    {/* ------------------start-cancel popup in edit page-------------- */}
                    <IbssDialog
                        open={this.state.isShowModalCancel}
                        content=
                        {
                            <div>{this.labels.HubLabelconfirmCancel}</div>
                        }
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubButtonCancelBooking}</label>
                            </>
                        }
                        footer=
                        {
                            <div className="px-58 w-100 d-flex justify-content-center">
                                <IbssButtonRedo
                                    className="button-primary w-50"
                                    variant='contained'
                                    onClick={() => this.cancelBooking()}
                                >
                                    {this.labels.HubButtoncancelConfirmation}
                                </IbssButtonRedo>
                            </div>
                        }
                        onClose={() => this.handleShowCancelMOdal()}
                        fullWidth
                    />
                    {/* ------------------End-cancel popup in edit page-------------- */}

                    {/* ----------------------------- Start Hearing-Aids && Presentation-Aids Popup --------------------------- */}
                    <IbssDialog
                        open={this.state.bookingConfirmedModal}
                        content={
                            <>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{height:"52px",marginRight:"24px"}}>
                                    <IbssSvgIcon>{Icons.Success}</IbssSvgIcon>
                                    </div>
                                    <h1 className="my-1">{this.labels.HubLabelAllBooked}</h1>
                                </div>
                                {
                                    bookingConfirmationHasNoFurtherAction ?
                                        <div>
                                            <div style={{ textAlign: "center" }}>
                                                <p>{this.labels.HubLabelRememberCheckIn} </p>
                                            </div>
                                            <Card style={{ backgroundColor: this.props.lightModeTheme ? customTheme.lightTheme.uiBackGround : customTheme.darkTheme.uiBackGround, padding: '15px' }}>
                                                <h3>{this.state.name}</h3>
                                                <Card style={{ padding: '12px' }}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>
                                                        <div style={{ paddingLeft: '2px', paddingBottom: '2px' }}>
                                                            <div className='d-flex'>
                                                                <IbssSvgIcon sx={{ marginRight: '6px' }}>{Icons.calenderIcon}</IbssSvgIcon>
                                                                <div style={{ marginTop: '2px' }}>
                                                                    {this.labels.HubLabelDate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {DateTime.fromJSDate(this.state.bookingDate).toLocaleDateString()}
                                                    </div>
                                                    <hr />
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>
                                                        <div style={{ paddingLeft: '2px' }}>
                                                            <div className='d-flex'>
                                                                <IbssSvgIcon sx={{ marginRight: '5px' }}>{Icons.TimeIcon}</IbssSvgIcon>
                                                                <div >
                                                                    {this.labels.HubLabelTime}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {DateTime.fromJSDate(this.state.start).toLocaleTimeString()} - {`${DateTime.fromJSDate(this.state.end).toLocaleTimeString()} (${this.labels.HubLabelLocalSecurityIcon})`} 
                                                    </div>
                                                </Card>
                                            </Card>
                                        </div>
                                        : 

                                        <div>
                                            <div style={{ textAlign: "center" }}>
                                                <p>{this.labels.HubLabelAllBookedPra1}</p>
                                                <p>{this.labels.HubLabelAllBookedPra2}</p>
                                            </div>
                                            <>
                                                {this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 && (
                                                    <div className={`row attendes`}>
                                                        <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                            <div className="d-flex pointer">
                                                                <span className="space-icon-item">
                                                                    <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} />
                                                                </span>
                                                                <span className="space-text-item col-text" onClick={() => this.handleHearingAndPresentationalAid("PresentationAid")}>{this.labels.HubLabelPresentationAid}</span>
                                                            </div>
                                                            <div className="space-icon-item space-text-item pointer" onClick={() => this.handleHearingAndPresentationalAid("PresentationAid")}><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></div>
                                                        </div>
                                                    </div>)}
                                                <hr />
                                            </>
                                            <>
                                                {this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 && (
                                                    <div className={`row attendes`}>
                                                        <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                            <div className="d-flex pointer">
                                                                <span className="space-icon-item">
                                                                    <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} />
                                                                </span>
                                                                <span className="space-text-item col-text" onClick={() => this.handleHearingAndPresentationalAid("HearingAid")}>{this.labels.HubLabelHearingAid}</span>
                                                            </div>
                                                            <div className="space-icon-item space-text-item pointer" onClick={() => this.handleHearingAndPresentationalAid("HearingAid")}><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></div>
                                                        </div>
                                                    </div>)}
                                                <hr />
                                            </>
                                            <>
                                                {this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1 && (
                                                    <div className={`row attendes`}>
                                                        <div className="icon-text-inline pl-0 d-flex justify-content-between w-100">
                                                            <div className="d-flex pointer">
                                                                <span className="space-icon-item">
                                                                    <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                                                </span>
                                                                <span className="space-text-item col-text" onClick={() => history.push('/create-catering-order/' + this.state.buildingid + '/' + this.state.bookingid + '/' + this.state.spaceId)}>{this.labels.HubLabelCateringTable}</span>
                                                            </div>
                                                            <div className="space-icon-item space-text-item pointer" onClick={() => history.push('/create-catering-order/' + this.state.buildingid + '/' + this.state.bookingid + '/' + this.state.spaceId)}><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></div>
                                                        </div>

                                                    </div>)}
                                                <hr />
                                            </>
                                        </div>
                                }
                            </>
                        }
                        footer=
                        {
                            <IbssButtonRedo
                                variant="contained"
                                onClick={() => this.handleShowHearingAidsStatusModal()}
                            >
                                {bookingConfirmationHasNoFurtherAction ? this.labels.funcCreateBookingReturnHome_S : this.labels.HubButtondoThisLater}
                            </IbssButtonRedo>
                        }
                        fullWidth
                        onClose={() => this.handleShowHearingAidsStatusModal()}
                    />
                    {/* -----------------------------End Hearing-Aids&presentation Aid Popup--------------------------- */}

                    {/* -----------------------------Start Cost codes Popup---------------------------*/}

                    <CostCodeModal
                        bookingId={this.state.bookingid}
                        bookingCostCodes={this.state.costCodes}
                        show={this.state.showCostCodeModal}
                        onClose={this.handleCostCodeModal}
                        updateBookingCostCodes={this.updateBookingCostCodes}
                    />

                    {/* -----------------------------End Cost codes Popup---------------------------*/}

                    <div className="rightPanel-main-content side">
                        {this.state.show ? (
                            <ConfirmModal {...confModalData} />
                        ) : ""}

                        {/* Left panel code */}
                        <div style={{ textDecoration: "none", cursor: "default" }}>
                            <div className="form-panel-left gap-0">
                                <div className="form-elements">
                                    { primarySpace != null &&
                                    <>
                                        <div className="row mb-6px">
                                            <span className="space-Name spacetype">{primarySpace.Space_Type_Label ? primarySpace.Space_Type_Label : primarySpace.Space_Type}</span>

                                        </div>

                                        <div className="row mb-6px">
                                            <span className="space-Name spacetext">{primarySpace.Space_Name} </span>
                                        </div>

                                        <div className="row my-9 img-align">
                                            <div className="align-icons-text">
                                                <div className="space-icon-item"><img className="spaceIcon" src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt="icon" /></div>
                                                <div className="space-text-item">{primarySpace.floorName}</div>
                                            </div>
                                            <div className="align-icons-text">
                                                <span className="space-icon-item"><img className="spaceIcon" src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt="icon" /></span>
                                                <span className="space-text-item">{primarySpace.Meta_Loc_Zone}</span>
                                            </div>
                                            <div className="align-icons-text">
                                                <span className="space-icon-item"><img className="spaceIcon" src="/images/User Logged in (Fill).svg" alt="icon" /></span>
                                                <span className="space-text-item mr-1">{this.labels.HubLabelCapacity}</span>
                                                <span className="space-text-item">{primarySpace.Space_Capacity} </span>
                                                {/* <span className="space-text-item">{item.Space_Setup === 5 ? <img src="/images/Linked Space.svg" alt="icon" /> : ""}</span> */}
                                            </div>
                                        </div>
                                        <div className="row mb-6px">
                                            <Card className="cardimg ">
                                                <Card.Img className="" src={primarySpace.ImageURI ? primarySpace.ImageURI : this.state.imagePaths.get(primarySpace.Space_Type)} />
                                            </Card>

                                        </div>
                                        <div className="row mb-6px">
                                            {(primarySpace.Meta_Serv_Reqs_Catering === 1 || primarySpace.Meta_Serv_Reqs_AV === 1 || primarySpace.Meta_Serv_Reqs_Hearing > 1) && (
                                                <div className="col pl-0">

                                                    {primarySpace.Meta_Serv_Reqs_Catering === 1 && (
                                                        <div className="co-md-4 align-icons-text mb-3px">
                                                            <span className="space-icon-item back-round">
                                                                <img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} alt="icon" />
                                                            </span>
                                                            <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelCateringTable} </span>
                                                        </div>
                                                    )}

                                                    {primarySpace.Meta_Serv_Reqs_AV === 1 && (
                                                        <div className="co-md-4 align-icons-text mb-3px">
                                                            <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/ITAV (Fill).svg`} alt="icon" /></span>
                                                            <span className="space-text-item pt-0 ml-10">{this.labels.HubLabeltvAv}</span>
                                                        </div>
                                                    )}

                                                    {primarySpace.Meta_Serv_Reqs_Hearing === 1 && (
                                                        <div className="co-md-4 align-icons-text mb-3px">
                                                            <span className="space-icon-item back-round">
                                                                <img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} alt="icon" />
                                                            </span>
                                                            <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelHearingAid}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            <div className="col pl-0">

                                                {primarySpace.Meta_Serv_Reqs_Presentation === 1 && (
                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                        <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} alt="icon" /></span>
                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelPresentationAid}</span>
                                                    </div>
                                                )}

                                                {primarySpace.Space_Setup > 1 && (
                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                        <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} alt="icon" /></span>
                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelLinkedSpaces}</span>
                                                    </div>
                                                )}

                                                {primarySpace.Space_Setup >= 1 && (
                                                    <div className="co-md-4 align-icons-text mb-3px">
                                                        <span className="space-icon-item back-round"><img className="cl-w" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} alt="icon" /></span>
                                                        <span className="space-text-item pt-0 ml-10">{this.labels.HubLabelLayouts}</span>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </>}
                                </div>
                                <ModalFooter className="shadow-top">
                                    {this.state.bookingid && (
                                        <div className="d-flex ">
                                            <IbssButtonRedo
                                                variant="contained"
                                                color="primary"
                                                sx={{ mr: 0.5, width: "50%" }}
                                                onClick={() => this.checkIn()}
                                                disabled={!checkInEnabled}
                                            >
                                                {this.labels.HubButtonCheckIn}
                                            </IbssButtonRedo>
                                            <IbssButtonRedo
                                                variant="contained"
                                                sx={{ ml: 0.5, width: "50%", whiteSpace: "nowrap" }}
                                                color="error"
                                                onClick={() => this.handleShowCancelMOdal()}
                                                disabled={cancelBookingBtnDisable}
                                            >
                                                {this.labels.HubButtonCancelBooking}
                                            </IbssButtonRedo>
                                        </div>
                                    )}
                                </ModalFooter>
                            </div>
                        </div>

                        {/* Right panel code */}
                        <div className="form-panel-right">
                            <div className="">
                                <div className="form-elements">
                                    <div className="row md-4">
                                        <input
                                            className="inputboxmodel"
                                            placeholder={this.labels.HubLabelBooking}
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={this.change}
                                            disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                        />
                                    </div>
                                    <hr className="my-2" />
                                    <div className="row facility-booking-desc md-4">
                                        <input
                                            className="inputboxmodel"
                                            placeholder={this.labels.HubLabelAddDescription}
                                            type="text"
                                            name="description"
                                            value={description}
                                            onChange={this.change}
                                            disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                        />
                                    </div>
                                    <hr className="my-2" />
                                    <fieldset>
                                        <legend style={{ visibility: 'hidden', position: 'absolute', top: '-1000px' }}>start time and end time</legend>
                                        <div style={{
                                            paddingBottom: '0.5em',
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr 1fr',
                                            gridTemplateRows: 'auto auto',
                                            gridAutoFlow: 'column',
                                            alignItems: 'baseline',
                                        }}>
                                            <span className="form-input-box-label-date">{this.labels.HubLabelFrom}</span>
                                            <IbssTimePicker
                                                shouldDisableTime={(time, type) => this.isTimeUnavailable(time, type, 'start')}
                                                className="inputboxmodel-date"
                                                value={this.state.start}
                                                onChange={time => this.startTimeChanged(time as (Dayjs | null))}
                                                ampm={false}
                                                minutesStep={this.getMinutesStepFromPolicy(this.state.startIntervalMinutes)}
                                                disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                                renderInput={(params) =>
                                                {
                                                    const { sx, ...paramsMinusSx } = params
                                                    return <TextField
                                                        {...paramsMinusSx}
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                            '.MuiInputBase-input': { fontFamily: 'Source Sans Pro', fontWeight: 600 },
                                                        }}
                                                        error={false}
                                                    />
                                                }}
                                            />

                                            <div>{/* intentionally empty to allow 3x2 Grid to work. */}</div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    padding: "10px 22px 10px 22px",
                                                }}>
                                                <img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 239.svg`} alt="" />
                                            </div>

                                            <span className="form-input-box-label-date">{this.labels.HubLabelTo}</span>
                                            <IbssTimePicker
                                                shouldDisableTime={(time, type) => this.isTimeUnavailable(time, type, 'end')}
                                                className="inputboxmodel-date"
                                                value={this.state.end}
                                                onChange={time => this.endTimeChanged(time as (Dayjs | null))}
                                                ampm={false}
                                                minutesStep={this.getMinutesStepFromPolicy(this.state.endIntervalMinutes)}
                                                disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                                renderInput={(params) =>
                                                {
                                                    const { sx, ...paramsMinusSx } = params
                                                    return <TextField
                                                        {...paramsMinusSx}
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                            '.MuiInputBase-input': { fontFamily: 'Source Sans Pro', fontWeight: 600 },
                                                        }}
                                                        error={false}
                                                    />
                                                }}
                                            />
                                        </div>
                                    </fieldset>
                                    <hr />

                                    <div className="d-flex justify-content-between">
                                        <div className="icon-text-inline pl-0">
                                            <IbssDatePicker
                                                value={this.state.bookingDate}
                                                onChange={(event) => this.handleDateChange(event as Date)}
                                                renderInput={(params) =>
                                                {
                                                    const { sx, ...paramsMinusSx } = params
                                                    return <TextField
                                                        {...paramsMinusSx}
                                                        sx={{
                                                            '& legend': { display: 'none' },
                                                            '& fieldset': { top: 0 },
                                                            '.MuiInputBase-input': { width: '85px', fontFamily: 'Source Sans Pro', fontWeight: 600 }, // modify padding here to adjust textfield input padding.
                                                            '.MuiButtonBase-root': { padding: '8px 5px' }, // calendar icon button.
                                                        }}
                                                        error={false}
                                                    />
                                                }}
                                                InputAdornmentProps={{ sx: { padding: '0px', marginLeft: '0px' } }}
                                                disabled={bookingStatus === "Completed" || bookingStatus === "Cancelled" ? true : false}
                                                minDate={DateHelper.now().toJSDate()}
                                                shouldDisableDate={date => this.isDateUnavailable(date)}
                                            />
                                        </div>
                                        <div className="icon-text-inline">
                                            <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Time (Fill).svg`} alt="icon" /></span>
                                            <span className="timeDate space-text-item pb-4px">{this.state.timeDifference}</span>

                                        </div>
                                        <div className="icon-text-inline">
                                            <span className="space-icon-item"><img style={{ height: '18px', width: '18px', paddingRight: '5px' }} src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Timezone (Fill).svg`} alt="icon" /></span>
                                            <span className="timeDate space-text-item pb-4px">{this.state.timelabel}</span>
                                            {/* <DatePicker className="timeDate space-text-item" selected={this.state.localTime} showTimeSelect dateFormat="HH:mm" locale="pt-BR" timeFormat="HH:mm" /> */}
                                        </div>
                                    </div>
                                    <hr />


                                    {this.state.isOnBehalfOfRight && (
                                        <>
                                            <div
                                                className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`}
                                                onClick={this.state.isEnable ? () => { } : () => this.handleShowHideBookingForModal()}
                                            >
                                                <div className="icon-text-inline pl-0">
                                                    <span className="space-icon-item">
                                                        <img alt="user" className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/User.svg`} />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelBookForSomeone}</span>
                                                </div>
                                                <div className="d-flex">

                                                    {(this.state.onBehalfOfLabel || this.state.bookingResponse.Booking_Owner_Email !== this.state.bookingResponse._CreatedBy) && <span className="space-text-item mr-3">{this.state.onBehalfOfLabel}</span>}
                                                    <span className="space-icon-item space-text-item"  ><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    )}
                                    {(this.state.bookingid && this.state.spacesData[0]?.Space_Capacity > 1) ?
                                        this.state.isEnable ?
                                            <>
                                                <div className="d-flex space-between">
                                                    <span className="space-icon-item">
                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Attendee Icon.svg`} alt="icon" />
                                                        <span className="space-text-item col-text">{this.state.allAttendees.length === 0 && this.labels.HubLabelAddAttendeeLabel}</span>
                                                    </span>
                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                                <hr />
                                            </>
                                            :
                                            <>
                                                <div className="row attendes cp" onClick={() => this.setState({ isShowModalAttendees: true })}>
                                                    <div className="icon-text-inline pl-0">
                                                        {this.state.booking_Parties_array.length > 0 && (
                                                            <>
                                                                {this.state.booking_Parties_array.map(item => (
                                                                    <span key={item.email} className="space-icon-item">
                                                                        <p className="attendee-name circle-labels overlap" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : "#2A3F49" }}>{this.extractFirstLetters(item.name?.split(' ')[0], item.name?.split(' ')[1])}</p>
                                                                    </span>
                                                                ))}
                                                            </>
                                                        )}
                                                        {this.state.booking_Parties_Visitors_array.length > 0 && (
                                                            <>
                                                                <span className="space-icon-item ml-2">
                                                                    {this.state.booking_Parties_Visitors_array.map(item => (
                                                                        <p key={item.email} className="attendee-name circle-labels overlap" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : "#2A3F49" }}>{this.extractFirstLetters(item.name?.split(' ')[0], item.name?.split(' ')[1])}</p>
                                                                    ))}
                                                                </span>
                                                            </>
                                                        )}
                                                        {(this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && (
                                                            <>
                                                                <div className="d-flex space-between mr-2">
                                                                    <span className="space-icon-item">
                                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Attendee Icon.svg`} alt="icon" />
                                                                        <span className="space-text-item col-text">{this.state.allAttendees.length === 0 && this.labels.HubLabelAddAttendeeLabel}</span>
                                                                    </span>
                                                                </div>
                                                                <hr />
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="d-flex">
                                                        <span className="space-text-item mr-3">{(this.state.booking_Parties_array.length !== 0 || this.state.booking_Parties_Visitors_array.length !== 0) && `${this.state.booking_Parties_array.length + this.state.booking_Parties_Visitors_array.length} Attendees`}</span>
                                                        <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                    </div>
                                                </div>
                                                <hr />
                                            </>
                                        :
                                        (<div>
                                            {this.state.spacesData[0]?.Space_Capacity > 1 && (
                                                <>
                                                    <div className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`} onClick={this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? () => { } : () => this.setState({ isShowModalAttendees: true })}>
                                                        <div className="icon-text-inline pl-0">
                                                            {this.state.allAttendees.length === 0 ?
                                                                (
                                                                    <span className="space-icon-item">
                                                                        <img className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Attendee Icon.svg`} alt="icon" />
                                                                    </span>
                                                                )
                                                                :
                                                                (
                                                                    <span className="ml-2 d-flex">
                                                                        {this.state.allAttendees.map(item => (
                                                                            <p key={item.email} className="attendee-name circle-labels overlap" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : "#2A3F49" }}>{this.extractFirstLetters(item.name?.split(' ')[0], item.name?.split(' ')[1])}</p>
                                                                        ))}
                                                                    </span>
                                                                )
                                                            }
                                                            <span className="space-text-item col-text">{this.state.allAttendees.length === 0 && this.labels.HubLabelAddAttendeeLabel}</span>

                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="space-text-item mr-3">{this.state.allAttendees.length !== 0 && `${this.state.allAttendees.length} Attendees`}</span>
                                                            <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </>
                                            )}
                                        </div>)
                                    }
                                    {
                                        /* cost codes */
                                        // 1. check user has rights to assign cost codes
                                        (this.local.hasRight("API.Bookings.AssignCostCode") && this.local.hasRight("API.Bookings.V2")) && (
                                            <>
                                                <div className={this.state.isEnable ? "row attendes cna" : "row attendes cp"} onClick={() => this.handleCostCodeModal()}>
                                                    <div className="icon-text-inline pl-0">
                                                        <span className="space-icon-item">
                                                            <SvgIcon component={CostCodeIcon} inheritViewBox sx={{ height: '18px', width: '18px', marginRight: '12px', color: (theme) => theme.palette.text.primary }} />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelCostCode}</span>

                                                    </div>
                                                    <div className="d-flex">
                                                        <span className="space-text-item mr-3">{this.getCostCodeLabel()}</span>
                                                        <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                    </div>
                                                </div>
                                                <hr />
                                            </>
                                        )
                                    }
                                    {this.state.showSpaceLayout && (
                                        <>
                                            <div className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`} onClick={this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? () => { } : () => this.handleShowHideLayoutModal()}>
                                                <div className="icon-text-inline pl-0">
                                                    <span className="space-icon-item">
                                                        <img alt="linked space" className="pl-0 spaceIcon" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Linked Space (Fill).svg`} />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelLinkedSpaces}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="space-text-item mr-3">{this.state.selectedSpaceLayoutName && this.state.selectedSpaceLayoutName}</div>
                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    )}

                                    {this.state.showSpaceArrangement && (
                                        <>
                                            <div className={`row attendes ${this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? "" : "cp"}`} onClick={this.state.bookingStatus === "Completed" || this.state.bookingStatus === "Cancelled" ? () => { } : () => this.handleShowHideLayoutModal()}>
                                                <div className="icon-text-inline pl-0">
                                                    <span className="space-icon-item">
                                                        <img alt="Seating Arrangement" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Seating Arrangement.svg`} />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelLayouts}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="space-text-item mr-3">{this.state.selectedSeatingArrangement && this.state.selectedSeatingArrangement}</div>
                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    )}

                                    {(this.state.bookingid) ?
                                        (
                                            this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing >= 1 &&
                                            <div>

                                                <div className={this.state.isEnable ? "row attendes cna" : "row attendes cp"} onClick={() => this.state.isEnable ? () => { } : this.handleHearingAndPresentationalAid("HearingAid")}>
                                                    <div className="icon-text-inline pl-0">

                                                        <span className="space-icon-item">
                                                            <img className="spaceIcon pl-0 col-text" alt="hearing aid" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelHearingAid}</span>

                                                    </div>
                                                    <div className="d-flex">
                                                        <span style={{ margin: "10px" }} className="space-text-item mr-3"> {this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 ? this.state.hearingAidTotal + ' ' + this.labels.HubLabelItems : this.labels.HubLabelNone} </span>
                                                        <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>

                                                    </div>
                                                </div>
                                                <hr className="mb-0" />
                                            </div>
                                        ) :
                                        (<div>
                                            {this.state.spacesData[0]?.Meta_Serv_Reqs_Hearing === 1 && (
                                                <div className="row">
                                                    <div className="disabled-labels">
                                                        <span className="space-icon-item">
                                                            <img alt="Hearing Aid" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Hearing Aid (Fill).svg`} />
                                                        </span>
                                                        <div className="icon-text-inline">
                                                            <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelHearingConfirmation}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>)
                                    }
                                    {(this.state.bookingid) ?
                                        this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation >= 1 &&
                                        <div>
                                            <div className={this.state.isEnable ? "row attendes cna mt-16" : "row attendes cp mt-16"} onClick={() => this.state.isEnable ? () => { } : this.handleHearingAndPresentationalAid("PresentationAid")}>
                                                <div className="icon-text-inline pl-0">

                                                    <span className="space-icon-item">
                                                        <img alt="Presentation" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelPresentationAid}</span>

                                                </div>
                                                <div className="d-flex">
                                                    <span style={{ margin: "10px" }} className="space-text-item mr-3"> {this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 ? this.state.presentationAidTotal + ' ' + this.labels.HubLabelItems : this.labels.HubLabelNone}</span>
                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                            </div>
                                            <hr className="mb-0" />
                                        </div>
                                        :
                                        <div>
                                            {this.state.spacesData[0]?.Meta_Serv_Reqs_Presentation === 1 && (
                                                <>
                                                    <div className="row">
                                                        <div className="disabled-labels">
                                                            <span className="space-icon-item">
                                                                <img alt="Presentation" className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Presentation (Fill).svg`} />
                                                            </span>
                                                            <div className="icon-text-inline">
                                                                <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelconfirmation}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                    }
                                    {this.state.bookingid && (this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1) &&
                                        (
                                            <div>
                                                <div className={this.state.isEnable ? "row attendes cna mt-16" : "row attendes cp mt-16"} onClick={() => this.state.isEnable ? () => { } : this.handleCateringRoute()}>
                                                    <div className="icon-text-inline pl-0">
                                                        <span className="space-icon-item">
                                                            <img className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                                        </span>
                                                        <span className="space-text-item col-text">{this.labels.HubLabelCateringTable}</span>
                                                    </div>
                                                    <div className="d-flex">
                                                        {this.state.isCateringEnable ?
                                                            <div style={{ cursor: "none" }}>
                                                                <CircularProgress size={20} />
                                                            </div> :
                                                            <span style={{ margin: "10px" }} className="space-text-item mr-3"> {this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1 ? this.state.cateringTotal + ' ' + this.labels.HubLabelItems : this.labels.HubLabelNone}</span>
                                                        }
                                                        <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                    </div>
                                                </div>
                                                <hr className="mb-0" />
                                            </div>
                                        )
                                    }
                                    {!this.state.bookingid && (this.state.spacesData[0]?.Meta_Serv_Reqs_Catering === 1) &&
                                        (
                                            <div>
                                                <div className={this.state.isEnable ? "row attendes cna mt-16" : "row attendes cp mt-16"}>
                                                    <div className="disabled-labels">
                                                        <span className="space-icon-item">
                                                            <img className="spaceIcon pl-0 col-text" src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Restaurant (Fill).svg`} />
                                                        </span>
                                                        <div className="icon-text-inline">
                                                            <span className="space-text-item mt-0 pt-0 ">{this.labels.HubLabelAvailabelCatering}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="mb-0" />
                                            </div>
                                        )
                                    }

                                    {this.state.bookingid && (
                                        <>
                                            <div className="row attendes cp mt-16" onClick={() => this.handleShowHideViewStatusModal()}>
                                                <div className="icon-text-inline pl-0">
                                                    <span className="space-icon-item">
                                                        <div className="pl-0 spaceIcon" />
                                                    </span>
                                                    <span className="space-text-item col-text">{this.labels.HubLabelViewStatus}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <span className="space-icon-item space-text-item"><img src={`/images/Sidebar_Icons/${this.props.lightModeTheme ? "Light_theme" : "Dark_Theme"}/Vector 10 (Stroke)Suffix.svg`} alt="" /></span>
                                                </div>
                                            </div>
                                            <hr className="mb-0" />
                                        </>

                                    )}
                                </div>
                                <ModalFooter className="shadow-top">
                                    {(this.state.bookingid) ?
                                        (<IbssButtonRedo
                                            className="btn-primary w-100 mx-22"
                                            variant='contained'
                                            onClick={() => this.submit()}
                                            disabled={updateBookingBtnDisable}
                                        >
                                            {this.labels.HubButtonUpdateBooking}
                                        </IbssButtonRedo>)
                                        :
                                        (<IbssButtonRedo
                                            className="btn-primary w-100 mx-22"
                                            variant='contained'
                                            onClick={() => this.submit()}
                                        >
                                            {this.labels.HubButtonConfirmBooking}
                                        </IbssButtonRedo>)
                                    }
                                </ModalFooter>

                            </div>
                        </div>
                        <IbssButtonRedo
                            className="btn-back ml-3 col-text"
                            variant='contained'
                            color="secondary"
                            onClick={() => this.cancel()}
                        >
                            {this.labels.HubButtonBacklabel}
                        </IbssButtonRedo>

                    </div>

                </div>
            </>
        );
    }
}
const mapStateToProps = (state: any) =>
{
    return {
        lightModeTheme: state.lightModeTheme,
        currentPageTitle: state.currentPageTitle,
        identityProvidersStore: state.identityProvidersDetails,
        mainPageTitle: state.mainPageTitle,
        flexMySearchFilterCriteria: state.flexMySearchFilterCriteria,
    };
};
export default connect(mapStateToProps)(EditBooking);

export interface IProps extends RouteComponentProps<IQueryParams>, IPropsFromState
{
    flexMySearchFilterCriteria: ISearchFilterCriteria;
}

export interface IQueryParams
{
    buildingid: string;
    spaceId: string;
    bookingid: string | undefined;
    tab: string;
}

export interface ISearchFilterCriteria
{
    fmsfc_start_date_for_filter_modal: Date;
    End_Date_For_filter_modal: Date;
}

export class State
{
    public isLoading = false;
    public primarySpace: ((Space & { floorName: string }) | null) = null;
    public spacesData = new Array<ISpaceView>();
    public imagePaths = new Map<string, string>();
    public isLoadingToSave = false;
    public start = new Date();
    public end = new Date();
    public isStartValid = true;
    public isEndValid = true;
    public bookingDate = new Date();
    public spaceId = "";
    public editMode = false;
    public name = "";
    public Space_Layout: ISpaceLayout[] = [];
    public SpaceArrangementList: ISpaceArrangement[] = [];
    public timeDifference = "";
    public description = "";
    public onBehalfOf = "";
    public bookingOwnerEmail = "";
    public nodeId = 0;
    public isShowModalAttendees = false;
    public isShowModalBookingsFor = false;
    public isShowModalLayout = false;
    public isShowModalCancel = false;
    public isVisitor = false;
    public isDelete = false;
    public show = false;
    public userList: IUser[] = [];
    public _CreatedAt = "";
    public _CreatedBy = "";
    public booking_Parties_array: Attendee[] = [];
    public booking_Parties_Visitors_array: Attendee[] = [];
    public tab = 1;
    public bookingCheckInTime = "";
    public bookingEarlyCheckIn = "";
    public bookingCompletionTime = "";
    public bookingCancelletionTime = "";
    public allAttendees: Attendee[] = [];
    public errors = '';
    public alreadyPresentError = '';
    public isDisabled = true;
    public bookingAutoCheckin = 0;
    public onBehalfOfLabel = "";
    public onBehalfOfData = { email: "", firstName: "", lastName: "", company: "", isVisitor: false };
    public active = true;
    public Space_Setup = 0;
    public visitorOnBehalfOfData = { Email: "", First_Name: "", Last_Name: "", Company: "", };
    public nonVisitorOnBehalfOfData = { Email: "", First_Name: "", Last_Name: "", Company: "", displayName: "" };
    public noOnBehalfOfSelectedError = "";
    public selectedSpaceLayout = "";
    public selectedSpaceLayoutName = "";
    public selectedSeatingArrangement = "";
    public bookingid = "";
    public isViewStatusShowModal = false;
    public bookingConfirmedModal = false;
    public isPrivate = 0;
    public bookingResponse: any = [];
    public BookedData: any = []
    public costCodeAllocation: string | ICreateV2Booking_CostCodeAllocation[] = '';
    public viewStatusProps = {
        _CreatedAt: "", _CreatedBy: "", bookingid: "", metaExtBookingId: "",
        Space_Layout: "", bookingStatus: "", isactive: false, checkedin: false,
        completed: false, cancelled: false, autocheckin: false, earlycheckin: false,
        lastcheckin: false, bookingCheckInTime: "", bookingCompletionTime: "",
        bookingCancelletionTime: "",
    };
    public buildingid = 0;
    public bookingStatus = "";
    public showSpaceLayout = false;
    public showSpaceArrangement = false;
    public isOnBehalfOfRight = false;
    public timelabel = "";
    public hearingAidTotal = 0;
    public presentationAidTotal = 0;
    public showCostCodeModal = false;
    public costCodes: Array<CostCodeWithAllocation> = [];
    public startIntervalMinutes: string[] = [];
    public endIntervalMinutes: string[] = [];
    public cateringTotal = 0;
    public isCateringEnable = false;
    public cateringTask: CateringTask | undefined;
    public isEnable = false;
    public bookingSlotsPolicy: IBookingSlots;
    public spaceStatus = "";

    constructor()
    {
        this.bookingSlotsPolicy = {} as IBookingSlots;

        //Workspaces
        this.imagePaths.set("Desk", "/images/spaceImages/space_desk.png");
        this.imagePaths.set("SitStandDesk", "/images/spaceImages/space_sitstanddesk.png");
        this.imagePaths.set("SpecialistDesk", "/images/spaceImages/space_specialistdesk.png");
        this.imagePaths.set("MeetingRoom", "/images/spaceImages/space_meetingroom.png");
        this.imagePaths.set("MeetingBooth", "/images/spaceImages/space_meetingbooth.png");
        this.imagePaths.set("PhoneBooth", "/images/spaceImages/space_phonebooth.png");
        this.imagePaths.set("FocusBooth", "/images/spaceImages/space_focusBooth.png");
        this.imagePaths.set("Touchdown", "/images/spaceImages/space_touchdown.png");
        this.imagePaths.set("CollaborationSpace", "/images/spaceImages/space_collaborationspace.png");
        this.imagePaths.set("Office", "/images/spaceImages/space_office.png");
        this.imagePaths.set("PrivateRoom", "/images/spaceImages/space_privateroom.png");
        this.imagePaths.set("VCRoom", "/images/spaceImages/space_vcroom.png");
        this.imagePaths.set("Boardroom", "/images/spaceImages/space_boardroom.png");
        this.imagePaths.set("SharedQuietRoom", "/images/spaceImages/space_quietroom.png");
        this.imagePaths.set("LectureTheatre", "/images/spaceImages/space_lecturetheatre.png");

        //Supportspaces
        this.imagePaths.set("InformalLounge", "/images/spaceImages/space_informallounge.png");
        this.imagePaths.set("TrainingRoom", "/images/spaceImages/space_trainingroom.png");
        this.imagePaths.set("BenchSeating", "/images/spaceImages/space_benchseating.png");
        this.imagePaths.set("Storage", "/images/spaceImages/space_storage.png");
        this.imagePaths.set("PrintRoom", "/images/spaceImages/space_printroom.png");
        this.imagePaths.set("Lockers", "/images/spaceImages/space_lockers.png");
        this.imagePaths.set("Stationery", "/images/spaceImages/space_stationery.png");
        this.imagePaths.set("PostRoom", "/images/spaceImages/space_postroom.png");

        //Amenities
        this.imagePaths.set("Reception", "/images/spaceImages/amenity_reception.png");
        this.imagePaths.set("WaitingLounge", "/images/spaceImages/amenity_waitinglounge.png");
        this.imagePaths.set("Restaurant", "/images/spaceImages/amenity_restaurant.png");
        this.imagePaths.set("Café", "/images/spaceImages/amenity_cafe.png");
        this.imagePaths.set("Kitchenette", "/images/spaceImages/amenity_kitchenette.png");
        this.imagePaths.set("Bar", "/images/spaceImages/amenity_bar.png");
        this.imagePaths.set("LiftLobby", "/images/spaceImages/amenity_liftlobby.png");
        this.imagePaths.set("TreatmentRoom", "/images/spaceImages/amenity_treatmentroom.png");
        this.imagePaths.set("BOHOffice", "/images/spaceImages/space_office.png");
        this.imagePaths.set("Gym", "/images/spaceImages/amenity_gym.png");
        this.imagePaths.set("Changing", "/images/spaceImages/amenity_changing.png");
        this.imagePaths.set("UnisexWCs", "/images/spaceImages/amenity_toilets.png");
        this.imagePaths.set("MaleWC", "/images/spaceImages/amenity_toiletsmale.png");
        this.imagePaths.set("FemaleWC", "/images/spaceImages/amenity_toiletsfemale.png");
        this.imagePaths.set("AccessibleWC", "/images/spaceImages/amenity_toilets.png");
        this.imagePaths.set("BikeStore", "/images/spaceImages/amenity_bikestore.png");
        this.imagePaths.set("FirstAid", "/images/spaceImages/amenity_firstaid.png");
        this.imagePaths.set("MothersRoom", "/images/spaceImages/amenity_mothersroom.png");
        this.imagePaths.set("Security", "/images/spaceImages/amenity_security.png");
        this.imagePaths.set("CoatStorage", "/images/spaceImages/amenity_coatstorage.png");
        this.imagePaths.set("IndoorGarden", "/images/spaceImages/amenity_indoorgarden.png");
        this.imagePaths.set("PrayerRoom", "/images/spaceImages/amenity_prayerroom.png");
        this.imagePaths.set("Prayer", "/images/spaceImages/amenity_prayer.png");
        this.imagePaths.set("CleaningStation", "/images/spaceImages/amenity_cleaningstation.png");
        this.imagePaths.set("PlantRoom", "/images/spaceImages/ancillary_plantroom.png");
        this.imagePaths.set("Riser", "/images/spaceImages/ancillary_riser.png");
        this.imagePaths.set("EquipmentRoom", "/images/spaceImages/ancillary_equipmentroom.png");
        this.imagePaths.set("WellnessRoom", "/images/spaceImages/amenity_wellness.png");
        this.imagePaths.set("Pantry", "/images/spaceImages/amenity_pantry.png");
        this.imagePaths.set("WaterPoint", "/images/spaceImages/amenity_waterpoint.png");
        this.imagePaths.set("ParkingSpace", "/images/spaceImages/amenity_parkingspace.png");
        this.imagePaths.set("ParcelLocker", "/images/spaceImages/amenity_parcellocker.png");
    }
}

export interface ISpaceView extends Space
{
    floorName: string;
}

export interface ISpaceLayout
{
    Space_Id: string;
    Name: string;
}

export interface ISpaceArrangement
{
    Style: string;
    Capacity: number;
    Setup: number;
    Breakdown: number;
}
