import { IBookingService } from "./BookingService";
import { ISpaceService } from "./SpaceService";
import { ISpaceUtilisationSummaryService } from "./SpaceUtilisationSummaryService";
import { IUserPreferencesService } from "./UserPreferencesService";

export class Services implements IServices
{
    public bookings: IBookingService;
    public spaces: ISpaceService;
    public spaceUtilisationSummaries: ISpaceUtilisationSummaryService;
    public userPreferences: IUserPreferencesService;

    constructor(
        bookings: IBookingService,
        spaces: ISpaceService,
        spaceUtilisationSummaries: ISpaceUtilisationSummaryService,
        userPreferences: IUserPreferencesService,
    )
    {
        this.bookings = bookings;
        this.spaces = spaces;
        this.spaceUtilisationSummaries = spaceUtilisationSummaries;
        this.userPreferences = userPreferences;
    }
}

export interface IServices
{
    bookings: IBookingService;
    spaces: ISpaceService;
    spaceUtilisationSummaries: ISpaceUtilisationSummaryService;
    userPreferences: IUserPreferencesService;
}
