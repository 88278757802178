import { Component } from "react";
import { IPartialAppState, appContext } from "../../AppContext";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { multiSort, handleSortTableDataDivWrapProperyArray } from "../../Common/Helper";
import Spinner from "../../Components/Spinner";
import ChartPanelInfo from "../../Components/ChartPanelInfo";
// import { appContext } from "../../AppContext";
import ApiMessageBuilder from "../../Providers.Api/ApiMessageBuilder";
import { DateHelper } from "../../Common/DateHelper";
import { DateTime } from "luxon";
import { IVisit } from "../../Providers.Api/Models";

const html2pdf = require('html2pdf.js');



class FacilityVisitor extends Component
{

  private labels = appContext().labels;
  private appState = appContext().state;
  private alert = appContext().alert;
  private apiMessageBuilder = new ApiMessageBuilder();
  private session = appContext().sessionStorageProvider;
  private local = appContext().localStorageProvider;
  private get apiClient() { return appContext().apiClient; }

  state: any = {
    getRightApiResponseErrorLoading: false,
    getDetailsApiResponseErrorLoading: false,
    isLoading: false,
    isFilter: false,
    Start_Date_For_filter_modal: DateHelper.now().toJSDate(),
    End_Date_For_filter_modal: DateTime.fromISO(DateHelper.now().toISO()).plus({ day: 7 }).endOf('day').toJSDate(),
    isLoadingToSave: false,
    awaitingApprovalVisitor: [],
    todaysVisitor: [],
    nextWorkingDayVisitor: [],
    onsiteVisitor: [],
    futureVisitor: [],
    inactiveVisitor: [],
    filter: "Visitor",
    activePage: 0,
    selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
    selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
    selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
    selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
    selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
    selectedBoxInactiveVisitor: [],
    selectedBoxCustomFilterVisitor: [{ visitid: "", status: "" }],
    show: false,
    buildingid: this.appState.buildingId.toString(),
    selectedDate: "",
    dataGraph: [],
    donutChartData: [],

    dataPieEmpty: [{ name: "No Data", value: 100, count: 0 }],
    COLORSEMPTY: ['#393D44'],

    dataPie1: [],
    dataPie1Value: 0,
    COLORS1: ['#9B59B6', '#F44336', '#FF7913', '#2962FF'],

    dataPie2: [],
    dataPie2Value: 0,
    COLORS2: ['#F44336', '#FFC400', '#00C853', '#2962FF'],

    dataPie3: [],
    dataPie3Value: 0,
    COLORS3: ['#FFC400', '#2962FF', '#00C853'],

    widthchart: 0,
    widthdonut: 0,
    cxdonut: 0,
    isCreateRight: false,
    isUpdateRight: false,
    isApproveRight: false,
    isDenyRight: false,
    isCancelRight: false,
    isCheckInRight: false,
    isCheckOutRight: false,
    isLoadingToGetFilteredData: false,
    comment: "",
    showDeny: false,
    visitListDeny: [],
    activeMode: "",
    visitDeny: null,
    statusDeny: "",
    denyComment: "",
    sortObjectAwaitingApproval: { columnName: "", direction: "" },  // asc, desc
    sortObjectToday: { columnName: "", direction: "" },             // asc, desc
    sortObjectNextDay: { columnName: "", direction: "" },           // asc, desc
    sortObjectOnSite: { columnName: "", direction: "" },            // asc, desc
    sortObjectFutureVisitor: { columnName: "", direction: "" },     // asc, desc
    sortObjectFilteredVisitor: { columnName: "", direction: "" }, // asc, desc
    inactiveStartSort: "",
    inactiveEndSort: "",
    approvedStartSort: "",
    approvedEndSort: "",
    onsiteStartSort: "",
    onsiteEndSort: "",
    nextWorkingStartSort: "",
    nextWorkingEndSort: "",
    todayStartSort: "",
    todayEndSort: "",
    awaitingStartSort: "",
    awaitingEndSort: "",
    isApprove: false,
    isDeny: false,
    isCancel: false,
    isCheckIn: false,
    isCheckOut: false,
    isPrintPass: false,
    isEditButton: false,
    value: "",
    customFilterVisitor: [],
    filterStatusOption: [
      { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
      { label: this.labels.HubLabelCancelled, value: "Cancelled" },
      { label: this.labels.HubLabelDeniedLabel, value: "Denied" },
      { label: this.labels.HubLabelApproved, value: "Approved" },
      { label: this.labels.HubLabelCheckedIn, value: "Checked In" },
      { label: this.labels.HubLabelCheckedOut, value: "Checked Out" },
      { label: this.labels.HubLabelNoShow, value: "No Show" },
      { label: this.labels.HubLabelAutoCancelledStatus, value: "Auto Cancelled" },
    ],
    userList: [{ label: "", email: "", }],
    selectedHostObject: { label: "", email: "" },
    filterMode: false,
    isFileUpload: false,
    file: { name: "" },
    fileImporting: false,
    printPassData: {},
    confirmModalshow: false,
    exportExcelFunctionKey: '',
    badgeImageUrl: '',
    isEBRD: false,
  };
  toDataURL = (url: any, callback: any) =>
  {
    var xhr = new XMLHttpRequest();
    xhr.onload = function ()
    {
      var reader = new FileReader();
      reader.onloadend = function ()
      {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
  public async componentDidMount()
  {
    const { match, history }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    apis.getData({ endPoint: `${this.state.buildingid}/Parameters?schema=false&recursive=true&%24filter=Parameter_Name%20eq%20%27Vis_Pass_Image%27` }).then((res) =>
    {
      if (res.status === 200)
      {
        var self = this;
        this.setState({ badgeImageUrl: res.data[0].Parameter_Value });
        this.toDataURL(res.data[0].Parameter_Value, function (dataUrl: any)
        {
          self.setState({ badgeImageUrl: dataUrl })
        })
      }
    })
    this.calculateWidth();
    this.setState({ isEBRD: window.location.origin.toLowerCase().indexOf('ebrd') != -1 ? true : false });
    if (match.params.tab)
    {
      this.setState({
        activePage: parseInt(match.params.tab)
      })
    }

    if (this.state.buildingid)
    {
      this.setState({
        buildingid: this.state.buildingid
      })
      if (this.state.buildingid !== match.params.buildingid)
      {
        let temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
        history.push(temp + "/" + this.state.buildingid)
      }
    } else
    {
      if (match.params.buildingid !== "0")
      {
        apis.getSpacesById(match.params.buildingid, 'Building').then((res) =>
        {
          this.session.setBuildingId(match.params.buildingid);
          this.session.setBuildingName(res?.data?.name);
          window.location.reload();
        }).catch((error: any) => { });
      }
      else
      {
        this.alert.show("", this.labels.HubLabelSelectBuildingError);
      }
    }

    if (this.state.buildingid && match.params.buildingid !== null && this.state.buildingid !== match.params.buildingid)
    {
      // const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push("/operational-services-visitors-trends/" + this.state.buildingid)
    } else
    {
      if (this.state.buildingid && match.params.filter)
      {
        history.push("/operational-services-visitors-trends/" + this.state.buildingid + "/" + match.params.tab + "/" + match.params.filter)
      } else if (this.state.buildingid)
      {
        history.push("/operational-services-visitors-trends/" + this.state.buildingid)
      } else
      {
        history.push("/operational-services-visitors-trends/0")
      }
    }
    this.onBarClick({ activeLabel: DateTime.now().toLocaleString(DateTime.DATE_SHORT) });
    this.getRightList();
    this.getDetails();
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener('load', this.updateDimensions);
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void
  {
    if (prevState.buildingid != this.state.buildingid)
    {
      this.getDetails();
      this.getRightList();
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
    if (state.buildingId != undefined)
    {
      this.setState({ buildingid: state.buildingId });
      history.push(match.path.replace(":buildingid", state.buildingId));
    }
  }

  getRightList = () =>
  {

    const res = this.local.getIbssRightList();

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Create") > -1)
    {
      this.setState({
        isCreateRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Update") > -1)
    {
      this.setState({
        isUpdateRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Approve") > -1)
    {
      this.setState({
        isApproveRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Deny") > -1)
    {
      this.setState({
        isDenyRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("Cancel") > -1)
    {
      this.setState({
        isCancelRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("CheckIn") > -1)
    {
      this.setState({
        isCheckInRight: true
      })
    }

    if (res && res.ADMINPORTAL && res.ADMINPORTAL.Visits && res.ADMINPORTAL.Visits.indexOf("CheckOut") > -1)
    {
      this.setState({
        isCheckOutRight: true
      })
    }
  }

  calculateWidth = () =>
  {
    let chartwidth = window.innerWidth - 390;
    if (chartwidth > 2200)
    {
      this.setState({
        widthchart: 2200
      })
    } else
    {
      if (chartwidth < 960)
      {
        this.setState({
          widthchart: 960
        })
      } else
      {
        this.setState({
          widthchart: chartwidth
        })
      }
    }
  }

  updateDimensions = () =>
  {
    this.calculateWidth();
  }

  getDetails = async () =>
  {
    this.handleComponentMountGetFilterVisitor()

    const totalApiResponseErrorNumber = 7
    let apiResponseErrorNumber = 0
    this.setState({ getDetailsApiResponseErrorLoading: true, selectedDate: "" })

    try
    {
      await apis.getWorkingStartEndDateForSpecificBuilding(this.state.buildingid)
    } catch (error: any)
    {
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    }

    let tempGraph: any = [];
    let tempPie1: any = [];
    let tempPie2: any = [];
    let tempPie3: any = [];
    let todayDate = DateHelper.now().toJSDate();
    let dateFormatting = DateTime.fromISO(todayDate.toISOString()).toLocaleString(DateTime.DATE_SHORT);
    tempGraph.push({ name: dateFormatting, NoShow: 0, Cancelled: 0, AutoCancelled: 0, Completed: 0, Active: 0 });
    for (let i = 1; i < 14; i++)
    {
      const date = DateTime.fromISO(todayDate.toISOString()).toJSDate();
      dateFormatting = DateTime.fromISO(date.toISOString()).plus({ day: i }).toLocaleString(DateTime.DATE_SHORT);
      tempGraph.push({ name: dateFormatting, Denied: 0, NeverApproved: 0, AwaitingApproval: 0, Approved: 0 });
    }

    tempPie1.push({ name: "Denied", value: 0, count: 0 })
    tempPie1.push({ name: "Never Approved", value: 0, count: 0 })
    tempPie1.push({ name: "Awaiting Approval", value: 0, count: 0 })
    tempPie1.push({ name: "Approved", value: 0, count: 0 })

    tempPie2.push({ name: "No Show", value: 0, count: 0 })
    tempPie2.push({ name: "Cancelled", value: 0, count: 0 })
    tempPie2.push({ name: "Completed", value: 0, count: 0 })
    tempPie2.push({ name: "Active", value: 0, count: 0 })

    tempPie3.push({ name: "Not Checked In", value: 0, count: 0 })
    tempPie3.push({ name: "Checked In", value: 0, count: 0 })
    tempPie3.push({ name: "Checked Out", value: 0, count: 0 })

    this.setState({
      dataGraph: tempGraph,
      dataPie1: tempPie1,
      dataPie2: tempPie2,
      dataPie3: tempPie3
    });

    apis.getOverviewVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      todayDate = DateHelper.now().toJSDate();
      dateFormatting = DateTime.fromISO(todayDate.toString()).set({ day: DateTime.local().day }).toLocaleString(DateTime.DATE_SHORT);
      for (let i = 0; i < tempGraph.length; i++)
      {
        let Denied = 0;
        let NeverApproved = 0;
        let AwaitingApproval = 0;
        let Approved = 0;

        for (let j = 0; j < res.data.length; j++)
        {
          dateFormatting = DateTime.fromISO(res.data[j].Visit_Start_Date).toLocaleString(DateTime.DATE_SHORT);
          if (tempGraph[i].name === dateFormatting)
          {
            if (res.data[j].Visit_IsApproved === 0 && res.data[j].Visit_IsDenied === 1)
            {
              Denied = Denied + 1;
            }
            if (res.data[j].Visit_IsApproved === 0 && (res.data[j].Visit_IsNoShow === 1 || res.data[j].Visit_IsCancelled === 1) && res.data[j].Visit_IsDenied === 0)
            {
              NeverApproved = NeverApproved + 1;
            }
            if (res.data[j].Visit_IsApproved === 0 && res.data[j].Visit_IsNoShow === 0 && res.data[j].Visit_IsDenied === 0 && res.data[j].Visit_IsCancelled === 0)
            {
              AwaitingApproval = AwaitingApproval + 1;
            }
            if (res.data[j].Visit_IsApproved === 1)
            {
              Approved = Approved + 1;
            }
          }
        }

        tempGraph[i].Denied = Denied
        tempGraph[i].NeverApproved = NeverApproved
        tempGraph[i].AwaitingApproval = AwaitingApproval
        tempGraph[i].Approved = Approved
      }
      this.setState({
        dataGraph: tempGraph
      });

    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getBottomChartVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      this.setState({
        donutChartData: res.data
      })

      tempPie1 = [];
      tempPie2 = [];
      tempPie3 = [];
      let Denied = 0;
      let NeverApproved = 0;
      let AwaitingApproval = 0;
      let Approved = 0;
      let NoShow = 0;
      let Cancelled = 0;
      let Completed = 0;
      let Active = 0;
      let NotCheckedIn = 0;
      let CheckedIn = 0;
      let CheckedOut = 0;

      const visitsToday = res.data.filter((visit: IVisit) => 
      DateTime.fromISO(visit.Visit_Start_Date).toLocaleString(DateTime.DATE_SHORT) == DateTime.now().toLocaleString(DateTime.DATE_SHORT));

      for (let i = 0; i < visitsToday.length; i++)
      {
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsDenied === 1)
        {
          Denied = Denied + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && (visitsToday[i].Visit_IsNoShow === 1 || visitsToday[i].Visit_IsCancelled === 1) && visitsToday[i].Visit_IsDenied === 0)
        {
          NeverApproved = NeverApproved + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsDenied === 0 && visitsToday[i].Visit_IsCancelled === 0)
        {
          AwaitingApproval = AwaitingApproval + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1)
        {
          Approved = Approved + 1;
        }

        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsNoShow === 1)
        {
          NoShow = NoShow + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 0 && visitsToday[i].Visit_IsCancelled === 1)
        {
          Cancelled = Cancelled + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 1)
        {
          Completed = Completed + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCancelled === 0 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsCheckedOut === 0)
        {
          Active = Active + 1;
        }


        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsNoShow === 0 && visitsToday[i].Visit_IsCheckedIn === 0 && visitsToday[i].Visit_IsCheckedOut === 0)
        {
          NotCheckedIn = NotCheckedIn + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 0 && visitsToday[i].Visit_IsCheckedIn === 1)
        {
          CheckedIn = CheckedIn + 1;
        }
        if (visitsToday[i].Visit_IsApproved === 1 && visitsToday[i].Visit_IsCheckedOut === 1 && visitsToday[i].Visit_IsCheckedIn === 0)
        {
          CheckedOut = CheckedOut + 1;
        }
      }

      let totalDonut1 = Denied + NeverApproved + AwaitingApproval + Approved;
      let totalDonut2 = NoShow + Cancelled + Completed + Active;
      let totalDonut3 = NotCheckedIn + CheckedIn + CheckedOut;

      tempPie1.push({ name: this.labels.HubLabelDeniedLabel, value: Math.round((Denied / totalDonut1) * 100), count: Denied })
      tempPie1.push({ name: this.labels.HubLabelNeverApproved, value: Math.round((NeverApproved / totalDonut1) * 100), count: NeverApproved })
      tempPie1.push({ name: this.labels.HubTabAwaitingApproval, value: Math.round((AwaitingApproval / totalDonut1) * 100), count: AwaitingApproval })
      tempPie1.push({ name: this.labels.HubLabelApproved, value: Math.round((Approved / totalDonut1) * 100), count: Approved })

      tempPie2.push({ name: this.labels.HubLabelNoShow, value: Math.round((NoShow / totalDonut2) * 100), count: NoShow })
      tempPie2.push({ name: this.labels.HubLabelCancelled, value: Math.round((Cancelled / totalDonut2) * 100), count: Cancelled })
      tempPie2.push({ name: this.labels.HubLabelCompleted, value: Math.round((Completed / totalDonut2) * 100), count: Completed })
      tempPie2.push({ name: this.labels.HubLabelActive, value: Math.round((Active / totalDonut2) * 100), count: Active })

      tempPie3.push({ name: this.labels.HubLabelNotCheckedIn, value: Math.round((NotCheckedIn / totalDonut3) * 100), count: NotCheckedIn })
      tempPie3.push({ name: this.labels.HubLabelCheckedIn, value: Math.round((CheckedIn / totalDonut3) * 100), count: CheckedIn })
      tempPie3.push({ name: this.labels.HubLabelCheckedOut, value: Math.round((CheckedOut / totalDonut3) * 100), count: CheckedOut })

      this.setState({
        dataPie1: tempPie1,
        dataPie1Value: totalDonut1,
        dataPie2: tempPie2,
        dataPie2Value: totalDonut2,
        dataPie3: tempPie3,
        dataPie3Value: totalDonut3
      })

    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getAwaitingApprovalVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let newArray = res.data && res.data[0] && res.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ awaitingApprovalVisitor: (newArray && newArray[0]) ? newArray : [] });
    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getTodaysVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let newArray = res.data && res.data[0] && res.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ todaysVisitor: (newArray && newArray[0]) ? newArray : [] });
    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getNextWorkingDayVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let newArray = res.data && res.data[0] && res.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ nextWorkingDayVisitor: (newArray && newArray[0]) ? newArray : [] });
    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getCheckedInVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let newArray = res.data && res.data[0] && res.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ onsiteVisitor: (newArray && newArray[0]) ? newArray : [] });
    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    apis.getFutureVisitors(this.state.buildingid).then((res) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let newArray = res.data && res.data[0] && res.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ futureVisitor: (newArray && newArray[0]) ? newArray : [] });
    }).catch((error: any) =>
    {
      apiResponseErrorNumber++
      this.setState({ getDetailsApiResponseErrorLoading: (apiResponseErrorNumber === totalApiResponseErrorNumber) ? false : true, })
      let errorMessage = this.labels.HubLabelTokenErorr
      if (error.response)
      {
        if (error.response.statusText)
        {
          errorMessage = error.response.statusText
          this.setState({
            tableMessage: errorMessage
          })
        }
      }
    });

    // apis.getInactiveVisitors(this.state.buildingid).then((res) => {
    //   if (res.data.length === 0) {
    //     this.setState({
    //       inactiveVisitor: [],
    //     });
    //   } else {
    //     for (let i = 0; i < res.data.length; i++) {
    //       const moment = require('moment');
    //       let startDate = res.data[i].Visit_Start_Date
    //       let endDate = res.data[i].Visit_End_Date
    //       res.data[i].Visit_Start_DateSort = moment(startDate).format("yy-MM-DD HH:mm");
    //       res.data[i].Visit_End_DateSort = moment(endDate).format("yy-MM-DD HH:mm");
    //       res.data[i].Visit_Start_Date = <div onClick={() => this.updateVisitDetail(res.data[i])}>{moment(startDate).format("DD/MM/yyyy HH:mm")}</div>
    //       res.data[i].Visit_End_Date = <div onClick={() => this.updateVisitDetail(res.data[i])}>{moment(endDate).format("DD/MM/yyyy HH:mm")}</div>
    //     }

    //     this.setState({ inactiveVisitor: res.data });
    //   }
    // }).catch((error: any) => {
    //   let errorMessage = this.labels.HubLabelTokenErorr
    //   if (error.response) {
    //     if (error.response.statusText) {
    //       errorMessage = error.response.statusText
    //       this.setState({
    //         tableMessage: errorMessage
    //       })
    //     }
    //   }
    // });
  }

  handleClick(params: any)
  {
    const { history }: any = this.props;
    history.push("/operational-services-visitors-trends/" + this.state.buildingid + "/visit/" + params.Visit_Id);
  }

  checkBoxListAwaitingApprovalVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;
    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    let index: any = -1
    let approve = false;
    let deny = false;
    let checkin = false;
    let checkout = false;
    let cancel = false;
    let printPass = false;
    let editButton = false;
    for (let i = 0; i < this.state.selectedBoxAwaitingApprovalVisitor.length; i++)
    {
      if (this.state.selectedBoxAwaitingApprovalVisitor[i].visitid === params)
      {
        index = i;
      }
    }
    let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
    if (index === -1)
    {
      if (this.state.selectedBoxAwaitingApprovalVisitor[0].visitid === "")
      {
        tempData = [{ visitid: params, status: status }];
      } else
      {
        tempData.push({ visitid: params, status: status });
      }
    } else
    {
      tempData.splice(index, 1);
      if (tempData.length === 0)
      {
        tempData = [{ visitid: "", status: "" }];
      }
    }
    for (let i = 0; i < tempData.length; i++)
    {
      if (tempData[i].status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Approved")
      {
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Checked In")
      {
        checkout = true;
      }
      if ((tempData[i].status === "Checked In" || tempData[i].status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
    }
    if (tempData[0].visitid === "")
    {
      approve = false;
      deny = false;
      checkin = false;
      checkout = false;
      cancel = false;
      editButton = false;
      printPass = false;
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: tempData,
      isApprove: approve,
      isDeny: deny,
      isCancel: cancel,
      isCheckIn: checkin,
      isCheckOut: checkout,
      isPrintPass: printPass,
      isEditButton: editButton,
    })
  }

  checkBoxListTodaysVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;
    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    let index: any = -1
    let approve = false;
    let deny = false;
    let checkin = false;
    let checkout = false;
    let cancel = false;
    let printPass = false;
    let editButton = false;


    for (let i = 0; i < this.state.selectedBoxTodaysVisitor.length; i++)
    {
      if (this.state.selectedBoxTodaysVisitor[i].visitid === params)
      {
        index = i;
      }
    }
    let tempData: any = this.state.selectedBoxTodaysVisitor;
    if (index === -1)
    {
      if (this.state.selectedBoxTodaysVisitor[0].visitid === "")
      {
        tempData = [{ visitid: params, status: status }];
      } else
      {
        tempData.push({ visitid: params, status: status });
      }
    } else
    {
      tempData.splice(index, 1);
      if (tempData.length === 0)
      {
        tempData = [{ visitid: "", status: "" }];
      }
    }

    for (let i = 0; i < tempData.length; i++)
    {
      if (tempData[i].status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Approved")
      {
        checkin = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Checked In")
      {
        checkout = true;
      }
      if ((tempData[i].status === "Checked In" || tempData[i].status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
    }
    if (tempData[0].visitid === "")
    {
      approve = false;
      deny = false;
      checkin = false;
      checkout = false;
      cancel = false;
      editButton = false;
      printPass = false;
    }
    this.setState({
      selectedBoxTodaysVisitor: tempData,
      isApprove: approve,
      isDeny: deny,
      isCancel: cancel,
      isCheckIn: checkin,
      isCheckOut: checkout,
      isPrintPass: printPass,
      isEditButton: editButton,
    })

  }

  checkBoxListNextWorkingDayVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;

    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    if (this.state.selectedBoxNextWorkingDayVisitor.visitid !== params)
    {
      let approve = false;
      let deny = false;
      let checkin = false;
      let checkout = false;
      let cancel = false;
      let printPass = false;
      let editButton = false;
      if (status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (status === "Approved")
      {
        deny = true;
        cancel = true;
      }
      if (status === "Checked In")
      {
        checkout = true;
      }
      if ((status === "Checked In" || status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
      this.setState({
        selectedBoxNextWorkingDayVisitor: { visitid: params, status: status },
        isApprove: approve,
        isDeny: deny,
        isCancel: cancel,
        isCheckIn: checkin,
        isCheckOut: checkout,
        isPrintPass: printPass,
        isEditButton: editButton,
      })
    } else
    {
      this.setState({
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        isApprove: false,
        isDeny: false,
        isCancel: false,
        isCheckIn: false,
        isCheckOut: false,
        isPrintPass: false,
        isEditButton: false,
      })
    }
  }

  checkBoxListOnsiteVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;

    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    let index: any = -1
    let approve = false;
    let deny = false;
    let checkin = false;
    let checkout = false;
    let cancel = false;
    let printPass = false;
    let editButton = false;
    for (let i = 0; i < this.state.selectedBoxOnsiteVisitor.length; i++)
    {
      if (this.state.selectedBoxOnsiteVisitor[i].visitid === params)
      {
        index = i;
      }
    }
    let tempData: any = this.state.selectedBoxOnsiteVisitor;
    if (index === -1)
    {
      if (this.state.selectedBoxOnsiteVisitor[0].visitid === "")
      {
        tempData = [{ visitid: params, status: status }];
      } else
      {
        tempData.push({ visitid: params, status: status });
      }
    } else
    {
      tempData.splice(index, 1);
      if (tempData.length === 0)
      {
        tempData = [{ visitid: "", status: "" }];
      }
    }
    for (let i = 0; i < tempData.length; i++)
    {
      if (tempData[i].status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Approved")
      {
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Checked In")
      {
        checkout = true;
      }
      if ((tempData[i].status === "Checked In" || tempData[i].status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
    }
    if (tempData[0].visitid === "")
    {
      approve = false;
      deny = false;
      checkin = false;
      checkout = false;
      cancel = false;
      editButton = false;
      printPass = false;
    }
    this.setState({
      selectedBoxOnsiteVisitor: tempData,
      isApprove: approve,
      isDeny: deny,
      isCancel: cancel,
      isCheckIn: checkin,
      isCheckOut: checkout,
      isPrintPass: printPass,
      isEditButton: editButton,
    })
  }

  checkBoxListApprovedVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;

    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    let index: any = -1
    let approve = false;
    let deny = false;
    let checkin = false;
    let checkout = false;
    let cancel = false;
    let printPass = false;
    let editButton = false;
    for (let i = 0; i < this.state.selectedBoxApprovedVisitor.length; i++)
    {
      if (this.state.selectedBoxApprovedVisitor[i].visitid === params)
      {
        index = i;
      }
    }
    let tempData: any = this.state.selectedBoxApprovedVisitor;
    if (index === -1)
    {
      if (this.state.selectedBoxApprovedVisitor[0].visitid === "")
      {
        tempData = []
        tempData.push({ visitid: params, status: status });
      } else
      {
        tempData.push({ visitid: params, status: status });
      }
    } else
    {
      tempData.splice(index, 1);
      if (tempData.length === 0)
      {
        tempData = [{ visitid: "", status: "" }];
      }
    }
    for (let i = 0; i < tempData.length; i++)
    {
      if (tempData[i].status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Approved")
      {
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Checked In")
      {
        checkout = true;
      }
      if ((tempData[i].status === "Checked In" || tempData[i].status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
    }
    if (tempData[0].visitid === "")
    {
      approve = false;
      deny = false;
      checkin = false;
      checkout = false;
      cancel = false;
      editButton = false;
      printPass = false;
    }
    this.setState({
      selectedBoxApprovedVisitor: tempData,
      isApprove: approve,
      isDeny: deny,
      isCancel: cancel,
      isCheckIn: checkin,
      isCheckOut: checkout,
      isPrintPass: printPass,
      isEditButton: editButton,
    })
  }


  checkBoxListCustomFilterVisitor = (e: any, params: any, endDate: any, status: any) =>
  {
    params = e.target.name;
    const todayDate = DateHelper.now().toJSDate();
    const todayFormattedDate = DateHelper.fromIsoToDefaultFormat(todayDate.toISOString(), 'dd/MM/yyyy HH:mm');

    const todayAndFutureDate = () =>
    {
      let condition = false
      if (endDate > todayFormattedDate)
      {
        condition = true
      }
      return condition;
    }

    let index: any = -1
    let approve = false;
    let deny = false;
    let checkin = false;
    let checkout = false;
    let cancel = false;
    let printPass = false;
    let editButton = false;
    for (let i = 0; i < this.state.selectedBoxCustomFilterVisitor.length; i++)
    {
      if (this.state.selectedBoxCustomFilterVisitor[i].visitid === params)
      {
        index = i;
      }
    }
    let tempData: any = this.state.selectedBoxCustomFilterVisitor;
    if (index === -1)
    {
      if (this.state.selectedBoxCustomFilterVisitor[0].visitid === "")
      {
        tempData = []
        tempData.push({ visitid: params, status: status });
      } else
      {
        tempData.push({ visitid: params, status: status });
      }
    } else
    {
      tempData.splice(index, 1);
      if (tempData.length === 0)
      {
        tempData = [{ visitid: "", status: "" }];
      }
    }
    for (let i = 0; i < tempData.length; i++)
    {
      if (tempData[i].status === "Awaiting Approval")
      {
        approve = true
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Approved")
      {
        deny = true;
        cancel = true;
      }
      if (tempData[i].status === "Checked In")
      {
        checkout = true;
      }
      if ((tempData[i].status === "Checked In" || tempData[i].status === "Approved") && todayAndFutureDate())
      {
        printPass = true;
        editButton = true;
      }
    }
    if (tempData[0].visitid === "")
    {
      approve = false;
      deny = false;
      checkin = false;
      checkout = false;
      cancel = false;
      editButton = false;
      printPass = false;
    }
    this.setState({
      selectedBoxCustomFilterVisitor: tempData,
      isApprove: approve,
      isDeny: deny,
      isCancel: cancel,
      isCheckIn: checkin,
      isCheckOut: checkout,
      isPrintPass: printPass,
      isEditButton: editButton,
    })
  }

  checkBoxListInactiveVisitor = (e: any, params: any) =>
  {
    params = e.target.name;
    let tempData: any = this.state.selectedBoxInactiveVisitor;
    let index: any = tempData.indexOf(params)
    if (index === -1)
    {
      tempData.push(params);
      this.setState({
        selectedBoxInactiveVisitor: tempData
      })
    } else
    {
      tempData.splice(index, 1);
      this.setState({
        selectedBoxInactiveVisitor: tempData
      })
    }
  }

  updateVisitDetail = (e: any) =>
  {
    const { history }: any = this.props;
    history.push(`/operational-services-visitors-trends/${this.state.buildingid}/visit/${e.Visit_Id}/${this.state.activePage}`);
  }

  denyVisitorSingle(visitid: any)
  {
    this.setState({
      showDeny: !this.state.showDeny,
      visitDeny: visitid,
      activeMode: "deny",
      statusDeny: "Single"
    })
  }

  singleVisitorApproveUpdate(visitid: any)
  {
    this.setState({ isLoadingToSave: true, })
    apis.updateApproveVisitByVisitId(this.state.buildingid, visitid).then((res) =>
    {
      this.setState({
        isLoadingToSave: false,
        selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
        selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
        selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
        selectedBoxInactiveVisitor: [],
      }, () =>
      {
        this.alert.show(this.labels.HubButtonApprove, visitid + " - " + (res.data.message ? res.data.message : res.data.Message));
      })
      this.getDetails()
    }).catch((error: any) =>
    {
      this.setState({ isLoadingToSave: false, })
    });
  }

  denyVisitorMulti = (visitList: any) =>
  {
    this.setState({
      showDeny: !this.state.showDeny,
      visitListDeny: visitList,
      activeMode: "deny",
      statusDeny: "Multi"
    })
  }

  multiVisitorCancelUpdate = (visitList: any) =>
  {
    let apiResponseErrorNumber = 0
    let messageList: any = [];
    let message = "";
    for (let i = 0; i < visitList.length; i++)
    {
      messageList.push({ id: i, showMessage: (i + 1) + "/" + visitList.length + " " + visitList[i].visitid, status: false })
    }

    for (let j = 0; j < visitList.length; j++)
    {
      if (j === 0)
      {
        message = messageList[j].showMessage
      } else
      {
        message = message + '\n' + messageList[j].showMessage
      }
    }
    this.setState({ isLoadingToSave: true });

    for (let i = 0; i < visitList.length; i++)
    {
      apis.updateCancelledVisitByVisitId(this.state.buildingid, visitList[i].visitid).then((res) =>
      {
        apiResponseErrorNumber++
        messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (res.data.message ? res.data.message : res.data.Message);
        messageList[i].status = true;
        let flag = 0;
        for (let j = 0; j < visitList.length; j++)
        {
          if (messageList[j].status === false)
          {
            flag = 1;
          }
        }
        if (flag === 0)
        {
          for (let j = 0; j < visitList.length; j++)
          {
            if (j === 0)
            {
              message = messageList[j].showMessage
            } else
            {
              message = message + '\n' + messageList[j].showMessage
            }
          }
          this.alert.show(this.labels.HubButtonCancel, message);
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      }).catch((error: any) =>
      {
        apiResponseErrorNumber++
        if (error.response)
        {
          if (error.response.statusText)
          {
            messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (error.response.data.message ? error.response.data.message : error.response.data.Message);
            messageList[i].status = true;
            let flag = 0;
            for (let j = 0; j < visitList.length; j++)
            {
              if (messageList[j].status === false)
              {
                flag = 1;
              }
            }
            if (flag === 0)
            {
              for (let j = 0; j < visitList.length; j++)
              {
                if (j === 0)
                {
                  message = messageList[j].showMessage
                } else
                {
                  message = message + '\n' + messageList[j].showMessage
                }
              }
              this.alert.show(this.labels.HubButtonCancel, message);
            }
          }
        } else
        {
          messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + this.labels.HubLabelerrorSomethingWentWrong;
          messageList[i].status = true;
          let flag = 0;
          for (let j = 0; j < visitList.length; j++)
          {
            if (messageList[j].status === false)
            {
              flag = 1;
            }
          }
          if (flag === 0)
          {
            for (let j = 0; j < visitList.length; j++)
            {
              if (j === 0)
              {
                message = messageList[j].showMessage
              } else
              {
                message = message + '\n' + messageList[j].showMessage
              }
            }
            this.alert.show(this.labels.HubButtonCancel, message);
          }
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      });
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
      selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
      selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
      selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
      selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
      selectedBoxInactiveVisitor: [],
    })
  }

  singleVisitorCancelUpdate(visitid: any)
  {
    this.setState({ isLoadingToSave: true, })
    apis.updateCancelledVisitByVisitId(this.state.buildingid, visitid).then((res) =>
    {
      this.setState({
        isLoadingToSave: false,
        selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
        selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
        selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
        selectedBoxInactiveVisitor: [],
      }, () =>
      {
        this.alert.show(this.labels.HubButtonCancel, visitid + " - " + (res.data.message ? res.data.message : res.data.Message));
      })
      this.getDetails()
    }).catch((error: any) =>
    {
      this.setState({ isLoadingToSave: false, })
    });
  }

  singleVisitorCheckInUpdate(visitid: any)
  {
    this.setState({ isLoadingToSave: true, })
    apis.updateCheckedInVisitByVisitId(this.state.buildingid, visitid).then((res) =>
    {
      this.setState({
        isLoadingToSave: false,
        selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
        selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
        selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
        selectedBoxInactiveVisitor: [],
      }, () =>
      {
        this.alert.show(this.labels.HubButtonCheckIn, visitid + " - " + (res.data.message ? res.data.message : res.data.Message));
      })
      this.getDetails()
    }).catch((error: any) =>
    {
      this.setState({ isLoadingToSave: false, })
    });
  }

  multiVisitorCheckInUpdate = (visitList: any) =>
  {
    let apiResponseErrorNumber = 0
    let messageList: any = [];
    let message = "";
    for (let i = 0; i < visitList.length; i++)
    {
      messageList.push({ id: i, showMessage: (i + 1) + "/" + visitList.length + " " + visitList[i].visitid, status: false })
    }

    for (let j = 0; j < visitList.length; j++)
    {
      if (j === 0)
      {
        message = messageList[j].showMessage
      } else
      {
        message = message + '\n' + messageList[j].showMessage
      }
    }
    this.setState({ isLoadingToSave: true });

    for (let i = 0; i < visitList.length; i++)
    {
      apis.updateCheckedInVisitByVisitId(this.state.buildingid, visitList[i].visitid).then((res) =>
      {
        apiResponseErrorNumber++
        messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (res.data.message ? res.data.message : res.data.Message);
        messageList[i].status = true;
        let flag = 0;
        for (let j = 0; j < visitList.length; j++)
        {
          if (messageList[j].status === false)
          {
            flag = 1;
          }
        }
        if (flag === 0)
        {
          for (let j = 0; j < visitList.length; j++)
          {
            if (j === 0)
            {
              message = messageList[j].showMessage
            } else
            {
              message = message + '\n' + messageList[j].showMessage
            }
          }
          this.alert.show(this.labels.HubButtonCheckIn, message);
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      }).catch((error: any) =>
      {
        apiResponseErrorNumber++
        if (error.response)
        {
          if (error.response.statusText)
          {
            messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (error.response.data.message ? error.response.data.message : error.response.data.Message);
            messageList[i].status = true;
            let flag = 0;
            for (let j = 0; j < visitList.length; j++)
            {
              if (messageList[j].status === false)
              {
                flag = 1;
              }
            }
            if (flag === 0)
            {
              for (let j = 0; j < visitList.length; j++)
              {
                if (j === 0)
                {
                  message = messageList[j].showMessage
                } else
                {
                  message = message + '\n' + messageList[j].showMessage
                }
              }
              this.alert.show(this.labels.HubButtonCheckIn, message);
            }
          }
        } else
        {
          messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + this.labels.HubLabelerrorSomethingWentWrong;
          messageList[i].status = true;
          let flag = 0;
          for (let j = 0; j < visitList.length; j++)
          {
            if (messageList[j].status === false)
            {
              flag = 1;
            }
          }
          if (flag === 0)
          {
            for (let j = 0; j < visitList.length; j++)
            {
              if (j === 0)
              {
                message = messageList[j].showMessage
              } else
              {
                message = message + '\n' + messageList[j].showMessage
              }
            }
            this.alert.show(this.labels.HubButtonCheckIn, message);
          }
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      });
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
      selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
      selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
      selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
      selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
      selectedBoxInactiveVisitor: [],
    })
  }

  singleVisitorCheckoutUpdate(visitid: any)
  {
    this.setState({ isLoadingToSave: true, })
    apis.updateCheckedOutVisitByVisitId(this.state.buildingid, visitid).then((res) =>
    {
      this.setState({
        isLoadingToSave: false,
        selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
        selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
        selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
        selectedBoxInactiveVisitor: [],
      }, () =>
      {
        this.alert.show(this.labels.HubButtonCheckOut, visitid + " - " + (res.data.message ? res.data.message : res.data.Message));
      })
      this.getDetails()
    }).catch((error: any) =>
    {
      this.setState({ isLoadingToSave: false, })
    });
  }
  printPass = (data: any, key: string) =>
  {
    const content: any = document.getElementById("visitorBadgeContent");
    if (content && content.style)
    {
      content.style.display = 'none'
    }
    let find = {}
    if (key === 'awaitingApprovalVisitor')
    {
      //find = this.state[key].find((obj: any) => obj.Visit_Id === data[0].visitid)
      const id = data[0].visitid;
      apis.getDetailsByVisitId(this.state['buildingid'], id).then(res =>
      {
        find = res.data;
        this.setState({ printPassData: find, showPrintPass: !this.state.showPrintPass });
      });
    } else
    {
      //find = this.state[key].find((obj: any) => obj.Visit_Id === data.visitid)
      let id;
      if (Array.isArray(data))
      {
        id = data[0].visitid;
      } else
      {
        id = data.visitid;
      }
      apis.getDetailsByVisitId(this.state['buildingid'], id).then(res =>
      {
        find = res.data;
        this.setState({ printPassData: find, showPrintPass: !this.state.showPrintPass });
      });
    }
    // let find = {}
    // if (key === 'awaitingApprovalVisitor') {
    //   find = this.state[key].find((obj: any) => obj.Visit_Id === data[0].visitid)
    // } else {
    //   find = this.state[key].find((obj: any) => obj.Visit_Id === data.visitid)
    // }
    // this.setState({ printPassData: find, showPrintPass: !this.state.showPrintPass });

  }
  makePrintPass = () =>
  {
    const content: any = document.getElementById("visitorBadgeContent");
    content.style.display = 'block'
    setTimeout(() =>
    {
      const content: any = document.getElementById("visitorBadgeContent");
      html2pdf(content, {
        jsPDF: {
          format: 'a6', orientation: 'l', unit: 'px'
        },
        html2canvas: { scale: 2, allowTaint: false, useCORS: true },
        image: { type: 'jpeg', quality: 0.98 },
        output: './pdf/generate.pdf'
      });
      this.setState({ showPrintPass: !this.state.showPrintPass })
    })

    // var opt = {
    //   margin: 1,
    //   filename: 'userCard.pdf',
    //   image: { type: 'jpg', quality: 0.99 },
    //   html2canvas: { dpi: 192, letterRendering: true, useCORS: true },
    //   jsPDF: {
    //     unit: 'pt', format: 'letter', orientation: 'portrait'
    //   }
    // };
    // html2pdf().from(content).set(opt).save();
  }

  multiVisitorCheckoutUpdate = (visitList: any) =>
  {
    let apiResponseErrorNumber = 0
    let messageList: any = [];
    let message = "";
    for (let i = 0; i < visitList.length; i++)
    {
      messageList.push({ id: i, showMessage: (i + 1) + "/" + visitList.length + " " + visitList[i].visitid, status: false })
    }

    for (let j = 0; j < visitList.length; j++)
    {
      if (j === 0)
      {
        message = messageList[j].showMessage
      } else
      {
        message = message + '\n' + messageList[j].showMessage
      }
    }
    this.setState({ isLoadingToSave: true });

    for (let i = 0; i < visitList.length; i++)
    {
      apis.updateCheckedOutVisitByVisitId(this.state.buildingid, visitList[i].visitid).then((res) =>
      {
        apiResponseErrorNumber++
        messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (res.data.message ? res.data.message : res.data.Message);
        messageList[i].status = true;
        let flag = 0;
        for (let j = 0; j < visitList.length; j++)
        {
          if (messageList[j].status === false)
          {
            flag = 1;
          }
        }
        if (flag === 0)
        {
          for (let j = 0; j < visitList.length; j++)
          {
            if (j === 0)
            {
              message = messageList[j].showMessage
            } else
            {
              message = message + '\n' + messageList[j].showMessage
            }
          }
          this.alert.show(this.labels.HubButtonCheckOut, message);
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      }).catch((error: any) =>
      {
        apiResponseErrorNumber++
        if (error.response)
        {
          if (error.response.statusText)
          {
            messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (error.response.data.message ? error.response.data.message : error.response.data.Message);
            messageList[i].status = true;
            let flag = 0;
            for (let j = 0; j < visitList.length; j++)
            {
              if (messageList[j].status === false)
              {
                flag = 1;
              }
            }
            if (flag === 0)
            {
              for (let j = 0; j < visitList.length; j++)
              {
                if (j === 0)
                {
                  message = messageList[j].showMessage
                } else
                {
                  message = message + '\n' + messageList[j].showMessage
                }
              }
              this.alert.show(this.labels.HubButtonCheckOut, message);
            }
          }
        } else
        {
          messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + this.labels.HubLabelerrorSomethingWentWrong;
          messageList[i].status = true;
          let flag = 0;
          for (let j = 0; j < visitList.length; j++)
          {
            if (messageList[j].status === false)
            {
              flag = 1;
            }
          }
          if (flag === 0)
          {
            for (let j = 0; j < visitList.length; j++)
            {
              if (j === 0)
              {
                message = messageList[j].showMessage
              } else
              {
                message = message + '\n' + messageList[j].showMessage
              }
            }
            this.alert.show(this.labels.HubButtonCheckOut, message);
          }
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      });
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
      selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
      selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
      selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
      selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
      selectedBoxInactiveVisitor: [],
    })
  }

  multiVisitorApproveUpdate = (visitList: any) =>
  {
    let apiResponseErrorNumber = 0
    let messageList: any = [];
    let message = "";
    for (let i = 0; i < visitList.length; i++)
    {
      messageList.push({ id: i, showMessage: (i + 1) + "/" + visitList.length + " " + visitList[i].visitid, status: false })
    }

    for (let j = 0; j < visitList.length; j++)
    {
      if (j === 0)
      {
        message = messageList[j].showMessage
      } else
      {
        message = message + '\n' + messageList[j].showMessage
      }
    }
    this.setState({ isLoadingToSave: true });

    for (let i = 0; i < visitList.length; i++)
    {
      apis.updateApproveVisitByVisitId(this.state.buildingid, visitList[i].visitid).then((res) =>
      {
        apiResponseErrorNumber++
        messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (res.data.message ? res.data.message : res.data.Message);
        messageList[i].status = true;
        let flag = 0;
        for (let j = 0; j < visitList.length; j++)
        {
          if (messageList[j].status === false)
          {
            flag = 1;
          }
        }
        if (flag === 0)
        {
          for (let j = 0; j < visitList.length; j++)
          {
            if (j === 0)
            {
              message = messageList[j].showMessage
            } else
            {
              message = message + '\n' + messageList[j].showMessage
            }
          }
          this.alert.show(this.labels.HubButtonApprove, message);
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      }).catch((error: any) =>
      {
        apiResponseErrorNumber++
        if (error.response)
        {
          if (error.response.statusText)
          {
            messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (error.response.data.message ? error.response.data.message : error.response.data.Message);
            messageList[i].status = true;
            let flag = 0;
            for (let j = 0; j < visitList.length; j++)
            {
              if (messageList[j].status === false)
              {
                flag = 1;
              }
            }
            if (flag === 0)
            {
              for (let j = 0; j < visitList.length; j++)
              {
                if (j === 0)
                {
                  message = messageList[j].showMessage
                } else
                {
                  message = message + '\n' + messageList[j].showMessage
                }
              }
              this.alert.show(this.labels.HubButtonApprove, message);
            }
          }
        } else
        {
          messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + this.labels.HubLabelerrorSomethingWentWrong;
          messageList[i].status = true;
          let flag = 0;
          for (let j = 0; j < visitList.length; j++)
          {
            if (messageList[j].status === false)
            {
              flag = 1;
            }
          }
          if (flag === 0)
          {
            for (let j = 0; j < visitList.length; j++)
            {
              if (j === 0)
              {
                message = messageList[j].showMessage
              } else
              {
                message = message + '\n' + messageList[j].showMessage
              }
            }
            this.alert.show(this.labels.HubButtonApprove, message);
          }
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      });
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
      selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
      selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
      selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
      selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
      selectedBoxInactiveVisitor: [],
    })
  }

  multiVisitorDenyUpdate = (visitList: any, denyComment: any) =>
  {
    let apiResponseErrorNumber = 0
    let messageList: any = [];
    let message = "";
    for (let i = 0; i < visitList.length; i++)
    {
      messageList.push({ id: i, showMessage: (i + 1) + "/" + visitList.length + " " + visitList[i].visitid, status: false })
    }

    for (let j = 0; j < visitList.length; j++)
    {
      if (j === 0)
      {
        message = messageList[j].showMessage
      } else
      {
        message = message + '\n' + messageList[j].showMessage
      }
    }

    this.setState({ isLoadingToSave: true });

    for (let i = 0; i < visitList.length; i++)
    {
      apis.updateDenyVisitByVisitId(this.state.buildingid, visitList[i].visitid, denyComment).then((res) =>
      {
        apiResponseErrorNumber++
        messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (res.data.message ? res.data.message : res.data.Message);
        messageList[i].status = true;
        let flag = 0;
        for (let j = 0; j < visitList.length; j++)
        {
          if (messageList[j].status === false)
          {
            flag = 1;
          }
        }
        if (flag === 0)
        {
          for (let j = 0; j < visitList.length; j++)
          {
            if (j === 0)
            {
              message = messageList[j].showMessage
            } else
            {
              message = message + '\n' + messageList[j].showMessage
            }
          }
          this.alert.show(this.labels.HubButtonDeny, message);
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      }).catch((error: any) =>
      {
        apiResponseErrorNumber++
        if (error.response)
        {
          if (error.response.statusText)
          {
            messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + (error.response.data.message ? error.response.data.message : error.response.data.Message);
            messageList[i].status = true;
            let flag = 0;
            for (let j = 0; j < visitList.length; j++)
            {
              if (messageList[j].status === false)
              {
                flag = 1;
              }
            }
            if (flag === 0)
            {
              for (let j = 0; j < visitList.length; j++)
              {
                if (j === 0)
                {
                  message = messageList[j].showMessage
                } else
                {
                  message = message + '\n' + messageList[j].showMessage
                }
              }
              this.alert.show(this.labels.HubButtonDeny, message);
            }
          }
        } else
        {
          messageList[i].showMessage = (i + 1) + "/" + visitList.length + " " + visitList[i].visitid + " - " + this.labels.HubLabelerrorSomethingWentWrong;
          messageList[i].status = true;
          let flag = 0;
          for (let j = 0; j < visitList.length; j++)
          {
            if (messageList[j].status === false)
            {
              flag = 1;
            }
          }
          if (flag === 0)
          {
            for (let j = 0; j < visitList.length; j++)
            {
              if (j === 0)
              {
                message = messageList[j].showMessage
              } else
              {
                message = message + '\n' + messageList[j].showMessage
              }
            }
            this.alert.show(this.labels.HubButtonDeny, message);
          }
        }
        this.setState({ isLoadingToSave: (apiResponseErrorNumber === visitList.length) ? false : true, })
        if (apiResponseErrorNumber === visitList.length)
        {
          this.getDetails()
        }
      });
    }
    this.setState({
      selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
      selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
      selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
      selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
      selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
      selectedBoxInactiveVisitor: [],
    })
  }

  singleVisitorDenyUpdate(visitid: any, denyComment: any)
  {
    this.setState({ isLoadingToSave: true })
    apis.updateDenyVisitByVisitId(this.state.buildingid, visitid, denyComment).then((res) =>
    {
      this.setState({
        isLoadingToSave: false,
        selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
        selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
        selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
        selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
        selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
        selectedBoxInactiveVisitor: [],
      }, () =>
      {
        this.setState({ isLoadingToSave: false, })
        this.alert.show(this.labels.HubButtonDeny, visitid + " - " + (res.data.message ? res.data.message : res.data.Message));
      })
      this.getDetails()
    }).catch((error: any) =>
    {
      this.setState({ isLoadingToSave: false, })
    });
  }

  workInProgress = () =>
  {
    this.alert.show("", this.labels.HubLabelWorkInProgressLabel);
  }

  onBarClick = (e: any) =>
  {
    if (e !== null)
    {
      let bardate = " - " + e.activeLabel;
      if (this.state.selectedDate === bardate)
      {
        this.setState({
          selectedDate: ""
        })
        // this.getDetails();
      } else
      {
        let piedata = this.state.donutChartData;
        let tempPie1: any = [];
        let tempPie2: any = [];
        let tempPie3: any = [];
        let Denied = 0;
        let NeverApproved = 0;
        let AwaitingApproval = 0;
        let Approved = 0;
        let NoShow = 0;
        let Cancelled = 0;
        let Completed = 0;
        let Active = 0;
        let NotCheckedIn = 0;
        let CheckedIn = 0;
        let CheckedOut = 0;

        for (let i = 0; i < piedata.length; i++)
        {
          let data: any = piedata[i];
          let dateFormatting = DateTime.fromISO(data.Visit_Start_Date).toLocaleString(DateTime.DATE_SHORT)
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsDenied === 1)
          {
            Denied = Denied + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && (data.Visit_IsNoShow === 1 || data.Visit_IsCancelled === 1) && data.Visit_IsDenied === 0)
          {
            NeverApproved = NeverApproved + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsNoShow === 0 && data.Visit_IsDenied === 0 && data.Visit_IsCancelled === 0)
          {
            AwaitingApproval = AwaitingApproval + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1)
          {
            Approved = Approved + 1;
          }


          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsNoShow === 1)
          {
            NoShow = NoShow + 1;
          }
          if (e.activeLabel === dateFormatting && e.activeLabel === dateFormatting && data.Visit_IsApproved === 0 && data.Visit_IsCancelled === 1)
          {
            Cancelled = Cancelled + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 1)
          {
            Completed = Completed + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCancelled === 0 && data.Visit_IsNoShow === 0 && data.Visit_IsCheckedOut === 0)
          {
            Active = Active + 1;
          }


          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsNoShow === 0 && data.Visit_IsCheckedIn === 0 && data.Visit_IsCheckedOut === 0)
          {
            NotCheckedIn = NotCheckedIn + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 0 && data.Visit_IsCheckedIn === 1)
          {
            CheckedIn = CheckedIn + 1;
          }
          if (e.activeLabel === dateFormatting && data.Visit_IsApproved === 1 && data.Visit_IsCheckedOut === 1 && data.Visit_IsCheckedIn === 0)
          {
            CheckedOut = CheckedOut + 1;
          }
        }

        let totalDonut1 = Denied + NeverApproved + AwaitingApproval + Approved;
        let totalDonut2 = NoShow + Cancelled + Completed + Active;
        let totalDonut3 = NotCheckedIn + CheckedIn + CheckedOut;
        tempPie1.push({ name: "Denied", value: Math.round((Denied / totalDonut1) * 100), count: Denied })
        tempPie1.push({ name: "Never Approved", value: Math.round((NeverApproved / totalDonut1) * 100), count: NeverApproved })
        tempPie1.push({ name: "Awaiting Approval", value: Math.round((AwaitingApproval / totalDonut1) * 100), count: AwaitingApproval })
        tempPie1.push({ name: "Approved", value: Math.round((Approved / totalDonut1) * 100), count: Approved })

        tempPie2.push({ name: "No Show", value: Math.round((NoShow / totalDonut2) * 100), count: NoShow })
        tempPie2.push({ name: "Cancelled", value: Math.round((Cancelled / totalDonut2) * 100), count: Cancelled })
        tempPie2.push({ name: "Completed", value: Math.round((Completed / totalDonut2) * 100), count: Completed })
        tempPie2.push({ name: "Active", value: Math.round((Active / totalDonut2) * 100), count: Active })

        tempPie3.push({ name: "Not Checked In", value: Math.round((NotCheckedIn / totalDonut3) * 100), count: NotCheckedIn })
        tempPie3.push({ name: "Checked In", value: Math.round((CheckedIn / totalDonut3) * 100), count: CheckedIn })
        tempPie3.push({ name: "Checked Out", value: Math.round((CheckedOut / totalDonut3) * 100), count: CheckedOut })

        this.setState({
          dataPie1: [],
          dataPie1Value: 0,
          dataPie2: [],
          dataPie2Value: 0,
          dataPie3: [],
          dataPie3Value: 0,
        }, () =>
        {
          this.setState({
            dataPie1: tempPie1,
            dataPie1Value: totalDonut1,
            dataPie2: tempPie2,
            dataPie2Value: totalDonut2,
            dataPie3: tempPie3,
            dataPie3Value: totalDonut3,
            selectedDate: bardate
          })
        })
      }
    }
  }

  cancelModal = () =>
  {
    this.setState({
      showDeny: !this.state.showDeny,
      comment: "",
    })
  }

  submitDenyModal = () =>
  {
    this.setState({
      showDeny: !this.state.showDeny,
    }, () =>
    {
      if (this.state.statusDeny === "Multi")
      {
        this.multiVisitorDenyUpdate(this.state.visitListDeny, this.state.denyComment);
      } else
      {
        this.singleVisitorDenyUpdate(this.state.visitDeny, this.state.denyComment);
      }
    })
  }

  change = (e: any) =>
  {
    this.setState({ [e.target.name]: e.target.value });
  }

  dateSortInactive = (e: any) =>
  {
    const timer = setTimeout(() =>
    {
      if (e.column === "table_Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.inactiveVisitor, { Visit_Start_DateSort: 'asc' })
        let tempInactiveVisitor: any = this.state.inactiveVisitor;
        for (let i = 0; i < tempInactiveVisitor.length; i++)
        {
          let params = tempInactiveVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempInactiveVisitor[i] = {
            ...tempInactiveVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempInactiveVisitor[i].Visit_Id} id={tempInactiveVisitor[i].Visit_Id} value={tempInactiveVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempInactiveVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          inactiveVisitor: tempInactiveVisitor,
          inactiveStartSort: e.direction,
          inactiveEndSort: ""
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.inactiveVisitor, { Visit_Start_DateSort: 'desc' })
        let tempInactiveVisitor: any = this.state.inactiveVisitor;
        for (let i = 0; i < tempInactiveVisitor.length; i++)
        {
          let params = tempInactiveVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempInactiveVisitor[i] = {
            ...tempInactiveVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempInactiveVisitor[i].Visit_Id} id={tempInactiveVisitor[i].Visit_Id} value={tempInactiveVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempInactiveVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          inactiveVisitor: tempInactiveVisitor,
          inactiveStartSort: e.direction,
          inactiveEndSort: ""
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.inactiveVisitor, { Visit_End_DateSort: 'desc' })
        let tempInactiveVisitor: any = this.state.inactiveVisitor;
        for (let i = 0; i < tempInactiveVisitor.length; i++)
        {
          let params = tempInactiveVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempInactiveVisitor[i] = {
            ...tempInactiveVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempInactiveVisitor[i].Visit_Id} id={tempInactiveVisitor[i].Visit_Id} value={tempInactiveVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempInactiveVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          inactiveVisitor: tempInactiveVisitor,
          inactiveEndSort: e.direction,
          inactiveStartSort: ""
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.inactiveVisitor, { Visit_End_DateSort: 'asc-' })
        let tempInactiveVisitor: any = this.state.inactiveVisitor;
        for (let i = 0; i < tempInactiveVisitor.length; i++)
        {
          let params = tempInactiveVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempInactiveVisitor[i] = {
            ...tempInactiveVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempInactiveVisitor[i].Visit_Id} id={tempInactiveVisitor[i].Visit_Id} value={tempInactiveVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempInactiveVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          inactiveVisitor: tempInactiveVisitor,
          inactiveEndSort: e.direction,
          inactiveStartSort: ""
        })
      }
    }, 500);
  }

  dateSortApproved = (e: any) =>
  {
    this.setState({ sortObjectFutureVisitor: { columnName: e.column, direction: e.direction }, })

    if (e.column !== "table_Visit_Start_Date" && e.column !== "table_Visit_End_Date")
    {
      const newArray = handleSortTableDataDivWrapProperyArray(this.state.futureVisitor, e.column, e.direction)
      this.setState({ futureVisitor: newArray })
    }

    const timer = setTimeout(() =>
    {
      if (e.column === "Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.futureVisitor, { Visit_Start_DateSort: 'asc' })
        let tempApprovedVisitor: any = this.state.futureVisitor;
        for (let i = 0; i < tempApprovedVisitor.length; i++)
        {
          let params = tempApprovedVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempApprovedVisitor[i] = {
            ...tempApprovedVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempApprovedVisitor[i].Visit_Id} id={tempApprovedVisitor[i].Visit_Id} value={tempApprovedVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempApprovedVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          futureVisitor: tempApprovedVisitor,
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.futureVisitor, { Visit_Start_DateSort: 'desc' })
        let tempApprovedVisitor: any = this.state.futureVisitor;
        for (let i = 0; i < tempApprovedVisitor.length; i++)
        {
          let params = tempApprovedVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempApprovedVisitor[i] = {
            ...tempApprovedVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempApprovedVisitor[i].Visit_Id} id={tempApprovedVisitor[i].Visit_Id} value={tempApprovedVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempApprovedVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          futureVisitor: tempApprovedVisitor,
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.futureVisitor, { Visit_End_DateSort: 'desc' })
        let tempApprovedVisitor: any = this.state.futureVisitor;
        for (let i = 0; i < tempApprovedVisitor.length; i++)
        {
          let params = tempApprovedVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempApprovedVisitor[i] = {
            ...tempApprovedVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempApprovedVisitor[i].Visit_Id} id={tempApprovedVisitor[i].Visit_Id} value={tempApprovedVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempApprovedVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          futureVisitor: tempApprovedVisitor,
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.futureVisitor, { Visit_End_DateSort: 'asc-' })
        let tempApprovedVisitor: any = this.state.futureVisitor;
        for (let i = 0; i < tempApprovedVisitor.length; i++)
        {
          let params = tempApprovedVisitor[i];
          let tempData: any = this.state.selectedBoxInactiveVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempApprovedVisitor[i] = {
            ...tempApprovedVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempApprovedVisitor[i].Visit_Id} id={tempApprovedVisitor[i].Visit_Id} value={tempApprovedVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, tempApprovedVisitor[i].Visit_Id)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          futureVisitor: tempApprovedVisitor,
        })
      }
    }, 500);
  }

  dateSortOnsite = (e: any) =>
  {
    this.setState({ sortObjectOnSite: { columnName: e.column, direction: e.direction }, })

    if (e.column !== "table_Visit_Start_Date" && e.column !== "Visit_End_Date")
    {
      const newArray = handleSortTableDataDivWrapProperyArray(this.state.onsiteVisitor, e.column, e.direction)
      this.setState({ onsiteVisitor: newArray })
    }

    const timer = setTimeout(() =>
    {
      if (e.column === "table_Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.onsiteVisitor, { Visit_Start_DateSort: 'asc' })
        let tempOnSiteVisitor: any = this.state.onsiteVisitor;
        for (let i = 0; i < tempOnSiteVisitor.length; i++)
        {
          let params = tempOnSiteVisitor[i];
          let tempData: any = this.state.selectedBoxOnsiteVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempOnSiteVisitor[i] = {
            ...tempOnSiteVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempOnSiteVisitor[i].Visit_Id} id={tempOnSiteVisitor[i].Visit_Id} value={tempOnSiteVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListOnsiteVisitor(e, tempOnSiteVisitor[i].Visit_Id, tempOnSiteVisitor[i].table_Visit_End_Date?.props?.children, tempOnSiteVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          onsiteVisitor: tempOnSiteVisitor,
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.onsiteVisitor, { Visit_Start_DateSort: 'desc' })
        let tempOnSiteVisitor: any = this.state.onsiteVisitor;
        for (let i = 0; i < tempOnSiteVisitor.length; i++)
        {
          let params = tempOnSiteVisitor[i];
          let tempData: any = this.state.selectedBoxOnsiteVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempOnSiteVisitor[i] = {
            ...tempOnSiteVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempOnSiteVisitor[i].Visit_Id} id={tempOnSiteVisitor[i].Visit_Id} value={tempOnSiteVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListOnsiteVisitor(e, tempOnSiteVisitor[i].Visit_Id, tempOnSiteVisitor[i].table_Visit_End_Date?.props?.children, tempOnSiteVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          onsiteVisitor: tempOnSiteVisitor,
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.onsiteVisitor, { Visit_End_DateSort: 'desc' })
        let tempOnSiteVisitor: any = this.state.onsiteVisitor;
        for (let i = 0; i < tempOnSiteVisitor.length; i++)
        {
          let params = tempOnSiteVisitor[i];
          let tempData: any = this.state.selectedBoxOnsiteVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempOnSiteVisitor[i] = {
            ...tempOnSiteVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempOnSiteVisitor[i].Visit_Id} id={tempOnSiteVisitor[i].Visit_Id} value={tempOnSiteVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListOnsiteVisitor(e, tempOnSiteVisitor[i].Visit_Id, tempOnSiteVisitor[i].table_Visit_End_Date?.props?.children, tempOnSiteVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          onsiteVisitor: tempOnSiteVisitor,
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.onsiteVisitor, { Visit_End_DateSort: 'asc-' })
        let tempOnSiteVisitor: any = this.state.onsiteVisitor;
        for (let i = 0; i < tempOnSiteVisitor.length; i++)
        {
          let params = tempOnSiteVisitor[i];
          let tempData: any = this.state.selectedBoxOnsiteVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempOnSiteVisitor[i] = {
            ...tempOnSiteVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempOnSiteVisitor[i].Visit_Id} id={tempOnSiteVisitor[i].Visit_Id} value={tempOnSiteVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListOnsiteVisitor(e, tempOnSiteVisitor[i].Visit_Id, tempOnSiteVisitor[i].table_Visit_End_Date?.props?.children, tempOnSiteVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          onsiteVisitor: tempOnSiteVisitor,
        })
      }
    }, 500);
  }

  dateSortNextWorkingDay = (e: any) =>
  {
    this.setState({ sortObjectNextDay: { columnName: e.column, direction: e.direction }, })

    if (e.column !== "table_Visit_Start_Date" && e.column !== "table_Visit_End_Date")
    {
      const newArray = handleSortTableDataDivWrapProperyArray(this.state.nextWorkingDayVisitor, e.column, e.direction)
      this.setState({ nextWorkingDayVisitor: newArray })
    }

    const timer = setTimeout(() =>
    {
      if (e.column === "table_Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.nextWorkingDayVisitor, { Visit_Start_DateSort: 'asc' })
        let tempNextWorkingDayVisitor: any = this.state.nextWorkingDayVisitor;
        for (let i = 0; i < tempNextWorkingDayVisitor.length; i++)
        {
          let params = tempNextWorkingDayVisitor[i];
          let tempData: any = this.state.selectedBoxNextWorkingDayVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempNextWorkingDayVisitor[i] = {
            ...tempNextWorkingDayVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempNextWorkingDayVisitor[i].Visit_Id} id={tempNextWorkingDayVisitor[i].Visit_Id} value={tempNextWorkingDayVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListNextWorkingDayVisitor(e, tempNextWorkingDayVisitor[i].Visit_Id, tempNextWorkingDayVisitor[i].table_Visit_End_Date?.props?.children, tempNextWorkingDayVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          nextWorkingDayVisitor: tempNextWorkingDayVisitor,
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.nextWorkingDayVisitor, { Visit_Start_DateSort: 'desc' })
        let tempNextWorkingDayVisitor: any = this.state.nextWorkingDayVisitor;
        for (let i = 0; i < tempNextWorkingDayVisitor.length; i++)
        {
          let params = tempNextWorkingDayVisitor[i];
          let tempData: any = this.state.selectedBoxNextWorkingDayVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempNextWorkingDayVisitor[i] = {
            ...tempNextWorkingDayVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempNextWorkingDayVisitor[i].Visit_Id} id={tempNextWorkingDayVisitor[i].Visit_Id} value={tempNextWorkingDayVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListNextWorkingDayVisitor(e, tempNextWorkingDayVisitor[i].Visit_Id, tempNextWorkingDayVisitor[i].table_Visit_End_Date?.props?.children, tempNextWorkingDayVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          nextWorkingDayVisitor: tempNextWorkingDayVisitor,
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.nextWorkingDayVisitor, { Visit_End_DateSort: 'desc' })
        let tempNextWorkingDayVisitor: any = this.state.nextWorkingDayVisitor;
        for (let i = 0; i < tempNextWorkingDayVisitor.length; i++)
        {
          let params = tempNextWorkingDayVisitor[i];
          let tempData: any = this.state.selectedBoxNextWorkingDayVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempNextWorkingDayVisitor[i] = {
            ...tempNextWorkingDayVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempNextWorkingDayVisitor[i].Visit_Id} id={tempNextWorkingDayVisitor[i].Visit_Id} value={tempNextWorkingDayVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListNextWorkingDayVisitor(e, tempNextWorkingDayVisitor[i].Visit_Id, tempNextWorkingDayVisitor[i].table_Visit_End_Date?.props?.children, tempNextWorkingDayVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          nextWorkingDayVisitor: tempNextWorkingDayVisitor,
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.nextWorkingDayVisitor, { Visit_End_DateSort: 'asc-' })
        let tempNextWorkingDayVisitor: any = this.state.nextWorkingDayVisitor;
        for (let i = 0; i < tempNextWorkingDayVisitor.length; i++)
        {
          let params = tempNextWorkingDayVisitor[i];
          let tempData: any = this.state.selectedBoxNextWorkingDayVisitor;
          let index: any = tempData.indexOf(params.Visit_Id)
          tempNextWorkingDayVisitor[i] = {
            ...tempNextWorkingDayVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempNextWorkingDayVisitor[i].Visit_Id} id={tempNextWorkingDayVisitor[i].Visit_Id} value={tempNextWorkingDayVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListNextWorkingDayVisitor(e, tempNextWorkingDayVisitor[i].Visit_Id, tempNextWorkingDayVisitor[i].table_Visit_End_Date?.props?.children, tempNextWorkingDayVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          nextWorkingDayVisitor: tempNextWorkingDayVisitor,
        })
      }
    }, 500);
  }

  dateSortTodays = (e: any) =>
  {
    this.setState({ sortObjectToday: { columnName: e.column, direction: e.direction }, })

    if (e.column !== "table_Visit_Start_Date" && e.column !== "table_Visit_End_Date")
    {
      const newArray = handleSortTableDataDivWrapProperyArray(this.state.todaysVisitor, e.column, e.direction)
      this.setState({ todaysVisitor: newArray })
    }

    const timer = setTimeout(() =>
    {
      if (e.column === "table_Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.todaysVisitor, { Visit_Start_DateSort: 'asc' })
        let tempTodaysVisitor: any = this.state.todaysVisitor;
        for (let i = 0; i < tempTodaysVisitor.length; i++)
        {
          let params = tempTodaysVisitor[i];
          let tempData: any = this.state.selectedBoxTodaysVisitor;

          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }

          tempTodaysVisitor[i] = {
            ...tempTodaysVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempTodaysVisitor[i].Visit_Id} id={tempTodaysVisitor[i].Visit_Id} value={tempTodaysVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListTodaysVisitor(e, tempTodaysVisitor[i].Visit_Id, tempTodaysVisitor[i].table_Visit_End_Date, tempTodaysVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          todaysVisitor: tempTodaysVisitor,
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.todaysVisitor, { Visit_Start_DateSort: 'desc' })
        let tempTodaysVisitor: any = this.state.todaysVisitor;
        for (let i = 0; i < tempTodaysVisitor.length; i++)
        {
          let params = tempTodaysVisitor[i];
          let tempData: any = this.state.selectedBoxTodaysVisitor;

          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }

          tempTodaysVisitor[i] = {
            ...tempTodaysVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempTodaysVisitor[i].Visit_Id} id={tempTodaysVisitor[i].Visit_Id} value={tempTodaysVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListTodaysVisitor(e, tempTodaysVisitor[i].Visit_Id, tempTodaysVisitor[i].table_Visit_End_Date, tempTodaysVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          todaysVisitor: tempTodaysVisitor,
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.todaysVisitor, { Visit_End_DateSort: 'desc' })
        let tempTodaysVisitor: any = this.state.todaysVisitor;
        for (let i = 0; i < tempTodaysVisitor.length; i++)
        {
          let params = tempTodaysVisitor[i];
          let tempData: any = this.state.selectedBoxTodaysVisitor;

          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }

          tempTodaysVisitor[i] = {
            ...tempTodaysVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempTodaysVisitor[i].Visit_Id} id={tempTodaysVisitor[i].Visit_Id} value={tempTodaysVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListTodaysVisitor(e, tempTodaysVisitor[i].Visit_Id, tempTodaysVisitor[i].table_Visit_End_Date, tempTodaysVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          todaysVisitor: tempTodaysVisitor,
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.todaysVisitor, { Visit_End_DateSort: 'asc-' })
        let tempTodaysVisitor: any = this.state.todaysVisitor;
        for (let i = 0; i < tempTodaysVisitor.length; i++)
        {
          let params = tempTodaysVisitor[i];
          let tempData: any = this.state.selectedBoxTodaysVisitor;
          // let index: any = tempData.indexOf(params.Visit_Id)

          let index: any = -1;

          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }

          tempTodaysVisitor[i] = {
            ...tempTodaysVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempTodaysVisitor[i].Visit_Id} id={tempTodaysVisitor[i].Visit_Id} value={tempTodaysVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListTodaysVisitor(e, tempTodaysVisitor[i].Visit_Id, tempTodaysVisitor[i].table_Visit_End_Date, tempTodaysVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          todaysVisitor: tempTodaysVisitor,
        })
      }
    }, 500);
  }

  dateSortAwaitingApproval = (e: any) =>
  {
    this.setState({ sortObjectAwaitingApproval: { columnName: e.column, direction: e.direction }, })

    if (e.column !== "table_Visit_Start_Date" && e.column !== "table_Visit_End_Date")
    {
      const newArray = handleSortTableDataDivWrapProperyArray(this.state.awaitingApprovalVisitor, e.column, e.direction)
      this.setState({ awaitingApprovalVisitor: newArray })
    }

    const timer = setTimeout(() =>
    {
      if (e.column === "table_Visit_Start_Date" && e.direction === 'asc')
      {
        multiSort(this.state.awaitingApprovalVisitor, { Visit_Start_DateSort: 'asc' })
        let tempAwaitingApprovalVisitor: any = this.state.awaitingApprovalVisitor;
        for (let i = 0; i < tempAwaitingApprovalVisitor.length; i++)
        {
          let params = tempAwaitingApprovalVisitor[i];
          let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempAwaitingApprovalVisitor[i] = {
            ...tempAwaitingApprovalVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempAwaitingApprovalVisitor[i].Visit_Id} id={tempAwaitingApprovalVisitor[i].Visit_Id} value={tempAwaitingApprovalVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListAwaitingApprovalVisitor(e, tempAwaitingApprovalVisitor[i].Visit_Id, tempAwaitingApprovalVisitor[i].table_Visit_End_Date?.props?.children, tempAwaitingApprovalVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          awaitingApprovalVisitor: tempAwaitingApprovalVisitor,
        })
      }
      if (e.column === "table_Visit_Start_Date" && e.direction === 'desc')
      {
        multiSort(this.state.awaitingApprovalVisitor, { Visit_Start_DateSort: 'desc' })
        let tempAwaitingApprovalVisitor: any = this.state.awaitingApprovalVisitor;
        for (let i = 0; i < tempAwaitingApprovalVisitor.length; i++)
        {
          let params = tempAwaitingApprovalVisitor[i];
          let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempAwaitingApprovalVisitor[i] = {
            ...tempAwaitingApprovalVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempAwaitingApprovalVisitor[i].Visit_Id} id={tempAwaitingApprovalVisitor[i].Visit_Id} value={tempAwaitingApprovalVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListAwaitingApprovalVisitor(e, tempAwaitingApprovalVisitor[i].Visit_Id, tempAwaitingApprovalVisitor[i].table_Visit_End_Date?.props?.children, tempAwaitingApprovalVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          awaitingApprovalVisitor: tempAwaitingApprovalVisitor,
        })
      }

      if (e.column === "table_Visit_End_Date" && e.direction === 'asc')
      {
        multiSort(this.state.awaitingApprovalVisitor, { Visit_End_DateSort: 'desc' })
        let tempAwaitingApprovalVisitor: any = this.state.awaitingApprovalVisitor;
        for (let i = 0; i < tempAwaitingApprovalVisitor.length; i++)
        {
          let params = tempAwaitingApprovalVisitor[i];
          let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempAwaitingApprovalVisitor[i] = {
            ...tempAwaitingApprovalVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempAwaitingApprovalVisitor[i].Visit_Id} id={tempAwaitingApprovalVisitor[i].Visit_Id} value={tempAwaitingApprovalVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListAwaitingApprovalVisitor(e, tempAwaitingApprovalVisitor[i].Visit_Id, tempAwaitingApprovalVisitor[i].table_Visit_End_Date?.props?.children, tempAwaitingApprovalVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          awaitingApprovalVisitor: tempAwaitingApprovalVisitor,
        })
      }
      if (e.column === "table_Visit_End_Date" && e.direction === 'desc')
      {
        multiSort(this.state.awaitingApprovalVisitor, { Visit_End_DateSort: 'asc-' })
        let tempAwaitingApprovalVisitor: any = this.state.awaitingApprovalVisitor;
        for (let i = 0; i < tempAwaitingApprovalVisitor.length; i++)
        {
          let params = tempAwaitingApprovalVisitor[i];
          let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
          let index: any = -1;
          for (let j = 0; j < tempData.length; j++)
          {
            if (tempData[j].visitid === params.Visit_Id)
            {
              index = j;
            }
          }
          tempAwaitingApprovalVisitor[i] = {
            ...tempAwaitingApprovalVisitor[i],
            'active': <label className="newCheckBox">
              <input type="checkbox" className="checkBoxHide" name={tempAwaitingApprovalVisitor[i].Visit_Id} id={tempAwaitingApprovalVisitor[i].Visit_Id} value={tempAwaitingApprovalVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListAwaitingApprovalVisitor(e, tempAwaitingApprovalVisitor[i].Visit_Id, tempAwaitingApprovalVisitor[i].table_Visit_End_Date?.props?.children, tempAwaitingApprovalVisitor[i].Visit_Status)} />
              <span className="geekmark"></span>
            </label>
          }
        }
        this.setState({
          awaitingApprovalVisitor: tempAwaitingApprovalVisitor,
        })
      }
    }, 500);
  }

  excelExportButtonStyle = (table: any) =>
  {
    let styleClass = "button-primary-disable mr-3 e-control e-btn e-lib"
    if (table && table.rows && table.rows[0])
    {
      styleClass = "button-primary e-control mr-3 e-btn e-lib"
    }
    return styleClass
  }

  handleFilterButton = () =>
  {
    this.setState({
      isFilter: !this.state.isFilter,
      filterMode: true,
    })
  }

  cancelFilterModal = () =>
  {
    this.setState({
      isFilter: false,
      // selectedHostObject: { label: "", email: "" }
    })
  }

  handleComponentMountGetFilterVisitor = async () =>
  {
    const filteredVisitorsObj: any = {
      buildingId: this.state.buildingid,
      startDate: "",
      endDate: "",
      statusValue: "",
      host: "",
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value: any, key) =>
    {
      filteredVisitorsObj[key] = value
    })

    if (!filteredVisitorsObj.startDate || !filteredVisitorsObj.endDate)
    {
      filteredVisitorsObj.startDate = DateHelper.now().toJSDate().toISOString();
      filteredVisitorsObj.endDate = DateTime.fromISO(DateHelper.now().toISO()).plus({ day: 7 }).endOf('day').toUTC().toISO();
    }

    await this.handleGetFilteredVisitors(filteredVisitorsObj)
    this.cancelFilterModal()

  }

  handleFilterModalOkButton = async () =>
  {
    const filteredVisitorsObj = {
      buildingId: this.state.buildingid,
      startDate: this.state.Start_Date_For_filter_modal.toISOString(),
      endDate: this.state.End_Date_For_filter_modal.toISOString(),
      statusValue: this.state.value,
      host: this.state.selectedHostObject.email,
    }
    await this.handleGetFilteredVisitors(filteredVisitorsObj)
    this.cancelFilterModal()

    return;
  }

  handleGetFilteredVisitors = async (filteredVisitorsObj: any) =>
  {
    const { match }: any = this.props;
    this.setState({ isLoadingToGetFilteredData: true })

    try
    {
      let response: any = [];
      if (match.params.filter && match.params.filter === "todaysvisitors" && !this.state.filterMode)
      {
        response = await apis.getTodaysSpaceVisitors(this.state.buildingid)
      } else if (match.params.filter && match.params.filter === "visitorinbuilding" && !this.state.filterMode)
      {
        response = await apis.getVisitorInBuilding(this.state.buildingid)
      } else if (match.params.filter && match.params.filter === "visitorscheckout" && !this.state.filterMode)
      {
        response = await apis.getVisitorsCheckout(this.state.buildingid)
      } else
      {
        response = await apis.getFilteredVisitors(filteredVisitorsObj)
      }

      let newArray = response.data && response.data[0] && response.data.map((item: any) =>
      {
        let newItem = { ...item }
        const { Visitor_Email, Visitor_First_Name, Visitor_Last_Name, Visitor_Company, Visit_Host_Name, Visit_Id, Visit_Status } = item
        newItem.tableSearch = `${Visitor_Email}, ${Visitor_First_Name} ${Visitor_Last_Name}, ${Visitor_Company}, ${Visit_Host_Name}, ${Visit_Id}, ${Visit_Status}`
        let startDate = newItem.Visit_Start_Date
        let endDate = newItem.Visit_End_Date
        newItem.Visitor_Full_Name = `${newItem.Visitor_First_Name} ${newItem.Visitor_Last_Name}`;
        newItem.Visit_Start_DateSort = DateHelper.fromIsoToDefaultFormat(startDate, 'yy-MM-dd HH:mm');
        newItem.Visit_End_DateSort = DateHelper.fromIsoToDefaultFormat(endDate, 'yy-MM-dd HH:mm');

        newItem.table_Visit_Start_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(startDate, 'dd/MM/yyyy HH:mm')}</div>
        newItem.table_Visit_End_Date = <div onClick={() => this.updateVisitDetail(item)}>{DateHelper.fromIsoToDefaultFormat(endDate, 'dd/MM/yyyy HH:mm')}</div>

        newItem.table_Visitor_Email = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Email}</div>
        newItem.table_Visitor_Full_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Full_Name}</div>
        newItem.table_Visitor_Company = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visitor_Company}</div>
        newItem.table_Visit_Host_Name = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Host_Name}</div>
        newItem.table_visitId = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Id}</div>
        newItem.table_Visit_Status = <div onClick={() => this.updateVisitDetail(item)}>{newItem.Visit_Status}</div>
        return newItem
      })
      this.setState({ customFilterVisitor: (newArray && newArray[0]) ? newArray : [] });

    }
    catch (error: any)
    {
    }

    this.setState({ isLoadingToGetFilteredData: false })
    return
  }

  handleChangeCustomFilterStartDate = (e: any) =>
  {
    this.setState({
      Start_Date_For_filter_modal: e,
    })
  }

  handleChangeCustomFilterEndDate = (e: any) =>
  {
    const maxEndDate = DateTime.fromISO((this.state.Start_Date_For_filter_modal).toISOString()).plus({ day: 7 }).toJSDate();
    const maxEndDateIso = maxEndDate.toISOString()
    const endDateIso = e.toISOString()

    if (endDateIso > maxEndDateIso)
    {
      e = maxEndDate
    }
    this.setState({
      End_Date_For_filter_modal: e,
    })

  }

  handleSelectChange = (event: any) =>
  {
    this.setState({ value: event.target.value });
  };

  changeTitle = (e: any) =>
  {
    this.setState({ [e.target.name]: e.target.value, updates: true });
    if (e.target.value.length >= 3)
    {
      this.apiClient.users.getUsers(e.target.value).then((res) =>
      {
        const newArray = res && res[0] && res.map(item =>
        {
          let newItem = { ...item, label: '' };
          newItem.label = item.displayName ? item.displayName : item.email;
          newItem.displayName = item.displayName ? item.displayName : item.email;
          newItem.lastName = newItem.lastName ? newItem.lastName : ""
          newItem.firstName = newItem.firstName ? newItem.firstName : ""

          return newItem
        })

        this.setState({
          userList: (newArray && newArray[0]) ? newArray : [] // useing only label & email
        })
      }).catch((error: any) =>
      {

      });
    } else
    {
      this.setState({
        userList: []
      })
    }
  }

  handleSelectUser = (e: any, newValue: any) =>
  {
    if (newValue)
    {
      this.setState({ selectedHostObject: { label: newValue.label, email: newValue.email } })
    } else
    {
      this.setState({ selectedHostObject: { label: "", email: "" } })
    }
  }

  inputChangeEmailName = (e: any) =>
  {
    // console.log("inputChangeEmailName")
  }

  handleFilteredVisitorDataSort = (e: any) =>
  {
    this.setState({ sortObjectFilteredVisitor: { columnName: e.column, direction: e.direction }, })
    const newArray = handleSortTableDataDivWrapProperyArray(this.state.customFilterVisitor, e.column, e.direction)
    this.setState({ customFilterVisitor: newArray })
  }

  handleImportVisitor = () =>
  {
    this.setState({
      isFileUpload: !this.state.isFileUpload,
    })
  }

  handleUpload = (e: any) =>
  {
    // let f: any = e.target.files[0]
    this.setState({
      file: e.target.files[0]
    })
  }

  handleUploadButton = () =>
  {
    this.setState({ fileImporting: true });
    this.handleImportVisitor()
    const { match }: any = this.props;
    const formData = new FormData();
    let file: any = this.state.file ? this.state.file : "";
    formData.append('file', file);
    formData.append('fileName', file.name);

    apis.visitorImportFile(match.params.buildingid, formData)
      .then((res: any) =>
      {
        if (res.status === 200)
        {
          this.alert.show("", this.labels.HubLabelFileuploadedsuccessfully);
        }
        else
        {
          this.alert.show("", this.apiMessageBuilder.getMessage(res.statusText, match.params.buildingid));
        }
      })
      .catch((error: any) => { })
      .finally(() =>
      {
        this.setState({ fileImporting: false });
      });
  }


  render()
  {
    const { match, history }: any = this.props;
    let { awaitingApprovalVisitor, todaysVisitor, nextWorkingDayVisitor, onsiteVisitor,
      futureVisitor, inactiveVisitor, customFilterVisitor, value }: any = this.state;

    const props = {
      ...this.props,
      submenu: [
        {
          title: this.labels.HubTabOverview,
          tabNo: 0,
          active: true,
        },
        {
          title: this.labels.HubTabAwaitingApproval,
          tabNo: 1,
          active: this.state.activePage === 1 ? true : false,
          display: 'block'
        },
        {
          title: this.labels.HubTabToday,
          tabNo: 2,
          active: this.state.activePage === 2 ? true : false,
          display: 'block'
        },
        {
          title: this.labels.HubTabNextWorkingDay,
          tabNo: 3,
          active: this.state.activePage === 3 ? true : false,
          display: 'block'
        },
        {
          title: this.labels.HubTabOnSite,
          tabNo: 4,
          active: this.state.activePage === 4 ? true : false,
          display: 'block'
        },
        {
          title: this.labels.HubTabFutureVisitors,
          tabNo: 5,
          active: this.state.activePage === 5 ? true : false,
          display: 'block'
        },
        {
          title: this.labels.HubTabCustomFilter,
          tabNo: 6,
          active: this.state.activePage === 6 ? true : false,
          display: 'block'
        },
        // {
        //   title: this.labels.HubLabelInActive,
        //   tabNo: 6,
        //   active: this.state.activePage === 6 ? true : false,
        //   display: 'block'
        // },
      ],
      activePageValue: this.state.activePage,
      childValue: (i: any) =>
      {
        if (match.params.filter && i !== 6)
        {
          history.push("/operational-services-visitors-trends/" + this.state.buildingid + "/" + i)
        } else
        {
          this.setState({
            activePage: i,
            isApprove: false,
            isDeny: false,
            isCancel: false,
            isCheckIn: false,
            isCheckOut: false,
            selectedBoxAwaitingApprovalVisitor: [{ visitid: "", status: "" }],
            selectedBoxTodaysVisitor: [{ visitid: "", status: "" }],
            selectedBoxNextWorkingDayVisitor: { visitid: "", status: "" },
            selectedBoxOnsiteVisitor: [{ visitid: "", status: "" }],
            selectedBoxApprovedVisitor: [{ visitid: "", status: "" }],
          });
        }

      },
    };

    for (let i = 0; i < awaitingApprovalVisitor.length; i++)
    {
      let params = awaitingApprovalVisitor[i];
      let tempData: any = this.state.selectedBoxAwaitingApprovalVisitor;
      let index: any = -1;//tempData.indexOf(params.Visit_Id)
      for (let j = 0; j < tempData.length; j++)
      {
        if (tempData[j].visitid === params.Visit_Id)
        {
          index = j;
        }
      }
      awaitingApprovalVisitor[i] = {
        ...awaitingApprovalVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={awaitingApprovalVisitor[i].Visit_Id} id={awaitingApprovalVisitor[i].Visit_Id} value={awaitingApprovalVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListAwaitingApprovalVisitor(e, awaitingApprovalVisitor[i].Visit_Id, awaitingApprovalVisitor[i].table_Visit_End_Date?.props?.children, awaitingApprovalVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    for (let i = 0; i < todaysVisitor.length; i++)
    {
      let params = todaysVisitor[i];
      let tempData: any = this.state.selectedBoxTodaysVisitor;
      let index: any = -1;//tempData.indexOf(params.Visit_Id)
      for (let j = 0; j < tempData.length; j++)
      {
        if (tempData[j].visitid === params.Visit_Id)
        {
          index = j;
        }
      }
      todaysVisitor[i] = {
        ...todaysVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={todaysVisitor[i].Visit_Id} id={todaysVisitor[i].Visit_Id} value={todaysVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListTodaysVisitor(e, todaysVisitor[i].Visit_Id, todaysVisitor[i].table_Visit_End_Date?.props?.children, todaysVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    for (let i = 0; i < nextWorkingDayVisitor.length; i++)
    {
      nextWorkingDayVisitor[i] = {
        ...nextWorkingDayVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={nextWorkingDayVisitor[i].Visit_Id} id={nextWorkingDayVisitor[i].Visit_Id} value={nextWorkingDayVisitor[i].Visit_Id} checked={this.state.selectedBoxNextWorkingDayVisitor.visitid === nextWorkingDayVisitor[i].Visit_Id} onClick={(e: any) => this.checkBoxListNextWorkingDayVisitor(e, nextWorkingDayVisitor[i].Visit_Id, nextWorkingDayVisitor[i].table_Visit_End_Date?.props?.children, nextWorkingDayVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    for (let i = 0; i < onsiteVisitor.length; i++)
    {
      let params = onsiteVisitor[i];
      let tempData: any = this.state.selectedBoxOnsiteVisitor;
      let index: any = -1;//tempData.indexOf(params.Visit_Id)
      for (let j = 0; j < tempData.length; j++)
      {
        if (tempData[j].visitid === params.Visit_Id)
        {
          index = j;
        }
      }
      onsiteVisitor[i] = {
        ...onsiteVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={onsiteVisitor[i].Visit_Id} id={onsiteVisitor[i].Visit_Id} value={onsiteVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListOnsiteVisitor(e, onsiteVisitor[i].Visit_Id, onsiteVisitor[i].table_Visit_End_Date?.props?.children, onsiteVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    for (let i = 0; i < futureVisitor.length; i++)
    {
      let params = futureVisitor[i];
      let tempData: any = this.state.selectedBoxApprovedVisitor;
      let index: any = -1;
      for (let j = 0; j < tempData.length; j++)
      {
        if (tempData[j].visitid === params.Visit_Id)
        {
          index = j;
        }
      }
      futureVisitor[i] = {
        ...futureVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={futureVisitor[i].Visit_Id} id={futureVisitor[i].Visit_Id} value={futureVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListApprovedVisitor(e, futureVisitor[i].Visit_Id, futureVisitor[i].table_Visit_End_Date?.props?.children, futureVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }

    for (let i = 0; i < inactiveVisitor.length; i++)
    {
      let params = inactiveVisitor[i];
      let tempData: any = this.state.selectedBoxInactiveVisitor;
      let index: any = tempData.indexOf(params.Visit_Id)
      inactiveVisitor[i] = {
        ...inactiveVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={inactiveVisitor[i].Visit_Id} id={inactiveVisitor[i].Visit_Id} value={inactiveVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListInactiveVisitor(e, inactiveVisitor[i].Visit_Id)} />
          <span className="geekmark"></span>
        </label>
      }
    }


    for (let i = 0; i < customFilterVisitor.length; i++)
    {
      let params = customFilterVisitor[i];
      let tempData: any = this.state.selectedBoxCustomFilterVisitor;
      let index: any = -1;
      for (let j = 0; j < tempData.length; j++)
      {
        if (tempData[j].visitid === params.Visit_Id)
        {
          index = j;
        }
      }
      customFilterVisitor[i] = {
        ...customFilterVisitor[i],
        'active': <label className="newCheckBox">
          <input type="checkbox" className="checkBoxHide" name={customFilterVisitor[i].Visit_Id} id={customFilterVisitor[i].Visit_Id} value={customFilterVisitor[i].Visit_Id} checked={index > -1} onClick={(e: any) => this.checkBoxListCustomFilterVisitor(e, customFilterVisitor[i].Visit_Id, customFilterVisitor[i].table_Visit_End_Date?.props?.children, customFilterVisitor[i].Visit_Status)} />
          <span className="geekmark"></span>
        </label>
      }
    }


    const dataAwaitingApprovalVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visit_Start_Date" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visit_End_Date" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visitor_Email" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visitor_Company" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visit_Host_Name" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_visitId" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectAwaitingApproval.columnName === "table_Visit_Status" ? this.state.sortObjectAwaitingApproval.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.awaitingApprovalVisitor
    };
    const dataTodaysVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectToday.columnName === "table_Visit_Start_Date" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectToday.columnName === "table_Visit_End_Date" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectToday.columnName === "table_Visitor_Email" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectToday.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectToday.columnName === "table_Visitor_Company" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectToday.columnName === "table_Visit_Host_Name" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectToday.columnName === "table_visitId" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectToday.columnName === "table_Visit_Status" ? this.state.sortObjectToday.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.todaysVisitor
    }

    const dataNextWorkingDayVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectNextDay.columnName === "table_Visit_Start_Date" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectNextDay.columnName === "table_Visit_End_Date" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectNextDay.columnName === "table_Visitor_Email" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectNextDay.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectNextDay.columnName === "table_Visitor_Company" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectNextDay.columnName === "table_Visit_Host_Name" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectNextDay.columnName === "table_visitId" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectNextDay.columnName === "table_Visit_Status" ? this.state.sortObjectNextDay.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.nextWorkingDayVisitor
    }
    const dataOnsiteVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectOnSite.columnName === "table_Visit_Start_Date" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectOnSite.columnName === "table_Visit_End_Date" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectOnSite.columnName === "table_Visitor_Email" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectOnSite.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectOnSite.columnName === "table_Visitor_Company" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectOnSite.columnName === "table_Visit_Host_Name" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectOnSite.columnName === "table_visitId" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectOnSite.columnName === "table_Visit_Status" ? this.state.sortObjectOnSite.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.onsiteVisitor
    }
    const dataApprovedVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visit_Start_Date" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visit_End_Date" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visitor_Email" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visitor_Company" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visit_Host_Name" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_visitId" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectFutureVisitor.columnName === "table_Visit_Status" ? this.state.sortObjectFutureVisitor.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.futureVisitor
    }

    const dataCustomFilterVisitor = {
      columns: [
        {
          label: '',
          field: "active",
          sort: "disabled",
        },
        {
          label: this.labels.HubLabelVisitStart,
          field: "table_Visit_Start_Date",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visit_Start_Date" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitEnd,
          field: "table_Visit_End_Date",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visit_End_Date" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitorEmail,
          field: "table_Visitor_Email",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visitor_Email" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelvisitor,
          field: "table_Visitor_Full_Name",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visitor_Full_Name" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelCompany,
          field: "table_Visitor_Company",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visitor_Company" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelHostName,
          field: "table_Visit_Host_Name",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visit_Host_Name" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelVisitID,
          field: "table_visitId",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_visitId" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: this.labels.HubLabelStatus,
          field: "table_Visit_Status",
          sort: this.state.sortObjectFilteredVisitor.columnName === "table_Visit_Status" ? this.state.sortObjectFilteredVisitor.direction : "",
          width: 140
        },
        {
          label: "",
          field: "tableSearch",
          sort: "disabled",
        }
      ],
      rows: this.state.customFilterVisitor ? this.state.customFilterVisitor : []
    }
    return (
      <>
        <div>
          {(this.state.getRightApiResponseErrorLoading || this.state.getDetailsApiResponseErrorLoading || this.state.isLoadingToGetFilteredData || this.state.fileImporting) && <Spinner />}
          {this.state.activePage === 0 && (
            <div className="rightPanel-main-content-chart">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelVisitorsOverview}
                    iconSrc="/images/Sidebar_Icons/Light_theme/Visitor Management.svg"
                  />
                  <div className="topchart">
                    {this.state.dataGraph.length !== 0 &&
                      <BarChart data={this.state.dataGraph} onClick={this.onBarClick} height={400} width={this.state.widthchart} >
                        <CartesianGrid vertical={false} />
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" label={{ value: this.labels.HubLabelVisitorLevel, angle: -90, position: 'insideLeft', fill: 'var(--ui-text)' }} />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        <Legend />
                        <Bar dataKey="Denied" name={this.labels.HubLabelDeniedLabel} stackId="a" fill="#9B59B6" />
                        <Bar dataKey="NeverApproved" name={this.labels.HubLabelNeverApproved} stackId="a" fill="#F44336" />
                        <Bar dataKey="AwaitingApproval" name={this.labels.HubTabAwaitingApproval} stackId="a" fill="#FF7913" />
                        <Bar dataKey="Approved" name={this.labels.HubLabelApproved} stackId="a" fill="#2962FF" />
                      </BarChart>
                    }
                  </div>
                </div>

                <div className="chart-panel-section2">
                  <div className="donutchart">
                    <h3 className="donutcharttitle">{this.labels.HubLabelVisitApprovalStatus}{this.state.selectedDate}</h3>
                    {(this.state.dataPie1.length !== 0 && this.state.dataPie1Value !== 0) ?
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPie1} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPie1.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORS1[index % this.state.COLORS1.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value={this.state.dataPie1Value} position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie1.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie1.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS1[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.value}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                      :
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPieEmpty.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie1.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie1.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS1[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>0</td>
                                    <td>0%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="donutchart donutchartmargin">
                    <h3 className="donutcharttitle">{this.labels.HubLabelApprovedVisits}{this.state.selectedDate}</h3>
                    {(this.state.dataPie2.length !== 0 && this.state.dataPie2Value !== 0) ?
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPie2} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPie2.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORS2[index % this.state.COLORS2.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value={this.state.dataPie2Value} position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie2.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie2.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS2[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.value}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                      :
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPieEmpty.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie2.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie2.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS2[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>0</td>
                                    <td>0%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="donutchart donutchartmargin">
                    <h3 className="donutcharttitle">{this.labels.HubLabelCheckinCheckoutStatusLabel}{this.state.selectedDate}</h3>
                    {(this.state.dataPie3.length !== 0 && this.state.dataPie3Value !== 0) ?
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPie3} cx={80} cy={70} innerRadius="40" outerRadius="65" dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPie3.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORS3[index % this.state.COLORS3.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value={this.state.dataPie3Value} position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie3.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie3.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS3[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>{item.count}</td>
                                    <td>{item.value}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                      :
                      <div className="div-flex">
                        <div className="col-6">
                          <PieChart width={250} height={200}>
                            <Pie stroke="none" data={this.state.dataPieEmpty} cx={80} cy={70} innerRadius="40" outerRadius="65" paddingAngle={0} dataKey="value" startAngle={180} endAngle={-360}>
                              {this.state.dataPieEmpty.map((entry: any, index: any) => (
                                <Cell key={`cell-${index}`} fill={this.state.COLORSEMPTY[index % this.state.COLORSEMPTY.length]} />
                              ))}
                              <Label stroke="var(--ui-text)" value="0" position="center" fontSize="35" fontWeight="bolder" />
                            </Pie>
                          </PieChart>
                        </div>
                        <div className="donutchartlegend">
                          {this.state.dataPie3.length !== 0 &&
                            <table className="col-12 charttable">
                              <tbody>
                                {this.state.dataPie3.map((item: any, i: any) => (
                                  <tr key={i}>
                                    <td><div className="statusbox" style={{ backgroundColor: this.state.COLORS3[i] }}></div></td>
                                    <td>{item.name}</td>
                                    <td>0</td>
                                    <td>0%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) =>
{
  return {
    currentPageTitle: state.currentPageTitle,
    lightModeTheme: state.lightModeTheme,
    identityProvidersStore: state.identityProviders,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilityVisitor);
