import { PaletteType } from "@material-ui/core";
import { ThemeOptions } from '@mui/material/styles';
// create a custom MUI theme by mapping Ibss brand's designs in terms of colours etc to MUI's colour palette

type IbssColorsType = {
  uiPrimary: string,
  uiSecondary: string,
  uiText: string,
  uiError: string,
  uiSuccess: string,
  uiWarn: string,
  uiAlternateBackground: string,
  uiBackGround: string,
  uiMidTone: string,
  uiLightText: string,
  uiTextAlternate: string,
  uiErrorPastel: string,
  uiWarnPastel: string,
  uiSuccessPastel: string,
  uiPrimaryPastel: string,
  uiCore: string,
}

export default class customTheme {

    static lightTheme: IbssColorsType = {
      uiPrimary: '#4893CF', // --ui-primary
      uiSecondary: '#2A3F49', // --ui-secondary
      uiText: '#263238',
      uiError: '#F44336',
      uiSuccess: '#00C853',
      uiWarn: '#FFC400',
      uiAlternateBackground: '#FFFFFF',
      uiBackGround: '#F2F3F4',
      uiMidTone: '#DCE1E5',
      uiLightText: '#FFFFFF',
      uiTextAlternate: '#7A7B82',
      uiErrorPastel: '#FFBBBF',
      uiWarnPastel: '#FFF3CC',
      uiSuccessPastel: '#CCF4DD',
      uiPrimaryPastel: '#DAE9F5',
      uiCore: '#F0EEE9',
    }
    
    static darkTheme: IbssColorsType = {
      uiPrimary: '#2D9CFC', // --ui-primary
      uiSecondary: '#181A1E', // --ui-secondary
      uiText: '#FFFFFF',
      uiError: '#FF5660',
      uiSuccess: '#39D67A',
      uiWarn: '#FFDD6A',
      uiAlternateBackground: '#26292E',
      uiBackGround: '#1E2025',
      uiMidTone: '#393D44', 
      uiLightText: '#FFFFFF',
      uiTextAlternate: '#7E8183',
      uiErrorPastel: '#FFBBBF',
      uiWarnPastel: '#FFF3CC',
      uiSuccessPastel: '#CCF4DD',
      uiPrimaryPastel: '#DAE9F5',
      uiCore: '#3F4042'
    }
     
    public static getDesignTokens = (mode: PaletteType): ThemeOptions => ({
      palette: {
        mode,
        ...(mode === 'light'
          ? {
              // palette values for light mode
              text: {
                primary: customTheme.lightTheme.uiText,
                secondary: customTheme.lightTheme.uiTextAlternate
              },
              primary: {
                main: customTheme.lightTheme.uiPrimary,
                // dark: will be calculated from palette.primary.main,
                light: customTheme.lightTheme.uiPrimaryPastel,
                contrastText: customTheme.lightTheme.uiLightText,
              },
              secondary: {
                main: customTheme.lightTheme.uiSecondary,
                contrastText: customTheme.lightTheme.uiLightText,
              },
              grey: {
                400: customTheme.lightTheme.uiMidTone,
              },
              error: {
                  main: customTheme.lightTheme.uiError,
                  light: customTheme.lightTheme.uiErrorPastel
              },
              success: {
                  main: customTheme.lightTheme.uiSuccess,
                  light: customTheme.lightTheme.uiSuccessPastel
              },
              warning: {
                  main: customTheme.lightTheme.uiWarn,
                  light: customTheme.lightTheme.uiWarnPastel
              },
              background: {
                default: customTheme.lightTheme.uiBackGround,
                paper: customTheme.lightTheme.uiAlternateBackground
              },
              common: {
                uiCore: customTheme.lightTheme.uiCore
              },
            }
          : {
              // palette values for dark mode
              text: {
                primary: customTheme.darkTheme.uiText,
                secondary: customTheme.darkTheme.uiTextAlternate
              },
              primary: {
                main: customTheme.darkTheme.uiPrimary,
                // dark: will be calculated from palette.primary.main,
                light: customTheme.darkTheme.uiPrimaryPastel,
                contrastText: customTheme.darkTheme.uiLightText,
              },
              secondary: {
                main: customTheme.darkTheme.uiSecondary,
                contrastText: customTheme.darkTheme.uiLightText,
              },
              grey: {
                400: customTheme.darkTheme.uiMidTone,
              },
              error: {
                  main: customTheme.darkTheme.uiError,
                  light: customTheme.darkTheme.uiErrorPastel
              },
              success: {
                  main: customTheme.darkTheme.uiSuccess,
                  light: customTheme.darkTheme.uiSuccessPastel
              },
              warning: {
                  main: customTheme.darkTheme.uiWarn,
                  light: customTheme.darkTheme.uiWarnPastel
              },
              background: {
                default: customTheme.darkTheme.uiBackGround,
                paper: customTheme.darkTheme.uiAlternateBackground
              },
              common: {
                uiCore: customTheme.darkTheme.uiCore
              },
            }),
      },
    });
  }