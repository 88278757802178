import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";
import { Task } from "./TaskRepository";

export class UpdateTaskEndpoint implements IUpdateTaskEndpoint
{
    public async execute(nodeId: number, taskId: number, payload: Task): Promise<string>
    {
        try
        {
            const response = await axios.put(apis.allApi + nodeId + '/Tasks/' + taskId, payload);
            return response.data.Message;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateTaskEndpoint
{
    execute(nodeId: number, taskId: number, payload: Task): Promise<string>;
}
