import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class CreateTaskEndpoint implements ICreateTaskEndpoint
{
    public async execute(nodeId: number, payload: ICreateTask): Promise<string>
    {
        try
        {
            const response = await axios.post(apis.allApi + nodeId + '/Tasks', payload);
            console.log(response)
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface ICreateTaskEndpoint
{
    execute(nodeId: number, payload: ICreateTask): Promise<string>;
}

export interface ICreateTask
{
    Space_Id: string;
    Task_Category_Id: number;
    Task_Type_Id: number;
    Task_Description: string;
    Task_Priority: number;
    Equipment_Id: string;
    Booking_Id: string;
    Task_Cost_Code: string;
    Booking_Start: string;
    Is_Catering_Request: boolean;
    Catering_Service_Time: string;
    Catering_Clearing_Time: string;
    Catering_Attendees: number;
    Is_Catering_Clear: boolean;
    Catering_Notes: string;
    DisableExtUpdate: false;
    Cost_Code_Allocation: ICostCodeAllocation[];
}

export interface ICostCodeAllocation
{
    Cost_Code: string;
    Cost_Code_Id: string;
    Allocation: number;
}
