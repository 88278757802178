import { Grid } from '@mui/material';
import { Component } from 'react'
import { getFloorNameUsingFloorAndBuildingId } from '../../../Common/Helper';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import IbssSearchCard from '../../../Components/uicomponents/IbssSearchCard';
import apis from '../../../Providers.Api/apis';
import NoEquipmentAvailablePage from './NoEquipmentAvailablePage';
import { Constants } from '../../../Common/Constants';
import { RouteComponentProps } from 'react-router-dom';
import { IEquipmentDetailsResponse, IUpdateEquipmentRequest, IUpdateEquipmentResponse } from '../../../Providers.Api/Models';
import { AxiosResponse } from 'axios';

export default class SearchAvailableEquipment extends Component<IProps, IState>
{
    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            equipments: [],
            isSaving: false,
            isLoading: false,
        };
    }

    public async componentDidMount(): Promise<void>
    {
        if (this.props.bookingId == null)
        {
            return;
        }

        // update equipment
        const buildingId = this.props.match.params.buildingid;
        const updatedEquipment: IUpdateEquipmentRequest =
        {
            EquipmentType: this.props.equipmentType,
            StartDate: this.props.bookingStart,
            EndDate: this.props.bookingEnd,
        };

        this.setState({ isSaving: true, isLoading: true });

        // get available equipment
        const updateResponse: AxiosResponse<IUpdateEquipmentResponse[]> = await apis.updateEquipment(buildingId, updatedEquipment);
        let equipments = updateResponse.data.map(i => Equipment.fromUpdateEquipmentResponse(i, buildingId));

        // get booked equipment
        if (this.props.bookedEquipment != null)
        {
            for (let i = 0; i < this.props.bookedEquipment.length; i++)
            {
                const bookedEquipment: AxiosResponse<IEquipmentDetailsResponse> = await apis.getEquipmentDetails(1, this.props.bookedEquipment[i].Booking_Resource_Id);
                const bookedEquipmentView = Equipment.fromEquipmentDetailsResponse(bookedEquipment.data, buildingId);
                equipments.push(bookedEquipmentView);
            }
        }

        // done
        this.setState({ equipments: equipments, isLoading: false, isSaving: false, });
    };

    public render(): JSX.Element
    {
        return (
            <div>
                {this.state.isSaving && <LoadingOverlay />}
                {this.state.equipments?.filter( (equip: { equipmentType: string; }) => equip.equipmentType == this.props.equipmentType).sort((a,b) => a.equipmentName > b.equipmentName ? 1 : -1).map(item => (
                    <Grid container sx={{ backgroundColor: this.props.selectedEquipmentIds.includes(item.equipmentId) ? 'lightGrey' : ''}}>
                        <Grid item xs={8}>
                            <IbssSearchCard
                                className='equipment-item'
                                imageSrc={item.imageUrl}
                                searchHeading={item.equipmentName}
                                searchSubHeading={item.equipmentType}
                                onClick={() => this.props.selectEquipment(item.equipmentId)}
                                backgroundColor={this.props.selectedEquipmentIds.includes(item.equipmentId) ? 'lightGrey' : ''}
                            />
                        </Grid>
                        <Grid item xs={4} className='my-auto'>
                            <div className='ml-2'>
                                <div className='d-flex align-items-center mb-10'>
                                    {/* added alt attribute to remove warnings */}
                                    <img className='icon-sm mr-10' src="/images/Sidebar_Icons/Active_icons/Floor.svg" alt="floor-icon"/>
                                    <p className="labels-sm my-0">{item.floorName}</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <img className='icon-sm mr-10' src="/images/Sidebar_Icons/Active_icons/Zone.svg" alt="zones-icon"/>
                                    <p className="labels-sm my-0">{item.metaLocZone}</p>
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                ))}
                {this.state.equipments.length === 0 && (
                    <NoEquipmentAvailablePage />
                )}
            </div>
        )
    }
}

export interface IProps extends RouteComponentProps<IQueryParams>
{
    bookingId: string;
    bookingStart: string;
    bookingEnd: string;
    equipmentType: string;
    selectEquipment: (equipmentId: string) => void;
    selectedEquipmentIds: string[];
    bookedEquipment: IBookedEquipment[] | null;
}

export interface IQueryParams
{
    buildingid: string;
}


export interface IBookedEquipment
{
    Booking_Resource_Id: string;
}

export interface IState
{
    equipments: Equipment[];
    isSaving: boolean;
    isLoading: boolean;
}

export class Equipment
{
    public static fromUpdateEquipmentResponse(response: IUpdateEquipmentResponse, buildingId: string): Equipment
    {
        let equipment = new Equipment();
        equipment.nodeId = response.Node_Id;
        equipment.equipmentId = response.Equip_Id;
        equipment.equipmentName = response.Equip_Name;
        equipment.equipmentType = response.Equip_Type;
        equipment.equipmentClass = response.Equip_Class;
        equipment.imageUrl = ((response.ImageURI || Constants.imagePaths.get(response.Equip_Class)) ?? "");
        equipment.bookingResourceId = response.Booking_Resource_Id;
        equipment.metaLocZone = response.Meta_Loc_Zone;
        equipment.floorName = getFloorNameUsingFloorAndBuildingId(buildingId, response.Node_Id);
        equipment.backgroundColor = "";
        return equipment;
    }

    public static fromEquipmentDetailsResponse(response: IEquipmentDetailsResponse, buildingId: string): Equipment
    {
        let equipment = new Equipment();
        equipment.nodeId = response.Node_Id;
        equipment.equipmentId = response.Equip_Id;
        equipment.equipmentName = response.Equip_Name;
        equipment.equipmentType = response.Equip_Type;
        equipment.equipmentClass = response.Equipment_Class;
        equipment.imageUrl = ((response.ImageURI || Constants.imagePaths.get(response.Equipment_Class)) ?? "");
        equipment.bookingResourceId = response.Booking_Resource_Id;
        equipment.metaLocZone = response.Meta_Loc_Zone;
        equipment.floorName = getFloorNameUsingFloorAndBuildingId(buildingId, response.Node_Id);
        equipment.backgroundColor = "lightGrey";
        return equipment;
    }

    public nodeId = 0;
    public equipmentId = "";
    public equipmentName = "";
    public equipmentType = "";
    public equipmentClass = "";
    public imageUrl = "";
    public bookingResourceId = "";
    public metaLocZone = "";
    public floorName = "";
    public backgroundColor = "";
}
