import { Component, SyntheticEvent } from "react";
import "../../../../styles/css/searchspace.scss";
import "../../../../App.css";
import { appContext } from "../../../../AppContext";
import { Icons } from "../../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../../Components/uicomponents/IbssSvgIcon";
import { TextField } from "@mui/material";
import IbssAutocomplete from "../../../../Components/uicomponents/IbssAutocomplete";
import IbssDialog from "../../../../Components/uicomponents/IbssDialog";
import IbssTextField from "../../../../Components/uicomponents/IbssTextField";
import { ICreateV2Booking_BookingParty } from "../../../../Providers.Api/Bookings/CreateV2BookingEndpoint";
import IbssButtonRedo from "../../../../Components/uicomponents/IbssButton";

export default class AddAttendees extends Component<IProps, IState>
{
    private labels = appContext().labels;

    private get apiClient() { return appContext().apiClient; }

    constructor(props: IProps)
    {
        super(props);

        this.state = {
            allAttendees: [],
            showModal: false,
            isVisitor: false,
            nonVisitorAttendeesData: {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1
            },
            visitorAttendeesData: {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2
            },
            isDisabled: false,
            errors: '',
            alreadyPresentError: '',
            userListAttendees: [],
            selectedAttendee: '',
            booking_Parties_array: [],
            booking_Parties_Visitors_array: [],
            clearTriggered: false
        }
    }

    private handleShowHideAttendeeModal(): void
    {
        this.setState(
            {
                showModal: !this.state.showModal,
                isVisitor: false,
                nonVisitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: false,
                    type: 1
                },
                visitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: true,
                    type: 2
                },
            })
    }

    private handleChangeVisitor(): void
    {
        this.setState({
            isVisitor: !this.state.isVisitor,
            nonVisitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: false,
                type: 1
            },
            visitorAttendeesData:
            {
                email: "",
                name: "",
                organisation: "",
                resourceId: null,
                visitor: true,
                type: 2
            },
            isDisabled: true,
            errors: '',
            alreadyPresentError: '',
        })
    }

    private handleSelectNonVisitorAttendees(e: SyntheticEvent, obj: IUserListAttendees): void
    {
        if (obj)
        {
            const HubLabelalreadyPresent = this.state.booking_Parties_array.find((item: IAttendee) => item.email === obj.email);
            if (HubLabelalreadyPresent != null)
            {
                this.setState({
                    alreadyPresentError: this.labels.HubLabelalreadyPresent,
                    isDisabled: true,
                    selectedAttendee: '',
                });
            } else
            {
                this.setState({
                    nonVisitorAttendeesData: {
                        email: obj.email,
                        name: obj.firstName + ' ' + obj.lastName,
                        organisation: obj.companyName,
                        resourceId: null,
                        visitor: false,
                        type: 1

                    },
                    isDisabled: false,
                })
            }
        }
    }

    private async inputChangeTitle(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): Promise<void>
    {
        this.setState({ selectedAttendee: e.target.value });

        if (e.target.value.length <= 3)
        {
            this.setState({
                alreadyPresentError: '',
            })
        }

        if (e.target.value.length >= 3)
        {
            this.apiClient.users.getUsers(e.target.value).then((res) =>
            {
                let newArray = res && res[0] && res.map(item =>
                {
                    let newItem = { ...item, label: '' }
                    newItem.label = item.displayName ? item.displayName : item.email;
                    newItem.displayName = item.displayName ? item.displayName : item.email;
                    newItem.lastName = newItem.lastName ? newItem.lastName : ""
                    newItem.firstName = newItem.firstName ? newItem.firstName : ""

                    return newItem
                })

                this.setState({
                    userListAttendees: (newArray && newArray[0]) ? newArray : []
                })
            });
        }
        else
        {
            this.setState({
                userListAttendees: []
            })
        }
    }

    private handleInputChangeVisitorAttendeesData(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void
    {
        const { type, value } = e.target;
        let errors: string = '';
        if (type === 'email')
        {
            const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );

            if (!pattern.test(value))
            {
                // update error massage in errors.email  object
                errors = this.labels.HubLabelInvalidEmailAddress;;
            }
            // updated state for email errors
            if (errors === "")
            {
                this.setState({
                    isDisabled: false
                })
            } else
            {
                this.setState({
                    isDisabled: true
                })
            }
            this.setState({
                errors: errors,
            });
        }

        if (e.target.name == 'firstname')
        {
            this.setState(
                {
                    visitorAttendeesData:
                    {
                        ...this.state.visitorAttendeesData,
                        name: e.target.value + ' ' + (this.state.visitorAttendeesData.name?.split(' ').length > 1 ? this.state.visitorAttendeesData.name?.split(' ')[1] : '')
                    },
                })
        }
        else if (e.target.name == 'lastname')
        {
            this.setState(
                {
                    visitorAttendeesData:
                    {
                        ...this.state.visitorAttendeesData,
                        name: this.state.visitorAttendeesData.name?.split(' ')[0] + ' ' + e.target.value
                    },
                })
        }
        else
        {
            this.setState({
                visitorAttendeesData: {
                    ...this.state.visitorAttendeesData,
                    [e.target.name]: e.target.value
                },
            })
        }
    }

    private handleAddAttendee(): void
    {
        if (!this.state.isVisitor)
        {
            const newBooking_Parties_array: IAttendee[] = [...this.state.booking_Parties_array]
            newBooking_Parties_array.push(this.state.nonVisitorAttendeesData)
            this.setState(
                {
                    booking_Parties_array: newBooking_Parties_array,
                    selectedAttendee: '',
                    nonVisitorAttendeesData:
                    {
                        email: "",
                        name: "",
                        organisation: "",
                        resourceId: null,
                        visitor: false,
                        type: 1
                    },
                    isDisabled: true,
                });
        }
        else
        {
            const newBooking_Parties_Visitors_array: IAttendee[] = [...this.state.booking_Parties_Visitors_array]
            newBooking_Parties_Visitors_array.push(this.state.visitorAttendeesData)
            this.setState({
                booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
                visitorAttendeesData:
                {
                    email: "",
                    name: "",
                    organisation: "",
                    resourceId: null,
                    visitor: true,
                    type: 2
                },
                isDisabled: true,
            });
        }
    }

    private handleRemoveAttendees = (item: IAttendee) =>
    {
        const newBooking_Parties_array: IAttendee[] = this.state.booking_Parties_array.filter((element: IAttendee) => element.email !== item.email)
        const newBooking_Parties_Visitors_array: IAttendee[] = this.state.booking_Parties_Visitors_array.filter((element: IAttendee) => element.email !== item.email)
        this.setState(
            {
                booking_Parties_array: newBooking_Parties_array,
                booking_Parties_Visitors_array: newBooking_Parties_Visitors_array,
                clearTriggered: true,
            })
    }

    private handleClearAllAttendees(): void
    {
        if (this.state.booking_Parties_array.length == 0 && this.state.booking_Parties_Visitors_array.length == 0)
        {
            return;
        }
        this.setState({
            booking_Parties_array: [],
            booking_Parties_Visitors_array: [],
            isDisabled: true,
            clearTriggered: true,
        })
    }

    private handleTotalAddAttendee(): void
    {
        let attendees: IAttendee[] = []
        if (!this.state.isVisitor && this.state.booking_Parties_Visitors_array.length === 0)
        {
            attendees = [...this.state.booking_Parties_array]
        } else
        {
            if (this.state.booking_Parties_array.length !== 0)
            {
                attendees = [...this.state.booking_Parties_array, ...this.state.booking_Parties_Visitors_array];
                this.setState({
                    allAttendees: attendees
                })
            } else
            {
                attendees = [...this.state.booking_Parties_Visitors_array];
            }

        }
        let firstElement = attendees[0];
        firstElement = { ...firstElement, backgroundColor: '#4893CF' };

        attendees.shift();
        attendees.unshift(firstElement);

        this.setState({
            allAttendees: attendees
        });
        if (this.state.clearTriggered === true && this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0)
        {
            this.setState({
                allAttendees: [],
                clearTriggered: false,
            });
        }
        this.handleShowHideAttendeeModal();

        this.props.onSubmit(this.state.booking_Parties_array, this.state.booking_Parties_Visitors_array)
    }

    private extractFirstLetters(First_Name: string, Last_Name: string): string
    {
        let firstName = First_Name?.charAt(0).toUpperCase();
        let lastName = Last_Name?.charAt(0).toUpperCase();

        let firstLetters = firstName + lastName;

        return firstLetters;
    }


    public render(): JSX.Element
    {

        const isButtonDisabled = (this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && this.state.clearTriggered === false;

        return (
            <>
                <div className="row attendes cp" onClick={() => this.handleShowHideAttendeeModal()}>
                    <div className="icon-text-inline pl-0">
                        {(this.state.booking_Parties_array.length === 0 && this.state.booking_Parties_Visitors_array.length === 0) && (
                            <>
                                <div className="d-flex space-between mr-2">
                                    <span className="space-icon-item">
                                        <div style={{ fontSize: '26px', height: '19px' }}>
                                            <IbssSvgIcon fontSize='inherit' className="mr-2">
                                                {Icons.AddAttendeeIcon}
                                            </IbssSvgIcon>
                                        </div>
                                        <span className="space-text-item col-text" style={{ marginLeft: '-12px' }}>{this.state.allAttendees.length === 0 && this.labels.HubLabelAddAttendeeLabel}</span>
                                    </span>
                                </div>
                                <hr />
                            </>
                        )}
                        <span className="ml-2 d-flex">
                            {this.state.allAttendees.map((item: IAttendee) => (
                                <p className="attendee-name circle-labels overlap" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : "#2A3F49" }}>{this.extractFirstLetters(item.name?.split(' ')[0], item.name?.split(' ')[1])}</p>
                            ))}
                        </span>
                    </div>
                    <div className="d-flex">
                        <span className="space-text-item mr-3">{this.state.allAttendees.length !== 0 && `${this.state.allAttendees.length} ${this.labels.HubLabelAttendees}`}</span>
                        <IbssSvgIcon fontSize='medium' className="mr-2">
                            {Icons.RightArrowIcon}
                        </IbssSvgIcon>
                    </div>
                </div>
                <hr />
                {
                    this.state.showModal &&
                    <IbssDialog
                        open={this.state.showModal}
                        fullWidth
                        onClose={() => this.handleShowHideAttendeeModal()}
                        header=
                        {
                            <>
                                <label className="modal-heading">{this.labels.HubLabelAttendeeSelection}</label>
                            </>
                        }
                        content=
                        {
                            <div>

                                <div className="d-flex justify-content-between">
                                    <label className="lable-modal">{this.labels.HubButtonVisitorAccess}</label>
                                    <input name="isPrivate" type="checkbox" className="input-check-box-label border checkbox-sm" checked={this.state.isVisitor} onChange={() => this.handleChangeVisitor()} />
                                </div>

                                <hr className="m-0 mx-2" />

                                {!this.state.isVisitor &&
                                    <div className="mt-2">
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelnameEmailAddress}</label>
                                            <IbssAutocomplete
                                                id="free-solo-demo"
                                                className="mt-8"
                                                freeSolo
                                                options={this.state.userListAttendees}
                                                value={this.state.selectedAttendee}
                                                onChange={(e: SyntheticEvent, obj: IUserListAttendees) => this.handleSelectNonVisitorAttendees(e, obj)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        className="form-control modal-attendees-form w-100 border-1"
                                                        name="selectedAttendee"
                                                        value={this.state.selectedAttendee}
                                                        id="selectedAttendee"
                                                        placeholder={this.labels.HubLabelNameOrEmailLabel}
                                                        onBlur={(e) => { this.inputChangeTitle(e); }}
                                                        onChange={(e) => { this.inputChangeTitle(e); }}
                                                        size={'small'} sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    />}
                                            />
                                            <div className="text-danger">{this.state.alreadyPresentError}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.isVisitor &&
                                    <>
                                        <div className="form-group mt-3 mx-10">
                                            <label className="py-1">{this.labels.HubLabelnameEmailAddress + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="email"
                                                    name="email"
                                                    value={this.state.visitorAttendeesData.email}
                                                    onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                                    placeholder={this.labels.HubLabelNameOrEmailLabel}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                            <div className="text-danger">
                                                {this.state.errors}
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelFirstName + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="firstname"
                                                    value={this.state.visitorAttendeesData.name?.split(' ')[0]}
                                                    onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                                    placeholder={this.labels.HubLabelFirstNamePlaceholderLabel}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelLastName + ' *'}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="lastname"
                                                    value={this.state.visitorAttendeesData.name?.split(' ')[1]}
                                                    onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                                    placeholder={this.labels.HubLabelLastNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mx-10">
                                            <label className="py-1">{this.labels.HubLabelCompany}</label>
                                            <div>
                                                <IbssTextField
                                                    className="form-control modal-attendees-form w-100 mt-8"
                                                    variant="outlined"
                                                    type="text"
                                                    name="organisation"
                                                    value={this.state.visitorAttendeesData.organisation}
                                                    onChange={(e) => { this.handleInputChangeVisitorAttendeesData(e) }}
                                                    placeholder={this.labels.HubLabelCompanyNamePlaceholder}
                                                    size="small"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="form-group mx-10">
                                    <IbssButtonRedo
                                        variant='contained'
                                        onClick={() => this.handleAddAttendee()}
                                        disabled={
                                            this.state.isDisabled ||
                                            (this.state.isVisitor &&
                                                (
                                                    this.state.visitorAttendeesData.name?.split(' ')[0] == undefined ||
                                                    this.state.visitorAttendeesData.name?.split(' ')[1] == undefined ||
                                                    this.state.visitorAttendeesData.name?.split(' ')[0]?.length < 1 ||
                                                    this.state.visitorAttendeesData.name?.split(' ')[1]?.length < 1
                                                )
                                            )
                                        }
                                    >
                                        {this.labels.HubButtonAddattendeebutton}
                                    </IbssButtonRedo>
                                </div>
                            </div>
                        }
                        footer=
                        {
                            <div className="w-100">
                                {this.state.booking_Parties_array.length > 0 && (
                                    <>
                                        <div className="px-58 form-group ml-10">
                                            <label className="py-1 lable-modal-bold">{this.labels.HubLabelAttendees}</label>
                                            <div className="d-flex flex-wrap">
                                                {this.state.booking_Parties_array.map((item: IAttendee) => (
                                                    <>
                                                        <p className="attendee-name mr-3">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.handleRemoveAttendees(item)}></i></p>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="attendee-hr" />
                                    </>
                                )}
                                {this.state.booking_Parties_Visitors_array.length > 0 && (
                                    <>
                                        <div className="px-58 form-group ml-10 mt-1">
                                            <label className="py-1 lable-modal-bold">{this.labels.HubLabelVisitorAttendee}</label>
                                            <div className="d-flex flex-wrap">
                                                {this.state.booking_Parties_Visitors_array.map((item: IAttendee) => (
                                                    <p className="attendee-name mr-3 mb-0">{item.name} <i className="fa fa-times-circle-o" aria-hidden="true" onClick={() => this.handleRemoveAttendees(item)}></i></p>
                                                ))}
                                            </div>
                                        </div>
                                        <hr className="attendee-hr" />
                                    </>
                                )}
                                <div className="px-58 mt-1 pw-100 d-flex justify-content-end">
                                    <a href="#" className="clear-attendees" onClick={() => this.handleClearAllAttendees()} >{this.labels.HubButtonClearAttendees}</a>
                                    <IbssButtonRedo
                                        className='ml-2'
                                        variant='contained'
                                        disabled={isButtonDisabled}
                                        onClick={() => this.handleTotalAddAttendee()}
                                    >
                                        {this.labels.HubButtonSave}
                                    </IbssButtonRedo>
                                </div>
                            </div>
                        }
                    />
                }
            </>
        );
    }
}

export interface IState
{
    showModal: boolean;
    allAttendees: IAttendee[];
    isVisitor: boolean;
    nonVisitorAttendeesData: IAttendee;
    visitorAttendeesData: IAttendee;
    isDisabled: boolean;
    errors: string;
    alreadyPresentError: string;
    userListAttendees: IUserListAttendees[];
    selectedAttendee: string;
    booking_Parties_array: IAttendee[];
    booking_Parties_Visitors_array: IAttendee[];
    clearTriggered: boolean;
}

export interface IProps
{
    onSubmit: Function;
}

export class IAttendee
{
    email: string;
    name: string;
    organisation: string;
    resourceId: string | null;
    visitor: boolean;
    type: number;
    backgroundColor?: string;

    constructor(data: ICreateV2Booking_BookingParty)
    {
        this.email = data.Booking_Participant_Email;
        this.name = data.Booking_Participant_Name;
        this.organisation = data.Booking_Participant_Organisation;
        this.resourceId = data.Booking_Resource_Id;
        this.visitor = data.Booking_Visitor;
        this.type = data.Booking_Participant_Type;
    }
}

export interface IUserListAttendees
{
    label: string;
    displayName: string;
    lastName: string;
    firstName: string;
    email: string;
    companyName: string;
}