import { Component } from "react";
import { Card, SelectChangeEvent } from "@mui/material";
import IbssDataGrid from "../../../Components/uicomponents/IbssDataGrid";
import { appContext } from "../../../AppContext";
import Spinner from "../../../Components/Spinner";
import IbssActionButton, { IActionButton } from "../../../Components/uicomponents/IbssActionButton";
import { Icons } from "../../../Common/AllsvgIcons";
import IbssSvgIcon from "../../../Components/uicomponents/IbssSvgIcon";
import IbssTextField from "../../../Components/uicomponents/IbssTextField";
import IbssButton from "../../../Components/uicomponents/IbssButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import IbssFormControl from "../../../Components/uicomponents/IbssFormControl";
import IbssInputDropDown from "../../../Components/uicomponents/IbssInputDropDown";
import apis from "../../../Providers.Api/apis";
import { ComponentHelper } from "../../../Common/ComponentHelper";
import { Space } from "../../../Providers.Api/Spaces/SpaceRepository";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";

class BookingPolicies extends Component<RouteComponentProps, IState>
{
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private get appState() { return appContext().state; }
    private get localStorage() { return appContext().localStorageProvider; }
    private get apiCache() {return appContext().apiCache; }
    private get local() { return appContext().localStorageProvider; }

    private rows = new Array<IGridRow>();
    private userCanCreate: boolean;
    private userCanDelete: boolean;
    private component = new ComponentHelper(this);
    private setStateAsync = this.component.setStateAsync.bind(this.component);

    constructor(props: RouteComponentProps)
    {
        super(props);
        this.userCanCreate = this.local.hasRight('DATAMODEL.BookingPolicies.Create');
        this.userCanDelete = this.local.hasRight('DATAMODEL.BookingPolicies.Delete');
        
        this.state =
        {
            bookingPolicyIds: new Array<string>(),
            isLoading: false,
            filteredRows: new Array<IGridRow>(),
            buildingId: this.appState.buildingId,
            filter: '',
            showDeleteConifimationModal: false,
            showReassignSpacesModal: false,
            bookingPolicies: new Array<IGridRow>(),
            totalWorkspacesOfSelectedPolicies: 0,
            selectedReassignBuildingId: '',
        };
    }

    public async componentDidMount(): Promise<void>
    {
        this.appState.autoMap(this, i => ({ lightModeTheme: i.lightModeTheme, buildingId: i.buildingId }));
        let buildingId = parseInt((this.props.match.params as IQueryParams).buildingid);
        if(buildingId == 0)
        {
            const buildings = await apis.getSpacesByFilterEnabledBuilding('Building', 1)
            buildingId = buildings.data.sort((a: { name: string; },b: { name: string; }) => a.name > b.name ? 1 : -1)[0].id
        }
        await this.appState.set({ buildingId: buildingId });
        await this.loadBookingPolicies();
    }

    public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: IState): Promise<void>
    {
        if (prevState.buildingId != this.state.buildingId)
        {
            await this.loadBookingPolicies();
            const { history, match } = this.props;
            history.push(match.path.replace(":buildingid", this.state.buildingId.toString()));
        }
    }

    private async getSpaces(buildingId: number, bookingPolicyId: string): Promise<Space[]>
    {
        const spacesByBuilding = await this.apiCache.getSpacesByBuilding(buildingId);
        const spacesByPolicy = spacesByBuilding.filter(i => i.Booking_Policy_Id == bookingPolicyId);

        spacesByPolicy.forEach(item =>
        {
            item.Space_Type_Label = (item.Space_Type_Label === "" ? item.Space_Type : item.Space_Type_Label);
        });

        return spacesByPolicy;
    }

    private async loadBookingPolicies(): Promise<void>
    {
        this.setState({ isLoading: true });
        const policies = await this.apiClient.bookingPolicies.getBookingPolicies(this.state.buildingId, 200, 0);
        this.rows = new Array<IGridRow>();

        for (let i = 0; i < policies.value.length; i++)
        {
            const policy = policies.value[i];
            this.rows.push(
            {
                id: policy.Booking_Policy_Id ?? "",
                policyName: policy.Booking_Policy_Name,
                description: policy.Booking_Policy_Object.Booking_Policy_Description,
                workspaces: await this.getWorkspacesByPolicy(policy.Booking_Policy_Id!),
            })
        }

        this.setState({ filteredRows: this.rows, bookingPolicies: this.rows, isLoading: false });
    }

    private async getWorkspacesByPolicy(bookingPolicyId: string): Promise<string>
    {
        const spacesByPolicy = await this.getSpaces(this.state.buildingId, bookingPolicyId);

        const workspaceTotals = spacesByPolicy
            .groupBy(i => i.Space_Type)
            .map(i => ` (${i.value.length} no.) ${i.key} `);

        return workspaceTotals.toString();
    }

    private searchTextChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        this.setState({ filter: e.target.value });
    }

    private applyFilterClicked(filter: string): void
    {
        let filteredRows = this.rows;
        if (filter != null && filter != '')
        {
            filteredRows = filteredRows.filter(x =>
                x.policyName.includes(filter) ||
                x.description?.includes(filter) ||
                x.workspaces?.includes(filter));
        }
        this.setState({ filteredRows: filteredRows });
    }

    private async deletePoliciesClicked(): Promise<void>
    {
        //check how many spaces are assigned to selected policies
        let count = 0
        for (let i = 0; i < this.state.bookingPolicyIds.length; i++)
        {
            const policyId = this.state.bookingPolicyIds[i];
            const spaces = await this.getSpaces(this.state.buildingId, policyId);
            count += spaces.length;
        }

        //if no spaces assigned open delete confirmation modal
        if(count == 0)
        {
            this.setState({ showDeleteConifimationModal: true })
        }
        //if spaces are assigned open reassign spaces modal
        if(count > 0)
        {
            this.setState({ showReassignSpacesModal: true, totalWorkspacesOfSelectedPolicies: count })
        }
    }

    private rowClicked(e: any): void
    {
        this.props.history.push("/booking-policies/edit/" + e.id);
    }

    private selectedPoliciesChanged(bookingPolicyIds: string[]): void
    {
        this.setState({ bookingPolicyIds: bookingPolicyIds });
    }

    private deleteConfirmationModalClosed(): void
    {
        this.setState({ showDeleteConifimationModal: false, selectedReassignBuildingId: '' });
    }

    private async deleteConfirmationModalSubmitted(): Promise<void>
    {
        this.setState({ isLoading: true });
        
        if(this.state.totalWorkspacesOfSelectedPolicies > 0)
        {
            let spaceIds: string[] = [];
            for (let i = 0; i < this.state.bookingPolicyIds.length; i++)
            {
                const policyId = this.state.bookingPolicyIds[i];
                const spaces = await this.getSpaces(this.state.buildingId, policyId);
                spaceIds = spaceIds.concat(spaces.map(space => space.Space_Id));
            }
            await this.apiClient.bookingPolicies.assignBookingPolicyToSpaces(this.appState.buildingId, this.state.selectedReassignBuildingId, spaceIds);
        }
        
        await this.state.bookingPolicyIds.forEach(policyId => this.apiClient.bookingPolicies.deleteBookingPolicy(this.state.buildingId, policyId));
        await this.setStateAsync({ isLoading: false, showDeleteConifimationModal: false, selectedReassignBuildingId: '' });
        this.loadBookingPolicies();
    }

    private reassignSpacesModalClosed(): void
    {
        this.setState({ showReassignSpacesModal: false, selectedReassignBuildingId: '' });
    }

    private reassignSpacesModalSubmitted(): void
    {
        this.setState({ showReassignSpacesModal: false, showDeleteConifimationModal: true });
    }

    private policySelected(id: string): void
    {
        this.setState({ selectedReassignBuildingId: id });
    }

    public render(): JSX.Element
    {
        const columns =
        [
            {
                field: 'policyName',
                headerName: this.labels.HubLabelViewPoliciesTableColumnPolicyName,
                width: 280,
            },
            {
                field: 'description',
                headerName: this.labels.HubLabelViewPoliciesTableColumnDescription,
                width: 280,
            },
            {
                field: 'workspaces',
                headerName: this.labels.HubLabelViewPoliciesTableColumnWorkspaces,
                width: 280,
            },
        ];

        let buttons: IActionButton[] =
         [
            {
                label: this.labels.HubButtonDelete,
                icon: <IbssSvgIcon>{Icons.Bin}</IbssSvgIcon>,
                color: 'error',
                onClick: () => this.deletePoliciesClicked(),
                disabled: (this.state.bookingPolicyIds.length == 0),
            },
            {
                label: this.labels.HubLabelAssignSpacesButton,
                icon: <IbssSvgIcon>{Icons.AssignSpacesIcon}</IbssSvgIcon>,
                color: 'primary',
                onClick: () => this.props.history.push("/booking-policies/assign/" + this.state.bookingPolicyIds[0]),
                disabled: (this.state.bookingPolicyIds.length == 0 || this.state.bookingPolicyIds.length > 1),
            },
            {
                label: this.labels.HubLabelAddButton,
                icon: <IbssSvgIcon>{Icons.AddIcon}</IbssSvgIcon>,
                color: 'primary',
                onClick: () => this.props.history.push("/booking-policies/" + this.appState.buildingId + "/create"),
            }
        ]

        if(!this.userCanCreate)
        {
            buttons = buttons.filter(x => x.label != this.labels.HubLabelAddButton);
        }

        if(!this.userCanDelete)
        {
            buttons = buttons.filter(x => x.label != this.labels.HubButtonDelete);
        }

        return (
            <div className="rightPanel-main-content">
                <Card>
                    <div className="m-3">
                        <div className='d-flex'>
                            <div className="mr-2">
                                <IbssSvgIcon fontSize='large'>
                                    {Icons.StandardDesk}
                                </IbssSvgIcon>
                            </div>
                            <div className='table-panel-header' style={{ paddingTop: '4px' }}>
                                {this.labels.HubMenuBookingPolicies}
                            </div>
                        </div>
                        <div className="table-panel-description">
                            {this.labels.HubLabelViewBookingPoliciesTableDescription}
                        </div>
                        {
                            this.state.isLoading ?
                                <Spinner />
                                :
                                <div style={{ display: 'grid' }}>
                                    <div className='d-flex'>
                                        <IbssTextField onChange={e => this.searchTextChanged(e)} label={this.labels.HubLabelSearch} />
                                        <div style={{ alignSelf: 'center', paddingLeft: '8px' }}>
                                            <IbssButton fullWidth={false} style={{ minWidth: '100px', height: '40px' }} variant="contained" onClick={() => this.applyFilterClicked(this.state.filter)}>{this.labels.HubLabelFilter}</IbssButton>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ float: 'right', paddingBottom: '5px' }}>
                                            <IbssActionButton buttons={buttons} />
                                        </div>
                                    </div>
                                    <IbssDataGrid
                                        className="view-booking-policies-data-grid"
                                        onRowClick={e => this.rowClicked(e)} 
                                        disableRowSelectionOnClick
                                        onRowSelectionModelChange={e => this.selectedPoliciesChanged(e as string[])}
                                        height={'800px'}
                                        checkboxSelection
                                        columns={columns}
                                        rows={this.state.filteredRows}
                                    />
                                    <DeleteConfirmationModal
                                        show={this.state.showDeleteConifimationModal}
                                        onHide={() => this.deleteConfirmationModalClosed()}
                                        onSubmit={() => this.deleteConfirmationModalSubmitted()}
                                    />
                                    <ReassignSpacesModal
                                        show={this.state.showReassignSpacesModal}
                                        onHide={() => this.reassignSpacesModalClosed()}
                                        selectedBookingPolicies={this.state.bookingPolicies.filter(x => this.state.bookingPolicyIds.includes(x.id))}
                                        totalWorkspacesOfSelectedPolicies={this.state.totalWorkspacesOfSelectedPolicies}
                                        onSubmit={() => this.reassignSpacesModalSubmitted()}
                                        bookingPolicies={this.state.bookingPolicies}
                                        selectedReassignBuildingId={this.state.selectedReassignBuildingId}
                                        selectedBookingPolicyIds={this.state.bookingPolicyIds}
                                        onPolicySelected={id => this.policySelected(id)}
                                    />
                                </div>
                        }
                    </div>
                </Card>
            </div>
        )
    }
}

class DeleteConfirmationModal extends Component<IDeleteConfirmationModalProps, any>
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        const { show, onHide, onSubmit } = this.props;

        return (
        <Modal show={show} onHide={() => onHide()}>
            <Modal.Header>
                <Modal.Title>{this.labels.HubLabelConfirmDeletePolicy}</Modal.Title>
                <button type="button" className="close" onClick={() => onHide()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <div className='m-4'>
                {this.labels.HubLabelConfirmDeletePolicyText}
            </div>
            <Modal.Footer>
                <div style={{ textAlign: 'center' }}>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px', marginRight: '180px' }}
                        color='secondary'
                        variant="contained"
                        onClick={() => onHide()}
                    >
                        {this.labels.HubButtonCancel}
                    </IbssButton>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px' }}
                        color='error'
                        variant="contained"
                        onClick={() => onSubmit()}
                        endIcon={<IbssSvgIcon fontSize='small'>{Icons.WarningIcon}</IbssSvgIcon>}
                    >
                        {this.labels.HubLabelOk}
                    </IbssButton>
                </div>
            </Modal.Footer>
        </Modal>
        )
    }
}

class ReassignSpacesModal extends Component<IReassignSpacesModalProps, any>
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        const { show, onHide, selectedBookingPolicies, totalWorkspacesOfSelectedPolicies, onSubmit, bookingPolicies, selectedReassignBuildingId, selectedBookingPolicyIds, onPolicySelected } = this.props;
        return (
        <Modal show={show} onHide={() => onHide()}>
            <Modal.Header>
                <Modal.Title>{this.labels.HubLabelDeletePolicy}</Modal.Title>
                <button type="button" className="close" onClick={() => onHide()} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <div className='m-4'>
                {
                    `
                    ${selectedBookingPolicies.length == 1 ? this.labels.HubLabelDeletePolicyTextPt1 : this.labels.HubLabelDeletePolicyPluralisedTextPt1}
                    "${selectedBookingPolicies.map(x => x.policyName).toString().replaceAll(',', ', ')}" 
                    ${selectedBookingPolicies.length == 1 ? this.labels.HubLabelDeletePolicyTextPt2 : this.labels.HubLabelDeletePolicyTextPluralisedPt2}
                    `
                } 
                <div className="mt-4 d-flex" style={{ justifyContent: 'space-around' }}>
                    {`(${totalWorkspacesOfSelectedPolicies} No.) ${this.labels.HubLabelSpaces}`}
                    <IbssFormControl style={{ width: '350px', paddingBottom: '8px' }} >
                        <IbssInputDropDown
                            onChange={(e: SelectChangeEvent) => onPolicySelected(e.target.value)}
                            inputLabel={this.labels.HubLabelPickPolicy}
                            id='policiesDropdown'
                            options={bookingPolicies.filter(policy => !selectedBookingPolicyIds.includes(policy.id)).map(x => {return({value: x.id, label: x.policyName})})}
                            defaultValue={''}
                            />
                    </IbssFormControl>
                </div>
            </div>
            <Modal.Footer>
                <div className="m-4">
                    {this.labels.HubLabelDeletePolicyFooterText}
                </div>
                <div style={{ textAlign: 'center' }}>
                    <IbssButton
                        style={{ height: '45px', minWidth: '100px' }}
                        variant="contained"
                        onClick={() => onSubmit()}
                        disabled={selectedReassignBuildingId == ''}
                    >
                        {this.labels.HubLabelOk}
                    </IbssButton>
                </div>
            </Modal.Footer>
        </Modal>
        )
    }
}

export default withRouter(BookingPolicies);

export interface IQueryParams
{
    buildingid: string;
}

export interface IState
{
    isLoading: boolean;
    buildingId: number;
    bookingPolicyIds: string[];
    filter: string;
    filteredRows: IGridRow[];
    showDeleteConifimationModal: boolean;
    showReassignSpacesModal: boolean;
    bookingPolicies: IGridRow[];
    totalWorkspacesOfSelectedPolicies: number;
    selectedReassignBuildingId: string;
}

export interface IGridRow
{
    id: string;
    policyName: string;
    description: string;
    workspaces: string;
}

export interface IDeleteConfirmationModalProps
{
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
}

export interface IReassignSpacesModalProps
{
    show: boolean;
    selectedBookingPolicies: IGridRow[];
    totalWorkspacesOfSelectedPolicies: number;
    bookingPolicies: IGridRow[];
    selectedReassignBuildingId: string;
    selectedBookingPolicyIds: string[];
    onHide: () => void;
    onSubmit: () => void;
    onPolicySelected: (id: string) => void;
}
