import { Component } from "react";
import "../../styles/css/table.scss";
import "../../App.css";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ChartPanelInfo from "../../Components/ChartPanelInfo";
import Spinner from "../../Components/Spinner";
import { IPartialAppState, appContext } from "../../AppContext";
import { UPDATE_MAIN_PAGE_TITLE } from "../../app/constants";

class FacilityAnalyticsOverview extends Component {
  private labels = appContext().labels;
  private appState = appContext().state;
  private session = appContext().sessionStorageProvider;
  state = {
    isLoading: false,
    showAlertModal: false,
    alertTitle: "",
    alertMessageText: "",
    spaces: [],
    filter: "",
    tableMessage: this.labels.HubLabelLoadingText,
    isCreateRight: false,
    widthchart: 0,
    envZonesData: [],
    tempretatureInfo: [],
    humidityInfo: [],
    noiceInfo: [],
    iaqInfo: [],
    node_Id: null,
    selectedBuildingId: this.appState.buildingId.toString(),
  };
  public async componentDidMount() {
    const { match, dispatch, history }: any = this.props;
    this.appState.subscribe(this, i => this.appStateChanged(i));
    const buildingId = match.params["buildingid"];
    await this.appState.set({ buildingId: buildingId });
    dispatch({ type: UPDATE_MAIN_PAGE_TITLE, mainPageTitle: this.labels.HubLabelSustainabilityAnalyticsOverview });
    if (this.session.getBuildingId() !== match.params.buildingid) {
      const temp = window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));
      history.push(temp + "/" + this.session.getBuildingId())
    }
    this.setState({ node_Id: match.params.buildingid })
    const filter = match.url.split('=')[1];
    this.setState({ filter: filter });
    this.setState({ isLoading: true })
    apis.getFacilityZonesData(this.session.getBuildingId()).then((res) => {
      let chartList = ['TEMP', 'HUMIDITY', 'NOICE', 'IAQ']
      let status = '';
      for (let k = 0; k < chartList.length; k++) {
        if (chartList[k] === "TEMP") {
          status = 'Env_Temperature_Status';
        } else if (chartList[k] === "HUMIDITY") {
          status = 'Env_Humidity_Status';
        } else if (chartList[k] === "NOICE") {
          status = 'Env_Sound_Level_Status';
        } else if (chartList[k] === "IAQ") {
          status = 'IAQ_Status';
        }
        this.formatChartData(res, status, chartList[k])
      }
    }).catch((error: any) => {
      this.setState({
        isLoading: false,
      })
    })

  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: any, snapshot?: any): void {
    if(prevState.selectedBuildingId != this.state.selectedBuildingId)
    {
      this.setState({ isLoading: true })
      apis.getFacilityZonesData(this.session.getBuildingId()).then((res) => {
        let chartList = ['TEMP', 'HUMIDITY', 'NOICE', 'IAQ']
        let status = '';
        for (let k = 0; k < chartList.length; k++) {
          if (chartList[k] === "TEMP") {
            status = 'Env_Temperature_Status';
          } else if (chartList[k] === "HUMIDITY") {
            status = 'Env_Humidity_Status';
          } else if (chartList[k] === "NOICE") {
            status = 'Env_Sound_Level_Status';
          } else if (chartList[k] === "IAQ") {
            status = 'IAQ_Status';
          }
          this.formatChartData(res, status, chartList[k])
        }
      }).catch((error: any) => {
        this.setState({
          isLoading: false,
        })
      })
    }
  }

  private async appStateChanged(state: IPartialAppState): Promise<void>
  {
    const { history, match }: any = this.props;
      if (state.buildingId != undefined)
      {
          this.setState({ selectedBuildingId: state.buildingId.toString() });
          history.push(match.path.replace(":buildingid", state.buildingId));
      }
  }

  formatChartData(res: any, status: string, statString: string) {

    let NoData = 0;
    let Good = 0;
    let Fair = 0;
    let Poor = 0;
    let tempData = [{ "name": "Good", label: "Good" , "Good": 0 },
    { "name": "Fair", label: "Fair", "Fair": 0 },
    { "name": "Poor", label: "Poor", "Poor": 0 },
    { "name": "NoData", label: "No Data", "NoData": 0 }
    ];
    for (let j = 0; j < res.data.length; j++) {
      if (res.data[j][status] === 0) {
        NoData += 1;
      } else if (res.data[j][status] === 1) {
        Good += 1;
      } else if (res.data[j][status] === 2) {
        Fair += 1;
      } else if (res.data[j].status === 3) {
        Poor += 1;
      }
    }
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i].name === 'Good') {
        tempData[i].Good = (Good / res.data.length) * 100
      } else if (tempData[i].name === 'Fair') {
        tempData[i].Fair = (Fair / res.data.length) * 100;
      } else if (tempData[i].name === 'Poor') {
        tempData[i].Poor = (Poor / res.data.length) * 100;
      } else if (tempData[i].name === 'NoData') {
        tempData[i].NoData = (NoData / res.data.length) * 100;
      }
    }
    if (statString === "TEMP") {
      this.setState({ tempretatureInfo: tempData });
    } else if (statString === "HUMIDITY") {
      this.setState({ humidityInfo: tempData });
    } else if (statString === "NOICE") {
      this.setState({ noiceInfo: tempData });
    } else if (statString === "IAQ") {
      this.setState({ iaqInfo: tempData });
    }
    this.setState({ envZonesData: res.data, isLoading: false });
  }


  onBarClick = () => {
    const { history }: any = this.props;
    history.push(`/sustainability-analytics-zones/${this.session.getBuildingId()}`)
  }
  render() {

    return (
          <div className="rightPanel-main-content-chart">
            {(this.state.isLoading || this.state.isLoading) && <Spinner />}
            <div className="row">
              <div className="col-sm p-r-20px">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelTemperature}
                    iconSrc="/images/Temperature.svg"
                  />
                  <div className="topchart">
                    {this.state.tempretatureInfo.length !== 0 &&
                      <BarChart data={this.state.tempretatureInfo} onClick={this.onBarClick} height={200} width={300} >
                        {/* <CartesianGrid vertical={false} /> */}
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" unit="%" />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        {/* <Legend /> */}
                        <Bar dataKey="Good" name={this.labels.HubLabelGood}stackId="a" fill="#00c843" />
                        <Bar dataKey="Fair" name={this.labels.HubLabelFair} stackId="a" fill="#FFC400" />
                        <Bar dataKey="Poor" name={this.labels.HubLabelPoor} stackId="a" fill="#f44336" />
                        <Bar dataKey="NoData" name={this.labels.HubLabelNoData} stackId="a" fill="#393D44" />
                      </BarChart>
                    }
                  </div>
                </div>
              </div>
              <div className="col-sm p-r-20px">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelHumidity}
                    iconSrc="/images/Humidity.svg"
                  />
                  <div className="topchart">
                    {this.state.humidityInfo.length !== 0 &&
                      <BarChart data={this.state.humidityInfo} onClick={this.onBarClick} height={200} width={300} >
                        {/* <CartesianGrid vertical={false} /> */}
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" unit="%" />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        {/* <Legend /> */}
                        <Bar dataKey="Good" name={this.labels.HubLabelGood} stackId="a" fill="#00c843" />
                        <Bar dataKey="Fair" name={this.labels.HubLabelFair} stackId="a" fill="#FFC400" />
                        <Bar dataKey="Poor" name={this.labels.HubLabelPoor} stackId="a" fill="#f44336" />
                        <Bar dataKey="NoData" name={this.labels.HubLabelNoData}stackId="a" fill="#393D44" />
                      </BarChart>
                    }
                  </div>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-sm p-r-20px" id="part2">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelNoiseLevel}
                    iconSrc="/images/Sound Level.svg"
                  />
                  <div className="topchart">
                    {this.state.noiceInfo.length !== 0 &&
                      <BarChart data={this.state.noiceInfo} onClick={this.onBarClick} height={200} width={300} >
                        {/* <CartesianGrid vertical={false} /> */}
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" unit="%" />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        {/* <Legend /> */}
                        <Bar dataKey="Good" name={this.labels.HubLabelGood} stackId="a" fill="#00c843" />
                        <Bar dataKey="Fair" name={this.labels.HubLabelFair} stackId="a" fill="#FFC400" />
                        <Bar dataKey="Poor" name={this.labels.HubLabelPoor} stackId="a" fill="#f44336" />
                        <Bar dataKey="NoData" name={this.labels.HubLabelNoData} stackId="a" fill="#393D44" />
                      </BarChart>
                    }
                  </div>
                </div>
              </div>
              <div className="col-sm p-r-20px" id="part2">
                <div className="chart-panel-section1">
                  <ChartPanelInfo
                    chartHeading={this.labels.HubLabelAirQuality}
                    iconSrc="/images/Environment.svg"
                  />
                  <div className="topchart">
                    {this.state.iaqInfo.length !== 0 &&
                      <BarChart data={this.state.iaqInfo} onClick={this.onBarClick} height={200} width={300} >
                        {/* <CartesianGrid vertical={false} /> */}
                        <XAxis stroke="var(--ui-text)" dataKey="name" />
                        <YAxis stroke="var(--ui-text)" unit="%" />
                        <Tooltip contentStyle={{ color: 'var(--ui-text)', backgroundColor: 'var(--ui-background-alternate)' }} />
                        {/* <Legend /> */}
                        <Bar dataKey="Good" name={this.labels.HubLabelGood} stackId="a" fill="#00c843" />
                        <Bar dataKey="Fair" name={this.labels.HubLabelFair} stackId="a" fill="#FFC400" />
                        <Bar dataKey="Poor" name={this.labels.HubLabelPoor} stackId="a" fill="#f44336" />
                        <Bar dataKey="NoData" name={this.labels.HubLabelNoData}  stackId="a" fill="#393D44" />
                      </BarChart>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    lightModeTheme: state.lightModeTheme,
    mainPageTitle: state.mainPageTitle,
  };
};

export default connect(mapStateToProps)(FacilityAnalyticsOverview);
