import { appContext } from "../../../../AppContext";
import { RouterProps } from "react-router";
import Booking from "../../../OneLens/Shared/Home/homeBookings.png";
import Preferences from "./homePreferences.png";
import Visitors from "../../../OneLens/Shared/Home/homeVisitor.png";
import Space from "../../../OneLens/Shared/Home/homeBookSpace.png";
import { Box, Grid } from "@mui/material";
import {Typography} from "@mui/material";
import { IbssComponent } from "../../../../Components/IbssComponent";
import { IUserRights } from "../../../../Providers.Api/Models.UserApi";
import IbssHomeCard from "../../../../Components/IbssHomeCard";

class FlexHome extends IbssComponent<IProps, IState>
{
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get labels() { return appContext().labels; }
    private rights: IUserRights;

    constructor(props: IProps)
    {
        super(props);
        this.state =
        {
            buildingId: this.appState.buildingId,           
        }

        this.rights = this.local.getUserDetails().userRights;
    }

    public componentDidMount(): void
    {
        this.pageTitle = this.labels.HubMenuHome;
        this.appState.autoMap(this, i => ({ buildingId: i.buildingId }));
    }

    public render(): JSX.Element
    {
        const displayName = this.local.getUserDetails().displayName;

        const callToActions: ICallToAction[] = [
            {
                id: '1',
                title: this.labels.funcBookingHomeSpace_S,
                description: this.labels.funcBookingHomeSpace_L,
                buttonLabel: this.labels.funcBookingHomeSpaceStart_S,
                src: Space,
                imageAlt: this.labels.funcBookingHomeSpace_S,
                route:"/flex-find-a-space",
                permissions: (this.rights?.FLEX?.Search?.includes?.("Spaces") ?? false ) || (this.rights?.FLEX?.FindASpace?.includes?.("RecurringBookings") ?? false ),
            },

            {
                id: '2',
                title: this.labels.HubLabelBookings,
                description: this.labels.funcBookingHomeYourView_L,
                buttonLabel: this.labels.funcBookingHomeView_S,
                src: Booking,
                imageAlt: this.labels.HubLabelBookings,
                route:`/flex-my-bookings`,
                permissions: (this.rights?.FLEX?.MySchedule?.includes?.("MyBookings") ?? false),
            },

            {
                id: '3',
                title: this.labels.HubMenuVisitors,
                description: this.labels.funcBookingHomeYourVisitors_L,
                buttonLabel: this.labels.funcVisitorsHomeView_S,
                src: Visitors,
                imageAlt: this.labels.HubMenuVisitors,
                route:`/flex-my-visitor`,
                permissions: (this.rights?.FLEX?.MySchedule?.includes?.("MyVisitors") ?? false),
            },

            {
                id: '4',
                title: this.labels.funcBookingHomePreferences_S,
                description: this.labels.funcBookingHomePreferences_L,
                buttonLabel: this.labels.funcBookingHomeViewPreferences_S,
                src: Preferences,
                imageAlt: this.labels.funcBookingHomePreferences_S,
                route: `/flex-user-pref-notifications`,
                permissions:(this.rights?.FLEX?.UserPreferences?.includes?.('Notifications') ?? false),
            },
            
        ]

        return (
            <div className="rightPanel-main-content">
                <Box 
                    sx={theme => 
                        ({
                            [theme.breakpoints.up(1800)]: { // media query for screen sizes above 1800 pixels width
                                mt: 7,
                                mb: 7,
                            },
                            [theme.breakpoints.down(1800)]: { 
                                mt: 0,
                                mb: 2,
                            },
                        })
                    }
                >
                    <Typography 
                        sx={{
                            fontFamily: 'Source Sans Pro',
                            typography: { xs: "h6",  sm: "h6", md: "h6", lg: "h6", xl: "h4" },
                            fontWeight: 500, 
                            color: (theme)=> theme.palette.text.primary
                        }}
                    >
                        {`${this.labels.HubLabelWelcomeText}, ${displayName}`}
                    </Typography>
                </Box>
                <Box sx={{width:'100%', overflow: 'auto'}}>
                    <Grid 
                        container 
                        spacing={3} 
                        sx={theme => 
                        ({ 
                            minWidth: '1435px', 
                            maxWidth: '1435px',
                            [theme.breakpoints.up(1000)]: {
                                pb: 2,
                            },
                            [theme.breakpoints.down(1000)]: { 
                                pb: 0,
                            },
                        })
                        }
                    >     
                    {callToActions.filter(i => i.permissions === true).map((item) => 
                        <Grid 
                            item 
                            xs={3}
                            key={item.id}
                        >
                            <IbssHomeCard           
                                title={item.title}
                                description={item.description}
                                buttonLabel={item.buttonLabel}
                                src={item.src}
                                imageAlt={item.imageAlt}
                                route={item.route}
                                redirect={() => this.props.history.push(item.route)}
                            />
                        </Grid>
                    )}
                    </Grid>
                </Box>
            </div>
        );
    }
}

interface IProps extends RouterProps
{
}

interface IState
{
    buildingId: number;
}

export interface ICallToAction
{
    id: string;
    title: string;
    description: string;
    buttonLabel: string;
    src: string;
    imageAlt: string;
    route: string;
    permissions: boolean;
}

export default FlexHome;