import axios, { AxiosResponse } from "axios";
import { ApiError } from "../ApiError";
import apis from "../apis";

export class UpdateEndpoint implements IUpdateEndpoint
{
    public async execute(buildingId: number, spaceCateringId: number, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>
    {
        try
        {
            await axios.put(`${apis.allApi}${buildingId}/SpaceCateringMenu/${spaceCateringId}`, payload);
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IUpdateEndpoint
{
    execute(buildingId: number, spaceCateringId: number, payload: IUpdateSpaceCateringMenuItemPayload): Promise<void>;
}

export interface IUpdateSpaceCateringMenuItemPayload
{
    Node_Id?: number;
    Space_Catering_Id?: number;
    ImageURI?: string;
    Space_Catering_Title?: string;
    Space_Catering_Message?: string;
    Space_Catering_IsEnabled: number;
    Space_Catering_IsPreBookable?: number;
    Space_Catering_IsRoomService?: number;
    Space_Catering_Section?: string;
    Space_Catering_Cost?: number;
    Space_Catering_Short_Message?: string;
}
