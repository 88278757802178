import Desk from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/desk';
import BuildingIcon from '../Components/uicomponents/customMaterialIcons/BuildingIcon';
import CalendarIcon from '../Components/uicomponents/customMaterialIcons/CalendarIcon';
import DataPrivacyIcon from '../Components/uicomponents/customMaterialIcons/DataPrivacyIcon';
import NotificationIcon from '../Components/uicomponents/customMaterialIcons/NotificationIcon';
import SearchResultIcon from '../Components/uicomponents/customMaterialIcons/SearchResultIcon';
import SecurityIcon from '../Components/uicomponents/customMaterialIcons/SecurityIcon';
import Bar from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/bar';
import Benchseating from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/benchseating';
import Bikestore from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/bikestore';
import Boardroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/boardroom';
import Café from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/café';
import Changing from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/changing';
import Cleaningstation from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/cleaningstation';
import Coatstorage from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/coatstorage';
import Collaborationspace from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/collaborationspace';
import Drink from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/drink';
import Equipmentroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/equipmentroom';
import Fault from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/fault';
import Firstaid from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/firstaid';
import Focusbooth from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/focusbooth';
import Gym from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/gym';
import Height from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/height';
import Indoorgarden from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/indoorgarden';
import Informallounge from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/informallounge';
import Informalmeeting from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/informalmeeting';
import Kitchenette from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/kitchenette';
import Lecturetheatre from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/lecturetheatre';
import Liftlobby from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/liftlobby';
import Lightsoff from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/lightsoff';
import Lightson from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/lightson';
import Lockers from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/lockers';
import Meetingbooth from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/meetingbooth';
import Meetingroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/meetingroom';
import Office from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/office';
import Pantry from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/pantry';
import Parcellocker from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/parcellocker';
import Parkingspace from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/parkingspace';
import Phonebooth from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/phonebooth';
import Plantroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/plantroom';
import Postroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/postroom';
import Prayer from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/prayer';
import Prayerroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/prayerroom';
import Presentation from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/presentation';
import Printroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/printroom';
import Privateroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/privateroom';
import Quickstop from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/quickstop';
import Quietroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/quietroom';
import Reception from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/reception';
import Restaurant from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/restaurant';
import Security from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/security';
import Sitstanddesk from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/sitstanddesk';
import Specialistdesk from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/specialistdesk';
import Stationery from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/stationery';
import Storage from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/stationery';
import Temperature from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/temperature';
import Toilets from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/toilets';
import Toiletsaccessible from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/toiletsaccessible';
import Toiletsfemale from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/toiletsfemale';
import Toiletsmale from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/toiletsmale';
import Touchdown from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/touchdown';
import Trainingroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/trainingroom';
import Treatmentroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/treatmentroom';
import Vcroom from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/vcroom';
import Waitinglounge from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/waitinglounge';
import Waterpoint from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/waterpoint';
import Wellbeing from '../Components/uicomponents/customMaterialIcons/SpaceTypeIcons/wellbeing';
import TaskIcon from '../Components/uicomponents/customMaterialIcons/TaskIcon';
import WorkingDayIcon from '../Components/uicomponents/customMaterialIcons/WorkingDayIcon';
import RightArrowIcon from '../Components/uicomponents/customMaterialIcons/RightArrowIcon';
import CloseIcon from '../Components/uicomponents/customMaterialIcons/CloseIcon';
import DownAngleIcon from '../Components/uicomponents/customMaterialIcons/DownAngleIcon';
import UpAngleIcon from '../Components/uicomponents/customMaterialIcons/UpAngleIcon';
import IdProvider from '../Components/uicomponents/customMaterialIcons/IdProviderIcon';
import UserIcon from '../Components/uicomponents/customMaterialIcons/UserIcon';
import OrganisationIcon from '../Components/uicomponents/customMaterialIcons/OrganisationIcon';
import RegionIcon from '../Components/uicomponents/customMaterialIcons/RegionIcon';
import FloorIcon from '../Components/uicomponents/customMaterialIcons/FlooorIcon';
import ZoneIcon from '../Components/uicomponents/customMaterialIcons/ZoneIcon';
import MobileIcon from '../Components/uicomponents/customMaterialIcons/MobileIcon';
import TabletIcon from '../Components/uicomponents/TabletIcon';
import KiskoIcon from '../Components/uicomponents/customMaterialIcons/KiskoIcon';
import MobileDeviceIcon from '../Components/uicomponents/customMaterialIcons/MobileDevicesIcon';
import DrinkIcon from '../Components/uicomponents/customMaterialIcons/DrinkIcon';
import VisitorIcon from '../Components/uicomponents/VisitorIcon';
import OverviewIcon from '../Components/uicomponents/customMaterialIcons/OverviewIcon';
import SensorIcon from '../Components/uicomponents/customMaterialIcons/SensorIcon';
import Success from '../Components/uicomponents/customMaterialIcons/Success';
import TimeIcon from '../Components/uicomponents/customMaterialIcons/TimeIcon';
import StandardDesk from '../Components/uicomponents/customMaterialIcons/StandarDesk';
import Bin from '../Components/uicomponents/customMaterialIcons/Bin';
import AssignSpacesIcon from '../Components/uicomponents/customMaterialIcons/AssignSpacesIcon';
import AddIcon from '../Components/uicomponents/customMaterialIcons/AddIcon';
import WarningIcon from '../Components/uicomponents/customMaterialIcons/WarningIcon';
import EditIcon from '../Components/uicomponents/customMaterialIcons/EditIcon';
import TimerIcon from '../Components/uicomponents/customMaterialIcons/TimerIcon';
import CostCodeIcon from '../Components/uicomponents/customMaterialIcons/CostCodeIcon';
import AddAttendeeIcon from '../Components/uicomponents/customMaterialIcons/AddAttendeeIcon';
import HearingAidIcon from '../Components/uicomponents/customMaterialIcons/HearingAidIcon';
import PresentationAidIcon from '../Components/uicomponents/customMaterialIcons/PresentationAidIcon';
import CateringIcon from '../Components/uicomponents/customMaterialIcons/CateringIcon';

export const Icons =
{
    building: <BuildingIcon />,
    bar: <Bar />,
    benchSeating: <Benchseating />,
    bikeStore: <Bikestore />,
    boardroom: <Boardroom />,
    cafe: <Café />,
    changing: <Changing />,
    cleaningStation: <Cleaningstation />,
    coatStorage: <Coatstorage />,
    collaborationSpace: <Collaborationspace />,
    desk: <Desk />,
    drink: <Drink />,
    euipmentRoom: <Equipmentroom />,
    fault: <Fault />,
    firstaid: <Firstaid />,
    focusbooth: <Focusbooth />,
    gym: <Gym />,
    height: <Height />,
    indoorGarden: <Indoorgarden />,
    InformalLounge: <Informallounge />,
    informalMeeting: <Informalmeeting />,
    kitchenette: <Kitchenette />,
    lectureTheater: <Lecturetheatre />,
    liftLobby: <Liftlobby />,
    lightsOff: <Lightsoff />,
    lightsOn: <Lightson />,
    lockers: <Lockers />,
    meetingbooth: <Meetingbooth />,
    meetingRoom: <Meetingroom />,
    office: <Office />,
    pantry: <Pantry />,
    parcelLoceker: <Parcellocker />,
    parkingSpace: <Parkingspace />,
    phoneBooth: <Phonebooth />,
    plantRoom: <Plantroom />,
    postRoom: <Postroom />,
    prayer: <Prayer />,
    prayerRoom: <Prayerroom />,
    presentation: <Presentation />,
    printRoom: <Printroom />,
    privateRoom: <Privateroom />,
    quickStop: <Quickstop />,
    quiteRoom: <Quietroom />,
    reception: <Reception />,
    restaurant: <Restaurant />,
    security: <Security />,
    sitStandDesk: <Sitstanddesk />,
    specialistDesk: <Specialistdesk />,
    stationaery: <Stationery />,
    storage: <Storage />,
    temperature: <Temperature />,
    toilets: <Toilets />,
    toiletsAccessible: <Toiletsaccessible />,
    toiletsFemale: <Toiletsfemale />,
    toiletsMale: <Toiletsmale />,
    touchDown: <Touchdown />,
    trainingRoom: <Trainingroom />,
    treatmentRoom: <Treatmentroom />,
    vcRoom: <Vcroom />,
    waitingLounge: <Waitinglounge />,
    waterPoint: <Waterpoint />,
    wellbeing: <Wellbeing />,
    calenderIcon: <CalendarIcon />,
    dataPrivacyIcon: <DataPrivacyIcon />,
    notificationIcon: <NotificationIcon />,
    searchResultIcon: <SearchResultIcon />,
    securityIcon: <SecurityIcon />,
    taskIcon: <TaskIcon />,
    workingDayIcon: <WorkingDayIcon />,
    RightArrowIcon: <RightArrowIcon />,
    IdProvider: <IdProvider />,    CloseIcon: <CloseIcon />,
    DownAngleIcon: <DownAngleIcon />,
    upAngleIcon: <UpAngleIcon />,

    UserIcon: <UserIcon />,
    OrganisationIcon: < OrganisationIcon />,
    RegionIcon: <RegionIcon />,
    FloorIcon: <FloorIcon />,
    ZoneIcon: <ZoneIcon />,
    MobileIcon: <MobileIcon />,
    TabletIcon: <TabletIcon />,
    KiskoIcon: <KiskoIcon />,
    MobileDevice: <MobileDeviceIcon />,
    DrinkIcon: <DrinkIcon />,
    VisitorIcon: <VisitorIcon />,
    OverviewIcon: <OverviewIcon />,
    SensorIcon: <SensorIcon />,
    Success:<Success />,
    TimeIcon:<TimeIcon />,
    StandardDesk:<StandardDesk />,
    Bin:<Bin />,
    AssignSpacesIcon:<AssignSpacesIcon />,
    AddIcon: <AddIcon />,
    WarningIcon: <WarningIcon />,
    EditIcon: <EditIcon />,
    TimerIcon: <TimerIcon />,
    CostCodeIcon: <CostCodeIcon />,
    AddAttendeeIcon: <AddAttendeeIcon />,
    HearingAidIcon: <HearingAidIcon />,
    PresentationAidIcon: <PresentationAidIcon />,
    CateringIcon: <CateringIcon />
}
