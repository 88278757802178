import React, { Component } from 'react';
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import IbssDatePicker from './Datepicker/IbssDatePicker';
import { appContext } from "../../AppContext";

class IbssPageHeader extends Component<IPageHeaderProps>
{
    private get labels() { return appContext().labels; }

    public render(): JSX.Element
    {
        return (
            <>
                <Grid container rowSpacing={0} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                <Grid item md={6} sx={{pt:0}} >
                    <Box className="table-panel-header" component="div" sx={{ ml: '0',textOverflow: 'ellipsis',whiteSpace:'nowrap',overflow:'hidden',width:"100%" }}>{this.props.pageTitle}</Box>
                </Grid>
                        <>
                            <Grid item md={6} sx={{ display: 'flex', justifyContent: 'right',pt:0 }}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { ml: 1,my:1, width: '20ch' },
                                    }}
                                >
                                    <FormControl>
                                        <Select
                                            id="demo-simple-select"
                                            value={this.props.daysFilter}
                                            size="small"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={(e: SelectChangeEvent<string>) => this.props.todayChanged(e)}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        >
                                            <MenuItem value={this.labels.HubLabelToday}>{this.labels.HubLabelToday}</MenuItem>
                                            {
                                                this.props.additionalDateFilterOptions && this.props.additionalDateFilterOptions?.length > 0 &&
                                                this.props.additionalDateFilterOptions.map(label => { return (<MenuItem value={label}>{label}</MenuItem>) })
                                            }
                                            <MenuItem value={this.labels.HubLabelCustom}>{this.labels.HubLabelCustom}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <IbssDatePicker
                                        value={this.props.startDate}
                                        onChange={(event) => this.props.dateChanged(event as Date, this.labels.HubLabelStart)}
                                        renderInput={(params) =>
                                            {
                                            const { sx, ...paramsMinusSx } = params;
                                            return (
                                                <TextField
                                                    size="small"
                                                    {...paramsMinusSx}
                                                    sx={{
                                                        width: "auto",
                                                        "& legend": { display: "none" },
                                                        "& fieldset": { top: 0 },
                                                    }}
                                                    error={false}
                                                />
                                            );
                                        }}
                                        disabled={this.props.dateDisabled}
                                    />
                                    <IbssDatePicker
                                        value={this.props.endDate}
                                        onChange={(event) => this.props.dateChanged(event as Date, this.labels.HubLabelEnd)}
                                        renderInput={(params) =>
                                            {
                                            const { sx, ...paramsMinusSx } = params;
                                            return (
                                                <TextField
                                                    size="small"
                                                    {...paramsMinusSx}
                                                    sx={{
                                                        width: "auto",
                                                        "& legend": { display: "none" },
                                                        "& fieldset": { top: 0 },
                                                    }}
                                                    error={new Date(this.props.endDate) < new Date(this.props.startDate)}
                                                />
                                            );
                                        }}
                                        disabled={this.props.dateDisabled}
                                        minDate={this.props.startDate}
                                    />
                                </Box>
                            </Grid>
                            {
                                new Date(this.props.endDate) < new Date(this.props.startDate) &&
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right',pt:0 }}>
                                    <div className='text-danger' style={{ margin: 0 }} >{this.labels.HubLabelStartDateMustBeBeforeTheEndDate}</div>
                                </Grid>
                            }
                        </>
                </Grid>
            </>
        )
    }
};

export interface IPageHeaderProps
{
    pageTitle: string;
    daysFilter: string;
    todayChanged: (e: SelectChangeEvent<string>) => void;
    startDate: Date;
    endDate: Date;
    dateChanged: (e: Date, value: string) => void;
    dateDisabled: boolean,
    additionalDateFilterOptions?: string[],
}

export default IbssPageHeader;
