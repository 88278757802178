import { Component } from "react";
import "../../styles/css/dashboard.scss";
import "../../styles/css/header.scss";
import "../../App.css";
import Submenu from "../../Components/SubMenu";
import { connect } from "react-redux";
import apis from "../../Providers.Api/apis";
import "../../styles/css/form.scss";
import Inputbox from "../../Components/uicomponents/Inputbox";
import SelectBox from "../../Components/uicomponents/SelectBox";
import ConfirmModal from "../../Components/uicomponents/ConfirmModal";
import { MDBDataTable } from "mdbreact";
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import LoadingOverlay from "../../Components/LoadingOverlay"
import NoneIcon from '../../assets/images/securityRight/None.png';
import BasicIcon from '../../assets/images/securityRight/Basic.png';
import LocalIcon from '../../assets/images/securityRight/Local.png';
import DeepIcon from '../../assets/images/securityRight/Deep.png';
import GlobalIcon from '../../assets/images/securityRight/Global.png';
import { appContext } from "../../AppContext";
import { IUserDetails } from "../../Providers.LocalStorage/Models";
import IbssDialog from "../../Components/uicomponents/IbssDialog";
import IbssButtonRedo from '../../Components/uicomponents/IbssButton';
import Grid from '@mui/material/Grid';
import { Box, Typography } from "@mui/material";
import IbssButton from "../../Components/uicomponents/IbssButton";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IDynamicObject } from "../../Providers.Api/Models.UserApi";

class RolesDetails extends Component<any, any>
{
    private labels = appContext().labels;
    private alert = appContext().alert;
    private local = appContext().localStorageProvider;

    constructor(props: any)
    {
        super(props);
        this.state = {
            openJSONViewModal: false,
            isLoadingToSave: false,
            activePage: 0,
            updates: false,
            loading: false,
            title: "",
            showGroupID: 'none',
            rolesTabTableHeading: [this.labels.HubLabelName, this.labels.HubLabelIdentityproviders, ""],
            rolesTabthClasses: ["col-name th", "col-name th", "col-button th"],
            rolesTabtdClasses: ["col-name td", "col-name td", "col-button td"],
            rolesTabTableMessage: this.labels.HubLabelLoadingText,
            dataModelTabTableHeading: [this.labels.HubLabeleventTypeCol, this.labels.HubLabelCreateCol, this.labels.HubLabelReadCol, this.labels.HubLabelUpdateCol, this.labels.HubLabelDeleteCol, this.labels.HubLabelLockCol, this.labels.HubLabelUnlockCol, this.labels.HubLabelShareCol],
            dataModelTabthClasses: ["spaces-col-300 th", "spaces-col-100 th", "spaces-col-100 th", "spaces-col-100 th", "spaces-col-100 th", "spaces-col-100 th", "spaces-col-100 th", "spaces-col-100 th"],
            dataModelTabtdClasses: ["spaces-col-300 td", "spaces-col-100 td", "spaces-col-100 td", "spaces-col-100 td", "spaces-col-100 td", "spaces-col-100 td", "spaces-col-100 td", "spaces-col-100 td"],
            dataModelTabTableMessage: this.labels.HubLabelLoadingText,
            cirlceClassNames: { 0: "circle-icon circle-none-icon", 1: "circle-icon circle-quarter-icon", 2: "circle-icon circle-half-icon", 3: "circle-icon circle-three-quarter-icon", 4: "circle-icon circle-full-icon" },
            applicationActions: '{}', // empty string - "" isn't valid json, which is causing problems when we JSON.parse(applicationActions);
            canLockEvents: false,
            canUnlockEvents: false,
            concurrencyStamp: null,
            groupId: null,
            id: null,
            identityProviderId: null,
            identityProviderName: null,
            name: "",
            normalizedName: "",
            securityProviderId: null,
            roleId: 0,
            eventTypeId: 0,
            isOwner: 0,
            isUpdateRight: false,
            isDeleteRight: false,
            context: "",
            dataSecurityRole: [
                {
                    id: 0,
                    recursiveNodes: true,
                    eventTypeName: '',
                    create: 0,
                    read: 0,
                    update: 0,
                    delete: 0,
                    lock: 0,
                    unlock: 0,
                    append: 0,
                    appendTo: 0,
                    share: 0,
                },
            ],
            usersRole: [
                {
                    email: null,
                    identityProviderId: null,
                },
            ],
            providersNames: { 1: this.labels.HubLabelNative, 2: this.labels.HubLabelAAD, 3: "OKTA" },
            breadcrumbs: null,
            userID: null,
            show: false,
            isDelete: false,
            checked: [],
            checkedBuildingNode: [],
            extraapplicationActions: {},
            oldchecked: [],
            expanded: [],
            expandedBuildingNode: [],
            nodes: [],
            buildingNodes: [],
            treeEdit: false,
            allNodes: [],
            extraNodes: [],
            identityProviderIdList: [],
            appliesToList: {},
            selectedValue: "",
            finalSet: {},
            resetState: "",
        };
    }

    public componentDidMount(): void
    {
        const { match, history }: any = this.props;
        this.getAvailableRights();
        this.getBuildingNodes();
        if (sessionStorage.getItem("roleTitle") !== null || history.location.state === 'cancel')
        {
            this.setState({ activePage: 3 });
        }
        if (match.params.id != "0")
        {
            // match.params.id && 
            this.setState({
                id: match.params.id
            })
            this.getRightList();
            // this.getDetails(match.params.id);
            this.getIdentityProvidersDetails(match.params.id);
            this.getRoleDataSecurityDetails(match.params.id);
            this.getRolesDetails(match.params.id);
            this.getUserRoleDetails(match.params.id);
        } else
        {
            this.setState({
                title: this.labels.HubButtonNewRole,
                id: 0,
                isUpdateRight: true,
                context: this.labels.HubButtonNewRole,
            })
        }
        sessionStorage.removeItem("roleTitle")
        sessionStorage.removeItem("roleID")
        if (sessionStorage.getItem('userTitle') !== null)
        {
            this.setState({
                breadcrumbs: sessionStorage.getItem('userTitle'),
                userID: sessionStorage.getItem('userID')
            })
        }

        this.identityProviderAllList();
    }

    private identityProviderAllList()
    {
        apis.getIdentityProviders().then((res) =>
        {
            let tempidentityProviderIdList: any = [];
            for (let i = 0; i < res.data.length; i++)
            {
                let tempidentityProviderId = { title: "", value: i }
                tempidentityProviderId.title = res.data[i].name;
                tempidentityProviderId.value = res.data[i].id;
                tempidentityProviderIdList.push(tempidentityProviderId);
            }
            this.setState({
                identityProviderIdList: tempidentityProviderIdList
            })
        }).catch((error: any) => { });
    }

    private getAvailableRights()
    {

        apis.getAvailableRights().then((res) =>
        {

            let tempTree = [];
            let tempAllNodes = [];
            let tempExtraNodes = [];
            let keys = Object.keys(res.data)
            const index = keys.indexOf("APPLIESTO");
            if (index !== -1)
            {
                keys.splice(index, 1);
            }
            for (let i = 0; i < keys.length; i++)
            {
                let temp: any = { label: "", value: "", children: [] };
                temp.label = keys[i];
                temp.value = keys[i];
                let parentKeys: string[] | number[] = Object.keys(res.data[keys[i]]);
                for (let j = 0; j < parentKeys.length; j++)
                {
                    let subTemp: any = { label: "", value: "", children: [] };
                    subTemp.label = parentKeys[j];
                    subTemp.value = keys[i] + "_" + parentKeys[j];
                    tempExtraNodes.push(keys[i] + "_" + parentKeys[j]);
                    let childKeys = res.data[keys[i]][parentKeys[j]];
                    if (Array.isArray(childKeys))
                    {
                        for (let k = 0; k < childKeys.length; k++)
                        {
                            let childTemp = { label: "", value: "" };
                            childTemp.label = childKeys[k];
                            childTemp.value = keys[i] + "_" + parentKeys[j] + "_" + childKeys[k];
                            tempAllNodes.push(childTemp.value);
                            subTemp.children.push(childTemp)
                        }
                    }

                    temp.children.push(subTemp)
                }
                tempTree.push(temp);
            }
            this.setState({
                nodes: tempTree,
                allNodes: tempAllNodes,
                extraNodes: tempExtraNodes
            })

        }).catch((error: any) => { });

    }

    private async getBuildingNodes()
    {
        const res = this.local.getNodeData();
        let tempExtraNodes: Array<IParentNode> = [];
        let tempChildNodes: Array<IChildNode> = [];
        await res.Regions.map((node) =>
        {
            tempChildNodes = []
            node.Buildings.map((building) =>
            {
                tempChildNodes.push({ label: building.Name, value: building.Node_Id });
            })
            tempExtraNodes.push({ label: node.Node_Name, value: node.Node_Name, children: tempChildNodes });
        })
        this.setState({
            buildingNodes: tempExtraNodes,
        })

    }

    private getRightList()
    {
        const res = this.local.getIbssRightList();

        if (res.ADMINPORTAL && res.ADMINPORTAL.Roles && res.ADMINPORTAL.Roles.indexOf("Update") > -1) 
        {
            this.setState({
                isUpdateRight: true
            })
        }
        if (res.ADMINPORTAL && res.ADMINPORTAL.Roles && res.ADMINPORTAL.Roles.indexOf("Delete") > -1) 
        {
            this.setState({
                isDeleteRight: true
            })
        }
    }

    private getExtraApplicationAction(data: any)
    {
        if (data.length > 0) 
        {
            let tempData = JSON.parse(data);
            for (let i = 0; i < this.state.extraNodes.length; i++) 
            {
                let tempExtra: any = this.state.extraNodes[i] + "";
                tempExtra = tempExtra.split("_");
                if (tempExtra[0] && tempExtra[0] === "APPLIESTO") 
                {
                    if (tempData[tempExtra[0]]) 
                    {
                        delete tempData[tempExtra[0]];
                    }
                }
                else 
                {
                    if (tempData[tempExtra[0]] && tempData[tempExtra[0]][tempExtra[1]]) 
                    {
                        delete tempData[tempExtra[0]][tempExtra[1]];
                    }
                }

            }

            this.setState({
                extraapplicationActions: tempData
            })
        }
    }

    private isJSON(params: string)
    {
        try
        {
            let parseJSON: IDynamicObject = JSON.parse(params);
            if (!Array.isArray(parseJSON))
            {
                return parseJSON;
            }
            else
            {
                return false;
            }
        } 
        catch (e)
        {
            return false;
        }
    }

    private populateTrees(): void
    {
        let temp = JSON.parse(this.state.applicationActions);
        let tempChecked: Array<string> = [];
        tempChecked = this.state.checked;
        let keys: Array<string> = Object.keys(temp).sort();
        for (let i = 0; i < keys.length; i++) 
        {
            let parentKeys = Object.keys(temp[keys[i]]);
            for (let j = 0; j < parentKeys.length; j++) 
            {
                let childKeys = temp[keys[i]][parentKeys[j]];
                if (childKeys.indexOf("*") !== -1) 
                {
                    let searchKey = keys[i] + "_" + parentKeys[j] + "_";
                    for (let k = 0; k < this.state.allNodes.length; k++) 
                    {
                        let currentNode = this.state.allNodes[k] + "";
                        if (currentNode.includes(searchKey))
                        {
                            tempChecked.push(this.state.allNodes[k]);
                        }
                    }
                }
                else 
                {
                    for (let k = 0; k < childKeys.length; k++) 
                    {
                        let value = keys[i] + "_" + parentKeys[j] + "_" + childKeys[k];
                        tempChecked.push(value);
                    }
                }
            }
        }

        const editedJSON: Array<string> = tempChecked.filter((value: string) =>
        {
            if (!value.includes('APPLIESTO'))
            {
                const treesplit = value.split('_');
                const childValue = treesplit[2];
                if (this.state.applicationActions.includes(childValue))
                {
                    return value;
                }
            }
        })

        if (editedJSON.length > 0 || this.state.applicationActions === "" || this.state.applicationActions === "{}") 
        {
            this.setState({
                checked: editedJSON,
                treeEdit: true
            });
        }
        else 
        {
            this.setState({
                checked: editedJSON,
                treeEdit: false
            });
        }
    }

    private getIdentityProvidersDetails(id: string): void
    {
        apis.getIdentityProvidersRolesDetailsById(id).then((res) =>
        {
            this.setState({ ...res.data, context: `${res.data.identityProviderName} / ${res.data.name}` }, () =>
            {
                let temp = JSON.parse(res.data.applicationActions);
                if (temp && res.data.applicationActions.length > 0) 
                {
                    this.setState({ applicationActions: res.data.applicationActions });
                    if (temp.hasOwnProperty('APPLIESTO'))
                    {
                        this.setState({
                            appliesToList: temp['APPLIESTO']
                        });
                    }
                    this.getExtraApplicationAction(res.data.applicationActions);
                    let tempChecked: Array<string> = [];
                    tempChecked = this.state.checked;
                    let keys = Object.keys(temp)
                    for (let i = 0; i < keys.length; i++)
                    {
                        let parentKeys = Object.keys(temp[keys[i]]);
                        for (let j = 0; j < parentKeys.length; j++)
                        {
                            let childKeys = temp[keys[i]][parentKeys[j]];
                            if (childKeys.indexOf("*") !== -1) 
                            {
                                let searchKey = keys[i] + "_" + parentKeys[j] + "_";
                                for (let k = 0; k < this.state.allNodes.length; k++) 
                                {
                                    let currentNode = this.state.allNodes[k] + "";
                                    if (currentNode.includes(searchKey))
                                    {
                                        tempChecked.push(this.state.allNodes[k]);
                                    }
                                }
                            } else
                            {
                                for (let k = 0; k < childKeys.length; k++) 
                                {
                                    let value = keys[i] + "_" + parentKeys[j] + "_" + childKeys[k];
                                    tempChecked.push(value);
                                }
                            }
                        }
                    }

                    if (tempChecked.length > 0 || res.data.applicationActions === "" || res.data.applicationActions === "{}")
                    {
                        this.setState({
                            checked: tempChecked,
                            treeEdit: true
                        })
                    } else
                    {
                        this.setState({
                            checked: tempChecked,
                            treeEdit: false
                        })
                    }

                } else
                {
                    this.setState({
                        checked: []
                    }, () =>
                    {
                        if (res.data.applicationActions === "" || res.data.applicationActions === "{}")
                        {
                            this.setState({
                                treeEdit: true
                            })
                        } else
                        {
                            this.alert.show("", this.labels.HubLabelInvalidJSONMessage);
                        }
                    })
                }

            });
            this.setState({ loading: false });

        }).catch((error: any) => { });
    }

    private getRoleDataSecurityDetails(id: any)
    {
        apis.getRoleDataSecurityById(id).then((res) =>
        {
            res.data.sort(this.getSortOrder("eventTypeName"));
            if (res.data.length === 0)
            {
                this.setState({
                    dataSecurityRole: res.data,
                    loading: false,
                    dataModelTabTableMessage: this.labels.HublabelErrorEmptyData
                });
            } else
            {
                let divClasses: any = this.state.cirlceClassNames;
                for (let j = 0; j < res.data.length; j++)
                {
                    res.data[j] = {
                        ...res.data[j],
                        'createStatus': <div className={divClasses[res.data[j].create]} onClick={() => { this.updateRoles(res.data[j].create, j, res.data[j].id, res.data[j].eventTypeId, 'create') }}></div>,
                        'readStatus': <div className={divClasses[res.data[j].read]} onClick={() => { this.updateRoles(res.data[j].read, j, res.data[j].id, res.data[j].eventTypeId, 'read') }}></div>,
                        'updateStatus': <div className={divClasses[res.data[j].update]} onClick={() => { this.updateRoles(res.data[j].update, j, res.data[j].id, res.data[j].eventTypeId, 'update') }}></div>,
                        'deleteStatus': <div className={divClasses[res.data[j].delete]} onClick={() => { this.updateRoles(res.data[j].delete, j, res.data[j].id, res.data[j].eventTypeId, 'delete') }}></div>,
                        'lockStatus': <div className={divClasses[res.data[j].lock]} onClick={() => { this.updateRoles(res.data[j].lock, j, res.data[j].id, res.data[j].eventTypeId, 'lock') }}></div>,
                        'unlockStatus': <div className={divClasses[res.data[j].unlock]} onClick={() => { this.updateRoles(res.data[j].unlock, j, res.data[j].id, res.data[j].eventTypeId, 'unlock') }}></div>,
                        'shareStatus': <div className={divClasses[res.data[j].share]} onClick={() => { this.updateRoles(res.data[j].share, j, res.data[j].id, res.data[j].eventTypeId, 'share') }}></div>,
                    }
                }
                this.setState({
                    dataSecurityRole: res.data,
                    loading: false
                });
            }
        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        dataModelTabTableMessage: errorMessage
                    }, () =>
                    {
                        // @ts-ignore
                        dataModelTabTableEmptySettings.message = this.state.dataModelTabTableMessage
                    })
                }
            } else
            {
                this.setState({
                    dataModelTabTableMessage: errorMessage
                }, () =>
                {
                    // @ts-ignore
                    dataModelTabTableEmptySettings.message = this.state.dataModelTabTableMessage
                })
            }
        });
    }

    private getSortOrder(prop: any)
    {
        return function (obj1: any, obj2: any)
        {
            if (obj1[prop] > obj2[prop])
            {
                return 1;
            } else if (obj1[prop] < obj2[prop])
            {
                return -1;
            }
            return 0;
        }
    }

    private getUserRoleDetails(id: any)
    {

        apis.getUserRoleByRoleId(id).then((res) =>
        {
            const newRes = res.data && res.data[0] && res.data.map((item: any) =>
            {
                let userTypeStatus = "";
                if (item.userType === 0)
                {
                    userTypeStatus = "User"
                }
                if (item.userType === 1)
                {
                    userTypeStatus = "Application"
                }

                const newItem = {
                    ...item,
                    displayName: item.displayName ? item.displayName : item.email,
                    clickEvent: () => this.redirectUsers(item.id),
                    userType: userTypeStatus,
                    'Option': <button className="btn btn-success" onClick={() => this.redirectUsers(item.id)} >Edit </button>
                }
                return newItem
            })

            this.setState({
                usersRole: newRes ? newRes : []
            })
        }).catch((error: any) =>
        {
            let errorMessage = this.labels.HubLabelerrorSomethingWentWrong
            if (error.response)
            {
                if (error.response.statusText)
                {
                    errorMessage = error.response.statusText
                    this.setState({
                        rolesTabTableMessage: errorMessage
                    }, () =>
                    {
                        // @ts-ignore
                        rolesTabTableEmptySettings.message = this.state.rolesTabTableMessage
                    })
                }
            } else
            {
                this.setState({
                    rolesTabTableMessage: errorMessage
                }, () =>
                {
                    // @ts-ignore
                    rolesTabTableEmptySettings.message = this.state.rolesTabTableMessage
                })
            }
        });
    }

    private getRolesDetails(id: any)
    {
        apis.getRolesById(id).then((res) =>
        {
            this.setState({
                concurrencyStamp: res.data.concurrencyStamp,
                title: res.data.name
            })
        }).catch((error: any) => { });
    };

    private submit()
    {
        this.setState({ isLoadingToSave: true })
        const { match }: any = this.props;
        const { applicationActions, name, groupId, identityProviderId, isOwner, concurrencyStamp }: any = this.state;
        const validJSON = applicationActions? JSON.parse(applicationActions) : '{}'; // JSON.parse applicationActions only if it isn't ''.
        let payload;
        payload = {
            name: name,
            groupId: groupId,
            applicationActions: JSON.stringify(validJSON),
            identityProviderId: identityProviderId,
            concurrencyStamp: concurrencyStamp,
            isOwner: isOwner,
        };

        if (match.params.id === "0")
        {
            this.create(payload);
        } else
        {
            this.update(payload);
        }
    };

    private create(payload: any)
    {
        apis.createRole(payload).then((res) =>
        {
            const { history }: any = this.props;
            this.setState({
                updates: true,
                isLoadingToSave: false
            }, () =>
            {
                history.push("/security-roles/rolesdetails/" + res.data.id);
                window.location.reload();
            })
        }).catch((error: any) =>
        {
            this.setState({ isLoadingToSave: false })
        });;
    };

    private update(payload: any)
    {
        const { match, history }: any = this.props;
        const { updates }: any = this.state;
        const id = match.params.id;
        if (updates)
        {
            apis.updateRoleDataSecurityById(id, payload).then(() =>
            {
                apis.getRightsList().then((res) =>
                {
                    const user = this.local.getUserDetails()
                    const rightsData: IUserDetails = {
                        ...user,
                        userRights: res.data
                    }
                    this.local.setUserDetails(rightsData);

                    this.setState({ isLoadingToSave: false })
                    history.push("/security-roles");
                }).catch((error: any) =>
                {
                    this.setState({ isLoadingToSave: false })
                });

            }).catch((error: any) =>
            {
                this.setState({ isLoadingToSave: false })
            });
        } else
        {
            this.setState({ isLoadingToSave: false })
            history.push("/security-roles");
        }
    };

    private change(e: any)
    {
        this.setState({ updates: true })
        this.setState({ [e.target.name]: e.target.value });
    };

    private updateRoles(e: any, i: number, idRole: number, eventTypeId: number, property: string)
    {
        if (this.state.isUpdateRight === true)
        {
            const { match }: any = this.props;
            const { history }: any = this.props;
            const id = match.params.id;
            this.setState({ updates: true })
            let dataSecure: any = [...this.state.dataSecurityRole]
            let dataSecured: any = { ...dataSecure[i] }
            if (property === 'create') { if (dataSecured.create === 4) { dataSecured.create = 0 } else { dataSecured.create += 1 } }
            if (property === 'read') { if (dataSecured.read === 4) { dataSecured.read = 0 } else { dataSecured.read += 1 } }
            if (property === 'update') { if (dataSecured.update === 4) { dataSecured.update = 0 } else { dataSecured.update += 1 } }
            if (property === 'delete') { if (dataSecured.delete === 4) { dataSecured.delete = 0 } else { dataSecured.delete += 1 } }
            if (property === 'lock') { if (dataSecured.lock === 4) { dataSecured.lock = 0 } else { dataSecured.lock += 1 } }
            if (property === 'unlock') { if (dataSecured.unlock === 4) { dataSecured.unlock = 0 } else { dataSecured.unlock += 1 } }
            if (property === 'share') { if (dataSecured.share === 4) { dataSecured.share = 0 } else { dataSecured.share += 1 } }

            dataSecure[i] = dataSecured;
            this.setState({ dataSecure })
            this.setState({ dataSecurityRole: dataSecure })

            let payload = [
                {
                    "id": idRole,
                    "eventTypeId": eventTypeId,
                    "nodeIds": "1",
                    "recursiveNodes": true,
                    "create": dataSecure[i].create,
                    "read": dataSecure[i].read,
                    "update": dataSecure[i].update,
                    "delete": dataSecure[i].delete,
                    "lock": dataSecure[i].lock,
                    "unlock": dataSecure[i].unlock,
                    "append": 0,
                    "appendTo": 0,
                    "share": dataSecure[i].share
                }
            ]

            apis.updateRoleData(id, payload).then((res) =>
            {
                this.getRoleDataSecurityDetails(id);
            }).catch((error: any) => { });
        }
    }

    private isDelete()
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
        this.delete();
    }

    private cancelDeleteModal()
    {
        this.setState({
            isDelete: !this.state.isDelete,
            show: !this.state.show,
        })
    }

    private delete()
    {
        const { match }: any = this.props;
        const id = match.params.id;
        if (this.state.isDelete)
        {
            apis.deleteRoleById(id).then((res) =>
            {
                const { history }: any = this.props;
                history.push("/security-roles");
            }).catch((error: any) => { });
        }
    };

    private handleModal()
    {
        this.setState({
            show: !this.state.show,
        })
    }

    private cancel()
    {
        const { updates } = this.state
        const { history }: any = this.props;
        if (updates)
        {
            this.handleModal();
        } else
        {
            if (this.state.userID)
            {
                history.push("/security-users/userdetails/" + this.state.userID, 'cancel');
            } else
            {
                history.push("/security-roles");
            }
        }
    }

    private redirectUsers(id: any)
    {
        const { history, match }: any = this.props;
        const roleId = match.params.id;
        sessionStorage.setItem('roleID', match.params.id);
        sessionStorage.setItem('roleTitle', this.state.title);
        history.push("/security-users/userdetails/" + id, `user=${roleId}`);
    }


    private treeClick(node: OnCheckNode): void
    {
        this.setState({ updates: true })
        const changedValue = node.value.replaceAll('_', '.');
        if (node.checked && !node.children)
        {
            this.setState({ selectedValue: node.value })
        }
        else
        {
            this.setState({ selectedValue: "" })
        }
        let temp = JSON.parse(this.state.applicationActions);
        let object: IDynamicObject = this.state.appliesToList && Object.keys(this.state.appliesToList).length > 0 ? this.state.appliesToList : {};
        if (this.state.checked.length === 0) 
        {
            temp = {};
        }
        for (let i = 0; this.state.checked.length > i; i++) 
        {
            var str = "";
            str = this.state.checked[i];
            let arr = str.split("_");
            if (this.state.appliesToList && Object.keys(this.state.appliesToList).find((key) => key === node.value))
            {
                let appliesData: IDynamicObject = this.state.appliesToList;
                appliesData[changedValue] = this.state.checkedBuildingNode;
                object = appliesData;
            }
            else 
            {
                this.setState({
                    checkedBuildingNode: []
                })
            }
            {
                if (i !== 0) 
                {
                    if (temp[arr[0]] && temp[arr[0]][arr[1]]) 
                    {
                        if (!temp[arr[0]][arr[1]].find((key:string)=> key === arr[2]))
                        {
                            temp[arr[0]][arr[1]].push(arr[2]);
                        }
                    }
                    else 
                    {
                        if (temp[arr[0]]) 
                        {
                            temp[arr[0]][arr[1]] = [arr[2]];
                        }
                        else 
                        {
                            temp[arr[0]] = { [arr[1]]: [arr[2]] };
                        }
                    }
                } else
                {
                    if (temp[arr[0]])
                    {
                        temp[arr[0]][arr[1]] = [arr[2]];
                    } else
                    {
                        temp[arr[0]] = { [arr[1]]: [arr[2]] };
                    }
                }
            }
        }
        if (!node.checked)
        {
            const deletedList = this.state.appliesToList;
            const splitStr = changedValue.split('.');
            if (splitStr.length === 3 && temp[splitStr[0]])
            {
                if (temp[splitStr[0]][splitStr[1]].length === 1)
                {
                    delete temp[splitStr[0]][splitStr[1]];
                }
                else
                {
                    temp[splitStr[0]][splitStr[1]] = temp[splitStr[0]][splitStr[1]].filter((value:string)=> value !== splitStr[2]);
                }
            }
            if (deletedList && Object.keys(deletedList).length > 0 && deletedList[changedValue])
            {
              delete deletedList[changedValue];
            }
            temp['APPLIESTO'] = deletedList;
            object = deletedList;
        }
        this.setState({
            // if user has not checked any rights, then it is not necessary to have APPLIESTO property. 
            applicationActions: this.state.checked.length > 0 ? JSON.stringify(temp) : JSON.stringify({}), // JSON Formation Set
            appliesToList: object
        })
    }

    private mapBuildingNodes(clickedNode: OnCheckNode): void
    {
        const changedValue = clickedNode.value.replaceAll('_', '.');
        if (!clickedNode.children)
        {
            this.setState({ selectedValue: clickedNode.value })
            if (this.state.appliesToList?.hasOwnProperty(changedValue))
            {
                const temp = this.state.appliesToList[changedValue as keyof typeof this.state.appliesToList];
                this.setState({
                    checkedBuildingNode: [...temp]
                })
            }
            else
            {
                this.setState({
                    checkedBuildingNode: []
                })
            }
        }
        else
        {
            this.setState({
                selectedValue: "",
                checkedBuildingNode: [],
            })
        }
    }

    private buildingCheck(): void
    {
        const validJSON = JSON.parse(this.state.applicationActions);
        let listHolder: IDynamicObject = this.state.appliesToList && Object.keys(this.state.appliesToList).length > 0 ? this.state.appliesToList : {};
        const filteredList = this.state.checked?.filter((elem: string, index: number, self: Array<string>) => {
            return index === self.indexOf(elem);
        });
        const changedValue = this.state.selectedValue.replaceAll('_', '.');
        if (validJSON.hasOwnProperty('APPLIESTO'))
        {
            listHolder = validJSON['APPLIESTO'];
        }
        if (listHolder && this.state.selectedValue.replaceAll('.', '_') in Object.keys(listHolder))
        {
            listHolder[changedValue] = [...this.state.checkedBuildingNode];
            validJSON['APPLIESTO'] = { ...listHolder };
            this.setState({
                appliesToList: { ...listHolder },
                applicationActions: this.state.checked.length > 0? JSON.stringify(validJSON) : JSON.stringify({}), // if user has not checked any rights, then it is not necessary to have APPLIESTO property
                updates: true,
            });
        }
        else
        {
            listHolder[changedValue] = [...this.state.checkedBuildingNode];
            const isValueExist = filteredList.find((value: string) => value === this.state.selectedValue );
            if (isValueExist)
            {
                this.setState({
                    appliesToList: { ...this.state.appliesToList, ...listHolder },
                    updates: true,
                });
            }
            else
            {
                const checkedList = [...filteredList, `${this.state.selectedValue}`].filter(function(elem, index, self) {
                    return index === self.indexOf(elem);
                });
                validJSON['APPLIESTO'] = { ...listHolder };
                if (checkedList)
                {
                    let data = checkedList.find((key) => key === this.state.selectedValue)?.split('_');
                    let parentKey :string = data[0];
                    let childKey :string = data[1]; 
                    let nonChildKey :string = data[2];
                    if (validJSON && Object.keys(validJSON).find((key) => key === data[0]))
                    {
                        const isValueExist = validJSON[parentKey][childKey] && validJSON[parentKey][childKey].find((value: string) => value === nonChildKey)
                        if (!isValueExist)
                        {
                            validJSON[parentKey][childKey] = validJSON[parentKey][childKey] ? [...validJSON[parentKey][childKey], nonChildKey] : [nonChildKey];
                        }
                    }
                    else
                    {   
                        validJSON[parentKey] = {};
                        validJSON[parentKey][childKey] = [nonChildKey];
                    }
                }

                this.setState({
                    checked: checkedList,
                    updates: true,
                    appliesToList: listHolder
                });
            }
            validJSON['APPLIESTO'] = { ...listHolder };
            this.setState({ applicationActions: this.state.checked.length > 0 ? JSON.stringify(validJSON): JSON.stringify({})}); // if user hasn't selected any rights, there is no appliesTo section to the non existent rights.
        }
    }

    private saveJSON(): void
    {
        if (this.isJSON(this.state.applicationActions))
        {
            const applicationActions = JSON.parse(this.state.applicationActions);
            this.populateTrees();
            this.setState({ appliesToList: applicationActions['APPLIESTO'] });
            this.setState({ openJSONViewModal: false });
        }
    }

    private openJSONModal(): void
    {
        this.setState({ openJSONViewModal: true, resetState: this.state.applicationActions });
    }

    private get prettyApplicationActions(): string
    {
        try
        {
            return JSON.stringify(JSON.parse(this.state.applicationActions), null, 4);
        }
        catch
        {
            return this.state.applicationActions;
        }
    }

    public render(): JSX.Element
    {
        const { history }: any = this.props;
        let {
            dataSecurityRole,
            showGroupID,
            usersRole,
            providersNames,
            cirlceClassNames,
            name,
            identityProviderId,
            groupId,
            rolesTabTableHeading,
            rolesTabtdClasses,
            rolesTabthClasses,
            dataModelTabTableHeading,
            dataModelTabthClasses,
            dataModelTabtdClasses,
            dataModelTabTableMessage,
            rolesTabTableMessage
        }: any = this.state;

        const rolesTabTableEmptySettings = {
            columns: rolesTabTableHeading.length,
            thClasses: rolesTabthClasses,
            heading: rolesTabTableHeading,
            style: [],
            message: rolesTabTableMessage,
            dashboard: 1,
        };

        const dataModelTabTableEmptySettings = {
            columns: dataModelTabTableHeading.length,
            thClasses: dataModelTabthClasses,
            heading: dataModelTabTableHeading,
            style: [],
            message: dataModelTabTableMessage,
            dashboard: 1,
        };

        let rolesTabRowData: any = [];

        const dataUsersRole = {
            columns: [
                {
                    label: this.labels.HubLabelName,
                    field: "displayName",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelIdentityprovider,
                    field: "identityProviderName",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabelType,
                    field: "securityProviderTypeName",
                    sort: "",
                    width: 50
                },
                {
                    label: this.labels.HubLabeluserType,
                    field: "userType",
                    sort: "",
                    width: 50
                },
                {
                    label: "",
                    field: "",
                    sort: "disabled",
                },
            ],
            rows: usersRole
        };

        const dataDataSecurityRole = {
            columns: [
                {
                    label: this.labels.HubLabeleventTypeCol,
                    field: "eventTypeName",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelCreateCol,
                    field: "createStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelReadCol,
                    field: "readStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelUpdateCol,
                    field: "updateStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelDeleteCol,
                    field: "deleteStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelLockCol,
                    field: "lockStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelUnlockCol,
                    field: "unlockStatus",
                    sort: "disabled",
                    width: 50
                },
                {
                    label: this.labels.HubLabelShareCol,
                    field: "shareStatus",
                    sort: "disabled",
                    width: 50
                },
            ],
            rows: dataSecurityRole
        };

        let rolesTabTableSettings: any = {};
        if (rolesTabRowData.length !== 0)
        {
            rolesTabTableSettings = {
                columns: rolesTabTableHeading.length,
                thClasses: rolesTabthClasses,
                tdClasses: rolesTabtdClasses,
                rows: rolesTabRowData.length,
                data: rolesTabRowData,
                heading: rolesTabTableHeading,
                style: [],
                dashboard: 0,
            };
        }

        if (identityProviderId !== 1)
        {
            showGroupID = 'block'
        }

        const nameFld = {
            name: "name",
            label: this.labels.HubLabelName,
            value: name,
            mandatory: true,
            placeholder: "Provide Name",
            onchange: (e: any) => this.change(e),
        };
        const IdentityProviderTypeFld = {
            name: "identityProviderType",
            label: this.labels.HubLabelIdentityProviderType,
            value: identityProviderId,
            mandatory: true,
            disabled: this.state.id !== 0,
            onchange: (value: any) =>
            {
                this.setState({
                    identityProviderId: value,
                    updates: true
                });
            },
            type: "option",
            options: this.state.identityProviderIdList,
            placeholder: "Provide value",
        };
        const confModalData = this.state.isDelete ? {
            name: this.state.name,
            show: this.state.show,
            handleModal: () => this.cancelDeleteModal(),
            okButton: () => this.delete(),
            modalHeading: this.labels.HubLabelConfirmation,
            modalMessage: this.labels.HubLabelDeleteMessage,
        }
            : {
                name: this.state.name,
                show: this.state.show,
                handleModal: () => this.handleModal(),
                okButton: () => { history.push("/security-roles") },
                modalHeading: this.labels.HubLabelConfirmation,
                modalMessage: this.labels.HubLabelConfirmCancelMessage,
            }

        const identityProviderGroupIdField = {
            name: "groupId",
            label: this.labels.HubLabelIdentityProviderGroupID,
            value: groupId,
            mandatory: true,
            placeholder: "00000000-0000-0000-0000-00000000000",
            onchange: (e: any) => this.change(e)
        };
        const props = {
            ...this.props,
            submenu: [
                {
                    title: this.labels.HubLabelRoleDetailsSubMenu,
                    tabNo: 0,
                    active: this.state.activePage === 0 ? true : false,
                },
                {
                    title: this.labels.HubLabelsppSettingsSubMenuTitle,
                    tabNo: 1,
                    active: this.state.activePage === 1 ? true : false,
                },
                {
                    title: this.labels.HubLabelDataSecuritySubMenuTitle,
                    tabNo: 2,
                    active: this.state.activePage === 2 ? true : false,
                    display: this.state.id !== 0 ? 'block' : 'none',
                },
                {
                    title: this.labels.HubLabelUsers,
                    tabNo: 3,
                    active: this.state.activePage === 3 ? true : false,
                    display: this.state.id !== 0 ? 'block' : 'none',
                },
            ],
            activePageValue: this.state.activePage,
            childValue: (i: any) =>
            {
                this.setState({ activePage: i });
            },
        };
        return (
            <>
                {this.state.isLoadingToSave && <LoadingOverlay />}
                <div className="rightPanel">
                    <Submenu {...props} />
                    <div className="rightPanel-main-content">
                        <div className="table-panel p-30 ">
                            {this.state.show ? (
                                <ConfirmModal {...confModalData} />
                            ) : ""}
                            {this.state.loading && (<div className="row">{this.labels.HubLabelLoadingText}</div>)}

                            {/* {this.state.breadcrumbs !== null ?
                <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/security-users/userdetails/` + this.state.userID }}>{this.state.breadcrumbs}</Link> / <Link className="breadcrumbs" to={{ pathname: `security-roles` }}>{this.labels.HubLabelRoles}</Link></div>
                :
                <div className="breadcrumbs"><Link className="breadcrumbs" to={{ pathname: `/security-users` }}>{this.labels.HubLabelRoles}</Link></div>
              } */}
                            <Typography variant="h4" gutterBottom>
                                {this.state.title}
                            </Typography>
                            <Typography gutterBottom>
                                {this.labels.HubLabelUserPermissionDescription}
                            </Typography>
                            {/* <div className="mb-1"></div>
                <div className="mb-1"></div> */}
                            <div className="d-flex flex-row flex-wrap">
                                {this.state.activePage == 2 &&
                                    <div className="securityIcons">
                                        <div className="securityIcon-item">
                                            <p className="securityIcon-title">{this.labels.HubLabelNoneSecurityIcon}</p>
                                            <img className="securityIcon-image" src={NoneIcon} alt="NoneIcon"></img>
                                        </div>
                                        <div className="securityIcon-item">
                                            <p className="securityIcon-title">{this.labels.HubLabelBasicSecurityIcon}</p>
                                            <img className="securityIcon-image" src={BasicIcon} alt="BasicIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelLocalSecurityIcon}</p>
                                            <img className="securityIcon-image" src={LocalIcon} alt="LocalIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelDeepSecurityIcon}</p>
                                            <img className="securityIcon-image" src={DeepIcon} alt="DeepIcon"></img>
                                        </div>
                                        <div className="securityIcon-item" >
                                            <p className="securityIcon-title">{this.labels.HubLabelGlobalSecurityIcon}</p>
                                            <img className="securityIcon-image" src={GlobalIcon} alt="GlobalIcon"></img>
                                        </div>
                                    </div>}
                            </div>

                            {this.state.activePage === 0 && (
                                <div className="main-content" >
                                    {this.state.loading || (
                                        <div className="card card-scroller">
                                            <div className="p-45">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-6 pr-2">
                                                            <div className="input-controls col-12">
                                                                <Inputbox {...nameFld} />
                                                            </div>
                                                            <div className="input-controls col-12">
                                                                <SelectBox {...IdentityProviderTypeFld} />
                                                            </div>
                                                            <div className="input-controls col-12" style={{ display: showGroupID }}>
                                                                <Inputbox {...identityProviderGroupIdField} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="input-controls">
                                                                <label>{this.labels.HubLabelIsOwner}</label>
                                                            </div>
                                                            <div className="switch-field" >
                                                                <input
                                                                    type="radio"
                                                                    id="radio-one"
                                                                    name="switch-one"
                                                                    defaultValue="Disabled"
                                                                    checked={this.state.isOwner === 0}
                                                                    onChange={() => { this.setState({ isOwner: 0 }); }}
                                                                />
                                                                <label htmlFor="radio-one">
                                                                    {this.labels.HubLabelDisabled}
                                                                </label>
                                                                <input
                                                                    type="radio"
                                                                    id="radio-two"
                                                                    name="switch-one"
                                                                    defaultValue="Enabled"
                                                                    checked={this.state.isOwner === 1}
                                                                    onChange={() => { this.setState({ isOwner: 1 }); }}
                                                                />
                                                                <label htmlFor="radio-two">
                                                                    {this.labels.HubLabelEnabled}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {this.state.activePage === 1 && (
                                <div className="main-content">
                                    {this.state.loading || (
                                        <div className="card card-scroller">
                                            {/* <div className=""> */}
                                            <form>
                                                <Grid container spacing={2} sx={{ pl: 2, mt: 3 }}>
                                                    <Grid xs={6}>
                                                        <Box sx={{ pr: 2 }}>
                                                            <Typography component="div" sx={{ fontWeight: 'bold', fontSize: '18' }}>
                                                                {this.labels.HubLabelUserPermissionCol1}
                                                            </Typography>
                                                            <Typography color="secondary" component="div" sx={{ mt: 1 }}>{this.labels.HubLabelUserPermissionDescriptionCol1}</Typography>
                                                            {/* <div className=""><label></label></div> 
                              <div className="mt-1"><label></label></div>*/}
                                                            <hr />
                                                            <Box sx={{ my: 2 }} className="multicheck-height">
                                                                {this.state.treeEdit ?
                                                                    <CheckboxTree
                                                                        icons={{
                                                                            check: <CheckBoxIcon color="primary" />,
                                                                            uncheck: <CheckBoxOutlineBlankIcon />,
                                                                            expandClose: <ArrowRightIcon />,
                                                                            expandOpen: <ArrowDropDownIcon />,
                                                                            halfCheck: <CheckBoxIcon color="primary" />,
                                                                        }}
                                                                        nodes={this.state.nodes}
                                                                        checked={this.state.checked}
                                                                        expanded={this.state.expanded}
                                                                        showNodeIcon={false}
                                                                        onCheck={(checked, node) => this.setState({ checked }, () => { this.treeClick(node) })}
                                                                        onExpand={expanded => this.setState({ expanded })}
                                                                        onClick={clickedNode => this.mapBuildingNodes(clickedNode)}
                                                                    />
                                                                    :
                                                                    <CheckboxTree
                                                                        icons={{
                                                                            check: <CheckBoxIcon color="primary" />,
                                                                            uncheck: <CheckBoxOutlineBlankIcon />,
                                                                            expandClose: <ArrowRightIcon />,
                                                                            expandOpen: <ArrowDropDownIcon />,
                                                                            halfCheck: <CheckBoxIcon color="primary" />,
                                                                        }}
                                                                        nodes={this.state.nodes}
                                                                        expanded={this.state.expanded}
                                                                        showNodeIcon={false}
                                                                        onExpand={expanded => this.setState({ expanded })}
                                                                    />
                                                                }
                                                            </Box>
                                                            <hr />
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box sx={{ pr: 2 }}>
                                                            <Typography component="div" sx={{ fontWeight: 'bold', fontSize: '18' }}>
                                                                {this.labels.HubLabelUserPermissionCol2}</Typography>
                                                            <Typography color="secondary" component="div" sx={{ mt: 1 }}>{this.labels.HubLabelUserPermissionDescriptionCol2}</Typography>
                                                            <hr />
                                                            {this.state.selectedValue && this.state.selectedValue !== "" &&
                                                                <Box sx={{ my: 2 }} className="multicheck-height">
                                                                    {this.state.treeEdit ?
                                                                        <CheckboxTree
                                                                            icons={{
                                                                                check: <CheckBoxIcon color="primary" />,
                                                                                uncheck: <CheckBoxOutlineBlankIcon />,
                                                                                expandClose: <ArrowRightIcon />,
                                                                                expandOpen: <ArrowDropDownIcon />,
                                                                                halfCheck: <CheckBoxIcon color="primary" />,
                                                                            }}
                                                                            nodes={this.state.buildingNodes}
                                                                            checked={this.state.checkedBuildingNode}
                                                                            expanded={this.state.expandedBuildingNode}
                                                                            showNodeIcon={false}
                                                                            onCheck={checkedBuildingNode => this.setState({ checkedBuildingNode }, () => { this.buildingCheck() })}
                                                                            onExpand={expandedBuildingNode => this.setState({ expandedBuildingNode })}
                                                                        />
                                                                        :
                                                                        <CheckboxTree
                                                                            icons={{
                                                                                check: <CheckBoxIcon color="primary" />,
                                                                                uncheck: <CheckBoxOutlineBlankIcon />,
                                                                                expandClose: <ArrowRightIcon />,
                                                                                expandOpen: <ArrowDropDownIcon />,
                                                                                halfCheck: <CheckBoxIcon color="primary" />,

                                                                            }}
                                                                            nodes={this.state.buildingNodes}
                                                                            expanded={this.state.expandedBuildingNode}
                                                                            showNodeIcon={false}
                                                                            onExpand={expandedBuildingNode => this.setState({ expandedBuildingNode })}
                                                                        />
                                                                    }
                                                                    <hr />
                                                                </Box>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                            {/* </div> */}
                                        </div>
                                    )}
                                </div>
                            )}


                            {this.state.activePage === 2 && (
                                <div className="main-content">
                                    {this.state.loading && (
                                        <div className="row">{this.labels.HubLabelLoadingText}</div>
                                    )}
                                    {this.state.loading || (
                                        <div className="row">
                                            {/* <div className="col-12"> */}
                                            {/* <div className="card"> */}
                                            <MDBDataTable className="customsizeRoleInnerCol7 tableDataSecurity" paging={false} searching={false} data={dataDataSecurityRole} />
                                            {/* </div> */}
                                            {/* </div> */}
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.state.activePage === 3 && (
                                <div className="main-content">
                                    {this.state.loading && (
                                        <div className="row">{this.labels.HubLabelLoadingText}</div>
                                    )}
                                    {this.state.loading || (
                                        <div className="row">
                                            {/* <div className="col-12"> */}
                                            <MDBDataTable className="standardTable tableTabButtons tableUsers tableDataSecurity" displayEntries={false} entries={50} hover data={dataUsersRole} />
                                            {/* </div> */}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <Box className=" space-between mt-3">
                            <Box >
                                {this.state.activePage === 1 &&
                                    <IbssButton variant='outlined' className="border-none" sx={{ backgroundColor: 'transparent', border: 'none', textDecoration: "underline", color: "var(--ui-text-alternate)" }} onClick={() => this.openJSONModal()}>{this.labels.HubLabelButtonJSONView}</IbssButton>
                                }
                            </Box>
                            <Box >
                                <IbssButton variant="contained" color="secondary" sx={{ mr: 2 }} onClick={() => this.cancel()}>{this.labels.HubButtonCancel}</IbssButton>
                                <IbssButton variant="contained" sx={{ ml: 2 }} disabled={!this.state.isUpdateRight} onClick={() => this.submit()}>{this.labels.HubButtonSave}</IbssButton>
                            </Box>
                        </Box>
                    </div>
                </div>
                <IbssDialog
                    open={this.state.openJSONViewModal}
                    onClose={() => this.setState({ openJSONViewModal: false, applicationActions: this.state.resetState })}
                    fullWidth
                    header={this.labels.HubLabelJSONView}
                    content=
                    {
                        <>
                            <label>{this.labels.HubLabelJSONViewDescription}</label>
                            <div className="nonePointer">
                                <textarea
                                    name="applicationActions"
                                    onChange={e => this.change(e)}
                                    className="textAreaUserDetails"
                                    value={this.prettyApplicationActions} />
                            </div>
                            {!this.isJSON(this.state.applicationActions) &&
                                <div className="w-50 mx-auto text-danger my-2">
                                    {/* Error message will change here */}
                                    Invalid JSON Structure. Please check
                                </div>
                            }
                        </>
                    }
                    footer=
                    {
                        <>
                            <IbssButtonRedo
                                color='primary'
                                variant='contained'
                                disabled={!this.isJSON(this.state.applicationActions)}
                                onClick={() => this.saveJSON()}
                            >
                                {this.labels.HubLabelOk}
                            </IbssButtonRedo>
                        </>
                    }
                />
            </>
        );
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProvidersDetails,
        mainPageTitle: state.mainPageTitle,
    }
}

export default connect(mapStateToProps)(RolesDetails);

export interface IParentNode
{
    label: string;
    value: string;
    children: object;
}

export interface IChildNode
{
    label: string;
    value: number;
}
