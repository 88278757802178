import { Component } from "react";
import "../../../styles/css/table.scss";
import "../../../App.css";
import { connect } from "react-redux";
import apis from "../../../Providers.Api/apis";
import { MDBDataTable } from "mdbreact";
import { dateConverter, getBuildingTimeZoneByNodeId } from "../../../Common/Helper";
import Spinner from "../../../Components/Spinner";
import TablePanelInfo from "../../../Components/table/TablePanelInfo";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link, RouteComponentProps, RouterProps, generatePath } from "react-router-dom";
import { IPartialAppState, appContext } from "../../../AppContext";
import { DateHelper } from "../../../Common/DateHelper";
import IbssDataGrid from "../../../Components/uicomponents/IbssDataGrid";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import IbssFormControl from "../../../Components/uicomponents/IbssFormControl";
import IbssTextField from "../../../Components/uicomponents/IbssTextField";
import IbssPageHeader from "../../../Components/uicomponents/IbssPageHeader";
import { DateTime } from "luxon";
import IbssButtonRedo from "../../../Components/uicomponents/IbssButton";
import IbssSvgIcon from "../../../Components/uicomponents/IbssSvgIcon";
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import IbssActionButton, { IActionButton } from "../../../Components/uicomponents/IbssActionButton";
import AddIcon from '@mui/icons-material/Add';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Autocomplete, Box, Grid, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import { IVisit } from "../../../Providers.Api/Models"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import IbssDialog from "../../../Components/uicomponents/IbssDialog";
import ApiMessageBuilder from "../../../Providers.Api/ApiMessageBuilder";
import { IDispatch, IPropsFromState } from "../../../redux/Interfaces";
import PrintPass from "../../../Components/PrintPass";
import IbssChip from "../../../Components/uicomponents/IbssChip";
import IbssAutocomplete from "../../../Components/uicomponents/IbssAutocomplete";
import BookingStatus from "../../../Components/BookingStatus";
import { ParameterRepository } from "../../../Providers.Api/Parameters/ParameterRepository";
import TimeZoneOffset from "../../../Components/TimeZoneOffset";
const html2pdf = require('html2pdf.js');

class ListVisits extends Component<IProps, IState>
{
    private get alert() { return appContext().alert; }
    private get appState() { return appContext().state; }
    private get local() { return appContext().localStorageProvider; }
    private get apiClient() { return appContext().apiClient; }
    private get labels() { return appContext().labels; }
    private apiMessageBuilder = new ApiMessageBuilder();

    constructor(props: IProps)
    {
        super(props);

        this.state =
        {
            isLoading: false,
            myVisit: [],
            canExport: false,
            canCreateVisits: false,
            canUpdateVisits: false,
            canApproveVisits: false,
            canDenyVisits: false,
            canCancelVisits: false,
            canCheckIntoVisits: false,
            canCheckOutOfVisits: false,
            activePage: "0",
            buildingid: this.appState.buildingId,
            searchTerm: "",
            daysFilter: "",
            tableData: [],
            bookingStartDate: DateHelper.now().toJSDate(),
            bookingEndDate: DateHelper.now().toJSDate(),
            filterStatusOption: [
                { label: this.labels.HubTabAwaitingApproval, value: "Awaiting Approval" },
                { label: this.labels.HubLabelCancelled, value: "Cancelled" },
                { label: this.labels.HubLabelDeniedLabel, value: "Denied" },
                { label: this.labels.HubLabelApproved, value: "Approved" },
                { label: this.labels.HubLabelCheckedIn, value: "Checked In" },
                { label: this.labels.HubLabelCheckedOut, value: "Checked Out" },
                { label: this.labels.HubLabelNoShow, value: "No Show" },
                { label: this.labels.HubLabelAutoCancelledStatus, value: "Auto Cancelled" },
            ],
            userList: [{ label: "", email: "", }],
            selectedHostObject: { label: "", email: "" },
            filterValue: "",
            dateDisabled: true,
            cancelAllRowsEnable: true,
            selectedRows: [],
            isCompleteLoading: false,
            urlParameter: "",
            cancelButtonEnabled: false,
            approveButtonEnabled: false,
            denyButtonEnabled: false,
            checkInButtonEnabled: false,
            checkOutButtonEnabled: false,
            printPassButtonEnabled: false,
            showDeny: false,
            comment: "",
            denyComment: "",
            fileImporting: false,
            isFileUpload: false,
            file: { name: "" },
            visitors: [],
            showPrintPass: false,
            isFilter: false,
        }
    }

    public async componentDidMount(): Promise<void>
    {
        const { history } = this.props;
        this.appState.subscribe(this, (i) => this.appStateChanged(i));
        const url = window.location.href;
        const path = new URL(url).pathname;
        this.setState({ urlParameter: path });

        if (path === "/flex-my-visitor")
        {
            this.setState({
                daysFilter: this.labels.HubLabelThisWeek,
                bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
            });
        }
        else 
        {
            this.setState({
                bookingStartDate: DateHelper.now().toJSDate(),
                bookingEndDate: DateHelper.now().toJSDate(),
                daysFilter: this.labels.HubLabelToday
            });
        }

        const buildingId = this.props.match.params["buildingid"];
        if (buildingId !== undefined)
        {
            await this.appState.set({ buildingId: parseInt(buildingId) });
            if (this.props.match.params.tab)
            {
                this.setState({
                    activePage: this.props.match.params.tab,
                });
            }

            if (this.props.match.params.buildingid !== "0")
            {
                apis.getSpacesById(this.props.match.params.buildingid, "Building");
            }
            else
            {
                this.alert.show("", this.labels.HubLabelSelectBuildingError);
            }

            if (
                this.state.buildingid &&
                this.props.match.params.buildingid !== null &&
                this.state.buildingid !== parseInt(this.props.match.params.buildingid)
            )
            {
                history.push(
                    "/operational-services-visitors/" +
                    this.state.buildingid +
                    "/" +
                    this.state.activePage
                );
            }
            else
            {
                if (this.state.buildingid && this.props.match.params.filter)
                {
                    history.push(
                        "/operational-services-visitors/" +
                        this.state.buildingid +
                        "/" +
                        this.props.match.params.tab +
                        "/" +
                        this.props.match.params.filter
                    );
                }
                else if (this.state.buildingid)
                {
                    history.push(
                        "/operational-services-visitors/" +
                        this.state.buildingid +
                        "/" +
                        this.state.activePage
                    );
                }
                else
                {
                    history.push("/operational-services-buildings/0");
                }
            }
            await this.loadRights();
            await this.getDetails();
        }
        else
        {
            await this.loadRights();
            await this.getDetails();
        }
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: IState, snapshot?: IState): void
    {
        if (prevState.buildingid !== this.state.buildingid)
        {
            this.getDetails();
        }
    }

    private loadRights(): void
    {
        this.setState
        ({
            canExport: this.local.hasRight("API.Visits.Export"),
            canCreateVisits: this.local.hasRight("API.Visits.Create"),
            canUpdateVisits: this.local.hasRight("API.Visits.Update"),
            canApproveVisits: this.local.hasRight("API.Visits.Approve"),
            canDenyVisits: this.local.hasRight("API.Visits.Deny"),
            canCancelVisits: this.local.hasRight("API.Visits.Cancel"),
            canCheckIntoVisits: this.local.hasRight("API.Visits.CheckIn"),
            canCheckOutOfVisits: this.local.hasRight("API.Visits.CheckOut"),
        });
    }

    private async appStateChanged(state: IPartialAppState): Promise<void>
    {
        const { history } = this.props;
        if (state.buildingId !== undefined)
        {
            this.setState({ buildingid: state.buildingId }, () =>
            {
                const buildingId = state.buildingId;
                const path = generatePath(this.props.match.path, {
                    buildingid: buildingId,
                    tab: this.props.match.params.tab,
                });
                history.replace(path);
            });
        }
    }

    private async getDetails(): Promise<void>
    {
        if (this.state.urlParameter === "/flex-my-visitor")
        {
            const payload = {
                startDate: DateTime.fromISO(dateConverter(this.state.bookingStartDate)).startOf('day').toUTC(),
                endDate: DateTime.fromISO(dateConverter(this.state.bookingEndDate)).endOf('day').toUTC()
            }
            const nodeId = "1";
            const email = this.local.getUserDetails().email;

            await apis.getMyVisitors(nodeId, email, payload.startDate, payload.endDate).then((res) =>
            {
                this.setState({ isCompleteLoading: false })
                const newArray = res.data.map((item: IVisit) => ({
                    id: item.Visit_Id,
                    ...item,
                }));
                this.setState({
                    myVisit: (newArray && newArray[0]) ? newArray : [],
                    tableData: newArray
                });
            }).catch((error) =>
            {
                this.setState({ isCompleteLoading: false })
            });
        }
        else 
        {
            this.setState({ isCompleteLoading: true })
            const payload = {
                buildingId: this.appState.buildingId,
                startDate: this.fromDateToString(this.state.bookingStartDate, "start"),
                endDate: this.fromDateToString(this.state.bookingEndDate, "end")
            }

            await apis.getTodaysVisitors(payload.buildingId, payload.startDate, payload.endDate).then((res) =>
            {
                this.setState({ isCompleteLoading: false })
                const newArray = res.data.map((item: IVisit) => ({
                    id: item.Visit_Id,
                    ...item,
                }));
                this.setState({
                    visitors: (newArray && newArray[0]) ? newArray : [],
                    tableData: newArray
                });
            }).catch((error) =>
            {
                this.setState({ isCompleteLoading: false })
            });
        }
    }

    private searchTableData(searchTerm: string): IVisit[] 
    {
        const searchResults = this.state.tableData.filter((row: any) =>
        {
            for (const key in row)
            {
                if (row[key].toString().includes(searchTerm))
                {
                    return true;
                }
            }
            return false;
        });
        return searchResults;
    }

    private fromDateToString(date: Date, type: "start" | "end"): string
    {
        if (type === "start")
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.appState.buildingId)).startOf('day').toUTC().toISO();
        }
        else
        {
            return DateTime.fromISO(dateConverter(date)).setZone(getBuildingTimeZoneByNodeId(this.appState.buildingId)).endOf('day').toUTC().toISO();
        }
    }

    private selectionChanged(selection: GridRowSelectionModel): void
    {
        const selectedRows = this.searchTableData(this.state.searchTerm).filter(row => selection.includes(row.Visit_Id));
        const cancellableRows = selectedRows.filter(i => i.Visit_Status == "New" || i.Visit_Status == "Amended" || i.Visit_Status == "Late Checkin" || i.Visit_Status == "Awaiting Approval" || i.Visit_Status == "Approved");
        const approvableRows = selectedRows.filter(i => i.Visit_Status == "Awaiting Approval");
        const checkInableRows = selectedRows.filter(i => i.Visit_Status == "Approved");
        const checkOutableRows = selectedRows.filter(i => i.Visit_Status == "Checked In");
        const printableRows = selectedRows.filter(i => i.Visit_Status == "Checked In" || i.Visit_Status == "Approved");

        this.setState
        ({
            selectedRows: selectedRows,
            cancelButtonEnabled: (selectedRows.length > 0 && selectedRows.length == cancellableRows.length),
            approveButtonEnabled: (selectedRows.length > 0 && selectedRows.length == approvableRows.length),
            denyButtonEnabled: (selectedRows.length > 0 && selectedRows.length == approvableRows.length),
            checkInButtonEnabled: (selectedRows.length > 0 && selectedRows.length == checkInableRows.length),
            checkOutButtonEnabled: (selectedRows.length > 0 && selectedRows.length == checkOutableRows.length),
            printPassButtonEnabled: (selectedRows.length > 0 && selectedRows.length == printableRows.length),
        });
    }

    private cancelManyClicked(): void
    {
        this.setState({
            isCompleteLoading: true,
        })
        let message = ""
        const promises = this.state.selectedRows.map((resourceId: IVisit) =>
        {
            return apis.updateCancelledVisitByVisitId(resourceId.Node_Id, resourceId.Visit_Id)
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.data.Message;
                        return Promise.resolve(response);
                    } else
                    {
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.setState({
                    isCompleteLoading: false,
                    selectedRows: []
                })
                this.getDetails();
            })
            .catch(error =>
            {
                this.setState({
                    isCompleteLoading: false,
                })
                console.log(error);
            });
    }

    private approveVisitorClicked(): void
    {
        this.setState({
            isCompleteLoading: true,
        })
        let message = ""
        const promises = this.state.selectedRows.map((resourceId: IVisit) =>
        {
            return apis.updateApproveVisitByVisitId(this.appState.buildingId, resourceId.Visit_Id)
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.data.Message;
                        return Promise.resolve(response);
                    } else
                    {
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.setState({
                    isCompleteLoading: false,
                })
                this.getDetails();
            })
            .catch(error =>
            {
                this.setState({
                    isCompleteLoading: false,
                })
                console.log(error)
            });
    }

    private denyVisitorClicked(): void
    {
        this.setState({
            isCompleteLoading: true,
        })
        let message = ""
        const promises = this.state.selectedRows.map((resourceId: IVisit) =>
        {
            return apis.updateDenyVisitByVisitId(this.appState.buildingId, resourceId.Visit_Id, this.state.denyComment)
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.data.Message;
                        return Promise.resolve(response);
                    } else
                    {
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.setState({
                    isCompleteLoading: false,
                })
                this.getDetails();
            })
            .catch(error => console.log(error));
    }

    private checkInVisitorClicked(): void
    {
        this.setState({
            isCompleteLoading: true,
        })
        let message = ""
        const promises = this.state.selectedRows.map((resourceId: IVisit) =>
        {
            return apis.updateCheckedInVisitByVisitId(this.appState.buildingId, resourceId.Visit_Id)
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.data.Message;
                        return Promise.resolve(response);
                    } else
                    {
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.setState({
                    isCompleteLoading: false,
                })
                this.getDetails();
            })
            .catch(error => console.log(error));
    }

    private checkOutVisitorClicked(): void
    {
        this.setState({
            isCompleteLoading: true,
        })
        let message = ""
        const promises = this.state.selectedRows.map((resourceId: IVisit) =>
        {
            return apis.updateCheckedOutVisitByVisitId(this.appState.buildingId, resourceId.Visit_Id)
                .then((response) =>
                {
                    if (response)
                    {
                        message = response.data.Message
                        return Promise.resolve(response);
                    } else
                    {
                        return Promise.reject(new Error(`Failed to delete with ID ${resourceId}`));
                    }
                })
        });
        Promise.all(promises)
            .then(responses =>
            {
                // All responses are okay, show the modal
                this.alert.show("", message);
                this.setState({
                    isCompleteLoading: false,
                })
                this.getDetails();
            })
            .catch(error => console.log(error));
    }

    private titleChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void
    {
        if (e.target.value.length >= 3) 
        {
            this.apiClient.users.getUsers(e.target.value).then((res) =>
            {
                const newArray = res && res && res[0] && res.map(item =>
                {
                    const newItem = { ...item, label: '' };
                    newItem.label = item.displayName ? item.displayName : item.email;
                    newItem.displayName = item.displayName ? item.displayName : item.email;
                    newItem.lastName = newItem.lastName ? newItem.lastName : "";
                    newItem.firstName = newItem.firstName ? newItem.firstName : "";
                    return newItem;
                })

                this.setState({
                    userList: (newArray && newArray[0]) ? newArray : []
                })
            }).catch((error) =>
            {
                console.log(error);
            });
        } else 
        {
            this.setState({
                userList: []
            })
        }
    }

    private userSelected(e: React.SyntheticEvent<Element, Event>, newValue: ICompanyName): void
    {
        if (newValue) 
        {
            this.setState({ selectedHostObject: { label: newValue.label, email: newValue.email } })
        } else 
        {
            this.setState({ selectedHostObject: { label: "", email: "" } })
        }
    }

    private async dateRangeChanged(e: SelectChangeEvent<string>): Promise<void>
    {
        this.setState({ daysFilter: e.target.value });

        if (e.target.value === this.labels.HubLabelToday)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().toJSDate(),
                    bookingEndDate: DateHelper.now().toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelTomorrow)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ days: 1 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('week').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextWeek)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('week').plus({ days: 7 }).toJSDate(),
                    bookingEndDate: DateHelper.now().startOf('week').plus({ days: 13 }).toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelThisMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').toJSDate(),
                    bookingEndDate: DateHelper.now().endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelNextMonth)
        {
            await this.setState(
                {
                    bookingStartDate: DateHelper.now().startOf('month').plus({ months: 1 }).toJSDate(),
                    bookingEndDate: DateHelper.now().plus({ months: 1 }).endOf('month').toJSDate(),
                    dateDisabled: true,
                });
        }
        if (e.target.value === this.labels.HubLabelCustom)
        {
            await this.setState(
                {
                    bookingStartDate: this.state.bookingStartDate,
                    bookingEndDate: this.state.bookingEndDate,
                    dateDisabled: false
                });
        }

        this.getDetails();
    }

    private async dateChanged(e: Date, type: string): Promise<void>
    {
        console.log(type, 'type')
        if (type === "Start")
        {
            await this.setState({
                bookingStartDate: e,
                isCompleteLoading: true
            });
        }
        else
        {
            await this.setState({
                bookingEndDate: e,
                isCompleteLoading: true
            });
        }
        this.getDetails()
    }

    private denyModelCancelled(): void 
    {
        this.setState({
            showDeny: !this.state.showDeny,
            comment: "",
        })
    }

    private denyButtonClicked(): void
    {
        this.setState({
            showDeny: !this.state.showDeny,
            comment: "",
        })
    }

    private fileUploadModalClosed(): void
    {
        this.setState({
            isFileUpload: !this.state.isFileUpload,
        })
    }

    private selectedFileChanged(e: React.ChangeEvent<HTMLInputElement>) 
    {
        this.setState({
            file: e.target.files?.[0]
        })
    }

    private async uploadFileButtonClicked(): Promise<void> 
    {
        this.setState({
            fileImporting: true,
            isCompleteLoading: true
        });
        this.fileUploadModalClosed()
        const formData = new FormData();

        const file: any = this.state.file ? this.state.file : "";
        formData.append('file', file);
        formData.append('fileName', file.name);

        await apis.visitorImportFile(this.appState.buildingId, formData)
            .then((res) =>
            {
                if (res.status === 200)
                {
                    let messageHtml = `<h2>${this.labels.HubLabelFileuploadedsuccessfully}</h2> </br>`;

                    const erroredEntries = res.data.filter((x: { ImportSuccessful: boolean; }) =>x.ImportSuccessful == false);
                    const successfulEntries = res.data.filter((x: { ImportSuccessful: boolean; }) =>x.ImportSuccessful == true);

                    if(erroredEntries.length > 0)
                    {
                        messageHtml = `<h2>${successfulEntries.length}/${res.data.length} ${this.labels.HubLabelEntriesUploadedSuccessfully}</h2><h3>${this.labels.HubLabelIssuesOccured}:</h3>` + res.data.filter((x: { ImportSuccessful: boolean; }) =>
                            x.ImportSuccessful == false).map((i: { Error: { RowNo: number, Message: string } }) =>
                                `<span>${this.labels.HubLabelRow} ${i.Error?.RowNo ? i.Error?.RowNo + 1 : '-'} ${i.Error?.Message?.length > 0 ? `- ${i.Error?.Message}` : ''}</span> </br>`).join('');
                    }
                    this.alert.show("", messageHtml, () => { }, { messageIsHtml: true });
                }
                else
                {
                    this.alert.show("", this.apiMessageBuilder.getMessage(res.statusText, this.appState.buildingId));
                }
            })
            .catch((error) =>
            {
                this.alert.show("", error.Message);
                this.setState({
                    isCompleteLoading: false
                })
            })
            .finally(() =>
            {
                this.setState({ fileImporting: false, isCompleteLoading: false });
            });
    }

    private async downloadVisitsClicked(): Promise<void> 
    {
        const email = this.local.getUserDetails().email;
        const filteredVisitorsObj = {
            buildingId: this.state.buildingid,
            startDate: this.fromDateToString(this.state.bookingStartDate, "start"),
            endDate: this.fromDateToString(this.state.bookingEndDate, "end"),
            statusValue: this.state.filterValue,
            host: this.state.selectedHostObject.email,
            email: email,
            type: this.state.urlParameter === "/flex-my-visitor" ? "flex" : "oneLens"
        }
        try
        {
            await apis.downloadExcelFilterVisitors(filteredVisitorsObj);
        }
        catch (error)
        {
            console.log(error, 'error')
        }

    }

    private printPassButtonClicked(): void
    {
        this.setState({
            showPrintPass: !this.state.showPrintPass  
        })
    }

    private makePrintPassButtonClicked(): void 
    {
        const content: any = document.getElementById("visitorBadgeContent");
        console.log(content, 'content')
        content.style.display = 'block'
        setTimeout(() =>
        {
            const content = document.getElementById("visitorBadgeContent");
            html2pdf(content, {
                jsPDF: {
                    format: 'a6', orientation: 'l', unit: 'px'
                },
                html2canvas: { scale: 2, allowTaint: false, useCORS: true },
                image: { type: 'jpeg', quality: 0.98 },
                output: './pdf/generate.pdf'
            });
            this.setState({ showPrintPass: !this.state.showPrintPass })
        })
    }

    private addVisitButtonClicked(): void
    {
        const { history } = this.props;
        if (this.state.urlParameter === "/flex-my-visitor")
        {
            history.push(`/flex-my-visitor/1/myvisit/0`);
        }
        else
        {
            history.push(`/operational-services-Visitors/${this.appState.buildingId}/visit/0/1`);
        }
    }

    private editVisitButtonClicked(param: IVisit): void
    {
        const { history } = this.props;
        if (this.state.urlParameter === "/flex-my-visitor")
        {
            history.push(`/flex-my-visitor/${param.Node_Id}/myvisit/${param.Visit_Id}`);
        }
        else
        {
            history.push(`/operational-services-Visitors/${this.appState.buildingId}/visit/${param.Visit_Id}/1`);
        }
    }

    private filterButtonClicked(): void
    {
        this.setState({
            isFilter: !this.state.isFilter,
        })
    }

    private filterModalClosed(): void
    {
        this.setState({
            isFilter: false,
            selectedHostObject: { label: "", email: "" }
        })
    }

    private async filterModalOkButtonClicked(): Promise<void>
    {
        this.setState({
            isCompleteLoading: true
        })
        const filteredVisitorsObj = {
            buildingId: this.state.buildingid,
            startDate: this.fromDateToString(this.state.bookingStartDate, "start"),
            endDate: this.fromDateToString(this.state.bookingEndDate, "end"),
            statusValue: this.state.filterValue,
            host: this.state.selectedHostObject.email.toLocaleLowerCase(),
        }
        const response = await apis.getFilteredVisitors(filteredVisitorsObj)
        const newArray = response.data.map((item: IVisit) => ({
            id: item.Visit_Id,
            ...item,
        }));
        this.setState({
            visitors: (newArray && newArray[0]) ? newArray : [],
            isCompleteLoading: false,
            tableData: newArray,
        });
        this.filterModalClosed()
    }


    private getStartTime(row:IVisit): string
    {
        return DateTime.fromISO(row.Visit_Start_Date).offsetTimeByNode(row.Node_Id,false).toLocaleDateTimeString();   
    }

    private getEndTime(row:IVisit): string
    {
        return DateTime.fromISO(row.Visit_End_Date).offsetTimeByNode(row.Node_Id,false).toLocaleDateTimeString();   
    }

    public render(): JSX.Element
    {
        const dataTodayColumn: GridColDef[] = [
            {
                headerName: this.labels.HubLabelVisitStart,
                field: "Booking_Start",
                width: 140,
                valueGetter: (params) => this.getStartTime(params.row),
            },
            {
                headerName: this.labels.HubLabelVisitEnd,
                field: "Booking_End",
                width: 140,
                valueGetter: (params) => this.getEndTime(params.row),
            },
            {
                headerName: this.labels.HubLabelTimeZone,
                field: "timeZone",
                minWidth: 140,
                renderCell: (params) => <TimeZoneOffset nodeId={params.row.Node_Id} />
            },
            {
                headerName: this.labels.HubLabelVisitorEmail,
                field: "Visitor_Email",
                width: 140,
            },
            {
                headerName: this.labels.HubLabelvisitor,
                field: "Visitor_First_Name",
                width: 140,
            },
            {
                headerName: this.labels.HubLabelCompany,
                field: "Visitor_Company",
                width: 140,
            },

            {
                headerName: this.labels.HubLabelHostName,
                field: "Visit_Host_Name",
                width: 140,
            },
            {
                headerName: this.labels.HubLabelVisitID,
                field: "Visit_Id",
                width: 140,
            },
            {
                headerName: this.labels.HubLabelAction,
                width: 120,
                field: "",
                renderCell: (params) => this.renderEdit(params.row)
            },
            {
                headerName: this.labels.HubLabelStatus,
                field: "Visit_Status",
                width: 140,
                renderCell: (params) => <BookingStatus status={params.row.Visit_Status} />
            },
        ];

        if (this.state.urlParameter === "/flex-my-visitor")
        {
            dataTodayColumn.splice(3, 1,
                {
                    headerName: this.labels.HubLabelSpace,
                    field: "Space_Name",
                    minWidth: 140,
                })
            dataTodayColumn.splice(6, 1);
        }

        const searchResults = this.searchTableData(this.state.searchTerm);

        let actions: IActionButton[] = [
            {
                label: this.labels.HubButtonApprove,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: !this.state.approveButtonEnabled || !this.state.canApproveVisits,
                onClick: () => this.approveVisitorClicked()
            },
            {
                label: this.labels.HubButtonDeny,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: !this.state.denyButtonEnabled || !this.state.canDenyVisits,
                onClick: () => this.denyButtonClicked()
            },
            {
                label: this.labels.HubButtonCancel,
                icon: (
                    <IbssSvgIcon>
                        <CloseIcon />
                    </IbssSvgIcon>),
                color: "error",
                disabled: !this.state.cancelButtonEnabled || !this.state.canCancelVisits,
                onClick: () => this.cancelManyClicked(),
            },
            {
                label: this.labels.HubButtonCheckIn,
                icon: (
                    <IbssSvgIcon>
                        <CheckCircleIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: !this.state.checkInButtonEnabled || !this.state.canCheckIntoVisits,
                onClick: () => this.checkInVisitorClicked()
            },
            {
                label: this.labels.HubButtonCheckOut,
                icon: (
                    <IbssSvgIcon>
                        <CancelIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: !this.state.checkOutButtonEnabled || !this.state.canCheckOutOfVisits,
                onClick: () => this.checkOutVisitorClicked()
            },
            {
                label: this.labels.HubButtonPrintPass,
                icon: (
                    <IbssSvgIcon>
                        <AccountBalanceWalletIcon />
                    </IbssSvgIcon>),
                color: "primary",
                disabled: !this.state.printPassButtonEnabled,
                onClick: () => this.printPassButtonClicked()
            },
            {
                label: this.labels.HubButtonAdd,
                icon: (
                    <IbssSvgIcon>
                        <AddIcon />
                    </IbssSvgIcon>
                ),
                color: "primary",
                disabled: !this.state.canCreateVisits,
                onClick: () => this.addVisitButtonClicked(),
            }
        ];

        if (this.state.urlParameter === "/flex-my-visitor")
        {
            actions = actions.filter(i => i.label == this.labels.HubButtonCancel || i.label == this.labels.HubButtonAdd);
        }

        return (
            <>
                <div>
                    {this.state.isCompleteLoading && <Spinner />}
                        <IbssDialog
                            onClose={() => this.filterModalClosed()}
                            open={this.state.isFilter}
                            header={this.labels.HubLabelFilter}
                            fullWidth
                            content={
                                <>
                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">{this.labels.HubLabelStatus}</label>
                                            <div className="form-input-select-box">
                                                <select className="custom-form-control visit-filter-selectbox" onChange={(e) => this.setState({ filterValue: e.target.value })} value={this.state.filterValue} >
                                                    <option></option>
                                                    {this.state.filterStatusOption.map((option: IFilterStatus) => (
                                                        <option value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-input-box">
                                            <label className="form-input-box-label visit-filter-label">{this.labels.HublabelHostVisitFilter}</label>
                                            <IbssAutocomplete
                                                freeSolo
                                                className="form-input-box-autocomplete auto-text"
                                                options={this.state.userList}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ fontSize: "14px", fontFamily: "Source Sans Pro", fontWeight: "bold" }}>{children}</Paper>
                                                )}
                                                value={this.state.selectedHostObject.label}
                                                onChange={(e, newValue) => this.userSelected(e, newValue)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        className="input-box model-textbox"
                                                        name="selectedUser"
                                                        id="selectedUser"
                                                        placeholder="&#8230;"
                                                        value={this.state.selectedHostObject.label}
                                                        onBlur={(e) => this.titleChanged(e)}
                                                        onChange={(e) => this.titleChanged(e)}
                                                    />
                                                } />
                                        </div>
                                    </div>
                                </>
                            }
                            footer={
                                <>
                                    <IbssButtonRedo
                                        onClick={() => this.filterModalClosed()}
                                        color="secondary"
                                        variant="outlined"
                                        className="mr-2"
                                    >
                                        {this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        className="ml-2"
                                        onClick={() => this.filterModalOkButtonClicked()}
                                    >
                                        {this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </>
                            }
                        />
                        <IbssDialog
                            onClose={() => this.fileUploadModalClosed()}
                            open={this.state.isFileUpload}
                            header={this.labels.HublabelUploadFile}
                            fullWidth
                            content={
                                <>
                                    <div className="row mt-3">
                                        <div className="col-3 pl-0 ">
                                            <label className="upload-file-modal-label pr-0">{this.labels.HubLabelFile}</label>
                                        </div>
                                        <div className="col-8 pl-0 file-picker">
                                            <input className="upload-file-modal-input" value={this.state.file?.name} type="text" readOnly></input>
                                            <label htmlFor="filePicker" className="button-outline file-picker-label mr-1">{this.labels.HubLabeSelectFile}</label>
                                            <input id="filePicker" className="hideInput" type={"file"} onChange={(e) => this.selectedFileChanged(e)}></input>
                                        </div>
                                    </div>
                                </>
                            }
                            footer={
                                <>
                                    <div className="d-flex justify-content-center w-100">
                                        <IbssButtonRedo
                                            onClick={() => this.fileUploadModalClosed()}
                                            color="secondary"
                                            variant="outlined"
                                            className="mr-2"
                                        >
                                            {this.labels.HubButtonCancel}
                                        </IbssButtonRedo>
                                        <IbssButtonRedo
                                            color="primary"
                                            variant="contained"
                                            size="medium"
                                            className="ml-2"
                                            onClick={() => this.uploadFileButtonClicked()}
                                        >
                                            {this.labels.HubLabelOk}
                                        </IbssButtonRedo>
                                    </div>
                                </>
                            }
                        />
                        <IbssDialog
                            onClose={() => this.denyModelCancelled()}
                            open={this.state.showDeny}
                            header={this.labels.HubButtonDeny}
                            fullWidth
                            content={
                                <>
                                    <div className="col-12">
                                        <label>{this.labels.HubLabelComment}</label>
                                        <input className="input-box border model-textbox" type="text" name="denyComment" value={this.state.denyComment} onChange={(e) => this.setState({ denyComment: e.target.value })} />
                                    </div>
                                </>
                            }
                            footer={
                                <>
                                    <IbssButtonRedo
                                        color="secondary"
                                        variant="outlined"
                                        className="mr-2" onClick={() => this.denyModelCancelled()}>{this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        disabled={!this.state.denyComment}
                                        onClick={() => this.denyVisitorClicked()}>{this.labels.HubLabelOk}
                                    </IbssButtonRedo>
                                </>
                            }
                        />
                        {/* --Print pass modal-- */}
                        <IbssDialog
                            onClose={() => this.setState({ showPrintPass: !this.state.showPrintPass })}
                            fullWidth
                            open={this.state.showPrintPass}
                            content={
                                <>
                                    <PrintPass passDataId={this.state.selectedRows} />
                                </>
                            }
                            footer={
                                <>
                                    <IbssButtonRedo
                                        color="secondary"
                                        variant="outlined"
                                        className="mr-2" onClick={() => this.setState({ showPrintPass: !this.state.showPrintPass })}>{this.labels.HubButtonCancel}
                                    </IbssButtonRedo>
                                    <IbssButtonRedo
                                        color="primary"
                                        variant="contained"
                                        size="medium"
                                        className="ml-2" onClick={() => this.makePrintPassButtonClicked()}>{this.labels.HubButtonPrint}
                                    </IbssButtonRedo>
                                </>
                            }
                        />
                        <div className="rightPanel-main-content">
                        <div className="table-panel">
                        <IbssPageHeader
                            pageTitle={this.labels.HubLabelMyVisitors}
                            daysFilter={this.state.daysFilter}
                            todayChanged={(e) => this.dateRangeChanged(e)}
                            startDate={this.state.bookingStartDate}
                            endDate={this.state.bookingEndDate}
                            dateDisabled={this.state.dateDisabled}
                            dateChanged={(event, value) => this.dateChanged(event, value)}
                            additionalDateFilterOptions={[this.labels.HubLabelTomorrow, this.labels.HubLabelThisWeek, this.labels.HubLabelNextWeek, this.labels.HubLabelThisMonth, this.labels.HubLabelNextMonth]}
                        />
                            <Grid container rowSpacing={1} sx={{display:'flex',alignItems:'center',mt:0,ml:0}}>
                                <Grid item xs={6} sx={{display:'flex',alignItems:'center'}}>
                                <Box
                                    sx={{
                                        '& > :not(style)': { mr: 2,my:1,alignItems:"center" },
                                    }}
                                >
                                    <IbssFormControl variant="outlined">
                                        <IbssTextField
                                            type="text"
                                            size="small"
                                            placeholder="Search"
                                            value={this.state.searchTerm}
                                            onChange={(event) =>
                                                this.setState({ searchTerm: event.target.value })
                                            }
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }}}
                                        />
                                    </IbssFormControl>
                                    {this.state.urlParameter !== "/flex-my-visitor" &&
                                        <IbssButtonRedo color="primary" className="mr-2" variant="contained" onClick={() => this.filterButtonClicked()}>{this.labels.HubLabelFilter}</IbssButtonRedo>
                                    }
                                    </Box>
                                    </Grid>
                                <Grid item xs={6} sx={{display:'flex', justifyContent: 'right'}}>
                                    { this.state.canExport && <IbssButtonRedo color="secondary" className="mr-2" variant="contained" onClick={() => this.downloadVisitsClicked()} >{this.labels.HubButtonExport}</IbssButtonRedo>}
                                    <IbssButtonRedo color="secondary" variant="contained" onClick={() => this.fileUploadModalClosed()}>{this.labels.HubButtonImport}</IbssButtonRedo>
                                </Grid>
                                </Grid>
                                <Box component="div" sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', my: 1, mr: 0 }}>
                                    <IbssActionButton buttons={actions} />
                                </Box>
                                <Box sx={{mt:1}}>
                        <IbssDataGrid
                            checkboxSelection
                            rows={searchResults}
                            columns={dataTodayColumn}
                            onRowSelectionModelChange={e => this.selectionChanged(e)}
                        />
                        </Box>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    private renderEdit(row: IVisit): React.ReactNode
    {
        return (
            <>
                {
                    this.state.canUpdateVisits && !(row.Visit_Status === "Cancelled" || row.Visit_Status === "Completed" || row.Visit_Status === "No Show" || row.Visit_Status === "Auto Cancelled") ?
                        <IbssSvgIcon className="pointer" onClick={() => this.editVisitButtonClicked(row)}>
                            <CreateIcon />
                        </IbssSvgIcon> : <p>-</p>
                }
            </>
        )
    }
}

const mapStateToProps = (state: any) =>
{
    return {
        currentPageTitle: state.currentPageTitle,
        lightModeTheme: state.lightModeTheme,
        identityProvidersStore: state.identityProviders,
        mainPageTitle: state.mainPageTitle,
    };
};

interface IProps extends RouterProps, RouteComponentProps<IMatchParams>, IPropsFromState, IDispatch
{
}

interface IMatchParams
{
    buildingid: string;

    tab: string;
    filter: string;
}

interface ICompanyName 
{
    displayName: string;
    email: string;
    firstName: string;
    label: string;
    lastName: string;
    oid: string;
    pin: string;
    userName: string;
}

interface IFilterStatus
{
    label: string;
    value: string;
}

interface IUserDetails
{
    label: string;
    email: string
}

interface IState
{
    isLoading: boolean;
    myVisit: IVisit[];
    canExport: boolean;
    canCreateVisits: boolean;
    canUpdateVisits: boolean;
    canApproveVisits: boolean;
    canDenyVisits: boolean;
    canCancelVisits: boolean;
    canCheckIntoVisits: boolean;
    canCheckOutOfVisits: boolean;
    activePage: string;
    buildingid: number;
    searchTerm: string;
    daysFilter: string;
    bookingStartDate: Date;
    bookingEndDate: Date;
    dateDisabled: boolean;
    cancelAllRowsEnable: boolean;
    selectedRows: IVisit[];
    tableData: IVisit[];
    isCompleteLoading: boolean;
    urlParameter: string;
    cancelButtonEnabled: boolean;
    approveButtonEnabled: boolean;
    denyButtonEnabled: boolean;
    checkInButtonEnabled: boolean;
    checkOutButtonEnabled: boolean;
    printPassButtonEnabled: boolean;
    showDeny: boolean;
    comment: string;
    fileImporting: boolean;
    denyComment: string;
    isFileUpload: boolean;
    showPrintPass: boolean;
    file: {
        name: string;
    } | undefined;
    filterStatusOption: IFilterStatus[];
    filterValue: string;
    visitors: IVisit[];
    isFilter: boolean;
    userList: IUserDetails[];
    selectedHostObject: {
        label: string;
        email: string;
    };
}

export default connect(mapStateToProps)(ListVisits);
