import { DateTime, Interval, Duration, DurationObjectUnits } from "luxon";
import { appContext } from "../AppContext";
import { dateConverter } from "./Helper";

export class DateHelper
{
    private static get localStorage() { return appContext().localStorageProvider; }
    public static get defaultZone() { return DateTime.local().zone.name; }
    public static readonly defaultFormat = "yyyy-MM-dd'T'HH:mm";

    public static today(timeZone?: string): DateTime
    {
        return DateTime.now().setZoneOrDefault(timeZone).date();
    }

    public static now(timeZone?: string): DateTime
    {
        return DateTime.now().setZoneOrDefault(timeZone);
    }

    public static null(): DateTime
    {
        return DateTime.invalid("null")
    }

    public static fromFormat(value: string, format?: string, timeZone?: string): DateTime
    {
        return DateTime.fromFormat(value, format ?? this.defaultFormat).setZoneOrDefault(timeZone);
    }

    public static fromIsoToJsDate(value: string): any
    {
        return DateTime.fromISO(value).toJSDate()
    }

    /** @deprecated */
    public static fromIsoToDefaultFormat(dateString: string, format?: string): string
    {
        return DateTime.fromISO(dateString).toFormatOrDefault();
    }

    public static getZoneByNode(nodeId: number): string
    {
        const nodes = this.localStorage.getNodeData();

        const nodesWithBuildingId = nodes.Regions
            .flatMap(region => region.Buildings)
            .flatMap(building =>
            {
                if(building.Floors?.length >0)
                {
                    return [
                        { nodeId: building.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ },
                        ...building.Floors.flatMap(floor =>
                        [
                            { nodeId: floor.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ },
                            ...floor.Zones?.map(zone => ({ nodeId: zone.Node_Id, buildingId: building.Node_Id, timeZone: building.Building_TZ })) ?? [],
                        ]),
                    ]
                }
            }
            );

        const node = nodesWithBuildingId.find(i => i?.nodeId == nodeId);
        const timeZone = (node == null ? "UTC" : node.timeZone);
        return timeZone;
    }

    public static fromIsoOrDateTime(value: DateTime | string): DateTime
    {
        return (typeof(value) == "string" ? DateTime.fromISO(value) : value);
    }

    public static fromIsoOrJsDate(value: Date | string | number): DateTime
    {
        return DateTime.fromISO(dateConverter(value));
    }

    // the 'on change' event on the 'ibss date/time pickers' gives a JS date as its first parameter
    // however this is not actually a JS date at runtime
    // this method converts from the parameter to a luxon date/time
    public static fromPicker(value: { toISO(): string } | { toISOString(): string } | null): DateTime
    {
        if (value == null)
        {
            return DateHelper.null();
        }
        else if ('toISO' in value)
        {
            return DateTime.fromISO(value.toISO());
        }
        else if ('toISOString' in value)
        {
            return DateTime.fromISO(value.toISOString());
        }
        else
        {
            throw new Error("Not supported");
        }
    }
}



