import axios, { AxiosResponse } from "axios";
import { ApiError } from "./ApiError";
import apis from "./apis";

export class RoleRepository implements IRoleRepository
{
    public async getRoles(): Promise<IRole[]>
    {
        try
        {
            const response: AxiosResponse<IRole[]> = await axios.get(`${apis.dataEntrySecurityApi}/Roles`);
            return response.data;
        }
        catch (response)
        {
            throw ApiError.fromAxiosResponse(response as AxiosResponse);
        }
    }
}

export interface IRoleRepository
{
    getRoles(): Promise<IRole[]>;
}

export interface IRole
{
    id: string,
    name: string,
}
